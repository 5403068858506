
import Vue, { PropType } from 'vue';
import RDisposalSiteDisposablePeriodMultipleDialog from '~/components/panels/schedule/r-order-form/RDisposalSiteDisposablePeriodMultipleDialog.vue';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { OrderDisposalSiteAssignmentType } from '~/framework/domain/typeAliases';
import { formatPeriod } from '~/framework/services/date-time/date-time';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';

type DataType = {
  isDisposablePeriodMultipleDialogActive: boolean;
};

export type DisposalSiteDisposablePeriod = {
  disposalSiteId: string;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
};

export default Vue.extend({
  name: 'RDisposalSitesDisposablePeriod',
  components: {
    RDisposalSiteDisposablePeriodMultipleDialog,
  },
  props: {
    orderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
    disposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<AggregatedDisposalSiteEntity[]>,
      required: true,
    },
    disposalSitesDisposableDatesText: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data(): DataType {
    return {
      isDisposablePeriodMultipleDialogActive: false,
    };
  },
  computed: {
    // NOTE: 指定処分場が複数の場合は簡易なテキストにする
    disposablePeriodMultipleText(): string {
      return this.orderAssignedDisposalSites
        .map(
          (orderAssignedDisposalSite) =>
            this.disposalSiteName(orderAssignedDisposalSite.disposalSiteId) +
            ' ' +
            formatPeriod(orderAssignedDisposalSite.disposablePeriodStart, orderAssignedDisposalSite.disposablePeriodEnd)
        )
        .join(', ');
    },
  },
  methods: {
    formatPeriod,
    onUpdateDisposablePeriodSingle(start: number, end: number): void {
      const currentDisposablePeriod: DisposalSiteDisposablePeriod = {
        disposalSiteId: this.orderAssignedDisposalSites[0].disposalSiteId,
        disposablePeriodStart: start,
        disposablePeriodEnd: end,
      };
      this.$emit('change', [currentDisposablePeriod]);
    },
    onUpdateDisposablePeriodMultiple(disposablePeriods: DisposalSiteDisposablePeriod[]): void {
      this.$emit('change', disposablePeriods);
    },
    disposalSiteName(disposalSiteId: string): string {
      const disposalSite = this.disposalSiteEntities.find((disposalSite) => disposalSite.id === disposalSiteId);
      // NOTE: store に存在しない処分場が出てくることはほぼありえないが、 type guard が必要
      return disposalSite ? disposalSite.name : '不明な処分場';
    },
  },
});
