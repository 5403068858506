
import Vue, { PropType } from 'vue';
enum EventTypes {
  ClickCancelAbort = 'click:cancel-abort',
  ClickCancelConfirm = 'click:cancel-confirm',
}
export default Vue.extend({
  name: 'RScheduleCancelReservationConfirmDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  methods: {
    onClickCancelAbort(): void {
      this.$emit(EventTypes.ClickCancelAbort);
    },
    onClickCancelConfirm(): void {
      this.$emit(EventTypes.ClickCancelConfirm);
    },
  },
});
