
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RResultDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
});
