import {
  CarAttendancesByDateRangeQuery,
  CarAttendancesByDateRangeQueryVariables,
  UpdateCarAttendanceMutation,
  UpdateCarAttendanceMutationVariables,
} from '@/graphql/graphQLServerApi';
import { CarAttendancesByDateRange } from '@/../graphql/server-api/queries/car-attendance.gql';
import { UpdateCarAttendance } from '@/../graphql/server-api/mutations/car-attendance.gql';
import { PersistentId } from '~/framework/typeAliases';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IUpdateData } from '~/framework/server-api/masters/carAttendance';

export class GetByDateRangeApi extends GraphqlApiBase {
  async getByDateRange(start: Date, end: Date, carIds?: Array<PersistentId>) {
    const result = await this.query<CarAttendancesByDateRangeQuery, CarAttendancesByDateRangeQueryVariables>({
      query: CarAttendancesByDateRange,
      variables: { condition: { start, end, carIds } },
    });
    return result.carAttendancesByDateRange;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateData) {
    const result = await this.mutate<UpdateCarAttendanceMutation, UpdateCarAttendanceMutationVariables>({
      mutation: UpdateCarAttendance,
      variables: { carAttendance: data },
    });
    return result.updateCarAttendance;
  }
}
