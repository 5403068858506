import { getHoursAndMinutesOf, secsToHhMm, splitHhMm } from '~/framework/services/date-time/date-time';
import { Maybe, Seconds } from '~/framework/typeAliases';
import { ValueObjectBase } from '~/framework/domain/valueObjectBase';

/**
 * 日付に依存しないとある日の「時間」を扱うためのもの
 */
export class Time extends ValueObjectBase<Time> {
  readonly hour: number;
  readonly minute: number;

  /**
   * 秒から生成する
   * コンストラクタのオーバーロードがまともにできないので致し方なくやっている
   * @param value
   */
  static buildBySecs(value: Maybe<Seconds> = 0): Time {
    const [hour, minute] = getHoursAndMinutesOf(value);
    return new Time(hour, minute);
  }

  /**
   * Hh:Mm の文字列から生成する
   * コンストラクタのオーバーロードがまともにできないので致し方なくやっている
   * @param value
   */
  static buildByString(value: Maybe<string> = '00:00'): Time {
    const [hour, minute] = splitHhMm(value);
    return new Time(Number.parseInt(hour), Number.parseInt(minute));
  }

  constructor(hour: number, minute: number) {
    super();
    this.hour = hour;
    this.minute = minute;
  }

  isEqualTo(another: Time): boolean {
    return this.toSecs() === another.toSecs();
  }

  isAfter(another: Time): boolean {
    return another.toSecs() < this.toSecs();
  }

  isBefore(another: Time): boolean {
    return this.toSecs() < another.toSecs();
  }

  toSecs(): Seconds {
    return this.hour * (60 * 60) + this.minute * 60;
  }

  toHhMm(ceil: boolean = false): string {
    return secsToHhMm(this.toSecs(), ceil);
  }

  toString(): string {
    return this.toHhMm();
  }

  /**
   * 時のみ指定した値になった Time を返す
   * @param hour
   */
  ofHour(hour: number): Time {
    return new Time(hour, this.minute);
  }

  /**
   * 分のみ指定した値になった Time を返す
   * @param minute
   */
  ofMinute(minute: number): Time {
    return new Time(this.hour, minute);
  }
}
