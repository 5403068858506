export interface IHasRestPeriodOption {
  value: boolean;
  label: string;
}

/**
 * 休憩時間の選択肢
 * 排出場、処分場では入場禁止時間
 */
export const hasRestPeriodOptions: IHasRestPeriodOption[] = [
  { value: false, label: '無' },
  { value: true, label: '有' },
];

/**
 * value から label を引ける様にしたもの
 */
export const hasRestPeriodOptionMap = new Map<IHasRestPeriodOption['value'], IHasRestPeriodOption['label']>(
  hasRestPeriodOptions.map((option) => [option.value, option.label])
);
