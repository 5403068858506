import {
  AllContainerTypeTaskTypesQuery,
  AllContainerTypeTaskTypesQueryVariables,
  UpdateContainerTypeTaskTypesMutation,
  UpdateContainerTypeTaskTypesMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllContainerTypeTaskTypes } from '@/../graphql/server-api/queries/container-type-task-type.gql';
import { UpdateContainerTypeTaskTypes } from '@/../graphql/server-api/mutations/container-type-task-type.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IUpdateData, IContainerTypeTaskTypeData } from '~/framework/server-api/masters/containerTypeTaskType';

export class GetAll extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllContainerTypeTaskTypesQuery, AllContainerTypeTaskTypesQueryVariables>({
      query: AllContainerTypeTaskTypes,
      variables: {},
    });

    const converted: IContainerTypeTaskTypeData[] = [];
    for (const containerTypeTaskType of result.allContainerTypeTaskTypes) {
      converted.push({
        id: containerTypeTaskType.id,
        containerType: containerTypeTaskType.containerType,
        taskType: containerTypeTaskType.taskType,
        duration: containerTypeTaskType.values.duration,
        status: containerTypeTaskType.values.status,
        isProportionalToCount: containerTypeTaskType.values.isProportionalToCount,
      } as const);
    }

    return converted;
  }
}

export class Update extends GraphqlApiBase {
  async update(data: IUpdateData[]) {
    const result = await this.mutate<
      UpdateContainerTypeTaskTypesMutation,
      UpdateContainerTypeTaskTypesMutationVariables
    >({
      mutation: UpdateContainerTypeTaskTypes,
      variables: { containerTypeTaskTypes: data },
    });
    this.validateArrayConsistency(data, result.updateContainerTypeTaskTypes, `UpdateContainerTypeTaskTypes`);
    return result.updateContainerTypeTaskTypes;
  }
}
