
import Vue, { PropType } from 'vue';

type Position = 'top' | 'bottom' | 'left' | 'right';

export default Vue.extend({
  name: 'RTooltip',
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    position: {
      type: String as PropType<Position>,
      required: false,
      default: 'bottom',
    },
  },
  data() {
    return {
      isTooltipVisible: false,
      // NOTE: コンテンツを表示する位置を微調整するための値
      menuWidth: 300,
      activatorWidth: 14,
    };
  },
  computed: {
    top(): boolean {
      return this.position === 'top';
    },
    bottom(): boolean {
      return this.position === 'bottom';
    },
    right(): boolean {
      return this.position === 'right';
    },
    left(): boolean {
      return this.position === 'left';
    },
    nudgeLeft(): number {
      if (this.position === 'top' || this.position === 'bottom') {
        return this.menuWidth / 2 - this.activatorWidth / 2;
      }
      return 0;
    },
    nudgeTop(): number {
      if (this.position === 'top') return 8;
      if (this.position === 'left' || this.position === 'right') return 20;

      return 0;
    },
    nudgeBottom(): number {
      if (this.position === 'bottom') return 8;

      return 0;
    },
    nudgeRight(): number {
      if (this.position === 'right') return 8;

      return 0;
    },
  },
  watch: {
    isTooltipVisible(value: boolean) {
      console.log(`isTooltipVisible: ${value}`);
    },
  },
  methods: {},
});
