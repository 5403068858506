
import Vue, { PropType } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import RSearchablePulldown from '~/components/common/r-searchable-pulldown/RSearchablePulldown.vue';
import { IErpClientData } from '~/framework/server-api/masters/erpClient';
import { required } from '~/framework/view-models/rules';
import { Id } from '~/framework/typeAliases';
import { ErpOrderForm } from '~/components/common/r-erp-order-form/RErpOrderForm';

export default Vue.extend({
  name: 'RErpOrderForm',
  components: { RSearchablePulldown },
  model: {
    prop: 'initialErpOrderForm',
    event: 'change',
  },
  props: {
    transportationClients: {
      type: Array as PropType<IErpClientData[]>,
      required: true,
    },
    initialErpOrderForm: {
      type: Object as PropType<ErpOrderForm>,
      required: true,
    },
    isErpOrderCategoryRequired: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const withDisposalItemFromSchedulingOptions = [
      {
        label: '運搬・処分',
        value: true,
      },
      {
        label: '運搬のみ',
        value: false,
      },
    ];

    return {
      erpOrderForm: cloneDeep(this.initialErpOrderForm),
      withDisposalItemFromSchedulingOptions,
      rules: {
        required,
      },
    };
  },
  watch: {
    erpOrderForm: {
      handler(): void {
        this.$emit('change', this.erpOrderForm);
      },
      deep: true,
    },
  },
  methods: {
    onChangeTransportationClientId(id: Id): void {
      // NOTE: `transportationClients` から選択しているわけなので見つからないことはない。
      this.erpOrderForm.transportationClient = this.transportationClients.find((client) => client.id === id)!;
    },
  },
});
