
import Vue, { PropType } from 'vue';

type Link = {
  url: string;
  text: string;
};

export default Vue.extend({
  name: 'RDescribeCardItem',
  props: {
    subtitle: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    text: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    link: {
      type: Object as PropType<Link>,
      required: false,
      default: undefined,
    },
  },
});
