export interface IGoogleMapsRequestError {
  code: string;
  endpoint: string;
  name: string;
  message: string;
}

export interface IGoogleMapsServerError {
  code: string;
  endpoint: string;
  name: string;
  message: string;
}

export const isGoogleMapsRequestError = (error: Error): error is IGoogleMapsRequestError => {
  return error.name === 'MapsRequestError';
};

export const isGoogleMapsServerError = (error: Error): error is IGoogleMapsServerError => {
  return error.name === 'MapsServerError';
};

export const isIgnorableGoogleMapsRequestError = (error: Error) => {
  if (
    isGoogleMapsRequestError(error) &&
    error.endpoint === 'STREETVIEW_GET_PANORAMA' &&
    error.code === 'ZERO_RESULTS'
  ) {
    // Street view の表示にして道がないような場所を見ようとするとエラーになってしまう
    // ひとまずこれに関しては握りつぶしてもいいということにする
    return true;
  }
  if (isGoogleMapsServerError(error)) {
    // Server 側のエラーはひとまず全部無視していいということにする
    return true;
  }
  return false;
};
