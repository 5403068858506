import { StoredMapperBase } from '~/framework/core/mapper';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { IOrderGroupData } from '~/framework/server-api/masters/orderGroup';

export class OrderGroupMapper extends StoredMapperBase<IOrderGroupData, OrderGroupEntity> {
  protected store: OrderGroupStore;

  constructor(store: OrderGroupStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IOrderGroupData): OrderGroupEntity {
    return new OrderGroupEntity(data);
  }

  protected updateWithData(data: IOrderGroupData, entity: OrderGroupEntity): void {
    entity.update(data);
  }
}
