export const reservation$cancelReservationSymbol = Symbol('reservation$cancelReservationSymbol');

export interface ICancelReservationApi {
  [reservation$cancelReservationSymbol]: void;
  cancelReservation(data: ICancelReservationData): Promise<ICancelReservationMutationResultData>;
}

export interface ICancelReservationData {
  id: string;
}

export interface ICancelReservationMutationResultData {
  id: string;
}
