import { IDisposable } from '~/framework/core/disposable';
import { Ports } from '~/framework/core/ports';
import { Store } from '~/framework/domain/store';
import { AggregatedWasteTypeMapper } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeMapper';
import { JwnetWasteMasterMapper } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterMapper';
import { JwnetWasteMasterEntity } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { AggregatedWasteTypeEntity } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';
import {
  wasteType$getAllSymbol,
  wasteType$getByKeywordsSymbol,
  wasteType$getAvailableCodeSymbol,
  ICreateData,
  wasteType$createSymbol,
  IUpdateData,
  wasteType$updateSymbol,
  IWasteTypeCreateError,
} from '~/framework/server-api/masters/wasteType';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import { jwnetWasteMaster$getAllSymbol } from '~/framework/server-api/masters/jwnetWasteMaster';

export interface ICreatePresenter {
  create(entity: AggregatedWasteTypeEntity): void;
  errorOnCreate(error: IWasteTypeCreateError): void;
}

export const wasteTypeSymbol = Symbol('wasteType');

export class WasteTypeApplicationService {
  private readonly serverApis: ServerApiManager;
  private readonly createPort: Ports<ICreatePresenter>;
  private jwnetWasteMasterMapper: JwnetWasteMasterMapper;
  private aggregatedWasteTypeMapper: AggregatedWasteTypeMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.createPort = new Ports<ICreatePresenter>();
    this.jwnetWasteMasterMapper = new JwnetWasteMasterMapper(store.masters.jwnetWasteMaster);
    this.aggregatedWasteTypeMapper = new AggregatedWasteTypeMapper(
      store.masters.aggregatedWasteType,
      store.masters.jwnetWasteMaster,
      store.masters.user
    );
  }

  async getAllJwnetWasteMasters(): Promise<JwnetWasteMasterEntity[]> {
    const getAllApi = this.serverApis.get(jwnetWasteMaster$getAllSymbol);
    const result = await getAllApi.getAll();
    const entities = this.jwnetWasteMasterMapper.map(result);
    return entities;
  }

  async getAvailableWasteTypeCode(code: string): Promise<string> {
    const getAvailableCodeApi = this.serverApis.get(wasteType$getAvailableCodeSymbol);
    return await getAvailableCodeApi.getAvailableCode(code);
  }

  addCreatePresenter(presenter: ICreatePresenter): IDisposable {
    return this.createPort.add(presenter);
  }

  async create(data: ICreateData): Promise<void> {
    const createApi = this.serverApis.get(wasteType$createSymbol);
    const createdResult = await createApi.create(data);
    if (createdResult.__typename === 'WasteType') {
      const createdEntity = this.aggregatedWasteTypeMapper.mapSingle(createdResult);
      this.createPort.output('create', createdEntity);
    } else {
      this.createPort.output('errorOnCreate', createdResult);
    }
  }

  async update(data: IUpdateData): Promise<AggregatedWasteTypeEntity> {
    const updateApi = this.serverApis.get(wasteType$updateSymbol);
    const updatedResult = await updateApi.update(data);
    const updatedEntity = this.aggregatedWasteTypeMapper.mapSingle(updatedResult);
    return updatedEntity;
  }

  async search(keywords: string[]): Promise<AggregatedWasteTypeEntity[]> {
    const getByKeywordsApi = this.serverApis.get(wasteType$getByKeywordsSymbol);
    const result = await getByKeywordsApi.getByKeywords(keywords);
    const entities = this.aggregatedWasteTypeMapper.map(result);
    return entities;
  }

  async getAll(): Promise<AggregatedWasteTypeEntity[]> {
    const getAllApi = this.serverApis.get(wasteType$getAllSymbol);
    const result = await getAllApi.getAll();
    const entities = this.aggregatedWasteTypeMapper.map(result);
    return entities;
  }
}
