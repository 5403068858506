import { PersistentId } from '~/framework/typeAliases';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { IDuplicatedNameError } from '~/framework/server-api/errors';
import { CheckItem, CreateCheckItemMutation, UpdateCheckItemMutation } from '~/graphql/graphQLServerApi';
export const checkItem$getAllSymbol = Symbol('checkItem$getAll');
export const checkItem$createSymbol = Symbol('checkItem$create');
export const checkItem$updateSymbol = Symbol('checkItem$update');
export const checkItem$deleteSymbol = Symbol('checkItem$delete');
export const checkItem$updateSequenceSymbol = Symbol('checkItem$updateSequence');

export type ApiTypes = {
  [checkItem$getAllSymbol]: IGetAllApi;
  [checkItem$createSymbol]: ICreateApi;
  [checkItem$updateSymbol]: IUpdateApi;
  [checkItem$deleteSymbol]: IDeleteApi;
  [checkItem$updateSequenceSymbol]: IUpdateApi;
};

export interface ICheckItemData {
  id: string;
  name: string;
  default: boolean;
  status: SoftDeleteStatus;
}

export type ICreateResultTypes = CreateCheckItemMutation['createCheckItem'];
export type IUpdateResultTypes = UpdateCheckItemMutation['updateCheckItem'];

export interface IGetAllApi {
  getAll(): Promise<ICheckItemData[]>;
}

export interface ICreateData {
  name: string;
  default: boolean;
}

export interface ICreateApi {
  create(data: ICreateData): Promise<ICreateResultTypes>;
}

export interface IUpdateData {
  name: string;
  default: boolean;
}

export interface IUpdateApi {
  update(id: string, data: IUpdateData): Promise<IUpdateResultTypes>;
  updateSequence(data: string[]): Promise<string[]>;
}

export interface IDeleteApi {
  delete(id: PersistentId): Promise<CheckItem>;
}

export interface ICheckItemMutationError {
  __typename: 'CheckItemMutationError';
  errors: CheckItemMutationErrorTypes[];
}

export type CheckItemMutationErrorTypes = IDuplicatedNameError;
