import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';

export interface IContainerType {
  readonly entity: AggregatedContainerTypeEntity;
  isSamePackingStyle: boolean;
}

export class ContainerType {
  readonly entity: AggregatedContainerTypeEntity;
  isSamePackingStyle: boolean;

  constructor(entity: AggregatedContainerTypeEntity) {
    this.entity = entity;
    this.isSamePackingStyle = false;
  }
}
