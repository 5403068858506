import { WithoutId } from '~/framework/typeAliases';

export const collectablePeriodTemplate$createSymbol = Symbol('create');

export const collectablePeriodTemplate$getAllSymbol = Symbol('getAll');

export const collectablePeriodTemplate$updateSymbol = Symbol('update');

export type CollectablePeriodTemplate = {
  [collectablePeriodTemplate$getAllSymbol]: IGetAllApi;
  [collectablePeriodTemplate$updateSymbol]: IUpdateApi;
  [collectablePeriodTemplate$createSymbol]: ICreateApi;
};
/**
 * API でやり取りするデータの型
 */
export interface ICollectablePeriodTemplateData {
  id: string;
  name: string;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  note: string | undefined;
  isDefault: boolean;
}
export interface ICreateApi {
  /**
   * 作成して ID の配列を返す
   * @param data
   */
  create(data: WithoutId<ICollectablePeriodTemplateData>[]): Promise<string[]>;
}

export interface IGetAllApi {
  getAll(): Promise<ICollectablePeriodTemplateData[]>;
}

export interface IUpdateApi {
  update(data: ICollectablePeriodTemplateData[]): Promise<string[]>;
}
