import { Id } from '~/framework/typeAliases';
import { EmploymentStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { ISimpleCarTypeData } from './carType';
import { IDriverAttendanceTemplateData } from './driverAttendanceTemplate';
import { ICarData } from './car';
export const driver$getAllSymbol = Symbol('getAll');
export const driver$getByIdsSymbol = Symbol('driver$getByIdsSymbol');
export const driver$createSymbol = Symbol('create');
export const driver$updateSymbol = Symbol('update');

export type Driver = {
  [driver$getAllSymbol]: IGetAllApi;
  [driver$getByIdsSymbol]: IGetByIdsApi;
  [driver$createSymbol]: ICreateApi;
  [driver$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: ICreateDriverData[]): Promise<string[]>;
}

export interface IDriverData {
  id: string;
  name: string;
  nameRuby: string;
  employmentStatus: EmploymentStatus;
  defaultPrimaryCar: ICarData | undefined;
  licensedCarTypes: ISimpleCarTypeData[];
  defaultAttendanceTemplate: IDriverAttendanceTemplateData | undefined;
  status: SoftDeleteStatus;
  signInUrl: string;
}

export interface IGetAllApi {
  /**
   * @param ids 仮に deleted だったとしても取得したいデータ
   */
  getAll(ids?: string[]): Promise<IDriverData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IDriverData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateDriverData[]): Promise<string[]>;
  updateDriverSequence(data: IUpdateDriverSequenceData): Promise<Id[]>;
}

export interface ICreateDriverData {
  name: string;
  nameRuby: string;
  employmentStatus: EmploymentStatus;
  defaultPrimaryCarId: string | undefined;
  licensedCarTypeIds: string[];
  defaultAttendanceTemplateId: string | undefined;
  status: SoftDeleteStatus;
}

export interface IUpdateDriverData extends ICreateDriverData {
  id: string;
}

export type IUpdateDriverSequenceData = {
  sortedDriverIds: Id[];
};
