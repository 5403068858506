
import Vue from 'vue';
import { VAutocomplete } from 'vuetify/lib';
import { Pixel } from '~/framework/typeAliases';

enum EventTypes {
  /**
   * スクロールしてアイテムの最後に達した時に呼ばれる
   */
  EndOfItems = 'end-of-items',
}

/**
 * スクロール領域の残りがこの量になったら、次のアイテムの読み込みが必要と判断する
 */
const endOfItemsThreshold: Pixel = 100;

// NOTE Vuetify は全てのコンポーネントを Component として型付けしているため、拡張する時にそのコンポーネントの
// 型を参照できない。しかし any にするのはさすがに微妙なので個別に参照するもののみ、定義しておく
type VueType = typeof Vue;
type VAutocompleteType = {
  data(): {
    isMenuActive: boolean;
  };
  methods: {
    getContent(): HTMLElement;
  };
  options: Record<string, any>;
};

type VAutocompleteDataType = ReturnType<VAutocompleteType['data']>;
type VAutocompleteMethodsType = VAutocompleteType['methods'];

export default (VAutocomplete as VueType).extend<VAutocompleteDataType, VAutocompleteMethodsType, {}, {}>().extend({
  name: 'RLazyLoadVAutocomplete',
  props: {},
  beforeMount() {
    // watch の親を呼ぶ方法が見つからなかったので $watch している
    this.$watch('isMenuActive', this.onIsMenuActiveChange);
  },
  methods: {
    onScroll(): void {
      // オーバーライドしてしまっているので親を呼んでおく
      (VAutocomplete as any).options.methods.onScroll.call(this);
      this.checkMenuScrollPosition();
    },
    checkMenuScrollPosition(): void {
      const content = this.getContent();
      if (this.isMenuActive && content) {
        const scrollable = content.scrollHeight - (content.scrollTop + content.clientHeight);
        const showMoreItems = scrollable < endOfItemsThreshold;
        if (showMoreItems) this.$emit(EventTypes.EndOfItems);
      }
    },
    scrollMenuToTop(): void {
      const content = this.getContent();
      if (content) content.scrollTop = 0;
    },
    async onIsMenuActiveChange(): Promise<void> {
      // NOTE メニューをいったん開いて閉じた後にまた開くと前回のメニューの内容が残ってしまっており、
      // そのままだと表示に必要なアイテムが十分読み込まれない事がある。そこでメニューが開いたら
      // 読み込みが必要かどうかを一度チェックしておく。

      this.scrollMenuToTop();
      await Vue.nextTick();
      this.checkMenuScrollPosition();
    },
  },
});
