import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { Maybe } from '~/framework/typeAliases';

export const packingPlacement$updateSymbol = Symbol('packingPlacement$updateSymbol');

export interface IUpdateApi {
  [packingPlacement$updateSymbol]: void;
  update(generationSiteId: string, packingPlacements: IUpdateData[]): Promise<IPackingPlacementData[]>;
}

export interface IUpdateData {
  containerTypeId: string;
  num: number;
  lastAllocatedAt: Maybe<Date>;
}
