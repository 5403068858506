import { IOrderData } from '~/framework/server-api/schedule/order/order';
import { PersistentId } from '~/framework/typeAliases';

export const order$routableOrders = Symbol('order$routableOrders');

export interface IRoutableOrdersApi {
  [order$routableOrders]: void;
  routableOrders(condition: IRoutableOrdersCondition): Promise<IOrderData[]>;
}

export interface IRoutableOrdersCondition {
  orderId: PersistentId | undefined;
  orderGroupId: PersistentId;
  date: Date;
}
