import { GraphQLScalarType, Kind } from 'graphql';
import { parseISO, formatISO } from 'date-fns';

/**
 * ISO8601 の日付・日時フォーマット
 */
export const Iso8601DateTimeScalarType = new GraphQLScalarType({
  name: 'Iso8601DateTime',
  /**
   * 内部値（フロントエンド側）を出力用（サーバー側）の値に変換する
   * @param value
   */
  serialize: (value: Date) => {
    return formatISO(value);
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param value
   */
  parseValue: (value): Date => {
    return parseISO(value);
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param valueAST
   */
  parseLiteral: (valueAST): Date => {
    if (valueAST.kind === Kind.STRING) {
      return parseISO(valueAST.value);
    }
    throw new Error(`Cannot parse kind: ${valueAST.kind}`);
  },
});
