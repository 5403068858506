import { CollectionEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/collectionEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Maybe } from '~/framework/typeAliases';
import { AggregatedOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { CarUsage } from '~/pages/schedule/carUsage';
import { OriginalCollection } from '~/pages/schedule/originalCollection';
import { makePropertyReactive } from '~/framework/property';

export class Collection extends CollectionEntity<OriginalCollection> {
  // reactive property を追加
  /**
   * この収集のもととなった注文
   */
  order: AggregatedOrderEntity;
  /**
   * どの車が割り当てられているか
   */
  orderCarAssignments: CarUsage[];
  /**
   * 順番固定ができるかどうか
   * 上から順番にしか固定できない
   */
  isFixable: boolean;
  /**
   * ドラッグ可能な状態にあるかどうか
   */
  isDraggable: boolean;

  /**
   * ドラッグしている収集と同じルートに属しているかどうか
   */
  _isSameRouteAsDraggingCollection: Maybe<boolean>;
  /**
   * ルートをドラッグ中かどうか
   */
  _isDraggingRoute: boolean;
  /**
   * 開始時間が編集可能かどうか
   */
  isStartTimeEditable: boolean;
  /**
   * 終了時間が編集可能かどうか
   */
  isEndTimeEditable: boolean;
  /**
   * ソートが終わったアニメーションを再生するかどうか
   */
  isSortedAnimationEnabled: boolean;
  /**
   * 順番が固定された際のアニメーションを有効にするかどうか
   */
  isFixedAnimationEnabled: boolean;

  get isSameRouteAsDraggingCollection(): Maybe<boolean> {
    return this._isSameRouteAsDraggingCollection;
  }

  set isSameRouteAsDraggingCollection(value: Maybe<boolean>) {
    this._isSameRouteAsDraggingCollection = value;
    this.updateIsDraggable();
  }

  get isDraggingRoute(): boolean {
    return this._isDraggingRoute;
  }

  set isDraggingRoute(value: boolean) {
    this._isDraggingRoute = value;
    this.updateIsDraggable();
  }

  constructor(
    original: Maybe<OriginalCollection>,
    id: string,
    index: number,
    orderId: PseudoId,
    generationSiteArrivalTime: number,
    generationSiteDepartureTime: number,
    hasRestBeforeGenerationSiteArrival: boolean,
    isFixedAssignment: boolean,
    order: AggregatedOrderEntity,
    orderCarAssignments: CarUsage[]
  ) {
    super(
      original,
      id,
      index,
      orderId,
      generationSiteArrivalTime,
      generationSiteDepartureTime,
      hasRestBeforeGenerationSiteArrival,
      isFixedAssignment
    );
    this.order = order;
    this.orderCarAssignments = orderCarAssignments;
    this.isFixable = false;
    this.isDraggable = true;
    this._isSameRouteAsDraggingCollection = undefined;
    this._isDraggingRoute = false;
    this.isStartTimeEditable = false;
    this.isEndTimeEditable = false;
    this.isSortedAnimationEnabled = false;
    this.isFixedAnimationEnabled = false;

    // CollectionEntity のプロパティ変更を検知
    makePropertyReactive(this, 'isFixedAssignment', {
      preSetter: this.preSetIsFixedAssignment,
      postSetter: this.postSetIsFixedAssignment,
    });
  }

  /**
   * 回収の移動が終わった時に呼ばれる
   */
  onReassignCollection(): void {
    this.updateIsDraggable();
  }

  /**
   * @param isUnstable
   * @override
   */
  setIsCarUnstable(isUnstable: boolean) {
    super.setIsCarUnstable(isUnstable);
    if (isUnstable || this.original === undefined) {
      this.orderCarAssignments = [];
    } else {
      this.orderCarAssignments = this.original.orderCarAssignments;
    }
  }

  /**
   * 回収がドラッグ可能かどうかを更新する
   *
   * @description
   * - 回収が一つしかないのであればドラッグ不可
   * - 回収が固定されていたら問答無用でドラッグ不可
   * - 回収が固定されていない場合にドラッグ中ではないかもしくはドラッグ中であっても同じドライバーであればドラッグ可能
   * - それ以外はドラッグ不可
   */
  updateIsDraggable() {
    if (this.isFirstOfRoute && this.isLastOfRoute) {
      this.isDraggable = false;
    } else if (this.isFixedAssignment || this.isDraggingRoute) {
      this.isDraggable = false;
    } else if (this.isSameRouteAsDraggingCollection === undefined || this.isSameRouteAsDraggingCollection) {
      this.isDraggable = true;
    } else {
      this.isDraggable = false;
    }
  }

  clone(): Collection {
    // なんとなく orderCarAssignments はそのまま渡すとおかしくなりそうな気がしたので
    const clone = new Collection(
      this.original,
      this.id,
      this.index,
      this.orderId,
      this.generationSiteArrivalTime,
      this.generationSiteDepartureTime,
      this.hasRestBeforeGenerationSiteArrival,
      this.isFixedAssignment,
      this.order,
      [...this.orderCarAssignments]
    );

    // 面倒だが内部状態をきちんとコピーする必要がある
    // CollectionEntity
    clone.startTime = this.startTime;
    clone.endTime = this.endTime;
    clone.isFirstOfDriver = this.isFirstOfDriver;
    clone.isFirstOfRoute = this.isFirstOfRoute;
    clone.isLastOfRoute = this.isLastOfRoute;
    clone.isLastOfDriver = this.isLastOfDriver;
    clone.isTimetableUnstable = this.isTimetableUnstable;
    clone.isStartGarageSiteAndBaseSiteSame = this.isStartGarageSiteAndBaseSiteSame;
    clone.hasRest = this.hasRest;
    clone.hasRestAfterGenerationSiteDeparture = this.hasRestAfterGenerationSiteDeparture;
    clone.hasRestAfterDisposalSiteDeparture = this.hasRestAfterDisposalSiteDeparture;
    clone.lastRestAfterGenerationSiteDepartureEndTime = this.lastRestAfterGenerationSiteDepartureEndTime;
    clone.lastRestAfterDisposalSiteDepartureEndTime = this.lastRestAfterDisposalSiteDepartureEndTime;
    clone.routeStartTime = this.routeStartTime;
    clone.routeEndTime = this.routeEndTime;
    clone.lastGarageSiteArrivalTime = this.lastGarageSiteArrivalTime;
    clone.garageSiteDepartureTime = this.garageSiteDepartureTime;
    clone.garageSiteDepartureId = this.garageSiteDepartureId;
    clone.baseSiteId = this.baseSiteId;
    clone.baseSiteArrivalTime = this.baseSiteArrivalTime;
    clone.baseSiteDepartureTime = this.baseSiteDepartureTime;
    clone.lastGenerationSiteDepartureTime = this.lastGenerationSiteDepartureTime;
    clone.lastDisposalSiteDepartureTime = this.lastDisposalSiteDepartureTime;
    clone.disposalSiteArrivalTime = this.disposalSiteArrivalTime;
    clone.disposalSiteDepartureTime = this.disposalSiteDepartureTime;
    clone.garageSiteArrivalTime = this.garageSiteArrivalTime;
    clone.isFixable = this.isFixable;
    // Collection
    // いくつかは this の内容をそのまま取ってくると復元した時に UI が壊れるので直接値を指定している
    clone.isDraggable = true;
    clone._isSameRouteAsDraggingCollection = undefined;
    clone._isDraggingRoute = false;
    clone.isStartTimeEditable = this.isStartTimeEditable;
    clone.isEndTimeEditable = this.isEndTimeEditable;
    clone.isSortedAnimationEnabled = this.isSortedAnimationEnabled;
    clone.isFixedAnimationEnabled = this.isFixedAnimationEnabled;
    clone.updateIsDraggable();

    return clone;
  }

  private preSetIsFixedAssignment(value: boolean): boolean {
    return !!value;
  }

  private postSetIsFixedAssignment(value: boolean): boolean {
    this.updateIsDraggable();
    if (value) this.isFixedAnimationEnabled = true;
    return value;
  }
}
