import { StoredMapperBase } from '~/framework/core/mapper';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { IDriverAttendanceTemplateData } from '~/framework/server-api/masters/driverAttendanceTemplate';

export class DriverAttendanceTemplateMapper extends StoredMapperBase<
  IDriverAttendanceTemplateData,
  DriverAttendanceTemplateEntity
> {
  protected store: DriverAttendanceTemplateStore;

  constructor(store: DriverAttendanceTemplateStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IDriverAttendanceTemplateData): DriverAttendanceTemplateEntity {
    return new DriverAttendanceTemplateEntity(data);
  }

  protected updateWithData(data: IDriverAttendanceTemplateData, entity: DriverAttendanceTemplateEntity): void {
    entity.update(data);
  }
}
