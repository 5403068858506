
import Vue, { PropType } from 'vue';
import { Maybe } from '~/framework/typeAliases';
import { MapDialogResultType } from '~/components/common/r-map-dialog/resultType';
import { IAddress } from '~/framework/services/google-maps/address';
import { VuetifyColors } from '~/framework/constants';
import { GoogleMapService, IGoogleMapService } from '~/framework/services/google-maps/googleMapService';

type DataType = {
  isMapDialogActive: boolean;
  addressOption: Maybe<IAddress>;
  ambiguousAddress: Maybe<IAddress>;
  isMapConfirmationRequired: boolean;
  isAddressValidatedDialogActive: boolean;
  isAddressOptionDialogActive: boolean;
  google: IGoogleMapService;
  addressMessage: Maybe<AddressMessage>;
  isValidating: boolean;
  // 何故住所を２重管理しているかと言うと、変更があった場合に住所チェックは再度行いたいが、
  // そのままイベントを emit すると代替の住所で再度チェックしなくていい場合に判別が付かなくなってしまうため
  postalCodeValue: string;
  address1Value: string;
  address2Value: string;
  address3Value: string;
};

type AddressMessage = {
  message: string;
  type: VuetifyColors;
  callback: () => void;
};

enum EventTypes {
  UpdateIsAddressValidated = 'update:is-address-validated',
  UpdateLatitude = 'update:latitude',
  UpdateLongitude = 'update:longitude',
  UpdatePostalCode = 'update:postal-code',
  UpdateAddress1 = 'update:address1',
  UpdateAddress2 = 'update:address2',
  UpdateAddress3 = 'update:address3',
}

export default Vue.extend({
  name: 'RAddressValidator',
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    mapDialogTitle: {
      type: String as PropType<string>,
      required: true,
    },
    postalCode: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address1: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address2: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address3: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    latitude: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    longitude: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    isAddressValidated: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      isMapDialogActive: false,
      addressOption: undefined,
      isMapConfirmationRequired: false,
      ambiguousAddress: undefined,
      isAddressValidatedDialogActive: false,
      isAddressOptionDialogActive: false,
      google: new GoogleMapService(this.$google),
      addressMessage: undefined,
      isValidating: false,
      postalCodeValue: this.postalCode,
      address1Value: this.address1,
      address2Value: this.address2,
      address3Value: this.address3,
    };
  },
  computed: {
    isAddressValidatable(): boolean {
      // 住所っぽいものが入力されていて一応検索できる状態になっているか
      return this.address1 !== '' && this.address2 !== '' && this.address3 !== '';
    },
    isValidateAddressButtonDisabled(): boolean {
      return !this.isAddressValidatable || this.isAddressValidated;
    },
    hasAddressMessage(): boolean {
      return this.addressMessage !== undefined;
    },
    address(): string {
      const components = [];
      if (this.postalCodeValue) components.push(this.postalCodeValue);
      if (this.address1Value) components.push(this.address1Value);
      if (this.address2Value) components.push(this.address2Value);
      if (this.address3Value) components.push(this.address3Value);
      return components.join(' ');
    },
  },
  watch: {
    postalCode(value: string) {
      if (this.postalCodeValue !== value) {
        this.postalCodeValue = value;
        this.invalidateAddress();
      }
    },
    address1(value: string) {
      if (this.address1Value !== value) {
        this.address1Value = value;
        this.invalidateAddress();
      }
    },
    address2(value: string) {
      if (this.address2Value !== value) {
        this.address2Value = value;
        this.invalidateAddress();
      }
    },
    address3(value: string) {
      if (this.address3Value !== value) {
        this.address3Value = value;
        this.invalidateAddress();
      }
    },
  },
  methods: {
    invalidateAddress() {
      this.ambiguousAddress = undefined;
      this.addressMessage = undefined;
      this.addressOption = undefined;
      this.$emit(EventTypes.UpdateLatitude, undefined);
      this.$emit(EventTypes.UpdateLongitude, undefined);
      this.$emit(EventTypes.UpdateIsAddressValidated, false);
    },
    async onValidateAddressButtonClicked(): Promise<void> {
      this.isValidating = true;
      this.addressMessage = undefined;
      this.isAddressOptionDialogActive = false;
      this.ambiguousAddress = undefined;
      try {
        const addresses = await this.google.getAddressesFrom(this.address);
        const address = addresses.preferredAddress;
        if (address === undefined) throw new Error(`Could not retrieve address!`);
        const location = { lat: address.geometry.location.lat(), lng: address.geometry.location.lng() };
        const isTravelable = await this.google.isTravelable(location);
        const isAddressValidated = isTravelable && address.isPreciseAddress;
        if (isTravelable === false) {
          this.addressMessage = {
            message: 'その地点には車で移動できないため登録できません',
            type: VuetifyColors.Error,
            callback: this.onAddressMessageButtonClicked,
          };
        } else if (address.isPreciseAddress) {
          this.addressMessage = {
            message: `${address.address} の地点が登録されました。詳細は地図でも確認できます。`,
            type: VuetifyColors.Success,
            callback: this.onAddressMessageButtonClicked,
          };
        } else {
          this.ambiguousAddress = address;
          this.isMapConfirmationRequired = true;
        }
        if (isAddressValidated) {
          this.$emit(EventTypes.UpdateLatitude, address.geometry.location.lat());
          this.$emit(EventTypes.UpdateLongitude, address.geometry.location.lng());
        }
        this.$emit(EventTypes.UpdateIsAddressValidated, isAddressValidated);
      } catch (error) {
        this.addressMessage = {
          message: `住所が確認できませんでした。正しい住所になっているか確認して下さい。`,
          type: VuetifyColors.Error,
          callback: this.onAddressMessageButtonClicked,
        };
      }
      this.isValidating = false;
    },
    onCheckMapButtonClicked(): void {
      this.isMapConfirmationRequired = false;
      this.addressMessage = undefined;
      this.addressOption = undefined;
      this.isMapDialogActive = true;
    },
    onAddressMessageButtonClicked(): void {
      this.addressMessage = undefined;
    },
    onAddressValidatedOKButtonClicked(): void {
      this.isAddressValidatedDialogActive = false;
      this.isAddressOptionDialogActive = true;
    },
    onAddressOptionOKButtonClicked(): void {
      if (this.addressOption === undefined) throw new Error(`addressOption is undefined, almost impossible.`);
      // NOTE 地図上で指定された地点の住所は必ずしもそのまま登録可能とは限らない
      // 緯度経度は取得可能で車で移動可能であったとしても番地が取得できない地区の範囲を指している事があり、
      // この場合に address3 をそのまま書き戻してしまうと番地がなくなってしまい住所として登録ができない。
      // このため、address3 が空だった場合は「付近」という文字を埋める事で登録可能にしている。
      const address3 = this.addressOption.address3 === '' ? '付近' : this.addressOption.address3;
      if (this.addressOption.address3 === '') {
        this.addressMessage = {
          message: `指定された場所の住所が存在しないため“付近”と表記しています。`,
          type: VuetifyColors.Success,
          callback: this.onAddressMessageButtonClicked,
        };
      }

      this.postalCodeValue = this.addressOption.postalCode;
      this.address1Value = this.addressOption.address1;
      this.address2Value = this.addressOption.address2;
      this.address3Value = address3;
      this.$emit(EventTypes.UpdatePostalCode, this.addressOption.postalCode);
      this.$emit(EventTypes.UpdateAddress1, this.addressOption.address1);
      this.$emit(EventTypes.UpdateAddress2, this.addressOption.address2);
      this.$emit(EventTypes.UpdateAddress3, address3);
      this.addressOption = undefined;
      this.isAddressOptionDialogActive = false;
    },
    onAddressOptionCancelButtonClicked(): void {
      this.isAddressOptionDialogActive = false;
    },
    onChangeMapDialogActive(value: boolean, result: Maybe<MapDialogResultType>): void {
      if (value === false && result !== undefined) {
        if (result.inferredAddress !== undefined && this.address !== result.inferredAddress.address) {
          this.addressOption = result.inferredAddress;
          this.isAddressValidatedDialogActive = true;
        }
        this.$emit(EventTypes.UpdateLatitude, result.latitude);
        this.$emit(EventTypes.UpdateLongitude, result.longitude);
        this.$emit(EventTypes.UpdateIsAddressValidated, true);
      }
    },
  },
});
