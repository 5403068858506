import { ISnackbarData } from '~/framework/view-models/snackbarData';

export interface ISnackbarList {
  position: SnackbarListPosition;
  snackbars: ISnackbarData[];
}

export enum SnackbarListPosition {
  TopRight = 'top-right',
  BottomRight = 'bottom-right',
}
