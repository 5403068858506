import { Maybe } from '~/framework/typeAliases';
import { IJwnetWasteMasterData } from '~/framework/server-api/masters/jwnetWasteMaster';

export class JwnetWasteMasterEntity {
  readonly id: string;
  readonly persistentId: string;
  firstCategoryName: string;
  secondCategoryName: Maybe<string>;
  thirdCategoryName: Maybe<string>;
  code: string;
  isUsable: boolean;

  get firstCategoryCode(): string {
    return this.code.slice(0, 2);
  }

  get secondCategoryCode(): string {
    return this.code.slice(2, 3);
  }

  get thirdCategoryCode(): string {
    return this.code.slice(3, 4);
  }

  get fourthCategoryCode(): string {
    return this.code.slice(4, 7);
  }

  constructor(data: IJwnetWasteMasterData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.firstCategoryName = data.firstCategoryName;
    this.secondCategoryName = data.secondCategoryName;
    this.thirdCategoryName = data.thirdCategoryName;
    this.code = data.code;
    this.isUsable = data.isUsable;
  }

  update(data: IJwnetWasteMasterData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of JwnetWasteMasterEntity');
    }
    this.firstCategoryName = data.firstCategoryName;
    this.secondCategoryName = data.secondCategoryName;
    this.thirdCategoryName = data.thirdCategoryName;
    this.code = data.code;
    this.isUsable = data.isUsable;
  }
}
