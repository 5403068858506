import { Exception } from '~/framework/core/exception';

export const authentication$signOutSymbol = Symbol('authentication$signOutSymbol');

export interface ISignOutApi {
  [authentication$signOutSymbol]: void;

  /**
   * @throws SignOutException
   */
  signOut(): Promise<void>;
}

export class SignOutException extends Exception {
  readonly name = 'SignOutException';
}
