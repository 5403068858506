import { RoutingRegulationsByIds, AllRoutingRegulations } from '@/../graphql/server-api/queries/routing-regulation.gql';
import {
  CreateRoutingRegulation,
  UpdateRoutingRegulation,
  DeleteRoutingRegulation,
} from '@/../graphql/server-api/mutations/routing-regulation.gql';
import {
  RoutingRegulationsByIdsQuery,
  AllRoutingRegulationsQuery,
  CreateRoutingRegulationMutation,
  CreateRoutingRegulationMutationVariables,
  DeleteRoutingRegulationMutation,
  DeleteRoutingRegulationMutationVariables,
  UpdateRoutingRegulationMutation,
  UpdateRoutingRegulationMutationVariables,
} from '@/graphql/graphQLServerApi';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateData, IUpdateData } from '~/framework/server-api/masters/routingRegulation';
import { PersistentId } from '~/framework/typeAliases';

export class RoutingRegulationApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<RoutingRegulationsByIdsQuery>({
      query: RoutingRegulationsByIds,
      variables: { ids },
    });
    return result.routingRegulationsByIds;
  }

  async getAll() {
    const result = await this.query<AllRoutingRegulationsQuery>({
      query: AllRoutingRegulations,
      variables: {},
    });
    return result.allRoutingRegulations;
  }

  async create(data: ICreateData) {
    const result = await this.mutate<CreateRoutingRegulationMutation, CreateRoutingRegulationMutationVariables>({
      mutation: CreateRoutingRegulation,
      variables: { routingRegulation: data },
    });
    return result.createRoutingRegulation;
  }

  async update(data: IUpdateData) {
    const result = await this.mutate<UpdateRoutingRegulationMutation, UpdateRoutingRegulationMutationVariables>({
      mutation: UpdateRoutingRegulation,
      variables: { routingRegulation: data },
    });
    return result.updateRoutingRegulation;
  }

  async delete(id: PersistentId) {
    const result = await this.mutate<DeleteRoutingRegulationMutation, DeleteRoutingRegulationMutationVariables>({
      mutation: DeleteRoutingRegulation,
      variables: { id },
    });
    return result.deleteRoutingRegulation;
  }
}
