
import { Ripple } from 'vuetify/lib';
import Vue, { PropType } from 'vue';
import { CssClasses } from '~/framework/typeAliases';
import { hankanize } from '~/framework/services/string/string';
import { IContainerType } from '~/components/common/r-container-types/containerType';

type DataType = {
  isHovering: boolean;
};

enum EventTypes {
  ClickEdit = 'click:edit',
}

export default Vue.extend({
  name: 'RContainerType',
  directives: { Ripple },
  props: {
    isBeingEdited: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isLast: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    containerType: {
      type: Object as PropType<IContainerType>,
      required: true,
    },
  },
  data(): DataType {
    return {
      isHovering: false,
    };
  },
  computed: {
    hoverBackgroundClasses(): CssClasses {
      return {
        'r-container-type__hover-background': true,
        'r-container-type__hover-background--hover': this.isHovering,
      };
    },
    beingEditedBackgroundClasses(): CssClasses {
      return {
        'r-container-type__being-edited-background': true,
        'r-container-type__being-edited-background--being-edited': this.isBeingEdited,
      };
    },
    editIconColor(): string {
      return this.isBeingEdited ? 'primary' : 'bluish-gray_20';
    },
    packingStyleClasses(): CssClasses {
      return {
        'r-container-type__packing-style': true,
        'r-container-type__packing-style--same-packing-style': this.containerType.isSamePackingStyle,
      };
    },
    reservationSiteEnabledText(): string {
      return this.containerType.entity.reservationSiteEnabled ? '利用する' : '利用しない';
    },
  },
  methods: {
    hankanize,
    onMouseover(): void {
      this.isHovering = true;
    },
    onMouseleave(): void {
      this.isHovering = false;
    },
    onClickEdit(): void {
      this.$emit(EventTypes.ClickEdit, this.containerType);
    },
  },
});
