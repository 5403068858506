import { Maybe } from '~/framework/typeAliases';
import { IRouteEntity, IRouteEntityData } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { RouteComparison } from '~/framework/domain/schedule/schedule/pseudo-entities/routeComparison';

export interface IScheduleDiff {
  /**
   * 特定ルートに差分があるかどうかを取得する
   * 現存するルートからしか辿れない
   * これでいいのかは若干議論ありそうではあるが現状そういう仕様
   * 差分がないのであれば undefined
   * @param id
   */
  getRouteDiff(id: string): Maybe<IRouteDiff>;
}

export interface IRouteDiff {
  isCreated: boolean;

  /**
   * 回収に差分があるかどうかを取得する
   * ルート自体の順番をいじったりしていなくても回収を他人に移動してなくなっている回収があったりすると true
   */
  hasCollectionDiff: boolean;

  /**
   * 入れ換えていたりして順番が本来の位置にない場合に true
   * 新規のルートが前に差し込まれていても true になる
   */
  hasIndexDiff: boolean;

  getCollectionDiff(id: string): Maybe<ICollectionDiff>;
}

export interface ICollectionDiff {
  /**
   * 入れ換えていたりして順番が本来の位置にない場合に true
   * 他人の回収が前に差し込まれていても true になる
   */
  hasIndexDiff: boolean;
}

export class ScheduleDiffBuilder {
  buildDiffFrom(
    originalRoutes: IRouteEntityData<any, any, any>[],
    presentRoutes: IRouteEntity<any, any, any, any, any>[]
  ): IScheduleDiff {
    const originalDriverRouteMap = this.getDriverRoutesMap(originalRoutes);
    const presentDriverRouteMap = this.getDriverRoutesMap(presentRoutes);
    const diff = new ScheduleDiff();
    for (const driverId of presentDriverRouteMap.keys()) {
      const originalDriverRoutes = originalDriverRouteMap.get(driverId) || [];
      const presentDriverRoutes = presentDriverRouteMap.get(driverId) || [];
      const routeComparison = new RouteComparison(originalDriverRoutes, presentDriverRoutes);
      for (const [key, value] of routeComparison.diffs) {
        diff.setRouteDiff(key, value);
      }
    }

    return diff;
  }

  private getDriverRoutesMap<Route extends IRouteEntityData<any, any, any>>(routes: Route[]): Map<string, Route[]> {
    const driverRoutesMap: Map<string, Route[]> = new Map<string, Route[]>();
    for (const route of routes) {
      const driverRoutes = driverRoutesMap.get(route.driverId.value) || [];
      driverRoutes.push(route);
      driverRoutesMap.set(route.driverId.value, driverRoutes);
    }
    return driverRoutesMap;
  }
}

export class ScheduleDiff implements IScheduleDiff {
  private readonly routeDiffMap: Map<string, IRouteDiff>;

  constructor() {
    this.routeDiffMap = new Map<string, IRouteDiff>();
  }

  getRouteDiff(id: string): Maybe<IRouteDiff> {
    return this.routeDiffMap.get(id);
  }

  setRouteDiff(id: string, diff: IRouteDiff): void {
    this.routeDiffMap.set(id, diff);
  }
}

export class RouteDiff implements IRouteDiff {
  private readonly collectionDiffMap: Map<string, ICollectionDiff>;

  readonly isCreated: boolean;
  readonly hasCollectionDiff: boolean;
  readonly hasIndexDiff: boolean;

  constructor(isCreated: boolean, hasCollectionDiff: boolean, hasIndexDiff: boolean) {
    this.collectionDiffMap = new Map<string, ICollectionDiff>();
    this.isCreated = isCreated;
    this.hasCollectionDiff = hasCollectionDiff;
    this.hasIndexDiff = hasIndexDiff;
  }

  getCollectionDiff(id: string): Maybe<ICollectionDiff> {
    return this.collectionDiffMap.get(id);
  }

  setCollectionDiff(id: string, diff: ICollectionDiff): void {
    this.collectionDiffMap.set(id, diff);
  }
}

export class CollectionDiff implements ICollectionDiff {
  readonly hasIndexDiff: boolean;

  constructor(hasIndexDiff: boolean) {
    this.hasIndexDiff = hasIndexDiff;
  }
}
