import { Maybe } from '~/framework/typeAliases';
import { MarginType, PreloadStatus } from '~/framework/domain/typeAliases';
import { IOfficeSettingData } from '~/framework/server-api/masters/officeSetting';

export class OfficeSettingEntity {
  readonly id: string;
  readonly persistentId: string;

  defaultDurationAtBaseSite: number;
  defaultDurationAtGenerationSite: number;
  defaultDurationAtDisposalSite: number;
  defaultRestPeriodStartOfGenerationSite: Maybe<number>;
  defaultRestPeriodEndOfGenerationSite: Maybe<number>;
  defaultRestPeriodStartOfDisposalSite: Maybe<number>;
  defaultRestPeriodEndOfDisposalSite: Maybe<number>;

  defaultBannedDriverIdsAtGenerationSite: string[];
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: boolean;
  defaultAvoidHighways: boolean;
  isPackingPlacementManagementEnabled: boolean;
  isFixedArrivalTimeReportEnabled: boolean;
  defaultIsFixedArrivalTimeReportNeeded: boolean;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;

  isOnlineReservationEnabled!: boolean;
  isPublishingStatusOfScheduleManagementEnabled: boolean;
  canPrintSchedules: boolean;
  canUseErp: boolean;
  useCsvImport: boolean;
  useCsvExport: boolean;

  get hasDefaultRestPeriodOfGenerationSite(): boolean {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    return (
      this.defaultRestPeriodStartOfGenerationSite !== undefined &&
      this.defaultRestPeriodEndOfGenerationSite !== undefined
    );
  }

  get hasDefaultRestPeriodOfDisposalSite(): boolean {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    return (
      this.defaultRestPeriodStartOfDisposalSite !== undefined && this.defaultRestPeriodEndOfDisposalSite !== undefined
    );
  }

  constructor(data: IOfficeSettingData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.defaultDurationAtBaseSite = data.defaultDurationAtBaseSite;
    this.defaultDurationAtGenerationSite = data.defaultDurationAtGenerationSite;
    this.defaultDurationAtDisposalSite = data.defaultDurationAtDisposalSite;
    this.defaultRestPeriodStartOfGenerationSite = data.defaultRestPeriodStartOfGenerationSite;
    this.defaultRestPeriodEndOfGenerationSite = data.defaultRestPeriodEndOfGenerationSite;
    this.defaultRestPeriodStartOfDisposalSite = data.defaultRestPeriodStartOfDisposalSite;
    this.defaultRestPeriodEndOfDisposalSite = data.defaultRestPeriodEndOfDisposalSite;
    this.defaultBannedDriverIdsAtGenerationSite = data.defaultBannedDriverIdsAtGenerationSite;
    this.defaultPreloadStatus = data.defaultPreloadStatus;
    this.defaultRouteCollectionAllowed = data.defaultRouteCollectionAllowed;
    this.defaultAvoidHighways = data.defaultAvoidHighways;
    this.isPackingPlacementManagementEnabled = data.isPackingPlacementManagementEnabled;
    this.isFixedArrivalTimeReportEnabled = data.isFixedArrivalTimeReportEnabled;
    this.defaultIsFixedArrivalTimeReportNeeded = data.defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = data.defaultMarginTypeOfFixedArrivalTime;
    this.defaultMarginOfFixedArrivalTime = data.defaultMarginOfFixedArrivalTime;
    this.isOnlineReservationEnabled = data.isOnlineReservationEnabled;
    this.isPublishingStatusOfScheduleManagementEnabled = data.isPublishingStatusOfScheduleManagementEnabled;
    this.canPrintSchedules = data.canPrintSchedules;
    this.canUseErp = data.canUseErp;
    this.useCsvImport = data.useCsvImport;
    this.useCsvExport = data.useCsvExport;
  }

  update(data: IOfficeSettingData) {
    if (this.id !== data.id) {
      throw new Error('invalid update of OfficeSettingEntity');
    }
    this.defaultDurationAtBaseSite = data.defaultDurationAtBaseSite;
    this.defaultDurationAtGenerationSite = data.defaultDurationAtGenerationSite;
    this.defaultDurationAtDisposalSite = data.defaultDurationAtDisposalSite;
    this.defaultRestPeriodStartOfGenerationSite = data.defaultRestPeriodStartOfGenerationSite;
    this.defaultRestPeriodEndOfGenerationSite = data.defaultRestPeriodEndOfGenerationSite;
    this.defaultRestPeriodStartOfDisposalSite = data.defaultRestPeriodStartOfDisposalSite;
    this.defaultRestPeriodEndOfDisposalSite = data.defaultRestPeriodEndOfDisposalSite;
    this.defaultBannedDriverIdsAtGenerationSite = data.defaultBannedDriverIdsAtGenerationSite;
    this.defaultPreloadStatus = data.defaultPreloadStatus;
    this.defaultRouteCollectionAllowed = data.defaultRouteCollectionAllowed;
    this.defaultAvoidHighways = data.defaultAvoidHighways;
    this.isPackingPlacementManagementEnabled = data.isPackingPlacementManagementEnabled;
    this.isFixedArrivalTimeReportEnabled = data.isFixedArrivalTimeReportEnabled;
    this.defaultIsFixedArrivalTimeReportNeeded = data.defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = data.defaultMarginTypeOfFixedArrivalTime;
    this.defaultMarginOfFixedArrivalTime = data.defaultMarginOfFixedArrivalTime;
    this.isOnlineReservationEnabled = data.isOnlineReservationEnabled;
    this.isPublishingStatusOfScheduleManagementEnabled = data.isPublishingStatusOfScheduleManagementEnabled;
    this.canPrintSchedules = data.canPrintSchedules;
    this.canUseErp = data.canUseErp;
    this.useCsvImport = data.useCsvImport;
    this.useCsvExport = data.useCsvExport;
  }
}
