
import Vue from 'vue';
import _ from 'lodash';
import Draggable from 'vuedraggable';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { GenerationSitesByKeywordsCondition, GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';
import { required } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RoutingRegulationType } from '~/framework/domain/typeAliases';
import { RoutingRegulationEntity } from '~/framework/domain/masters/routing-regulation/routingRegulationEntity';
import {
  RoutingRegulationApplicationService,
  routingRegulationServiceSymbol,
} from '~/framework/application/masters/routing-regulation/routingRegulationApplicationService';

import { IllegalStateException } from '~/framework/core/exception';
// import { IGenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { GenerationSite } from '~/components/common/r-lazy-searchable-pulldown/generationSite';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  PageNames,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { ids } from '~/framework/core/entity';

enum FormMode {
  Register,
  Edit,
}

type FormValue = {
  name: string;
  generationSiteIds: string[];
  optionalGenerationSiteIds: Maybe<string>[];
  preserveGenerationSiteSequence: boolean;
};

type DataType = {
  /**
   * このパネルを表示したい時に true
   */
  drag: boolean;
  isActive: boolean;
  FormMode: typeof FormMode;
  isConfirmDialogActive: boolean;
  isDeleteConfirmDialogActive: boolean;
  isFormValid: boolean;
  rules: { [key: string]: ValidationRule };
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  routingRegulationApplicationService: RoutingRegulationApplicationService;
} & AsyncDataType;

type AsyncDataType = {
  viewModel?: IRoutingRegulationForm;
};

interface IRoutingRegulationForm {
  title: string;
  formMode: FormMode;
  formValue: FormValue;
  initialFormValue: FormValue;
  // 基本はIDのみで管理しているが、表示のために選択されているデータは保持する
  generationSiteSet: Set<AggregatedGenerationSiteEntity>;
  generationSiteDefaultCondition: GenerationSitesByKeywordsCondition;
  generationSiteLoader: GenerationSite;

  /**
   * 更新する時に使うEntity ( RoutingRegulationは、一つしかないので配列じゃなくても良い )
   */
  routingRegulationEntity: Maybe<RoutingRegulationEntity>;

  /**
   * 更新されたEntity
   */
  updatedRoutingRegulationEntity: Maybe<RoutingRegulationEntity>;

  /**
   * 登録されたEntity
   */
  createdRoutingRegulationEntity: Maybe<RoutingRegulationEntity>;

  /**
   * 削除されたEntity
   */
  deletedRoutingRegulationEntity: Maybe<RoutingRegulationEntity>;

  /**
   * 何かしら入力されていたら true
   */
  isDirty: boolean;

  /**
   * 登録中かどうか
   */
  isRegistering: boolean;
}

class RoutingRegulationForm implements IRoutingRegulationForm {
  title: string;
  formMode: FormMode;

  routingRegulationEntity: Maybe<RoutingRegulationEntity>;
  updatedRoutingRegulationEntity: Maybe<RoutingRegulationEntity>;
  createdRoutingRegulationEntity: Maybe<RoutingRegulationEntity>;
  deletedRoutingRegulationEntity: Maybe<RoutingRegulationEntity>;

  generationSiteDefaultCondition: GenerationSitesByKeywordsCondition;
  generationSiteLoader: GenerationSite;
  formValue: FormValue;
  initialFormValue: FormValue;
  generationSiteSet: Set<AggregatedGenerationSiteEntity>;

  isRegistering: boolean;

  get isDirty(): boolean {
    return (
      this.formValue.name !== this.initialFormValue.name ||
      _.isEqual(_.sortBy(this.formValue.generationSiteIds), _.sortBy(this.initialFormValue.generationSiteIds)) === false
    );
  }

  constructor(
    routingRegulation: Maybe<RoutingRegulationEntity>,
    generationSiteDefaultCondition: GenerationSitesByKeywordsCondition,
    generationSiteLoader: GenerationSite
  ) {
    this.routingRegulationEntity = routingRegulation;
    this.initialFormValue = this.getInitialFormValues(routingRegulation);
    this.formMode = this.routingRegulationEntity === undefined ? FormMode.Register : FormMode.Edit;
    this.title = this.formMode === FormMode.Register ? 'ルートの登録' : 'ルートの編集';
    this.generationSiteDefaultCondition = generationSiteDefaultCondition;
    this.generationSiteLoader = generationSiteLoader;
    this.updatedRoutingRegulationEntity = undefined;
    this.createdRoutingRegulationEntity = undefined;
    this.deletedRoutingRegulationEntity = undefined;
    this.isRegistering = false;

    this.formValue = {
      name: this.initialFormValue.name,
      generationSiteIds: [...this.initialFormValue.generationSiteIds],
      optionalGenerationSiteIds: [...this.initialFormValue.optionalGenerationSiteIds],
      preserveGenerationSiteSequence: this.initialFormValue.preserveGenerationSiteSequence,
    };
    const selectedGenerationSites = this.routingRegulationEntity ? this.routingRegulationEntity.generationSites : [];
    const selectedOptionalGenerationSites = this.routingRegulationEntity
      ? this.routingRegulationEntity.optionalGenerationSites
      : [];
    this.generationSiteSet = new Set([...selectedGenerationSites, ...selectedOptionalGenerationSites]);
  }

  private getDefaultInitialFormValues(): FormValue {
    return {
      name: '',
      // D&Dで並び替えをする都合上、要素に対しての key が必須かつindexは入れられないという状況になる
      // そのため、idとして存在していなければ負の値を入れることで対応している
      // idの生成ロジックに依存するのであまりいい対応ではないが他にいい方法が思いつかないので暫定
      generationSiteIds: ['-1'],
      optionalGenerationSiteIds: [],
      preserveGenerationSiteSequence: false,
    };
  }

  private getInitialFormValuesByRoutingRegulation(routingRegulation: RoutingRegulationEntity): FormValue {
    return {
      name: routingRegulation.name,
      generationSiteIds: ids(routingRegulation.generationSites),
      optionalGenerationSiteIds: ids(routingRegulation.optionalGenerationSites),
      preserveGenerationSiteSequence: routingRegulation.preserveGenerationSiteSequence,
    };
  }

  private getInitialFormValues(routingRegulation: Maybe<RoutingRegulationEntity>): FormValue {
    return routingRegulation === undefined
      ? this.getDefaultInitialFormValues()
      : this.getInitialFormValuesByRoutingRegulation(routingRegulation);
  }
}

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RRoutingRegulationForm',
  components: {
    Draggable,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },

  data(): DataType {
    const routingRegulationApplicationService = this.$context.applications.get(routingRegulationServiceSymbol);
    return {
      drag: false,
      isActive: false,
      FormMode,
      isConfirmDialogActive: false,
      isDeleteConfirmDialogActive: false,
      rules: { required },
      isFormValid: false,
      viewModel: undefined,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      routingRegulationApplicationService,
    };
  },
  computed: {
    isRegisterButtonDisabled(): boolean {
      return !this.isFormValid;
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.ROUTING_REGULATION;
    },
    validFormGenerationSiteIds(): string[] {
      if (!this.viewModel) return [];
      return this.viewModel.formValue.generationSiteIds.filter((id) => {
        return Number(id) >= 0;
      });
    },
    validFormOptionalGenerationSiteIds(): string[] {
      if (!this.viewModel) return [];
      return _.compact(this.viewModel.formValue.optionalGenerationSiteIds);
    },
    undefinedUniqueNumber(): number {
      if (!this.viewModel) return -1;
      return (
        Math.min(
          0,
          ...this.viewModel.formValue.generationSiteIds.map((id) => {
            return Number(id);
          })
        ) - 1
      );
    },
  },

  mounted() {
    const openEventListenerDisposer = this.$context.panels.routingRegulationFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.routingRegulationFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },

  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },

  methods: {
    /**
     * @public
     */
    async open(routingRegulation: Maybe<RoutingRegulationEntity> = undefined): Promise<void> {
      const generationSiteDefaultCondition: GenerationSitesByKeywordsCondition = {
        clientId: undefined,
        keywords: undefined,
        since: undefined,
        orderBy: GenerationSitesByKeywordsOrder.CreatedAtDesc,
      };
      const generationSiteLoader = new GenerationSite(this.$context, generationSiteDefaultCondition);
      this.viewModel = new RoutingRegulationForm(
        routingRegulation,
        generationSiteDefaultCondition,
        generationSiteLoader
      );

      await (this.$refs.RRoutingRegulationForm as any).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },

    /**
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RRoutingRegulationForm as any).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<RoutingRegulationEntity> = {
        entity: this.viewModel ? this.viewModel.routingRegulationEntity : undefined,
        updatedEntity: this.viewModel ? this.viewModel.updatedRoutingRegulationEntity : undefined,
        createdEntity: this.viewModel ? this.viewModel.createdRoutingRegulationEntity : undefined,
        removedEntity: this.viewModel ? this.viewModel.deletedRoutingRegulationEntity : undefined,
      };
      this.$context.panels.routingRegulationFormPanel.closeFormEvent.emit(closeFormArgs);
    },

    async onOpenForm(args: IOpenEntityFormArgs<RoutingRegulationEntity>): Promise<void> {
      await this.open(args.entity);
    },

    async onCloseForm(): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },

    onDeleteButtonClicked(): void {
      this.isDeleteConfirmDialogActive = true;
    },

    async onConfirmDelete(): Promise<void> {
      if (
        this.viewModel === undefined ||
        this.viewModel.routingRegulationEntity === undefined ||
        this.viewModel.formMode !== FormMode.Edit
      )
        return;

      const entity = this.viewModel.routingRegulationEntity;

      this.viewModel.isRegistering = true;
      await this.routingRegulationApplicationService.delete(this.viewModel.routingRegulationEntity.id);

      this.viewModel.deletedRoutingRegulationEntity = entity;
      this.viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.DELETE, {
        [AdditionalInfoKeys.ROUTING_REGULATION_ID]: this.viewModel.routingRegulationEntity.persistentId,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.ROUTING_REGULATION,
        [AdditionalInfoKeys.REFERRER]: PageNames.ROUTING_REGULATION_FORM,
      });

      this.$context.snackbar.success('ルートの削除完了');
      await this.close();
    },

    async onRegisterButtonClicked(): Promise<void> {
      if (this.viewModel === undefined) throw new IllegalStateException('viewModel is undefined');
      this.viewModel.isRegistering = true;

      if (this.viewModel.formMode === FormMode.Register) {
        const result = await this.routingRegulationApplicationService.create({
          name: this.viewModel.formValue.name,
          // TODO: generation site 以外を選択できるようになった時に対応する
          routingRegulationType: RoutingRegulationType.GenerationSite,
          generationSiteIds: this.validFormGenerationSiteIds,
          optionalGenerationSiteIds: this.validFormOptionalGenerationSiteIds,
          preserveGenerationSiteSequence: this.viewModel.formValue.preserveGenerationSiteSequence,
        });

        // NOTE: エラーの場合は snackbar を表示する
        if (typeof result === 'string') {
          this.$context.snackbar.error(result);
          this.viewModel.isRegistering = false;
          return;
        }

        const entity = result;

        this.viewModel.createdRoutingRegulationEntity = entity;
        this.onCreated(entity);
      } else {
        if (this.viewModel.routingRegulationEntity === undefined) {
          throw new IllegalStateException('routingRegulationEntity is undefined');
        }

        const result = await this.routingRegulationApplicationService.update({
          id: this.viewModel.routingRegulationEntity.id,
          name: this.viewModel.formValue.name,
          // TODO: generation site 以外を選択できるようになった時に対応する
          routingRegulationType: RoutingRegulationType.GenerationSite,
          generationSiteIds: this.validFormGenerationSiteIds,
          optionalGenerationSiteIds: this.validFormOptionalGenerationSiteIds,
          preserveGenerationSiteSequence: this.viewModel.formValue.preserveGenerationSiteSequence,
        });

        // NOTE: エラーの場合は snackbar を表示する
        if (typeof result === 'string') {
          this.$context.snackbar.error(result);
          this.viewModel.isRegistering = false;
          return;
        }

        const entity = result;

        this.viewModel.updatedRoutingRegulationEntity = entity;
        this.onUpdated(entity);
      }

      this.viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.ROUTING_REGULATION,
        [AdditionalInfoKeys.MODE]:
          this.viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },

    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },

    onCreated(_routingRegulationEntity: RoutingRegulationEntity): void {
      this.$context.snackbar.success('ルートの作成完了');
      this.close();
    },

    onUpdated(_routingRegulationEntity: RoutingRegulationEntity): void {
      this.$context.snackbar.success('ルートの編集完了');
      this.close();
    },

    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.routingRegulationFormPanel.updateDisplayedWidth(value);
    },
    onAddGenerationSiteButtonClick(type: 'required' | 'optional'): void {
      if (this.viewModel === undefined) return;
      if (
        this.viewModel.formValue.generationSiteIds.length + this.viewModel.formValue.optionalGenerationSiteIds.length >=
        50
      ) {
        this.$context.snackbar.error('排出場は合計で50件まで登録できます');
        return;
      }

      if (type === 'required') {
        this.viewModel.formValue.generationSiteIds.push(this.undefinedUniqueNumber.toString());
      }
      if (type === 'optional') {
        this.viewModel.formValue.optionalGenerationSiteIds.push(undefined);
      }
    },
    onRemoveItem(generationSiteIds: string[], targetIndex: number): void {
      if (this.viewModel === undefined) return;

      this.$delete(generationSiteIds, targetIndex);
    },
    onGenerationSiteChange(): void {
      // NOTE: 排出場が変更されるたびに validation を実行する
      const form = this.$refs.registerForm as any;
      form.validate();
    },
    isGenerationSiteAlreadySelected(targetId: string): boolean {
      const selectedIds = [...this.validFormGenerationSiteIds, ...this.validFormOptionalGenerationSiteIds];
      return (
        selectedIds.find((id) => {
          return id === targetId;
        }) !== undefined
      );
    },
    onUpdateGenerationSite(generationSite: Maybe<AggregatedGenerationSiteEntity>, index: number): void {
      if (!this.viewModel || !generationSite) return;

      const isAlreadySelected = this.isGenerationSiteAlreadySelected(generationSite.persistentId);
      if (isAlreadySelected) {
        this.$context.snackbar.warning('同一の排出場がすでに選択されています');
      }

      this.viewModel.formValue.generationSiteIds[index] = isAlreadySelected
        ? this.undefinedUniqueNumber.toString()
        : generationSite.persistentId;
      this.viewModel.formValue.generationSiteIds = [...this.viewModel.formValue.generationSiteIds]; // 更新処理を走らせるため
      this.updateGenerationSiteData(generationSite);
    },
    onUpdateOptionalGenerationSite(generationSite: Maybe<AggregatedGenerationSiteEntity>, index: number): void {
      if (!this.viewModel || !generationSite) return;

      const isAlreadySelected = this.isGenerationSiteAlreadySelected(generationSite.persistentId);
      if (isAlreadySelected) {
        this.$context.snackbar.warning('同一の排出場がすでに選択されています');
      }

      this.viewModel.formValue.optionalGenerationSiteIds[index] = isAlreadySelected
        ? undefined
        : generationSite.persistentId;
      this.viewModel.formValue.optionalGenerationSiteIds = [...this.viewModel.formValue.optionalGenerationSiteIds]; // 更新処理を走らせるため
      this.updateGenerationSiteData(generationSite);
    },
    updateGenerationSiteData(generationSite: Maybe<AggregatedGenerationSiteEntity>): void {
      if (!this.viewModel || !generationSite) return;

      this.viewModel.generationSiteSet.add(generationSite);
    },
    getGenerationSiteFromSetById(id: Maybe<string>): Maybe<AggregatedGenerationSiteEntity> {
      if (!this.viewModel || !id) return undefined;
      return [...this.viewModel.generationSiteSet].find((g) => {
        return g.persistentId === id;
      });
    },
  },
});
