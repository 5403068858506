
import Vue, { PropType } from 'vue';

/**
 * 基本的な挙動は VCheckbox の通り、見た目だけ異なる
 */
export default Vue.extend({
  name: 'RVCheckbox',
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    inputValue: {
      type: undefined as any as PropType<any>,
      required: false,
      default: null,
    },
  },
  methods: {
    onChange(value: any) {
      this.$emit('change', value);
    },
  },
});
