import { AggregatedReservationEntity } from '~/framework/domain/reservation/reservation/aggregatedReservationEntity';
import { OrderService, ValidateTaskType } from '~/framework/domain/schedule/order/orderService';

import {
  IAcceptReservationData,
  IAcceptReservationMutationResultData,
  reservation$acceptReservationSymbol,
} from '~/framework/server-api/reservation/acceptReservation';
import { reservation$reservationsByDateSymbol } from '~/framework/server-api/reservation/reservationsByDate';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import { ICreateOrderData } from '~/framework/server-api/schedule/order/createOrders';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { order$validateOrdersSymbol } from '~/framework/server-api/schedule/order/validateOrders';

import { RawRouteJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { reservation$cancelledReservationsByDateSymbol } from '~/framework/server-api/reservation/cancelledReservationsByDate';
import {
  IPushBackReservationFromCancelledToWaitingReviewData,
  IPushBackReservationFromCancelledToWaitingReviewMutationResultData,
  reservation$pushBackReservationFromCancelledToWaitingReviewSymbol,
} from '~/framework/server-api/reservation/pushBackReservationFromCancelledToWaitingReview';
import {
  ICancelReservationData,
  ICancelReservationMutationResultData,
  reservation$cancelReservationSymbol,
} from '~/framework/server-api/reservation/cancelReservation';
import { PersistentId } from '~/framework/typeAliases';
import { reservation$waitingReviewReservationDatesByDateRangeSymbol } from '~/framework/server-api/reservation/waitingReviewReservationByDateRange';
import { taskType$getAllSymbol } from '~/framework/server-api/masters/taskType';
import { reservation$reservationDeadlineByDateSymbol } from '~/framework/server-api/reservation/reservationDeadlineByDate';
import { IReservationDeadlineData } from '~/framework/server-api/reservation/reservation';
import { reservation$updateReservationDeadlineSymbol } from '~/framework/server-api/reservation/updateReservationDeadline';
import { ReservationDeadlineStatus } from '~/graphql/graphQLServerApi';
import { AggregatedReservationMapper } from '~/framework/domain/reservation/reservation/aggregatedReservationMapper';
import { Store } from '~/framework/domain/store';

export const reservationSymbol = Symbol('reservation');

export class ReservationApplicationService {
  private serverApis: ServerApiManager;
  private orderService: OrderService;
  private aggregatedReservationMapper: AggregatedReservationMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.orderService = new OrderService();
    this.aggregatedReservationMapper = new AggregatedReservationMapper(
      store.reservation.aggregatedReservation,
      store.masters.aggregatedGenerationSite,
      store.masters.aggregatedClient,
      store.masters.user,
      store.masters.collectablePeriodTemplate,
      store.masters.orderGroup,
      store.masters.aggregatedDisposalSite,
      store.masters.aggregatedWasteType,
      store.masters.aggregatedCar,
      store.masters.aggregatedBaseSite,
      store.masters.aggregatedDriver,
      store.masters.driverAttendanceTemplate,
      store.masters.jwnetWasteMaster,
      store.masters.aggregatedContainerType,
      store.masters.packingStyle
    );
  }

  async getByDate(date: Date): Promise<AggregatedReservationEntity[]> {
    const reservation$reservationByDateApi = this.serverApis.get(reservation$reservationsByDateSymbol);
    const reservationData = await reservation$reservationByDateApi.reservationsByDate(date);
    return this.aggregatedReservationMapper.map(reservationData);
  }

  async getCancelledReservationsByDate(date: Date): Promise<AggregatedReservationEntity[]> {
    const reservation$cancelledReservationsByDate = this.serverApis.get(reservation$cancelledReservationsByDateSymbol);
    const cancelledReservationData = await reservation$cancelledReservationsByDate.cancelledReservationsByDate(date);
    return this.aggregatedReservationMapper.map(cancelledReservationData);
  }

  async getWaitingReviewReservationDatesByDateRange(start: Date, end: Date): Promise<Date[]> {
    const reservation$waitingReviewReservationDatesByDateRangeApi = this.serverApis.get(
      reservation$waitingReviewReservationDatesByDateRangeSymbol
    );
    const waitingReviewDates =
      await reservation$waitingReviewReservationDatesByDateRangeApi.waitingReviewReservationDatesByDateRange(
        start,
        end
      );
    return waitingReviewDates;
  }

  async getReservationDeadline(date: Date): Promise<IReservationDeadlineData> {
    const reservation$reservationDeadlineByDateApi = this.serverApis.get(reservation$reservationDeadlineByDateSymbol);
    return await reservation$reservationDeadlineByDateApi.reservationDeadlineByDate(date);
  }

  async validateOrder(order: ICreateOrderData): Promise<RawScheduleJsonObject<RawRouteJsonObject>> {
    const validateOrderApi = this.serverApis.get(order$validateOrdersSymbol);
    const validateOrderData: ICreateOrderData = {
      ...order,
      attachmentsToAdd: [],
    };
    const [data] = await validateOrderApi.validateOrders([validateOrderData]);
    return data;
  }

  async acceptReservation(
    reservationId: PersistentId,
    orderData: ICreateOrderData
  ): Promise<IAcceptReservationMutationResultData> {
    this.orderService.validateTasks(ValidateTaskType.Create, orderData.generationSiteTasks, orderData.irregularTasks);
    this.orderService.validateAssignedDisposalSiteIds(
      orderData,
      await this.serverApis.get(taskType$getAllSymbol).getAll()
    );

    const reservation$acceptReservationApi = this.serverApis.get(reservation$acceptReservationSymbol);

    const acceptReservationData: IAcceptReservationData = {
      id: reservationId,
      order: orderData,
    };

    return await reservation$acceptReservationApi.acceptReservation(acceptReservationData);
  }

  async cancelReservation(reservationId: PersistentId): Promise<ICancelReservationMutationResultData> {
    const reservation$cancelReservationApi = this.serverApis.get(reservation$cancelReservationSymbol);

    const cancelReservationData: ICancelReservationData = {
      id: reservationId,
    };

    return await reservation$cancelReservationApi.cancelReservation(cancelReservationData);
  }

  async pushBackReservationFromCancelledToWaitingReview(
    reservationId: PersistentId
  ): Promise<IPushBackReservationFromCancelledToWaitingReviewMutationResultData> {
    const reservation$pushBackReservationFromCancelledToWaitingReviewApi = this.serverApis.get(
      reservation$pushBackReservationFromCancelledToWaitingReviewSymbol
    );

    const pushBackReservationFromCancelledToWaitingReviewData: IPushBackReservationFromCancelledToWaitingReviewData = {
      id: reservationId,
    };

    return await reservation$pushBackReservationFromCancelledToWaitingReviewApi.pushBackReservationFromCancelledToWaitingReview(
      pushBackReservationFromCancelledToWaitingReviewData
    );
  }

  async updateReservationDeadline(date: Date, status: ReservationDeadlineStatus): Promise<IReservationDeadlineData> {
    const reservation$updateReservationDeadlineApi = this.serverApis.get(reservation$updateReservationDeadlineSymbol);

    return await reservation$updateReservationDeadlineApi.updateReservationDeadline(date, status);
  }
}
