import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { AggregatedCarTypeContainerTypeMapper } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeMapper';
import { ICarTypeData } from '~/framework/server-api/masters/carType';
import { AggregatedContainerTypeStore } from '../container-type/aggregatedContainerTypeStore';
import { PackingStyleStore } from '../packing-style/packingStyleStore';

export class AggregatedCarTypeMapper extends StoredMapperBase<ICarTypeData, AggregatedCarTypeEntity> {
  protected store: AggregatedCarTypeStore;
  private orderGroupMapper: OrderGroupMapper;
  private carTypeContainerTypeMapper: AggregatedCarTypeContainerTypeMapper;

  constructor(
    store: AggregatedCarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    containerTypeStore: AggregatedContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.carTypeContainerTypeMapper = new AggregatedCarTypeContainerTypeMapper(
      carTypeContainerTypeStore,
      containerTypeStore,
      packingStyleStore
    );
  }

  protected instantiateWithData(data: ICarTypeData): AggregatedCarTypeEntity {
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const loadableContainerTypes = this.carTypeContainerTypeMapper.map(data.loadableContainerTypes);
    return new AggregatedCarTypeEntity(data, orderGroup, loadableContainerTypes);
  }

  protected updateWithData(data: ICarTypeData, entity: AggregatedCarTypeEntity): void {
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const loadableContainerTypes = this.carTypeContainerTypeMapper.map(data.loadableContainerTypes);
    entity.update(data, orderGroup, loadableContainerTypes);
  }
}
