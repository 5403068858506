import { ITypedEventContext, TypedEventContext } from '~/framework/events/typedEventContext';
import { IDisposable } from '~/framework/core/disposable';

export interface Listener<T> {
  (event: T, context: ITypedEventContext): Promise<void> | void;
}

/**
 * on と once の切り替えがあったり Disposable の仕組みがいいなと思ったので導入する。
 * https://typescript-jp.gitbook.io/deep-dive/main-1/typed-event
 */
export interface ITypedAsyncEvent<T> {
  /**
   * リスナを登録する。
   * 返り値の Disposable#dispose を呼ぶ事でリスナを削除できる。
   * いつまでも呼ばれない様に off するか dispose する事を忘れない事。
   * @param listener
   * @param priority リスナーのレイヤー（0 = 最も優先順位が低い）
   */
  on(listener: Listener<T>, priority?: number): IDisposable;

  /**
   * 一度限りで削除されるリスナを登録する。
   * 現状では oncer の方が普通のリスナよりも優先される。
   * @param listener
   * @param priority リスナーのレイヤー（0 = 最も優先順位が低い）
   */
  once(listener: Listener<T>, priority?: number): void;

  /**
   * リスナを削除する。
   * @param listener
   */
  off(listener: Listener<T>): void;

  /**
   * イベントを発火する。
   * @param event
   */
  emit(event: T): Promise<void>;

  /**
   * このイベントが発火すると別のイベントを発火する様にパイプする。
   * @param another
   */
  pipe(another: TypedAsyncEvent<T>): IDisposable;
}

export class TypedAsyncEvent<T> implements ITypedAsyncEvent<T> {
  private listeners: Listener<T>[] = [];
  private listenersOncer: Listener<T>[] = [];
  private listenerLayerMap: Map<Listener<T>, number> = new Map<Listener<T>, number>();

  on(listener: Listener<T>, priority: number = 0): IDisposable {
    this.listeners.push(listener);
    this.listenerLayerMap.set(listener, priority);
    this.sortListeners(this.listeners);
    return {
      dispose: () => this.off(listener),
    };
  }

  once(listener: Listener<T>, priority: number = 0): void {
    this.listenersOncer.push(listener);
    this.listenerLayerMap.set(listener, priority);
    this.sortListeners(this.listenersOncer);
  }

  off(listener: Listener<T>): void {
    const callbackIndex = this.listeners.indexOf(listener);
    if (-1 < callbackIndex) this.listeners.splice(callbackIndex, 1);
    this.listenerLayerMap.delete(listener);
  }

  async emit(event: T): Promise<void> {
    const context = new TypedEventContext();
    for (const listener of this.listenersOncer) {
      if (context.isStopped === false) await listener(event, context);
      this.listenerLayerMap.delete(listener);
    }
    this.listenersOncer = [];
    if (context.isStopped) return;

    for (const listener of this.listeners) {
      if (context.isStopped === false) await listener(event, context);
      if (context.isStopped) break;
    }
  }

  pipe(another: TypedAsyncEvent<T>): IDisposable {
    return this.on(async (e) => await another.emit(e));
  }

  private sortListeners(listeners: Listener<T>[]): void {
    listeners.sort((a, b) => {
      return this.listenerLayerMap.getOrError(b) - this.listenerLayerMap.getOrError(a);
    });
  }
}
