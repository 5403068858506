
import Vue, { PropType } from 'vue';
import { Ripple } from 'vuetify/lib';
import { InputMessage } from 'vuetify';
import { IRLazySearchablePulldownEntity } from './rLazySearchablePulldown';
import { Maybe, MilliSeconds, ValidationRule } from '~/framework/typeAliases';
import { ILazySearchCondition, ILazySearchLoader } from '~/components/common/r-lazy-searchable-pulldown/lazySearch';
import RLazyLoadVAutocomplete from '~/components/common/r-lazy-searchable-pulldown/RLazyLoadVAutocomplete.vue';
import { wait } from '~/framework/async';

type DataType = {
  isMounted: boolean;
  items: IRLazySearchablePulldownEntity[];
  searchInput: string;
  isLoading: boolean;
  searchScheduledAt: Maybe<number>;
};

/**
 * キー入力をしてからどれくらい経っていたら検索を行うか
 */
const searchDelay: MilliSeconds = 300;

/**
 * 既に検索を実行中だったり既に検索がスケジュールされていた場合に同期するために待つ時間
 * 特に根拠はなくとりあえず負荷を減らすために 10 としている
 */
const waitTimeOnSynchronization: MilliSeconds = 10;

enum EventTypes {
  Input = 'input',
  Change = 'change',
  Mount = 'mount',
  Unmount = 'unmount',
  UpdateValueItem = 'update:value-item',
}

/**
 * 内部的に別オブジェクトに詰め替えているので return-object は実質的に動作しないためサポート外とした
 */
export default Vue.extend({
  name: 'RLazySearchablePulldown',
  directives: { Ripple },
  components: {
    RLazyLoadVAutocomplete,
  },
  model: {
    event: EventTypes.Change,
    prop: 'value',
  },
  props: {
    loader: {
      type: Object as PropType<ILazySearchLoader<any, any>>,
      required: true,
    },
    defaultCondition: {
      type: Object as PropType<ILazySearchCondition>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    persistentPlaceholder: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    value: {
      type: undefined as any as PropType<any>,
      required: false,
      default: undefined,
    },
    /**
     * value が示すアイテム自体を指定する。
     * これが必要なのは、ストリーミングで読み込みをさせると value で指定されている先のアイテムがまだ読み込まれて
     * いないという状態があり得てしまうため。value に何かを指定していて、その指し示すアイテムがないとフィールドが
     * 空で表示されてしまう。value が更新される（change）と valueItem も同時に更新（update:value-item）されるが、
     * この時 valueItem が先に更新されて value が後から更新される仕様になっている。
     */
    valueItem: {
      type: Object as PropType<IRLazySearchablePulldownEntity>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    autoSelectFirst: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    rules: {
      type: Array as PropType<Array<ValidationRule>>,
      required: false,
      default() {
        return [];
      },
    },
    itemText: {
      type: String as PropType<string>,
      required: false,
      default: 'name',
    },
    itemValue: {
      type: String as PropType<string>,
      required: false,
      default: 'id',
    },
    hideDetails: {
      type: undefined as any as PropType<String | boolean>,
      required: false,
      default: false,
    },
    hint: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    persistentHint: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
    messages: {
      type: [String, Array] as PropType<InputMessage>,
      default: () => [],
    },
    errorMessages: {
      type: [String, Array] as PropType<InputMessage>,
      default: () => [],
    },
    errorCount: {
      type: [Number, String],
      default: 1,
    },
    hideNoData: {
      type: Boolean,
      default: true,
    },
  },
  data(): DataType {
    return {
      isMounted: false,
      items: [],
      searchInput: '',
      isLoading: false,
      searchScheduledAt: undefined,
    };
  },
  watch: {
    valueItem(value: Maybe<IRLazySearchablePulldownEntity>, oldValue: Maybe<IRLazySearchablePulldownEntity>) {
      /**
       * valueItemが更新されたときに、itemsにvalueItemと同じ項目が入っていない場合、UI上選択されていない状態になるため、
       * valueItemの項目がitemsになければ追加する
       */
      if (
        value !== undefined &&
        value !== oldValue &&
        !this.items.find(
          (item: Record<string, any>) => item[this.itemValue] === (value as Record<string, any>)[this.itemValue]
        )
      ) {
        this.items.push(value);
      }
    },
    value(value: Maybe<string | string[]>, oldValue: Maybe<string | string[]>): void {
      if (value === oldValue) return;
      if (value === undefined) {
        this.resetItems();
      }
    },
    async defaultCondition(value: ILazySearchCondition): Promise<void> {
      this.setCondition(value);
      this.resetItems();
      await this.search();
    },
    async searchInput(): Promise<void> {
      // NOTE searchInput はアイテムを選択するとそのアイテムの名前自体に変更される。
      // この時検索をかけると無駄なリクエストが飛ぶ上にその名前だけのリストが返ってくるので
      // 選択肢が全て消える事になる。この挙動は気持ち悪いので名前が選択されたアイテムと一緒
      // なのであれば検索はかけない事にする。
      if (this.searchInput === (this.valueItem as any)?.[this.itemText]) return;
      await this.search();
    },
    items(): void {
      if (this.autoSelectFirst && this.items.length === 1) {
        const first = this.items[0] as any;
        this.emitChange(first[this.itemValue], first);
      }
    },
  },
  mounted(): void {
    this.isMounted = true;
    this.$emit(EventTypes.Mount, this);
  },
  beforeDestroy(): void {
    this.isMounted = false;
    this.$emit(EventTypes.Unmount, this);
  },
  methods: {
    onChange(value: any): void {
      // NOTE: clear されると null が入ってくるため undefined に変換する
      const sanitizedValue = value === null ? undefined : value;
      this.filterDeletedItems(value);
      const valueItem =
        sanitizedValue === undefined
          ? undefined
          : this.items.find((item: any) => item[this.itemValue] === sanitizedValue);

      this.emitChange(sanitizedValue, valueItem);
    },
    onClick(event: Event): void {
      const target = this.$refs.VAutoComplete as any;
      if (target === undefined || !target.isMenuActive) return;
      // クリック時にプルダウンが開いている場合は入力を空にして閉じる
      // クリックしたプルダウンが一瞬閉じてから再度開いてしまうため必要
      event.stopPropagation();
      target.blur();
      this.searchInput = '';
    },
    filterDeletedItems(value: any): void {
      // 論理削除されているものはまた選択する意味はないので isDeleted でかつ選択状態にないアイテムは除外する
      // ここで items を filter するともう復活する事はできないのだが、削除されたものは復活できなくてよいので大丈夫
      this.items = this.items.filter((item: any) => !(item.isDeleted && value !== item[this.itemValue]));
    },
    reset(): void {
      this.emitChange(undefined, undefined);
      (this.$refs.VAutoComplete as any).reset();
    },
    /**
     * 値の変更は valueItem を変更した後で value を変更するという仕様にしているため、必ずここを通すこと。
     * また、実際に value が変更されていないタイミングで emit してしまうと利用している画面側で
     * 値が変更されたものとして処理が走ってしまうため、問題になる事があり、value が実質的に変化して
     * いない場合にはイベントを発生させない様にしている。
     */
    emitChange(value: Maybe<any>, valueItem: Maybe<any>): void {
      if (value === this.value) return;
      this.$emit(EventTypes.UpdateValueItem, valueItem);
      this.$emit(EventTypes.Change, value);
    },
    async search(): Promise<void> {
      // 既に検索を実行中だった場合は、それが終わるまで待つ
      while (this.isLoading) {
        await wait(waitTimeOnSynchronization);
      }

      if (this.searchScheduledAt !== undefined) {
        // 既に検索がスケジュールされている場合は延長した上でなにもしない

        this.searchScheduledAt = new Date().getTime();
        return;
      }

      this.searchScheduledAt = new Date().getTime();

      if (this.searchInput) {
        while (true) {
          await wait(waitTimeOnSynchronization);
          if (this.searchScheduledAt + searchDelay <= new Date().getTime()) break;
        }
      }
      const keywords = this.searchInput ? this.searchInput.split(' ') : [];
      const condition = {
        ...this.defaultCondition,
        keywords,
      };
      this.setCondition(condition);
      this.resetItems();

      await this.loadItems();

      // 終わったのでリセットしておく
      this.searchScheduledAt = undefined;
    },
    setCondition(condition: ILazySearchCondition): void {
      const key: keyof ILazySearchLoader<any, any> = 'condition';
      this.$set(this.loader, key, condition);
    },
    async onReachEndOfList(): Promise<void> {
      await this.loadItems();
    },
    resetItems(): void {
      const items = [];
      // value に指定されているものは items に存在していないとフィールドが空になってしまう一方で
      // value に指定されているものと valueItem が仮に別物だった場合、それを選択肢に追加してしまうと
      // 検索条件と合致せず意味が分からない表示になるので消しておく。
      if (this.valueItem !== undefined && (this.valueItem as any)[this.itemValue] === this.value) {
        items.push(this.valueItem);
      }
      this.items = items;
      this.updateMenuDimensions();
    },
    updateMenuDimensions(): void {
      // resetItems を呼ぶ元は非同期関数である場合がある。resetItems を呼ぶ元が実行開始された時には
      // DOM が存在していても、非同期関数を await している間にパネルを閉じたりすると DOM 上は存在しなく
      // なっている可能性がある。この様な場合に $refs を参照すると問題になるので mounted〜beforeDestroy
      // を alive な期間としてそれ以外の場合は何もしない様にする
      if (this.isMounted === false) return;
      (this.$refs.VAutoComplete as any).updateMenuDimensions();
    },
    async loadItems(): Promise<void> {
      this.isLoading = true;
      const result = await this.loader.loadItems();

      this.items.push(...result.items);
      this.isLoading = false;
    },
    isDeleted(item: IRLazySearchablePulldownEntity): boolean {
      return item.isDeleted || false;
    },
  },
});
