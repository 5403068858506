import { StoredMapperBase } from '~/framework/core/mapper';
import { PackingStyleTaskTypeDefaultEntity } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultEntity';
import { PackingStyleTaskTypeDefaultStore } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultStore';
import { IPackingStyleTaskTypeDefaultData } from '~/framework/server-api/masters/packingStyleTaskTypeDefault';

export class PackingStyleTaskTypeDefaultMapper extends StoredMapperBase<
  IPackingStyleTaskTypeDefaultData,
  PackingStyleTaskTypeDefaultEntity
> {
  protected store: PackingStyleTaskTypeDefaultStore;

  constructor(store: PackingStyleTaskTypeDefaultStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IPackingStyleTaskTypeDefaultData): PackingStyleTaskTypeDefaultEntity {
    return new PackingStyleTaskTypeDefaultEntity(data);
  }

  protected updateWithData(data: IPackingStyleTaskTypeDefaultData, entity: PackingStyleTaskTypeDefaultEntity): void {
    entity.update(data);
  }
}
