import { StoredMapperBase } from '~/framework/core/mapper';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';
import { ICollectablePeriodTemplateData } from '~/framework/server-api/masters/collectablePeriodTemplate';

export class CollectablePeriodTemplateMapper extends StoredMapperBase<
  ICollectablePeriodTemplateData,
  CollectablePeriodTemplateEntity
> {
  protected store: CollectablePeriodTemplateStore;

  constructor(store: CollectablePeriodTemplateStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: ICollectablePeriodTemplateData): CollectablePeriodTemplateEntity {
    return new CollectablePeriodTemplateEntity(data);
  }

  protected updateWithData(data: ICollectablePeriodTemplateData, entity: CollectablePeriodTemplateEntity): void {
    entity.update(data);
  }
}
