import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedDriverStore } from '~/framework/domain/masters/driver/aggregatedDriverStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { DriverAttendanceTemplateMapper } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateMapper';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { IDriverData } from '~/framework/server-api/masters/driver';
import { SimpleCarTypeEntity } from '../car-type/aggregatedCarTypeEntity';

export class AggregatedDriverMapper extends StoredMapperBase<IDriverData, AggregatedDriverEntity> {
  protected store: AggregatedDriverStore;
  private carMapper: AggregatedCarMapper;
  private driverAttendanceTemplateMapper: DriverAttendanceTemplateMapper;

  constructor(
    store: AggregatedDriverStore,
    carStore: AggregatedCarStore,
    baseSiteStore: AggregatedBaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.carMapper = new AggregatedCarMapper(carStore, baseSiteStore, userStore);
    this.driverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(driverAttendanceTemplateStore);
  }

  protected instantiateWithData(data: IDriverData): AggregatedDriverEntity {
    const defaultPrimaryCar = data.defaultPrimaryCar ? this.carMapper.mapSingle(data.defaultPrimaryCar) : undefined;
    const licensedCarTypes = data.licensedCarTypes.map((carTypeData) => new SimpleCarTypeEntity(carTypeData));
    const defaultAttendanceTemplate = data.defaultAttendanceTemplate
      ? this.driverAttendanceTemplateMapper.mapSingle(data.defaultAttendanceTemplate)
      : undefined;

    return new AggregatedDriverEntity(data, defaultPrimaryCar, licensedCarTypes, defaultAttendanceTemplate);
  }

  protected updateWithData(data: IDriverData, entity: AggregatedDriverEntity): void {
    const defaultPrimaryCar = data.defaultPrimaryCar ? this.carMapper.mapSingle(data.defaultPrimaryCar) : undefined;
    const licensedCarTypes = data.licensedCarTypes.map((carTypeData) => new SimpleCarTypeEntity(carTypeData));
    const defaultAttendanceTemplate = data.defaultAttendanceTemplate
      ? this.driverAttendanceTemplateMapper.mapSingle(data.defaultAttendanceTemplate)
      : undefined;
    entity.update(data, defaultPrimaryCar, licensedCarTypes, defaultAttendanceTemplate);
  }
}
