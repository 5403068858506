import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  officeSetting$getSymbol,
  IUpdateData,
  officeSetting$updateSymbol,
} from '~/framework/server-api/masters/officeSetting';
import { OfficeSettingMapper } from '~/framework/domain/masters/office-setting/officeSettingMapper';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';

export const officeSettingSymbol = Symbol('officeSettingSymbol');
export class OfficeSettingApplicationService {
  private readonly serverApis: ServerApiManager;
  private officeSettingMapper: OfficeSettingMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.officeSettingMapper = new OfficeSettingMapper(store.masters.officeSetting);
  }

  async update(data: IUpdateData): Promise<OfficeSettingEntity> {
    const officeSetting$updateApi = this.serverApis.get(officeSetting$updateSymbol);
    await officeSetting$updateApi.update(data);
    return await this.get();
  }

  async get(): Promise<OfficeSettingEntity> {
    const officeSetting$getApi = this.serverApis.get(officeSetting$getSymbol);
    const result = await officeSetting$getApi.get();
    return this.officeSettingMapper.mapSingle(result);
  }
}
