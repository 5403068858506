import { Maybe, Seconds } from '~/framework/typeAliases';

import { getHoursAndMinutesOf } from '~/framework/services/date-time/date-time';

export interface IGenerationSiteTaskItem {
  /**
   * タスクの内容が有効かどうか
   * 例えばコンテナ種類が必要だが設定されていない様な場合には false となる
   */
  isValid(): boolean;
}

export interface IGenerationSiteTaskDuration<IGenerationSiteTaskItem> {
  /**
   * 排出場での合計所要時間・時（直接編集しない）
   * undefined の場合はタスク内容が不正なので編集できない場合
   */
  readonly hoursAtGenerationSite: Maybe<number>;

  /**
   * 排出場での合計所要時間・分（直接編集しない）
   * undefined の場合はタスク内容が不正なので編集できない場合
   */
  readonly minutesAtGenerationSite: Maybe<number>;

  /**
   * 排出場の合計作業時間（入退場時間 + コンテナなどの作業時間）（直接編集しない）
   * undefined の場合はタスク内容が不正なので編集できない場合
   */
  readonly durationAtGenerationSite: Maybe<Seconds>;

  /**
   * 排出場のデフォルトの合計作業時間（デフォルト入退場時間 + コンテナなどの作業時間）（直接編集しない）
   * undefined の場合はタスク内容が不正なので編集できない場合
   */
  readonly defaultDurationAtGenerationSite: Maybe<Seconds>;

  /**
   * タスクの時間（秒、編集不可）
   * 自動計算される
   */
  readonly durationOfTasks: Maybe<number>;

  /**
   * 手動でいじって設定されている排出場の合計作業時間
   */
  readonly fixedDurationAtGenerationSite: Maybe<Seconds>;

  /**
   * デフォルトの入退場時間を設定する
   * これはタスクなどを設定する前に指定しておかないとおかしくなるので注意
   * @param value
   */
  setDefaultDurationAtEntrance(value: Seconds): void;

  /**
   * 排出場の合計作業時間（秒）を設定する
   * 編集画面を最初に開いた時しか使わない
   * @param value 新規作成画面を開いた時は undefined
   */
  setDurationAtGenerationSite(value: Maybe<Seconds>): void;

  /**
   * 現状のタスク状態に応じて durationOfTasks を再計算する。
   * それに応じて durationAtGenerationSite を更新しようとするが、setHoursAtGenerationSite や setMinutesAtGenerationSite
   * を呼んでいて fixedDurationAtGenerationSite が設定されている場合は更新しない。そうでなければ更新される。
   * @param tasks
   */
  recalculateDuration(tasks: IGenerationSiteTaskItem[]): void;

  /**
   * 排出場での合計作業時間（時）を設定する
   * これを呼ぶと手動で固定したと見なして fixedDurationAtGenerationSite が設定される
   * @param value
   * @return 現場の作業時間未満に設定しているかどうか
   */
  setHoursAtGenerationSite(value: number): boolean;

  /**
   * 排出場での合計作業時間（分）を設定する
   * これを呼ぶと手動で固定したと見なして fixedDurationAtGenerationSite が設定される
   * @param value
   * @return 現場の作業時間未満に設定しているかどうか
   */
  setMinutesAtGenerationSite(value: number): boolean;

  /**
   * 手入力されている合計作業時間をリセットしてデフォルトの入退場時間 + 作業時間から計算された値にする
   */
  resetHoursAndMinutes(): void;
}

export abstract class GenerationSiteTaskDurationBase<Task extends IGenerationSiteTaskItem>
  implements IGenerationSiteTaskDuration<Task>
{
  private defaultDurationAtEntrance: number;
  durationOfTasks: Maybe<number>;
  hoursAtGenerationSite: Maybe<number>;
  minutesAtGenerationSite: Maybe<number>;
  durationAtGenerationSite: Maybe<Seconds>;
  fixedDurationAtGenerationSite: Maybe<Seconds>;
  defaultDurationAtGenerationSite: Maybe<Seconds>;

  constructor() {
    this.defaultDurationAtEntrance = 0;
    this.durationOfTasks = undefined;
    this.hoursAtGenerationSite = undefined;
    this.minutesAtGenerationSite = undefined;
    this.durationAtGenerationSite = undefined;
    this.fixedDurationAtGenerationSite = undefined;
    this.defaultDurationAtGenerationSite = undefined;
  }

  setDefaultDurationAtEntrance(value: Seconds) {
    this.defaultDurationAtEntrance = value;
    if (this.durationOfTasks !== undefined) {
      this.defaultDurationAtGenerationSite = this.durationOfTasks + value;
      if (this.fixedDurationAtGenerationSite === undefined) {
        this.updateDurations(this.defaultDurationAtGenerationSite);
      }
    } else {
      this.defaultDurationAtGenerationSite = undefined;
    }
  }

  setDurationAtGenerationSite(value: Maybe<Seconds>) {
    this.fixedDurationAtGenerationSite = value;
    this.durationAtGenerationSite = value;
    if (value !== undefined) {
      const [hours, minutes] = getHoursAndMinutesOf(value);
      this.hoursAtGenerationSite = hours;
      this.minutesAtGenerationSite = minutes;
    }
  }

  recalculateDuration(tasks: IGenerationSiteTaskItem[]): void {
    const invalidTasks = tasks.filter((task) => task.isValid() === false);
    if (invalidTasks.length === 0) {
      const cumulativeDuration = this.getDurationOfTasks(tasks);
      this.durationOfTasks = Math.floor(cumulativeDuration);
      this.defaultDurationAtGenerationSite = this.durationOfTasks + this.defaultDurationAtEntrance;
      this.updateDurations(this.defaultDurationAtGenerationSite);
    } else {
      // 無効なタスクがあるので入力不可
      this.defaultDurationAtGenerationSite = undefined;
      this.hoursAtGenerationSite = undefined;
      this.minutesAtGenerationSite = undefined;
      this.durationAtGenerationSite = undefined;
      this.durationOfTasks = undefined;
    }
  }

  setHoursAtGenerationSite(value: number): boolean {
    if (this.hoursAtGenerationSite === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    if (this.minutesAtGenerationSite === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    if (this.durationOfTasks === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    if (this.defaultDurationAtGenerationSite === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    const hours = value * 60 * 60;
    const minutes = this.minutesAtGenerationSite * 60;
    const lessThanDurationOfTasks = hours + minutes < this.durationOfTasks;
    // わざわざこうしているのは、500分とかを入力された時に時間と分に数値を割り振るため
    this.fixedDurationAtGenerationSite = hours + minutes;
    this.updateDurations(this.defaultDurationAtGenerationSite);
    return lessThanDurationOfTasks;
  }

  setMinutesAtGenerationSite(value: number): boolean {
    if (this.hoursAtGenerationSite === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    if (this.minutesAtGenerationSite === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    if (this.durationOfTasks === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    if (this.defaultDurationAtGenerationSite === undefined) throw new Error(`Cannot modify when tasks have errors!`);
    const hours = this.hoursAtGenerationSite * 60 * 60;
    const minutes = value * 60;
    const lessThanDurationOfTasks = hours + minutes < this.durationOfTasks;
    // わざわざこうしているのは、500分とかを入力された時に時間と分に数値を割り振るため
    this.fixedDurationAtGenerationSite = hours + minutes;
    this.updateDurations(this.defaultDurationAtGenerationSite);
    return lessThanDurationOfTasks;
  }

  resetHoursAndMinutes(): void {
    if (this.durationOfTasks === undefined) return;
    // 作業時間と排出場デフォルトの時間の合計で再計算
    const durationAtGenerationSite = this.durationOfTasks + this.defaultDurationAtEntrance;
    const [hours, minutes] = getHoursAndMinutesOf(durationAtGenerationSite);
    this.hoursAtGenerationSite = hours;
    this.minutesAtGenerationSite = minutes;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.fixedDurationAtGenerationSite = undefined;
  }

  /**
   * 作業の実時間を取得する
   * 具体クラスで各々実装
   * @param tasks
   */
  protected abstract getDurationOfTasks(tasks: IGenerationSiteTaskItem[]): number;

  private updateDurations(defaultDurationAtGenerationSite: Seconds): void {
    if (this.fixedDurationAtGenerationSite === undefined) {
      const [hours, minutes] = getHoursAndMinutesOf(defaultDurationAtGenerationSite);
      this.hoursAtGenerationSite = hours;
      this.minutesAtGenerationSite = minutes;
      this.durationAtGenerationSite = defaultDurationAtGenerationSite;
    } else {
      const [hours, minutes] = getHoursAndMinutesOf(this.fixedDurationAtGenerationSite);
      this.hoursAtGenerationSite = hours;
      this.minutesAtGenerationSite = minutes;
      this.durationAtGenerationSite = this.fixedDurationAtGenerationSite;
    }
  }
}
