import { Maybe, Pixel } from '~/framework/typeAliases';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';
import { IPanelState } from '~/framework/view-models/panels/panelState';
import {
  ICloseEntityFormArgs,
  CloseHandler,
  IEntityFormPanel,
  IOpenEntityFormArgs,
} from '~/framework/view-models/panels/entityFormPanel';
import { ITypedAsyncEvent, TypedAsyncEvent } from '~/framework/events/typedAsyncEvent';
import { IOpenPanelOption, IUpdateDisplayedWidthEvent } from '~/framework/view-models/panels/panel';
import { IPersistentEntity } from '~/framework/core/entity';

/**
 * マスターなどをキャッシュできる可能性があるパネルに利用するオプション
 */
export interface ICacheablePanelOption {
  /**
   * あるなら既にキャッシュされているデータを利用する
   */
  useCache?: boolean;
}

/**
 * 初期値を設定したいときに利用するオプション
 */
export interface IInitializablePanelOption<FormValues> {
  /**
   * 指定された値をフォームの初期値としてセットする
   */
  initialFormValues?: FormValues;
}

/**
 * タイトルを独自に設定したときに利用するオプション
 */
export interface IEntitlablePanelOption {
  /**
   * 指定した title が設定される
   */
  formTitle?: string;
}

export abstract class AbstractEntityFormPanel<Data extends IPersistentEntity, Option extends IOpenPanelOption = never>
  implements IEntityFormPanel<Data, Option>
{
  private readonly panelState: IPanelState;
  private closeHandler: Maybe<CloseHandler>;

  readonly openFormEvent: ITypedAsyncEvent<IOpenEntityFormArgs<Data, Option>>;
  readonly closeFormEvent: ITypedEvent<ICloseEntityFormArgs<Data>>;
  readonly updateDisplayedWidthEvent: ITypedEvent<IUpdateDisplayedWidthEvent>;
  editingEntity: Maybe<Data>;

  constructor(panelState: IPanelState) {
    this.editingEntity = undefined;
    this.panelState = panelState;
    this.openFormEvent = new TypedAsyncEvent();
    this.closeFormEvent = new TypedEvent();
    this.updateDisplayedWidthEvent = new TypedEvent();
    const closeListener = this.onClose.bind(this);
    this.closeFormEvent.on(closeListener);
  }

  registerCloseHandler(closeHandler: CloseHandler) {
    this.closeHandler = closeHandler;
  }

  async open(entity: Maybe<Data>, option?: Option): Promise<boolean> {
    // 全く同じものをそもそも編集中な場合は開いた事にする
    if (
      this.editingEntity !== undefined &&
      entity !== undefined &&
      this.editingEntity.persistentId === entity.persistentId
    ) {
      return true;
    }

    if (this.panelState.isPanelOpen) {
      const closeResult = await this.panelState.closeCurrentPanel(option?.forceClose ?? false);
      if (closeResult === false) return false;
    }
    const registerResult = this.panelState.registerCurrentPanel(this);
    if (registerResult === false) return false;
    this.editingEntity = entity;
    await this.openFormEvent.emit({ entity, option });
    return true;
  }

  async close(forceClose: boolean): Promise<boolean> {
    if (this.closeHandler === undefined) throw new Error(`Close handler has not been registered!`);
    return await this.closeHandler(forceClose);
  }

  updateDisplayedWidth(width: Pixel) {
    this.updateDisplayedWidthEvent.emit({ panel: this, width });
  }

  /**
   * パネルから close が呼ばれた時か、手動で閉じられた時にも呼ばれる
   * どちらから呼ばれても editingEntity を空にしたいため
   *
   * @param _args
   * @private
   */
  private onClose(_args: ICloseEntityFormArgs<Data>): void {
    this.editingEntity = undefined;
    this.panelState.unregisterCurrentPanel(this);
  }
}
