import { Maybe, Pixel } from '~/framework/typeAliases';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';
import { IPanelState } from '~/framework/view-models/panels/panelState';
import { CloseHandler } from '~/framework/view-models/panels/entityFormPanel';
import { ITypedAsyncEvent, TypedAsyncEvent } from '~/framework/events/typedAsyncEvent';
import {
  CloseGenericObjectArgs,
  IGenericObjectPanel,
  OpenGenericObjectArgs,
} from '~/framework/view-models/panels/genericObjectPanel';
import { IOpenPanelOption, IUpdateDisplayedWidthEvent } from '~/framework/view-models/panels/panel';

export interface IGenericPanelObject {}

/**
 * Entity ではない汎用的な何らかのオブジェクトのためのパネルを開くためのもの
 */
export abstract class AbstractGenericObjectPanel<OpenData, CloseData, Option extends IOpenPanelOption = never>
  implements IGenericObjectPanel<OpenData, CloseData, Option>
{
  private readonly panelState: IPanelState;
  private closeHandler: Maybe<CloseHandler>;

  readonly openEvent: ITypedAsyncEvent<OpenGenericObjectArgs<OpenData, Option>>;
  readonly closeEvent: ITypedEvent<CloseGenericObjectArgs<OpenData, CloseData>>;
  readonly updateDisplayedWidthEvent: ITypedEvent<IUpdateDisplayedWidthEvent>;
  editingData: Maybe<OpenData>;

  constructor(panelState: IPanelState) {
    this.editingData = undefined;
    this.panelState = panelState;
    this.openEvent = new TypedAsyncEvent();
    this.closeEvent = new TypedEvent();
    this.updateDisplayedWidthEvent = new TypedEvent();
    const closeListener = this.onClose.bind(this);
    this.closeEvent.on(closeListener);
  }

  registerCloseHandler(closeHandler: CloseHandler) {
    this.closeHandler = closeHandler;
  }

  async open(data: Maybe<OpenData>, option?: Option): Promise<boolean> {
    // 全く同じものをそもそも編集中な場合は開いた事にする
    if (this.editingData !== undefined && data !== undefined && this.isDataSame(this.editingData, data)) {
      return true;
    }

    if (this.panelState.isPanelOpen) {
      const closeResult = await this.panelState.closeCurrentPanel(option?.forceClose ?? false);
      if (closeResult === false) return false;
    }
    const registerResult = this.panelState.registerCurrentPanel(this);
    if (registerResult === false) return false;
    this.editingData = data;
    await this.openEvent.emit({ openData: data, option });
    return true;
  }

  async close(forceClose: boolean): Promise<boolean> {
    if (this.closeHandler === undefined) throw new Error(`Close handler has not been registered!`);
    return await this.closeHandler(forceClose);
  }

  updateDisplayedWidth(width: Pixel) {
    this.updateDisplayedWidthEvent.emit({ panel: this, width });
  }

  /**
   * a と b のデータが同じものを指していてパネルを新しく開く必要がないかどうかを判定する
   *
   * @param a
   * @param b
   * @protected
   */
  protected abstract isDataSame(a: OpenData, b: OpenData): boolean;

  /**
   * パネルから close が呼ばれた時か、手動で閉じられた時にも呼ばれる
   * どちらから呼ばれても editingEntity を空にしたいため
   *
   * @param _args
   * @private
   */
  private onClose(_args: CloseGenericObjectArgs<OpenData, CloseData>): void {
    this.editingData = undefined;
    this.panelState.unregisterCurrentPanel(this);
  }
}
