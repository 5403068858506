
import Vue, { PropType } from 'vue';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';

enum EventTypes {
  Change = 'change',
}

type DataType = {
  fields: (keyof HolidayRuleEntity)[];
};

export default Vue.extend({
  name: 'RHolidayRuleCheckboxes',
  props: {
    holidayRule: {
      type: Object as PropType<HolidayRuleEntity>,
      required: true,
    },
  },
  data(): DataType {
    return {
      fields: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'nationalHoliday'],
    };
  },
  computed: {},
  methods: {
    changeHolidayRule(value: boolean, day: keyof HolidayRuleEntity): void {
      this.$emit(EventTypes.Change, value, day);
    },
  },
});
