import { Maybe } from '~/framework/typeAliases';

/**
 * 値が undefined かもしれないものから、配列を作る
 * 値が undefined だった場合、空の配列（[]）が返る
 *
 * @param value
 */
export const arrayFromMaybe = <T>(value: Maybe<T>) => {
  if (value === undefined) return [];
  else return [value];
};

/**
 * min と max を指定したら、min から max の順番に間のすべての数字の配列を生成する関数。
 * @param min 配列の最初の値。Inclusive.
 * @param max 配列の最後の値。Inclusive.
 * @returns 指定された min と max で構成された数字の配列。
 */
export const numberArray = (min: number, max: number): number[] => {
  const array = [];
  for (let num = min; num <= max; num++) {
    array.push(num);
  }
  return array;
};
