export const reservation$pushBackReservationFromCancelledToWaitingReviewSymbol = Symbol(
  'reservation$pushBackReservationFromCancelledToWaitingReviewSymbol'
);

export interface IPushBackReservationFromCancelledToWaitingReviewApi {
  [reservation$pushBackReservationFromCancelledToWaitingReviewSymbol]: void;
  pushBackReservationFromCancelledToWaitingReview(
    data: IPushBackReservationFromCancelledToWaitingReviewData
  ): Promise<IPushBackReservationFromCancelledToWaitingReviewMutationResultData>;
}

export interface IPushBackReservationFromCancelledToWaitingReviewData {
  id: string;
}

export interface IPushBackReservationFromCancelledToWaitingReviewMutationResultData {
  id: string;
}
