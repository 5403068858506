import { MutationResolvers, QueryResolvers, UserSetting } from '@/graphql/graphQLLocalState';
import { DataProxy } from 'apollo-cache';
import _ from 'lodash';
import { DefaultLocalStorage } from './localStorage';

import { ScheduleTabType } from '~/framework/domain/typeAliases';
import { ids } from '~/framework/core/entity';

// LocalStorage に全て保存する

/**
 * 理想的なユーザー設定の状態
 */
const defaultUserSettingBase: UserSetting = Object.freeze({
  __typename: 'UserSetting',
  id: '1',
  scheduleDate: new Date(),
  scheduleStartOfWeek: 0,
  scheduleTimelineStart: 8 * 60 * 60,
  scheduleTimelineEnd: 18 * 60 * 60,
  scheduleTab: ScheduleTabType.Schedule,
  orderGroupId: undefined,
  scheduleSolutionStatus: [],
  loginRememberMe: false,
});

export function setCacheDefaultValue(_cache: DataProxy): void {
  const defaultUserSetting = _.cloneDeep(defaultUserSettingBase);
  const storedUserSettings: UserSetting[] = DefaultLocalStorage.loadObjects([
    DefaultLocalStorage.getKeyByObject(defaultUserSetting),
  ]);
  const objects = new Map<string, object>();
  if (storedUserSettings.length === 1) {
    // 既に存在する場合は互換性の担保のための変換のみ行う
    // キーがないものがあったとしても、追加される
    for (const storedUserSetting of storedUserSettings) {
      objects.set(DefaultLocalStorage.getKeyByObject(storedUserSetting), {
        ...defaultUserSetting,
        ...storedUserSetting,
      });
    }
  } else {
    objects.set(DefaultLocalStorage.getKeyByObject(defaultUserSetting), defaultUserSetting);
  }
  DefaultLocalStorage.saveObjects(objects);
}

type UserSettingMutationResolvers = Pick<MutationResolvers, 'updateUserSettings'>;

export const Mutations: UserSettingMutationResolvers = {
  updateUserSettings: (_parent, args, _context, _info) => {
    const objects = new Map<string, object>();
    for (const userSetting of args.userSettings) {
      objects.set(DefaultLocalStorage.getKeyByObject(userSetting), userSetting);
    }

    DefaultLocalStorage.saveObjects(objects);
    return ids(args.userSettings);
  },
};

type UserSettingQueryResolvers = Pick<QueryResolvers, 'userSettingsByIds'>;

export const Queries: UserSettingQueryResolvers = {
  userSettingsByIds: (_parent, args, _context, _info) => {
    const objectIds = args.ids.map((id) => DefaultLocalStorage.getKey('UserSetting', id));
    const loadedObjects: UserSetting[] = DefaultLocalStorage.loadObjects(objectIds);

    return loadedObjects;
  },
};
