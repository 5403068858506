export enum GenerationSiteTaskCategory {
  /**
   * コンテナありのタスク
   */
  TaskWithContainer = 0,
  /**
   * その他のタスク
   */
  Irregular = 1,
}

export interface IGenerationSiteTaskCategory {
  value: number;
  label: string;
}

export const generationSiteTaskCategoryOptions: IGenerationSiteTaskCategory[] = [
  { value: GenerationSiteTaskCategory.TaskWithContainer, label: 'コンテナあり' },
  { value: GenerationSiteTaskCategory.Irregular, label: 'その他' },
];
