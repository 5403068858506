import { Maybe, MaybeWeakRef, PersistentId } from '~/framework/typeAliases';
import { IDisposable } from '~/framework/core/disposable';

export interface IWatcher {
  /**
   * プレゼンターを差し込む
   * @param presenter
   */
  addWatcherPresenter(presenter: MaybeWeakRef<IWatcherPresenter>): IDisposable;

  /**
   * 監視対象にする
   * @param scheduleId
   */
  watchSchedule(scheduleId: PersistentId): void;

  /**
   * 監視を中断する
   */
  cancelWatching(): void;
}

export enum ProgressStatus {
  CheckingBeforeOptimization, // '間違った情報が入力された案件がないか確認中'
  RunningOptimization1, // '配車表を読み込み中'
  RunningOptimization2, // '配車表を作成中'
  RunningOptimization3, // '配車表を書き出し中'
}

export interface IWatcherPresenter {
  /**
   * 作成が完了した時に呼ばれる
   */
  finished(): void;

  /**
   * 作成が失敗した時に呼ばれる
   */
  failed(): void;

  /**
   * 別日の配車表作成中のため実行拒否エラーの時に呼ばれる
   */
  failedByConflict(): void;

  /**
   * 作成ステータスが更新された時に呼ばれる
   * @param isCreating
   * @param progress
   * @param progressStatus
   */
  update(isCreating: boolean, progress: Maybe<number>, progressStatus: Maybe<ProgressStatus>): void;
}
