import { SystemContext } from '~/framework/systemContext';

export class Mio {
  private readonly systemContext: SystemContext;

  constructor(systemContext: SystemContext) {
    this.systemContext = systemContext;
  }

  /**
   * mio に対してのリンクを取得する
   * @param path
   */
  getMioLink(path: string): string {
    if (path.startsWith('/') === false) path = `/${path}`;
    if (this.systemContext.isDevelopment) return `http://localhost:5353${path}`;
    if (this.systemContext.isStaging)
      return `${this.systemContext.nuxtContext.env.mioUrl || 'https://api.stg.haisya-gasira.com'}${path}`;
    else return `https://api.haisya-gasira.com${path}`;
  }

  /**
   * ERP に対してのリンクを取得する
   * @param path
   */
  getErpLink(path?: string): string {
    if (!path) path = '/';
    if (path.startsWith('/') === false) path = `/${path}`;
    if (this.systemContext.isDevelopment) return `http://localhost:5353/erp${path}`;
    if (this.systemContext.isStaging) return `https://erp.stg.haisya-gasira.com/erp${path}`;
    else return `https://erp.haisya-gasira.com/erp${path}`;
  }
}

/**
 * MIO のパス
 */
export enum MioPath {
  TaskResultViews = 'task_result_views',
  LatestOrdersCsv = 'api/export_latest_orders',
  PrintSchedules = 'print_schedules',
  OrderImport = 'imports/orders',
  GenerationSiteImport = 'imports/generation_sites',
  DisposalSiteImport = 'imports/disposal_sites',
  ExportSchedulesCsv = 'api/export_schedules_csv', // 配車表の基幹システム連携用のCSVのダウンロード
  DownloadSchedulesCsv = 'api/exports/schedules/', // 配車表CSVのダウンロード
}

/**
 * ERP のパス
 */
export enum ErpPath {
  ErpOrders = '/erp_orders',
  ErpOrderById = '/erp_order',
}
