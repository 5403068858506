import { CollectablePeriodTemplateMapper } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateMapper';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { AggregatedGenerationSiteStore } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteStore';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { IGenerationSiteData } from '~/framework/server-api/masters/generationSite';
import { AggregatedDriverMapper } from '../driver/aggregatedDriverMapper';
import { AggregatedDriverStore } from '../driver/aggregatedDriverStore';
import { DriverAttendanceTemplateStore } from '../driver-attendance-template/driverAttendanceTemplateStore';
import { SimpleCarTypeEntity } from '../car-type/aggregatedCarTypeEntity';

export class AggregatedGenerationSiteMapper extends StoredMapperBase<
  IGenerationSiteData,
  AggregatedGenerationSiteEntity
> {
  protected store: AggregatedGenerationSiteStore;
  private clientMapper: AggregatedClientMapper;
  private driverMapper: AggregatedDriverMapper;
  private disposalSiteMapper: AggregatedDisposalSiteMapper;
  private carMapper: AggregatedCarMapper;
  private userMapper: UserMapper;
  private collectablePeriodTemplateMapper: CollectablePeriodTemplateMapper;

  constructor(
    store: AggregatedGenerationSiteStore,
    clientStore: AggregatedClientStore,
    userStore: UserStore,
    disposalSiteStore: AggregatedDisposalSiteStore,
    collectablePeriodTemplateStore: CollectablePeriodTemplateStore,
    carStore: AggregatedCarStore,
    baseSiteStore: AggregatedBaseSiteStore,
    driverStore: AggregatedDriverStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new AggregatedClientMapper(clientStore, userStore);
    this.driverMapper = new AggregatedDriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.disposalSiteMapper = new AggregatedDisposalSiteMapper(disposalSiteStore, userStore);
    this.carMapper = new AggregatedCarMapper(carStore, baseSiteStore, userStore);
    this.userMapper = new UserMapper(userStore);
    this.collectablePeriodTemplateMapper = new CollectablePeriodTemplateMapper(collectablePeriodTemplateStore);
  }

  protected instantiateWithData(data: IGenerationSiteData): AggregatedGenerationSiteEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    const defaultAssignedDriver = data.defaultAssignedDriver
      ? this.driverMapper.mapSingle(data.defaultAssignedDriver)
      : undefined;
    // 単数の場合のみ実装されている
    const defaultAssignedDisposalSite = data.defaultAssignedDisposalSite
      ? this.disposalSiteMapper.mapSingle(data.defaultAssignedDisposalSite)
      : undefined;
    const defaultAssignableCars = this.carMapper.map(data.defaultAssignableCars);
    const defaultAssignableCarTypes = data.defaultAssignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const defaultCollectablePeriodTemplate = data.defaultCollectablePeriodTemplate
      ? this.collectablePeriodTemplateMapper.mapSingle(data.defaultCollectablePeriodTemplate)
      : undefined;

    return new AggregatedGenerationSiteEntity(
      data,
      client,
      bannedDrivers,
      defaultAssignedDriver,
      defaultAssignedDisposalSite,
      defaultAssignableCars,
      defaultAssignableCarTypes,
      createdBy,
      defaultCollectablePeriodTemplate
    );
  }

  protected updateWithData(data: IGenerationSiteData, entity: AggregatedGenerationSiteEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    const defaultAssignedDriver = data.defaultAssignedDriver
      ? this.driverMapper.mapSingle(data.defaultAssignedDriver)
      : undefined;
    // 単数の場合のみ実装されている
    const defaultAssignedDisposalSite = data.defaultAssignedDisposalSite
      ? this.disposalSiteMapper.mapSingle(data.defaultAssignedDisposalSite)
      : undefined;
    const defaultAssignableCars = this.carMapper.map(data.defaultAssignableCars);
    const defaultAssignableCarTypes = data.defaultAssignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const defaultCollectablePeriodTemplate = data.defaultCollectablePeriodTemplate
      ? this.collectablePeriodTemplateMapper.mapSingle(data.defaultCollectablePeriodTemplate)
      : undefined;

    entity.update(
      data,
      client,
      bannedDrivers,
      defaultAssignedDriver,
      defaultAssignedDisposalSite,
      defaultAssignableCars,
      defaultAssignableCarTypes,
      createdBy,
      defaultCollectablePeriodTemplate
    );
  }
}
