import {
  CreateGenerationSitesMutation,
  CreateGenerationSitesMutationVariables,
  GenerationSitesByIdsQuery,
  GenerationSitesByIdsQueryVariables,
  GenerationSitesByKeywordsQuery,
  GenerationSitesByKeywordsQueryVariables,
  UpdateGenerationSitesMutation,
  UpdateGenerationSitesMutationVariables,
} from '@/graphql/graphQLServerApi';
import { GenerationSitesByIds, GenerationSitesByKeywords } from '@/../graphql/server-api/queries/generation-site.gql';
import { CreateGenerationSites, UpdateGenerationSites } from '@/../graphql/server-api/mutations/generation-site.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  ICondition,
  ICreateGenerationSiteData,
  IUpdateGenerationSiteData,
} from '~/framework/server-api/masters/generationSite';

import { Maybe } from '~/framework/typeAliases';

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<GenerationSitesByIdsQuery, GenerationSitesByIdsQueryVariables>({
      query: GenerationSitesByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.generationSitesByIds, `GenerationSitesByIds`);
    return result.generationSitesByIds;
  }
}

export class GetByKeywordsApi extends GraphqlApiBase {
  async getByKeywords(first: number, after: Maybe<string>, condition: ICondition) {
    const result = await this.query<GenerationSitesByKeywordsQuery, GenerationSitesByKeywordsQueryVariables>({
      query: GenerationSitesByKeywords,
      variables: { first, after, condition },
    });
    return result.generationSitesByKeywords;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: ICreateGenerationSiteData[]) {
    const result = await this.mutate<CreateGenerationSitesMutation, CreateGenerationSitesMutationVariables>({
      mutation: CreateGenerationSites,
      variables: { generationSites: data },
    });
    this.validateArrayConsistency(data, result.createGenerationSites, `CreateGenerationSites`);
    return result.createGenerationSites;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateGenerationSiteData[]) {
    const result = await this.mutate<UpdateGenerationSitesMutation, UpdateGenerationSitesMutationVariables>({
      mutation: UpdateGenerationSites,
      variables: { generationSites: data },
    });
    this.validateArrayConsistency(data, result.updateGenerationSites, `UpdateGenerationSites`);
    return result.updateGenerationSites;
  }
}
