import { AddressComponentType } from '~/framework/services/google-maps/addressComponentType';

/**
 * 正確であると判断したい順番（数値が高い方が優先順位が高い）
 */
export const addressComponentTypePriorityMap: Map<AddressComponentType, number> = new Map<AddressComponentType, number>(
  [
    [AddressComponentType.Subpremise, 12],
    [AddressComponentType.Premise, 11],
    [AddressComponentType.SublocalityLevel5, 10],
    [AddressComponentType.SublocalityLevel4, 9],
    [AddressComponentType.SublocalityLevel3, 8],
    [AddressComponentType.SublocalityLevel2, 7],
    [AddressComponentType.SublocalityLevel1, 6],
    [AddressComponentType.Ward, 5],
    [AddressComponentType.Locality, 4],
    [AddressComponentType.AdministrativeAreaLevel2, 3],
    [AddressComponentType.AdministrativeAreaLevel1, 2],
    [AddressComponentType.PostalCode, 1],
  ]
);
