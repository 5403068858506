import { Maybe } from '~/framework/typeAliases';

/**
 * 1.0 -> 100%
 * 0.9 -> 90%
 * の様にパーセント表示に変換する。
 * @param value
 */
export const percentize = (value: number): string => {
  return `${(value * 100).toFixed()}%`;
};

/**
 * 小数の端数処理を行う
 * @param value 丸める小数
 * @param digits 丸める桁数（デフォルトは2桁）
 * @returns 丸めた結果
 */
export const round = (value: Maybe<number>, digits: number = 2): Maybe<number> => {
  return value !== undefined ? parseFloat(value.toFixed(digits)) : undefined;
};
