import { ApolloLink, FetchResult, NextLink, Observable, Operation } from 'apollo-link';
import { Maybe } from '~/framework/typeAliases';

/**
 * Apollo Client の context に渡すとレスポンスの内容を取得できる様にするハンドラ
 */
export type ResponseHandler = (response: Response) => void;

/**
 * 通常の使い方をしていると client を呼ぶ側からレスポンスの内容を得る事はできないがそういう事がしたくなる
 * 事もあるので、コンテキストに専用のハンドラが仕込まれていた時はそれを呼ぶリンク
 */
export class ResponseAwareLink extends ApolloLink {
  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    const observable = forward(operation).map((result) => {
      const responseHandler: Maybe<ResponseHandler> = operation.getContext().responseHandler;
      if (responseHandler !== undefined && typeof responseHandler === 'function') {
        responseHandler(operation.getContext().response);
      }
      return result;
    });
    return observable;
  }
}
