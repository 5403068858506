
import Vue, { PropType } from 'vue';
import { Maybe } from '~/framework/typeAliases';
import { required } from '~/framework/view-models/rules';
import { JwnetWasteMasterEntity } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { IRSearchablePulldownEntity } from '~/components/common/r-searchable-pulldown/rSearchablePulldownTypes';
import { ensure } from '~/framework/core/value';

enum EventTypes {
  UpdateJwnetWasteMaster = 'update:jwnet-waste-master',
}

type DataType = {
  firstCategoryName: Maybe<string>;
  secondCategoryName: Maybe<string>;
  thirdCategoryName: Maybe<string>;
};

export default Vue.extend({
  name: 'RWasteTypeFormWasteFields',
  props: {
    jwnetWasteMasters: {
      type: Array as PropType<JwnetWasteMasterEntity[]>,
      required: true,
    },
    jwnetWasteMaster: {
      type: Object as PropType<Maybe<JwnetWasteMasterEntity>>,
      required: false,
      default: undefined,
    },
    isEditing: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data(): DataType {
    return {
      firstCategoryName: undefined,
      secondCategoryName: undefined,
      thirdCategoryName: undefined,
    };
  },
  computed: {
    jwnetWasteMastersInOrder(): JwnetWasteMasterEntity[] {
      // code は正規化されておりソートしてもよいものとしている
      return [...this.jwnetWasteMasters].sort((a, b) => a.code.localeCompare(b.code));
    },
    firstCategoryNamePulldownEntities(): JwnetWasteMasterEntity[] {
      const usedNames: Set<string> = new Set<string>();
      const distinctEntities: JwnetWasteMasterEntity[] = [];
      for (const entity of this.jwnetWasteMastersInOrder) {
        if (usedNames.has(entity.firstCategoryName)) continue;
        usedNames.add(entity.firstCategoryName);
        distinctEntities.push(entity);
      }
      return distinctEntities;
    },
    firstCategoryNamePulldownItems(): IRSearchablePulldownEntity[] {
      return this.firstCategoryNamePulldownEntities.map((entity) => {
        return { id: entity.firstCategoryName, name: entity.firstCategoryName };
      });
    },
    secondCategoryNamePulldownEntities(): JwnetWasteMasterEntity[] {
      const usedNames: Set<string> = new Set<string>();
      const entities = this.jwnetWasteMastersInOrder.filter(
        (entity) => entity.firstCategoryName === this.firstCategoryName
      );
      const distinctEntities: JwnetWasteMasterEntity[] = [];
      for (const entity of entities) {
        if (entity.secondCategoryName === undefined) continue;
        if (usedNames.has(entity.secondCategoryName)) continue;
        usedNames.add(entity.secondCategoryName);
        distinctEntities.push(entity);
      }
      return distinctEntities;
    },
    secondCategoryNamePulldownItems(): IRSearchablePulldownEntity[] {
      return this.secondCategoryNamePulldownEntities.map((entity) => {
        ensure(entity.secondCategoryName);
        return { id: entity.secondCategoryName, name: entity.secondCategoryName };
      });
    },
    /**
     * 中分類が選択されていない場合、大分類に紐付き、中分類には紐付かない要素を抽出する
     * 中分類が選択されいている場合、中分類に紐付く要素を抽出する
     */
    thirdCategoryNamePulldownEntities(): JwnetWasteMasterEntity[] {
      const usedNames: Set<string> = new Set<string>();
      const entities = this.jwnetWasteMastersInOrder.filter((entity) => {
        if (entity.thirdCategoryName === undefined) return false;
        if (entity.firstCategoryName !== this.firstCategoryName) return false;
        if (this.secondCategoryName === undefined) {
          return entity.secondCategoryName === undefined;
        } else {
          return entity.secondCategoryName === this.secondCategoryName;
        }
      });
      const distinctEntities: JwnetWasteMasterEntity[] = [];
      for (const entity of entities) {
        if (entity.thirdCategoryName === undefined) continue;
        if (usedNames.has(entity.thirdCategoryName)) continue;
        usedNames.add(entity.thirdCategoryName);
        distinctEntities.push(entity);
      }
      return distinctEntities;
    },
    thirdCategoryNamePulldownItems(): IRSearchablePulldownEntity[] {
      return this.thirdCategoryNamePulldownEntities.map((entity) => {
        ensure(entity.thirdCategoryName);
        return { id: entity.thirdCategoryName, name: entity.thirdCategoryName };
      });
    },
    baseJwnetWasteMaster(): Maybe<JwnetWasteMasterEntity> {
      const baseJwnetWasteMaster = (() => {
        if (this.thirdCategoryName) {
          const entity = this.thirdCategoryNamePulldownEntities.find(
            (entity) => entity.thirdCategoryName === this.thirdCategoryName
          );
          return entity;
        } else if (this.secondCategoryName) {
          const entity = this.secondCategoryNamePulldownEntities.find(
            (entity) => entity.secondCategoryName === this.secondCategoryName
          );
          return entity;
        } else if (this.firstCategoryName) {
          const entity = this.firstCategoryNamePulldownEntities.find(
            (entity) => entity.firstCategoryName === this.firstCategoryName
          );
          return entity;
        } else {
          return undefined;
        }
      })();
      return baseJwnetWasteMaster;
    },
    isUsableWaste(): boolean {
      return this.baseJwnetWasteMaster?.isUsable === true;
    },
  },
  watch: {
    jwnetWasteMaster: {
      handler(value: Maybe<JwnetWasteMasterEntity>, oldValue: Maybe<JwnetWasteMasterEntity>): void {
        if (value !== undefined && value?.id !== oldValue?.id) {
          this.firstCategoryName = value.firstCategoryName;
          this.secondCategoryName = value.secondCategoryName;
          this.thirdCategoryName = value.thirdCategoryName;
        }
      },
      immediate: true,
    },
    baseJwnetWasteMaster(value: Maybe<JwnetWasteMasterEntity>): void {
      this.$emit(EventTypes.UpdateJwnetWasteMaster, value);
    },
    firstCategoryName(): void {
      this.secondCategoryName = undefined;
    },
    secondCategoryName(): void {
      this.thirdCategoryName = undefined;
    },
  },
  methods: {
    required,
  },
});
