/**
 * 既にこのクラス内でチェック中のエラー
 */
export class AlreadyCheckingError extends Error {
  constructor() {
    super(`Already checking another order!`);
  }
}

/**
 * ユーザーによってキャンセルされたエラー
 */
export class CancelledByUserError extends Error {
  constructor() {
    super(`Order acceptance check was cancelled by the user!`);
  }
}

/**
 * 最適化エンジンが落ち続けて受注チェックができなかった時のエラー
 */
export class CancelledError extends Error {
  constructor() {
    super(`Order acceptance check was cancelled by optimization process!`);
  }
}

/**
 * 指定時間待っても結果が出なかった時のエラー
 */
export class AbortedError extends Error {
  constructor() {
    super(`Order acceptance check was aborted since the server did not respond in expected time.`);
  }
}
