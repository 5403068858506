import { AggregatedCarAttendanceEntity } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceEntity';
import { AggregatedCarAttendanceStore } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceStore';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import { ICarAttendanceData } from '~/framework/server-api/masters/carAttendance';

export class AggregatedCarAttendanceMapper extends StoredMapperBase<ICarAttendanceData, AggregatedCarAttendanceEntity> {
  protected store: AggregatedCarAttendanceStore;
  private attendanceMapper: AttendanceMapper;

  constructor(store: AggregatedCarAttendanceStore, attendanceStore: AttendanceStore) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
  }

  protected instantiateWithData(data: ICarAttendanceData): AggregatedCarAttendanceEntity {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);

    return new AggregatedCarAttendanceEntity(data, attendanceEntity);
  }

  protected updateWithData(data: ICarAttendanceData, entity: AggregatedCarAttendanceEntity): void {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);
    entity.update(data, attendanceEntity);
  }
}
