// ValueObject の様なものが本当に必要か分からないが、あった方が便利そうな感覚もあるので
// ひとまずサンプルで作ってみる。

/**
 * Value Object のベースクラス
 * 今のところ本当に必要なものだけ追加している
 *
 * Value Object は基本的に
 * - 一意性がない
 * - immutable である
 * を前提とする事に注意する
 */
export abstract class ValueObjectBase<T extends ValueObjectBase<T>> {
  abstract isEqualTo(another: T): boolean;
}
