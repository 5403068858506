
import Vue from 'vue';

enum EventTypes {
  OnClick = 'click',
}
export default Vue.extend({
  name: 'RCreateButton',
  methods: {
    onClick(): void {
      this.$emit(EventTypes.OnClick);
    },
  },
});
