// グローバルで一意になる事を担保奴
import { v4 as uuidv4 } from 'uuid';

export class IdGenerator {
  private static readonly usedUuid: Set<string> = new Set<string>();

  /**
   * UUID がカブるとかほぼありえないのだが、あり得るとバグるので一応対応しておく。
   */
  static generateNewId(): string {
    // 100回やって取れないとかありえないはず。。。
    for (let index = 0; index < 100; index++) {
      const newId = uuidv4();
      if (IdGenerator.usedUuid.has(newId) === false) {
        IdGenerator.usedUuid.add(newId);
        return newId;
      }
    }
    throw new Error('Could not generate new UUID. Almost impossible.');
  }
}
