export interface ISkipDisposalSiteOption {
  value: boolean;
  label: string;
}

export const skipDisposalSiteOptions: ISkipDisposalSiteOption[] = [
  // 表示上は「処分場を経由するか」になっているので
  { value: false, label: 'する' },
  { value: true, label: 'しない' },
];
