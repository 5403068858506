
import Vue, { PropType } from 'vue';
import { ContainerTypeTaskTypeStatus } from '~/framework/domain/typeAliases';

enum EventTypes {
  UpdateStatus = 'update:status',
  UpdateDurations = 'update:durations',
  UpdateIsProportionalToCount = 'update:is-proportional-to-count',
}

type StatusItem = {
  text: string;
  value: ContainerTypeTaskTypeStatus;
};

type IsProportionalToCountItem = {
  text: string;
  value: boolean;
};

type DataType = {
  statusItems: StatusItem[];
  isProportionalToCountItems: IsProportionalToCountItem[];
  durationKeys: [number, number];
};

export default Vue.extend({
  name: 'RPackingStyleRow',
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    status: {
      type: String as PropType<ContainerTypeTaskTypeStatus>,
      required: true,
    },
    isProportionalToCount: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    durations: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  data(): DataType {
    const statusItems: StatusItem[] = [
      { text: 'する', value: ContainerTypeTaskTypeStatus.InUse },
      { text: 'しない', value: ContainerTypeTaskTypeStatus.NotInUse },
    ];
    const isProportionalToCountItems: IsProportionalToCountItem[] = [
      { text: 'する', value: true },
      { text: 'しない', value: false },
    ];
    return {
      statusItems,
      isProportionalToCountItems,
      durationKeys: [0, 0],
    };
  },
  methods: {
    onChangeStatus(value: ContainerTypeTaskTypeStatus): void {
      this.$emit(EventTypes.UpdateStatus, value);
    },
    onChangeIsProportionalToCount(value: boolean): void {
      this.$emit(EventTypes.UpdateIsProportionalToCount, value);
    },
    onChangeDuration(index: number, value: string): void {
      const actualValue = Math.max(this.hasValue(value) ? Number.parseFloat(value) : 0, 0);
      const durations = [...this.durations];
      durations[index] = actualValue;
      this.$emit(EventTypes.UpdateDurations, durations);
      this.durationKeys[index]++;
    },
    hasValue<T>(
      value: T | null | undefined | '' | 0,
      options: { isEmptyStringAllowed: boolean; isZeroAllowed: boolean } = {
        isEmptyStringAllowed: false,
        isZeroAllowed: true,
      }
    ): boolean {
      const hasStringValue = typeof value === 'string' && (options?.isEmptyStringAllowed || value !== '');
      const hasNumberValue = typeof value === 'number' && (options?.isZeroAllowed || value !== 0);
      return value !== null && value !== undefined && (hasStringValue || hasNumberValue);
    },
  },
});
