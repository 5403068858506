
import Vue, { PropType } from 'vue';
import statusText from './status.json';
import isProportionalToCountText from './isProportionalToCount.json';
import { constValue, defaultValue, MaybeDefault } from '~/components/panels/masters/r-container-task-form/formValues';
import { ContainerTypeTaskTypeStatus } from '~/framework/domain/typeAliases';
import { SelectItem } from '~/framework/view-models/vuetify';
import { hankanize } from '~/framework/services/string/string';

enum EventTypes {
  UpdateStatus = 'update:status',
  UpdateIsProportionalToCount = 'update:is-proportional-to-count',
  UpdateDurations = 'update:durations',
}

type StatusItem = SelectItem<MaybeDefault<ContainerTypeTaskTypeStatus>>;
type IsProportionalToCountItem = SelectItem<MaybeDefault<boolean>>;
type DurationItem = SelectItem<MaybeDefault<typeof constValue>>;
type DataType = {
  defaultValue: typeof defaultValue;
  ContainerTypeTaskTypeStatus: typeof ContainerTypeTaskTypeStatus;
  statusItems: StatusItem[];
  isProportionalToCountItems: IsProportionalToCountItem[];
  durationItems: DurationItem[];
  statusText: typeof statusText;
  isProportionalToCountText: typeof isProportionalToCountText;
};

export default Vue.extend({
  name: 'RContainerRow',
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    status: {
      type: [String, Symbol] as PropType<MaybeDefault<ContainerTypeTaskTypeStatus>>,
      required: true,
    },
    isProportionalToCount: {
      type: [Boolean, Symbol] as PropType<MaybeDefault<boolean>>,
      required: true,
    },
    durations: {
      type: Array as PropType<MaybeDefault<number>[]>,
      required: true,
    },
    defaultStatus: {
      type: String as PropType<ContainerTypeTaskTypeStatus>,
      required: true,
    },
    defaultIsProportionalToCount: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    defaultDurations: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  data(): DataType {
    const statusItems: StatusItem[] = [
      { text: 'デフォルト', value: defaultValue },
      { text: statusText[ContainerTypeTaskTypeStatus.InUse], value: ContainerTypeTaskTypeStatus.InUse },
      { text: statusText[ContainerTypeTaskTypeStatus.NotInUse], value: ContainerTypeTaskTypeStatus.NotInUse },
    ];
    const isProportionalToCountItems: IsProportionalToCountItem[] = [
      { text: 'デフォルト', value: defaultValue },
      { text: isProportionalToCountText.true, value: true },
      { text: isProportionalToCountText.false, value: false },
    ];
    const durationItems: DurationItem[] = [
      { text: 'デフォルト', value: defaultValue },
      { text: 'カスタム入力', value: constValue },
    ];
    return {
      defaultValue,
      statusText,
      isProportionalToCountText,
      ContainerTypeTaskTypeStatus,
      statusItems,
      isProportionalToCountItems,
      durationItems,
    };
  },
  computed: {
    actualStatus(): ContainerTypeTaskTypeStatus {
      if (this.status === defaultValue) {
        return this.defaultStatus;
      } else {
        return this.status;
      }
    },
    actualIsProportionalToCount(): MaybeDefault<boolean> | undefined {
      if (this.actualStatus === ContainerTypeTaskTypeStatus.InUse) {
        return this.isProportionalToCount;
      } else {
        return undefined;
      }
    },
    actualDurations(): (MaybeDefault<number> | undefined)[] {
      if (this.actualStatus === ContainerTypeTaskTypeStatus.InUse) {
        return this.durations;
      } else {
        return [undefined, undefined];
      }
    },
  },
  methods: {
    hankanize,
    onChangeStatus(value: MaybeDefault<ContainerTypeTaskTypeStatus>): void {
      this.$emit(EventTypes.UpdateStatus, value);
    },
    onChangeIsProportionalToCount(value: MaybeDefault<boolean>): void {
      this.$emit(EventTypes.UpdateIsProportionalToCount, value);
    },
    onChangeDurationType(index: number, value: MaybeDefault<typeof constValue>): void {
      if (value === constValue) {
        this.updateDurations(index, this.defaultDurations[index]);
      } else {
        this.updateDurations(index, defaultValue);
      }
    },
    onClickClearDurationValue(index: number): void {
      this.updateDurations(index, defaultValue);
    },
    onChangeDurationValue(index: number, value: string | number): void {
      const actualValue = this.hasValue(value)
        ? Math.max((value = typeof value === 'number' ? value : Number.parseFloat(value)), 0)
        : defaultValue;
      this.updateDurations(index, actualValue);
    },
    updateDurations(index: number, value: MaybeDefault<number>): void {
      const durations = [...this.durations];
      durations[index] = value;
      this.$emit(EventTypes.UpdateDurations, durations);
    },
    hasValue<T>(
      value: T | null | undefined | '' | 0,
      options: { isEmptyStringAllowed: boolean; isZeroAllowed: boolean } = {
        isEmptyStringAllowed: false,
        isZeroAllowed: true,
      }
    ): boolean {
      const hasStringValue = typeof value === 'string' && (options?.isEmptyStringAllowed || value !== '');
      const hasNumberValue = typeof value === 'number' && (options?.isZeroAllowed || value !== 0);
      return value !== null && value !== undefined && (hasStringValue || hasNumberValue);
    },
  },
});
