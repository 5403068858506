import { zip } from '~/framework/core/array';
import { ICollectionEntityData } from '~/framework/domain/schedule/schedule/pseudo-entities/collectionEntity';
import { CollectionDiff, ICollectionDiff } from '~/framework/domain/schedule/schedule/pseudo-entities/scheduleDiff';

/**
 * 回収が入れ換えられたり追加・削除されたりして編集された状態にあるかどうかを取得する
 * id は original の方も実際の方も一緒という前提があるので注意
 */
export interface ICollectionComparison {
  /**
   * key は presentCollection の id
   * presentCollection から見た diff が ICollectionDiff としてマップされている
   */
  readonly diffs: Map<string, ICollectionDiff>;

  /**
   * 与えられた回収全体として編集されているかどうか
   */
  readonly hasDiff: boolean;
}

export class CollectionComparison<Collection extends ICollectionEntityData> implements ICollectionComparison {
  readonly diffs: Map<string, ICollectionDiff>;
  readonly hasDiff: boolean;

  constructor(originalCollections: Collection[], presentCollections: Collection[]) {
    this.diffs = new Map<string, ICollectionDiff>();
    this.hasDiff = false;
    const pairs = zip(originalCollections, presentCollections);
    for (const [originalCollection, presentCollection] of pairs) {
      if (presentCollection !== undefined) {
        const hasIndexDiff =
          originalCollection === undefined ||
          presentCollection.id !== originalCollection.id ||
          presentCollection.index !== originalCollection.index;
        const hasDiff = hasIndexDiff;
        if (hasDiff) this.diffs.set(presentCollection.id, new CollectionDiff(hasIndexDiff));
        this.hasDiff ||= hasDiff;
      } else if (presentCollection === undefined) {
        this.hasDiff = true;
      } else if (originalCollection === undefined) {
        this.hasDiff = true;
      }
    }
  }
}
