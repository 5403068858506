import { ApolloLink, FetchResult, NextLink, Observable, Operation } from 'apollo-link';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';

/**
 * 単純にリクエストが飛ぼうとしている瞬間とリクエストが帰ってきた時を観測したい時に利用する
 * リクエストや結果をいじったりする事はあまり想定していない
 */
export class ObserverLink extends ApolloLink {
  readonly requestEvent: ITypedEvent<Operation>;
  readonly responseEvent: ITypedEvent<FetchResult>;

  constructor() {
    super();
    this.requestEvent = new TypedEvent();
    this.responseEvent = new TypedEvent();
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    this.requestEvent.emit(operation);
    const observable = forward(operation).map((data) => {
      const context = operation.getContext();
      const result = {
        ...data,
        context,
      };
      this.responseEvent.emit(result);
      return result;
    });
    return observable;
  }
}
