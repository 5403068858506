
import Vue from 'vue';
import { VAutocomplete } from 'vuetify/lib';

enum EventTypes {
  /**
   * アイテムがフィルタされた時に呼ばれる
   */
  filtered = 'filtered',
}

// HACK extends VAutocomplete が動かないので致し方なくやっている
// なんかいい方法ある？
export default (VAutocomplete as typeof Vue & typeof VAutocomplete).extend({
  name: 'RVAutocomplete',
  props: {},
  watch: {
    filteredItems: '_onFilteredItemsChanged',
  },
  methods: {
    /**
     * onFilteredItemsChanged は既に VAutocomplete で使われているのでアンスコを付けている
     * @param items
     * @private
     */
    _onFilteredItemsChanged(items: any[]): void {
      this.$emit(EventTypes.filtered, items);
    },
  },
});
