import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  routingRegulation$getByIdsSymbol,
  routingRegulation$getAllSymbol,
  ICreateData,
  IUpdateData,
  routingRegulation$createSymbol,
  routingRegulation$updateSymbol,
  routingRegulation$deleteSymbol,
} from '~/framework/server-api/masters/routingRegulation';
import { RoutingRegulationMapper } from '~/framework/domain/masters/routing-regulation/routingRegulationMapper';
import { PersistentId } from '~/framework/typeAliases';
import { RoutingRegulationEntity } from '~/framework/domain/masters/routing-regulation/routingRegulationEntity';
export const routingRegulationServiceSymbol = Symbol('routingRegulationService');

export class RoutingRegulationApplicationService {
  private readonly serverApis: ServerApiManager;
  private readonly routingRegulationMapper: RoutingRegulationMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.routingRegulationMapper = new RoutingRegulationMapper(
      store.masters.aggregatedGenerationSite,
      store.masters.aggregatedClient,
      store.masters.user,
      store.masters.aggregatedDisposalSite,
      store.masters.collectablePeriodTemplate,
      store.masters.aggregatedCar,
      store.masters.aggregatedBaseSite,
      store.masters.aggregatedDriver,
      store.masters.driverAttendanceTemplate
    );
  }

  async get(id: string): Promise<RoutingRegulationEntity> {
    const routingRegulation$getByIdsApi = this.serverApis.get(routingRegulation$getByIdsSymbol);
    const [result] = await routingRegulation$getByIdsApi.getByIds([id]);
    return this.routingRegulationMapper.mapSingle(result);
  }

  async getAll(): Promise<RoutingRegulationEntity[]> {
    const routingRegulation$getAllApi = this.serverApis.get(routingRegulation$getAllSymbol);
    const data = await routingRegulation$getAllApi.getAll();
    return this.routingRegulationMapper.map(data);
  }

  async create(data: ICreateData): Promise<RoutingRegulationEntity | string> {
    const routingRegulation$createApi = this.serverApis.get(routingRegulation$createSymbol);
    const result = await routingRegulation$createApi.create(data);

    if (result.__typename === 'RoutingRegulationMutationError') {
      // NOTE: 現状は DuplicatedNameError しかないので1件だけ確認する
      if (result.errors[0].__typename !== undefined && result.errors[0].__typename === 'DuplicatedNameError') {
        return '同じルート名は登録できません。';
      }
      throw new Error(`Uncategorized error! ${result}`);
    }

    return await this.get(result.id);
  }

  async update(data: IUpdateData): Promise<RoutingRegulationEntity | string> {
    const routingRegulation$updateApi = this.serverApis.get(routingRegulation$updateSymbol);
    const result = await routingRegulation$updateApi.update(data);

    if (result.__typename === 'RoutingRegulationMutationError') {
      // NOTE: 現状は DuplicatedNameError しかないので1件だけ確認する
      if (result.errors[0].__typename !== undefined && result.errors[0].__typename === 'DuplicatedNameError') {
        return '同じルート名は登録できません。';
      }
      throw new Error(`Uncategorized error! ${result}`);
    }

    return await this.get(result.id);
  }

  async delete(id: PersistentId): Promise<PersistentId> {
    const routingRegulation$deleteApi = this.serverApis.get(routingRegulation$deleteSymbol);
    await routingRegulation$deleteApi.delete(id);
    return id;
  }
}
