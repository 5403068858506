import { PersistentId } from '~/framework/typeAliases';
import { UserSettingEntity } from '~/framework/domain/user-setting/userSettingEntity';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { AggregatedCarAttendanceEntity } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceEntity';
import {
  carAttendance$getByDateRangeSymbol,
  carAttendance$updateSymbol,
  IUpdateData,
} from '~/framework/server-api/masters/carAttendance';

import { AggregatedCarAttendanceMapper } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceMapper';

export const carAttendanceSymbol = Symbol('carAttendance');

export interface IPageData {
  userSetting: UserSettingEntity;
  holidayRule: HolidayRuleEntity;
}

export class CarAttendanceApplicationService {
  private readonly serverApis: ServerApiManager;
  private aggregatedCarAttendanceMapper: AggregatedCarAttendanceMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.aggregatedCarAttendanceMapper = new AggregatedCarAttendanceMapper(
      store.masters.aggregatedCarAttendance,
      store.masters.attendance
    );
  }

  async getCarsAttendanceByDateRange(start: Date, end: Date, carIds?: Array<PersistentId>) {
    const getByDateRangeApi = this.serverApis.get(carAttendance$getByDateRangeSymbol);
    const carAttendanceData = await getByDateRangeApi.getByDateRange(start, end, carIds);
    return this.aggregatedCarAttendanceMapper.map(carAttendanceData);
  }

  async updateCarAttendance(updateData: IUpdateData): Promise<AggregatedCarAttendanceEntity> {
    const updateCarAttendanceApi = this.serverApis.get(carAttendance$updateSymbol);
    const carAttendanceData = await updateCarAttendanceApi.update(updateData);
    return this.aggregatedCarAttendanceMapper.mapSingle(carAttendanceData);
  }
}
