import { DayOfWeek as GraphQLDayOfWeek } from '~/framework/domain/typeAliases';
import { DayOfWeek } from '~/framework/typeAliases';
import daysOfWeek from '~/assets/settings/daysOfWeek.json';

/**
 * GraphQL 側の曜日を date-fns 系に変換する
 */
const daysOfWeekIndexMap = new Map<GraphQLDayOfWeek, DayOfWeek>([
  [GraphQLDayOfWeek.Sunday, 0],
  [GraphQLDayOfWeek.Monday, 1],
  [GraphQLDayOfWeek.Tuesday, 2],
  [GraphQLDayOfWeek.Wednesday, 3],
  [GraphQLDayOfWeek.Thursday, 4],
  [GraphQLDayOfWeek.Friday, 5],
  [GraphQLDayOfWeek.Saturday, 6],
]);

/**
 * date-fns 系の曜日を GraphQL 側に変換する
 */
const indexDaysOfWeekMap = new Map<DayOfWeek, GraphQLDayOfWeek>([
  [0, GraphQLDayOfWeek.Sunday],
  [1, GraphQLDayOfWeek.Monday],
  [2, GraphQLDayOfWeek.Tuesday],
  [3, GraphQLDayOfWeek.Wednesday],
  [4, GraphQLDayOfWeek.Thursday],
  [5, GraphQLDayOfWeek.Friday],
  [6, GraphQLDayOfWeek.Saturday],
]);

/**
 * 表示用に GraphQL 側の曜日を日本語に変換する
 */
const daysOfWeekLabelsMap = new Map<GraphQLDayOfWeek, string>([
  [GraphQLDayOfWeek.Sunday, daysOfWeek['0']],
  [GraphQLDayOfWeek.Monday, daysOfWeek['1']],
  [GraphQLDayOfWeek.Tuesday, daysOfWeek['2']],
  [GraphQLDayOfWeek.Wednesday, daysOfWeek['3']],
  [GraphQLDayOfWeek.Thursday, daysOfWeek['4']],
  [GraphQLDayOfWeek.Friday, daysOfWeek['5']],
  [GraphQLDayOfWeek.Saturday, daysOfWeek['6']],
]);

export const dayOfWeekToIndex = (dayOfWeek: GraphQLDayOfWeek): DayOfWeek => {
  return daysOfWeekIndexMap.getOrError(dayOfWeek);
};

export const indexToDayOfWeek = (index: DayOfWeek): GraphQLDayOfWeek => {
  return indexDaysOfWeekMap.getOrError(index);
};

export const dayOfWeekToLabel = (dayOfWeek: GraphQLDayOfWeek): string => {
  return daysOfWeekLabelsMap.getOrError(dayOfWeek);
};
