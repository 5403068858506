
import Vue, { PropType } from 'vue';
import { NuxtError } from '@nuxt/types';
import { Maybe } from '~/framework/typeAliases';
import { NuxtErrorStatusCodes } from '~/nuxt';

export default Vue.extend({
  name: 'RErrors',
  props: {
    error: {
      type: undefined as any as PropType<Maybe<NuxtError>>,
      required: false,
      default: undefined,
    },
    statusCode: {
      type: Number as PropType<Maybe<number>>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    isNotFoundException(): boolean {
      return this.statusCode === 404;
    },
    isEntityNotFoundException(): boolean {
      return this.statusCode === NuxtErrorStatusCodes.EntityNotFoundException;
    },
    isNetworkException(): boolean {
      return this.statusCode === NuxtErrorStatusCodes.GraphQLNetworkException;
    },
    isInconsistentSessionException(): boolean {
      return this.statusCode === NuxtErrorStatusCodes.InconsistentSessionException;
    },
    isIncompatibleBrowserException(): boolean {
      return this.statusCode === NuxtErrorStatusCodes.IncompatibleBrowserException;
    },
  },
});
