import { PackingStyleCode } from '~/framework/domain/typeAliases';
import { IPackingStyleData } from '~/framework/server-api/masters/packingStyle';

export class PackingStyleEntity {
  public readonly id: string;
  public code: PackingStyleCode;
  public name: string;
  public defaultUnitName: string;

  constructor(data: IPackingStyleData) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.defaultUnitName = data.defaultUnitName;
  }

  update(data: IPackingStyleData) {
    if (this.id !== data.id) {
      throw new Error('invalid update of PackingStyleEntity');
    }
    this.code = data.code;
    this.name = data.name;
    this.defaultUnitName = data.defaultUnitName;
  }

  /**
   * 整数として数えられるかどうか。
   * バラの場合は整数として数えられないために小数点が含まれた数値になる場合がある。
   * @returns 整数として数えられる場合はtrue
   */
  public isCountedByInteger(): boolean {
    // PackingStyleCodeのCode01はバラに固定されている
    return this.code !== PackingStyleCode.Code01;
  }
}
