
import Vue, { PropType } from 'vue';
import { RAddressValidatorInstance } from '../r-address-validator/componentType';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { required } from '~/framework/view-models/rules';
import { GoogleMapService, IGoogleMapService } from '~/framework/services/google-maps/googleMapService';

import Prefectures from '~/assets/settings/prefectures.json';
import { IAddress } from '~/framework/services/google-maps/address';
import { transformFormattedZipCode } from '~/framework/services/address/zipCode';
import { RinEventNames } from '~/framework/services/rin-events/rinEventParams';

enum EventTypes {
  ClickAutocompleteButton = 'click:autocomplete-button',
  UpdateZipCode = 'update:zip-code',
  UpdateAddress1 = 'update:address1',
  UpdateAddress2 = 'update:address2',
  UpdateAddress3 = 'update:address3',
  UpdateAddress4 = 'update:address4',
  UpdateLatitude = 'update:latitude',
  UpdateLongitude = 'update:longitude',
  UpdateIsAddressValidated = 'update:is-address-validated',
  UpdateIsPastingAddress = 'update:is-pasting-address',
}

interface IAddress1Options {
  id: string;
  name: string;
}

type DataType = {
  isAutocompleteErrorMessageOpen: boolean;
  rules: { [key: string]: ValidationRule };
  address1Options: Array<IAddress1Options>;

  google: IGoogleMapService;
};

export default Vue.extend({
  name: 'RAddressInputGroup',
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    zipCode: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    address1: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address2: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address3: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address4: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    latitude: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    longitude: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    mapDialogTitle: {
      type: String as PropType<string>,
      required: true,
    },
    isAddressValidated: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isAddressComplete: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    isPastingAddress: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      isAutocompleteErrorMessageOpen: false,
      rules: { required },
      address1Options: Prefectures.map((prefecture) => ({ id: prefecture, name: prefecture })),

      google: new GoogleMapService(this.$google),
    };
  },
  computed: {
    showAmbiguousAlert(): boolean {
      // csvインポート経由などで追加され、曖昧な住所データと判定されたものを編集するときに表示される
      return !this.isAddressComplete;
    },
    zipCodeLocal: {
      get(): Maybe<string> {
        return this.zipCode;
      },
      set(newVal: Maybe<string>): void {
        const formattedZipCode = transformFormattedZipCode(newVal);

        this.$emit(EventTypes.UpdateZipCode, formattedZipCode);
      },
    },
    isAddressValidatedLocal: {
      get(): boolean {
        return this.isAddressValidated;
      },
      set(newVal: boolean): void {
        this.$emit(EventTypes.UpdateIsAddressValidated, newVal);
      },
    },
    latitudeLocal: {
      get(): Maybe<number> {
        return this.latitude;
      },
      set(newVal: Maybe<number>): void {
        this.$emit(EventTypes.UpdateLatitude, newVal);
      },
    },
    longitudeLocal: {
      get(): Maybe<number> {
        return this.longitude;
      },
      set(newVal: Maybe<number>): void {
        this.$emit(EventTypes.UpdateLongitude, newVal);
      },
    },
    address1Local: {
      get(): string {
        return this.address1;
      },
      set(newVal: string): void {
        this.$emit(EventTypes.UpdateAddress1, newVal);
      },
    },
    address2Local: {
      get(): string {
        return this.address2;
      },
      set(newVal: string): void {
        this.$emit(EventTypes.UpdateAddress2, newVal);
      },
    },
    address3Local: {
      get(): string {
        return this.address3;
      },
      set(newVal: string): void {
        this.$emit(EventTypes.UpdateAddress3, newVal);
      },
    },
    address4Local: {
      get(): Maybe<string> {
        return this.address4;
      },
      set(newVal: Maybe<string>): void {
        this.$emit(EventTypes.UpdateAddress4, newVal);
      },
    },
    isPastingAddressLocal: {
      get(): boolean {
        return this.isPastingAddress;
      },
      set(newVal: boolean): void {
        this.$emit(EventTypes.UpdateIsPastingAddress, newVal);
      },
    },
  },
  methods: {
    closeAutocompleteErrorMessage(): void {
      this.isAutocompleteErrorMessageOpen = false;
    },

    async onAutocompleteButtonClicked(): Promise<void> {
      this.$rinGtm.push(RinEventNames.AUTO_COMPLETE_ADDRESS_FROM_ZIPCODE);
      try {
        if (this.zipCode === undefined) throw new Error(`zipCode is undefined`);
        const addresses = await this.google.getAddresses(this.zipCode);

        const preferredAddress = addresses.preferredAddress;
        if (preferredAddress === undefined) throw new Error(`preferredAddress is undefined`);
        this.$emit(EventTypes.UpdateAddress1, preferredAddress.address1);
        this.$emit(EventTypes.UpdateAddress2, preferredAddress.address2);
      } catch (error) {
        this.isAutocompleteErrorMessageOpen = true;
      }
    },

    async onPasteButtonClicked(): Promise<void> {
      this.$rinGtm.push(RinEventNames.PASTE_ADDRESS);
      this.isPastingAddressLocal = true;

      const text = await this.$context.clipboard.readText();
      if (text === undefined) {
        this.$context.snackbar.error('クリップボードが利用できません。\nブラウザの設定を確認して下さい。');
        this.isPastingAddressLocal = false;
        return;
      }

      const setAddress = (address: IAddress) => {
        this.$emit(EventTypes.UpdateZipCode, address.postalCode);
        this.$emit(EventTypes.UpdateAddress1, address.address1);
        this.$emit(EventTypes.UpdateAddress2, address.address2);
        this.$emit(EventTypes.UpdateAddress3, address.address3);
        this.$emit(EventTypes.UpdateAddress4, address.address4);
      };

      const noResultError = () => {
        this.$context.snackbar.error('住所ではないためペーストできませんでした。');
      };

      try {
        const addresses = await this.google.getAddressesFrom(text);

        const address = addresses.preferredAddress;

        if (!address) throw new Error('対象となる住所が存在しませんでした。');

        setAddress(address);
      } catch (e) {
        noResultError();
      } finally {
        this.isPastingAddressLocal = false;
      }
    },

    onConfirmAmbiguousAddressAlert(): void {
      (this.$refs.addressValidator as RAddressValidatorInstance).onCheckMapButtonClicked();
    },
  },
});
