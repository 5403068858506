import { AllTransportationClients } from '@/../graphql/server-api/queries/erp-client.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { AllTransportationClientsQuery, AllTransportationClientsQueryVariables } from '~/graphql/graphQLServerApi';

export class AllTransportationClientsApi extends GraphqlApiBase {
  async getAllTransportationClients() {
    const result = await this.query<AllTransportationClientsQuery, AllTransportationClientsQueryVariables>({
      query: AllTransportationClients,
      variables: {},
    });

    return result.allTransportationClients;
  }
}
