import { OrderGroupColor } from '~/framework/domain/typeAliases';
export const orderGroup$getAllSymbol = Symbol('getAll');

export type OrderGroup = {
  [orderGroup$getAllSymbol]: IGetAllApi;
};

export interface IGetAllApi {
  getAll(): Promise<IOrderGroupData[]>;
}

export interface IOrderGroupData {
  id: string;
  name: string;
  colorName: OrderGroupColor;
}
