
import Vue, { PropType } from 'vue';
import ROrderFormSelect, {
  ItemTextColorClassType,
} from '~/components/panels/schedule/r-order-form/ROrderFormSelect.vue';
import { OrderDisposalSiteAssignmentType } from '~/framework/domain/typeAliases';
import { VuetifyColors } from '~/framework/constants';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import RConfirmDialog from '~/components/common/r-confirm-dialog/RConfirmDialog.vue';

type SelectionItemType = {
  title: string;
  value: OrderDisposalSiteAssignmentType;
  icon: string;
  class: string;
  color: VuetifyColors;
};

type DataType = {
  selectionItems: SelectionItemType[];
  isChangeAssignmentConfirmDialogActive: boolean;
  /**
   * `updateCount` is used to force re-render `ROrderFormSelect`.
   * Increment when there is a necessity to force re-render.
   */
  updateCount: number;
  changeAssignmentConfirmDialogResolver: Maybe<(value: boolean) => void>;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RDisposalSiteAssignmentTypeInput',
  components: {
    ROrderFormSelect,
    RConfirmDialog,
  },
  model: {
    prop: 'selectedDisposalSiteAssignmentType',
    event: EventTypes.Change,
  },

  props: {
    selectedDisposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    isDisposalSiteDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    selectedDisposalSiteIds: {
      type: Array as PropType<Array<PersistentId>>,
      required: true,
    },
  },
  data(): DataType {
    return {
      isChangeAssignmentConfirmDialogActive: false,
      changeAssignmentConfirmDialogResolver: undefined,
      updateCount: 0,
      selectionItems: [
        {
          title: '巡回する順番を指定',
          value: OrderDisposalSiteAssignmentType.SequentialMultiple,
          icon: 'ffi-sequence',
          class: ItemTextColorClassType.Primary,
          color: VuetifyColors.Primary,
        },
        {
          title: '候補を複数登録',
          value: OrderDisposalSiteAssignmentType.PrioritySingle,
          icon: 'ffi-candidates',
          class: ItemTextColorClassType.Primary,
          color: VuetifyColors.Primary,
        },
        {
          title: '詳細設定をリセット',
          value: OrderDisposalSiteAssignmentType.NonSequentialMultiple,
          icon: 'mdi-close',
          class: ItemTextColorClassType.Error,
          color: VuetifyColors.Error,
        },
      ],
    };
  },
  computed: {
    /**
     * `OrderDisposalSiteAssignmentType.NonSequentialMultiple` must be treated as `undefined`
     * for `r-order-form-select` to show correct label in `v-select`.
     */
    valueInner(): OrderDisposalSiteAssignmentType | undefined {
      if (this.selectedDisposalSiteAssignmentType === OrderDisposalSiteAssignmentType.NonSequentialMultiple)
        return undefined;
      return this.selectedDisposalSiteAssignmentType;
    },
    /**
     * Hide `OrderDisposalSiteAssignmentType.NonSequentialMultiple`'s selection item when it is selected.
     */
    displaySelectionItems(): Array<SelectionItemType> {
      if (this.selectedDisposalSiteAssignmentType === OrderDisposalSiteAssignmentType.NonSequentialMultiple)
        return this.selectionItems.filter(
          (selectionItem) => selectionItem.value !== OrderDisposalSiteAssignmentType.NonSequentialMultiple
        );

      return this.selectionItems;
    },
  },
  methods: {
    async onChangeValue(value: OrderDisposalSiteAssignmentType): Promise<void> {
      if (this.selectedDisposalSiteIds.length === 0) {
        this.$emit(EventTypes.Change, value);
      } else if (value === this.selectedDisposalSiteAssignmentType) {
        this.$emit(EventTypes.Change, value);
      } else {
        const confirmDialogPromise = new Promise<boolean>((resolve) => {
          this.isChangeAssignmentConfirmDialogActive = true;
          this.changeAssignmentConfirmDialogResolver = resolve;
        });

        if (await confirmDialogPromise) {
          this.$emit(EventTypes.Change, value);
        } else {
          // Re-render `ROrderSelectComponent` to fallback selected value to original (i.e. before rejecting confirm dialog).
          // Otherwise, displayed selected value and `selectedDisposalSiteAssignmentType` become different.
          this.updateCount++;
        }
      }
    },
    onConfirmDialog(value: boolean) {
      this.isChangeAssignmentConfirmDialogActive = false;

      if (this.changeAssignmentConfirmDialogResolver === undefined) throw new Error('Dialog resolver is undefined');
      this.changeAssignmentConfirmDialogResolver(value);
    },
  },
});
