import { RawLocation } from 'vue-router/types/router';
import { Maybe } from '~/framework/typeAliases';

export interface ISnackbarButton {
  label: string;
  location: Maybe<RawLocation>;
}

export class SnackbarButton implements ISnackbarButton {
  label: string;
  location: Maybe<RawLocation>;

  constructor(label: string, location: Maybe<RawLocation> = undefined) {
    this.label = label;
    this.location = location;
  }
}
