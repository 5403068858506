import { OrderAcceptanceCheckStatus } from '~/framework/domain/typeAliases';
import { IRawOrderAcceptanceJsonObject } from '~/framework/server-api/schedule/order/order-acceptance-check/orderAcceptanceCheck';
import { Maybe } from '~/framework/typeAliases';
import { defaultValue } from '~/framework/core/entity';

export interface OrderAcceptanceCheckEntityData {
  id: string;
  acceptanceData: IRawOrderAcceptanceJsonObject | undefined;
  ordersNum: number | undefined;
  estimatedFinishAt: Date | undefined;
  status: OrderAcceptanceCheckStatus;
}

export class OrderAcceptanceCheckEntity {
  id: string = defaultValue();
  persistentId: string = defaultValue();
  acceptanceData: Maybe<IRawOrderAcceptanceJsonObject> = defaultValue();
  ordersNum: Maybe<number> = defaultValue();
  estimatedFinishAt: Maybe<Date> = defaultValue();
  status: OrderAcceptanceCheckStatus = defaultValue();

  constructor(
    id: string,
    acceptanceData: Maybe<IRawOrderAcceptanceJsonObject>,
    ordersNum: Maybe<number>,
    estimatedFinishAt: Maybe<Date>,
    status: OrderAcceptanceCheckStatus
  ) {
    this.id = id;
    this.persistentId = id;
    this.acceptanceData = acceptanceData;
    this.ordersNum = ordersNum;
    this.estimatedFinishAt = estimatedFinishAt;
    this.status = status;
  }
}
