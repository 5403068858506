
import Vue, { PropType } from 'vue';
import { google } from 'google-maps';
import { Maybe } from '~/framework/typeAliases';
import { IAddress } from '~/framework/services/google-maps/address';
import { MapDialogResultType } from '~/components/common/r-map-dialog/resultType';
import { wait } from '~/framework/async';
import { GoogleMapService, IGoogleMapService } from '~/framework/services/google-maps/googleMapService';
import { AddressesFactory } from '~/framework/services/google-maps/addresses';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

type GoogleMap = google.maps.Map;
type GoogleMapMarker = google.maps.Marker;
type LatLng = google.maps.LatLngLiteral;

type DataType = {
  /**
   * geocoder などを非同期で利用するためのもの
   */
  google: IGoogleMapService;
  /**
   * 画面上に地図として表示されている Map の実体
   */
  map: Maybe<GoogleMap>;
  /**
   * 地図上のマーカー
   */
  mapMarker: Maybe<GoogleMapMarker>;
  /**
   * Google Maps に推測させた住所
   */
  inferredAddress: Maybe<IAddress>;
  /**
   * マップ内で設定されている緯度
   */
  latitudeValue: Maybe<number>;
  /**
   * マップ内で設定されている経度
   */
  longitudeValue: Maybe<number>;
  /**
   * API の問い合わせなど読み込みを行っている時
   */
  loading: boolean;
  /**
   * マーカーの位置が有効なポイントかどうか
   * - 緯度経度が取れて
   * - 車で移動可能かどうか
   */
  isLocationValid: boolean;
  /**
   * 最後に API を利用した時間
   */
  lastQueriedAt: Maybe<Date>;
  /**
   * 検証したい場所
   */
  scheduledLocation: Maybe<LatLng>;
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Change = 'change',
}

const defaultAddress = '東京都千代田区';
const addressNotTravelableMessage = 'その場所へは車で移動できないため登録できません。';

export default Vue.extend({
  name: 'RMapDialog',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  props: {
    isConfirmationMode: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isActive: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    postalCode: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address1: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address2: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    address3: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    latitude: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    longitude: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      google: new GoogleMapService(this.$google),
      map: undefined,
      mapMarker: undefined,
      inferredAddress: undefined,
      latitudeValue: undefined,
      longitudeValue: undefined,
      loading: false,
      isLocationValid: false,
      lastQueriedAt: undefined,
      scheduledLocation: undefined,
      listenerDisposer: undefined,
    };
  },
  computed: {
    address(): string {
      const postalCode = this.postalCode === '' ? undefined : this.postalCode;
      const address1 = this.address1 === '' ? undefined : this.address1;
      const address2 = this.address2 === '' ? undefined : this.address2;
      const address3 = this.address3 === '' ? undefined : this.address3;
      const components = [];
      if (postalCode) components.push(postalCode);
      if (address1) components.push(address1);
      if (address2) components.push(address2);
      if (address3) components.push(address3);
      return components.join(' ') || defaultAddress;
    },
    inferredAddressString(): Maybe<string> {
      return this.inferredAddress?.address;
    },
  },
  watch: {
    isActive(value: boolean): void {
      if (value) Vue.nextTick(this.onActivation);
    },
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    onClickConfirmButton(): void {
      if (this.mapMarker === undefined) throw new Error(`Impossible!`);
      if (this.latitudeValue === undefined) throw new Error(`Impossible!`);
      if (this.longitudeValue === undefined) throw new Error(`Impossible!`);
      const resultData: MapDialogResultType = {
        latitude: this.latitudeValue,
        longitude: this.longitudeValue,
        inferredAddress: this.inferredAddress,
      };
      this.$emit(EventTypes.Change, false, resultData);
    },
    onClickCancelButton(): void {
      this.$emit(EventTypes.Change, false, undefined);
    },
    async onActivation(): Promise<void> {
      this.loading = true;
      this.latitudeValue = this.latitude;
      this.longitudeValue = this.longitude;
      this.inferredAddress = undefined;
      let bounds;

      // 緯度経度が既に入力されていた場合はその値を利用し、設定されていなかった場合のみ
      // 住所から緯度経度を推測して利用する
      if (this.latitudeValue === undefined && this.longitudeValue === undefined) {
        const result = await this.getInitialLocation();
        this.latitudeValue = result.geometry.location.lat();
        this.longitudeValue = result.geometry.location.lng();
        bounds = result.geometry.bounds;
      }

      // この時点でこの値がない事はあり得ない前提
      if (this.latitudeValue === undefined) throw new Error(`Impossible!`);
      if (this.longitudeValue === undefined) throw new Error(`Impossible!`);

      const mapElement = document.getElementById('map');
      if (mapElement === null) throw new Error(`Could not find map element!`);
      this.map = new this.$google.maps.Map(mapElement, {
        center: { lat: this.latitudeValue, lng: this.longitudeValue },
        zoom: 17,
      });
      if (bounds !== undefined) this.map.fitBounds(bounds);
      this.mapMarker = new this.$google.maps.Marker({
        position: { lat: this.latitudeValue, lng: this.longitudeValue },
        map: this.map,
        draggable: true,
      });
      this.mapMarker.addListener('dragend', this.onMarkerDragEnd);
      await this.verifyLocation();
      this.loading = false;
    },
    async onMarkerDragEnd(): Promise<void> {
      const location = this.mapMarker?.getPosition();
      if (location === null || location === undefined) return;
      this.scheduledLocation = { lat: location.lat(), lng: location.lng() };
      while (this.loading) {
        await wait(1000);
      }
      if (this.scheduledLocation === undefined) return;
      this.loading = true;
      this.latitudeValue = this.scheduledLocation.lat;
      this.longitudeValue = this.scheduledLocation.lng;
      this.scheduledLocation = undefined;
      await this.verifyLocation();
      this.loading = false;
    },
    async verifyLocation(): Promise<void> {
      if (this.latitudeValue === undefined || this.longitudeValue === undefined) return;
      if (this.lastQueriedAt !== undefined) {
        const now = new Date();
        const needToWait = Math.max(0, 1000 - (now.getTime() - this.lastQueriedAt.getTime()));

        await wait(needToWait);
      }
      this.lastQueriedAt = new Date();
      const location = { lat: this.latitudeValue, lng: this.longitudeValue };

      // 住所が取れる事の確認
      try {
        const geocodeRequest: google.maps.GeocoderRequest = { location };
        const results = await this.google.geocode(geocodeRequest);

        const addresses = AddressesFactory.buildAddresses(results);
        this.inferredAddress = addresses.acceptableAddress;
      } catch (error) {
        this.inferredAddress = undefined;
      }

      // 移動できる事の確認
      this.isLocationValid = await this.google.isTravelable(location);

      if (this.isLocationValid === false) {
        this.$context.snackbar.error(addressNotTravelableMessage);
      }
    },
    async getInitialLocation(): Promise<google.maps.GeocoderResult> {
      try {
        // 入力されたアドレスからは結果が取れない事があるので try しておく

        const geocodeRequest: google.maps.GeocoderRequest = { address: this.address };
        const results = await this.google.geocode(geocodeRequest);
        return results[0];
      } catch (error) {
        // いったんここでのエラーは無視する
      }
      // デフォルトの住所から結果が取れない事は想定しない
      const geocodeRequest: google.maps.GeocoderRequest = { address: defaultAddress };
      const results = await this.google.geocode(geocodeRequest);
      return results[0];
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        Vue.nextTick(this.onClickCancelButton);
      }
      context.stop();
    },
  },
});
