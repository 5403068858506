import { toZenkanaCase, toHankanaCase } from 'encoding-japanese';
import { Maybe } from '~/framework/typeAliases';

/**
 * ルビとして使えるよみがなにする
 *
 * ひらがなはそのままカタカナに変換でき、カタカナとー・はそのまま使えるが漢字などが含まれると
 * 消去されるので、そういったものが含まれた場合に tuple の boolean が true になる
 *
 * - ひらがなをカタカナに変換する
 * - 半角カタカナは全角に直す
 * - 中点とハイフンは許可
 * @param value
 * @return [ルビ, 変換できない文字が含まれていたかどうか]
 */
export const yomiganize = (value: string): [string, boolean] => {
  const containsIllegalValue = /[^ぁ-んァ-ヺー・]/.test(value);
  const yomiganized = toZenkanaCase(value)
    .replace(/[ぁ-ん]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0x60))
    .replace(/[^ァ-ヺー・]/g, '');
  return [yomiganized, containsIllegalValue];
};

/**
 * 半角カタカナにする
 * @param value
 */
export const hankanize = (value: Maybe<string>): string => {
  if (value === undefined) return '';
  return toHankanaCase(value);
};

/**
 * 文字列でtrue, falseが返ってきた時に、booleanに変換するために使用する。
 * @param {string} value
 * @return {boolean}
 */
export const toBoolean = (value: string): boolean => {
  return value.toLowerCase() === 'true';
};
