import { Maybe } from '~/framework/typeAliases';
import { PackingPlacementsByKeywordsOrder } from '~/framework/server-api/typeAliases';
import { IConnection } from '~/framework/server-api/connection';
import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { IGenerationSiteData } from '~/framework/server-api/masters/generationSite';

export const packingPlacement$getByKeywordsSymbol = Symbol('packingPlacement$getByKeywordsSymbol');

export interface IGetByKeywordsApi {
  [packingPlacement$getByKeywordsSymbol]: void;
  getByKeywords(
    first: number,
    after: Maybe<string>,
    condition: ICondition
  ): Promise<IConnection<PackingPlacementAtSite>>;
}

export interface ICondition {
  keywords: Maybe<string[]>;
  containerTypeIds: Maybe<string[]>;
  leftSince: Maybe<Date>;
  orderBy: PackingPlacementsByKeywordsOrder;
}

// 何か別の場所のものが入ってきたらここは | になるイメージ
export type PackingPlacementAtSite = IPackingPlacementAtGenerationSiteData;

export interface IPackingPlacementAtSiteData {
  packingPlacements: IPackingPlacementData[];
  lastAllocatedAt: Maybe<Date>;
  managementStartedOn: Maybe<Date>;
}

export interface IPackingPlacementAtGenerationSiteData extends IPackingPlacementAtSiteData {
  __typename: 'PackingPlacementsAtGenerationSite';
  generationSite: IGenerationSiteData;
}
