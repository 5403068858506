
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

export default Vue.extend({
  name: 'RWeekCalendarColumnHeaderItem',
  props: {
    width: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      thElementRefId: uuidv4(),
    };
  },
  mounted() {
    const thElement = this.$refs[this.thElementRefId] as HTMLTableCellElement;
    const offsetLeft = thElement.offsetLeft;
    thElement.style.left = `${offsetLeft.toString()}px`;
  },
});
