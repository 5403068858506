import { Id, PersistentId } from '~/framework/typeAliases';

export interface IEntity {
  readonly id: Id;
}

// FIXME persistentId がないと既存エンティティとの整合性が崩れるので入れているが、移行が終わったら消す
export interface IPersistentEntity {
  readonly persistentId: PersistentId;
}

/**
 * ! で定義してしまうとプロパティが定義されていない事になってしまう。
 * 何かしら定義されていないとプロパティが存在しない事になってしまいリアクティブにならなくなるのでこの値をつめておく。
 */
export const defaultValue = <Type>() => {
  return undefined as unknown as Type;
};

/*
const idSymbol: unique symbol = Symbol('symbol that identifies the class is Id');
const persistentIdSymbol: unique symbol = Symbol('symbol that identifies the class is PersistentId');

export class Id extends String {
  [idSymbol] = idSymbol;

  isEqualTo(other: Id): boolean {
    return this.toString() === other.toString();
  }
}

export class PersistentId extends Id {
  [persistentIdSymbol] = persistentIdSymbol;

  isEqualTo(other: PersistentId): boolean {
    return this.toString() === other.toString();
  }
}
*/

export function ids(entities: IEntity[]): string[] {
  return entities.map((entity) => entity.id);
}
