import { toHankakuCase } from 'encoding-japanese';

export enum AllowNumberTypes {
  /**
   * Allow numbers only 0-9.
   */
  OnlyDigits,
  /**
   * Allow numbers and first period in the text.
   */
  DecimalNumbers,
  /**
   * Allow floating point notations. `3.456e2`
   */
  FloatingPointNotations,
}

const ALLOW_ONLY_DIGITS_REGEX = /[^0-9]/g;
const ALLOW_DECIMAL_REGEX = /[^0-9.]/g;
const ALLOW_FLOATING_POINT_REGEX = /[^0-9.e]/g;
const REPLACEMENT_TEXT = '';

/**
 * A function constructor to sanitize numerical text.
 * @param { AllowNumberTypes } allowNumberType Defines which method to use to sanitize input value. By default mimics `type="number"` as closely as possible. Default value `FloatingPointNotations`.
 * @param { boolean } allowNegative Defines if negative numbers are accepted. Default value `true`.
 * @returns { (value: string) => string } A function that takes value as argument, and returns sanitized string value.
 */
export const sanitizeNumericalText = (
  allowNumberType: AllowNumberTypes = AllowNumberTypes.FloatingPointNotations,
  allowNegative: boolean = true
): ((value: string) => string) => {
  return (value: string): string => {
    let sanitizedValue = value;

    // Convert full-width to half-width characters.
    sanitizedValue = toHankakuCase(sanitizedValue);

    // Store if value is a negative value, to make sanitized value negative in the last step.
    const isNegative = value[0] === '-';
    if (isNegative) sanitizedValue = sanitizedValue.slice(1);

    if (allowNumberType === AllowNumberTypes.OnlyDigits) {
      sanitizedValue = sanitizedValue.replace(ALLOW_ONLY_DIGITS_REGEX, REPLACEMENT_TEXT);
    } else {
      // If multiple periods exist, then only keep the first.
      const arrays = sanitizedValue.split('.');
      if (arrays.length > 1) {
        const beforePeriod = arrays.shift();
        const afterPeriod = arrays.join('');
        sanitizedValue = `${beforePeriod}.${afterPeriod}`;
      }

      if (allowNumberType === AllowNumberTypes.DecimalNumbers) {
        sanitizedValue = sanitizedValue.replace(ALLOW_DECIMAL_REGEX, REPLACEMENT_TEXT);
      } else if (allowNumberType === AllowNumberTypes.FloatingPointNotations) {
        // Remove if first character is `e`.
        if (sanitizedValue === 'e') sanitizedValue = '';
        else sanitizedValue = sanitizedValue.replace(ALLOW_FLOATING_POINT_REGEX, REPLACEMENT_TEXT);
      } else {
        throw new Error(`Unknown \`allowNumberType\`. ${allowNumberType}`);
      }
    }

    if (allowNegative && isNegative) sanitizedValue = `-${sanitizedValue}`;

    return sanitizedValue;
  };
};
