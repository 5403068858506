import {
  AllDriverAttendanceTemplatesQuery,
  AllDriverAttendanceTemplatesQueryVariables,
  CreateDriverAttendanceTemplatesMutation,
  CreateDriverAttendanceTemplatesMutationVariables,
  DriverAttendanceTemplatesByIdsQuery,
  DriverAttendanceTemplatesByIdsQueryVariables,
  UpdateDriverAttendanceTemplatesMutation,
  UpdateDriverAttendanceTemplatesMutationVariables,
} from '@/graphql/graphQLServerApi';
import {
  AllDriverAttendanceTemplates,
  DriverAttendanceTemplatesByIds,
} from '@/../graphql/server-api/queries/driver-attendance-template.gql';
import {
  CreateDriverAttendanceTemplates,
  UpdateDriverAttendanceTemplates,
} from '@/../graphql/server-api/mutations/driver-attendance-template.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IDriverAttendanceTemplateData } from '~/framework/server-api/masters/driverAttendanceTemplate';

import { WithoutId } from '~/framework/typeAliases';
import { Exception } from '~/framework/core/exception';

// ほぼあり得ないが、念のためデータ整合性をチェックしておく
const validateData = (datas: WithoutId<IDriverAttendanceTemplateData>[]) => {
  for (const data of datas) {
    if (data.restPeriodStart && data.restPeriodEnd) {
      if (data.restPeriodStart < data.regularWorkPeriodStart) {
        throw new Exception(
          `restPeriodStart should be greater than or equal to regularWorkPeriodStart! regularWorkPeriodStart: ${data.regularWorkPeriodStart}, restPeriodStart: ${data.restPeriodStart}`
        );
      }
      if (data.regularWorkPeriodEnd < data.restPeriodEnd) {
        throw new Exception(
          `restPeriodEnd should be less than or equal to regularWorkPeriodEnd! regularWorkPeriodEnd: ${data.regularWorkPeriodEnd}, restPeriodEnd: ${data.restPeriodEnd}`
        );
      }
    }
  }
};

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllDriverAttendanceTemplatesQuery, AllDriverAttendanceTemplatesQueryVariables>({
      query: AllDriverAttendanceTemplates,
      variables: {},
    });
    return result.allDriverAttendanceTemplates;
  }
}

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<DriverAttendanceTemplatesByIdsQuery, DriverAttendanceTemplatesByIdsQueryVariables>({
      query: DriverAttendanceTemplatesByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.driverAttendanceTemplatesByIds, `DriverAttendanceTemplatesByIds`);
    return result.driverAttendanceTemplatesByIds;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: WithoutId<IDriverAttendanceTemplateData>[]) {
    validateData(data);
    const result = await this.mutate<
      CreateDriverAttendanceTemplatesMutation,
      CreateDriverAttendanceTemplatesMutationVariables
    >({
      mutation: CreateDriverAttendanceTemplates,
      variables: { driverAttendanceTemplates: data },
    });
    this.validateArrayConsistency(data, result.createDriverAttendanceTemplates, `CreateDriverAttendanceTemplates`);
    return result.createDriverAttendanceTemplates;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IDriverAttendanceTemplateData[]) {
    validateData(data);
    const result = await this.mutate<
      UpdateDriverAttendanceTemplatesMutation,
      UpdateDriverAttendanceTemplatesMutationVariables
    >({
      mutation: UpdateDriverAttendanceTemplates,
      variables: { driverAttendanceTemplates: data },
    });
    this.validateArrayConsistency(data, result.updateDriverAttendanceTemplates, `UpdateDriverAttendanceTemplates`);
    return result.updateDriverAttendanceTemplates;
  }
}
