
import Vue, { PropType } from 'vue';

/**
 * This component handles text inputs. Passing a sanitization function will sanitize the input accordingly.
 * - `v-model` bind in parent component is expected to be a string or undefined.
 * - `v-model` is updated on `change` event.
 * - Return value is either a string or undefined.
 * - Non-props attributes are forwarded to `v-text-field`.
 * */
export default Vue.extend({
  name: 'RVTextField',
  // Non prop attributes need to be manually bound to `v-text-field`.
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number] as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    sanitizeFunction: {
      type: Function as PropType<undefined | ((value: string) => string)>,
      required: false,
      default: undefined,
    },
  },
  data(): {
    /**
     * This value is synced with user input, using input event.
     */
    internalValue: string | number | undefined;
  } {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    /**
     * Update `internalValue` if `value` is updated outside this component.
     */
    value(newValue: string | undefined) {
      this.updateInnerValueBasedOnValue(newValue);
    },
    async sanitizeFunction() {
      await this.onChange();
    },
  },
  methods: {
    onInput(input: string | number | undefined): void {
      /**
       * Exceptions in value of `input` when using `type="number"`
       * |-------------------|----------------|
       * | actual input text | value of input |
       * |-------------------|----------------|
       * | e                 | ''             |
       * | e1                | ''             |
       * | 1e                | ''             |
       * | .                 | ''             |
       * | 1.                | '1'            |
       * | -                 | ''             |
       * | 1-                | ''             |
       * | 1-2-3             | ''             |
       * |-------------------|----------------|
       */
      this.internalValue = input;
    },
    async onChange(): Promise<void> {
      if (typeof this.internalValue === 'number') return;

      const sanitizedInput = this.internalValue === undefined ? undefined : this.sanitizeInput(this.internalValue);
      this.$emit('change', sanitizedInput);
      // Wait for change event to do it's work in parent component.
      await this.$nextTick();
      await this.updateInnerValueBasedOnValue(this.value);
    },
    async updateInnerValueBasedOnValue(value: string | number | undefined): Promise<void> {
      // NOTE: `if` statement is a HACK. When `value` is `undefined` or `''`.
      // This hack is necessary to correctly update text visible to user in input element.
      if (typeof value === 'string' && (value === '' || value === undefined)) {
        // NOTE: There is no specific meaning in `'0'`. Any non empty string works.
        this.internalValue = '0';
        await this.$nextTick();
      } else if (typeof value === 'number' || value === undefined) {
        this.internalValue = 0;
      }
      this.internalValue = value;
    },
    sanitizeInput(value: string): string {
      if (this.sanitizeFunction) {
        return this.sanitizeFunction(value);
      } else {
        return value;
      }
    },
  },
});
