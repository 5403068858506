import { Maybe, PersistentId } from '~/framework/typeAliases';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { OrderScrollTarget } from '~/framework/view-models/panels/orderFormPanel';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { PseudoOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
// このコンポーネントが扱えるインターフェースはこのコンポーネントの領域で定義されているべきと考えるので、
// ここにインターフェースを定義しておく。実際のデータを提供する側でこのインターフェースを実装する。

/**
 * infeasibility に対してありえる解決方法
 */
export enum InfeasibilitySolutionTypes {
  EditOrder,
  EditPreloadStatus,
  EditGenerationSite,
  EditAttendance,
  EditDriver,
  EditDriverAttendance,
  EditDisposalSite,
  RinLink,
}

export interface IPotentialModification {
  drivers: Maybe<IPotentialModificationDriver[]>;
  orders: Maybe<IPotentialModificationOrder[]>;
  disposalSites: Maybe<IPotentialModificationDisposalSite[]>;
}

export interface IPotentialModificationDriver extends AggregatedDriverEntity {
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
  idealOvertimeWorkType: Maybe<OvertimeWorkType>;
  idealOvertimeWorkableDuration: Maybe<number>;
}
// NOTE: 受注の排出場到着時間に関する修正 suggest
export interface IPotentialModificationOrder {
  orderId: PseudoId;
  order: PseudoOrderEntity;
  collectablePeriodStart: number;
  collectablePeriodEnd: number;
  idealArrivalTime: number;
}

// NOTE: 受注の指定処分場に関する修正 suggest
export interface IPotentialModificationDisposalSite extends AggregatedDisposalSiteEntity {
  // NOTE: 修正すべき処分場が指定されている受注ID
  orderId: PseudoId;
  order: PseudoOrderEntity;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  idealArrivalTime: number;
  attendance: Maybe<IPotentialModificationDisposalSiteAttendance>;
}

// NOTE: 処分場の稼働状況に関する修正 suggest
export interface IPotentialModificationDisposalSiteAttendance {
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  idealArrivalTime: number;
}

export interface IInfeasibilitySolution<SolutionOption = never> {
  type: InfeasibilitySolutionTypes;
  option?: SolutionOption;
}

export interface IRinLinkSolutionOption {
  path: string;
  label: string;
}

export class EditOrderSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditOrder;
  orderId: PersistentId;
  constructor(id: PersistentId) {
    this.orderId = id;
  }
}

export interface IRinLinkOrderSolutionOption {
  scrollTarget?: OrderScrollTarget;
}
export class EditPreloadStatusSolution implements IInfeasibilitySolution<IRinLinkOrderSolutionOption> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditPreloadStatus;
  orderId: PersistentId;
  option?: IRinLinkOrderSolutionOption;
  constructor(id: PersistentId, scrollTarget?: Maybe<OrderScrollTarget>) {
    this.orderId = id;
    this.option = {
      scrollTarget,
    };
  }
}

export class EditGenerationSiteSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditGenerationSite;
}

export class EditAttendanceSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditAttendance;
}

export class EditDriverAttendanceSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditDriverAttendance;
  driverId: PersistentId;
  constructor(id: PersistentId) {
    this.driverId = id;
  }
}

export class EditDriverSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditDriver;
}

export class EditDisposalSiteSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditDisposalSite;
  disposalSiteId: PersistentId;
  constructor(id: PersistentId) {
    this.disposalSiteId = id;
  }
}
export class RinLinkSolution implements IInfeasibilitySolution<IRinLinkSolutionOption> {
  type: InfeasibilitySolutionTypes;
  option: IRinLinkSolutionOption;

  constructor(path: string, label: string) {
    this.type = InfeasibilitySolutionTypes.RinLink;
    this.option = {
      path,
      label,
    };
  }
}
