
import Vue, { PropType } from 'vue';
import { secsToHhMm } from '~/framework/services/date-time/date-time';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RDriverFormAttendanceSelect',
  model: {
    event: EventTypes.Change,
    prop: 'value',
  },
  props: {
    entities: {
      type: Array as PropType<DriverAttendanceTemplateEntity[]>,
      default() {
        return [];
      },
    },
    value: {
      type: undefined as any as PropType<DriverAttendanceTemplateEntity>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    secsToHhMm,
    onChange(changeArgs: any): void {
      this.$emit(EventTypes.Change, changeArgs);
    },
  },
});
