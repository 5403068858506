import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedBaseSiteEntity } from '~/framework/domain/masters/base-site/aggregatedBaseSiteEntity';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { IBaseSiteData } from '~/framework/server-api/masters/baseSite';

export class AggregatedBaseSiteMapper extends StoredMapperBase<IBaseSiteData, AggregatedBaseSiteEntity> {
  protected store: AggregatedBaseSiteStore;
  private userMapper: UserMapper;

  constructor(store: AggregatedBaseSiteStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IBaseSiteData): AggregatedBaseSiteEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    return new AggregatedBaseSiteEntity(data, createdBy);
  }

  protected updateWithData(data: IBaseSiteData, entity: AggregatedBaseSiteEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    entity.update(data, createdBy);
  }
}
