import { getDay } from 'date-fns';
import nationalHolidaySettings from '~/assets/settings/nationalHolidays.json';
import { Maybe } from '~/framework/typeAliases';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';

class NationalHoliday {
  readonly date: Date;
  readonly name: string;

  constructor(date: Date, name: string) {
    this.date = date;
    this.name = name;
  }
}

export class NationalHolidayService {
  private readonly nationalHolidayMap: Map<string, NationalHoliday> = new Map<string, NationalHoliday>();

  constructor() {
    for (const setting of nationalHolidaySettings) {
      const nationalHoliday = new NationalHoliday(new Date(setting.date), setting.name);
      this.nationalHolidayMap.set(this.getDateKey(nationalHoliday.date), nationalHoliday);
    }
  }

  /**
   * 与えられた日が国民の祝日かどうかを取得する
   * @param date
   */

  isNationalHoliday(date: Date): boolean {
    return this.nationalHolidayMap.has(this.getDateKey(date));
  }

  /**
   * 国民の祝日のデータがあれば取得する
   * @param date
   */
  getNationalHoliday(date: Date): Maybe<NationalHoliday> {
    return this.nationalHolidayMap.get(this.getDateKey(date));
  }

  /**
   * 勤怠ルールと国民の祝日を考慮して休日かどうかを判定する
   */
  isHoliday(date: Date, holidayRule: HolidayRuleEntity): boolean {
    const day = getDay(date);
    if (holidayRule.isDayHoliday(day)) return true;
    if (holidayRule.nationalHoliday && this.isNationalHoliday(date)) return true;
    return false;
  }

  private getDateKey(date: Date): string {
    return date.toDateString();
  }
}
