
import Vue from 'vue';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import {
  greaterThanOrEqualToZero,
  greaterThanZero,
  required,
  min,
  max,
  maxLength,
} from '~/framework/view-models/rules';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import RLoadableContainerTypeInput from '~/components/panels/masters/r-car-type-form/RLoadableContainerTypeInput.vue';
import {
  carTypeSymbol,
  CarTypeApplicationService,
} from '~/framework/application/masters/car-type/carTypeApplicationService';
import { IllegalStateException } from '~/framework/core/exception';
import { ICarTypeMutationError } from '~/framework/server-api/masters/carType';
import { FormErrors, IFormErrors } from '~/components/panels/masters/r-car-type-form/errors';
import {
  ApiDataFactory,
  FormValues,
  FormValuesFactory,
  IFormValues,
} from '~/components/panels/masters/r-car-type-form/formValues';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import {
  orderGroupSymbol,
  OrderGroupApplicationService,
} from '~/framework/application/masters/order-group/orderGroupApplicationService';
import {
  PackingStyleApplicationService,
  packingStyleSymbol,
} from '~/framework/application/masters/packingStyleApplicationService';
import {
  ContainerTypeApplicationService,
  containerTypeSymbol,
} from '~/framework/application/masters/container-type/containerTypeApplicationService';

enum FormMode {
  Register,
  Edit,
}

type Masters = {
  orderGroups: OrderGroupEntity[];
  containerTypes: AggregatedContainerTypeEntity[];
  packingStyles: PackingStyleEntity[];
};

type DataType = {
  carTypeApplicationService: CarTypeApplicationService;
  orderGroupApplicationService: OrderGroupApplicationService;
  packingStyleApplicationService: PackingStyleApplicationService;
  containerTypeApplicationService: ContainerTypeApplicationService;
  isActive: boolean;
  isCloseConfirmDialogActive: boolean;
  isRegisterConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  registrationConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
  title: string;
  isFormValid: boolean;
  formValues: Maybe<IFormValues>;
  masters: Maybe<Masters>;
  formMode: FormMode;
  rules: { [key: string]: ValidationRule };
  initialFormValues: Maybe<IFormValues>;
  editingCarType: Maybe<AggregatedCarTypeEntity>;
  createdCarType: Maybe<AggregatedCarTypeEntity>;
  updatedCarType: Maybe<AggregatedCarTypeEntity>;
  isRegistering: boolean;
  isRegisterButtonDisabled: boolean;
  formErrors: IFormErrors;
  initialShowFullCountDetails: boolean;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RCarTypeForm',
  components: {
    RLoadableContainerTypeInput,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const carTypeApplicationService = this.$context.applications.get(carTypeSymbol);
    const orderGroupApplicationService = this.$context.applications.get(orderGroupSymbol);
    const packingStyleApplicationService = this.$context.applications.get(packingStyleSymbol);
    const containerTypeApplicationService = this.$context.applications.get(containerTypeSymbol);
    return {
      carTypeApplicationService,
      orderGroupApplicationService,
      packingStyleApplicationService,
      containerTypeApplicationService,
      isActive: false,
      isCloseConfirmDialogActive: false,
      isRegisterConfirmDialogActive: false,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      registrationConfirmDialogResolver: undefined,
      FormMode,
      formValues: undefined,
      masters: undefined,
      initialFormValues: undefined,
      title: '',
      formMode: FormMode.Register,
      isFormValid: true,
      editingCarType: undefined,
      createdCarType: undefined,
      updatedCarType: undefined,
      isRegistering: false,
      rules: { required, greaterThanZero, greaterThanOrEqualToZero, max999: max(999) },
      isRegisterButtonDisabled: false,
      formErrors: new FormErrors(),
      initialShowFullCountDetails: false,
    };
  },
  computed: {
    isOrderGroupShown(): boolean {
      return 2 <= (this.masters?.orderGroups.length ?? 0);
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.CAR_TYPE;
    },
  },
  watch: {
    isFormValid(value: boolean): void {
      this.isRegisterButtonDisabled = !value;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.carTypeFormPanel.openFormEvent.on(this.onOpen);
    this.$context.panels.carTypeFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
    this.carTypeApplicationService.addCreatePresenter({
      create: (entity: AggregatedCarTypeEntity) => this.onCreate(entity),
      errorOnCreate: (error: ICarTypeMutationError) => this.onCreateError(error),
    });
    this.carTypeApplicationService.addUpdatePresenter({
      update: (entity: AggregatedCarTypeEntity) => this.onUpdate(entity),
      errorOnUpdate: (error: ICarTypeMutationError) => this.onUpdateError(error),
    });
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    min,
    max,
    maxLength,
    /**
     * @public
     */
    async open(carType: Maybe<AggregatedCarTypeEntity> = undefined): Promise<void> {
      if (this.isActive) return;
      await this.initializeForm(carType);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: ICloseEntityFormArgs<AggregatedCarTypeEntity> = {
        entity: this.editingCarType,
        createdEntity: this.createdCarType,
        updatedEntity: this.updatedCarType,
        removedEntity: undefined,
      };
      this.$context.panels.carTypeFormPanel.closeFormEvent.emit(closeArgs);
    },
    async onOpen(args: IOpenEntityFormArgs<AggregatedCarTypeEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async onCloseForm(): Promise<boolean> {
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible`);
      if (this.initialFormValues === undefined) throw new IllegalStateException(`Impossible`);
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.formValues.isEqualTo(this.initialFormValues) === false) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    onConfirmRegistrationClose(value: boolean): void {
      this.isRegisterConfirmDialogActive = false;
      if (this.registrationConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.registrationConfirmDialogResolver(value);
    },
    /**
     *
     * @param carType
     */
    async initializeForm(carType: Maybe<AggregatedCarTypeEntity>): Promise<void> {
      const [orderGroups, packingStyles, containerTypes] = await Promise.all([
        this.orderGroupApplicationService.getAll(),
        this.packingStyleApplicationService.getAll(),
        this.containerTypeApplicationService.getAll(),
      ]);
      this.masters = { orderGroups, packingStyles, containerTypes };

      this.editingCarType = carType;
      this.initialFormValues = new FormValuesFactory(orderGroups, packingStyles, containerTypes).build(carType);
      this.formValues = FormValues.clone(this.initialFormValues);
      this.formErrors = new FormErrors();
      this.formMode = this.editingCarType === undefined ? FormMode.Register : FormMode.Edit;
      this.title = this.formMode === FormMode.Register ? '車種の登録' : '車種の編集';
      // 二段目の入力がすべて空であれば1段目と2段めに積荷できる個数を表示の項目をfalseにする
      // この項目は初期値だけを子コンポーネントに投げて、以降は子コンポーネントのほうの値が変更される形になるのでこちら側でリアクティブにする必要はない
      // this.initialShowFullCountDetailsをeditingCarType.loadableContainerTypes配列の中のupperTierFullCountが一つでも数値が入っていればtrueにする
      this.initialShowFullCountDetails =
        this.editingCarType?.loadableContainerTypes.some(
          (loadableContainerType) => loadableContainerType.upperTierFullCount > 0
        ) ?? false;
      if (orderGroups.length === 0) {
        console.log(`Found zero OrderGroup. This may cause unexpected behavior.`);
      }
    },
    async onRegisterButtonClicked(): Promise<void> {
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible!`);

      // コンテナの空、満載を入力していないものが一つでもあるか確認する
      if (this.formValues.hasInvalidLoadableContainerTypes()) {
        const registrationConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isRegisterConfirmDialogActive = true;
          this.registrationConfirmDialogResolver = resolve;
        });
        const isOkToRegister = await registrationConfirmDialogPromise;
        if (isOkToRegister === false) return;
      }

      this.isRegistering = true;
      this.formErrors.resetAllErrors();
      if (this.formMode === FormMode.Register) {
        await this.carTypeApplicationService.create(new ApiDataFactory().buildCreateData(this.formValues));
      } else if (this.formMode === FormMode.Edit) {
        await this.carTypeApplicationService.update(new ApiDataFactory().buildUpdateData(this.formValues));
      }
      this.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CAR_TYPE,
        [AdditionalInfoKeys.MODE]: this.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },
    onCreate(entity: AggregatedCarTypeEntity): void {
      this.createdCarType = entity;
      this.$context.snackbar.success('車種の登録完了');
      this.$nextTick(this.close);
    },
    onCreateError(error: ICarTypeMutationError): void {
      this.handleFormError(error);
    },
    onUpdate(entity: AggregatedCarTypeEntity): void {
      this.updatedCarType = entity;
      this.$context.snackbar.success('車種の編集完了');
      this.$nextTick(this.close);
    },
    onUpdateError(error: ICarTypeMutationError): void {
      this.handleFormError(error);
    },
    handleFormError(error: ICarTypeMutationError): void {
      for (const anError of error.errors) {
        if (anError.__typename === 'DuplicatedNameError') {
          this.formErrors.addError('name', '同じ名前が存在します');
        }
      }
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.carTypeFormPanel.updateDisplayedWidth(value);
    },
  },
});
