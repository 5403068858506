import { AxiosResponse } from 'axios';
import { CurrentSessionQuery, CurrentSessionQueryVariables } from '@/graphql/graphQLServerApi';
import { CurrentSession } from '@/../graphql/server-api/queries/session.gql';
import {
  authentication$signInSymbol,
  SignInData,
  SignInException,
  SignInStatus,
} from '~/framework/server-api/authentication/signIn';
import { AxiosApiBase } from '~/framework/port.adapter/server-api/axiosApiBase';
import {
  authentication$signOutSymbol,
  ISignOutApi,
  SignOutException,
} from '~/framework/server-api/authentication/signOut';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  ICurrentSessionApi,
  ISessionData,
  authentication$currentSessionSymbol,
} from '~/framework/server-api/authentication/session';

/**
 * サインイン時に受け取る body の内容
 */
export type SignInResponsePayload = {
  status: SignInStatus;
};

const signInPath = '/api/users/sign_in';
const signOutPath = '/api/users/sign_out';

/**
 * axios のリクエストに指定する HTTP ステータスのバリデータ
 * ここで true を返したものは例外とはならずに正常系として扱われる
 * @param status
 */
export const validateSignInStatus = (status: number) => {
  return status === 201 || status === 401;
};

/**
 * axios のリクエストに指定する HTTP ステータスのバリデータ
 * ここで true を返したものは例外とはならずに正常系として扱われる
 * @param status
 */
export const validateSignOutStatus = (status: number) => {
  return status === 204;
};

export class SignInApi extends AxiosApiBase {
  [authentication$signInSymbol] = undefined;
  async signIn(data: SignInData): Promise<SignInStatus> {
    try {
      const loginResult: AxiosResponse<SignInResponsePayload> = await this.axios.post(signInPath, data, {
        validateStatus: validateSignInStatus,
      });
      if (loginResult.data.status === SignInStatus.Success) {
        return SignInStatus.Success;
      } else {
        return SignInStatus.Failed;
      }
    } catch (e: any) {
      // 201 でも 401 でもない本気のエラーはこっち
      throw new SignInException(e);
    }
  }
}

export class SignOutApi extends AxiosApiBase implements ISignOutApi {
  [authentication$signOutSymbol] = undefined;
  async signOut(): Promise<void> {
    try {
      await this.axios.delete(signOutPath, { validateStatus: validateSignOutStatus });
    } catch (e: any) {
      throw new SignOutException(e);
    }
  }
}

export class CurrentSessionApi extends GraphqlApiBase implements ICurrentSessionApi {
  [authentication$currentSessionSymbol] = undefined;

  async getCurrentSession(): Promise<ISessionData> {
    const result = await this.query<CurrentSessionQuery, CurrentSessionQueryVariables>({
      query: CurrentSession,
      variables: {},
    });
    return result.currentSession;
  }
}
