
import Vue, { PropType } from 'vue';
import ROrderFormSelect, {
  ItemTextColorClassType,
} from '~/components/panels/schedule/r-order-form/ROrderFormSelect.vue';
import { ItemType as ROrderFormSelectItem } from '~/components/panels/schedule/r-order-form/rOrderFormSelectItem';
import { GenerationSiteTaskCategory } from '~/framework/view-models/generationSiteTaskCategory';
import { VuetifyColors } from '~/framework/constants';
import { Maybe } from '~/framework/typeAliases';
import { IllegalStateException } from '~/framework/core/exception';

type DataType = {
  isConfirmDialogActive: boolean;
  itemMap: Map<ItemTypes, ROrderFormSelectItem>;
  confirmDialogResolver: Maybe<(value: boolean) => void>;
};

enum ItemTypes {
  Irregular,
  Reset,
}

enum EventTypes {
  Change = 'change',
  Reset = 'reset',
}

export default Vue.extend({
  name: 'RTaskTypeSelect',
  components: {
    ROrderFormSelect,
  },
  model: {
    prop: 'value',
    event: EventTypes.Change,
  },
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    value: {
      type: Number as PropType<GenerationSiteTaskCategory>,
      required: true,
    },
  },
  data(): DataType {
    return {
      isConfirmDialogActive: false,
      confirmDialogResolver: undefined,
      itemMap: new Map(),
    };
  },
  computed: {
    items(): ROrderFormSelectItem[] {
      // その他の車のタスクは新規には指定できない
      const items: ROrderFormSelectItem[] = [];
      if (this.value === GenerationSiteTaskCategory.TaskWithContainer) {
        items.push(this.itemMap.getOrError(ItemTypes.Irregular));
      }
      if (this.value === GenerationSiteTaskCategory.Irregular) {
        items.push(this.itemMap.getOrError(ItemTypes.Irregular));
      }
      if (this.value !== GenerationSiteTaskCategory.TaskWithContainer) {
        items.push(this.itemMap.getOrError(ItemTypes.Reset));
      }
      return items;
    },
  },
  beforeMount(): void {
    this.itemMap.set(ItemTypes.Irregular, {
      title: '例外的な作業',
      value: GenerationSiteTaskCategory.Irregular,
      icon: 'ffi-irregular',
      class: ItemTextColorClassType.Primary,
      color: VuetifyColors.Primary,
      onBeforeChange: async () => {
        return await this.confirm();
      },
    });
    this.itemMap.set(ItemTypes.Reset, {
      title: '詳細設定をリセット',
      value: undefined,
      icon: 'mdi-close',
      class: ItemTextColorClassType.Error,
      color: VuetifyColors.Error,
      onBeforeChange: async () => {
        const confirmed = await this.confirm();
        if (confirmed) this.$emit(EventTypes.Reset);
        return Promise.resolve(false);
      },
    });
  },
  methods: {
    onChange(value: Maybe<number>): void {
      this.$emit(EventTypes.Change, value);
    },
    onConfirmDialog(value: boolean): void {
      if (this.confirmDialogResolver === undefined) throw new IllegalStateException(`resolver has not been set!`);
      this.confirmDialogResolver(value);
    },
    confirm(): Promise<boolean> {
      return new Promise<boolean>((resolve) => {
        this.isConfirmDialogActive = true;
        this.confirmDialogResolver = resolve;
      });
    },
  },
});
