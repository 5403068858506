import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { attendance$getByDateRangeSymbol, attendance$createSymbol } from '~/framework/server-api/masters/attendance';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';

export const attendanceSymbol = Symbol('attendance');

export class AttendanceApplicationService {
  private readonly serverApis: ServerApiManager;
  private attendanceMapper: AttendanceMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.attendanceMapper = new AttendanceMapper(store.masters.attendance);
  }

  async getByDateRange(start: Date, end: Date): Promise<AttendanceEntity[]> {
    const attendance$getByDateRangeApi = this.serverApis.get(attendance$getByDateRangeSymbol);
    const result = await attendance$getByDateRangeApi.getByDateRange(start, end);
    const entities = this.attendanceMapper.map(result);
    return entities;
  }

  async getOrCreate(date: Date): Promise<AttendanceEntity> {
    const attendances = await this.getByDateRange(date, date);
    if (attendances.length > 0) return attendances[0];

    // attendanceが存在しないので作成する
    // TODO: 効率が悪いので改善
    const attendance$createApi = this.serverApis.get(attendance$createSymbol);
    await attendance$createApi.create([{ date }]);
    const createdAttendance = (await this.getByDateRange(date, date))[0];
    return createdAttendance;
  }
}
