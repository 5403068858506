import { DriverType } from '~/framework/domain/typeAliases';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { CreateOrderAssignableDriverInput } from '~/graphql/graphQLServerApi';

// isDriversCandidate などにおけるassginableDriverが、
// driverIdを持つ場合とdriverEntityを持つ場合のどちらでも使えるようにする
interface IOrderDriverType {
  driverType: DriverType;
}

export interface IAggregatedOrderAssignableDriver {
  driverType: DriverType;
  driver: AggregatedDriverEntity;
}

/**
 * IOrderAssignableDriver[] から 特定の driverType を指定して driverId[] を取得する
 */
export function getAssignableDriverIdsByDriverType(
  driverType: DriverType,
  assignableDrivers: CreateOrderAssignableDriverInput[]
): string[] {
  return assignableDrivers
    .filter((assignableDriver) => assignableDriver.driverType === driverType)
    .map((assignableDriver) => assignableDriver.driverId);
}

/**
 * driverType を指定して driverId から IOrderAssignableDriver を生成する
 */
export function buildAssignableDriverByEntity(
  driverType: DriverType,
  driver: AggregatedDriverEntity
): CreateOrderAssignableDriverInput {
  return {
    driverType,
    driverId: driver.id,
  };
}

/**
 * driverType を指定して driverId[] から IOrderAssignableDriver[] を生成する
 */
export function buildAssignableDriversByEntities(
  driverType: DriverType,
  drivers: AggregatedDriverEntity[]
): CreateOrderAssignableDriverInput[] {
  return drivers.map((driver) => buildAssignableDriverByEntity(driverType, driver));
}

export function buildAssignableDriversByIds(
  driverType: DriverType,
  driverIds: string[],
  allDriverEntities: AggregatedDriverEntity[]
): CreateOrderAssignableDriverInput[] {
  const drivers = allDriverEntities.filter((driver) => driverIds.includes(driver.id));
  return drivers.map((driver) => buildAssignableDriverByEntity(driverType, driver));
}

/**
 * 乗務員の人数と指定された乗務員の人数を指定して候補かどうか計算する
 * 乗務員の人数より多く指定されていると候補
 */
export function isDriversCandidate(assignedDriverNum: number, assignableDrivers: IOrderDriverType[]) {
  return (
    assignableDrivers.filter((assignableDriver) => assignableDriver.driverType === DriverType.Driver).length >
    assignedDriverNum
  );
}

/**
 * 車台数と指定された運転者の人数から候補かどうか計算する
 * 車台数より多い場合は候補となる
 */
export function isOperatorsCandidate(carNum: number, assignableDrivers: IOrderDriverType[]) {
  return (
    assignableDrivers.filter((assignableDriver) => assignableDriver.driverType === DriverType.Operator).length > carNum
  );
}

/**
 * 乗務員の人数と指定された乗務員の人数を指定して候補かどうか計算する
 * 乗務員の人数 - 車台数より補助員指定が多ければ候補となる
 */
export function isHelpersCandidate(carNum: number, assignedDriverNum: number, assignableDrivers: IOrderDriverType[]) {
  return (
    assignableDrivers.filter((assignableDriver) => assignableDriver.driverType === DriverType.Helper).length >
    assignedDriverNum - carNum
  );
}

/**
 * IOrderAssignableDriver[] から DriverType を指定してその entity を検索して取得する
 */
export function getDriverEntities(
  driverType: DriverType,
  assignableDrivers: IAggregatedOrderAssignableDriver[]
): AggregatedDriverEntity[] {
  return assignableDrivers
    .filter((assignableDriver) => assignableDriver.driverType === driverType)
    .map((assignableDriver) => assignableDriver.driver);
}

export function getDriverEntitiesFromAllDrivers(
  driverType: DriverType,
  assignableDrivers: CreateOrderAssignableDriverInput[],
  allDrivers: AggregatedDriverEntity[]
): AggregatedDriverEntity[] {
  const driverIds = assignableDrivers
    .filter((assignableDriver) => assignableDriver.driverType === driverType)
    .map((assignableDriver) => assignableDriver.driverId);
  return allDrivers.filter((driverEntity) => driverIds.includes(driverEntity.id));
}
