import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  baseSite$createSymbol,
  ICreateData,
  baseSite$getAllSymbol,
  baseSite$updateSymbol,
  IUpdateData,
  IBaseSiteMutationError,
} from '~/framework/server-api/masters/baseSite';

import { AggregatedBaseSiteMapper } from '~/framework/domain/masters/base-site/aggregatedBaseSiteMapper';
import { AggregatedBaseSiteEntity } from '~/framework/domain/masters/base-site/aggregatedBaseSiteEntity';

import { IDisposable } from '~/framework/core/disposable';
import { Ports } from '~/framework/core/ports';

export const baseSiteSymbol = Symbol('baseSite');

export interface ICreatePresenter {
  create(entity: AggregatedBaseSiteEntity): void;
  errorOnCreate(error: IBaseSiteMutationError): void;
}

export interface IUpdatePresenter {
  update(entity: AggregatedBaseSiteEntity): void;
  errorOnUpdate(error: IBaseSiteMutationError): void;
}

export class BaseSiteApplicationService {
  private readonly serverApis: ServerApiManager;
  private readonly createPort: Ports<ICreatePresenter>;
  private readonly updatePort: Ports<IUpdatePresenter>;
  private baseSiteMapper: AggregatedBaseSiteMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.createPort = new Ports();
    this.updatePort = new Ports();
    this.baseSiteMapper = new AggregatedBaseSiteMapper(store.masters.aggregatedBaseSite, store.masters.user);
  }

  async getAll(): Promise<AggregatedBaseSiteEntity[]> {
    const getAllApi = this.serverApis.get(baseSite$getAllSymbol);
    const result = await getAllApi.getAll();
    const entities = this.baseSiteMapper.map(result);
    return entities;
  }

  addCreatePresenter(presenter: ICreatePresenter): IDisposable {
    return this.createPort.add(presenter);
  }

  async create(createData: ICreateData): Promise<void> {
    const createApi = this.serverApis.get(baseSite$createSymbol);
    const createResult = await createApi.create(createData);

    if (createResult.__typename === 'BaseSite') {
      const createdBaseSiteEntity = this.baseSiteMapper.mapSingle(createResult);
      this.createPort.output('create', createdBaseSiteEntity);
    } else if (createResult.__typename === 'BaseSiteMutationError') {
      this.createPort.output('errorOnCreate', createResult);
    } else {
      throw new Error('AbnormalResultException to create base-site mutation');
    }
  }

  addUpdatePresenter(presenter: IUpdatePresenter): IDisposable {
    return this.updatePort.add(presenter);
  }

  async update(updateData: IUpdateData): Promise<void> {
    const updateApi = this.serverApis.get(baseSite$updateSymbol);
    const updateResult = await updateApi.update(updateData);

    if (updateResult.__typename === 'BaseSite') {
      const updatedBaseSiteEntity = this.baseSiteMapper.mapSingle({ ...updateResult });
      this.updatePort.output('update', updatedBaseSiteEntity);
    } else if (updateResult.__typename === 'BaseSiteMutationError') {
      this.updatePort.output('errorOnUpdate', updateResult);
    } else {
      throw new Error('AbnormalResultException to update base-site mutation');
    }
  }
}
