import { StoredMapperBase } from '~/framework/core/mapper';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { HolidayRuleStore } from '~/framework/domain/masters/holiday-rule/holidayRuleStore';
import { IHolidayRuleData } from '~/framework/server-api/masters/holidayRule';

export class HolidayRuleMapper extends StoredMapperBase<IHolidayRuleData, HolidayRuleEntity> {
  protected store: HolidayRuleStore;

  constructor(store: HolidayRuleStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IHolidayRuleData): HolidayRuleEntity {
    return new HolidayRuleEntity(data);
  }

  protected updateWithData(data: IHolidayRuleData, entity: HolidayRuleEntity): void {
    entity.update(data);
  }
}
