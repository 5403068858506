import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { AggregatedDriverStore } from '~/framework/domain/masters/driver/aggregatedDriverStore';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { ContainerTypeTaskTypeStore } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeStore';
import { AggregatedDriverAttendanceStore } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceStore';
import { AggregatedContainerTypeStore } from '~/framework/domain/masters/container-type/aggregatedContainerTypeStore';
import { OfficeSettingStore } from '~/framework/domain/masters/office-setting/officeSettingStore';
import { AggregatedGenerationSiteStore } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteStore';
import { HolidayRuleStore } from '~/framework/domain/masters/holiday-rule/holidayRuleStore';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { AggregatedCarAttendanceStore } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceStore';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { PackingStyleTaskTypeDefaultStore } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { DisposalSiteAttendanceStore } from './disposal-site-attendance/disposalSiteAttendanceStore';

export class MastersStore {
  // normalized
  readonly collectablePeriodTemplate: CollectablePeriodTemplateStore;
  readonly orderGroup: OrderGroupStore;
  readonly driverAttendanceTemplate: DriverAttendanceTemplateStore;
  readonly attendance: AttendanceStore;
  readonly disposalSiteAttendance: DisposalSiteAttendanceStore;
  readonly user: UserStore;
  readonly containerTypeTaskType: ContainerTypeTaskTypeStore;
  readonly officeSetting: OfficeSettingStore;
  readonly holidayRule: HolidayRuleStore;
  readonly packingStyle: PackingStyleStore;
  readonly packingStyleTaskTypeDefault: PackingStyleTaskTypeDefaultStore;
  readonly jwnetWasteMaster: JwnetWasteMasterStore;
  // aggregated
  readonly aggregatedBaseSite: AggregatedBaseSiteStore;
  readonly aggregatedCar: AggregatedCarStore;
  readonly aggregatedCarType: AggregatedCarTypeStore;
  readonly aggregatedCarTypeContainerType: AggregatedCarTypeContainerTypeStore;
  readonly aggregatedDriver: AggregatedDriverStore;
  readonly aggregatedContainerType: AggregatedContainerTypeStore;
  readonly aggregatedDisposalSite: AggregatedDisposalSiteStore;
  readonly aggregatedClient: AggregatedClientStore;
  readonly aggregatedDriverAttendance: AggregatedDriverAttendanceStore;
  readonly aggregatedGenerationSite: AggregatedGenerationSiteStore;
  readonly aggregatedCarAttendance: AggregatedCarAttendanceStore;
  readonly aggregatedWasteType: AggregatedWasteTypeStore;

  constructor() {
    // normalized
    this.collectablePeriodTemplate = new CollectablePeriodTemplateStore();
    this.orderGroup = new OrderGroupStore();
    this.driverAttendanceTemplate = new DriverAttendanceTemplateStore();
    this.attendance = new AttendanceStore();
    this.disposalSiteAttendance = new DisposalSiteAttendanceStore();
    this.user = new UserStore();
    this.containerTypeTaskType = new ContainerTypeTaskTypeStore();
    this.officeSetting = new OfficeSettingStore();
    this.holidayRule = new HolidayRuleStore();
    this.packingStyle = new PackingStyleStore();
    this.packingStyleTaskTypeDefault = new PackingStyleTaskTypeDefaultStore();
    this.jwnetWasteMaster = new JwnetWasteMasterStore();

    // aggregated
    this.aggregatedBaseSite = new AggregatedBaseSiteStore();
    this.aggregatedCar = new AggregatedCarStore();
    this.aggregatedCarType = new AggregatedCarTypeStore();
    this.aggregatedCarTypeContainerType = new AggregatedCarTypeContainerTypeStore();
    this.aggregatedDriver = new AggregatedDriverStore();
    this.aggregatedContainerType = new AggregatedContainerTypeStore();
    this.aggregatedDisposalSite = new AggregatedDisposalSiteStore();
    this.aggregatedClient = new AggregatedClientStore();
    this.aggregatedDriverAttendance = new AggregatedDriverAttendanceStore();
    this.aggregatedGenerationSite = new AggregatedGenerationSiteStore();
    this.aggregatedCarAttendance = new AggregatedCarAttendanceStore();
    this.aggregatedWasteType = new AggregatedWasteTypeStore();
  }
}
