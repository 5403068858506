import { ApolloProvider } from 'vue-apollo';
import { Context } from '@nuxt/types';
import Vue from 'vue';
import { Loader, LoaderOptions } from 'google-maps';
import * as Sentry from '@sentry/vue';
import { ContextSetter } from 'apollo-link-context';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { FetchResult, Operation } from 'apollo-link';
import rinPackage from '../../package.json';
import { ErrorContext, SystemContext, SessionHandler } from '~/framework/systemContext';
import { Secrets } from '~/framework/secrets';
import { createLogger } from '~/framework/logger';
import { Mio } from '~/framework/services/mio/mio';
import rinConf from '~/assets/settings/rin.json';
import { EventManager } from '~/framework/events/eventManager';
import { Maybe, Pixel } from '~/framework/typeAliases';
import { PanelManager } from '~/framework/view-models/panels/panelManager';
import { NotificationManager } from '~/framework/notifications';
import { UIEventManager } from '~/framework/uiEventManager';
import { Clipboard } from '~/framework/clipboard';
import { FeatureManager } from '~/framework/featureManager';
import { Store } from '~/framework/domain/store';
import { ApplicationServiceManager } from '~/framework/application/applicationServiceManager';
import { ServerApiManager } from '~/framework/port.adapter/server-api/serverApiManager';
import { SessionData } from '~/framework/application/authentication/authenticationApplicationService';
import { isDevelopment, isProduction, isStaging } from '~/env';
import { injectDevelopmentDependencies, injectProductionDependencies } from '~/dependencies';
import { IllegalStateException, MaintenanceException } from '~/framework/core/exception';
import { InconsistentSessionException, UnauthenticatedException } from '~/framework/server-api/authentication/session';
import { isIgnorableGoogleMapsRequestError } from '~/framework/services/google-maps/error';
import { isIgnorableResizeObserverError } from '~/framework/core/error';
import { ITypedEvent } from '~/framework/events/typedEvent';
import { unwrap } from '~/framework/core/value';
import { UnauthorizedOperationException } from '~/framework/server-api/authorize';
import { GraphQLException, GraphQLNetworkException } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { GraphQLError } from 'graphql/error/GraphQLError';
import _ from 'lodash';
import { redirectToLogin } from '~/nuxt';

const logger = createLogger('vue');
let systemContext: Maybe<SystemContext>;

const errorContextHandler = (name: string, context: ErrorContext) => {
  Sentry.setContext(name, context);
};

declare global {
  interface ArrayConstructor {
    isArray(arg: readonly any[] | any): arg is readonly any[];
  }

  interface Window {
    session: {
      officeId: string;
      officeName: string;
      id: string;
      username: string;
    };
  }
}

const setLangage = () => {
  document.documentElement.lang = 'ja';
};

const sessionHandler: SessionHandler = (session: SessionData) => {
  window.session = {
    officeId: session.officeId,
    officeName: session.officeName,
    id: session.user.persistentId,
    username: session.user.name,
  };
  Sentry.setUser({
    officeId: session.officeId,
    officeName: session.officeName,
    id: session.user.persistentId,
    username: session.user.name,
  });
  // タグとしてセットしてSlackの通知で表示したい
  Sentry.setTag('officeName', session.officeName);
  Sentry.setTag('userName', session.user.name);
};

/**
 * iPad で vh がおかしくなるので調整するためのもの
 * --vh というプロパティを設定し、本当の描画領域 / 100 したものを突っ込んでおく
 * CSS 側ではこの値を利用して 100vh 相当の値とする
 */
const viewportHeightAdjuster = () => {
  window.addEventListener('resize', () => {
    logger.info(`updating vh, innerWidth: ${window.innerWidth}, innerHeight: ${window.innerHeight}`);
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  window.dispatchEvent(new Event('resize'));
};

/**
 * --vpw を設定するイベントハンドラを設定するためのもの
 *
 * @param systemContext
 */
const virtualPageWidthAdjuster = (systemContext: SystemContext) => {
  window.addEventListener('resize', () => {
    setVirtualPageWidth(systemContext.panels.displayedPanelWidth);
  });
  window.dispatchEvent(new Event('resize'));
};

/**
 * パネルの横幅を与えて --vpw プロパティを設定する。
 * vpw = Virtual Page Width, 画面全体からナビゲーションバーの横幅とパネルの表示幅を引いたもの。
 * 100vpw で実際の表示幅になる。画面全体は、body の clientWidth とした。これは window の innerWidth
 * としてしまうとスクロールバーの横幅が含まれてしまい、実際の表示幅以上になってしまうため。
 *
 * @param panelWidth
 */
const setVirtualPageWidth = (panelWidth: Pixel) => {
  // JS は読み込まれているが body が存在しない様な絶妙なタイミングで resize が呼ばれると DOM 上に
  // body が存在しない状態でここを通ってしまう可能性があり、そういう場合にエラーにならない様に
  // 一応 return しておく。確信はない。
  if (!document.body) return;
  const navigationBarWidth = 56;
  const vpw = (document.body.clientWidth - panelWidth - navigationBarWidth) * 0.01;
  document.documentElement.style.setProperty('--vpw', `${vpw}px`);
};

/**
 * Sentry を初期化する。
 *
 * @param context
 */
const initializeSentry = (context: Context) => {
  Sentry.init({
    Vue,
    release: 'rin-' + rinPackage.version,
    dsn: rinConf.sentry.dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: context.env.rinEnv ?? context.env.nodeEnv,
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    allowUrls: [
      /https:\/\/(?:\d+\.)?(?:stg\.)?app\.haisya-gasira\.com/,
      /https:\/\/(?:\d+\.)?(?:app\.)?stg\.haisya-gasira\.com/,
      /https:\/\/maps.googleapis.com\/(?:\S*)/,
    ],
    beforeSend(event, eventHint) {
      if (
        eventHint?.originalException instanceof UnauthenticatedException ||
        eventHint?.originalException instanceof InconsistentSessionException ||
        eventHint?.originalException instanceof MaintenanceException ||
        (eventHint?.originalException instanceof Error &&
          (isIgnorableGoogleMapsRequestError(eventHint.originalException) ||
            isIgnorableResizeObserverError(eventHint.originalException))) ||
        !document.location.protocol.includes('http')
      ) {
        // プロトコルがHTTPでない場合は送らない
        // ネットワークエラーは基本的にクライアント側の問題なので送らない
        // 残りはハンドルされているので送らない
        return null;
      }

      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: '😢問題が発生しました',
          subtitle: 'チームには報告済ですが、詳細をお教え頂けると問題解決に役立ちます',
          subtitle2: '',
          labelName: 'お名前',
          labelEmail: 'メールアドレス',
          labelComments: '問題の詳細（詳しい操作の手順などをご記入ください）',
          labelSubmit: 'レポートを送信',
          labelClose: '閉じる',
          successMessage: 'ご協力ありがとうございます。問題の解決に努めます🙇‍♂️',
        });
      }
      return event;
    },
  });
};

export default async (context: Context, inject: (key: string, value: any) => void): Promise<void> => {
  if (isProduction() || isStaging() || context.env.enableSentry) initializeSentry(context);
  initializeErrorHandler();

  // app の中は型が付いていないので取り出しておく
  // apolloProvider は中身がある事を期待してよいので undefined 非許容型に変換しておく
  const apolloProvider: ApolloProvider = context.app.apolloProvider!;
  const axios: NuxtAxiosInstance = context.app.$axios!;

  // NOTE initialize で実際に初期化されている事に注意
  // initialize 後でなければ context を参照する事はできない
  systemContext = new SystemContext(
    context.env.rinEnv,
    context.env.nodeEnv,
    context.env.rinVersion,
    errorContextHandler,
    sessionHandler
  );
  const mio = new Mio(systemContext);
  const notification = new NotificationManager(systemContext);
  const secrets = new Secrets(context.env.googleMapApiKey!);
  const eventManager = new EventManager();
  const panelManager = new PanelManager();
  const uiEventManager = new UIEventManager();
  const clipboard = new Clipboard();
  const featureManager = new FeatureManager(systemContext);
  const store = new Store();
  const serverApis = new ServerApiManager();
  const isLocalMio = !!context.env.rinLocalMio;
  if (isProduction()) injectProductionDependencies(serverApis, apolloProvider.defaultClient as any, axios);
  else if (isDevelopment())
    injectDevelopmentDependencies(serverApis, apolloProvider.defaultClient as any, axios, isLocalMio);
  else if (isStaging()) injectProductionDependencies(serverApis, apolloProvider.defaultClient as any, axios);
  else throw new IllegalStateException(`Invalid environment`);
  const applicationServiceManager = new ApplicationServiceManager(store, serverApis);
  systemContext.initialize(
    context,
    secrets,
    mio,
    notification,
    eventManager,
    panelManager,
    uiEventManager,
    clipboard,
    featureManager,
    applicationServiceManager,
    serverApis,
    store
  );

  setLangage();
  viewportHeightAdjuster();
  virtualPageWidthAdjuster(systemContext);
  systemContext.panels.updateDisplayedPanelWidthEvent.on((width) => setVirtualPageWidth(width));

  // Google Maps API 読み込み
  // 都道府県の表記が日本語以外ではバグるので日本語に固定する
  const options: LoaderOptions = { language: 'ja' };
  const loader = new Loader(secrets.googleMapApiKey, options);
  const google = await loader.load();

  inject('rinGtm', systemContext?.gtm);
  inject('context', systemContext);
  inject('log', logger);
  inject('google', google);
};

/**
 * エラーハンドラをセットする起点
 */
const initializeErrorHandler = () => {
  Vue.config.errorHandler = vueErrorHandler;
  // Sentry が自分でunhandledRejection を拡張しているが、それだとその前に処理を差し込めないので上書きする
  window.onunhandledrejection = unhandledExceptionHandler;
  window.addEventListener('error', function (event) {
    console.error(event);
  });
};

// この辺のものが外部のものに依存してしまっているのはやや気持ち悪いけど、起動のシーケンスが
// 1. apollo.*.config.js
// 2. framework.ts
// になるため、起動時には config から context が参照できなく、致し方なくやってしまっている。
export const graphQLContextSetter: ContextSetter = (_, { headers }) => {
  const context = {
    headers: {
      ...headers,
    },
  };
  if (systemContext !== undefined && systemContext.currentSession !== undefined) {
    context.headers['mio-office-id'] = systemContext.currentSession.officeId;
  }
  return context;
};

export const transactionObserver = (requestEvent: ITypedEvent<Operation>, responseEvent: ITypedEvent<FetchResult>) => {
  requestEvent.on(() => {
    unwrap(systemContext).events.loadStartEvent.emit();
  });
  responseEvent.on(() => {
    unwrap(systemContext).events.loadFinishEvent.emit();
  });
};

/**
 * Vueでエラーになった場合のハンドリング
 *
 * @param err
 * @param vm  エラーの原因になったVueのコンポーネント
 *            _vnode.parent に親コンポーネントが入っているので、繰り返せばどのどのコンポーネントが祖先のやつかわかる
 * @param info `info` は Vue 固有のエラー情報です（例： どのライフサイクルフックでエラーが起きたかなど）。 'v-on: Error' などが返ってくる
 */
function vueErrorHandler(err: Error, vm: Vue, info: string) {
  if (!systemContext) throw new Error('SystemContext not found');

  // 未ログイン
  if (err instanceof UnauthenticatedException) {
    systemContext.nuxtContext.redirect('/login');
    return;
  }
  // NOTE: 同じブラウザで異なるオフィスにログインした際、古いタブで発生する例外
  // ページをリロードする
  if (err instanceof InconsistentSessionException) {
    logger.warn('InconsistentSessionException', err);
    location.reload();
    return;
  }
  // 権限不足
  if (err instanceof UnauthorizedOperationException) {
    systemContext.snackbar.error('実行に失敗しました。権限が不足しています。');
    return;
  }
  if (err instanceof GraphQLNetworkException) {
    systemContext.snackbar.error('ネットワークエラーが発生しました。通信が不安定な可能性があります。');
    systemContext.nuxtContext.error(err);
    return;
  }
  if (isIgnorableGoogleMapsRequestError(err)) {
    // Street view の表示にして道がないような場所を見ようとするとエラーになってしまう
    // Google Maps 自体のエラーなのだが、そのままにしておくと rin 側でハンドルされてしまう
    return;
  }
  if (isIgnorableResizeObserverError(err)) {
    return;
  }
  if (err instanceof MaintenanceException) {
    // メンテナンスはrinの異常ではないので、rin側でハンドルしないようにする。
    return;
  }

  systemContext.snackbar.error(info + err);

  // エラー原因のコンポーネントの所在をスタックトレースに足す
  err.stack = createVueStack(vm) + '\n' + err.stack;
  err.name = err + ' Vueコンポーネントハンドル: ' + info;
  console.error(err.name + ':' + err.message + '\n' + err.stack);
  Sentry.captureException(err);
}

/**
 * Vueコンポーネントを渡すとどのコンポーネントに配置されているものか階層構造を出してくれる
 * @param vm
 * @param depth
 */
function createVueStack(vm: Vue, depth: number = 0): string {
  const indent = '  '.repeat(depth);
  const componentName = '<' + _.upperFirst(_.camelCase(`${vm.$options.name}`)) + '>' || 'unknown';
  const parentStack = vm.$parent ? createVueStack(vm.$parent, depth + 1) : '';
  return `${indent}${componentName}\n${parentStack}`;
}

/**
 * キャッチできなかった例外
 * @param event
 */
function unhandledExceptionHandler(event: PromiseRejectionEvent) {
  // NOTE: mio の validation に引っかかる場合などはここに分岐する
  if (event.reason instanceof GraphQLException) {
    graphqlExceptionHandler(event);
    event.preventDefault();
    return;
  }

  if (!systemContext) throw new Error('SystemContext not found');
  console.error('[Unhandled Rejection]', event.reason);

  // NOTE: 未ログインの場合はログイン画面にリダイレクトする
  // エラー画面に遷移しない
  if (event.reason instanceof UnauthenticatedException) {
    logger.warn('UnauthenticatedException', event);
    redirectToLogin(systemContext.nuxtContext);

    return;
  }

  // NOTE: 同じブラウザで異なるオフィスにログインした際、古いタブで発生する例外
  // ページをリロードする
  if (event.reason instanceof InconsistentSessionException) {
    logger.warn('InconsistentSessionException', event);
    location.reload();

    return;
  }

  // NOTE: 権限不足はスナックバーを出してハンドルできたことにする
  // エラー画面に遷移しない
  if (event.reason instanceof UnauthorizedOperationException) {
    logger.warn('UnauthorizedOperationException', event);
    systemContext.snackbar.error('実行に失敗しました。権限が不足しています。');

    return;
  }

  // キャッチされなかったPromiseイベントからエラーオブジェクトを作る
  // このときスタックトレースをPromiseイベントを元に差し替える
  Sentry.setContext('reason', event.reason);

  const captureError = new Error(event.reason);
  captureError.name = '[Unhandled Rejection]: ' + event.reason;
  captureError.stack = event.reason.stack;
  Sentry.captureException(captureError);

  // NOTE: エラー画面には遷移するが、Sentry に通知したくない場合はコメントアウトを外す
  // if (event.reason instanceof IncompatibleBrowserException) {
  //   systemContext.nuxtContext.error(event.reason);

  //   return;
  // }

  if (event.reason instanceof GraphQLNetworkException) {
    systemContext.snackbar.error('ネットワークエラーが発生しました。通信が不安定な可能性があります。');
  }

  // Productionではエラー画面に移動する
  if (systemContext.isProduction) {
    systemContext.snackbar.error('エラーが発生しました。画面を再読み込みしてください。', {
      timeout: 0,
    });
    systemContext.nuxtContext.error(event.reason);
  }
}

/**
 * graphql Errorを捕まえて表示する
 * snackbarとエラーログに出る、sentryにもでる
 * 主にlocal環境の場合などで、サーバーエラーの場合それも追加ででる
 *
 * @param {PromiseRejectionEvent} event - The Promise rejection event.
 * @returns {void}
 */
function graphqlExceptionHandler(event: PromiseRejectionEvent) {
  const err: GraphQLException = event.reason;

  // FE側でキャプチャされたエラーは event.reasonに入る
  console.error('[FEエラー]', event.reason);

  // FE側で投げたクエリはこの辺
  if (err.context) {
    try {
      // Mutation, Query, Resultをjsonとしてパースする
      const mutations: { mutation?: string; variables?: any } = JSON.parse(err.context?.mutations ?? 'null');
      const queries: { query: string; variables?: any } = JSON.parse(err.context?.queries ?? 'null');
      const result = JSON.parse(err.context?.result ?? 'null');
      console.warn('リクエストしたGQLクエリ', queries?.query || mutations?.mutation, '\n', {
        ...queries?.variables,
        ...mutations?.variables,
        result,
      });
      Sentry.setContext(`GraphQL クエリ`, err.context);
      Sentry.setContext(`GraphQL 変数`, { ...queries?.variables, ...mutations?.variables });
      if (systemContext) {
        if (isProduction()) {
          systemContext.snackbar.error(`通信エラーが発生しました。`, {
            timeout: 0,
          });
        } else {
          systemContext.snackbar.error(`[FE][${event.reason}] ${mutations?.mutation || queries?.query}`, {
            timeout: 10 * 1000,
          });
        }
      }
      // キャッチされなかったPromiseイベントからエラーオブジェクトを作る
      // このときスタックトレースをPromiseイベントを元に差し替える
      const captureError = new Error(`${queries?.query || mutations?.mutation}`);
      captureError.name = event.reason.name + `: ${queries?.query || mutations?.mutation}`;
      captureError.stack = event.reason.stack;
      Sentry.captureException(captureError);

      return;
    } catch (e) {
      // err.context がJSONじゃなかったのでパースできなかった
      console.error('error.context がJSONじゃなかった', e);
      console.error('errコンテキスト', err);
      Sentry.setContext(`errコンテキスト`, err);
    }
  }

  // GQLサーバーのエラーはこのなかにあるはず 多分stub由来
  err.errors.forEach((gqlE: GraphQLError) => {
    handleGraphQLServerError(gqlE);
  });

  console.warn(event.reason);
  Sentry.captureException(event.reason);
}

/**
 * graphqlのサーバーエラーはここに到達するはず
 * メッセージを表示してSentryに詰め込む
 * @param gqlE
 */
function handleGraphQLServerError(gqlE: GraphQLError) {
  const ctx = {
    code: gqlE.extensions?.code,
    stack: gqlE.extensions?.exception?.stacktrace.join('\n'),
    point: '',
  };

  if (ctx.stack) {
    ctx.point = ctx.stack?.split('\n')[1].match('/(stub.*?)$')[1];
  }

  console.error('[以下 graphql サーバーのメッセージ]', ctx.code, '\n', ctx.stack);
  Sentry.setContext('graphql サーバーコンテキスト', ctx);

  // スナックバーに出す
  if (systemContext) {
    if (isProduction()) {
      systemContext.snackbar.error('通信エラーが発生しました。', {
        timeout: 0,
      });
    } else {
      systemContext.snackbar.error('[graphql server error]' + ctx.code + '(' + ctx.point + ')', {
        timeout: 10 * 1000,
      });
    }
  }
}
