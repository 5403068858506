import { WasteTypeStatus } from '~/framework/domain/typeAliases';
export interface IWasteTypeStatusOption {
  value: WasteTypeStatus;
  label: string;
}

export const wasteTypeStatusOptions: IWasteTypeStatusOption[] = [
  { value: WasteTypeStatus.Displayed, label: '表示' },
  { value: WasteTypeStatus.NotDisplayed, label: '非表示' },
];
