
import Vue from 'vue';
export default Vue.extend({
  // 必須ではないが、個数の再計算をするならこのpropsを渡すこと
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      elementCount: 0,
    };
  },
  watch: {
    count() {
      this.updateCount();
    },
  },
  mounted() {
    this.updateCount();
  },
  methods: {
    updateCount() {
      this.$nextTick(() => {
        const target = this.$refs.elementWrapper as HTMLElement;

        if (target === undefined) return;
        const children = Array.from(target.children) as unknown as HTMLElement[];

        // 見切れ判定を行うため一瞬 display 設定を破棄する
        for (const child of children) {
          child.style.display = '';
        }
        this.elementCount = 0;
        // 縦方向の隠れが発生したかどうかの判定
        if (target.scrollHeight !== target.clientHeight) {
          for (const child of children) {
            const { clientHeight, offsetTop } = child;

            //  画面から見切れるものを display: none にする
            if (target.clientHeight < clientHeight + offsetTop) {
              this.elementCount++;
              child.style.display = 'none';
            }
          }
        }
      });
    },
  },
});
