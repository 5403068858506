import { DayOfWeek } from '~/framework/domain/typeAliases';
import { dayOfWeekToLabel } from '~/framework/view-models/daysOfWeek';

export interface IDayOfWeekOption {
  /**
   * フォーマット: `〇曜日`
   */
  label: string;
  value: DayOfWeek;
}

export const daysOfWeekOptions: IDayOfWeekOption[] = [
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Sunday)}曜日`,
    value: DayOfWeek.Sunday,
  },
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Monday)}曜日`,
    value: DayOfWeek.Monday,
  },
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Tuesday)}曜日`,
    value: DayOfWeek.Tuesday,
  },
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Wednesday)}曜日`,
    value: DayOfWeek.Wednesday,
  },
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Thursday)}曜日`,
    value: DayOfWeek.Thursday,
  },
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Friday)}曜日`,
    value: DayOfWeek.Friday,
  },
  {
    label: `${dayOfWeekToLabel(DayOfWeek.Saturday)}曜日`,
    value: DayOfWeek.Saturday,
  },
];
