import { WasteCategory, WasteTypeStatus } from '~/framework/domain/typeAliases';
import { IDuplicatedCodeError } from '~/framework/server-api/errors';

import { IJwnetWasteMasterData } from '~/framework/server-api/masters/jwnetWasteMaster';
import { IUserData } from '~/framework/server-api/masters/user';

export const wasteType$createSymbol = Symbol('wasteType$createSymbol');
export const wasteType$updateSymbol = Symbol('wasteType$updateSymbol');
export const wasteType$getAllSymbol = Symbol('getAll');
export const wasteType$getAllDisplayedSymbol = Symbol('getAllDisplayed');
export const wasteType$getAvailableCodeSymbol = Symbol('wasteType$getAvailableCode');
export const wasteType$getByKeywordsSymbol = Symbol('wasteType$getByKeywords');

export type WasteType = {
  [wasteType$getAllSymbol]: IGetAllApi;
  [wasteType$getAllDisplayedSymbol]: IGetAllDisplayedApi;
  [wasteType$getByKeywordsSymbol]: IGetByKeywordsApi;
  [wasteType$getAvailableCodeSymbol]: IGetAvailableCodeApi;
  [wasteType$createSymbol]: ICreateApi;
  [wasteType$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: ICreateData): Promise<CreateResultTypes>;
}

export type CreateResultTypes = (IWasteTypeDataType & IWasteTypeData) | IWasteTypeCreateError;

export interface ICreateData {
  category: WasteCategory;
  code: string | undefined;
  name: string;
  status: WasteTypeStatus;
  isProminent: boolean;
}

export interface IWasteTypeCreateError {
  __typename: 'WasteTypeCreateError';
  errors: WasteTypeCreateErrorTypes[];
}

export type WasteTypeCreateErrorTypes = IDuplicatedCodeError;

export interface IGetAllApi {
  getAll(): Promise<IWasteTypeData[]>;
}

export interface IGetAllDisplayedApi {
  getAllDisplayed(): Promise<IWasteTypeData[]>;
}

export interface IGetAvailableCodeApi {
  getAvailableCode(code: string): Promise<string>;
}

export interface IGetByKeywordsApi {
  getByKeywords(keywords: string[]): Promise<IWasteTypeData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateData): Promise<IWasteTypeData>;
}

export interface IUpdateData {
  id: string;
  name: string;
  status: WasteTypeStatus;
  isProminent: boolean;
}

export interface IWasteTypeDataType {
  __typename: 'WasteType';
}

export interface IWasteTypeData {
  id: string;
  category: WasteCategory;
  code: string;
  jwnetWasteMaster: IJwnetWasteMasterData | undefined;
  name: string;
  status: WasteTypeStatus;
  isProminent: boolean;
  createdBy: IUserData;
  createdAt: Date;
  updatedBy: IUserData;
  updatedAt: Date;
}
