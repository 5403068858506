/**
 * 影響範囲を指すもの
 * この単位で取り消したりする
 */
export interface IAffectedRange {
  readonly driverIds: string[];
}

export class AffectedRange implements IAffectedRange {
  readonly driverIds: string[];

  constructor(driverIds: string[]) {
    this.driverIds = driverIds;
  }

  toString(): string {
    return this.driverIds.join(`, `);
  }
}
