import { IResultOrderData } from './order';
import { ICreateOrderData } from './createOrders';
import { CreateGenerationSiteTaskInput, CreateIrregularTaskInput } from '../../typeAliases';

export const order$updateOrdersSymbol = Symbol('order$updateOrdersSymbol');

export interface IUpdateOrdersApi {
  [order$updateOrdersSymbol]: void;
  updateOrders(data: IUpdateOrderData[]): Promise<IResultOrderData[]>;
}

type OmitField = 'generationSiteTasks' | 'irregularTasks' | 'recurringSettings';

export interface IUpdateOrderData extends Omit<ICreateOrderData, OmitField> {
  id: string;
  attachmentsToRemove: string[];
  includeFollowingRecurringOrders: boolean | undefined;
  // 変更がない場合などにundefinedで返せるように型をoverrideする
  generationSiteTasks: CreateGenerationSiteTaskInput[] | undefined;
  irregularTasks: CreateIrregularTaskInput[] | undefined;
}
