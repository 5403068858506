import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { announcement$unreadAnnouncementsSymbol } from '~/framework/server-api/announcement/unreadAnnouncements';
import { IAnnouncementData } from '../server-api/announcement/announcement';

export const announcementSymbol = Symbol('announcementSymbol');

export class AnnouncementApplicationService {
  private readonly serverApis: ServerApiManager;

  constructor(serverApis: ServerApiManager) {
    this.serverApis = serverApis;
  }

  async getUnreadAnnouncements(): Promise<IAnnouncementData[]> {
    const announcement$unreadAnnouncementApi = this.serverApis.get(announcement$unreadAnnouncementsSymbol);
    return await announcement$unreadAnnouncementApi.unreadAnnouncements();
  }
}
