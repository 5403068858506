export interface ITypedEventContext {
  /**
   * 発火が停止状態にあるかどうか
   */
  readonly isStopped: boolean;

  /**
   * イベントの発火をそこで止める事を明示する
   * このリスナよりも優先順位の低いリスナは呼ばれない
   */
  stop(): void;
}

export class TypedEventContext implements ITypedEventContext {
  isStopped: boolean = false;

  stop() {
    this.isStopped = true;
  }
}
