import { Middleware } from '@nuxt/types';

import { authenticationSymbol } from '~/framework/application/authentication/authenticationApplicationService';
import { RinEventNames } from '~/framework/services/rin-events/rinEventParams';

const loginMiddleware: Middleware = async (context) => {
  const systemContext = context.app.$context;
  if (systemContext.hasLoggedIn === false) {
    // ここで Unauthenticated な例外が出る事はあるが、それは systemContext 内でハンドルされ、
    // 自動的にログインページにリダイレクトされる。systemContext 内でハンドルされた場合には
    // Sentry にも送らない様になっている。
    const service = systemContext.applications.get(authenticationSymbol);
    const session = await service.getCurrentSession();
    systemContext.hasLoggedIn = true;
    systemContext.currentSession = session;
    context.app.$rinGtm.push(RinEventNames.LOGIN);
  }
};

export default loginMiddleware;
