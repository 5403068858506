import { Maybe } from '~/framework/typeAliases';

export class TaskTypeFormValues {
  constructor(
    public id: Maybe<string>,
    public taskTypeName: string,
    public baseTaskTypeId: Maybe<string>,
    public bannedDriverIds: string[]
  ) {}

  isEqualTo(another: TaskTypeFormValues): boolean {
    return (
      this.id === another.id &&
      this.taskTypeName === another.taskTypeName &&
      this.baseTaskTypeId === another.baseTaskTypeId &&
      this.bannedDriverIds === another.bannedDriverIds
    );
  }
}
