export enum AddressType {
  StreetAddress = 'street_address',
  Subpremise = 'subpremise',
  Premise = 'premise',
  SublocalityLevel5 = 'sublocality_level_5',
  SublocalityLevel4 = 'sublocality_level_4',
  SublocalityLevel3 = 'sublocality_level_3',
  SublocalityLevel2 = 'sublocality_level_2',
  SublocalityLevel1 = 'sublocality_level_1',
  Ward = 'ward',
  Locality = 'locality',
}
