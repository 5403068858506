import { IGenericObjectPanel } from '~/framework/view-models/panels/genericObjectPanel';
import { AbstractGenericObjectPanel } from '~/framework/view-models/panels/abstractGenericObjectPanel';
import { AggregatedClientEntity as IClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import { Maybe } from '~/framework/typeAliases';
import { IOpenPanelOption } from '~/framework/view-models/panels/panel';
import { IPackingStyleReservationSetting } from '~/framework/server-api/masters/client';

export interface IOpenData {
  /**
   * 編集したい会社
   */
  client: IClientEntity;
  /**
   * 編集したい会社の持つ排出場の数
   */
  generationSiteNum: number;
}

/**
 * フォームの値
 */
export interface IFormValues {
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  reservationSiteEnabled: boolean;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
}

export interface IClientFormPanelOption extends IOpenPanelOption {
  /**
   * 登録が完了したときに呼ばれて欲しいコールバック
   */
  closeCallback?: (client: Maybe<IClientEntity>) => void;

  /**
   * 登録完了ボタンのラベル
   */
  registerButtonLabel?: string;

  /**
   * 登録時に初期入力されてほしい項目
   */
  initialFormValues?: Partial<IFormValues>;
}

export interface ICloseData {
  /**
   * 編集の元となった会社
   */
  client: Maybe<IClientEntity>;
  /**
   * 更新された会社
   */
  updatedClient: Maybe<IClientEntity>;
  /**
   * 作成された会社
   */
  createdClient: Maybe<IClientEntity>;
}

/**
 * ClientEntity を編集するためのもの
 */
export interface IClientFormPanel extends IGenericObjectPanel<IOpenData, ICloseData, IClientFormPanelOption> {}

export class ClientFormPanel
  extends AbstractGenericObjectPanel<IOpenData, ICloseData, IClientFormPanelOption>
  implements IClientFormPanel
{
  protected isDataSame(a: IOpenData, b: IOpenData): boolean {
    return a.client.persistentId === b.client.persistentId;
  }
}
