
import Vue from 'vue';

/**
 * ページ全体に 16px のパディングを設定するだけのシンプルなページレイアウト。
 * レイアウトの使い方のサンプルとして入れておく。
 * ~/layouts は Nuxt のレイアウトでページに適用されるものだが、
 * ~/components/templates はコンポーネントを配置するためのもの。
 */
export default Vue.extend({
  name: 'RPage',
});
