export interface IFormErrors {
  fields: { name: string[] };
  addError(field: keyof IFormErrors['fields'], value: string): void;
  resetErrors(field: keyof IFormErrors['fields']): void;
  resetAllErrors(): void;
}

export class FormErrors implements IFormErrors {
  fields: { name: string[] };

  constructor() {
    this.fields = {
      name: [],
    };
  }

  addError(field: keyof IFormErrors['fields'], value: string): void {
    this.fields[field] = [...this.fields[field], value];
  }

  resetErrors(field: keyof IFormErrors['fields']): void {
    this.fields[field] = [];
  }

  resetAllErrors() {
    this.fields.name = [];
  }
}
