import { BooleanValue, IntegerValue, ContainerTypeTaskTypeStatusValue } from '~/framework/domain/typeAliases';
import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { IContainerTypeTaskTypeData } from '~/framework/server-api/masters/containerTypeTaskType';
import { TaskTypeEntity } from '../../task-type/taskTypeEntity';
import { AggregatedContainerTypeEntity } from '../aggregatedContainerTypeEntity';

export class ContainerTypeTaskTypeEntity implements IEntity, IPersistentEntity {
  readonly id: string;
  readonly persistentId: string;
  containerType: AggregatedContainerTypeEntity;
  taskType: TaskTypeEntity;
  duration: IntegerValue;
  status: ContainerTypeTaskTypeStatusValue;
  isProportionalToCount: BooleanValue; // 個数が時間に影響するかどうか

  constructor(
    data: IContainerTypeTaskTypeData,
    containerType: AggregatedContainerTypeEntity,
    taskType: TaskTypeEntity
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.containerType = containerType;
    this.taskType = taskType;
    this.duration = data.duration;
    this.status = data.status;
    this.isProportionalToCount = data.isProportionalToCount;
  }

  update(
    data: IContainerTypeTaskTypeData,
    containerType: AggregatedContainerTypeEntity,
    taskType: TaskTypeEntity
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of ContainerTypeTaskTypeEntity');
    }
    this.containerType = containerType;
    this.taskType = taskType;
    this.duration = data.duration;
    this.status = data.status;
    this.isProportionalToCount = data.isProportionalToCount;
  }
}
