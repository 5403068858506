
import Vue, { PropType } from 'vue';
import RPackingStyleVisibleSettingsItem from '~/components/panels/masters/r-client-form/RPackingStyleVisibleSettingsItem.vue';
import { IPackingStyleReservationSetting } from '~/framework/server-api/masters/client';

type DataType = {
  localPackingStyleReservationSettings: IPackingStyleReservationSetting[];
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RPackingStyleVisibleSettings',
  components: {
    RPackingStyleVisibleSettingsItem,
  },
  props: {
    packingStyleReservationSettings: {
      type: Array as PropType<IPackingStyleReservationSetting[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      localPackingStyleReservationSettings: this.packingStyleReservationSettings,
    };
  },
  methods: {
    onPackingStyleReservationSettingChange(value: IPackingStyleReservationSetting): void {
      const packingStyleReservationSettings = [
        ...this.localPackingStyleReservationSettings.map((setting) => {
          if (setting.packingStyle.id !== value.packingStyle.id) return setting;
          return value;
        }),
      ];
      this.localPackingStyleReservationSettings = [...packingStyleReservationSettings];
      this.$emit(EventTypes.Change, packingStyleReservationSettings);
    },
  },
});
