
import Vue from 'vue';

export default Vue.extend({
  name: 'RWeekCalendarRow',
  props: {
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
