
import Vue, { PropType } from 'vue';
import { hhMmToSecs, secsToHhMm, isNextDay, validateHardLimitTime } from '~/framework/services/date-time/date-time';
import { Maybe, Seconds } from '~/framework/typeAliases';
import { DaySeconds } from '~/framework/constants';

type DataType = {
  endErrorMessage: Maybe<string>;
};

enum EventTypes {
  Update = 'update',
}

/*
 * 開始時間と終了時間を必須にするかを指定する
 * both: 開始時間と終了時間の両方が必須
 * bothOrNone: 開始時間と終了時間の両方が必須、または両方 null
 * oneOrBoth: 開始時間または終了時間のどちらかは必須
 * none: 開始時間と終了時間のどちらも必須ではない
 */
type RquiredType = 'both' | 'bothOrNone' | 'oneOrBoth' | 'none';

export default Vue.extend({
  name: 'RPeriodInput',
  props: {
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    startDescription: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    endDescription: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: '50px',
    },
    dense: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    start: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    end: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    requiredType: {
      type: String as PropType<RquiredType>,
      required: false,
      default: 'none',
    },
  },
  data(): DataType {
    return {
      endErrorMessage: undefined,
    };
  },
  computed: {
    startString(): Maybe<string> {
      return this.start === undefined ? undefined : secsToHhMm(this.start);
    },
    endString(): Maybe<string> {
      return this.end === undefined ? undefined : secsToHhMm(this.end);
    },
    isStartRequired(): boolean {
      switch (this.requiredType) {
        case 'both':
          return true;
        case 'bothOrNone':
          return this.end !== undefined;
        case 'oneOrBoth':
          return this.end === undefined;
        case 'none':
          return false;
        default:
          return false;
      }
    },
    isEndRequired(): boolean {
      switch (this.requiredType) {
        case 'both':
          return true;
        case 'bothOrNone':
          return this.start !== undefined;
        case 'oneOrBoth':
          return this.start === undefined;
        case 'none':
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    hhMmToSecs,
    secsToHhMm,
    isNextDay,
    validateHardLimitTime,
    onChangeStartString(value: Maybe<string>): void {
      if (value === undefined) return;

      const start = hhMmToSecs(value);
      let end = this.end;

      // NOTE: endTime が設定されていない場合はそのまま startTime を更新する
      if (this.end === undefined) {
        this.$emit(EventTypes.Update, start, end);
        return;
      }

      // start > end になった場合は start = end にする
      if (end && start > end) {
        end = start;
      }

      this.$emit(EventTypes.Update, start, end);
    },
    onChangeEndString(value: Maybe<string>): void {
      if (value === undefined) return;

      const end = hhMmToSecs(value);
      let start = this.start;

      // NOTE: startTime が設定されていない場合はそのまま endTime を更新する
      if (start === undefined) {
        this.$emit(EventTypes.Update, start, end);
        return;
      }

      // start > end になった場合は start = end にする
      if (start > end) {
        start = end;
      }

      this.$emit(EventTypes.Update, start, end);
    },
    // NOTE: 過去に遡ることはないため、 現状は endTime だけハードリミットチェックできればよい
    onUpdateError(value: Maybe<string>): void {
      if (value === undefined || this.start === undefined) return;

      this.endErrorMessage = this.checkErrors(this.start, hhMmToSecs(value));
    },
    checkErrors(start: number, end: number): Maybe<string> {
      // NOTE: startTime > endTime の場合は日跨ぎとみなす
      if (isNextDay(start, end)) {
        const calculatedEndTime = end + DaySeconds;

        if (!validateHardLimitTime(calculatedEndTime)) {
          return '翌日12時以降は指定できません';
        }
      }
      return undefined;
    },
  },
});
