export enum AddressComponentType {
  PostalCode = 'postal_code',
  AdministrativeAreaLevel1 = 'administrative_area_level_1',
  AdministrativeAreaLevel2 = 'administrative_area_level_2',
  Locality = 'locality',
  Ward = 'ward',
  SublocalityLevel1 = 'sublocality_level_1',
  SublocalityLevel2 = 'sublocality_level_2',
  SublocalityLevel3 = 'sublocality_level_3',
  SublocalityLevel4 = 'sublocality_level_4',
  SublocalityLevel5 = 'sublocality_level_5',
  Premise = 'premise',
  Subpremise = 'subpremise',
}
