export const version$getVersionSymbol = Symbol('version$getVersionSymbol');

/**
 * version取得時に受け取るの内容
 */
export type GetVersionResponsePayload = {
  version: string;
};

export interface IGetVersionApi {
  [version$getVersionSymbol]: void;

  getVersion(): Promise<string>;
}
