
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RFieldClickableTooltip',
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100%',
    },
    content: {
      type: String as PropType<string>,
      required: true,
    },
    closeDelay: {
      type: Number,
      required: false,
      default: 3000,
    },
  },
  data() {
    return {
      isTooltipVisible: false,
    };
  },
  watch: {
    isTooltipVisible(value: boolean) {
      if (!value) {
        return;
      }

      // closeDelay を参照して自動でtooltipを閉じる
      // closeDelay: 0 の場合は自動で閉じない
      if (this.closeDelay === 0) {
        return;
      }
      setTimeout(() => {
        this.isTooltipVisible = false;
      }, this.closeDelay);
    },
  },
});
