import { AxiosResponse } from 'axios';
import { AxiosApiBase } from '~/framework/port.adapter/server-api/axiosApiBase';
import {
  GetVersionResponsePayload,
  IGetVersionApi,
  version$getVersionSymbol,
} from '~/framework/server-api/version/getVersion';

const versionPath = '/version.json';

export class GetVersionApi extends AxiosApiBase implements IGetVersionApi {
  [version$getVersionSymbol] = undefined;
  async getVersion(): Promise<string> {
    const response: AxiosResponse<GetVersionResponsePayload> = await this.axios.get(location.origin + versionPath);
    return response.data.version;
  }
}
