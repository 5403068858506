import { IIrregularTaskData } from '~/framework/server-api/schedule/order/irregular-task/irregularTask';

/**
 * こいつは扱いが特殊で、Order の現場タスクを更新すると毎回新しい ID が振られる。
 * なので ID が同じでも集約の内容が更新される事はあり得ず、トラックする必要はない。
 */
export class IrregularTaskEntity {
  readonly id: string;
  readonly persistentId: string;
  orderId: string;
  name: string;
  durationAtGenerationSite: number;
  durationAtDisposalSite: number;
  skipDisposalSite: boolean;

  constructor(data: IIrregularTaskData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.orderId = data.orderId;
    this.name = data.name;
    this.durationAtGenerationSite = data.durationAtGenerationSite;
    this.durationAtDisposalSite = data.durationAtDisposalSite;
    this.skipDisposalSite = data.skipDisposalSite;
  }
}
