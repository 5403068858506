import employmentStatusMap from '~/assets/settings/employmentStatus.json';

export interface IEmploymentStatusOption {
  label: string;
  value: string;
}

export const employmentStatusOptions: IEmploymentStatusOption[] = Object.entries(employmentStatusMap).map(
  ([value, label]) => ({ label, value })
);
