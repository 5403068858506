
import Vue, { PropType } from 'vue';
import { ContainerTypeTaskItemFactory, IGenerationSiteTaskItem } from './generationSiteTaskItem';
import {
  BaseTaskTypeName,
  ContainerTypeTaskTypeStatus,
  SiteType,
  SoftDeleteStatus,
  WasteTypeStatus,
} from '~/framework/domain/typeAliases';
import { Id, Maybe, ValidationRule } from '~/framework/typeAliases';
import { greaterThanZero, max, min, required } from '~/framework/view-models/rules';
import { mapEntity } from '~/framework/core/mapper';
import { hankanize } from '~/framework/services/string/string';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { AllowNumberTypes } from '~/components/common/r-v-number-field/sanitizeNumericalText';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { round } from '~/framework/domain/number';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { AggregatedWasteTypeEntity } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';

type DataType = {
  rules: { [key: string]: ValidationRule };
  /**
   * item の index から wasteType が disabled されているかどうかを引くためのもの
   * disabled の場合、required ではなくする必要がある
   */
  isWasteTypeDisabled: boolean[];
  containerTypeMap: Map<Id, AggregatedContainerTypeEntity>;
  packingStylesMap: Map<Id, PackingStyleEntity>;
  AllowNumberTypes: typeof AllowNumberTypes;
};

enum EventTypes {
  ChangeItemValue = 'change:item-value',
}

/**
 * 作業（taskType）が設置のものは品目（wasteType）が必須ではない事に注意
 */
export default Vue.extend({
  name: 'RGenerationSiteTaskField',
  props: {
    items: {
      type: Array as PropType<IGenerationSiteTaskItem[]>,
      required: false,
      default: () => [],
    },
    taskTypes: {
      type: Array as PropType<TaskTypeEntity[]>,
      required: true,
    },
    wasteTypes: {
      type: Array as PropType<AggregatedWasteTypeEntity[]>,
      required: true,
    },
    containerTypes: {
      type: Array as PropType<AggregatedContainerTypeEntity[]>,
      required: true,
    },
    containerTypeTaskTypes: {
      type: Array as PropType<ContainerTypeTaskTypeEntity[]>,
      required: true,
    },
    packingStyles: {
      type: Array as PropType<PackingStyleEntity[]>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isApparentQuantityRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      rules: {
        required,
        maxContainerNum: max(9999),
      },
      isWasteTypeDisabled: [],
      containerTypeMap: mapEntity(this.containerTypes),
      packingStylesMap: mapEntity(this.packingStyles),
      AllowNumberTypes,
    };
  },
  computed: {
    taskTypeMap(): Record<string, TaskTypeEntity> {
      const result: Record<string, TaskTypeEntity> = {};
      for (const obj of this.taskTypes) {
        result[obj.id] = obj;
      }
      return result;
    },
    wasteTypesInOrder(): AggregatedWasteTypeEntity[] {
      return [...this.wasteTypes].sort((a, b) => {
        const isAProminent = a.isProminent === true;
        const isBProminent = b.isProminent === true;
        if (isAProminent !== isBProminent) {
          if (isAProminent) return -1;
          if (isBProminent) return 1;
        }
        return 0;
      });
    },
    /**
     * 各 item に対して表示される廃棄物の種別
     * Displayed なものか、選択されている廃棄物に限る
     * NotDisplayed なものは編集時には表示はされるが、一度別のものを選択するとリストからは消える（非表示だから）
     */
    displayedWasteTypes(): AggregatedWasteTypeEntity[][] {
      const displayedWasteTypes: AggregatedWasteTypeEntity[][] = [];
      for (const item of this.items) {
        const wasteTypes = this.wasteTypesInOrder.filter(
          (wasteType) => wasteType.status === WasteTypeStatus.Displayed || wasteType.id === item.wasteTypeId
        );
        displayedWasteTypes.push(wasteTypes);
      }
      return displayedWasteTypes;
    },
  },
  watch: {
    items() {
      this.updateWasteTypeDisabled();
    },
    containerTypes: {
      // これをオンにしておかないとパネルを開いた瞬間におかしな荷姿が指定されていた場合に containerTypeId が
      // 生き続けてしまうという問題があるため、true にしている
      immediate: true,
      handler() {
        // もしコンテナタイプが変更された場合に現状のコンテナ種類がその中に含まれていなかった場合、
        // そのままにしておくと選択肢がない状態なのに更新できてしまっておかしな事になるのでその場合は
        // コンテナの ID 自体をリセットしておく
        this.containerTypeMap = mapEntity(this.containerTypes);
        for (const item of this.items) {
          if (item.containerTypeId !== undefined && this.containerTypeMap.has(item.containerTypeId) === false) {
            this.$set(item, 'containerTypeId', undefined);
          }
        }
      },
    },
  },
  mounted() {
    // NOTE アイテムがゼロになる事は避けたいため
    if (this.items.length === 0) {
      this.$set(this.items, 0, ContainerTypeTaskItemFactory.instantiateDefault());
    }
    this.updateWasteTypeDisabled();
  },
  methods: {
    hankanize,
    onRemoveItem(index: number): void {
      this.$delete(this.items, index);
      this.$emit(EventTypes.ChangeItemValue, this.items);
    },
    onInputTaskType(item: IGenerationSiteTaskItem, event: string) {
      // v-model でid切り替えたらtaskが入れ替わる、なんてことはないのでこうする
      if (!this.taskTypeMap[event]) return;
      item.taskType = this.taskTypeMap[event];
    },
    onItemValueChange(): void {
      this.mergeSameItems();
      this.updateWasteTypeDisabled();
      this.$emit(EventTypes.ChangeItemValue, this.items);
    },
    onContainerNumChange(item: IGenerationSiteTaskItem): void {
      item.containerNum = round(item.containerNum, 4);
      this.onItemValueChange();
    },
    getFilteredTaskTypesOf(itemContainerTypeId: Maybe<Id>, itemTaskType: Maybe<TaskTypeEntity>): TaskTypeEntity[] {
      const taskTypeId = itemTaskType?.id;

      const sortKey = {
        ReplaceAtGenerationSite: 1,
        AllocateAtGenerationSite: 2,
        FetchAtGenerationSite: 3,
        LoadAtGenerationSite: 4,
        DisposeAtDisposalSite: 5,
        LoadAtBaseSite: 6,
        UnloadAtBaseSite: 7,
      } as const;
      // 既に荷姿(itemContainerType)が指定されている場合は
      // その荷姿に対して設定されている作業を拾ってくる（利用ありの荷姿のみ）

      // そうでない場合には全てのコンテナに対してあり得る作業を取ってくる
      return this.containerTypeTaskTypes
        .filter((cTTT) => {
          // itemContainerType の指定がなければすべて
          if (!itemContainerTypeId) return true;

          // 利用負荷な荷姿作業は除外（すでに選択されているものは除外しない）
          if (cTTT.taskType.id === taskTypeId) return true;
          if (cTTT.status.value === ContainerTypeTaskTypeStatus.NotInUse) return false;
          // 荷姿に紐づく作業をもってくる
          return cTTT.containerType.id === itemContainerTypeId;
        })
        .map((cTTT) => {
          return cTTT.taskType;
        })
        .filter((taskType) => taskType.baseTaskType.siteType === SiteType.GenerationSite)
        .sort((a, b) => {
          return sortKey[a.baseTaskType.name] - sortKey[b.baseTaskType.name];
        });
    },
    getFilteredContainerTypesOf(
      itemTaskType: Maybe<TaskTypeEntity>,
      selectedContainerTypeId: Maybe<string>
    ): AggregatedContainerTypeEntity[] {
      const containerTypeMap: Record<string, AggregatedContainerTypeEntity> = {};
      for (const containerType of this.containerTypes) {
        containerTypeMap[containerType.id] = containerType;
      }

      // 荷姿で荷姿の作業を絞り込む
      return this.containerTypeTaskTypes
        .filter((cTTT) => {
          // SoftDeleteされてる荷姿がある
          if (containerTypeMap[cTTT.containerType.id]?.status !== SoftDeleteStatus.Active) return false;

          // 作業が指定されてなければあとは全部
          if (itemTaskType === undefined) return true;

          // 作業から荷姿作業を絞り込む
          if (cTTT.taskType.id !== itemTaskType.id) return false;
          // 選択済みのものは採用
          if (cTTT.containerType.id === selectedContainerTypeId) return true;
          // 不使用のものは除外
          return cTTT.status.value === ContainerTypeTaskTypeStatus.InUse;
        })
        .map((cTTT) => {
          return containerTypeMap[cTTT.containerType.id];
        });
    },
    getUnitNameOf(containerTypeId: Maybe<Id>): string {
      if (containerTypeId === undefined) return '';
      return this.containerTypeMap.get(containerTypeId)?.unitName ?? '';
    },
    getPackingStyle(containerTypeId: Maybe<Id>): Maybe<PackingStyleEntity> {
      if (containerTypeId === undefined) return undefined;
      const containerType = this.containerTypeMap.get(containerTypeId);
      if (containerType === undefined) return undefined;
      return this.packingStylesMap.getOrError(containerType.packingStyle.id);
    },
    getAllowNumberType(containerTypeId: Maybe<Id>): AllowNumberTypes {
      const packingStyle = this.getPackingStyle(containerTypeId);
      if (packingStyle === undefined || packingStyle.isCountedByInteger()) {
        return AllowNumberTypes.OnlyDigits;
      } else {
        return AllowNumberTypes.FloatingPointNotations;
      }
    },
    getMinContainerNumRule(containerTypeId: Maybe<Id>) {
      const packingStyle = this.getPackingStyle(containerTypeId);
      if (packingStyle === undefined || packingStyle.isCountedByInteger()) {
        return min(1);
      } else {
        return greaterThanZero;
      }
    },
    /**
     * 仮に同じ作業タイプ・品目・コンテナのものが存在した場合にはマージする
     */
    mergeSameItems(): void {
      const itemSet = new Map<string, IGenerationSiteTaskItem>();
      for (let index = 0; index < this.items.length; index++) {
        const item = this.items[index];
        if (item.isValid()) {
          const itemKey = this.getItemKey(item);
          const duplicatedItem = itemSet.get(itemKey);
          if (duplicatedItem === undefined) {
            itemSet.set(itemKey, item);
          } else {
            duplicatedItem.containerNum! += item.containerNum!;
            this.$delete(this.items, index);
          }
        }
      }
    },
    getItemKey(item: IGenerationSiteTaskItem): string {
      return `${item.taskType?.id}-${item.wasteTypeId}-${item.containerTypeId}`;
    },
    updateWasteTypeDisabled(): void {
      for (const [index, item] of this.items.entries()) {
        const isAllocate =
          item.taskType !== undefined && item.taskType.baseTaskType.name === BaseTaskTypeName.AllocateAtGenerationSite;
        this.$set(this.isWasteTypeDisabled, index, isAllocate);
        // 仮に設置のタスクだった場合、品目は必要ないので undefined とする
        if (isAllocate) this.$set(item, 'wasteTypeId', undefined);
      }

      // アイテムを減らした場合は配列の長さが合わない場合があるので削っておく
      if (this.items.length < this.isWasteTypeDisabled.length) {
        const deleteCount = this.isWasteTypeDisabled.length - this.items.length;

        this.isWasteTypeDisabled.splice(this.items.length, deleteCount);
      }
    },
    kanaFilter(_: any, queryText: string, itemText: string) {
      return itemText.toLocaleLowerCase().includes(hankanize(queryText.toLocaleLowerCase()));
    },
    onChangeApparentQuantity(index: number, value: number) {
      this.$set(this.items[index], 'apparentQuantity', value);
    },
    onChangeApparentQuantityUnit(index: number, value: Id) {
      this.$set(this.items[index], 'apparentQuantityUnit', value);
    },
    /**
     * 「交換」・「引上」・「積込」の場合のみ予定数量の入力が必要。
     * 「設置」の場合は処分が存在しないので予定数量の入力が不要。
     */
    isApparentQuantityRequiredByTaskItem(generationSiteTaskItem: IGenerationSiteTaskItem): boolean {
      if (!generationSiteTaskItem.taskType) return false;

      return (
        this.isApparentQuantityRequired &&
        ['ReplaceAtGenerationSite', 'FetchAtGenerationSite', 'LoadAtGenerationSite'].includes(
          generationSiteTaskItem.taskType.baseTaskType.name
        )
      );
    },
  },
});
