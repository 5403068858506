import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { IDisposalSiteData } from '~/framework/server-api/masters/disposalSite';

export class AggregatedDisposalSiteMapper extends StoredMapperBase<IDisposalSiteData, AggregatedDisposalSiteEntity> {
  protected store: AggregatedDisposalSiteStore;
  private userMapper: UserMapper;

  constructor(store: AggregatedDisposalSiteStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IDisposalSiteData): AggregatedDisposalSiteEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    return new AggregatedDisposalSiteEntity(data, createdBy);
  }

  protected updateWithData(data: IDisposalSiteData, entity: AggregatedDisposalSiteEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    entity.update(data, createdBy);
  }
}
