import { ICarTypeContainerTypeData } from '~/framework/server-api/masters/carType';
import { AggregatedContainerTypeEntity } from '../../container-type/aggregatedContainerTypeEntity';

export class AggregatedCarTypeContainerTypeEntity {
  readonly id: string;
  emptyCount: number;
  emptyCountOccupation: number;
  lowerTierFullCount: number;
  upperTierFullCount: number;
  fullCountOccupation: number;
  isUpperTierAvailable: boolean;
  containerType: AggregatedContainerTypeEntity;

  constructor(data: ICarTypeContainerTypeData, containerType: AggregatedContainerTypeEntity) {
    this.id = data.id;
    this.emptyCount = data.emptyCount;
    this.emptyCountOccupation = data.emptyCountOccupation;
    this.lowerTierFullCount = data.lowerTierFullCount;
    this.upperTierFullCount = data.upperTierFullCount;
    this.fullCountOccupation = data.fullCountOccupation;
    this.isUpperTierAvailable = data.isUpperTierAvailable;
    this.containerType = containerType;
  }

  update(data: ICarTypeContainerTypeData, containerType: AggregatedContainerTypeEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedCarTypeContainerTypeEntity');
    }
    this.emptyCount = data.emptyCount;
    this.emptyCountOccupation = data.emptyCountOccupation;
    this.lowerTierFullCount = data.lowerTierFullCount;
    this.upperTierFullCount = data.upperTierFullCount;
    this.fullCountOccupation = data.fullCountOccupation;
    this.isUpperTierAvailable = data.isUpperTierAvailable;
    this.containerType = containerType;
  }
}
