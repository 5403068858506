import {
  IScheduleDataEntity,
  ScheduleDataEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/scheduleDataEntity';
import { IRoute } from '~/pages/schedule/route';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { IAbstractFactory } from '~/framework/domain/schedule/schedule/pseudo-entities/abstractFactory';
import { Collection } from '~/pages/schedule/collection';
import { IOriginalRoute } from '~/pages/schedule/originalRoute';
import { Disposal } from '~/pages/schedule/disposal';
import { InconsistentRouteInfo } from '~/pages/schedule/inconsistentRouteInfo';
import { AggregatedOrderEntity, PseudoOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { Infeasibility } from './infeasibility';

/**
 * ルート入れ替え操作時のドロップ失敗の理由
 */
export enum DropRouteErrorReason {
  DroppingBeforeFixedRoute, // 既に順固定されているルートより前にドロップしようとしている
}

/**
 * 回収入れ替え操作時のドロップ失敗の理由
 */
export enum DropCollectionErrorReason {
  DroppingBeforeFixedCollection, // 既に順固定されている回収より前にドロップしようとしている
}

export interface IScheduleData
  extends IScheduleDataEntity<IRoute, Collection, Disposal, InconsistentRouteInfo, IOriginalRoute> {
  /**
   * この配車表を読み込んだ段階で判明していた全乗務員
   * 配車表に関係ないデータも全部含んでいるのは人をまたいだ移動で最初に配車表に存在していなかった乗務員を
   * 割り当てる可能性があるため
   */
  readonly drivers: AggregatedDriverEntity[];

  /**
   * 全ての車
   */
  readonly cars: AggregatedCarEntity[];

  /**
   * ドライバー勤怠
   */
  readonly driverAttendances: AggregatedDriverAttendanceEntity[];

  /**
   * 全ての処分場
   */
  readonly disposals: AggregatedDisposalSiteEntity[];

  /**
   * 全ての受注
   */
  readonly orders: PseudoOrderEntity[];
  /**
   * 配車表に乗務予定がない乗務員の勤怠
   */
  readonly unassignedDriverAttendances: AggregatedDriverAttendanceEntity[];

  /**
   * エラーの数
   * 固定のエラーがあれば "?"
   * 作成不可のエラーがあれば "x"
   * なければ undefined
   */
  readonly errorNum: Maybe<string>;

  /**
   * ドラッグしているルート
   *
   * ※ ドラッグ中に値が入る
   */
  draggingRoute: Maybe<IRoute>;

  /**
   * ドラッグ先のルート
   *
   * ※ ドラッグ中に値が入る
   */
  draggingTargetRoute: Maybe<IRoute>;

  /**
   * ドラッグしている回収
   *
   * ※ ドラッグ中に値が入る
   */
  draggingCollection: Maybe<Collection>;

  /**
   * ドラッグ先の回収
   *
   * ※ ドラッグ中に値が入る
   */
  draggingTargetCollection: Maybe<Collection>;

  /**
   * ルートのドラッグ開始を通知する
   * @param index
   */
  onStartDraggingRoute(index: number): void;

  /**
   * ドラッグ中にルートをホバーした時に呼ばれる
   *
   * @param driverId 乗務員ID
   * @param routeIndex ルートINDEX
   */
  onHoverRouteWhileDragging(driverId: string, routeIndex: number): void;

  /**
   * ルートのドラッグが終了した事を通知する
   */
  onStopDraggingRoute(index: number): void;

  /**
   * 収集のドラッグ開始を通知する
   */
  onStartDraggingCollection(driverId: string, routeIndex: number, collectionIndex: number): void;

  /**
   * ドラッグ中に回収をホバーした時に呼ばれる
   *
   * @param driverId 乗務員ID
   * @param routeIndex ルートINDEX
   * @param collectionIndex 回収INDEX
   */
  onHoverCollectionWhileDragging(driverId: string, routeIndex: number, collectionIndex: number): void;

  /**
   * 収集のドラッグが終了した事を通知する
   */
  onStopDraggingCollection(driverId: string, routeIndex: number, collectionIndex: number): void;

  /**
   * ルートがソートされたアニメーションが終了した時に呼ばれる
   *
   * @param driverId
   * @param routeIndex
   */
  onRouteSortedAnimationEnd(driverId: string, routeIndex: number): void;

  /**
   * ルートが固定されたアニメーションが終了した時に呼ばれる
   *
   * @param driverId
   * @param routeIndex
   */
  onRouteFixedAnimationEnd(driverId: string, routeIndex: number): void;

  /**
   * 回収がソートされたアニメーションが終了した時に呼ばれる
   *
   * @param driverId
   * @param routeIndex
   * @param collectionIndex
   */
  onCollectionSortedAnimationEnd(driverId: string, routeIndex: number, collectionIndex: number): void;

  /**
   * 回収が固定されたアニメーションが終了した時に呼ばれる
   *
   * @param driverId
   * @param routeIndex
   * @param collectionIndex
   */
  onCollectionFixedAnimationEnd(driverId: string, routeIndex: number, collectionIndex: number): void;

  /**
   * 特定ドライバーの固定を全て取り消す
   *
   * @param driverId
   */
  onUnfixDriver(driverId: string): void;

  /**
   * ルートの z-index を更新する
   */
  updateZIndex(): void;

  /**
   * 乗務員別で回収の順番固定を操作できるかどうかを更新する
   *
   * 1. 順番固定されている最後の回収とその次の回収のみ操作可能
   * 例:
   * 回収1 固定あり → 操作不可
   * 回収2 固定あり → 操作可能
   * 回収3 固定なし → 操作可能
   * 回収4 固定なし → 操作不可
   *
   * 2. 何も順番固定されていない場合は一番最初の回収のみ操作可能
   * 例:
   * 回収1 固定なし → 操作可能
   * 回収2 固定なし → 操作不可
   * 回収3 固定なし → 操作不可
   * 回収4 固定なし → 操作不可
   */
  updateIsFixable(): void;

  /**
   * ルート入れ替え時に、ルートが指定先にドロップできるかどうか
   *
   * @returns 検証結果
   */
  canDropRouteToTarget(): Set<DropRouteErrorReason>;

  /**
   * 回収入れ替え時に、回収が指定先にドロップできるかどうか
   *
   * @returns 検証結果
   */
  canDropCollectionToTarget(): Set<DropCollectionErrorReason>;
}

export class ScheduleData
  extends ScheduleDataEntity<IRoute, Collection, Disposal, InconsistentRouteInfo, IOriginalRoute>
  implements IScheduleData
{
  readonly driverAttendances: AggregatedDriverAttendanceEntity[];
  readonly drivers: AggregatedDriverEntity[];
  readonly cars: AggregatedCarEntity[];
  readonly disposals: AggregatedDisposalSiteEntity[];
  readonly orders: PseudoOrderEntity[] = [];
  unassignedDriverAttendances: AggregatedDriverAttendanceEntity[];
  errorNum: Maybe<string> = undefined;
  draggingRoute: Maybe<IRoute> = undefined;
  draggingTargetRoute: Maybe<IRoute> = undefined;
  draggingCollection: Maybe<Collection> = undefined;
  draggingTargetCollection: Maybe<Collection> = undefined;

  constructor(
    id: string,
    factory: IAbstractFactory<IRoute, Collection>,
    routes: IRoute[],
    originalRoutes: IOriginalRoute[],
    inconsistencies: Maybe<Inconsistency[]>,
    infeasibilities: Maybe<Infeasibility[]>,
    drivers: AggregatedDriverEntity[],
    driverAttendances: AggregatedDriverAttendanceEntity[],
    cars: AggregatedCarEntity[],
    disposals: AggregatedDisposalSiteEntity[],
    orders: PseudoOrderEntity[]
  ) {
    super(id, factory, routes, originalRoutes, inconsistencies, infeasibilities);
    this.drivers = drivers;
    this.driverAttendances = driverAttendances;
    this.cars = cars;
    this.disposals = disposals;
    this.orders = orders;
    this.unassignedDriverAttendances = [];
    this.updateIsFixable();
    this.updateErrorNum();
    this.updateUnassignedDriversInAttendance();
  }

  onStartDraggingRoute(index: number): void {
    const targetRoute = this.routes[index];
    this.draggingRoute = targetRoute;
    this.draggingCollection = undefined;

    for (const route of this.routes) {
      // 同じドライバーのルートのみドラッグ可能
      route.isSameDriverAsDraggingRoute = route.driverId.equals(targetRoute.driverId);
      for (const collection of route.collections) {
        collection.isDraggingRoute = true;
      }
    }
  }

  onHoverRouteWhileDragging(driverId: string, routeIndex: number): void {
    this.draggingTargetRoute = this.getRoute(driverId, routeIndex);
    this.draggingTargetCollection = undefined;
  }

  onStopDraggingRoute(index: number): void {
    this.routes[index].isSortedAnimationEnabled = true;
    this.draggingRoute = undefined;
    this.draggingCollection = undefined;
    this.draggingTargetRoute = undefined;
    this.draggingTargetCollection = undefined;

    for (const route of this.routes) {
      // ドラッグ終了時には全てのルートがまたドラッグ対象に戻る
      route.isSameDriverAsDraggingRoute = undefined;
      for (const collection of route.collections) {
        collection.isDraggingRoute = false;
      }
    }
    this.updateIsFixable();
  }

  onStartDraggingCollection(driverId: string, routeIndex: number, collectionIndex: number): void {
    const route = this.getRoute(driverId, routeIndex);
    this.draggingRoute = undefined;
    this.draggingCollection = route.getCollection(collectionIndex);

    for (const route of this.routes) {
      route.isDraggingCollection = true;
      for (const collection of route.collections) {
        collection.isSameRouteAsDraggingCollection = route.driverId.value === driverId && route.index === routeIndex;
      }
    }
  }

  onHoverCollectionWhileDragging(driverId: string, routeIndex: number, collectionIndex: number): void {
    const route = this.getRoute(driverId, routeIndex);
    this.draggingTargetRoute = undefined;
    this.draggingTargetCollection = route.getCollection(collectionIndex);
  }

  onStopDraggingCollection(driverId: string, routeIndex: number, collectionIndex: number): void {
    this.draggingRoute = undefined;
    this.draggingCollection = undefined;
    this.draggingTargetRoute = undefined;
    this.draggingTargetCollection = undefined;

    for (const route of this.routes) {
      route.isDraggingCollection = false;
      for (const collection of route.collections) {
        collection.isSameRouteAsDraggingCollection = undefined;
        collection.isSortedAnimationEnabled =
          route.driverId.value === driverId && route.index === routeIndex && collection.index === collectionIndex;
      }
    }
  }

  updateZIndex(): void {
    // let zIndex = this.routes.length;
    let zIndex = 0;
    for (const route of this.routes) {
      route.zIndex = zIndex--;
    }
  }

  updateIsFixable(): void {
    const routeCollectionPairs = this.routes.flatMap((route) =>
      route.collections.map<[IRoute, Collection]>((collection) => [route, collection])
    );

    let isFixable = true;
    routeCollectionPairs.forEach((currentPair, index) => {
      const [prevRoute, prevCollection] = routeCollectionPairs[index - 1] ?? [];
      const [currentRoute, currentCollection] = currentPair;
      const [nextRoute, nextCollection] = routeCollectionPairs[index + 1] ?? [];

      if (prevCollection !== undefined && !prevRoute.driverId.equals(currentRoute.driverId)) {
        isFixable = true;
      }
      if (currentCollection.isFixedAssignment === false) {
        currentCollection.isFixable = isFixable;
        isFixable = false;
      } else if (nextCollection !== undefined && currentRoute.driverId.equals(nextRoute.driverId)) {
        currentCollection.isFixable = !nextCollection.isFixedAssignment;
      } else {
        currentCollection.isFixable = isFixable;
      }
    });
  }

  onRouteSortedAnimationEnd(driverId: string, routeIndex: number): void {
    this.getRoute(driverId, routeIndex).isSortedAnimationEnabled = false;
  }

  onRouteFixedAnimationEnd(driverId: string, routeIndex: number): void {
    this.getRoute(driverId, routeIndex).isFixedAnimationEnabled = false;
  }

  onCollectionFixedAnimationEnd(driverId: string, routeIndex: number, collectionIndex: number): void {
    this.getRoute(driverId, routeIndex).collections[collectionIndex].isFixedAnimationEnabled = false;
  }

  onCollectionSortedAnimationEnd(driverId: string, routeIndex: number, collectionIndex: number): void {
    this.getRoute(driverId, routeIndex).collections[collectionIndex].isSortedAnimationEnabled = false;
  }

  onUnfixDriver(driverId: string) {
    this.unfixAllAssignmentsOf(driverId);
    this.updateIsFixable();
  }

  reassignCollection(
    fromDriverId: Maybe<PersistentId>,
    fromRouteIndex: Maybe<number>,
    fromCollectionIndex: Maybe<number>,
    toDriverId: PersistentId,
    toRouteIndex: number,
    toCollectionIndex: number,
    intoPresentRoute: boolean,
    infeasibilityOrder: Maybe<AggregatedOrderEntity>,
    scrollToRoute: Maybe<Function>
  ) {
    super.reassignCollection(
      fromDriverId,
      fromRouteIndex,
      fromCollectionIndex,
      toDriverId,
      toRouteIndex,
      toCollectionIndex,
      intoPresentRoute,
      infeasibilityOrder,
      scrollToRoute
    );
    for (const route of this.routes) {
      route.onReassignCollection();
    }
    this.updateIsFixable();
    this.updateZIndex();
    this.updateUnassignedDriversInAttendance();
  }

  private updateUnassignedDriversInAttendance() {
    const assignedDriverSet = new Set(this.routes.map((route) => route.driver.id));
    this.unassignedDriverAttendances = this.driverAttendances.filter(
      (attendance) => !assignedDriverSet.has(attendance.driver.id)
    );
  }

  canDropRouteToTarget(): Set<DropRouteErrorReason> {
    const errors = new Set<DropRouteErrorReason>();
    if (
      this.draggingTargetRoute?.driverId &&
      this.draggingRoute?.driverId.equals(this.draggingTargetRoute?.driverId) && // 同じ乗務員内での移動だが、
      this.draggingTargetRoute.isFixedAssignmentInAnyOfCollections // 移動先のルートの回収が1つでも固定されている時
    ) {
      errors.add(DropRouteErrorReason.DroppingBeforeFixedRoute);
    }

    return errors;
  }

  canDropCollectionToTarget(): Set<DropCollectionErrorReason> {
    const errors = new Set<DropCollectionErrorReason>();
    if (
      this.draggingTargetCollection?.isSameRouteAsDraggingCollection && // 同じルート内での移動だが、
      this.draggingTargetCollection.isFixedAssignment // 移動先の回収が順固定されている時
    ) {
      errors.add(DropCollectionErrorReason.DroppingBeforeFixedCollection);
    }

    return errors;
  }

  private updateErrorNum(): void {
    if (this.inconsistencies !== undefined) this.errorNum = '?';
    else if (this.infeasibilities !== undefined) this.errorNum = `${this.infeasibilities.length}`;
    else this.errorNum = undefined;
  }
}
