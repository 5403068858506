import { Context } from '@nuxt/types';
import { getRelativeRefFrom, QueryKey, QueryValue } from '~/framework/core/uri';
import { Path } from '~/framework/constants';

/**
 * Nuxt の error レイアウトで表示を切り替える対応ができているものはここに定義している
 * 実際のエラーにこれを statusCode として指定すると、error 側の画面で参照する事ができる
 */
export enum NuxtErrorStatusCodes {
  GraphQLNetworkException = 600,
  EntityNotFoundException = 601,
  InconsistentSessionException = 602,
  IncompatibleBrowserException = 603,
  MaintenanceException = 700,
}

export const redirectToLogin = (context: Context) => {
  const queries = new Map<QueryKey, QueryValue>();
  for (const key in context.route.query) queries.set(key, context.route.query[key]);
  const relativeRef = getRelativeRefFrom(context.route.path, queries, context.route.hash);
  const redirectTo = encodeURIComponent(relativeRef);
  context.redirect(Path.login, { redirectTo });
};
