import { BaseTaskTypeName } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { IGenerationSiteTaskItem as IGenerationSiteTaskItemBase } from '~/components/panels/schedule/r-order-form/generationSiteTaskDuration';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

export interface IGenerationSiteTaskItem extends IGenerationSiteTaskItemBase {
  /**
   * 作業タイプ
   */
  taskType: Maybe<TaskTypeEntity>;

  /**
   * WasteType の persistentId
   */
  wasteTypeId: Maybe<string>;

  /**
   * ContainerType の persistentId
   */
  containerTypeId: Maybe<string>;

  /**
   * コンテナ数
   */
  containerNum: Maybe<number>;

  /**
   * 販売管理システムに送るための予定・見掛数量。
   */
  apparentQuantity: Maybe<number>;
  /**
   * 販売管理システムに送るための予定・見掛数量の単位。
   */
  apparentQuantityUnit: Maybe<string>;

  /**
   * 同じ内容のオブジェクトを生成する
   */
  clone(): IGenerationSiteTaskItem;
}

export class GenerationSiteTaskItem implements IGenerationSiteTaskItem {
  taskType: Maybe<TaskTypeEntity>;
  wasteTypeId: Maybe<string>;
  containerTypeId: Maybe<string>;
  containerNum: Maybe<number>;
  apparentQuantity: Maybe<number>;
  apparentQuantityUnit: Maybe<string>;

  constructor(
    taskType: Maybe<TaskTypeEntity>,
    wasteTypeId: Maybe<string>,
    containerTypeId: Maybe<string>,
    containerNum: Maybe<number>,
    apparentQuantity: Maybe<number>,
    apparentQuantityUnit: Maybe<string>
  ) {
    this.taskType = taskType;
    this.wasteTypeId = wasteTypeId;
    this.containerTypeId = containerTypeId;
    this.containerNum = containerNum;
    this.apparentQuantity = apparentQuantity;
    this.apparentQuantityUnit = apparentQuantityUnit;
  }

  /**
   * タスクの状態が有効かどうか
   * 設置のタスクの場合、品目が設定されていなくても有効であるとする
   */
  isValid(): boolean {
    if (this.taskType === undefined) return false;
    if (this.taskType.baseTaskType.name !== BaseTaskTypeName.AllocateAtGenerationSite && this.wasteTypeId === undefined)
      return false;
    if (this.containerTypeId === undefined) return false;
    if (this.containerNum === undefined || this.containerNum === 0) return false;
    return true;
  }

  clone(): IGenerationSiteTaskItem {
    return new GenerationSiteTaskItem(
      this.taskType,
      this.wasteTypeId,
      this.containerTypeId,
      this.containerNum,
      this.apparentQuantity,
      this.apparentQuantityUnit
    );
  }
}

export class ContainerTypeTaskItemFactory {
  static instantiateDefault(): IGenerationSiteTaskItem {
    return new GenerationSiteTaskItem(undefined, undefined, undefined, 1, undefined, undefined);
  }
}
