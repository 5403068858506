import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { PackingStyleCode } from '~/framework/domain/typeAliases';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';

export class PackingPlacementService {
  /**
   * 残存数が管理対象かどうかを取得する
   * @param containerType | packingStyle
   * @return 管理対象なら true
   */
  isPackingPlacementTracked(object: AggregatedContainerTypeEntity | PackingStyleEntity): boolean {
    if (this.isPackingStyle(object)) {
      return object.code !== PackingStyleCode.Code01;
    } else {
      return object.packingStyle.code !== PackingStyleCode.Code01;
    }
  }

  private isPackingStyle(object: AggregatedContainerTypeEntity | PackingStyleEntity): object is PackingStyleEntity {
    return 'code' in object;
  }
}
