import { TimelineEvent } from './timelineEvent';
import { ScheduleRequestOrderAssignment } from '~/graphql/custom-scalars/scheduleRequestJsonObjectTypes';
import { OrderAssignmentJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class OrderAssignment {
  // スキーマ上で定義されている項目
  orderAssignmentIndex: number;
  orderId: string;
  isOriginalAssignment: boolean;
  isFixedAssignment: boolean;
  events: TimelineEvent[];
  constructor(orderAssignment: OrderAssignmentJsonObject) {
    this.orderAssignmentIndex = orderAssignment.order_assignment_index;
    this.orderId = orderAssignment.order_id.toPseudoId().value;
    this.orderPseudoId = orderAssignment.order_id;
    this.isOriginalAssignment = orderAssignment.is_original_assignment;
    this.isFixedAssignment = orderAssignment.is_fixed_assignment;
    this.events = orderAssignment.events.map((event) => {
      return new TimelineEvent(event);
    });
  }

  // Rinで必要な項目
  orderPseudoId: string;

  getScheduleRequestOrderAssignment(): ScheduleRequestOrderAssignment {
    return {
      order_assignment_index: this.orderAssignmentIndex,
      order_id: this.orderPseudoId,
      is_fixed_assignment: this.isFixedAssignment,
    };
  }

  getOrderAssignmentJsonObject(): OrderAssignmentJsonObject {
    return {
      order_assignment_index: this.orderAssignmentIndex,
      order_id: this.orderPseudoId,
      is_original_assignment: this.isOriginalAssignment,
      is_fixed_assignment: this.isFixedAssignment,
      events: this.events.map((event) => event.getTimelineEventJsonObject()),
    };
  }

  getStartTime(): number {
    return this.events[0].time;
  }

  getEndTime(): number {
    return this.events[this.events.length - 1].time;
  }
}
