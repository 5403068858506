import { Location } from 'vue-router';
import { Path } from '~/framework/constants';
import { formatDateToString } from '~/framework/services/date/date';
import { Maybe, PersistentId } from '~/framework/typeAliases';

/**
 * URL の query parameter から獲得する受注検索の条件の型。
 * Value が string になるように Date や boolean を string として型を定義。
 */
export type SearchOrdersQueryParameters = {
  // プロパティがなくても大丈夫ように `?` を使う。

  clientId?: Maybe<PersistentId>;
  generationSiteId?: Maybe<PersistentId>;
  /** YYYY-MM-DD */
  startAt?: Maybe<string>;
  endAt?: Maybe<string>;
  createdStartAt?: Maybe<string>;
  createdEndAt?: Maybe<string>;
  note?: Maybe<string>;
  wasteTypeId?: Maybe<PersistentId>;
  includeCancelled?: 'true' | 'false';
  includeFollowingRecurringOrder?: 'true' | 'false';
  onlyFixedDisplayOnReservation?: 'true' | 'false';
};

/**
 * 受注検索に使う条件の型。
 */
export type SearchOrdersConditions = {
  // プロパティがなくても大丈夫ように `?` を使う。

  clientId?: Maybe<PersistentId>;
  generationSiteId?: Maybe<PersistentId>;
  /**
   * 受注日のこと。
   * 検索の期間範囲はstartAtからendAtまで。
   */
  startAt?: Maybe<Date>;
  endAt?: Maybe<Date>;
  /**
   * 登録日
   */
  createdStartAt?: Maybe<Date>;
  createdEndAt?: Maybe<Date>;
  /**
   * 備考欄
   */
  note?: Maybe<string>;
  /**
   * 廃棄物種別
   */
  wasteTypeId?: Maybe<PersistentId>;
  /**
   * キャンセルされた受注を含むかどうか。指定なしの場合は含まない (false)。
   */
  includeCancelled?: Maybe<Boolean>;
  /**
   * 繰り返しで作られた後続の受注を含めるかどうか
   * 登録日(createdStartAt or createdEndAt)が指定されているときに有効になる
   */
  includeFollowingRecurringOrder?: Maybe<Boolean>;

  /**
   * ウェブ依頼の標準作業解いて表示しているもののみ選択するかどうか
   */
  onlyFixedDisplayOnReservation?: Maybe<Boolean>;
};

export const getRouteLocationForSearchOrdersPage = (conditions?: Maybe<SearchOrdersConditions>): Location => {
  const path = Path.schedule.orders.index;

  const startAt = conditions?.startAt ? formatDateToString(conditions?.startAt) : undefined;
  const endAt = conditions?.endAt ? formatDateToString(conditions?.endAt) : undefined;
  const createdStartAt = conditions?.createdStartAt ? formatDateToString(conditions?.createdStartAt) : undefined;
  const createdEndAt = conditions?.createdEndAt ? formatDateToString(conditions?.createdEndAt) : undefined;
  const note = conditions?.note;
  const includeCancelled = conditions?.includeCancelled?.toString() as SearchOrdersQueryParameters['includeCancelled'];
  const includeFollowingRecurringOrder =
    conditions?.includeFollowingRecurringOrder?.toString() as SearchOrdersQueryParameters['includeFollowingRecurringOrder'];
  const onlyFixedDisplayOnReservation =
    conditions?.onlyFixedDisplayOnReservation?.toString() as SearchOrdersQueryParameters['onlyFixedDisplayOnReservation'];
  const query: SearchOrdersQueryParameters = {
    clientId: conditions?.clientId,
    generationSiteId: conditions?.generationSiteId,
    startAt,
    endAt,
    createdStartAt,
    createdEndAt,
    note,
    wasteTypeId: conditions?.wasteTypeId,
    includeCancelled,
    includeFollowingRecurringOrder,
    onlyFixedDisplayOnReservation,
  };

  return { path, query };
};
