import { Maybe } from '~/framework/typeAliases';
import { TaskType, BaseTaskTypeName } from '~/framework/domain/typeAliases';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderDisposalSitesAndTypeInput,
} from '~/framework/server-api/typeAliases';
import { IllegalArgumentException, IllegalStateException } from '~/framework/core/exception';

export enum ValidateTaskType {
  Create,
  Update,
}

export class OrderService {
  validateTasks(
    validateType: ValidateTaskType,
    generationSiteTasks: Maybe<CreateGenerationSiteTaskInput[]>,
    irregularTasks: Maybe<CreateIrregularTaskInput[]>
  ): void {
    let types = 0;
    if (0 < (generationSiteTasks?.length || 0)) types++;
    if (0 < (irregularTasks?.length || 0)) types++;
    if (validateType === ValidateTaskType.Create && types !== 1) {
      throw new IllegalArgumentException(`Should specify one generation site task type!`);
    }
    if (validateType === ValidateTaskType.Update && 2 <= types) {
      throw new IllegalArgumentException(`Should specify zero or one generation site task type!`);
    }
  }

  validateAssignedDisposalSiteIds(
    data: {
      generationSiteTasks: Maybe<CreateGenerationSiteTaskInput[]>;
      assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
      irregularTasks: CreateIrregularTaskInput[] | undefined;
    },
    taskTypes: Maybe<TaskType[]>
  ): void {
    // 設置のタスクは処分場に寄る必要がなく（廃棄物を現場から持ってきていないので）、設置のみのタスクの場合は
    // 処分場の指定が必須ではない設置以外のタスクが1つでも入っていた場合には処分場を指定する必要がある。
    // 本来的にはこういうのはサーバー側でやった方がいいが、ひとまず現状ではフロントエンドで蹴っておいて
    // そういうデータが登録されない様にしておく。
    const taskTypesMap: Record<string, TaskType> = {};
    if (taskTypes) {
      for (const taskType of taskTypes) {
        taskTypesMap[taskType.id] = taskType;
      }
    }

    if (data.assignedDisposalSitesAndType.orderDisposalSites.length === 0) {
      if (
        data.generationSiteTasks !== undefined &&
        // NOTE: 設置以外のタスクが1件でもあれば、処分場を指定する必要がある
        data.generationSiteTasks.some(
          (generationSiteTask) =>
            taskTypesMap[generationSiteTask.taskTypeId]?.baseTaskType.name !== BaseTaskTypeName.AllocateAtGenerationSite
        )
      ) {
        throw new IllegalStateException('設置以外の作業の場合は、処分場を指定する必要があります。');
      }

      // NOTE: 例外作業がある場合は、全ての作業が '処分場を経由しない' になっている必要がある
      if (
        data.irregularTasks !== undefined &&
        data.irregularTasks.length > 0 &&
        data.irregularTasks.some((task) => !task.skipDisposalSite)
      ) {
        throw new IllegalStateException('処分場を経由する場合は、処分場を指定する必要があります。');
      }
    }
  }
}
