import { IGenericObjectPanel } from '~/framework/view-models/panels/genericObjectPanel';
import { AbstractGenericObjectPanel } from '~/framework/view-models/panels/abstractGenericObjectPanel';
import { IOpenPanelOption } from '~/framework/view-models/panels/panel';
import { AggregatedPackingPlacementEntity } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementEntity';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { Maybe } from '~/framework/typeAliases';
import {
  IComponentProcessor,
  IProcessablePackingPlacements,
  IUpdateDataProcessor,
} from '~/components/panels/packing-placement/r-packing-placements-form/processors';

export interface IOpenData extends IOpenPanelOption {
  /**
   * 残存数
   */
  data: IPackingPlacementsAtSite;
}

export interface IPackingPlacementsAtSite extends IProcessablePackingPlacements {
  /**
   * 一意性を確認するための ID
   */
  id: string;

  /**
   * 最終設置日
   */
  lastAllocatedAt: Maybe<Date>;

  /**
   * 置いてある荷姿の残存数
   */
  packingPlacements: AggregatedPackingPlacementEntity[];
}

export interface ICloseData {
  /**
   * 残存数
   */
  data: IPackingPlacementsAtSite;
}

/**
 * 荷姿の残存数を編集するためのもの
 */
export interface IPackingPlacementsFormPanel extends IGenericObjectPanel<IOpenData, ICloseData> {}

export class PackingPlacementsFormPanel
  extends AbstractGenericObjectPanel<IOpenData, ICloseData>
  implements IPackingPlacementsFormPanel
{
  protected isDataSame(a: IOpenData, b: IOpenData): boolean {
    return a.data.id === b.data.id;
  }
}

export class PackingPlacementsAtGenerationSite implements IPackingPlacementsAtSite {
  get id(): string {
    return `PackingPlacementsAtGenerationSite$${this.generationSite.id}`;
  }

  readonly generationSite: AggregatedGenerationSiteEntity;
  readonly lastAllocatedAt: Maybe<Date>;
  readonly packingPlacements: AggregatedPackingPlacementEntity[];

  constructor(
    generationSite: AggregatedGenerationSiteEntity,
    lastAllocatedAt: Maybe<Date>,
    packingPlacements: AggregatedPackingPlacementEntity[]
  ) {
    this.generationSite = generationSite;
    this.lastAllocatedAt = lastAllocatedAt;
    this.packingPlacements = [...packingPlacements];
  }

  async acceptUpdateProcessor(processor: IUpdateDataProcessor): Promise<void> {
    await processor.updatePackingPlacementsAtGenerationSite(this);
  }

  acceptComponentProcessor(processor: IComponentProcessor): string {
    return processor.getComponentNameOfGenerationSite();
  }
}
