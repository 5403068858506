import { createLogger } from '~/framework/logger';

const logger = createLogger(`fetch`);
const warningThreshold = 10.0;

const extendedFetch = async (
  input: Parameters<typeof fetch>[0],
  init?: Parameters<typeof fetch>[1]
): Promise<Response> => {
  const response = await fetch(input, init);
  const xRuntime = (() => {
    const xRuntime = response.headers.get('x-runtime');
    return xRuntime ? parseFloat(xRuntime) : undefined;
  })();
  if (xRuntime && warningThreshold <= xRuntime) {
    const xRequestId = response.headers.get('x-request-id');
    const body = init?.body?.toString();
    const operationNames = (() => {
      if (body === undefined) {
        return [];
      } else {
        const regexp = /"operationName":"([a-zA-Z]+)"/g;
        const matches = body.matchAll(regexp);
        return Array.from(matches).map((match) => match[1]);
      }
    })();
    logger.addBreadcrumb({
      type: 'http',
      category: 'graphql',
      data: {
        body,
        'x-request-id': xRequestId,
      },
    });
    logger.warn(`Request took ${xRuntime} secs! Operation: ${operationNames.join(', ')}`);
  }
  return response;
};

export { extendedFetch as fetch };
