import { StoredMapperBase } from '~/framework/core/mapper';
import { UserSettingEntity, UserSettingEntityData } from '~/framework/domain/user-setting/userSettingEntity';
import { UserSettingStore } from '~/framework/domain/user-setting/userSettingStore';

export class UserSettingMapper extends StoredMapperBase<UserSettingEntityData, UserSettingEntity> {
  protected store: UserSettingStore;

  constructor(store: UserSettingStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: UserSettingEntityData): UserSettingEntity {
    return new UserSettingEntity(
      data.id,
      data.scheduleDate,
      data.scheduleStartOfWeek,
      data.scheduleTimelineStart,
      data.scheduleTimelineEnd,
      data.scheduleTab,
      data.orderGroupId,
      data.scheduleSolutionStatus,
      data.loginRememberMe
    );
  }

  protected updateWithData(data: UserSettingEntityData, entity: UserSettingEntity): void {
    entity.scheduleDate = data.scheduleDate;
    entity.scheduleStartOfWeek = data.scheduleStartOfWeek;
    entity.scheduleTimelineStart = data.scheduleTimelineStart;
    entity.scheduleTimelineEnd = data.scheduleTimelineEnd;
    entity.scheduleTab = data.scheduleTab;
    entity.orderGroupId = data.orderGroupId;
    entity.scheduleSolutionStatus = data.scheduleSolutionStatus;
    entity.loginRememberMe = data.loginRememberMe;
  }
}
