import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { ApolloContext } from 'src/graphql/localState';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Iso8601Date: Date;
  DayOfWeekNum: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  Second: number;
  Set: any;
  Iso8601DateTime: Date;
};

export type Mutation = {
  __typename?: 'Mutation';
  mutateSampleLocalState: Scalars['Boolean'];
  updateUserSettings: Array<Scalars['ID']>;
};

export type MutationMutateSampleLocalStateArgs = {
  id: Scalars['ID'];
  value1: Maybe<Scalars['String']>;
  value2: Maybe<Scalars['String']>;
  value3: Maybe<Scalars['String']>;
};

export type MutationUpdateUserSettingsArgs = {
  userSettings: Array<UpdateUserSettingInput>;
};

export type Query = {
  __typename?: 'Query';
  sampleLocalState: SampleLocalState;
  userSettingsByIds: Array<UserSetting>;
};

export type QueryUserSettingsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type SampleLocalState = {
  __typename?: 'SampleLocalState';
  id: Scalars['ID'];
  value1: Scalars['String'];
  value2: Scalars['String'];
  value3: Scalars['String'];
};

export type ScheduleSolutionStatus = {
  __typename?: 'ScheduleSolutionStatus';
  scheduleId: Scalars['ID'];
  inconsistencies: Scalars['Set'];
  infeasibilities: Scalars['Set'];
  updatedAt: Scalars['Iso8601DateTime'];
};

export type ScheduleSolutionStatusInput = {
  scheduleId: Scalars['ID'];
  inconsistencies: Scalars['Set'];
  infeasibilities: Scalars['Set'];
  updatedAt: Scalars['Iso8601DateTime'];
};

export enum ScheduleTabType {
  Schedule = 0,
  Orders = 1,
  Errors = 2,
}

export type UpdateUserSettingInput = {
  id: Scalars['ID'];
  scheduleDate: Scalars['Iso8601Date'];
  scheduleStartOfWeek: Scalars['DayOfWeekNum'];
  scheduleTimelineStart: Scalars['Second'];
  scheduleTimelineEnd: Scalars['Second'];
  scheduleTab: ScheduleTabType;
  orderGroupId: Maybe<Scalars['ID']>;
  scheduleSolutionStatus: Array<ScheduleSolutionStatusInput>;
  loginRememberMe: Scalars['Boolean'];
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['ID'];
  scheduleDate: Scalars['Iso8601Date'];
  scheduleStartOfWeek: Scalars['DayOfWeekNum'];
  scheduleTimelineStart: Scalars['Second'];
  scheduleTimelineEnd: Scalars['Second'];
  scheduleTab: ScheduleTabType;
  scheduleSolutionStatus: Array<ScheduleSolutionStatus>;
  orderGroupId: Maybe<Scalars['ID']>;
  loginRememberMe: Scalars['Boolean'];
};

export type MutateSampleLocalStateNodeMutationVariables = Exact<{
  id: Scalars['ID'];
  value1: Maybe<Scalars['String']>;
  value2: Maybe<Scalars['String']>;
  value3: Maybe<Scalars['String']>;
}>;

export type MutateSampleLocalStateNodeMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'mutateSampleLocalState'>;

export type UpdateUserSettingsMutationVariables = Exact<{
  userSettings: Array<UpdateUserSettingInput> | UpdateUserSettingInput;
}>;

export type UpdateUserSettingsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateUserSettings'>;

export type SampleLocalStateNodeQueryVariables = Exact<{ [key: string]: never }>;

export type SampleLocalStateNodeQuery = { __typename?: 'Query' } & {
  sampleLocalState: { __typename?: 'SampleLocalState' } & Pick<SampleLocalState, 'id' | 'value1' | 'value2' | 'value3'>;
};

export type UserSettingsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UserSettingsByIdsQuery = { __typename?: 'Query' } & {
  userSettingsByIds: Array<
    { __typename?: 'UserSetting' } & Pick<
      UserSetting,
      | 'id'
      | 'scheduleDate'
      | 'scheduleStartOfWeek'
      | 'scheduleTimelineStart'
      | 'scheduleTimelineEnd'
      | 'scheduleTab'
      | 'orderGroupId'
      | 'loginRememberMe'
    > & {
        scheduleSolutionStatus: Array<
          { __typename?: 'ScheduleSolutionStatus' } & Pick<
            ScheduleSolutionStatus,
            'scheduleId' | 'inconsistencies' | 'infeasibilities' | 'updatedAt'
          >
        >;
      }
  >;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  SampleLocalState: ResolverTypeWrapper<SampleLocalState>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  UserSetting: ResolverTypeWrapper<UserSetting>;
  Iso8601Date: ResolverTypeWrapper<Scalars['Iso8601Date']>;
  DayOfWeekNum: ResolverTypeWrapper<Scalars['DayOfWeekNum']>;
  Second: ResolverTypeWrapper<Scalars['Second']>;
  ScheduleTabType: ScheduleTabType;
  ScheduleSolutionStatus: ResolverTypeWrapper<ScheduleSolutionStatus>;
  Set: ResolverTypeWrapper<Scalars['Set']>;
  Iso8601DateTime: ResolverTypeWrapper<Scalars['Iso8601DateTime']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Mutation: ResolverTypeWrapper<{}>;
  UpdateUserSettingInput: UpdateUserSettingInput;
  ScheduleSolutionStatusInput: ScheduleSolutionStatusInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  SampleLocalState: SampleLocalState;
  ID: Scalars['ID'];
  String: Scalars['String'];
  UserSetting: UserSetting;
  Iso8601Date: Scalars['Iso8601Date'];
  DayOfWeekNum: Scalars['DayOfWeekNum'];
  Second: Scalars['Second'];
  ScheduleSolutionStatus: ScheduleSolutionStatus;
  Set: Scalars['Set'];
  Iso8601DateTime: Scalars['Iso8601DateTime'];
  Boolean: Scalars['Boolean'];
  Mutation: {};
  UpdateUserSettingInput: UpdateUserSettingInput;
  ScheduleSolutionStatusInput: ScheduleSolutionStatusInput;
};

export interface DayOfWeekNumScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DayOfWeekNum'], any> {
  name: 'DayOfWeekNum';
}

export interface Iso8601DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Iso8601Date'], any> {
  name: 'Iso8601Date';
}

export interface Iso8601DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Iso8601DateTime'], any> {
  name: 'Iso8601DateTime';
}

export type MutationResolvers<
  ContextType = ApolloContext,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  mutateSampleLocalState: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationMutateSampleLocalStateArgs, 'id'>
  >;
  updateUserSettings: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserSettingsArgs, 'userSettings'>
  >;
};

export type QueryResolvers<
  ContextType = ApolloContext,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  sampleLocalState: Resolver<ResolversTypes['SampleLocalState'], ParentType, ContextType>;
  userSettingsByIds: Resolver<
    Array<ResolversTypes['UserSetting']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserSettingsByIdsArgs, 'ids'>
  >;
};

export type SampleLocalStateResolvers<
  ContextType = ApolloContext,
  ParentType extends ResolversParentTypes['SampleLocalState'] = ResolversParentTypes['SampleLocalState']
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value1: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value2: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value3: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleSolutionStatusResolvers<
  ContextType = ApolloContext,
  ParentType extends ResolversParentTypes['ScheduleSolutionStatus'] = ResolversParentTypes['ScheduleSolutionStatus']
> = {
  scheduleId: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inconsistencies: Resolver<ResolversTypes['Set'], ParentType, ContextType>;
  infeasibilities: Resolver<ResolversTypes['Set'], ParentType, ContextType>;
  updatedAt: Resolver<ResolversTypes['Iso8601DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleTabTypeResolvers = { Schedule: 0; Orders: 1; Errors: 2 };

export interface SecondScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Second'], any> {
  name: 'Second';
}

export interface SetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Set'], any> {
  name: 'Set';
}

export type UserSettingResolvers<
  ContextType = ApolloContext,
  ParentType extends ResolversParentTypes['UserSetting'] = ResolversParentTypes['UserSetting']
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scheduleDate: Resolver<ResolversTypes['Iso8601Date'], ParentType, ContextType>;
  scheduleStartOfWeek: Resolver<ResolversTypes['DayOfWeekNum'], ParentType, ContextType>;
  scheduleTimelineStart: Resolver<ResolversTypes['Second'], ParentType, ContextType>;
  scheduleTimelineEnd: Resolver<ResolversTypes['Second'], ParentType, ContextType>;
  scheduleTab: Resolver<ResolversTypes['ScheduleTabType'], ParentType, ContextType>;
  scheduleSolutionStatus: Resolver<Array<ResolversTypes['ScheduleSolutionStatus']>, ParentType, ContextType>;
  orderGroupId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  loginRememberMe: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = ApolloContext> = {
  DayOfWeekNum: GraphQLScalarType;
  Iso8601Date: GraphQLScalarType;
  Iso8601DateTime: GraphQLScalarType;
  Mutation: MutationResolvers<ContextType>;
  Query: QueryResolvers<ContextType>;
  SampleLocalState: SampleLocalStateResolvers<ContextType>;
  ScheduleSolutionStatus: ScheduleSolutionStatusResolvers<ContextType>;
  ScheduleTabType: ScheduleTabTypeResolvers;
  Second: GraphQLScalarType;
  Set: GraphQLScalarType;
  UserSetting: UserSettingResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = ApolloContext> = Resolvers<ContextType>;
