import _ from 'lodash';
import { PostponeOrderStatus, PseudoOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { IScheduleSolutionStatus } from '~/framework/server-api/user-setting/userSetting';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { IScheduleData, ScheduleData } from '~/pages/schedule/scheduleData';
import {
  RawScheduleInfeasibilityJsonObject,
  RawInconsistencyJsonObject,
  ScheduleInfeasibilityOvertimeWorkType,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import {
  IPotentialModification,
  IPotentialModificationDisposalSite,
  IPotentialModificationDriver,
  IPotentialModificationOrder,
} from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import { DriverReason, Infeasibility } from '~/pages/schedule/infeasibility';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { OrderAcceptanceCheckId } from '~/framework/constants';
import { mapEntity } from '~/framework/core/mapper';

import { IdGenerator } from '~/framework/core/id';
import { IRoute, Route } from '~/pages/schedule/route';
import { ICreateOrderAcceptanceCheckData } from '~/framework/server-api/schedule/order/order-acceptance-check/create';
import { Collection } from '~/pages/schedule/collection';
import { defaultRouteSettings } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import {
  DriverScheduleJsonObject,
  ScheduleResponseJsonObject,
} from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedBaseSiteEntity } from '~/framework/domain/masters/base-site/aggregatedBaseSiteEntity';
import { convertOrderPlanInputToOrderPlan } from '~/framework/domain/schedule/order/orderUtils';
import { OrderApplicationService, orderSymbol } from '~/framework/application/schedule/order/orderApplicationService';
import {
  DriverApplicationService,
  driverSymbol,
} from '~/framework/application/masters/driver/driverApplicationService';
import { CarApplicationService, carSymbol } from '~/framework/application/masters/car/carApplicationService';
import {
  CarTypeApplicationService,
  carTypeSymbol,
} from '~/framework/application/masters/car-type/carTypeApplicationService';
import {
  DisposalSiteApplicationService,
  disposalSiteSymbol,
} from '~/framework/application/masters/disposal-site/disposalSiteApplicationService';
import {
  DisposalSiteAttendanceApplicationService,
  disposalSiteAttendanceServiceSymbol,
} from '~/framework/application/masters/disposal-site-attendance/disposalSiteAttendanceApplicationService';
import {
  ClientApplicationService,
  clientSymbol,
} from '~/framework/application/masters/client/clientApplicationService';
import {
  BaseSiteApplicationService,
  baseSiteSymbol,
} from '~/framework/application/masters/base-site/baseSiteApplicationService';
import {
  GenerationSiteApplicationService,
  generationSiteSymbol,
} from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import { ApplicationServiceManager } from '~/framework/application/applicationServiceManager';
import {
  UserSettingApplicationService,
  userSettingSymbol,
} from '~/framework/application/userSettingApplicationService';
import {
  DriverAttendanceApplicationService,
  driverAttendanceSymbol,
} from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';

/**
 * TODO: pseudoOrderMapはacceptanceCheck関連の作成不可の表示にのみ使われ、
 * 基本的にはorderMapが使われるので両方をpreparedDataとして渡すのは無駄が多いので、
 * PreparedData 自体のリファクタリングを検討する
 */
export interface IPreparedData {
  driverMap: Map<string, AggregatedDriverEntity>;
  pseudoOrderMap: Map<string, PseudoOrderEntity>;
  orderMap: Map<string, PseudoOrderEntity>;
  carMap: Map<string, AggregatedCarEntity>;
  carTypeMap: Map<string, AggregatedCarTypeEntity>;
  disposalSiteMap: Map<string, AggregatedDisposalSiteEntity>;
  baseSiteMap: Map<string, AggregatedBaseSiteEntity>;
}

/**
 * 配車表上で使われていて明示的に読み込む必要のあるリソース
 */
export interface IRequiredResources {
  carIdSet: Set<PersistentId>;
  carTypeIdSet: Set<PersistentId>;
  driverIdSet: Set<PersistentId>;
  orderIdSet: Set<PersistentId>;
  disposalSiteIdSet: Set<PersistentId>;
}

type ScheduleResponseJsonObjectCore = Omit<ScheduleResponseJsonObject, 'schedule_identifier' | 'schedule_version'>;

type PreparedDataOfInfeasibilities = Omit<IPreparedData, 'baseSiteMap'>;

type PreparedDataOfInconsistencies = Pick<IPreparedData, 'driverMap' | 'orderMap'>;

const overtimeWorkTypeSnakeToPascalMap = new Map<ScheduleInfeasibilityOvertimeWorkType, OvertimeWorkType>([
  [ScheduleInfeasibilityOvertimeWorkType.None, OvertimeWorkType.None],
  [ScheduleInfeasibilityOvertimeWorkType.Both, OvertimeWorkType.Both],
  [ScheduleInfeasibilityOvertimeWorkType.AvailableInEarlyTime, OvertimeWorkType.AvailableInEarlyTime],
  [ScheduleInfeasibilityOvertimeWorkType.AvailableInLateTime, OvertimeWorkType.AvailableInLateTime],
]);

/**
 * ScheduleData や ScheduleDataEntity を作成する時に必要になるベースの機能をまとめたもの。このクラス単体で利用する
 * 事は想定しないので abstract になっている。現状のところ ScheduleDataEntity を生成するシチュエーションがないので
 * ScheduleData を作成するために利用する事しか想定していない。
 */
abstract class ScheduleFactoryBase {
  protected readonly baseSiteApplicationService: BaseSiteApplicationService;
  protected readonly carApplicationService: CarApplicationService;
  protected readonly carTypeApplicationService: CarTypeApplicationService;
  protected readonly clientApplicationService: ClientApplicationService;
  protected readonly disposalSiteApplicationService: DisposalSiteApplicationService;
  protected readonly disposalSiteAttendanceApplicationService: DisposalSiteAttendanceApplicationService;
  protected readonly driverApplicationService: DriverApplicationService;
  protected readonly driverAttendanceApplicationService: DriverAttendanceApplicationService;
  protected readonly generationSiteApplicationService: GenerationSiteApplicationService;
  protected readonly orderApplicationService: OrderApplicationService;
  protected readonly userSettingApplicationService: UserSettingApplicationService;

  constructor(applicationServiceManager: ApplicationServiceManager) {
    this.baseSiteApplicationService = applicationServiceManager.get(baseSiteSymbol);
    this.carApplicationService = applicationServiceManager.get(carSymbol);
    this.carTypeApplicationService = applicationServiceManager.get(carTypeSymbol);
    this.clientApplicationService = applicationServiceManager.get(clientSymbol);
    this.disposalSiteApplicationService = applicationServiceManager.get(disposalSiteSymbol);
    this.disposalSiteAttendanceApplicationService = applicationServiceManager.get(disposalSiteAttendanceServiceSymbol);
    this.driverApplicationService = applicationServiceManager.get(driverSymbol);
    this.driverAttendanceApplicationService = applicationServiceManager.get(driverAttendanceSymbol);
    this.generationSiteApplicationService = applicationServiceManager.get(generationSiteSymbol);
    this.orderApplicationService = applicationServiceManager.get(orderSymbol);
    this.userSettingApplicationService = applicationServiceManager.get(userSettingSymbol);
  }

  // TODO: scheduleDataはCSVダウンロードで利用するためだけに残している、V1対応が終われば削除する
  protected async prepareV2(
    scheduleResponse: ScheduleResponseJsonObjectCore,
    acceptanceCheckInput: Maybe<ICreateOrderAcceptanceCheckData>
  ): Promise<IPreparedData> {
    const requiredResources = this.getRequiredResourcesOfScheduleResponse(scheduleResponse, acceptanceCheckInput);
    const [baseSites, cars, carTypes, disposalSites, drivers, aggregatedOrders] = await Promise.all([
      this.baseSiteApplicationService.getAll(),
      this.carApplicationService.getAll(),
      this.carTypeApplicationService.getAll(),
      // TODO: 余分な disposalSites を取得しないように getAll を使わず、 requiredResoueces に infeasibility の disposalSites を含めるようにする
      this.disposalSiteApplicationService.getAll(),
      this.driverApplicationService.getByIds(requiredResources.driverIdSet.toArray()),
      this.orderApplicationService.getByIds(requiredResources.orderIdSet.toArray()),
    ]);

    const orders = aggregatedOrders as PseudoOrderEntity[];

    let pseudoEntity: Maybe<PseudoOrderEntity>;
    if (acceptanceCheckInput !== undefined && acceptanceCheckInput.id === OrderAcceptanceCheckId) {
      // もし新規受注チェックの場合には ID がまだ存在しない Order になるので、その場合は PseudoOrderを生成する
      const generationSite = await this.generationSiteApplicationService.getById(acceptanceCheckInput.generationSiteId);
      pseudoEntity = new PseudoOrderEntity(
        OrderAcceptanceCheckId,
        OrderAcceptanceCheckId,
        convertOrderPlanInputToOrderPlan(acceptanceCheckInput.plan)!,
        generationSite.client,
        generationSite,
        acceptanceCheckInput.preloadStatus,
        PostponeOrderStatus.Default,
        cars.filter((car) => acceptanceCheckInput.assignableCarIds.includes(car.id)),
        carTypes.filter((carType) => acceptanceCheckInput.assignableCarTypeIds.includes(carType.id)),
        acceptanceCheckInput.fixedArrivalTime
      );
      orders.push(pseudoEntity);
    }

    return {
      driverMap: mapEntity(drivers),
      carMap: mapEntity(cars),
      carTypeMap: mapEntity(carTypes),
      disposalSiteMap: mapEntity(disposalSites),
      pseudoOrderMap: mapEntity(orders),
      orderMap: mapEntity(orders),
      baseSiteMap: mapEntity(baseSites),
    };
  }

  /**
   * 配車表データに必要なリソースの情報を取得するV2
   * @param scheduleResponse
   * @private
   */
  private getRequiredResourcesOfScheduleResponse(
    scheduleResponse: ScheduleResponseJsonObjectCore,
    acceptanceCheckInput: Maybe<ICreateOrderAcceptanceCheckData>
  ): IRequiredResources {
    // IScheduleJsonObject から取り出した ID はそのままだと string だが、内部では PseudoId にしないと扱いづらい
    // なのでいったん PseudoId に変換してから PseudoId.value を取り出しているので注意
    const resources: IRequiredResources = {
      carIdSet: new Set<PersistentId>(),
      carTypeIdSet: new Set<PersistentId>(),
      driverIdSet: new Set<PersistentId>(),
      orderIdSet: new Set<PersistentId>(),
      disposalSiteIdSet: new Set<PersistentId>(),
    };

    // driver内のリソースに追加する
    this.addRequiredResourceOfDrivers(scheduleResponse.driver_schedules, resources);

    // 作成不可まわり
    if (scheduleResponse.infeasibilities) {
      this.addRequiredResourceOfInfeasibilities(scheduleResponse.infeasibilities, resources);
    }
    if (scheduleResponse.inconsistencies) {
      this.addRequiredResourceOfInconsistency(scheduleResponse.inconsistencies, resources);
    }

    // not_assigned_driver_idsをdriverIdSetに追加
    resources.driverIdSet.addValues(...scheduleResponse.not_assigned_driver_ids.map((id) => id.toPseudoId().value));
    // not_assigned_order_idsをorderIdSetに追加
    resources.orderIdSet.addValues(...scheduleResponse.not_assigned_order_ids.map((id) => id.toPseudoId().value));

    // acceptanceCheckの場合は、未登録のorderに対応するリソースを追加する
    if (acceptanceCheckInput !== undefined) {
      this.addRequiredResourceOfAcceptanceCheck(acceptanceCheckInput, resources);
    }

    return resources;
  }

  /**
   * 配車表データに必要なリソースの情報を取得する
   * @param driverSchedules
   * @private
   */
  private addRequiredResourceOfDrivers(
    driverSchedules: DriverScheduleJsonObject[],
    resources: IRequiredResources
  ): void {
    driverSchedules.forEach((driverSchedule) => {
      // routesは廃止されて上位にdriverの配列があるためdriverIdはそこから取得する
      resources.driverIdSet.addValues(driverSchedule.driver_id.toPseudoId().value);
      // orderIdはroute.order_assignmentsのidから
      resources.orderIdSet.addValues(
        ..._.flatten(driverSchedule.routes.map((route) => route.order_assignments)).map(
          (orderAssignment) => orderAssignment.order_id.toPseudoId().value
        )
      );
      // driver_schedule.routes配列の中のroute.car_idsの配列を一つの配列にまとめる
      let carIds: string[] = [];
      driverSchedule.routes.forEach((route) => {
        if (route.car_ids === undefined) return;
        carIds = carIds.concat(route.car_ids.map((carId) => carId.toPseudoId().value));
      });
      resources.carIdSet.addValues(...carIds);
      resources.disposalSiteIdSet.addValues(
        ..._.flatten(driverSchedule.routes.map((route) => route.disposals)).map(
          (disposalSite) => disposalSite.disposal_site_id.toPseudoId().value
        )
      );
    });
  }

  private addRequiredResourceOfInfeasibilities(
    infeasibilities: RawScheduleInfeasibilityJsonObject[],
    resources: IRequiredResources
  ): void {
    for (const infeasibility of infeasibilities) {
      if (infeasibility.assigned_driver_id !== undefined) {
        resources.driverIdSet.add(infeasibility.assigned_driver_id.toPseudoId().value);
      }
      if (infeasibility.driver_reasons !== undefined) {
        for (const driverReason of infeasibility.driver_reasons) {
          resources.driverIdSet.add(driverReason.driver_id.toPseudoId().value);
        }
      }
      if (infeasibility.assigned_car_id !== undefined) {
        resources.carIdSet.add(infeasibility.assigned_car_id.toPseudoId().value);
      }
      if (infeasibility.acceptable_car_type_ids !== undefined) {
        resources.carTypeIdSet.addValues(...infeasibility.acceptable_car_type_ids.map((id) => id.toPseudoId().value));
      }
      resources.orderIdSet.add(infeasibility.order_id.toPseudoId().value);
    }
  }

  private addRequiredResourceOfInconsistency(
    inconsistencies: RawInconsistencyJsonObject[],
    resources: IRequiredResources
  ): void {
    for (const inconsistency of inconsistencies) {
      resources.driverIdSet.add(inconsistency.driver_id.toPseudoId().value);
      if (inconsistency.order_ids !== undefined && inconsistency.order_ids) {
        resources.orderIdSet.addValues(...inconsistency.order_ids.map((id) => id.toPseudoId().value));
      }
    }
  }

  private addRequiredResourceOfAcceptanceCheck(
    acceptanceCheckInput: ICreateOrderAcceptanceCheckData,
    resources: IRequiredResources
  ): void {
    // orderIdは一時的OrderAcceptanceCheckIdであるはずなので追加しない
    for (const carId of acceptanceCheckInput.assignableCarIds) {
      resources.carIdSet.add(carId);
    }

    for (const carTypeId of acceptanceCheckInput.assignableCarTypeIds) {
      resources.carIdSet.add(carTypeId);
    }

    for (const orderDisposalSite of acceptanceCheckInput.assignedDisposalSitesAndType.orderDisposalSites) {
      resources.disposalSiteIdSet.add(orderDisposalSite.disposalSiteId);
    }
    if (acceptanceCheckInput.assignableDriversAndNum.assignableDrivers !== undefined) {
      for (const assignableDriver of acceptanceCheckInput.assignableDriversAndNum.assignableDrivers) {
        resources.driverIdSet.add(assignableDriver.driverId);
      }
    }
  }
}

/**
 * ScheduleData を作成するためのクラス
 */
export class ScheduleFactory extends ScheduleFactoryBase {
  // 新規ルート作成時に必要になるためキャッシュしている
  // 厳密には全てを保持している必要はない
  private preparedData: IPreparedData;

  constructor(applicationServiceManager: ApplicationServiceManager) {
    super(applicationServiceManager);
    // ビルドした時に詰められるが、Maybe になっていると面倒なのでとりあえずダミーを詰めておく
    this.preparedData = {
      driverMap: new Map(),
      pseudoOrderMap: new Map(),
      orderMap: new Map(),
      disposalSiteMap: new Map(),
      carTypeMap: new Map(),
      carMap: new Map(),
      baseSiteMap: new Map(),
    };
  }

  async buildByDataV2(
    scheduleId: string,
    date: Date,
    scheduleResponse: ScheduleResponseJsonObject
  ): Promise<IScheduleData> {
    const [preparedData, driverAttendances, userSetting] = await Promise.all([
      this.prepareV2(scheduleResponse, undefined),
      this.driverAttendanceApplicationService.getByDates(date, date),
      this.userSettingApplicationService.get(),
    ]);
    this.preparedData = preparedData;
    const drivers = [...this.preparedData.driverMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const cars = [...this.preparedData.carMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const disposals = [...this.preparedData.disposalSiteMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const orders = [...this.preparedData.orderMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const scheduleSolutionStatus = userSetting.getScheduleSolutionStatusOf(scheduleId);
    const inconsistencies =
      scheduleResponse.inconsistencies === undefined
        ? undefined
        : this.buildInconsistencies(scheduleResponse.inconsistencies, this.preparedData, scheduleSolutionStatus);
    const infeasibilities =
      scheduleResponse.infeasibilities === undefined
        ? undefined
        : this.buildInfeasibilities(scheduleResponse.infeasibilities, this.preparedData, scheduleSolutionStatus);

    // FIXME: ScheduleDataはリソースの入れ物としてのみ使われている
    // 旧型式のroutes周りの機能などが多く残っているので、リファクタリングする
    // driverAttendancesは、「配車予定がない乗務員」を表示するのに必要
    const entity = new ScheduleData(
      IdGenerator.generateNewId(),
      this,
      [],
      [],
      inconsistencies,
      infeasibilities,
      drivers,
      driverAttendances,
      cars,
      disposals,
      orders
    );
    return entity;
  }

  async buildOrderAcceptanceCheckInconsistencies(
    inconsistencyData: RawInconsistencyJsonObject[],
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<Inconsistency[]> {
    // buildV2, buildOrderAcceptanceCheckInconsistenciesと処理を統一するため、
    // ほとんど空のscheduleResponseを構成する
    const scheduleResponse: ScheduleResponseJsonObjectCore = {
      driver_schedules: [],
      infeasibilities: [],
      inconsistencies: inconsistencyData,
      not_assigned_order_ids: [],
      not_assigned_driver_ids: [],
    };
    // disposalSiteAttendanceを取得したくなった場合にdateを取得する実装
    // const date: Date = getBaseDateFromOrderPlanInput(input.plan)!;
    const preparedData = await this.prepareV2(scheduleResponse, undefined);
    return this.buildInconsistencies(inconsistencyData, preparedData, scheduleSolutionStatus);
  }

  async buildOrderAcceptanceCheckInfeasibilities(
    infeasibilityDatas: RawScheduleInfeasibilityJsonObject[],
    input: ICreateOrderAcceptanceCheckData,
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<Infeasibility[]> {
    // buildV2, buildOrderAcceptanceCheckInconsistenciesと処理を統一するため、
    // ほとんど空のscheduleResponseを構成する
    const scheduleResponse: ScheduleResponseJsonObjectCore = {
      driver_schedules: [],
      infeasibilities: infeasibilityDatas,
      inconsistencies: [],
      not_assigned_order_ids: [],
      not_assigned_driver_ids: [],
    };
    // disposalSiteAttendanceを取得したくなった場合にdateを取得する実装
    // const date: Date = getBaseDateFromOrderPlanInput(input.plan)!;
    const preparedData = await this.prepareV2(scheduleResponse, input);
    return this.buildInfeasibilities(infeasibilityDatas, preparedData, scheduleSolutionStatus);
  }

  createRoute(driverId: PersistentId, collections: Collection[]): IRoute {
    const id = IdGenerator.generateNewId();
    const pseudoDriverId = new PseudoId('driver', driverId);
    const driver = this.preparedData.driverMap.getOrError(driverId);
    // endTime が入っていないとルートの必要時間と回収の時間が矛盾する事になってしまって
    // その後の計算ができなくなるのでひとまずルートの必要時間は回収の必要時間の合計という
    // 事にしている。後々無理が出てきそうな気がしなくもないが。。。
    const collectionDuration = collections.reduce((value, collection) => {
      return value + (collection.original?.generationSiteDuration ?? 0);
    }, 0);
    const endTime = defaultRouteSettings.generationSiteArrivalTimeDiff + collectionDuration;
    return new Route(
      undefined,
      id,
      0,
      undefined,
      undefined,
      pseudoDriverId,
      undefined,
      undefined,
      0,
      endTime,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      false,
      collections,
      [],
      [],
      [],
      undefined,
      driver,
      undefined,
      undefined,
      undefined
    );
  }

  private buildInconsistencies(
    inconsistencyDatas: RawInconsistencyJsonObject[],
    preparedData: PreparedDataOfInconsistencies,
    scheduleSolutionStatus: Maybe<IScheduleSolutionStatus> = undefined
  ): Inconsistency[] {
    const { driverMap, orderMap } = preparedData;
    const inconsistencies: Inconsistency[] = [];
    for (const [index, data] of inconsistencyDatas.entries()) {
      const id = IdGenerator.generateNewId();
      const driverId = data.driver_id.toPseudoId();
      const inconsistencyData = { ...data, id, driverId };
      const orders = data.order_ids?.map((id) => orderMap.getOrError(id.toPseudoId().value));
      inconsistencies.push(
        new Inconsistency(
          inconsistencyData,
          driverMap.getOrError(driverId.value),
          index,
          scheduleSolutionStatus ? scheduleSolutionStatus.inconsistencies.has(index) : false,
          orders
        )
      );
    }
    return inconsistencies;
  }

  private buildInfeasibilities(
    infeasibilityDatas: RawScheduleInfeasibilityJsonObject[],
    preparedData: PreparedDataOfInfeasibilities,
    scheduleSolutionStatus: Maybe<IScheduleSolutionStatus>
  ): Infeasibility[] {
    const { driverMap, carTypeMap, orderMap, disposalSiteMap } = preparedData;
    const infeasibilities: Infeasibility[] = [];
    for (const [index, data] of infeasibilityDatas.entries()) {
      // 先に DriverReason を生成しておく
      let driverReasons: Maybe<DriverReason[]>;
      if (data.driver_reasons) {
        driverReasons = [];
        for (const driverReasonData of data.driver_reasons) {
          const driverId = driverReasonData.driver_id.toPseudoId();
          driverReasons.push(new DriverReason(driverMap.get(driverId.value)!, driverReasonData.reasons));
        }
      }

      const id = IdGenerator.generateNewId();
      const order = orderMap.get(data.order_id.toPseudoId().value)!;
      const assignedDriverId: Maybe<PseudoId> = data.assigned_driver_id?.toPseudoId();
      const acceptableCarTypeIds: Maybe<PseudoId[]> = data.acceptable_car_type_ids?.map((id) => id.toPseudoId());

      const assignedDriver: Maybe<AggregatedDriverEntity> =
        assignedDriverId === undefined ? undefined : driverMap.get(assignedDriverId.value);

      const acceptableCarTypes: Maybe<AggregatedCarTypeEntity[]> =
        acceptableCarTypeIds === undefined ? undefined : acceptableCarTypeIds.map((id) => carTypeMap.get(id.value)!);

      const potentialModifications: Maybe<IPotentialModification[]> =
        data.potential_modifications === undefined
          ? undefined
          : data.potential_modifications.map((modification) => {
              return {
                drivers:
                  modification.drivers === undefined
                    ? undefined
                    : modification.drivers.map((driver) => {
                        return {
                          ...driverMap.getOrError(driver.driver_id.toPseudoId().value),
                          regularWorkPeriodStart: driver.regular_work_period_start,
                          regularWorkPeriodEnd: driver.regular_work_period_end,
                          overtimeWorkType: overtimeWorkTypeSnakeToPascalMap.getOrError(driver.overtime_work_type),
                          overtimeWorkableDuration: driver.overtime_workable_duration,
                          idealOvertimeWorkType:
                            driver.ideal_overtime_work_type === undefined
                              ? undefined
                              : overtimeWorkTypeSnakeToPascalMap.get(driver.ideal_overtime_work_type),
                          idealOvertimeWorkableDuration: driver.ideal_overtime_workable_duration,
                        } as IPotentialModificationDriver;
                      }),
                // acceptanceCheckのorder_idがまぎれている場合に、orderMapから正常に値を取得できない
                // そのような場合は取得して表示できる必要がないので、ここではじく
                orders:
                  modification.orders === undefined
                    ? undefined
                    : modification.orders.map((order) => {
                        return {
                          orderId: order.order_id.toPseudoId(),
                          order: orderMap.getOrError(order.order_id.toPseudoId().value),
                          collectablePeriodStart: order.collectable_period_start,
                          collectablePeriodEnd: order.collectable_period_end,
                          idealArrivalTime: order.ideal_arrival_time,
                        } as IPotentialModificationOrder;
                      }),
                disposalSites:
                  modification.disposal_sites === undefined
                    ? undefined
                    : modification.disposal_sites.map((disposalSite) => {
                        // NOTE: 過去のデータには disposalSite.order_id がない場合があるので、その場合は infeasibility の order_id を使う
                        const targetOrderId: PseudoId = disposalSite.order_id
                          ? disposalSite.order_id.toPseudoId()
                          : data.order_id.toPseudoId();
                        return {
                          ...disposalSiteMap.getOrError(disposalSite.site_id.toPseudoId().value),
                          orderId: targetOrderId,
                          order: orderMap.getOrError(targetOrderId.value),
                          disposablePeriodStart: disposalSite.disposable_period_start,
                          disposablePeriodEnd: disposalSite.disposable_period_end,
                          idealArrivalTime: disposalSite.ideal_arrival_time,
                          attendance: disposalSite.attendance
                            ? {
                                disposablePeriodStart: disposalSite.attendance.disposable_period_start,
                                disposablePeriodEnd: disposalSite.attendance.disposable_period_end,
                                idealArrivalTime: disposalSite.attendance.ideal_arrival_time,
                              }
                            : undefined,
                        } as IPotentialModificationDisposalSite;
                      }),
              };
            });

      infeasibilities.push(
        new Infeasibility(
          id,
          index,
          scheduleSolutionStatus ? scheduleSolutionStatus.infeasibilities.has(index) : false,
          PseudoId.buildByCombinedId(data.order_id),
          order.plan,
          order.activePlan.date,
          order.activePlan.collectablePeriodStart,
          order.activePlan.collectablePeriodEnd,
          order.activePlan.unloadDate,
          order.client,
          order.generationSite,
          order.preloadStatus,
          order.postponeOrderStatus,
          order.assignableCars,
          order.assignableCarTypes,
          data.cause,
          data.reasons,
          driverReasons,
          assignedDriver,
          acceptableCarTypes,
          potentialModifications,
          data.type,
          data.release_driver_assignment,
          data.duration_at_generation_site,
          data.duration_at_disposal_site,
          data.duration_of_driving,
          data.reducible_duration_by_highway
        )
      );
    }

    return infeasibilities;
  }
}
