export const erpClient$getAllTransportationClientsSymbol = Symbol('allTransportationClients');

export type erpClient = {
  [erpClient$getAllTransportationClientsSymbol]: IAllTransportationClientsApi;
};

export interface IErpClientData {
  id: string;
  name: string;
}

export interface IAllTransportationClientsApi {
  getAllTransportationClients(): Promise<IErpClientData[]>;
}
