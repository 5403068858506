import { IAttendanceData } from '~/framework/server-api/masters/attendance';

export class AttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  date: Date;

  constructor(data: IAttendanceData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.date = data.date;
  }

  update(data: IAttendanceData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AttendanceEntity');
    }
    this.date = data.date;
  }
}
