import { Maybe } from '~/framework/typeAliases';
import { ScheduleResponseJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export const schedule$createSymbol = Symbol('schedule$createSymbol');

export interface ICreateApi {
  [schedule$createSymbol]: void;
  create(data: IScheduleCreateData[]): Promise<string[]>;
}

export interface IScheduleCreateData {
  attendanceId: string;
  orderGroupId: string;
  scheduleResponse: Maybe<ScheduleResponseJsonObject>;
}
