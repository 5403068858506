import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';

/**
 * CarType を編集するためのもの
 */
export interface ICarTypeFormPanel extends IEntityFormPanel<AggregatedCarTypeEntity> {}

export class CarTypeFormPanel extends AbstractEntityFormPanel<AggregatedCarTypeEntity> implements ICarTypeFormPanel {}
