
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RCounter',
  props: {
    count: {
      type: Number as PropType<number>,
      required: true,
    },
  },
});
