import { Time } from '~/framework/domain/common/date-time/time';
import { Maybe } from '~/framework/typeAliases';
import { IllegalArgumentException } from '~/framework/core/exception';

export class DriverAttendanceService {
  /**
   * regularWorkPeriodStart の候補を与えて、制約を守った状態の Time を返す。
   *
   * @param regularWorkPeriodStart regularWorkPeriodStart の候補
   * @param restPeriodStart 現状設定されている restPeriodStart
   * @param restPeriodEnd 現状設定されている restPeriodEnd
   * @param regularWorkPeriodEnd 現状設定されている regularWorkPeriodEnd
   */
  correctRegularWorkPeriodStart(
    regularWorkPeriodStart: Maybe<Time>,
    restPeriodStart: Maybe<Time>,
    restPeriodEnd: Maybe<Time>,
    regularWorkPeriodEnd: Maybe<Time>
  ): Time {
    const items: RestrictedTimePoint[] = [];
    if (regularWorkPeriodStart) items.push(new RestrictedTimePoint(0, regularWorkPeriodStart));
    if (restPeriodStart) items.push(new RestrictedTimePoint(1, restPeriodStart));
    if (restPeriodEnd) items.push(new RestrictedTimePoint(2, restPeriodEnd));
    if (regularWorkPeriodEnd) items.push(new RestrictedTimePoint(3, regularWorkPeriodEnd));
    return this.correctRestrictedTimePoint(0, items);
  }

  /**
   * restPeriodStart の候補を与えて、制約を守った状態の Time を返す。
   *
   * @param regularWorkPeriodStart 現状設定されている regularWorkPeriodStart
   * @param restPeriodStart restPeriodStart の候補
   * @param restPeriodEnd 現状設定されている restPeriodEnd
   * @param regularWorkPeriodEnd 現状設定されている regularWorkPeriodEnd
   */
  correctRestPeriodStart(
    regularWorkPeriodStart: Maybe<Time>,
    restPeriodStart: Maybe<Time>,
    restPeriodEnd: Maybe<Time>,
    regularWorkPeriodEnd: Maybe<Time>
  ): Time {
    const items: RestrictedTimePoint[] = [];
    if (regularWorkPeriodStart) items.push(new RestrictedTimePoint(0, regularWorkPeriodStart));
    if (restPeriodStart) items.push(new RestrictedTimePoint(1, restPeriodStart));
    if (restPeriodEnd) items.push(new RestrictedTimePoint(2, restPeriodEnd));
    if (regularWorkPeriodEnd) items.push(new RestrictedTimePoint(3, regularWorkPeriodEnd));
    return this.correctRestrictedTimePoint(1, items);
  }

  /**
   * restPeriodEnd の候補を与えて、制約を守った状態の Time を返す。
   *
   * @param regularWorkPeriodStart 現状設定されている regularWorkPeriodStart
   * @param restPeriodStart 現状設定されている restPeriodStart
   * @param restPeriodEnd restPeriodEnd の候補
   * @param regularWorkPeriodEnd 現状設定されている regularWorkPeriodEnd
   */
  correctRestPeriodEnd(
    regularWorkPeriodStart: Maybe<Time>,
    restPeriodStart: Maybe<Time>,
    restPeriodEnd: Maybe<Time>,
    regularWorkPeriodEnd: Maybe<Time>
  ): Time {
    const items: RestrictedTimePoint[] = [];
    if (regularWorkPeriodStart) items.push(new RestrictedTimePoint(0, regularWorkPeriodStart));
    if (restPeriodStart) items.push(new RestrictedTimePoint(1, restPeriodStart));
    if (restPeriodEnd) items.push(new RestrictedTimePoint(2, restPeriodEnd));
    if (regularWorkPeriodEnd) items.push(new RestrictedTimePoint(3, regularWorkPeriodEnd));
    return this.correctRestrictedTimePoint(2, items);
  }

  /**
   * regularWorkPeriodEnd の候補を与えて、制約を守った状態の Time を返す。
   *
   * @param regularWorkPeriodStart 現状設定されている regularWorkPeriodStart
   * @param restPeriodStart 現状設定されている restPeriodStart
   * @param restPeriodEnd 現状設定されている restPeriodEnd
   * @param regularWorkPeriodEnd regularWorkPeriodEnd の候補
   */
  correctRegularWorkPeriodEnd(
    regularWorkPeriodStart: Maybe<Time>,
    restPeriodStart: Maybe<Time>,
    restPeriodEnd: Maybe<Time>,
    regularWorkPeriodEnd: Maybe<Time>
  ): Time {
    const items: RestrictedTimePoint[] = [];
    if (regularWorkPeriodStart) items.push(new RestrictedTimePoint(0, regularWorkPeriodStart));
    if (restPeriodStart) items.push(new RestrictedTimePoint(1, restPeriodStart));
    if (restPeriodEnd) items.push(new RestrictedTimePoint(2, restPeriodEnd));
    if (regularWorkPeriodEnd) items.push(new RestrictedTimePoint(3, regularWorkPeriodEnd));
    return this.correctRestrictedTimePoint(3, items);
  }

  private correctRestrictedTimePoint(targetIndex: number, items: RestrictedTimePoint[]): Time {
    // index が万が一カブっていると訳わからない事になりそうなのでチェックする
    const itemMap: Map<number, RestrictedTimePoint> = new Map<number, RestrictedTimePoint>();
    for (const item of items) {
      if (itemMap.has(item.index)) throw new IllegalArgumentException(`duplicated index! index: ${item.index}`);
      itemMap.set(item.index, item);
    }
    items.sort((a, b) => a.index - b.index);
    const targetItem = itemMap.getOrError(targetIndex);

    // 低い側の制約をチェック
    const lowerRestriction = [...items]
      .filter((item) => item.index < targetItem.index)
      .reverse()
      .find(() => true);
    if (lowerRestriction && targetItem.time.isBefore(lowerRestriction.time)) {
      return lowerRestriction.time;
    }

    // 高い側の制約をチェック
    const upperRestriction = items.find((item) => targetItem.index < item.index);
    if (upperRestriction && targetItem.time.isAfter(upperRestriction.time)) {
      return upperRestriction.time;
    }

    // 問題なければそのまま返す
    return targetItem.time;
  }
}

class RestrictedTimePoint {
  readonly index: number;
  readonly time: Time;

  constructor(index: number, time: Time) {
    this.index = index;
    this.time = time;
  }
}
