import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { PersistentId } from '~/framework/typeAliases';
import {
  driverAttendance$getByDateRangeSymbol,
  driverAttendance$getByDateRangeOfAllDriversSymbol,
  driverAttendance$deleteSymbol,
  driverAttendance$createDefaultSymbol,
  driverAttendance$createSymbol,
  driverAttendance$updateSymbol,
  IDriverAttendanceCreateData,
  IDriverAttendanceUpdateData,
} from '~/framework/server-api/masters/driverAttendance';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { AggregatedDriverAttendanceMapper } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceMapper';

export const driverAttendanceSymbol = Symbol('driverAttendance');

export class DriverAttendanceApplicationService {
  private readonly serverApis: ServerApiManager;
  private driverAttendanceStore;
  private driverAttendanceMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.driverAttendanceStore = store.masters.aggregatedDriverAttendance;
    this.driverAttendanceMapper = new AggregatedDriverAttendanceMapper(
      store.masters.aggregatedDriverAttendance,
      store.masters.attendance,
      store.masters.aggregatedDriver,
      store.masters.aggregatedCar,
      store.masters.driverAttendanceTemplate,
      store.masters.aggregatedBaseSite,
      store.masters.user
    );
  }

  async getByDates(start: Date, end: Date): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$getByDateRangeOfAllDrivers = this.serverApis.get(
      driverAttendance$getByDateRangeOfAllDriversSymbol
    );
    const driverAttendanceData = await driverAttendance$getByDateRangeOfAllDrivers.getByDateRangeOfAllDrivers(
      start,
      end
    );
    const driverAttendances = this.driverAttendanceMapper.map(driverAttendanceData);

    return driverAttendances;
  }

  async getListOfDriver(driverId: PersistentId, start: Date, end: Date): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$getByDateRangeApi = this.serverApis.get(driverAttendance$getByDateRangeSymbol);
    const driverAttendanceData = await driverAttendance$getByDateRangeApi.getByDateRange(driverId, start, end);
    return this.driverAttendanceMapper.map(driverAttendanceData);
  }

  async deleteDriverAttendances(ids: PersistentId[]): Promise<string[]> {
    const api = this.serverApis.get(driverAttendance$deleteSymbol);
    const result = await api.delete(ids);
    this.driverAttendanceStore.delete(result);
    return result;
  }

  async createDefaultDriverAttendances(
    driverId: PersistentId,
    start: Date,
    end: Date
  ): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$createDefaultApi = this.serverApis.get(driverAttendance$createDefaultSymbol);
    const driverAttendanceData = await driverAttendance$createDefaultApi.createDefault(driverId, start, end);
    return this.driverAttendanceMapper.map(driverAttendanceData);
  }

  async create(date: Date, data: IDriverAttendanceCreateData): Promise<AggregatedDriverAttendanceEntity> {
    // TODO: 効率が悪いので改善
    const driverAttendance$createApi = this.serverApis.get(driverAttendance$createSymbol);
    const [driverAttendanceId] = await driverAttendance$createApi.create([data]);
    const entities = await this.getByDates(date, date);
    const [entity] = entities.filter((entity) => entity.id === driverAttendanceId);
    return entity;
  }

  async update(date: Date, data: IDriverAttendanceUpdateData): Promise<AggregatedDriverAttendanceEntity> {
    // TODO: 効率が悪いので改善
    const driverAttendance$updateApi = this.serverApis.get(driverAttendance$updateSymbol);
    const [driverAttendanceId] = await driverAttendance$updateApi.update([data]);
    const entities = await this.getByDates(date, date);
    const [entity] = entities.filter((entity) => entity.id === driverAttendanceId);
    return entity;
  }
}
