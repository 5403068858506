
import Vue from 'vue';

/**
 * ここは middleware が何も設定されていないのでログインしていなくても対応ブラウザでなくても見れる
 */
export default Vue.extend({
  name: 'UnrestrictedLayout',
  middleware: [],
});
