/**
 * @argument 郵便番号の文字列 or undefined
 * @returns 整形された郵便番号の文字列
 * @description 全角→半角数字への変換。半角数字`-`以外のフィルタ。3桁目と4桁目の間に`-`の自動挿入を行う。
 */
export const transformFormattedZipCode = (input = '') => {
  let value = input;

  // HACK Windows で全角の input に反応して置き換えをすると半角と全角が両方入力されてしまって
  //    問題になるので insertText であって isComposing が false な場合に入力された全角文字は余計なもの
  //    として切り捨てている。Windows では意図した動きになり、Mac でも弊害がない事は確認している。
  //    本当は @input.native を拾いたいのだが、そうしてしまうと Vuetify の方の @input で
  //    反応しなくなり逆に不便という事があり、@input 側で window.event を参照する形にしている。
  //    hacky だしあまりよくないのは分かっているのだが、これ以外に解決方法がなかったので
  //    致し方なくやっている。このようなロジックを各コンポーネントで持っているのはすごく微妙。
  if (window.event && window.event.type === 'input') {
    const event = window.event as InputEvent;
    if (event.inputType === 'insertText' && event.isComposing === false) {
      value = value.replace(/[^0-9-]/g, '');
    }
  }

  value = value
    // 全角を半角に変換
    .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
    // 半角数字以外を除外
    .replace(/[^0-9-]/g, '');
  if (value.length === 4 && value.match(/^[0-9]{4}$/)) {
    // ハイフンを自動入力
    value = value.slice(0, 3) + '-' + value.slice(3, 4);
  } else if (9 <= value.length) {
    // ハイフン含めて9文字以上の郵便番号はあり得ないので削る
    value = value.slice(0, 8);
  }

  return value;
};
