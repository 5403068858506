/**
 * グローバルなパネルの状態を管理するためのもの
 */
import { IPanel } from '~/framework/view-models/panels/panel';
import { Maybe } from '~/framework/typeAliases';

export interface IPanelState {
  /**
   * パネルが開いているかどうかを取得する
   */
  readonly isPanelOpen: boolean;

  /**
   * 今開いているパネル
   */
  readonly currentPanel: Maybe<IPanel>;

  /**
   * パネルを登録する
   * 後々閉じる必要があるかもしれないので IPanel のインスタンスを渡す
   */
  registerCurrentPanel(newPanel: IPanel): boolean;

  /**
   * パネルを手動で閉じたので現在のパネルの登録を解除する
   * 同一性を念のため検証したいので閉じられたパネルのインスタンスを渡す
   */
  unregisterCurrentPanel(panel: IPanel): void;

  /**
   * パネルを閉じる事を明示する
   * 閉じる事に失敗する可能性もある
   * そもそもパネルが開いていなかったら undefined が返る
   *
   * @param forceClose 確認ダイアログを表示する事なく強制的に閉じて欲しいかどうか
   */
  closeCurrentPanel(forceClose: boolean): Promise<Maybe<boolean>>;
}

export class PanelState implements IPanelState {
  isPanelOpen: boolean;
  currentPanel: Maybe<IPanel>;

  constructor() {
    this.isPanelOpen = false;
    this.currentPanel = undefined;
  }

  registerCurrentPanel(newPanel: IPanel): boolean {
    if (this.isPanelOpen) return false;
    if (this.currentPanel !== undefined) return false;
    this.isPanelOpen = true;
    this.currentPanel = newPanel;
    return true;
  }

  unregisterCurrentPanel(panel: IPanel) {
    if (this.isPanelOpen === false) throw new Error(`Panel has not been opened!`);
    if (this.currentPanel !== panel) throw new Error(`The panel has not been registered as the current panel!`);
    this.isPanelOpen = false;
    this.currentPanel = undefined;
  }

  async closeCurrentPanel(forceClose: boolean): Promise<Maybe<boolean>> {
    // 扱いが微妙だがそもそもパネルが開いていない場合は成功とも失敗とも言い難いので
    // undefined を返す事にする
    if (this.isPanelOpen === false || this.currentPanel === undefined) return undefined;
    const result = await this.currentPanel.close(forceClose);
    if (result === false) return false;
    this.isPanelOpen = false;
    this.currentPanel = undefined;
    return true;
  }
}
