import { Maybe } from '~/framework/typeAliases';
import { WasteCategory, WasteTypeStatus } from '~/framework/domain/typeAliases';
import { JwnetWasteMasterEntity } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { IWasteTypeData } from '~/framework/server-api/masters/wasteType';

export class AggregatedWasteTypeEntity {
  readonly id: string;
  readonly persistentId: string;
  category: WasteCategory;
  code: string;
  name: string;
  status: WasteTypeStatus;
  isProminent: boolean;
  createdAt: Date;
  updatedAt: Date;
  jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>;
  createdBy: UserEntity;
  updatedBy: UserEntity;

  constructor(
    data: IWasteTypeData,
    jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>,
    createdBy: UserEntity,
    updatedBy: UserEntity
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.category = data.category;
    this.code = data.code;
    this.name = data.name;
    this.status = data.status;
    this.isProminent = data.isProminent;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.jwnetWasteMaster = jwnetWasteMaster;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }

  update(
    data: IWasteTypeData,
    jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>,
    createdBy: UserEntity,
    updatedBy: UserEntity
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedWasteTypeEntity');
    }
    this.category = data.category;
    this.code = data.code;
    this.name = data.name;
    this.status = data.status;
    this.isProminent = data.isProminent;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.jwnetWasteMaster = jwnetWasteMaster;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }
}
