
import Vue, { PropType } from 'vue';
import { CssStyles } from '~/framework/typeAliases';
import {
  RinEventFormTargetTypes,
  RinEventNames,
  AdditionalInfoKeys,
} from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  /**
   * 実際に表示されている横幅（offsetWidth）
   */
  displayedWidth: number;
};

enum EventTypes {
  Change = 'change',
  UpdateDisplayedWidth = 'update:displayed-width',
}

export default Vue.extend({
  name: 'RSideform',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  props: {
    closeButton: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    closeHandler: {
      type: Function as PropType<() => void>,
      required: false,
      default: undefined,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: '440px',
    },
    formTarget: {
      type: String as PropType<RinEventFormTargetTypes>,
      required: true,
    },
  },
  data(): DataType {
    return {
      isActive: false,
      displayedWidth: 0,
    };
  },
  computed: {
    cardStyles(): CssStyles {
      return {
        width: this.width,
      };
    },
  },
  watch: {
    async isActive(value: boolean): Promise<void> {
      if (value) {
        await Vue.nextTick();
        this.onScroll();
        this.setUpObserver();
      }
    },
    displayedWidth(value: number, oldValue: number): void {
      if (value !== oldValue) this.$emit(EventTypes.UpdateDisplayedWidth, value);
    },
  },
  methods: {
    /**
     * @public
     */
    async open(): Promise<void> {
      this.isActive = true;
      await Vue.nextTick();
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @public
     */
    async close(): Promise<void> {
      this.isActive = false;
      await Vue.nextTick();
      this.$emit(EventTypes.Change, this.isActive);
    },
    onCloseButtonClicked(): void {
      if (this.closeHandler) this.closeHandler();
      this.$rinGtm.push(RinEventNames.CLOSE_FORM, { [AdditionalInfoKeys.TARGET]: this.formTarget });
    },
    onScroll(): void {
      // HACK
      // 上下にスクロールする可能性のある要素の上下に padding を 1px ずつつけて、そこには絶対にスクロールしない
      // 様に調整している。これは iOS で position: fixed なパネルの様なものをスクロール可能な要素の上に重ねると
      // position: fixed なもののスクロールが末端に達した後でさらにスクロールしようとすると後ろの要素をスクロール
      // しようとして気持ち悪い挙動になるため、常に末端に達しない様にしている。
      const scrollDiv = this.$refs.scrollDiv as HTMLElement;
      if (!scrollDiv) return;
      const scrollable = scrollDiv.scrollHeight - scrollDiv.clientHeight;
      if (scrollDiv.scrollTop === 0) scrollDiv.scrollTop = 1;
      else if (scrollDiv.scrollTop === scrollable) scrollDiv.scrollTop = scrollDiv.scrollTop - 1;
    },
    setUpObserver(): void {
      // やや微妙なのだが rootDiv に対して mutation が行われた事を width が更新されたものとして扱っている
      const element = this.$refs.rootDiv as any as HTMLElement;
      const observer = new MutationObserver((_mutation) => {
        if (element) this.displayedWidth = element.offsetWidth;
      });
      const config = { attributes: true, childList: false, characterData: false };
      observer.observe(element, config);
    },
  },
});
