
import Vue, { PropType } from 'vue';
import { VuetifyColors } from '~/framework/constants';
import { Maybe } from '~/framework/typeAliases';
import RConfirmDialog from '~/components/common/r-confirm-dialog/RConfirmDialog.vue';
import { IRPopupMenuItem } from '~/components/common/r-popup-menu/rPopupMenu';

type DataType = {
  isConfirmDialogActive: boolean;
  confirmDialogResolver: Maybe<(value: boolean) => void>;
};

enum EventTypes {
  CreateGenerationSite = 'select:create-generation-site',
  EditGenerationSite = 'select:edit-generation-site',
  SearchOrders = 'select:search-orders',
}

type PopupMenuElementsType = {
  create: IRPopupMenuItem;
  edit: IRPopupMenuItem;
  searchOrders: IRPopupMenuItem;
};

const popupMenuElements: PopupMenuElementsType = {
  create: {
    key: 'create',
    title: '新規登録',
    icon: 'mdi-plus-circle-outline',
    color: VuetifyColors.Success,
  },
  edit: {
    key: 'edit',
    title: '編集',
    icon: 'mdi-pencil',
    color: VuetifyColors.Primary,
  },
  searchOrders: {
    key: 'searchOrders',
    title: '受注履歴',
    icon: 'ffi-history',
    color: VuetifyColors.Primary,
  },
};

export default Vue.extend({
  name: 'RGenerationSiteOptions',
  components: {
    RConfirmDialog,
  },
  props: {
    selected: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      isConfirmDialogActive: false,
      confirmDialogResolver: undefined,
    };
  },
  computed: {
    generationSiteDetailItems(): IRPopupMenuItem[] {
      return this.selected
        ? [popupMenuElements.edit, popupMenuElements.searchOrders, popupMenuElements.create]
        : [popupMenuElements.create];
    },
  },
  methods: {
    onClickGenerationSiteDetailButton(item: IRPopupMenuItem): void {
      switch (item.key) {
        case popupMenuElements.create.key:
          this.$emit(EventTypes.CreateGenerationSite);
          break;
        case popupMenuElements.edit.key:
          this.$emit(EventTypes.EditGenerationSite);
          break;
        case popupMenuElements.searchOrders.key:
          this.isConfirmDialogActive = true;
          break;
      }
    },
    onConfirmDialog(yes: boolean) {
      this.isConfirmDialogActive = false;
      if (yes) this.$emit(EventTypes.SearchOrders);
    },
  },
});
