import { StoredMapperBase } from '~/framework/core/mapper';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { OfficeSettingStore } from '~/framework/domain/masters/office-setting/officeSettingStore';
import { IOfficeSettingData } from '~/framework/server-api/masters/officeSetting';

export class OfficeSettingMapper extends StoredMapperBase<IOfficeSettingData, OfficeSettingEntity> {
  protected store: OfficeSettingStore;

  constructor(store: OfficeSettingStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IOfficeSettingData): OfficeSettingEntity {
    return new OfficeSettingEntity(data);
  }

  protected updateWithData(data: IOfficeSettingData, entity: OfficeSettingEntity): void {
    entity.update(data);
  }
}
