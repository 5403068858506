import { Maybe } from '~/framework/typeAliases';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';

import { IContainerTypeData } from '~/framework/server-api/masters/containerType';
import { SimpleCarTypeEntity } from '../car-type/aggregatedCarTypeEntity';

export class AggregatedContainerTypeEntity {
  readonly id: string;
  persistentId: string;
  name: string;
  unitName: string;
  status: SoftDeleteStatus;
  reservationSiteEnabled: boolean;
  transformingCarType: Maybe<SimpleCarTypeEntity>;
  packingStyle: PackingStyleEntity;

  constructor(
    data: IContainerTypeData,
    transformingCarType: Maybe<SimpleCarTypeEntity>,
    packingStyle: PackingStyleEntity
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.unitName = data.unitName;
    this.status = data.status;
    this.reservationSiteEnabled = data.reservationSiteEnabled;
    this.transformingCarType = transformingCarType;
    this.packingStyle = packingStyle;
  }

  update(
    data: IContainerTypeData,
    transformingCarType: Maybe<SimpleCarTypeEntity>,
    packingStyle: PackingStyleEntity
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedContainerTypeEntity');
    }
    this.name = data.name;
    this.unitName = data.unitName;
    this.status = data.status;
    this.reservationSiteEnabled = data.reservationSiteEnabled;
    this.transformingCarType = transformingCarType;
    this.packingStyle = packingStyle;
  }
}
