import { StoredMapperBase } from '~/framework/core/mapper';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { ContainerTypeTaskTypeStore } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeStore';
import { IContainerTypeTaskTypeData } from '~/framework/server-api/masters/containerTypeTaskType';
import { AggregatedContainerTypeMapper } from '../aggregatedContainerTypeMapper';
import { TaskTypeMapper } from '../../task-type/taskTypeMapper';
import { AggregatedContainerTypeStore } from '../aggregatedContainerTypeStore';
import { PackingStyleStore } from '../../packing-style/packingStyleStore';
import { AggregatedDriverStore } from '../../driver/aggregatedDriverStore';
import { AggregatedCarStore } from '../../car/aggregatedCarStore';
import { AggregatedBaseSiteStore } from '../../base-site/aggregatedBaseSiteStore';
import { DriverAttendanceTemplateStore } from '../../driver-attendance-template/driverAttendanceTemplateStore';
import { UserStore } from '../../user/userStore';

export class ContainerTypeTaskTypeMapper extends StoredMapperBase<
  IContainerTypeTaskTypeData,
  ContainerTypeTaskTypeEntity
> {
  protected store: ContainerTypeTaskTypeStore;
  private readonly containerTypeMapper: AggregatedContainerTypeMapper;
  private readonly taskTypeMappter: TaskTypeMapper;

  constructor(
    store: ContainerTypeTaskTypeStore,
    aggregatedContainerTypeStore: AggregatedContainerTypeStore,
    packingStyleStore: PackingStyleStore,
    driverStore: AggregatedDriverStore,
    carStore: AggregatedCarStore,
    baseSiteStore: AggregatedBaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.containerTypeMapper = new AggregatedContainerTypeMapper(aggregatedContainerTypeStore, packingStyleStore);
    this.taskTypeMappter = new TaskTypeMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
  }

  protected instantiateWithData(data: IContainerTypeTaskTypeData): ContainerTypeTaskTypeEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    const taskType = this.taskTypeMappter.mapSingle(data.taskType);
    return new ContainerTypeTaskTypeEntity(data, containerType, taskType);
  }

  protected updateWithData(data: IContainerTypeTaskTypeData, entity: ContainerTypeTaskTypeEntity): void {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    const taskType = this.taskTypeMappter.mapSingle(data.taskType);
    entity.update(data, containerType, taskType);
  }
}
