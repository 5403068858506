import {
  RawInconsistencyJsonObject,
  RawScheduleInfeasibilityJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { IOrderType } from '~/framework/application/schedule/order/order-acceptance-check/orderType';
import { Maybe, PersistentId } from '~/framework/typeAliases';

export enum ErrorTypes {
  /**
   * そもそも勤怠が全く登録されていないエラー
   */
  NoAttendances,
  /**
   * 同時チェックできないエラー
   */
  ViolatesMultipleCheckCondition,
  /**
   * Master, MasterOrOrder の Infeasibility が出ているエラー
   */
  InfeasibilityOfPrecheck,
  /**
   * Inconsistency が出ているエラー
   */
  Inconsistency,
  /**
   * 以下のどれかの Infeasibility が出ているエラー
   *
   * - AssignedDriverAttendance
   * - AssignedDriverCondition
   * - PrimaryCarAttendance
   * - PrimaryCarCondition
   * - DisposalSiteRestPeriod
   */
  InfeasibilityOfAttendance,
  /**
   * 最適化時に Infeasibility が出ているエラー
   * Optimization の Cause になっているものが該当する
   */
  InfeasibilityOfOptimization,
}

export interface IProcessableError {
  accept(processor: IErrorProcessor): Promise<void>;
}

export interface IErrorProcessor {
  processNoAttendanceError(error: INoAttendancesError): Promise<void>;
  processViolatesMultipleCheckConditionError(error: IViolatesMultipleCheckConditionError): Promise<void>;
  processInfeasibilityOfPrecheckError(error: IInfeasibilityOfPrecheckError): Promise<void>;
  processInconsistencyError(error: IInconsistencyError): Promise<void>;
  processInfeasibilityOfAttendanceError(error: IInfeasibilityOfAttendanceError): Promise<void>;
  processInfeasibilityOfOptimizationError(error: IInfeasibilityOfOptimizationError): Promise<void>;
}

export interface IOrderAcceptanceCheckError extends IProcessableError {
  readonly type: ErrorTypes;
}

export interface INoAttendancesError extends IOrderAcceptanceCheckError {
  // 拡張情報なし
}

export class NoAttendancesError implements INoAttendancesError {
  type: ErrorTypes = ErrorTypes.NoAttendances;

  async accept(processor: IErrorProcessor): Promise<void> {
    await processor.processNoAttendanceError(this);
  }
}

export interface IViolatesMultipleCheckConditionError extends IOrderAcceptanceCheckError {
  /**
   * チェック中の ID が分かっているのであればそれ
   */
  checking: Maybe<PersistentId>;
}

export class ViolatesMultipleCheckConditionError implements IViolatesMultipleCheckConditionError {
  type: ErrorTypes = ErrorTypes.ViolatesMultipleCheckCondition;
  checking: Maybe<PersistentId>;

  constructor(checking: Maybe<PersistentId>) {
    this.checking = checking;
  }

  async accept(processor: IErrorProcessor): Promise<void> {
    await processor.processViolatesMultipleCheckConditionError(this);
  }
}

export interface IInfeasibilityOfPrecheckError extends IOrderAcceptanceCheckError {
  /**
   * 対象、非対象の各 infeasibilities
   */
  readonly infeasibilities: IOrderType<RawScheduleInfeasibilityJsonObject[]>;

  /**
   * 対象、非対象のどの受注に対するエラーがあるか
   */
  readonly hasError: IOrderType<boolean>;
}

export class InfeasibilityOfPrecheckError implements IInfeasibilityOfPrecheckError {
  readonly type: ErrorTypes = ErrorTypes.InfeasibilityOfPrecheck;
  readonly hasError: IOrderType<boolean>;
  readonly infeasibilities: IOrderType<RawScheduleInfeasibilityJsonObject[]>;

  constructor(orderId: PersistentId, infeasibilities: RawScheduleInfeasibilityJsonObject[]) {
    this.infeasibilities = {
      object: infeasibilities.filter((infeasibility) => infeasibility.order_id.toPseudoId().value === orderId),
      others: infeasibilities.filter((infeasibility) => infeasibility.order_id.toPseudoId().value !== orderId),
    };
    this.hasError = {
      object: 0 < this.infeasibilities.object.length,
      others: 0 < this.infeasibilities.others.length,
    };
  }

  async accept(processor: IErrorProcessor): Promise<void> {
    await processor.processInfeasibilityOfPrecheckError(this);
  }
}

export interface IInconsistencyError extends IOrderAcceptanceCheckError {
  readonly inconsistencies: RawInconsistencyJsonObject[];
}

export class InconsistencyError implements IInconsistencyError {
  readonly type: ErrorTypes = ErrorTypes.Inconsistency;
  readonly inconsistencies: RawInconsistencyJsonObject[];

  constructor(inconsistencies: RawInconsistencyJsonObject[]) {
    this.inconsistencies = inconsistencies;
  }

  async accept(processor: IErrorProcessor): Promise<void> {
    await processor.processInconsistencyError(this);
  }
}

export interface IInfeasibilityOfAttendanceError extends IOrderAcceptanceCheckError {
  /**
   * 対象、非対象の各 infeasibilities
   */
  readonly infeasibilities: IOrderType<RawScheduleInfeasibilityJsonObject[]>;

  /**
   * 対象、非対象のどの受注に対するエラーがあるか
   */
  readonly hasError: IOrderType<boolean>;
}

export class InfeasibilityOfAttendanceError implements IInfeasibilityOfAttendanceError {
  readonly type: ErrorTypes = ErrorTypes.InfeasibilityOfAttendance;
  readonly hasError: IOrderType<boolean>;
  readonly infeasibilities: IOrderType<RawScheduleInfeasibilityJsonObject[]>;

  constructor(orderId: PersistentId, infeasibilities: RawScheduleInfeasibilityJsonObject[]) {
    this.infeasibilities = {
      object: infeasibilities.filter((infeasibility) => infeasibility.order_id.toPseudoId().value === orderId),
      others: infeasibilities.filter((infeasibility) => infeasibility.order_id.toPseudoId().value !== orderId),
    };
    this.hasError = {
      object: 0 < this.infeasibilities.object.length,
      others: 0 < this.infeasibilities.others.length,
    };
  }

  async accept(processor: IErrorProcessor): Promise<void> {
    await processor.processInfeasibilityOfAttendanceError(this);
  }
}

export interface IInfeasibilityOfOptimizationError extends IOrderAcceptanceCheckError {
  /**
   * 対象、非対象のどの受注に対するエラーがあるか
   */
  readonly hasError: IOrderType<boolean>;
}

export class InfeasibilityOfOptimizationError implements IInfeasibilityOfOptimizationError {
  readonly type: ErrorTypes = ErrorTypes.InfeasibilityOfOptimization;
  readonly hasError: IOrderType<boolean>;

  constructor(orderId: PersistentId, infeasibilities: RawScheduleInfeasibilityJsonObject[]) {
    this.hasError = {
      object: infeasibilities.some((infeasibility) => infeasibility.order_id.toPseudoId().value === orderId),
      others: infeasibilities.some((infeasibility) => infeasibility.order_id.toPseudoId().value !== orderId),
    };
  }

  async accept(processor: IErrorProcessor): Promise<void> {
    await processor.processInfeasibilityOfOptimizationError(this);
  }
}
