
import Vue, { PropType } from 'vue';
import RDescribeCardItem from './RDescribeCardItem.vue';

type Link = {
  url: string;
  text: string;
};

type Item = {
  subtitle: string | undefined;
  text: string | undefined;
  link: Link | undefined;
};

export default Vue.extend({
  name: 'RDescribeCard',
  components: { RDescribeCardItem },
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    items: {
      type: Array as PropType<Item[]>,
      required: false,
      default: () => [
        {
          subtitle: undefined,
          text: undefined,
          link: undefined,
        },
      ],
    },
  },
});
