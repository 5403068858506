import { NuxtOptionsEnv } from '@nuxt/types/config/env';

/**
 * 本番環境かどうか
 * @return {boolean}
 */
export const isProduction = (): boolean => {
  return getRuntimeEnvironment() === 'production';
};

/**
 * 開発環境かどうか
 */
export const isDevelopment = (): boolean => {
  return getRuntimeEnvironment() === 'development';
};

/**
 * ステージング環境かどうか
 * @returns {boolean}
 */
export const isStaging = (): boolean => {
  return getRuntimeEnvironment() === 'staging';
};

/**
 * テスト環境かどうか
 * @returns {boolean}
 */
export const isTest = (): boolean => {
  return getRuntimeEnvironment() === 'test';
};

/**
 * 実行環境を取得する
 * 基本的には NODE_ENV を尊重するが、RIN_ENV が設定されていた場合はそれで上書きする
 * @returns {string}
 */
export const getRuntimeEnvironment = (): string => {
  const env = process.env.RIN_ENV ? process.env.RIN_ENV : process.env.NODE_ENV! ?? '';
  return env.toLowerCase();
};

/**
 * ローカルの MIO に接続するモードかどうかを取得する
 */
export const isLocalMio = (): boolean => {
  return !!process.env.RIN_LOCAL_MIO;
};

/**
 * nuxt.config.ts で使える形の環境変数を取得する
 */
export const getEnvironmentVariables = (): NuxtOptionsEnv => {
  // ここで ! で無理矢理返すのは微妙なのだが、空文字を詰めてしまうと ?? で判断できなくなって
  // 逆に扱いにくいので ! でいいという事にしておく
  return {
    googleMapApiKey: process.env.GOOGLE_MAP_API_KEY!,
    nodeEnv: process.env.NODE_ENV!,
    rinEnv: process.env.RIN_ENV!,
    rinLocalMio: process.env.RIN_LOCAL_MIO!,
    enableSentry: process.env.ENABLE_RIN_SENTRY!,
    mioUrl: process.env.MIO_URL!,
  };
};
