
import Vue, { PropType } from 'vue';
import { PackingPlacementsAtGenerationSite } from '~/framework/view-models/panels/packingPlacementsFormPanel';

export default Vue.extend({
  name: 'RDescriptionOfGenerationSite',
  props: {
    packingPlacements: {
      type: Object as PropType<PackingPlacementsAtGenerationSite>,
      required: true,
    },
  },
});
