
import Vue, { PropType } from 'vue';
import { hankanize } from '~/framework/services/string/string';
import RLabelsShrinkable from '~/components/common/r-truncate-elements/RLabelsShrinkable';

type DataType = {};

export default Vue.extend({
  name: 'RGenerationSiteTask',
  mixins: [RLabelsShrinkable],
  props: {
    containerTypeName: {
      type: String as PropType<string>,
      required: false,
      default: '-',
    },
    taskTypeName: {
      type: String as PropType<string>,
      required: false,
      default: '-',
    },
    wasteTypeName: {
      type: String as PropType<string>,
      required: false,
      default: '-',
    },
    containerNum: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    unitName: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {};
  },
  methods: {
    hankanize,
  },
});
