
import Vue, { PropType } from 'vue';
import { Maybe } from '~/framework/typeAliases';
import RPlanCandidateDatesPeriodSetting from '~/components/panels/schedule/r-order-form/r-plan-candidate-dates-dialog/RPlanCandidateDatesPeriodSetting.vue';
import { IBusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import { OrderForm } from '~/components/panels/schedule/r-order-form/ROrderForm';
import type { Masters } from '~/components/panels/schedule/r-order-form/ROrderForm';

type TabItem = {
  label: string;
  hash: string;
};

type DataType = {
  value: boolean;
  listenerDisposer: Maybe<() => void>;
  tab: number;
  tabs: TabItem[];
};

export default Vue.extend({
  name: 'RPlanCandidateDatesDialog',
  components: {
    RPlanCandidateDatesPeriodSetting,
  },
  props: {
    viewModel: {
      type: Object as PropType<OrderForm>,
      required: false,
      default: undefined,
    },
    masters: {
      type: Object as PropType<Masters>,
      required: false,
      default: undefined,
    },
    businessDaysService: {
      type: Object as PropType<IBusinessDaysService>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      value: false,
      listenerDisposer: undefined,
      tab: 0,
      tabs: [{ label: '期間指定', hash: 'tab1' }],
    };
  },
  computed: {
    isDeleteButtonDisplayed(): boolean {
      return false;
    },
    isCompleteButtonDisabled(): boolean {
      return false;
    },
  },
  methods: {
    onClickPeriodConfirmButton(schedule: any): void {
      // period側編集完了

      this.$emit('confirm', schedule);
    },
    onClickDateConfirmButton(): void {
      // date側編集完了
    },
    onClickCloseButton(): void {
      this.close();
    },
    open(): void {
      this.value = true;
      this.$nextTick(() => {
        (this.$refs.planCandidateDatesPeriodSetting as any).init();
      });
    },
    close(): void {
      this.value = false;
    },
  },
});
