import { ScheduleByScheduleRequestInput, ScheduleByScheduleRequestResult } from '~/graphql/graphQLServerApi';

export const schedule$getByScheduleRequestSymbol = Symbol('schedule$getByScheduleRequestSymbol');

export interface IGetByScheduleRequestApi {
  [schedule$getByScheduleRequestSymbol]: void;
  getByScheduleRequest(
    scheduleByScheduleRequestInput: ScheduleByScheduleRequestInput
  ): Promise<ScheduleByScheduleRequestResult>;
}
