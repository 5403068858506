
import Vue, { PropType } from 'vue';
import RVRadio from '~/components/common/r-radio-group/RVRadio.vue';
import { IRRadioGroupItem } from '~/components/common/r-radio-group/rRadioGroup';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import {
  IClientContainerTypeReservationSetting,
  IPackingStyleReservationSetting,
} from '~/framework/server-api/masters/client';
import { hankanize } from '~/framework/services/string/string';

type DataType = {
  localPackingStyleVisible: boolean;
  localContainerTypeReservationSettings: IClientContainerTypeReservationSetting[];
};

enum EventTypes {
  ChangePackingStyleVisible = 'change-packing-style-visible',
  ChangePackingStyleReservationSettings = 'change-container-type-reservation-settings',
  Change = 'change',
}

export default Vue.extend({
  name: 'RPackingStyleVisibleSettingsItem',
  components: {
    RVRadio,
  },
  props: {
    packingStyleReservationSetting: {
      type: Object as PropType<IPackingStyleReservationSetting>,
      required: true,
    },
  },
  data(): DataType {
    return {
      localPackingStyleVisible: this.packingStyleReservationSetting.visible,
      localContainerTypeReservationSettings: this.packingStyleReservationSetting.containerTypeReservationSettings,
    };
  },
  computed: {
    packingStyleVisibleOptions(): IRRadioGroupItem<boolean>[] {
      return [
        {
          label: '表示',
          value: true,
        },
        {
          label: '非表示',
          value: false,
        },
      ];
    },
    // NOTE: containerTypeReservationSetting.visible の型は Maybe<undefined> だが、 v-radio に undefined を差し込むと index が勝手に value に設定されるため、 number としている
    containerTypeVisibleOptions(): IRRadioGroupItem<number>[] {
      const visibleText = this.localPackingStyleVisible ? '表示' : '非表示';
      return [
        {
          label: '表示',
          value: 1,
        },
        {
          label: '非表示',
          value: 2,
        },
        {
          label: `ﾃﾞﾌｫﾙﾄ (${visibleText})`,
          value: 3,
        },
      ];
    },
  },
  methods: {
    hankanize,
    // NOTE: option value -> Maybe<boolean>
    convertContainerTypeVisibleToOptionValue(visible: Maybe<boolean>): number {
      if (visible === true) {
        return 1;
      }
      if (visible === false) {
        return 2;
      }
      return 3;
    },
    // NOTE: Maybe<boolean> to option value
    convertOptionValueToContainerTypeVisible(value: number): Maybe<boolean> {
      if (value === 1) {
        return true;
      }
      if (value === 2) {
        return false;
      }
      return undefined;
    },
    onPackingStyleVisibleChange(value: boolean) {
      const packingStyleReservationSetting = {
        ...this.packingStyleReservationSetting,
        visible: value,
      };

      this.localPackingStyleVisible = value;
      this.$emit(EventTypes.Change, packingStyleReservationSetting);
    },
    onContainerTypeRadioChange(value: number, containerTypeId: PersistentId) {
      // NOTE: containerTypeId が該当する visible を更新する
      const containerTypeReservationSettings = [
        ...this.localContainerTypeReservationSettings.map((setting) => {
          if (setting.containerType.id !== containerTypeId) return setting;
          return {
            ...setting,
            visible: this.convertOptionValueToContainerTypeVisible(value),
          };
        }),
      ];

      const packingStyleReservationSetting = {
        ...this.packingStyleReservationSetting,
        containerTypeReservationSettings,
      };

      this.localContainerTypeReservationSettings = containerTypeReservationSettings;
      this.$emit(EventTypes.Change, packingStyleReservationSetting);
    },
  },
});
