export interface IFixedDisplayOnReservationOption {
  value: boolean;
  label: string;
}

/**
 * ウェブ依頼で利用するかどうかの選択肢
 */
export const fixedDisplayOnReservationOptions: IFixedDisplayOnReservationOption[] = [
  { value: true, label: 'する' },
  { value: false, label: 'しない' },
];
