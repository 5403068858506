import { Hours, Minutes, Seconds } from '~/framework/typeAliases';

import { IrregularTaskEntity } from '~/framework/domain/schedule/order/irregular-task/irregularTaskEntity';

export interface IIrregularTaskItem {
  name: string;
  skipDisposalSite: boolean;
  durationAtGenerationSite: Seconds;
  hoursAtGenerationSite: Hours;
  minutesAtGenerationSite: Minutes;
  durationAtDisposalSite: Seconds;
  hoursAtDisposalSite: Hours;
  minutesAtDisposalSite: Minutes;

  clone(): IIrregularTaskItem;
}

export class IrregularTaskItem implements IIrregularTaskItem {
  name: string;
  durationAtGenerationSite: Seconds;
  _hoursAtGenerationSite!: Hours;
  _minutesAtGenerationSite!: Minutes;
  skipDisposalSite: boolean;
  durationAtDisposalSite: Seconds;
  _hoursAtDisposalSite!: Hours;
  _minutesAtDisposalSite!: Minutes;

  set hoursAtGenerationSite(value: Hours) {
    this._hoursAtGenerationSite = value;
    this.updateDurationAtGenerationSite();
  }

  get hoursAtGenerationSite(): Hours {
    return this._hoursAtGenerationSite;
  }

  set minutesAtGenerationSite(value: Minutes) {
    this._minutesAtGenerationSite = value;
    this.updateDurationAtGenerationSite();
  }

  get minutesAtGenerationSite(): Minutes {
    return this._minutesAtGenerationSite;
  }

  set hoursAtDisposalSite(value: Hours) {
    this._hoursAtDisposalSite = value;
    this.updateDurationAtDisposalSite();
  }

  get hoursAtDisposalSite(): Hours {
    return this._hoursAtDisposalSite;
  }

  set minutesAtDisposalSite(value: Minutes) {
    this._minutesAtDisposalSite = value;
    this.updateDurationAtDisposalSite();
  }

  get minutesAtDisposalSite(): Minutes {
    return this._minutesAtDisposalSite;
  }

  constructor(
    name: string,
    durationAtGenerationSite: Seconds,
    skipDisposalSite: boolean,
    durationAtDisposalSite: Seconds
  ) {
    this.name = name;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.skipDisposalSite = skipDisposalSite;
    this.durationAtDisposalSite = durationAtDisposalSite;
    this.updateHoursAndMinutesAtGenerationSite();
    this.updateHoursAndMinutesAtDisposalSite();
  }

  clone(): IIrregularTaskItem {
    return new IrregularTaskItem(
      this.name,
      this.durationAtGenerationSite,
      this.skipDisposalSite,
      this.durationAtDisposalSite
    );
  }

  private updateHoursAndMinutesAtGenerationSite(): void {
    this._hoursAtGenerationSite = Math.floor(this.durationAtGenerationSite / (60 * 60));
    this._minutesAtGenerationSite = (this.durationAtGenerationSite % (60 * 60)) / 60;
  }

  private updateDurationAtGenerationSite(): void {
    this.durationAtGenerationSite = this.hoursAtGenerationSite * (60 * 60) + this.minutesAtGenerationSite * 60;
  }

  private updateHoursAndMinutesAtDisposalSite(): void {
    this._hoursAtDisposalSite = Math.floor(this.durationAtDisposalSite / (60 * 60));
    this._minutesAtDisposalSite = (this.durationAtDisposalSite % (60 * 60)) / 60;
  }

  private updateDurationAtDisposalSite(): void {
    this.durationAtDisposalSite = this.hoursAtDisposalSite * (60 * 60) + this.minutesAtDisposalSite * 60;
  }
}

export class IrregularTaskItemFactory {
  static instantiateByEntity(entity: IrregularTaskEntity): IrregularTaskItem {
    return new IrregularTaskItem(
      entity.name,
      entity.durationAtGenerationSite,
      entity.skipDisposalSite,
      entity.durationAtDisposalSite
    );
  }

  static instantiateDefault(): IrregularTaskItem {
    return new IrregularTaskItem('', 0, false, 0);
  }
}
