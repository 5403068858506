import { CandidateDate } from '~/framework/domain/reservation/reservation/candidateDate';
import { Vacancy } from '~/framework/domain/typeAliases';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';

export interface ICandidateDateCollectablePeriodItem extends Omit<DateCollectablePeriodItem, 'date'> {
  vacancy: Vacancy;
  // 日付が必ず存在するので DateCollectablePeriodItem の date を必須にする
  date: Date;
}

export class CandidateDateCollectablePeriodItem
  extends DateCollectablePeriodItem
  implements ICandidateDateCollectablePeriodItem
{
  vacancy: Vacancy;
  date: Date;

  constructor(candidateDate: CandidateDate) {
    super(
      candidateDate.date,
      candidateDate.collectablePeriodTemplateName,
      candidateDate.collectablePeriodStart,
      candidateDate.collectablePeriodEnd
    );
    this.vacancy = candidateDate.vacancy;
    this.date = candidateDate.date;
  }
}
