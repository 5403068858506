import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';

/**
 * DriverAttendanceTemplateEntity を編集するためのもの
 */
export interface IDriverAttendanceTemplateFormPanel extends IEntityFormPanel<DriverAttendanceTemplateEntity> {}

export class DriverAttendanceTemplateFormPanel
  extends AbstractEntityFormPanel<DriverAttendanceTemplateEntity>
  implements IDriverAttendanceTemplateFormPanel {}
