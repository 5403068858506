import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  carType$getAllSymbol,
  carType$createSymbol,
  ICreateCarTypeData,
  carType$updateSymbol,
  IUpdateCarTypeData,
  ICarTypeMutationError,
} from '~/framework/server-api/masters/carType';
import { AggregatedCarTypeMapper } from '~/framework/domain/masters/car-type/aggregatedCarTypeMapper';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { IDisposable } from '~/framework/core/disposable';

import { Ports } from '~/framework/core/ports';

export const carTypeSymbol = Symbol('carType');

interface ICreatePresenter {
  create(entity: AggregatedCarTypeEntity): void;
  errorOnCreate(error: ICarTypeMutationError): void;
}

interface IUpdatePresenter {
  update(entity: AggregatedCarTypeEntity): void;
  errorOnUpdate(error: ICarTypeMutationError): void;
}

export class CarTypeApplicationService {
  private readonly serverApis: ServerApiManager;
  private readonly createPort: Ports<ICreatePresenter>;
  private readonly updatePort: Ports<IUpdatePresenter>;
  private carTypeMapper: AggregatedCarTypeMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.createPort = new Ports<ICreatePresenter>();
    this.updatePort = new Ports<IUpdatePresenter>();
    this.carTypeMapper = new AggregatedCarTypeMapper(
      store.masters.aggregatedCarType,
      store.masters.orderGroup,
      store.masters.aggregatedCarTypeContainerType,
      store.masters.aggregatedContainerType,
      store.masters.packingStyle
    );
  }

  addCreatePresenter(presenter: ICreatePresenter): IDisposable {
    return this.createPort.add(presenter);
  }

  async create(data: ICreateCarTypeData): Promise<void> {
    const createApi = this.serverApis.get(carType$createSymbol);
    const createResult = await createApi.create(data);
    // visitor にしてもいいが、数がないのでわざわざそこまでしなくてもよさそう
    if (createResult.__typename === 'CarType') {
      const entity = this.carTypeMapper.mapSingle(createResult);
      this.createPort.output('create', entity);
    } else if (createResult.__typename === 'CarTypeMutationError') {
      this.createPort.output('errorOnCreate', createResult);
    }
  }

  addUpdatePresenter(presenter: IUpdatePresenter): IDisposable {
    return this.updatePort.add(presenter);
  }

  async update(data: IUpdateCarTypeData): Promise<void> {
    const updateApi = this.serverApis.get(carType$updateSymbol);
    const updateResult = await updateApi.update(data);
    // visitor にしてもいいが、数がないのでわざわざそこまでしなくてもよさそう
    if (updateResult.__typename === 'CarType') {
      const entity = this.carTypeMapper.mapSingle(updateResult);
      this.updatePort.output('update', entity);
    } else if (updateResult.__typename === 'CarTypeMutationError') {
      this.updatePort.output('errorOnUpdate', updateResult);
    }
  }

  async getAll(): Promise<AggregatedCarTypeEntity[]> {
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const carTypesData = await carType$getAllApi.getAll();
    const entities = this.carTypeMapper.map(carTypesData);
    return entities;
  }
}
