
import Vue, { PropType } from 'vue';
import RScheduleCancelConfirmDialog from './RScheduleCancelConfirmDialog.vue';

enum EventType {
  ClickConfirm = 'click:confirm',
  ClickAbort = 'click:abort',
}

export default Vue.extend({
  name: 'RScheduleOrderViaReservationCancelConfirmDialog',
  components: {
    RScheduleCancelConfirmDialog,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  methods: {
    onClickConfirm(): void {
      this.$emit(EventType.ClickConfirm);
    },
    onClickAbort(): void {
      this.$emit(EventType.ClickAbort);
    },
  },
});
