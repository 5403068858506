import { BaseTaskTypeName, OrderKind, SiteType } from '~/framework/domain/typeAliases';
import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { ValueOf } from '~/types/type';
import { IBaseTaskTypeData, ITaskTypeData } from '~/framework/server-api/masters/taskType';
import { AggregatedDriverEntity } from '../driver/aggregatedDriverEntity';

export const BaseTaskTypeDisplayName = {
  ReplaceAtGenerationSite: '交換',
  AllocateAtGenerationSite: '設置',
  FetchAtGenerationSite: '引上',
  LoadAtGenerationSite: '積込',
  DisposeAtDisposalSite: '処分',
  LoadAtBaseSite: '積荷',
  UnloadAtBaseSite: '積下',
} as const;

const SiteTypeDisplayName = {
  GenerationSite: '排出場',
  DisposalSite: '処分場',
  BaseSite: 'コンテナ拠点',
} as const;
export type SiteTypeDisplayNameValue = ValueOf<typeof SiteTypeDisplayName>;
export type TaskTypeDisplayNameValue = ValueOf<typeof BaseTaskTypeDisplayName>;

export class TaskTypeEntity implements IEntity, IPersistentEntity {
  readonly id: string;
  readonly persistentId: string;
  readonly baseTaskType: BaseTaskTypeEntity;
  name: string;
  bannedDrivers: AggregatedDriverEntity[];
  constructor(data: ITaskTypeData, baseTaskType: BaseTaskTypeEntity, bannedDrivers: AggregatedDriverEntity[]) {
    this.id = data.id;
    this.persistentId = data.id;
    this.baseTaskType = baseTaskType;
    this.name = data.name;
    this.bannedDrivers = bannedDrivers;
  }

  update(data: ITaskTypeData, bannedDrivers: AggregatedDriverEntity[]): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of RoutingRegulationEntity');
    }
    this.name = data.name;
    this.bannedDrivers = bannedDrivers;
  }
}

export class BaseTaskTypeEntity implements IEntity, IPersistentEntity {
  readonly id: string;
  readonly persistentId: string;
  readonly name: BaseTaskTypeName;
  readonly displayName: string | TaskTypeDisplayNameValue;
  readonly siteTypeDisplayName: SiteTypeDisplayNameValue;
  readonly orderKind: OrderKind;
  readonly siteType: SiteType;
  constructor(data: IBaseTaskTypeData) {
    this.id = data.id;
    this.name = data.name;
    this.orderKind = data.orderKind;
    this.siteType = data.siteType;
    this.persistentId = data.id;
    this.displayName = BaseTaskTypeDisplayName[data.name];
    this.siteTypeDisplayName = SiteTypeDisplayName[data.siteType];
  }
}
