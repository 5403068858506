
import Vue, { PropType } from 'vue';
import { format, formatISO, getDay, parseISO } from 'date-fns';
import { DatePickerAllowedDatesFunction } from 'vuetify';
import { CssClasses, CssStyles, Maybe, DayOfWeek } from '~/framework/typeAliases';
import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';
import { dateFormatter } from '~/framework/view-models/vuetify';
import { formatDateToString } from '~/framework/services/date/date';

type Formatter = (date: Date) => string;

type DataType = {
  /**
   * 日付を選択するためのカレンダーが表示されているかどうか
   */
  isDatePickerActive: boolean;
  /**
   * テキストフィールドに表示される文字列
   */
  dateString: Maybe<string>;
  /**
   * v-date-picker に渡される文字列（ISO フォーマットでなければならない）
   */
  dateIsoString: Maybe<string>;
};

enum EventTypes {
  DateChange = 'change:date',
}

const getDateString = (date: Date) => {
  return format(date, 'yyyy/MM/dd') + ' ' + daysOfWeekMap[getDay(date)];
};

const getDateIsoString = (date: Date) => {
  return formatISO(date, { representation: 'date' });
};

export default Vue.extend({
  name: 'RDateField',
  model: {
    event: EventTypes.DateChange,
    prop: 'date',
  },
  props: {
    /**
     * 選択されている日付
     */
    date: {
      type: Date as PropType<Maybe<Date>>,
      required: false,
      default: undefined,
    },
    min: {
      type: Date as PropType<Maybe<Date>>,
      required: false,
      default: undefined,
    },
    startOfWeek: {
      type: Number as PropType<DayOfWeek>,
      required: false,
      default: 0,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: '100%',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    formatter: {
      type: undefined as unknown as PropType<Formatter>,
      required: false,
      default() {
        return getDateString;
      },
    },
    allowedDates: {
      type: Function as PropType<DatePickerAllowedDatesFunction>,
      required: false,
      default() {
        // allow all dates by default
        return true;
      },
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      required: false,
      default() {
        return [];
      },
    },
    defaultDate: {
      type: Date as PropType<Date>,
      required: false,
      default() {
        return new Date();
      },
    },
  },
  data(): DataType {
    let dateString;
    let dateIsoString;
    if (this.date !== undefined) {
      dateString = this.formatter(this.date);
      dateIsoString = getDateIsoString(this.date);
    }
    return {
      isDatePickerActive: false,
      dateString,
      dateIsoString,
    };
  },
  computed: {
    styles(): CssStyles {
      return {
        width: this.width,
      };
    },
    textFieldClasses(): CssClasses {
      return {
        'date-field--is-menu-active': this.isDatePickerActive,
      };
    },
    minString(): Maybe<string> {
      return this.min ? formatDateToString(this.min) : undefined;
    },
  },
  watch: {
    dateIsoString(value: string) {
      this.$emit(EventTypes.DateChange, parseISO(value));
    },
    date(value: Maybe<Date>) {
      this.dateString = value !== undefined ? this.formatter(value) : undefined;
    },
    isDatePickerActive(value: boolean) {
      // activeになった時にdateが未入力の場合はDefaultDateを入れる
      if (value && this.date === undefined) {
        this.dateIsoString = getDateIsoString(this.defaultDate);
      }
    },
  },
  methods: {
    dateFormatter,
    onDateChange() {
      this.isDatePickerActive = false;
    },
  },
});
