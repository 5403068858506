
import Vue, { PropType } from 'vue';
import { CssClasses, CssStyles } from '~/framework/typeAliases';

type DataType = {
  showDetails: boolean;
};

/**
 * 内容を pre で設定したい場合は pre-content、および pre-details を指定して使用する。
 * default のスロットには br タグ込みの内容を設定する事ができるが、div などを含める事は想定していない。
 * width, height は親の div などに設定してもよいし、width, height を attribute として指定する事もできる。
 */
export default Vue.extend({
  name: 'RTruncate',
  props: {
    width: {
      type: String as PropType<string>,
      required: false,
      default: 'auto',
    },
    height: {
      type: String as PropType<string>,
      required: false,
      default: 'auto',
    },
    preContent: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    preDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    closeOnContentClick: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuProps: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      required: false,
      default: () => ({}),
    },
  },
  data(): DataType {
    return {
      showDetails: false,
    };
  },
  computed: {
    styles(): CssStyles {
      return {
        width: this.width,
        height: this.height,
      };
    },
    classes(): CssClasses {
      return {
        'r-truncate__content': true,
        'r-truncate__content--default': !this.preContent,
        'r-truncate__content--pre': this.preContent,
      };
    },
    listedDetailClasses(): CssClasses {
      return {
        'r-listed-details__text': true,
        'r-listed-details__text--pre': this.preDetails,
      };
    },
  },
  mounted(): void {
    const rootElement = this.$refs.rootElement as HTMLElement;
    if (rootElement === undefined) {
      return;
    }
    rootElement.onmouseover = this.updateShowDetails;
  },
  methods: {
    updateShowDetails() {
      const rootElement = this.$refs.rootElement as HTMLElement;
      if (rootElement === undefined || rootElement.clientWidth === 0 || rootElement.clientHeight === 0) {
        return;
      }
      const overflowsWidth = rootElement.clientWidth < rootElement.scrollWidth;
      const overflowsHeight = rootElement.clientHeight < rootElement.scrollHeight;
      this.showDetails = overflowsWidth || overflowsHeight;
    },
  },
});
