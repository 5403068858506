import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import {
  containerType$createSymbol,
  containerType$getAllSymbol,
  containerType$updateSymbol,
  ICreateData,
  IUpdateData,
} from '~/framework/server-api/masters/containerType';
import { AggregatedContainerTypeMapper } from '~/framework/domain/masters/container-type/aggregatedContainerTypeMapper';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

export const containerTypeSymbol = Symbol('containerType');

export interface IContainerTaskListData {
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  containerTypes: AggregatedContainerTypeEntity[];
  officeSetting: OfficeSettingEntity;
  taskTypes: TaskTypeEntity[];
}

export class ContainerTypeApplicationService {
  private readonly serverApis: ServerApiManager;
  private containerTypeMapper: AggregatedContainerTypeMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.containerTypeMapper = new AggregatedContainerTypeMapper(
      store.masters.aggregatedContainerType,
      store.masters.packingStyle
    );
  }

  async create(data: ICreateData): Promise<AggregatedContainerTypeEntity> {
    const containerTypeData = await this.serverApis.get(containerType$createSymbol).create(data);
    const entity = this.containerTypeMapper.mapSingle(containerTypeData);
    return entity;
  }

  async update(data: IUpdateData): Promise<AggregatedContainerTypeEntity> {
    const containerTypeData = await this.serverApis.get(containerType$updateSymbol).update(data);
    const entity = this.containerTypeMapper.mapSingle(containerTypeData);
    return entity;
  }

  // region list
  async getAll(): Promise<AggregatedContainerTypeEntity[]> {
    const containerTypeData = await this.serverApis.get(containerType$getAllSymbol).getAll();
    const entities = this.containerTypeMapper.map(containerTypeData);
    return entities;
  }
}
