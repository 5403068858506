import { StoredMapperBase } from '~/framework/core/mapper';
import { JwnetWasteMasterEntity } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { IJwnetWasteMasterData } from '~/framework/server-api/masters/jwnetWasteMaster';

export class JwnetWasteMasterMapper
  extends StoredMapperBase<IJwnetWasteMasterData, JwnetWasteMasterEntity>
  implements JwnetWasteMasterMapper
{
  protected store: JwnetWasteMasterStore;

  constructor(store: JwnetWasteMasterStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IJwnetWasteMasterData): JwnetWasteMasterEntity {
    return new JwnetWasteMasterEntity(data);
  }

  protected updateWithData(data: IJwnetWasteMasterData, entity: JwnetWasteMasterEntity) {
    entity.update(data);
  }
}
