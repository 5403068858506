import {
  HolidayRuleQuery,
  HolidayRuleQueryVariables,
  UpdateHolidayRulesMutation,
  UpdateHolidayRulesMutationVariables,
} from '@/graphql/graphQLServerApi';
import { HolidayRule } from '@/../graphql/server-api/queries/holiday-rule.gql';
import { UpdateHolidayRules } from '@/../graphql/server-api/mutations/holiday-rule.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IHolidayRuleData } from '~/framework/server-api/masters/holidayRule';

export class GetApi extends GraphqlApiBase {
  async get() {
    const result = await this.query<HolidayRuleQuery, HolidayRuleQueryVariables>({
      query: HolidayRule,
      variables: {},
    });
    return result.holidayRule;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IHolidayRuleData) {
    const result = await this.mutate<UpdateHolidayRulesMutation, UpdateHolidayRulesMutationVariables>({
      mutation: UpdateHolidayRules,
      variables: { holidayRules: [data] },
    });
    this.validateArrayConsistency([data], result.updateHolidayRules, `UpdateHolidayRules`);
  }
}
