export interface ICollectablePeriodTemplateDefaultOption {
  value: boolean;
  label: string;
}

/**
 * 該当の到着時間をデフォルとして設定するかどうかの選択肢
 */
export const collectablePeriodTemplateDefaultOptions: ICollectablePeriodTemplateDefaultOption[] = [
  { value: true, label: '指定する' },
];

/**
 * value から label を引ける様にしたもの
 */
export const collectablePeriodTemplateDefaultOptionMap = new Map<
  ICollectablePeriodTemplateDefaultOption['value'],
  ICollectablePeriodTemplateDefaultOption['label']
>(collectablePeriodTemplateDefaultOptions.map((option) => [option.value, option.label]));
