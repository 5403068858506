import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import {
  IPackingPlacementFormValues,
  PackingPlacementFormValues,
} from '~/components/panels/packing-placement/r-packing-placements-form/packingPlacementFormValues';
import { Id } from '~/framework/typeAliases';
import { mapEntity } from '~/framework/core/mapper';

export interface IPackingStyleFormValues {
  /**
   * 荷姿コードの ID
   */
  readonly id: Id;
  /**
   * 荷姿コード名
   */
  readonly name: string;
  /**
   * この荷姿コードに属する荷姿
   */
  readonly containerTypes: AggregatedContainerTypeEntity[];
  /**
   * 荷姿の残存数
   */
  packingPlacements: IPackingPlacementFormValues[];

  getContainerTypeOf(id: Id): AggregatedContainerTypeEntity;

  isEqualTo(another: IPackingStyleFormValues): boolean;
}

export class PackingStyleFormValues implements IPackingStyleFormValues {
  private readonly containerTypeMap: Map<Id, AggregatedContainerTypeEntity>;
  readonly id: Id;
  readonly name: string;
  readonly containerTypes: AggregatedContainerTypeEntity[];
  packingPlacements: IPackingPlacementFormValues[];

  constructor(
    id: string,
    name: string,
    containerTypes: AggregatedContainerTypeEntity[],
    loadableContainerTypes: IPackingPlacementFormValues[]
  ) {
    this.id = id;
    this.name = name;
    this.containerTypes = containerTypes;
    this.packingPlacements = loadableContainerTypes;
    this.containerTypeMap = mapEntity(containerTypes);
  }

  static clone(original: IPackingStyleFormValues): PackingStyleFormValues {
    const loadableContainerTypes = original.packingPlacements.map((loadableContainerType) =>
      PackingPlacementFormValues.clone(loadableContainerType)
    );
    return new PackingStyleFormValues(original.id, original.name, original.containerTypes, loadableContainerTypes);
  }

  getContainerTypeOf(id: Id): AggregatedContainerTypeEntity {
    return this.containerTypeMap.getOrError(id);
  }

  isEqualTo(another: IPackingStyleFormValues): boolean {
    return (
      this.id === another.id &&
      this.packingPlacements.length === another.packingPlacements.length &&
      this.packingPlacements.every((item, index) => item.isEqualTo(another.packingPlacements[index]))
    );
  }
}
