import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import introspectionResult from '@/graphql/fragmentMatcher';
import { withScalars } from 'apollo-link-scalars';
import * as typeDefinitions from '@/../graphql/server-api/schemas.gql';
import { buildSchemaFromTypeDefinitions } from '@graphql-tools/schema';
import { createUploadLink } from 'apollo-upload-client';
import { graphQLContextSetter, transactionObserver } from '~/plugins/framework';
import { resolvers, setCacheDefaultValue } from '~/graphql/localState';
import { typesMap } from '~/graphql/types';
import { ObserverLink } from '~/graphql/observerLink';
import { fetch } from '~/graphql/fetch';
import { ResponseAwareLink } from '~/graphql/responseAwareLink';

// ref: https://github.com/nuxt-community/apollo-module/blob/master/test/fixture-local-state/plugins/apollo-config.js
// ref: https://github.com/howtographql/react-apollo/blob/master/src/index.js

export default function (context) {
  // .gql ファイルから読み込まれるのは DocumentNode なので、これを GraphQLSchema に変換する必要がある
  // buildSchemaFromTypeDefinitions はこれをやってくれる便利関数
  const schema = buildSchemaFromTypeDefinitions(typeDefinitions);
  const httpEndpoint = context.env.mioUrl
    ? context.env.mioUrl + '/graphql'
    : 'https://api.stg.haisya-gasira.com/graphql';
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionResult,
  });
  const retryLink = new RetryLink();
  const batchLink = new BatchHttpLink({ batchMax: 20, uri: httpEndpoint, credentials: 'include', fetch });
  const authLink = setContext(graphQLContextSetter);
  const observerLink = new ObserverLink();
  const responseAwareLink = new ResponseAwareLink();
  const uploadLink = createUploadLink({ uri: httpEndpoint, credentials: 'include', fetch });
  const mediatorLink = ApolloLink.split(
    (operation) => {
      return operation.query.definitions.find((definition) => definition.operation === 'mutation');
    },
    uploadLink,
    batchLink
  );
  transactionObserver(observerLink.requestEvent, observerLink.responseEvent);
  const typedHttpLink = ApolloLink.from([
    withScalars({ schema, typesMap }),
    observerLink,
    responseAwareLink,
    authLink,
    retryLink,
    mediatorLink,
  ]);

  return {
    resolvers,
    inMemoryCacheOptions: {
      // 意図しないデータ書き換えが走る事を防ぐためにフリーズしている
      // ref: https://blog.apollographql.com/whats-new-in-apollo-client-2-6-b3acf28ecad1
      freezeResults: true,
      fragmentMatcher,
    },
    httpEndpoint,
    onCacheInit: setCacheDefaultValue,
    link: typedHttpLink,
    defaultHttpLink: false,
  };
}
