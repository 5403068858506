
import Vue, { PropType } from 'vue';
import { IRPopupMenuItem } from '../r-popup-menu/rPopupMenu';

enum EventTypes {
  ClickListItem = 'click:list-item',
}

type DataType = {
  open: boolean;
};

export default Vue.extend({
  name: 'RDetailPopupMenu',
  props: {
    items: {
      type: Array as PropType<Array<IRPopupMenuItem>>,
      required: false,
      default: () => [],
    },
  },
  data(): DataType {
    return {
      open: false,
    };
  },
  methods: {
    clickListItem(item: IRPopupMenuItem) {
      this.$emit(EventTypes.ClickListItem, item);
    },
  },
});
