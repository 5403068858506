import { ISimpleCarTypeData } from '~/framework/server-api/masters/carType';
import { IBaseSiteData } from '~/framework/server-api/masters/baseSite';

import { IDuplicatedNameError } from '~/framework/server-api/errors';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';

export const car$createSymbol = Symbol('car$createSymbol');

export const car$getAllSymbol = Symbol('getAll');

export const car$getByIdsSymbol = Symbol('getByIds');

export const car$updateSymbol = Symbol('car$updateSymbol');

export type Car = {
  [car$getAllSymbol]: IGetAllApi;
  [car$getByIdsSymbol]: IGetByIdsApi;
  [car$createSymbol]: ICreateApi;
  [car$updateSymbol]: IUpdateApi;
};

export interface ICarDataType {
  __typename: 'Car';
}

export interface ICarData {
  id: string;
  name: string;
  simpleCarType: ISimpleCarTypeData;
  baseSite: IBaseSiteData;
  enabledOn: Date;
  status: SoftDeleteStatus;
}

export interface ICreateApi {
  create(data: ICreateData): Promise<CreateResultTypes>;
}

export type CreateResultTypes = (ICarDataType & ICarData) | ICarMutationError;

export interface ICreateData {
  name: string;
  carTypeId: string;
  baseSiteId: string;
  enabledOn: Date;
}

export interface IGetAllApi {
  getAll(): Promise<ICarData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<ICarData[]>;
}

export interface ICarMutationError {
  __typename: 'CarMutationError';
  errors: CarMutationErrorTypes[];
}

export type CarMutationErrorTypes = IDuplicatedNameError;

export interface IUpdateApi {
  update(data: IUpdateData): Promise<UpdateResultTypes>;
}

export type UpdateResultTypes = (ICarDataType & ICarData) | ICarMutationError;

export interface IUpdateData {
  id: string;
  name: string;
  carTypeId: string;
  baseSiteId: string;
}
