export interface IRouteCollectionOption {
  value: boolean;
  label: string;
}

export interface IDefaultRouteCollectionOption {
  value: DefaultRouteCollectionOptionsValues;
  label: string;
}

export enum DefaultRouteCollectionOptionsValues {
  Allowed = 'Allowed',
  NotAllowed = 'NotAllowed',
  NotSet = 'NotSet',
}

/**
 * ルート回収の選択肢
 */
export const routeCollectionOptions: IRouteCollectionOption[] = [
  { value: true, label: '許可' },
  { value: false, label: '禁止' },
];

/**
 * value から label を引ける様にしたもの
 */
export const routeCollectionOptionMap = new Map<IRouteCollectionOption['value'], IRouteCollectionOption['label']>(
  routeCollectionOptions.map((option) => [option.value, option.label])
);

/**
 * Labels and values for default route collection radio group UI
 */
export const getDefaultRouteCollectionOptions = (
  defaultRouteCollectionAllowed: boolean
): IDefaultRouteCollectionOption[] => {
  const defaultLabel = routeCollectionOptionMap.get(defaultRouteCollectionAllowed);
  if (!defaultLabel) throw new Error('Impossible!');

  return [
    { value: DefaultRouteCollectionOptionsValues.Allowed, label: '許可' },
    { value: DefaultRouteCollectionOptionsValues.NotAllowed, label: '禁止' },
    { value: DefaultRouteCollectionOptionsValues.NotSet, label: `ﾃﾞﾌｫﾙﾄ(${defaultLabel})` },
  ];
};

/**
 * This map is used to map enum to api values when sending data to server.
 */
export const defaultRouteCollectionEnumToApiValueMap = new Map<
  DefaultRouteCollectionOptionsValues,
  boolean | undefined
>();
defaultRouteCollectionEnumToApiValueMap.set(DefaultRouteCollectionOptionsValues.Allowed, true);
defaultRouteCollectionEnumToApiValueMap.set(DefaultRouteCollectionOptionsValues.NotAllowed, false);
defaultRouteCollectionEnumToApiValueMap.set(DefaultRouteCollectionOptionsValues.NotSet, undefined);

/**
 * This map is used to restore data in form with data received from server.
 */
export const defaultRouteCollectionApiValueToEnumMap = new Map<
  boolean | undefined,
  DefaultRouteCollectionOptionsValues
>();
defaultRouteCollectionApiValueToEnumMap.set(true, DefaultRouteCollectionOptionsValues.Allowed);
defaultRouteCollectionApiValueToEnumMap.set(false, DefaultRouteCollectionOptionsValues.NotAllowed);
defaultRouteCollectionApiValueToEnumMap.set(undefined, DefaultRouteCollectionOptionsValues.NotSet);
