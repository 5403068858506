import { isSameDay } from 'date-fns';
import { IPage, IPageData, ISchedulesData } from '~/framework/application/schedule/schedule/page';
import { userSetting$getSymbol } from '~/framework/server-api/user-setting/get';
import { UserSettingMapper } from '~/framework/domain/user-setting/userSettingMapper';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { schedule$getLatestSymbol } from '~/framework/server-api/schedule/schedule/getLatest';
import { ScheduleStatus } from '~/framework/server-api/schedule/schedule/schedule';
import { ScheduleMapper } from '~/framework/domain/schedule/schedule/scheduleMapper';
import { ScheduleFactory } from '~/framework/factories/schedule/scheduleFactory';
import { IWatcher, IWatcherPresenter, ProgressStatus } from '~/framework/application/schedule/schedule/watcher';
import { Ports } from '~/framework/core/ports';
import { IDisposable } from '~/framework/core/disposable';
import { Maybe, MaybeWeakRef, PersistentId } from '~/framework/typeAliases';
import { PublishingStatus } from '~/framework/server-api/typeAliases';

import { IScheduleEntity } from '~/framework/domain/schedule/schedule/scheduleEntity';
import { schedule$getByIdsSymbol } from '~/framework/server-api/schedule/schedule/getByIds';
import { schedule$getByScheduleRequestSymbol } from '~/framework/server-api/schedule/schedule/getByScheduleRequest';
import { schedule$updatePublishingStateSymbol } from '~/framework/server-api/schedule/schedule/updatePublishingState';
import {
  ICreate,
  ICreateScheduleOfDateOptions,
  ICreateScheduleOptions,
} from '~/framework/application/schedule/schedule/create';
import { IScheduleCreateData, schedule$createSymbol } from '~/framework/server-api/schedule/schedule/create';
import { attendance$getByDateSymbol } from '~/framework/server-api/masters/attendance';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { orderGroup$getAllSymbol } from '~/framework/server-api/masters/orderGroup';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { OfficeSettingMapper } from '~/framework/domain/masters/office-setting/officeSettingMapper';
import { officeSetting$getSymbol } from '~/framework/server-api/masters/officeSetting';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';
import { ScheduleResponse } from '~/framework/domain/scheduleV2/scheduleResponse';
import { RevertScheduleInput, ScheduleByScheduleRequestInput } from '~/graphql/graphQLServerApi';
import { schedule$revertScheduleSymbol } from '~/framework/server-api/schedule/schedule/revertSchedule';
import { ScheduleResponseJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';
import { createLogger } from '~/framework/logger';

export const scheduleSymbol = Symbol('');
const logger = createLogger('scheduleApplicationService');
export class ScheduleApplicationService implements ICreate, IWatcher, IPage {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly scheduleMapper: ScheduleMapper;
  private readonly userSettingMapper: UserSettingMapper;
  private readonly scheduleFactory: ScheduleFactory;

  // region watcher
  private readonly watcherPort: Ports<IWatcherPresenter>;
  private _watchingId: Maybe<string>;
  private statusFetchInterval: number = 5 * 1000; // 5秒
  private fetchTimeoutHandler: Maybe<number> = undefined;
  private progressTimeoutHandler: Maybe<number> = undefined;
  private verificationProcessThreshold: number = 5; // 秒
  private optimizationThreshold1: number = 0.3;
  private optimizationThreshold2: number = 0.9;
  private deferThreshold: number = 0.9;
  private totalExpectedOptimizationDuration: number = 8 * 60; // 秒
  private totalActualExpectedOptimizationDuration: number = 0; // 実際、100% になるには何秒かかるのか
  private deferCoefficient: number = 1.5; // 90% から先、1% ごとにどれだけ伸びるか
  private creatingScheduleStartedAt: Maybe<Date>;
  private isCreatingSchedule: boolean = false;
  private progress: Maybe<number>;
  private progressStatus: Maybe<ProgressStatus>;
  // end region

  private get watchingId() {
    return this._watchingId;
  }

  private set watchingId(value: Maybe<string>) {
    this._watchingId = value;
    this.isCreatingSchedule = this._watchingId !== undefined;
  }
  // endregion

  constructor(store: Store, serverApis: ServerApiManager, scheduleFactory: ScheduleFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.scheduleMapper = new ScheduleMapper(this.store.schedule.schedule);
    this.userSettingMapper = new UserSettingMapper(this.store.userSetting);
    this.scheduleFactory = scheduleFactory;
    this.watcherPort = new Ports();
    this.setTotalActualExpectedOptimizationDuration();
  }

  async getPageData(): Promise<IPageData> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const officeSetting$getApi = this.serverApis.get(officeSetting$getSymbol);
    const orderGroup$getAllApi = this.serverApis.get(orderGroup$getAllSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);
    const officeSettingMapper: OfficeSettingMapper = new OfficeSettingMapper(this.store.masters.officeSetting);
    const orderGroupMapper: OrderGroupMapper = new OrderGroupMapper(this.store.masters.orderGroup);
    const [userSettingData, officeSettingData, orderGroupData] = await Promise.all([
      getApi.get(),
      officeSetting$getApi.get(),
      orderGroup$getAllApi.getAll(),
    ]);
    const userSetting = userSettingMapper.mapSingle(userSettingData);
    const officeSetting = officeSettingMapper.mapSingle(officeSettingData);
    const orderGroups = orderGroupMapper.map(orderGroupData);
    return {
      userSetting,
      officeSetting,
      orderGroups,
    };
  }

  async getSchedule(attendanceEntity: AttendanceEntity, orderGroupId: string): Promise<ISchedulesData> {
    const schedule$getLatestApi = this.serverApis.get(schedule$getLatestSymbol);
    const latestScheduleData = await schedule$getLatestApi.getLatest(attendanceEntity.persistentId, orderGroupId, [
      ScheduleStatus.Finished,
      ScheduleStatus.Running,
      ScheduleStatus.Waiting,
    ]);
    const result: ISchedulesData = {
      finishedSchedule: undefined,
      runningSchedule: undefined,
      waitingSchedule: undefined,
      scheduleResponse: undefined,
      finishedScheduleResponse: undefined,
      affectedDriverIds: undefined,
    };
    const finishedScheduleData = latestScheduleData.find((data) => data.status === ScheduleStatus.Finished);
    const runningScheduleData = latestScheduleData.find((data) => data.status === ScheduleStatus.Running);
    const waitingScheduleData = latestScheduleData.find((data) => data.status === ScheduleStatus.Waiting);
    result.finishedSchedule = finishedScheduleData ? this.scheduleMapper.mapSingle(finishedScheduleData) : undefined;
    result.runningSchedule = runningScheduleData ? this.scheduleMapper.mapSingle(runningScheduleData) : undefined;
    result.waitingSchedule = waitingScheduleData ? this.scheduleMapper.mapSingle(waitingScheduleData) : undefined;

    if (result.finishedSchedule && result.finishedSchedule.scheduleResponse) {
      result.finishedScheduleResponse = await this.scheduleFactory.buildByDataV2(
        result.finishedSchedule.persistentId,
        attendanceEntity.date,
        result.finishedSchedule.scheduleResponse
      );
      // v2データ格納
      result.scheduleResponse = new ScheduleResponse(result.finishedSchedule.scheduleResponse);
    }
    return result;
  }

  // createScheduleのためにscheduleResponseのみを返す関数
  async getScheduleResponse(scheduleRequestInput: ScheduleByScheduleRequestInput): Promise<ScheduleResponseJsonObject> {
    const Schedule$GetByScheduleRequestApi = this.serverApis.get(schedule$getByScheduleRequestSymbol);
    const resultScheduleByScheduleRequest = await Schedule$GetByScheduleRequestApi.getByScheduleRequest(
      scheduleRequestInput
    );
    return resultScheduleByScheduleRequest.scheduleResponse;
  }

  // ScheduleByScheduleRequest V2
  async getScheduleByScheduleRequest(
    schedulesData: ISchedulesData,
    persistentId: string,
    attendanceEntity: AttendanceEntity,
    scheduleRequestInput: ScheduleByScheduleRequestInput
  ): Promise<ISchedulesData> {
    const Schedule$GetByScheduleRequestApi = this.serverApis.get(schedule$getByScheduleRequestSymbol);

    const resultScheduleByScheduleRequest = await Schedule$GetByScheduleRequestApi.getByScheduleRequest(
      scheduleRequestInput
    );

    if (schedulesData !== undefined) {
      schedulesData.finishedScheduleResponse = await this.scheduleFactory.buildByDataV2(
        persistentId,
        attendanceEntity.date,
        resultScheduleByScheduleRequest.scheduleResponse
      );
      schedulesData.scheduleResponse = new ScheduleResponse(resultScheduleByScheduleRequest.scheduleResponse);
    }
    return schedulesData;
  }

  // RevertSchedule V2
  async revertSchedule(
    schedulesData: ISchedulesData,
    persistentId: string,
    attendanceEntity: AttendanceEntity,
    revertScheduleInput: RevertScheduleInput
  ): Promise<ISchedulesData> {
    const Schedule$RevertScheduleApi = this.serverApis.get(schedule$revertScheduleSymbol);

    const resultRevertSchedule = await Schedule$RevertScheduleApi.revertSchedule(revertScheduleInput);

    if (schedulesData !== undefined) {
      schedulesData.finishedScheduleResponse = await this.scheduleFactory.buildByDataV2(
        persistentId,
        attendanceEntity.date,
        resultRevertSchedule.scheduleResponse
      );
      schedulesData.scheduleResponse = new ScheduleResponse(resultRevertSchedule.scheduleResponse);
      schedulesData.affectedDriverIds = resultRevertSchedule.affectedDriverIds;
    }
    return schedulesData;
  }

  // region create
  async createScheduleOfAttendance(
    attendanceId: PersistentId,
    orderGroupId: PersistentId,
    options?: ICreateScheduleOptions
  ): Promise<IScheduleEntity> {
    const schedule$createApi = this.serverApis.get(schedule$createSymbol);
    const scheduleMapper: ScheduleMapper = new ScheduleMapper(this.store.schedule.schedule);

    const scheduleResponse = options?.scheduleResponse;

    // 新規作成
    const newScheduleData: IScheduleCreateData = {
      attendanceId,
      orderGroupId,
      scheduleResponse,
    };
    const [id] = await schedule$createApi.create([newScheduleData]);

    // 本当は create の返り値をそのまま map できるといいのだが、今の API はそうなっていないので
    const scheduleEntity = scheduleMapper.mapSingle({
      ...newScheduleData,
      id,
      status: ScheduleStatus.Waiting,
      creatingScheduleStartedAt: undefined,
      // isPublished の値は create では返却されず、また view からデータを引き回すとかなり複雑になる
      // 現時点ではここで設定する isPublished の値は利用しておらず、後続の polling 処理で取得した正しい値を利用している。
      // この値が必要になる場合にはgqlの返却値に追加することを検討する。
      isPublished: false,
    });

    return scheduleEntity;
  }

  async createScheduleOfDate(
    date: Date,
    orderGroupId: PersistentId,
    options?: ICreateScheduleOfDateOptions
  ): Promise<Maybe<IScheduleEntity>> {
    let attendance = options?.attendance;
    if (attendance === undefined) {
      const attendance$getByDateApi = this.serverApis.get(attendance$getByDateSymbol);
      const attendanceMapper: AttendanceMapper = new AttendanceMapper(this.store.masters.attendance);
      const attendanceData = await attendance$getByDateApi.getByDate(date);
      if (attendanceData) attendance = attendanceMapper.mapSingle(attendanceData);
    }
    if (attendance === undefined) return undefined;
    if (isSameDay(date, attendance.date) === false) {
      throw new Error(`date and attendance.date should be the same!`);
    }
    return await this.createScheduleOfAttendance(attendance.persistentId, orderGroupId, options);
  }

  // endregion

  // region watcher
  addWatcherPresenter(presenter: MaybeWeakRef<IWatcherPresenter>): IDisposable {
    return this.watcherPort.add(presenter);
  }

  watchSchedule(id: PersistentId) {
    this.cancelWatching();
    this.watchingId = id;
    this.scheduleNextFetch();
    this.update();
  }

  cancelWatching() {
    if (this.fetchTimeoutHandler !== undefined) {
      window.clearTimeout(this.fetchTimeoutHandler);
      this.fetchTimeoutHandler = undefined;
    }
    if (this.progressTimeoutHandler !== undefined) {
      window.clearTimeout(this.progressTimeoutHandler);
      this.progressTimeoutHandler = undefined;
    }
    this.watchingId = undefined;
    this.progress = undefined;
    this.progressStatus = undefined;
    this.creatingScheduleStartedAt = undefined;
    this.update();
  }

  async updatePublishingStatus(orderGroupId: string, date: Date, isPublishing: boolean): Promise<string> {
    const schedule$updatePublishingStateApi = this.serverApis.get(schedule$updatePublishingStateSymbol);

    const status = isPublishing ? PublishingStatus.Published : PublishingStatus.Unpublished;
    return await schedule$updatePublishingStateApi.updatePublishingStatusOfSchedule({ orderGroupId, date, status });
  }

  /**
   * 次の fetch をスケジュールする
   */
  private scheduleNextFetch(nextInterval: number = 1000): void {
    if (this.watchingId === undefined) {
      throw new Error('trying to schedule next fetch but watchingId is undefined!');
    }

    const watchingId = this.watchingId;
    this.fetchTimeoutHandler = window.setTimeout(async () => {
      await this.fetchStatus(watchingId);
      this.update();
    }, nextInterval);
  }

  /**
   * watch しているスケジュールの現在の状態を取得する
   */
  private async fetchStatus(watchingId: string): Promise<void> {
    const schedule$getByIdsApi = this.serverApis.get(schedule$getByIdsSymbol);
    const scheduleMapper: ScheduleMapper = new ScheduleMapper(this.store.schedule.schedule);

    const [scheduleData] = await schedule$getByIdsApi.getByIds([watchingId]);
    const scheduleEntity = scheduleMapper.mapSingle(scheduleData);

    // 非同期ジョブの実行中（通信中）にキャンセルされている可能性があるので現状の watch 対象 ID とこの非同期ジョブの
    // 開始時の ID をチェックし別物であればこのジョブは捨てられたものと判断して中断する
    if (this.watchingId !== watchingId) {
      return;
    }

    if (scheduleEntity.status === ScheduleStatus.Finished) {
      this.watcherPort.output('finished');
      this.cancelWatching();
    } else if (scheduleEntity.status === ScheduleStatus.Waiting || scheduleEntity.status === ScheduleStatus.Running) {
      this.scheduleNextUpdateProgress(scheduleEntity);
      this.scheduleNextFetch(this.statusFetchInterval);
    } else if (scheduleEntity.status === ScheduleStatus.Cancelled || scheduleEntity.status === ScheduleStatus.Failed) {
      this.watcherPort.output('failed');
      this.cancelWatching();
    } else if (scheduleEntity.status === ScheduleStatus.FailedByConflict) {
      this.watcherPort.output('failedByConflict');
      this.cancelWatching();
    }
  }

  private scheduleNextUpdateProgress(
    scheduleEntity: Maybe<IScheduleEntity> = undefined,
    nextInterval: number = 1000
  ): void {
    this.progressTimeoutHandler = window.setTimeout(() => {
      this.updateProgress(scheduleEntity);
      this.update();
    }, nextInterval);
  }

  /**
   * 進捗を更新する
   *
   * @param scheduleEntity 最初の更新時はいつ作成開始されたスケジュールなのか知りたいのでこれを詰める
   */
  private updateProgress(scheduleEntity: Maybe<IScheduleEntity>): void {
    if (scheduleEntity !== undefined) {
      if (scheduleEntity.status === ScheduleStatus.Waiting) {
        this.progressStatus = ProgressStatus.CheckingBeforeOptimization;
        this.progress = 0;
        return;
      } else if (scheduleEntity.status === ScheduleStatus.Running) {
        if (scheduleEntity.creatingScheduleStartedAt === undefined) {
          throw new Error('Optimization is running but creatingScheduleStartedAt is undefined!');
        }
        this.creatingScheduleStartedAt = scheduleEntity.creatingScheduleStartedAt;
      } else {
        logger.warn('scheduleEntity is initialized but status is not Waiting or Running!', scheduleEntity);
      }
    }
    if (this.creatingScheduleStartedAt !== undefined) {
      // 既に走っていた可能性があるので、開始からどれだけ経っているのかによって表示を切り替える
      // 現状どれだけ最適化が走っているか, 秒。汚い。。。
      const runningTime = (new Date().getTime() - this.creatingScheduleStartedAt.getTime()) / 1000;
      const deferThresholdSecs = this.totalExpectedOptimizationDuration * this.deferThreshold;
      if (runningTime < this.verificationProcessThreshold) {
        this.progress = runningTime / this.totalExpectedOptimizationDuration;
      } else if (runningTime <= deferThresholdSecs) {
        this.progress = runningTime / this.totalExpectedOptimizationDuration;
      } else {
        let secs = runningTime - deferThresholdSecs;
        const secsPercent = this.totalExpectedOptimizationDuration * 0.01;
        for (let index = 1; index <= Math.ceil((1.0 - this.deferThreshold) * 100.0); index++) {
          secs -= secsPercent * this.deferCoefficient ** index;
          if (secs <= 0) {
            this.progress = this.deferThreshold + (index - 1) / 100;
            break;
          }
        }
        if (this.progress === undefined) this.progress = 1.0;
      }
      // メッセージの更新
      if (runningTime < this.verificationProcessThreshold)
        this.progressStatus = ProgressStatus.CheckingBeforeOptimization;
      else if (this.progress < this.optimizationThreshold1) this.progressStatus = ProgressStatus.RunningOptimization1;
      else if (this.progress < this.optimizationThreshold2) this.progressStatus = ProgressStatus.RunningOptimization2;
      else this.progressStatus = ProgressStatus.RunningOptimization3;

      this.scheduleNextUpdateProgress(undefined);
    }
  }

  private setTotalActualExpectedOptimizationDuration(): void {
    // 90% まではそのまま
    this.totalActualExpectedOptimizationDuration = this.totalExpectedOptimizationDuration * this.deferThreshold;
    const secsPercent = this.totalExpectedOptimizationDuration * 0.01;
    for (let index = 1; index <= Math.ceil((1.0 - this.deferThreshold) * 100.0); index++) {
      const sp = secsPercent * this.deferCoefficient ** index;
      this.totalActualExpectedOptimizationDuration += sp;
    }
  }

  private update(): void {
    this.watcherPort.output('update', this.isCreatingSchedule, this.progress, this.progressStatus);
  }
  // endregion
}
