import { AllOrderGroupsQuery, AllOrderGroupsQueryVariables } from '@/graphql/graphQLServerApi';
import { AllOrderGroups } from '@/../graphql/server-api/queries/order-group.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllOrderGroupsQuery, AllOrderGroupsQueryVariables>({
      query: AllOrderGroups,
      variables: {},
    });
    return result.allOrderGroups;
  }
}
