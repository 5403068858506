import { PackingStyleTaskTypeDefault as PackingStyleTaskTypeDefaultApiTypes } from '~/framework/server-api/masters/packingStyleTaskTypeDefault';

import { PackingStyleCode } from '~/framework/domain/typeAliases';
export const packingStyle$getAllSymbol = Symbol('getAll');

export type PackingStyle = {
  [packingStyle$getAllSymbol]: IGetAllApi;
} & PackingStyleTaskTypeDefaultApiTypes;

export interface IGetAllApi {
  getAll(): Promise<IPackingStyleData[]>;
}

export interface IPackingStyleData {
  id: string;
  code: PackingStyleCode;
  name: string;
  defaultUnitName: string;
}
