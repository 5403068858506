
import Vue from 'vue';
import { google } from 'google-maps';
import { Maybe } from '~/framework/typeAliases';

export default Vue.extend({
  name: 'RGmapLoader',
  props: {},
  data(): {} {
    return {};
  },
  methods: {
    load(apiKey: string): Promise<google> {
      const loader = this.$refs.loader as Maybe<HTMLObjectElement>;
      const promise = new Promise<google>((resolve, reject) => {
        if (loader === undefined) {
          reject(new Error(`Could not find loader!`));
          return;
        }
        loader.onload = () => {
          const contentDocument = loader.contentDocument;
          const contentWindow = loader.contentWindow;
          if (contentDocument === null) {
            reject(new Error(`Could not find contentDocument!`));
            return;
          }
          if (contentWindow === null) {
            reject(new Error(`Could not find contentWindow!`));
            return;
          }
          const head = contentDocument.getElementsByTagName(`head`)[0];
          const script = contentDocument.createElement(`script`);
          script.src = `https://maps.google.com/maps/api/js?key=${apiKey}&language=ja`;
          head.appendChild(script);
          script.onload = () => {
            resolve((contentWindow as any).google);
          };
        };
        // TODO クソダサいのだが、object タグの内容をスクリプトから設定する事ができなかったのでこうしている
        // なにかいいやり方があれば修正
        loader.data = `/google-map/placeholder.html`;
      });
      return promise;
    },
  },
});
