import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Maybe } from '~/framework/typeAliases';
import { IOriginalCollectionEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalCollectionEntity';

export interface ICollectionEntityData {
  /**
   * 一意な ID
   */
  id: string;

  /**
   * ルート内での順番
   */
  index: number;

  /**
   * この収集の元となった受注番号
   */
  orderId: PseudoId;

  /**
   * 排出場出発時間
   * この時間は再計算で勝手に変更される可能性がある
   */
  generationSiteDepartureTime: number;

  /**
   * 排出場到着時間
   * この時間は再計算で勝手に変更される可能性がある
   */
  generationSiteArrivalTime: number;

  /**
   * 排出場到着前に休憩を取るかどうか
   */
  hasRestBeforeGenerationSiteArrival: boolean;

  /**
   * 順番固定
   */
  isFixedAssignment: boolean;
}

export interface ICollectionEntity<
  Original extends IOriginalCollectionEntity,
  Clone extends ICollectionEntity<Original, Clone>
> extends ICollectionEntityData {
  // route を見れば分かる情報なのだが、collection だけ見て情報を出したいので冗長だがあえて入れている

  /**
   * 編集前の状態
   */
  original: Maybe<Original>;

  /**
   * この回収の開始時間（排出場の時間とは限らない）
   * 回収と言いつつルート内の最初の回収であればルートの開始時間と一致する
   */
  startTime: number;

  /**
   * この回収の終了時間（排出場の時間とは限らない）
   * 回収と言いつつルート内の最後の回収であればルートの終了時間と一致する
   */
  endTime: number;

  /**
   * ルートの開始時間
   * この回収がルート内のどこにあろうと、ルートの開始時間が入る
   * この回収がルート内のどこにあるのかは、isFirstOfRoute などで判断する
   */
  routeStartTime: number;

  /**
   * ルートの終了時間
   * この回収がルート内のどこにあろうと、ルートの終了時間が入る
   * この回収がルート内のどこにあるのかは、isLastOfRoute などで判断する
   */
  routeEndTime: number;

  /**
   * ルート回収の最初の回収かどうか
   */
  isFirstOfRoute: boolean;

  /**
   * ルート回収の最後の回収かどうか
   */
  isLastOfRoute: boolean;

  /**
   * ドライバーの最初のルートの最初の回収かどうか
   */
  isFirstOfDriver: boolean;

  /**
   * ドライバーの最後のルートの最後の回収かどうか
   */
  isLastOfDriver: boolean;

  /**
   * 時間が不定になっているかどうか
   * そもそも回収単体では判別ができないので外部から設定する前提
   */
  isTimetableUnstable: boolean;

  /**
   * 前回の車庫到着時間
   * これがある場合は先頭の表示をマージしなければいけない
   */
  lastGarageSiteArrivalTime: Maybe<number>;

  /**
   * 最後の排出場出発時間
   * これがないと移動時間を先頭に付ける事ができない
   */
  lastGenerationSiteDepartureTime: Maybe<number>;

  /**
   * 最後の処分場出発時間
   */
  lastDisposalSiteDepartureTime: Maybe<number>;

  /**
   * 車庫出発時間
   */
  garageSiteDepartureTime: Maybe<number>;

  /**
   * 出発の車庫 ID
   */
  garageSiteDepartureId: Maybe<PseudoId>;

  /**
   * 拠点の ID
   */
  baseSiteId: Maybe<PseudoId>;

  /**
   * 拠点到着時間
   */
  baseSiteArrivalTime: Maybe<number>;

  /**
   * 拠点出発時間
   */
  baseSiteDepartureTime: Maybe<number>;

  /**
   * 処分場到着時間
   */
  disposalSiteArrivalTime: Maybe<number>;

  /**
   * 処分場出発時間
   */
  disposalSiteDepartureTime: Maybe<number>;

  /**
   * 車庫到着時間
   */
  garageSiteArrivalTime: Maybe<number>;

  /**
   * 出発の車庫と拠点の ID が同じかどうか
   * その場合は拠点も「車庫到着」「車庫出発」にまとめてしまう
   */
  isStartGarageSiteAndBaseSiteSame: boolean;

  /**
   * 排出場にいる時間
   */
  generationSiteDuration: number;

  /**
   * 処分場にいる時間
   */
  disposalSiteDuration: Maybe<number>;

  /**
   * この収集の時間内に休憩を取るかどうか
   */
  hasRest: boolean;

  /**
   * 排出場出発後に休憩を挟むかどうか
   * 最後の収集にしかつかない情報
   */
  hasRestAfterGenerationSiteDeparture: Maybe<boolean>;

  /**
   * 処分場出発後に休憩を挟むかどうか
   * 最後の収集にしかつかない情報
   */
  hasRestAfterDisposalSiteDeparture: Maybe<boolean>;

  /**
   * 前のルートで排出場の出発後に休憩を取っていた場合、それが終了した時間
   * 実質的には前のルートの endTime と一致する
   */
  lastRestAfterGenerationSiteDepartureEndTime: Maybe<number>;

  /**
   * 前のルートで処分場の出発後に休憩を取っていた場合、それが終了した時間
   * 実質的には前のルートの endTime と一致する
   */
  lastRestAfterDisposalSiteDepartureEndTime: Maybe<number>;

  /**
   * 外部から注入される情報をリセットする
   * 順番の入れ替えを行ったときなどに使う
   */
  resetProperties(): void;

  /**
   * 開始・終了時間を実情に合わせて更新する
   */
  updateStartAndEndTime(): void;

  /**
   * 車に乗った状態で二つ目以降のルートだったものを最初のルートにすると車に乗るタイミングが
   * なくなってしまい、開始時間と最初の排出場までの時間が空いてしまう。そのため、前回のアクション
   * が全くない場合は車庫出発時間を設定してしまう。また、拠点出発時間が設定されていたルートを最初に
   * 持ってきた場合も車庫出発がなく startTime がズレてしまうので調整する。
   */
  adjustStartTime(routeStartTime: number): void;

  /**
   * 上位のルートで車が不定になった場合に呼ばれる
   * @param isUnstable
   */
  setIsCarUnstable(isUnstable: boolean): void;

  /**
   * このオブジェクトの clone を返す
   */
  clone(): Clone;
}

export class CollectionEntity<Original extends IOriginalCollectionEntity>
  implements ICollectionEntity<Original, CollectionEntity<Original>>
{
  original: Maybe<Original>;
  startTime: number;
  endTime: number;
  isFirstOfDriver: boolean;
  isFirstOfRoute: boolean;
  isLastOfRoute: boolean;
  isLastOfDriver: boolean;
  id: string;
  index: number;
  orderId: PseudoId;
  generationSiteArrivalTime: number;
  generationSiteDepartureTime: number;
  hasRestBeforeGenerationSiteArrival: boolean;
  isFixedAssignment: boolean;

  isTimetableUnstable: boolean;
  routeStartTime: number;
  routeEndTime: number;
  lastGarageSiteArrivalTime: Maybe<number>;
  lastGenerationSiteDepartureTime: Maybe<number>;
  lastDisposalSiteDepartureTime: Maybe<number>;
  garageSiteDepartureId: Maybe<PseudoId>;
  garageSiteDepartureTime: Maybe<number>;
  baseSiteId: Maybe<PseudoId>;
  baseSiteArrivalTime: Maybe<number>;
  baseSiteDepartureTime: Maybe<number>;
  disposalSiteArrivalTime: Maybe<number>;
  disposalSiteDepartureTime: Maybe<number>;
  garageSiteArrivalTime: Maybe<number>;
  isStartGarageSiteAndBaseSiteSame: boolean;
  hasRest: boolean;
  hasRestAfterGenerationSiteDeparture: Maybe<boolean>;
  hasRestAfterDisposalSiteDeparture: Maybe<boolean>;
  lastRestAfterGenerationSiteDepartureEndTime: Maybe<number>;
  lastRestAfterDisposalSiteDepartureEndTime: Maybe<number>;

  get generationSiteDuration(): number {
    return this.generationSiteDepartureTime - this.generationSiteArrivalTime;
  }

  get disposalSiteDuration(): Maybe<number> {
    if (this.disposalSiteArrivalTime !== undefined && this.disposalSiteDepartureTime !== undefined) {
      return this.disposalSiteDepartureTime - this.disposalSiteArrivalTime;
    } else {
      return undefined;
    }
  }

  constructor(
    original: Maybe<Original>,
    id: string,
    index: number,
    orderId: PseudoId,
    generationSiteArrivalTime: number,
    generationSiteDepartureTime: number,
    hasRestBeforeGenerationSiteArrival: boolean,
    isFixedAssignment: boolean
  ) {
    this.original = original;
    this.id = id;
    this.index = index;
    this.orderId = orderId;
    this.generationSiteArrivalTime = generationSiteArrivalTime;
    this.generationSiteDepartureTime = generationSiteDepartureTime;
    this.hasRestBeforeGenerationSiteArrival = hasRestBeforeGenerationSiteArrival;
    this.isFixedAssignment = isFixedAssignment;

    // 以下は ICollectionEntity に固有の情報
    this.startTime = 0;
    this.endTime = 0;
    this.isFirstOfDriver = false;
    this.isFirstOfRoute = false;
    this.isLastOfRoute = false;
    this.isLastOfDriver = false;
    this.isTimetableUnstable = false;
    this.isStartGarageSiteAndBaseSiteSame = false;
    this.hasRest = false;
    this.hasRestAfterGenerationSiteDeparture = undefined;
    this.hasRestAfterDisposalSiteDeparture = undefined;
    this.lastRestAfterGenerationSiteDepartureEndTime = undefined;
    this.lastRestAfterDisposalSiteDepartureEndTime = undefined;
    this.routeStartTime = 0;
    this.routeEndTime = 0;
    this.lastGarageSiteArrivalTime = undefined;
    this.lastGenerationSiteDepartureTime = undefined;
    this.lastDisposalSiteDepartureTime = undefined;
    this.garageSiteDepartureTime = undefined;
    this.garageSiteDepartureId = undefined;
    this.baseSiteId = undefined;
    this.baseSiteArrivalTime = undefined;
    this.baseSiteDepartureTime = undefined;
    this.disposalSiteArrivalTime = undefined;
    this.disposalSiteDepartureTime = undefined;
    this.garageSiteArrivalTime = undefined;
  }

  resetProperties(): void {
    this.index = 0;
    this.startTime = 0;
    this.endTime = 0;
    this.isFirstOfDriver = false;
    this.isFirstOfRoute = false;
    this.isLastOfRoute = false;
    this.isLastOfDriver = false;
    this.isTimetableUnstable = false;
    this.isStartGarageSiteAndBaseSiteSame = false;
    this.hasRest = false;
    this.hasRestAfterGenerationSiteDeparture = undefined;
    this.hasRestAfterDisposalSiteDeparture = undefined;
    this.lastRestAfterGenerationSiteDepartureEndTime = undefined;
    this.lastRestAfterDisposalSiteDepartureEndTime = undefined;
    this.routeStartTime = 0;
    this.routeEndTime = 0;
    this.lastGarageSiteArrivalTime = undefined;
    this.garageSiteDepartureTime = undefined;
    this.garageSiteDepartureId = undefined;
    this.baseSiteId = undefined;
    this.baseSiteArrivalTime = undefined;
    this.baseSiteDepartureTime = undefined;
    this.lastGenerationSiteDepartureTime = undefined;
    this.lastDisposalSiteDepartureTime = undefined;
    this.disposalSiteArrivalTime = undefined;
    this.disposalSiteDepartureTime = undefined;
    this.garageSiteArrivalTime = undefined;
  }

  updateStartAndEndTime(): void {
    // 開始時間の調整
    this.startTime = this.generationSiteArrivalTime;
    if (this.baseSiteDepartureTime !== undefined) this.startTime = this.baseSiteDepartureTime;
    if (this.baseSiteArrivalTime !== undefined) this.startTime = this.baseSiteArrivalTime;
    if (this.garageSiteDepartureTime !== undefined) this.startTime = this.garageSiteDepartureTime;
    if (this.lastGenerationSiteDepartureTime !== undefined) this.startTime = this.lastGenerationSiteDepartureTime;
    if (this.lastDisposalSiteDepartureTime !== undefined) this.startTime = this.lastDisposalSiteDepartureTime;
    if (this.lastGarageSiteArrivalTime !== undefined) this.startTime = this.lastGarageSiteArrivalTime;
    if (this.isFirstOfRoute) this.startTime = this.routeStartTime;

    // 終了時間の調整
    if (this.generationSiteDepartureTime !== undefined) this.endTime = this.generationSiteDepartureTime;
    if (this.disposalSiteArrivalTime !== undefined) this.endTime = this.disposalSiteArrivalTime;
    if (this.disposalSiteDepartureTime !== undefined) this.endTime = this.disposalSiteDepartureTime;
    if (this.garageSiteArrivalTime !== undefined) this.endTime = this.garageSiteArrivalTime;
    if (this.isLastOfRoute) this.endTime = this.routeEndTime;
  }

  adjustStartTime(routeStartTime: number): void {
    if (this.index !== 0) {
      throw new Error(`Cannot call adjustStartTime of not first collection! index: ${this.index}`);
    }
    if (
      this.lastGenerationSiteDepartureTime !== undefined ||
      this.lastDisposalSiteDepartureTime !== undefined ||
      this.lastGarageSiteArrivalTime !== undefined ||
      this.garageSiteDepartureTime !== undefined ||
      this.baseSiteArrivalTime !== undefined ||
      this.baseSiteDepartureTime !== undefined
    ) {
      return;
    }
    this.garageSiteDepartureTime = routeStartTime;
  }

  setIsCarUnstable(_isUnstable: boolean) {
    // このクラスではひとまず何もしない
  }

  clone(): CollectionEntity<Original> {
    const clone = new CollectionEntity<Original>(
      this.original,
      this.id,
      this.index,
      this.orderId,
      this.generationSiteArrivalTime,
      this.generationSiteDepartureTime,
      this.hasRestBeforeGenerationSiteArrival,
      this.isFixedAssignment
    );
    clone.startTime = this.startTime;
    clone.endTime = this.endTime;
    clone.isFirstOfDriver = this.isFirstOfDriver;
    clone.isFirstOfRoute = this.isFirstOfRoute;
    clone.isLastOfRoute = this.isLastOfRoute;
    clone.isLastOfDriver = this.isLastOfDriver;
    clone.isTimetableUnstable = this.isTimetableUnstable;
    clone.isStartGarageSiteAndBaseSiteSame = this.isStartGarageSiteAndBaseSiteSame;
    clone.hasRest = this.hasRest;
    clone.hasRestAfterGenerationSiteDeparture = this.hasRestAfterGenerationSiteDeparture;
    clone.hasRestAfterDisposalSiteDeparture = this.hasRestAfterDisposalSiteDeparture;
    clone.lastRestAfterGenerationSiteDepartureEndTime = this.lastRestAfterGenerationSiteDepartureEndTime;
    clone.lastRestAfterDisposalSiteDepartureEndTime = this.lastRestAfterDisposalSiteDepartureEndTime;
    clone.routeStartTime = this.routeStartTime;
    clone.routeEndTime = this.routeEndTime;
    clone.lastGarageSiteArrivalTime = this.lastGarageSiteArrivalTime;
    clone.garageSiteDepartureTime = this.garageSiteDepartureTime;
    clone.garageSiteDepartureId = this.garageSiteDepartureId;
    clone.baseSiteId = this.baseSiteId;
    clone.baseSiteArrivalTime = this.baseSiteArrivalTime;
    clone.baseSiteDepartureTime = this.baseSiteDepartureTime;
    clone.lastGenerationSiteDepartureTime = this.lastGenerationSiteDepartureTime;
    clone.lastDisposalSiteDepartureTime = this.lastDisposalSiteDepartureTime;
    clone.disposalSiteArrivalTime = this.disposalSiteArrivalTime;
    clone.disposalSiteDepartureTime = this.disposalSiteDepartureTime;
    clone.garageSiteArrivalTime = this.garageSiteArrivalTime;
    return clone;
  }
}
