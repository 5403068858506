
import Vue, { PropType } from 'vue';
import { Id, Maybe } from '~/framework/typeAliases';
import {
  containerTypeSymbol,
  ContainerTypeApplicationService,
} from '~/framework/application/masters/container-type/containerTypeApplicationService';
import { ContainerType, IContainerType } from '~/components/common/r-container-types/containerType';
import {
  AggregatedContainerTypeEntity as IContainerTypeEntity,
  AggregatedContainerTypeEntity,
} from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { ICloseEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import RContainerType from '~/components/common/r-container-types/RContainerType.vue';
import {
  AdditionalInfoKeys,
  FormTargetParams,
  PageNames,
  RinEventNames,
} from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  containerTypeApplicationService: ContainerTypeApplicationService;
  isLoading: boolean;
  editingContainerTypeId: Maybe<Id>;
  containerTypes: IContainerType[];
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  UpdateLoad = 'update:load',
}

export default Vue.extend({
  name: 'RContainerTypes',
  components: {
    RContainerType,
  },
  props: {
    /**
     * 最新のデータを読み込ませたい時に true
     * ref でやってもいいがこちらの方が分かりやすそうなので
     */
    load: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    const containerTypeApplicationService = this.$context.applications.get(containerTypeSymbol);
    return {
      containerTypeApplicationService,
      isLoading: false,
      editingContainerTypeId: undefined,
      containerTypes: [],
      listenerDisposer: undefined,
    };
  },
  watch: {
    async load(value: boolean): Promise<void> {
      if (value) await this.loadContainerTypes();
    },
  },
  async mounted(): Promise<void> {
    const closeEventListenerDisposer = this.$context.panels.containerTypeFormPanel.closeFormEvent.on(
      this.onCloseContainerTypeForm
    );
    this.listenerDisposer = () => {
      closeEventListenerDisposer.dispose();
    };
    this.editingContainerTypeId = this.$context.panels.containerTypeFormPanel.editingEntity?.id;
    await this.loadContainerTypes();
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    async loadContainerTypes(): Promise<void> {
      if (this.isLoading) return;
      this.isLoading = true;
      const entities = await this.containerTypeApplicationService.getAll();
      this.setContainerTypes(entities);
      this.isLoading = false;
      this.$emit(EventTypes.UpdateLoad, false);
    },
    async onClickRegisterContainerType(): Promise<void> {
      this.$rinGtm.push(RinEventNames.OPEN_FORM, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CONTAINER_TYPE,
        [AdditionalInfoKeys.REFERRER]: PageNames.CONTAINER_TYPES,
      });
      await this.$context.panels.containerTypeFormPanel.open(undefined);
    },
    async onClickEditContainerType(containerType: IContainerType): Promise<void> {
      this.$rinGtm.push(RinEventNames.OPEN_FORM, {
        [AdditionalInfoKeys.CONTAINER_TYPE_ID]: containerType.entity.persistentId,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CONTAINER_TYPE,
        [AdditionalInfoKeys.REFERRER]: PageNames.CONTAINER_TYPES,
      });
      await this.$context.panels.containerTypeFormPanel.open(containerType.entity);
      this.editingContainerTypeId = this.$context.panels.containerTypeFormPanel.editingEntity?.id;
    },
    onCloseContainerTypeForm(args: ICloseEntityFormArgs<IContainerTypeEntity>): void {
      if (args.createdEntity !== undefined) {
        this.addContainerType(args.createdEntity);
      } else if (args.updatedEntity !== undefined) {
        this.updateContainerType(args.updatedEntity);
      }
      this.editingContainerTypeId = undefined;
    },
    addContainerType(containerType: AggregatedContainerTypeEntity): void {
      this.containerTypes.push(new ContainerType(containerType));
      this.sort();
      this.updateIsSamePackage();
    },
    updateContainerType(_containerType: AggregatedContainerTypeEntity): void {
      this.sort();
      this.updateIsSamePackage();
    },
    setContainerTypes(entities: AggregatedContainerTypeEntity[]): void {
      this.containerTypes = entities.map((entity) => new ContainerType(entity));
      this.sort();
      this.updateIsSamePackage();
    },
    sort(): void {
      this.containerTypes.sort(
        (a, b) =>
          a.entity.packingStyle.code.localeCompare(b.entity.packingStyle.code) ||
          a.entity.name.localeCompare(b.entity.name)
      );
    },
    updateIsSamePackage(): void {
      let lastPackingStyleId;
      for (const containerType of this.containerTypes) {
        containerType.isSamePackingStyle = lastPackingStyleId === containerType.entity.packingStyle.id;
        lastPackingStyleId = containerType.entity.packingStyle.id;
      }
    },
  },
});
