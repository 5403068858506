
import Vue, { PropType } from 'vue';
import RResultDialog from './RResultDialog.vue';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

enum EventTypes {
  ClickClose = 'click:close',
  ClickCreateSchedule = 'click:create-schedule',
}

type DataType = {
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'ROtherOrderDialog',
  components: {
    RResultDialog,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data(): DataType {
    return {
      listenerDisposer: undefined,
    };
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    onClickCloseButton(): void {
      this.$emit(EventTypes.ClickClose);
    },
    onClickCreateScheduleButton(): void {
      this.$emit(EventTypes.ClickCreateSchedule);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.$emit(EventTypes.ClickClose);
      }
      context.stop();
    },
  },
});
