import { OrderSchedulingPriority } from '../domain/typeAliases';

export interface ISchedulingPriorityOption {
  value: OrderSchedulingPriority;
  label: string;
}

/**
 * 高速利用の選択肢
 */
export const schedulePriorityOptions: ISchedulingPriorityOption[] = [
  { value: OrderSchedulingPriority.High, label: 'はい' },
  { value: OrderSchedulingPriority.None, label: 'いいえ' },
];
