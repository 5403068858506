
import Vue, { PropType } from 'vue';
import { Maybe, PersistentId, Seconds, CssStyles } from '~/framework/typeAliases';
import { ICollectablePeriodTemplateOption } from '~/framework/view-models/collectablePeriodTemplateOption';
import { hhMmToSecs, secsToHhMm, isNextDay, validateHardLimitTime } from '~/framework/services/date-time/date-time';
import { DaySeconds } from '~/framework/constants';

type DataType = {
  collectableDistinctTimeErrorMessage: Maybe<string>;
  collectablePeriodStartErrorMessage: Maybe<string>;
  collectablePeriodEndErrorMessage: Maybe<string>;
};

enum EventTypes {
  UpdateCollectablePeriodTemplateId = 'update:collectable-period-template-id',
  UpdateCollectableDistinctTime = 'update:collectable-distinct-time',
  UpdateCollectablePeriodStart = 'update:collectable-period-start',
  UpdateCollectablePeriodEnd = 'update:collectable-period-end',
}

export default Vue.extend({
  name: 'RCollectablePeriodInput',
  props: {
    label: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    isTimeRequired: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    templateClearable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    isCollectableTimeDistinct: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectablePeriodTemplateDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectableDistinctTimeDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectablePeriodStartDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectablePeriodEndDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    collectablePeriodTemplateId: {
      type: String as PropType<Maybe<PersistentId>>,
      required: false,
      default: undefined,
    },
    collectablePeriodTemplates: {
      type: Array as PropType<ICollectablePeriodTemplateOption[]>,
      required: true,
    },
    // NOTE: 到着時間テンプレートの form のデザインを調整できる
    templateStyles: {
      type: Object as PropType<CssStyles>,
      required: false,
      default: () => {
        return {
          'max-width': '146px',
        };
      },
    },
    // NOTE: 個別に time-picker の横幅を調整できる
    periodTimeWidth: {
      type: String as PropType<string>,
      required: false,
      default: '43px',
    },
    collectableDistinctTime: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodStart: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodEnd: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      required: false,
      default() {
        return [];
      },
    },
  },
  data(): DataType {
    return {
      collectableDistinctTimeErrorMessage: undefined,
      collectablePeriodStartErrorMessage: undefined,
      collectablePeriodEndErrorMessage: undefined,
    };
  },
  computed: {
    collectableDistinctTimeString(): Maybe<string> {
      if (this.collectableDistinctTime === undefined) return undefined;
      return secsToHhMm(this.collectableDistinctTime);
    },
    collectablePeriodStartString(): Maybe<string> {
      if (this.collectablePeriodStart === undefined) return undefined;
      return secsToHhMm(this.collectablePeriodStart);
    },
    collectablePeriodEndString(): Maybe<string> {
      if (this.collectablePeriodEnd === undefined) return undefined;
      return secsToHhMm(this.collectablePeriodEnd);
    },
  },
  methods: {
    isNextDay,
    validateHardLimitTime,
    onCollectablePeriodTemplateChange(args: any): void {
      this.$emit(EventTypes.UpdateCollectablePeriodTemplateId, args);
    },
    onCollectableDistinctTimeStringChange(value: string): void {
      const secs = hhMmToSecs(value);
      this.$emit(EventTypes.UpdateCollectableDistinctTime, secs);
    },
    onCollectablePeriodStartStringChange(value: Maybe<string>): void {
      if (value === undefined) return;

      const start = hhMmToSecs(value);

      // start > end の場合 end = start にする
      if (this.collectablePeriodEnd && start > this.collectablePeriodEnd) {
        this.$emit(EventTypes.UpdateCollectablePeriodEnd, start);
      }
      this.$emit(EventTypes.UpdateCollectablePeriodStart, start);
    },
    onCollectablePeriodEndStringChange(value: Maybe<string>): void {
      if (value === undefined) return;

      const end = hhMmToSecs(value);
      // start > end の場合 start = end にする
      if (this.collectablePeriodStart && this.collectablePeriodStart > end) {
        this.$emit(EventTypes.UpdateCollectablePeriodStart, end);
      }
      this.$emit(EventTypes.UpdateCollectablePeriodEnd, end);
    },
    // NOTE: 過去に遡ることはないため、 endTime だけハードリミットチェックできればよい
    onUpdateCollectablePeriodEndError(value: Maybe<string>): void {
      if (value === undefined || this.collectablePeriodStart === undefined) {
        return;
      }

      this.collectablePeriodEndErrorMessage = this.checkErrors(this.collectablePeriodStart, hhMmToSecs(value));
    },
    checkErrors(start: number, end: number): Maybe<string> {
      // NOTE: startTime > endTime の場合は日跨ぎ指定とみなす
      if (isNextDay(start, end)) {
        const calculatedEndTime = end + DaySeconds;

        if (!validateHardLimitTime(calculatedEndTime)) {
          return '翌日12時以降は指定できません';
        }
      }
      return undefined;
    },
  },
});
