
import Vue, { PropType } from 'vue';
import markdownit from 'markdown-it';
import { IAnnouncementData } from '~/framework/server-api/announcement/announcement';

export default Vue.extend({
  name: 'RAnnouncement',
  props: {
    announcement: {
      type: Object as PropType<IAnnouncementData>,
      required: true,
    },
  },
  computed: {
    linkifiedBodies(): string[] {
      // NOTE: リンクの有効化
      const md = markdownit({ linkify: true });

      // NOTE: メールアドレスのリンク化を無効化
      md.linkify.set({ fuzzyEmail: false });

      // NOTE: 改行コードごとに段落を分ける
      const paragraphs: string[] = this.announcement.body.split('\r\n');

      return paragraphs.map((paragraph) =>
        // NOTE: リンクを新しいタブで開くようにする
        // 下記リンクのような方法もあるが、こちらの方がシンプルであるため当分はこちらでよい判断。
        // プロジェクト全体で markdown を使う方針になるなら、 nuxt.config.js に plugin を追加したほうがよい。
        // ref: https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
        md.render(paragraph).replace(/<a/g, '<a target="_blank" rel="noopener noreferrer"')
      );
    },
  },
  watch: {},
  methods: {},
});
