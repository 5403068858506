import { IUserData } from '~/framework/server-api/masters/user';
import { Exception } from '~/framework/core/exception';
import { NuxtErrorStatusCodes } from '~/nuxt';

export const authentication$currentSessionSymbol = Symbol('authentication$currentSessionSymbol');

export interface ICurrentSessionApi {
  [authentication$currentSessionSymbol]: void;
  getCurrentSession(): Promise<ISessionData>;
}

export interface ISessionData {
  officeId: string;
  officeName: string;
  user: IUserData;
}

/**
 * 未認証時の例外
 */
export class UnauthenticatedException extends Exception {
  readonly name = 'UnauthenticatedException';
}

/**
 * オフィス ID がズレてるやつの例外
 */
export class InconsistentSessionException extends Exception {
  readonly name = 'InconsistentSessionException';
  readonly statusCode = NuxtErrorStatusCodes.InconsistentSessionException;
}
