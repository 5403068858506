import {
  CreateErpOrder,
  UpdateErpOrder,
  DeleteErpOrderByOrderId,
} from '@/../graphql/server-api/mutations/erp-order.gql';
import { ErpOrderByOrderId } from '@/../graphql/server-api/queries/erp-order.gql';
import { Id, Maybe } from '~/framework/typeAliases';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  CreateErpOrderMutation,
  CreateErpOrderMutationVariables,
  ErpOrderByOrderIdQuery,
  ErpOrderByOrderIdQueryVariables,
  UpdateErpOrderMutation,
  UpdateErpOrderMutationVariables,
  DeleteErpOrderByOrderIdMutation,
  DeleteErpOrderByOrderIdMutationVariables,
} from '~/graphql/graphQLServerApi';
import { IErpClientData } from '~/framework/server-api/masters/erpClient';
import { erpOrder$createErpOrderSymbol } from '~/framework/server-api/schedule/order/createErpOrder';
import { erpOrder$erpOrderByOrderIdSymbol } from '~/framework/server-api/schedule/order/erpOrderByOrder';
import { erpOrder$updateErpOrderSymbol } from '~/framework/server-api/schedule/order/updateErpOrder';
import { erpOrder$deleteErpOrderByOrderIdSymbol } from '~/framework/server-api/schedule/order/deleteErpOrderByOrderId';

export interface ICreateData {
  orderId: Id;
  transportationClientId: Id;
  withDisposalItemFromScheduling: boolean;
  note: Maybe<string>;
  erpOrderItems: {
    generationSiteTaskId: Maybe<Id>;
    irregularTaskId: Maybe<Id>;
    apparentQuantity: Maybe<number>;
    apparentQuantityUnit: Maybe<string>;
  }[];
  isRecurring: Maybe<boolean>;
}

export interface IUpdateData {
  id: Id;
  orderId: Id;
  transportationClientId: Id;
  withDisposalItemFromScheduling: boolean;
  note: Maybe<string>;
  erpOrderItems: {
    generationSiteTaskId: Maybe<Id>;
    irregularTaskId: Maybe<Id>;
    apparentQuantity: Maybe<number>;
    apparentQuantityUnit: Maybe<string>;
  }[];
  includeFollowingRecurringOrders: Maybe<boolean>;
}

export interface IResultData {
  id: Id;
  orderId: Id;
  transportationClient: IErpClientData;
  withDisposalItemFromScheduling: boolean;
  note: Maybe<string>;
  erpOrderItems: {
    id: Id;
    generationSiteTaskId: Maybe<Id>;
    irregularTaskId: Maybe<Id>;
    apparentQuantity: Maybe<number>;
    apparentQuantityUnit: Maybe<string>;
  }[];
}

export class CreateErpOrderApi extends GraphqlApiBase {
  [erpOrder$createErpOrderSymbol] = undefined;

  async createErpOrder(data: ICreateData): Promise<IResultData> {
    const result = await this.mutate<CreateErpOrderMutation, CreateErpOrderMutationVariables>({
      mutation: CreateErpOrder,
      variables: { erpOrder: data },
    });
    return result.createErpOrder;
  }
}

export class UpdateErpOrderApi extends GraphqlApiBase {
  [erpOrder$updateErpOrderSymbol] = undefined;

  async updateErpOrder(data: IUpdateData): Promise<IResultData> {
    const result = await this.mutate<UpdateErpOrderMutation, UpdateErpOrderMutationVariables>({
      mutation: UpdateErpOrder,
      variables: { erpOrder: data },
    });
    return result.updateErpOrder;
  }
}

export class GetErpOrderByOrderIdApi extends GraphqlApiBase {
  [erpOrder$erpOrderByOrderIdSymbol] = undefined;

  async getErpOrderByOrderId(orderId: Id): Promise<Maybe<IResultData>> {
    const result = await this.query<ErpOrderByOrderIdQuery, ErpOrderByOrderIdQueryVariables>({
      query: ErpOrderByOrderId,
      variables: { orderId },
    });

    return result.erpOrderByOrderId;
  }
}

export class DeleteErpOrderByOrderIdApi extends GraphqlApiBase {
  [erpOrder$deleteErpOrderByOrderIdSymbol] = undefined;

  async deleteErpOrderByOrderId(orderId: Id, includeFollowingRecurringOrders: Maybe<boolean>): Promise<Id> {
    const result = await this.query<DeleteErpOrderByOrderIdMutation, DeleteErpOrderByOrderIdMutationVariables>({
      query: DeleteErpOrderByOrderId,
      variables: { orderId, includeFollowingRecurringOrders },
    });

    return result.deleteErpOrderByOrderId;
  }
}
