import { StoredMapperBase } from '~/framework/core/mapper';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { IAttendanceData } from '~/framework/server-api/masters/attendance';

export class AttendanceMapper extends StoredMapperBase<IAttendanceData, AttendanceEntity> {
  protected store: AttendanceStore;

  constructor(store: AttendanceStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IAttendanceData): AttendanceEntity {
    return new AttendanceEntity(data);
  }

  protected updateWithData(data: IAttendanceData, entity: AttendanceEntity): void {
    entity.date = data.date;
  }
}
