
import Vue, { PropType } from 'vue';

enum EventTypes {
  Remove = 'remove',
}

/**
 * フォームに入力したファイルを表示するためのComponent
 */
export default Vue.extend({
  name: 'RUploadingFile',
  props: {
    files: {
      type: Array as PropType<File[]>,
      required: true,
    },
  },
  methods: {
    onRemove(filename: string): void {
      this.$emit(EventTypes.Remove, filename);
    },
  },
});
