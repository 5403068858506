import { MilliSeconds } from '~/framework/typeAliases';

/**
 * msecs 以上で jitter の変動幅を持って待つ
 *
 * @param msecs 最低限待ちたい時間
 * @param jitter 変動幅
 */
export const wait = (msecs: MilliSeconds, jitter: MilliSeconds = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, msecs + Math.random() * jitter);
  });
};
