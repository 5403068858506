
import Vue, { PropType, VNode } from 'vue';
import { VInput, VLabel } from 'vuetify/lib';
import { CssClasses, Maybe, Nullable } from '~/framework/typeAliases';

// HACK Vuetify の各コンポーネントの型定義が Component になってしまっていて劣化しているので
// この様な形の補完は致し方ないのかなと思っているが、いい方法があれば教えてほしい
type VueType = typeof Vue;
type VInputType = {
  props: {
    label: {
      type: PropType<string>;
    };
    dark: {
      type: PropType<boolean | null>;
    };
    disabled: {
      type: PropType<boolean>;
    };
  };
  data: {
    isFocused: boolean;
  };
  computed: {
    validationState(): string | undefined;
    computedId(): string;
  };
  methods: {
    reset(): void;
  };
  options: Record<string, any>;
};

const baseVInput = Vue.extend(VInput as VueType & VInputType);

export default baseVInput.extend({
  name: 'RVInput',
  props: {
    hideLabel: {
      type: Boolean as PropType<Maybe<boolean>>,
      required: false,
      default: undefined,
    },
    appendToSublabel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    hasLabel(): boolean {
      return !!(this.$slots.label || this.label);
    },
    showLabel(): boolean {
      if (this.hideLabel !== undefined) return !this.hideLabel;
      return this.hasLabel;
    },
    labelValue(): boolean {
      return true;
    },
    classes(): CssClasses {
      return {
        ...(VInput as any).options.computed.classes.call(this),
        'r-v-input': true,
        'r-v-input--with-label': this.showLabel,
        'r-v-input--append-to-sublabel': this.appendToSublabel,
      };
    },
  },
  methods: {
    genLabel(): Nullable<VNode> {
      if (!this.showLabel) return null;
      const data = {
        props: {
          absolute: true,
          color: this.validationState,
          dark: this.dark,
          disabled: this.disabled,
          focused: this.isFocused || !!this.validationState,
          for: this.computedId,
          value: this.labelValue,
        },
      };
      return this.$createElement(VLabel, data, this.$slots.label || this.label);
    },
  },
});
