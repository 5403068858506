
import Vue, { PropType } from 'vue';
import { add, isSameDay } from 'date-fns';
import { DayOfWeek, OrderRecurringSettingsType, WeekdayOrdinal } from '~/framework/domain/typeAliases';
import { formatDateForField } from '~/framework/services/date/date';
import {
  buildDefault,
  IRecurringOrderSettings,
  sortDaysOfMonth,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettings';
import { Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import {
  DialogMode,
  RecurringOrderSettingsDialogValue,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettingsDialogValue';
import {
  IWeekdayOrdinalOption,
  weekdayOrdinalOptions,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/weekdayOrdinal';
import {
  IDayOfWeekOption,
  daysOfWeekOptions,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/dayOfWeek';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import {
  ICollectablePeriodTemplateOption,
  DistinctTimeOptionId,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import RDateCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RDateCollectablePeriodInput.vue';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import {
  HideEventNames as HideTooltipEventNames,
  ShowEventNames as ShowTooltipEventNames,
} from '~/components/common/r-v-tooltip/tooltip';
import { ensure } from '~/framework/core/value';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

type RecurringTypeItem = {
  text: string;
  value: OrderRecurringSettingsType;
};

type DataType = {
  isActive: boolean;
  dateValue: Date;
  collectableDistinctTimeValue: Maybe<Seconds>;
  collectablePeriodStartValue: Maybe<Seconds>;
  collectablePeriodEndValue: Maybe<Seconds>;
  collectablePeriodTemplateIdValue: Maybe<PersistentId>;
  collectablePeriodTemplateNameValue: Maybe<String>;
  recurringTypeItems: RecurringTypeItem[];
  weekdayOrdinalOptions: IWeekdayOrdinalOption[];
  daysOfWeekOptions: IDayOfWeekOption[];
  endDateTypeItems: any[];
  innerSettings: IRecurringOrderSettings;
  mode: DialogMode;
  endDateType: boolean;
  DialogMode: typeof DialogMode;
  listenerDisposer: Maybe<() => void>;
  ShowTooltipEventNames: typeof ShowTooltipEventNames;
  HideTooltipEventNames: typeof HideTooltipEventNames;
};

enum EventTypes {
  Update = 'update',
  ClickClose = 'click:close',
  ClickComplete = 'click:complete',
  UpdateDate = 'update:date',
  UpdateCollectablePeriodTemplateId = 'update:collectable-period-template-id',
  UpdateCollectablePeriodTemplateName = 'update:collectable-period-template-name',
  UpdateCollectableDistinctTime = 'update:collectable-distinct-time',
  UpdateCollectablePeriodStart = 'update:collectable-period-start',
  UpdateCollectablePeriodEnd = 'update:collectable-period-end',
}

export default Vue.extend({
  name: 'RRecurringOrderSettingsDialog',
  components: {
    RDateCollectablePeriodInput,
  },
  model: {
    prop: 'value',
    event: EventTypes.Update,
  },
  props: {
    /**
     * value に値を詰めるとダイアログが開く
     */
    value: {
      type: Object as PropType<Maybe<RecurringOrderSettingsDialogValue>>,
      required: false,
      default: undefined,
    },
    collectablePeriodTemplateId: {
      type: String as PropType<Maybe<PersistentId>>,
      required: false,
      default: undefined,
    },
    collectablePeriodTemplateName: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    collectablePeriodTemplates: {
      type: Array as PropType<ICollectablePeriodTemplateOption[]>,
      required: true,
    },
    collectablePeriodTemplateMap: {
      type: Map as PropType<Map<PersistentId, CollectablePeriodTemplateEntity>>,
      required: true,
    },
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    collectableDistinctTime: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodStart: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodEnd: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      isActive: false,
      dateValue: this.date,
      collectableDistinctTimeValue: this.collectableDistinctTime,
      collectablePeriodStartValue: this.collectablePeriodStart,
      collectablePeriodEndValue: this.collectablePeriodEnd,
      collectablePeriodTemplateIdValue: this.collectablePeriodTemplateId,
      collectablePeriodTemplateNameValue: this.collectablePeriodTemplateName,
      recurringTypeItems: [
        { text: '日ごと', value: OrderRecurringSettingsType.Daily },
        { text: '週ごと', value: OrderRecurringSettingsType.Weekly },
        { text: 'カ月ごと', value: OrderRecurringSettingsType.Monthly },
      ],
      weekdayOrdinalOptions,
      daysOfWeekOptions,
      endDateTypeItems: [
        { label: 'なし', value: false },
        { label: 'あり', value: true },
      ],
      innerSettings: buildDefault(new Date()),
      endDateType: false,
      mode: DialogMode.New,
      DialogMode,
      listenerDisposer: undefined,
      ShowTooltipEventNames,
      HideTooltipEventNames,
    };
  },
  computed: {
    isTypeWeekly(): boolean {
      return this.innerSettings.type === OrderRecurringSettingsType.Weekly;
    },
    isTypeMonthly(): boolean {
      return this.innerSettings.type === OrderRecurringSettingsType.Monthly;
    },
    minEndDate(): Date {
      return add(this.innerSettings.startAt, { days: 1 });
    },
    isCompleteButtonDisabled(): boolean {
      if (this.isCollectableTimeDistinct) {
        if (this.collectableDistinctTimeValue === undefined) return true;
        if (this.innerSettings.daysOfMonthDuplicatesExist) return true;

        return false;
      }
      if (this.collectablePeriodStartValue === undefined || this.collectablePeriodEndValue === undefined) return true;
      if (this.innerSettings.daysOfMonthDuplicatesExist) return true;

      return false;
    },
    isDialogModeNew(): boolean {
      return this.mode === DialogMode.New;
    },
    isDialogModeEdit(): boolean {
      return this.mode === DialogMode.Edit;
    },
    isDateEdited(): boolean {
      return !isSameDay(this.dateValue, this.date);
    },
    isCollectableTimeDistinct(): boolean {
      return this.collectablePeriodTemplateIdValue === DistinctTimeOptionId;
    },
    /**
     * 同じ曜日の第４と月末の両方が入力されている場合のメッセージ表示
     */
    displayWeekdayOrdinalLastMessage(): boolean {
      if (this.innerSettings.daysOfMonthInput === undefined) return false;

      const selectedDaysOfWeekForWeekdayOrdinalFourth: DayOfWeek[] = [];
      const selectedDaysOfWeekForWeekdayOrdinalLast: DayOfWeek[] = [];

      this.innerSettings.daysOfMonthInput.forEach((dayOfMonth) => {
        if (dayOfMonth.weekdayOrdinal === WeekdayOrdinal.Fourth && dayOfMonth.dayOfWeek)
          selectedDaysOfWeekForWeekdayOrdinalFourth.push(dayOfMonth.dayOfWeek);
        if (dayOfMonth.weekdayOrdinal === WeekdayOrdinal.Last && dayOfMonth.dayOfWeek)
          selectedDaysOfWeekForWeekdayOrdinalLast.push(dayOfMonth.dayOfWeek);
      });

      const commonDaysOfWeek = selectedDaysOfWeekForWeekdayOrdinalFourth.filter((value) =>
        selectedDaysOfWeekForWeekdayOrdinalLast.includes(value)
      );

      return commonDaysOfWeek.length > 0;
    },
  },
  watch: {
    value: {
      handler(value: Maybe<RecurringOrderSettingsDialogValue>): void {
        if (value === undefined) {
          this.isActive = false;
        } else {
          this.isActive = true;
          this.dateValue = this.date;
          this.collectableDistinctTimeValue = this.collectableDistinctTime;
          this.collectablePeriodStartValue = this.collectablePeriodStart;
          this.collectablePeriodEndValue = this.collectablePeriodEnd;
          this.collectablePeriodTemplateIdValue = this.collectablePeriodTemplateId;
          this.collectablePeriodTemplateNameValue = this.collectablePeriodTemplateName;
          this.innerSettings = value.settings.clone();
          this.mode = value.mode;

          if (this.mode === DialogMode.Edit) this.innerSettings.daysOfMonth?.sort(sortDaysOfMonth);
        }
      },
      immediate: true,
    },
    innerSettings: {
      handler(value: IRecurringOrderSettings): void {
        this.endDateType = value.endAt !== undefined;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    formatDateForField,
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === undefined) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickComplete(): void {
      if (this.isDateEdited) this.$emit(EventTypes.UpdateDate, this.dateValue);

      if (this.collectablePeriodTemplateId !== this.collectablePeriodTemplateIdValue)
        this.$emit(EventTypes.UpdateCollectablePeriodTemplateId, this.collectablePeriodTemplateIdValue);

      if (this.collectablePeriodTemplateName !== this.collectablePeriodTemplateNameValue)
        this.$emit(EventTypes.UpdateCollectablePeriodTemplateName, this.collectablePeriodTemplateNameValue);

      if (this.collectableDistinctTime !== this.collectableDistinctTimeValue)
        this.$emit(EventTypes.UpdateCollectableDistinctTime, this.collectableDistinctTimeValue);

      if (this.collectablePeriodStart !== this.collectablePeriodStartValue)
        this.$emit(EventTypes.UpdateCollectablePeriodStart, this.collectablePeriodStartValue);

      if (this.collectablePeriodEnd !== this.collectablePeriodEndValue)
        this.$emit(EventTypes.UpdateCollectablePeriodEnd, this.collectablePeriodEndValue);

      if (this.isDialogModeEdit && this.isDateEdited) {
        // 繰り返し受注の編集で、日付を変えた場合は、繰り返し受注から外れる。単一の受注にする。
        this.$emit(EventTypes.ClickComplete, undefined);
      } else {
        this.innerSettings.setDaysOfMonth();
        this.$emit(EventTypes.ClickComplete, this.innerSettings);
      }

      // ダイアログを閉じる
      this.$emit(EventTypes.Update, undefined);
    },
    onClickClose(): void {
      this.$emit(EventTypes.Update, undefined);
      this.$emit(EventTypes.ClickClose);
    },
    onClickReadonlyDayOfWeek(): void {
      this.$context.snackbar.warning('繰り返しの開始日の曜日は選択解除できません');
    },
    onChangeEndDateType(value: boolean): void {
      if (value === false) this.innerSettings.endAt = undefined;
      else this.innerSettings.endAt = new Date(this.minEndDate);
    },
    onChangeIncludeNationalHolidays(value: boolean): void {
      // NOTE value そのまま使うと null になるので
      this.innerSettings.includeNationalHolidays = !!value;
    },
    onChangeStep(value: string | number): void {
      // こうしておかないと空が入力できてしまう
      if (value === '') this.innerSettings.step = 1;
    },
    revertDateToInitialValue(): void {
      this.dateValue = this.date;
    },
    onChangeDate(): void {
      if (this.mode === DialogMode.New) {
        this.resetInnerSettings();
      }
    },
    resetInnerSettings(): void {
      const recurringOrderSettingsDialogValue = new RecurringOrderSettingsDialogValue(
        buildDefault(this.dateValue),
        this.mode
      );
      this.innerSettings = recurringOrderSettingsDialogValue.settings.clone();

      this.$context.snackbar.warning('日付が変更されたため、繰り返しの設定がリセットされました。');
    },
    addMonthlyRecurringSetting(): void {
      ensure(this.innerSettings.daysOfMonthInput);
      this.innerSettings.daysOfMonthInput.push({
        weekdayOrdinal: undefined,
        dayOfWeek: undefined,
      });
      this.innerSettings.updateDuplicateDaysOfMonth();
    },
    removeMonthlyRecurringSetting(index: number): void {
      ensure(this.innerSettings.daysOfMonthInput);
      this.innerSettings.daysOfMonthInput.splice(index, 1);
      this.innerSettings.updateDuplicateDaysOfMonth();
    },
    async onChangeDayOfMonth() {
      // Ensure v-model is updated.
      await this.$nextTick();
      this.innerSettings.updateDuplicateDaysOfMonth();
    },
  },
});
