
import Vue, { PropType } from 'vue';
import { ISnackbarData } from '~/framework/view-models/snackbarData';
import { CssClasses } from '~/framework/typeAliases';
import { SnackbarListPosition } from '~/components/common/r-snackbar-list/snackbarList';

export default Vue.extend({
  name: 'RSnackbarList',
  props: {
    position: {
      type: String as PropType<SnackbarListPosition>,
      default: SnackbarListPosition.TopRight,
      required: false,
    },
    snackbars: {
      type: Array as PropType<ISnackbarData[]>,
      required: true,
    },
  },
  computed: {
    classes(): CssClasses {
      return {
        'r-default-layout__snackbars--top-right': this.position === SnackbarListPosition.TopRight,
        'r-default-layout__snackbars--bottom-right': this.position === SnackbarListPosition.BottomRight,
      };
    },
  },
  methods: {
    onSnackbarInput(id: string, value: boolean) {
      this.$emit('input', id, value, this.position);
    },
  },
});
