
import Vue, { PropType } from 'vue';
import { IOrderRoutingGroup } from '~/framework/server-api/schedule/order/orderRoutingGroup';

export default Vue.extend({
  name: 'RScheduleOrderRoutingGroup',
  props: {
    routingGroup: {
      type: Object as PropType<IOrderRoutingGroup>,
      required: true,
    },
  },
  computed: {
    routingGroupCount(): string {
      return this.routingGroup.orderIds.length.toString();
    },
  },
});
