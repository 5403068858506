
import Vue, { PropType } from 'vue';
import { UploadedFile } from '~/graphql/graphQLServerApi';

enum EventTypes {
  Change = 'change',
}

/**
 * 既にアップロード済みのファイルをFormで表示するためのComponent
 */
export default Vue.extend({
  name: 'RUploadedFile',
  model: {
    event: EventTypes.Change,
    prop: 'filenamesToRemove',
  },
  props: {
    files: {
      type: Array as PropType<UploadedFile[]>,
      required: true,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data: (): { filenamesToRemove: string[] } => {
    return { filenamesToRemove: [] };
  },
  computed: {
    activeFiles() {
      return this.files.filter((file) => !this.filenamesToRemove.includes(file.filename));
    },
  },
  methods: {
    onRemove(filename: string): void {
      this.filenamesToRemove.push(filename);
      this.$emit(EventTypes.Change, this.filenamesToRemove);
    },
  },
});
