
import Vue from 'vue';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { required } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import {
  collectablePeriodTemplateSymbol,
  CollectablePeriodTemplateApplicationService,
} from '~/framework/application/masters/collectable-period-template/collectablePeriodTemplateApplicationService';
import {
  collectablePeriodTemplateDefaultOptions,
  ICollectablePeriodTemplateDefaultOption,
} from '~/framework/view-models/collectablePeriodTemplateDefaultOption';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

export enum FormMode {
  Register,
  Edit,
}

type InitialFormValues = {
  name: Maybe<string>;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  note: Maybe<string>;
  isDefault: boolean;
};

class CollectablePeriodTemplateForm {
  title: string;
  collectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>;
  createCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>;
  updatedCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>;
  isFormValid: boolean = false;
  isRegistering: boolean;
  formMode: FormMode;
  rules: { [key: string]: ValidationRule };

  initialValues: InitialFormValues;

  name: Maybe<string>;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  note: Maybe<string>;
  isDefault: boolean;
  collectablePeriodTemplateDefaultOptions: Array<ICollectablePeriodTemplateDefaultOption>;
  defaultCollectablePeriodTemplate: CollectablePeriodTemplateEntity;

  get isDirty(): boolean {
    return (
      this.name !== this.initialValues.name ||
      this.collectablePeriodStart !== this.initialValues.collectablePeriodStart ||
      this.collectablePeriodEnd !== this.initialValues.collectablePeriodEnd ||
      this.note !== this.initialValues.note ||
      this.isDefault !== this.initialValues.isDefault
    );
  }

  constructor(
    collectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>,
    defaultCollectablePeriodTemplate: CollectablePeriodTemplateEntity
  ) {
    // フォームに関係する値を設定
    this.collectablePeriodTemplate = collectablePeriodTemplate;
    this.createCollectablePeriodTemplate = undefined;
    this.updatedCollectablePeriodTemplate = undefined;
    this.formMode = this.collectablePeriodTemplate === undefined ? FormMode.Register : FormMode.Edit;
    this.title = this.formMode === FormMode.Register ? '到着時間の登録' : '到着時間の編集';
    this.initialValues = this.getInitialFormValues(this.collectablePeriodTemplate);
    this.name = this.initialValues.name;
    this.collectablePeriodStart = this.initialValues.collectablePeriodStart;
    this.collectablePeriodEnd = this.initialValues.collectablePeriodEnd;
    this.note = this.initialValues.note;
    this.isDefault = this.initialValues.isDefault;
    this.collectablePeriodTemplateDefaultOptions = collectablePeriodTemplateDefaultOptions;
    this.defaultCollectablePeriodTemplate = defaultCollectablePeriodTemplate;
    this.isRegistering = false;

    this.rules = { required };
  }

  private getInitialFormValues(collectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>): InitialFormValues {
    return collectablePeriodTemplate === undefined
      ? this.getDefaultInitialFormValues()
      : this.getInitialFormValuesByCollectablePeriodTemplate(collectablePeriodTemplate);
  }

  private getDefaultInitialFormValues(): InitialFormValues {
    return {
      name: undefined,
      collectablePeriodStart: undefined,
      collectablePeriodEnd: undefined,
      note: undefined,
      isDefault: false,
    };
  }

  private getInitialFormValuesByCollectablePeriodTemplate(
    collectablePeriodTemplate: CollectablePeriodTemplateEntity
  ): InitialFormValues {
    return {
      name: collectablePeriodTemplate.name,
      collectablePeriodStart: collectablePeriodTemplate.collectablePeriodStart,
      collectablePeriodEnd: collectablePeriodTemplate.collectablePeriodEnd,
      note: collectablePeriodTemplate.note,
      isDefault: collectablePeriodTemplate.isDefault,
    };
  }

  onCollectablePeriodUpdate(start: number, end: number): void {
    this.collectablePeriodStart = start;
    this.collectablePeriodEnd = end;
  }
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  FormMode: typeof FormMode;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  collectablePeriodTemplateApplicationService: CollectablePeriodTemplateApplicationService;
  isChangeDefaultDialogActive: boolean;
  changeDefaultDialogResolver: Maybe<(value: boolean) => void>;
};

type AsyncDataType = {
  viewModel: Maybe<CollectablePeriodTemplateForm>;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RCollectablePeriodTemplateForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const collectablePeriodTemplateApplicationService = this.$context.applications.get(collectablePeriodTemplateSymbol);
    return {
      isActive: false,
      isConfirmDialogActive: false,
      viewModel: undefined,
      FormMode,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      collectablePeriodTemplateApplicationService,
      isChangeDefaultDialogActive: false,
      changeDefaultDialogResolver: undefined,
    };
  },
  computed: {
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.COLLECTABLE_PERIOD_TEMPLATE;
    },
    isRegisterButtonDisabled(): boolean {
      if (this.viewModel === undefined) return true;

      return !this.viewModel.isFormValid;
    },
  },
  watch: {},
  mounted() {
    const openEventListenerDisposer = this.$context.panels.collectablePeriodTemplateFormPanel.openFormEvent.on(
      this.onOpenForm
    );
    this.$context.panels.collectablePeriodTemplateFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async open(collectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity> = undefined): Promise<void> {
      if (this.isActive) return;
      const defaultCollectablePeriodTemplate = await this.collectablePeriodTemplateApplicationService.getDefault();
      this.viewModel = new CollectablePeriodTemplateForm(collectablePeriodTemplate, defaultCollectablePeriodTemplate);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<CollectablePeriodTemplateEntity> = {
        entity: this.viewModel!.collectablePeriodTemplate,
        createdEntity: this.viewModel!.createCollectablePeriodTemplate,
        updatedEntity: this.viewModel!.updatedCollectablePeriodTemplate,
        removedEntity: undefined,
      };
      this.$context.panels.collectablePeriodTemplateFormPanel.closeFormEvent.emit(closeFormArgs);
    },
    async onOpenForm(args: IOpenEntityFormArgs<CollectablePeriodTemplateEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async onCloseForm(): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    async onRegisterButtonClick(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;

      // Show dialog and confirm
      // if updating or creating new entity with `isDefault: true`.
      const hasDefaultUpdated = viewModel.isDefault !== viewModel.initialValues.isDefault;
      if (viewModel.isDefault && hasDefaultUpdated) {
        const dialogPromise = new Promise<boolean>((resolve) => {
          this.isChangeDefaultDialogActive = true;
          this.changeDefaultDialogResolver = resolve;
        });
        const confirmSetDefault = await dialogPromise;
        if (!confirmSetDefault) {
          viewModel.isDefault = false;
          return;
        }
      }

      viewModel.isRegistering = true;

      if (viewModel.formMode === FormMode.Register) {
        const entity = await this.collectablePeriodTemplateApplicationService.create({
          name: viewModel.name!,
          collectablePeriodStart: viewModel.collectablePeriodStart,
          collectablePeriodEnd: viewModel.collectablePeriodEnd,
          note: viewModel.note,
          isDefault: viewModel.isDefault,
        });
        viewModel.createCollectablePeriodTemplate = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        const collectablePeriodTemplateEntity = viewModel.collectablePeriodTemplate!;
        const entity = await this.collectablePeriodTemplateApplicationService.update({
          id: collectablePeriodTemplateEntity.id,
          name: viewModel.name!,
          collectablePeriodStart: viewModel.collectablePeriodStart,
          collectablePeriodEnd: viewModel.collectablePeriodEnd,
          note: viewModel.note,
          isDefault: viewModel.isDefault,
        });

        viewModel.updatedCollectablePeriodTemplate = entity;
      }

      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.COLLECTABLE_PERIOD_TEMPLATE,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('到着時間の登録完了');
      await this.close();
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.collectablePeriodTemplateFormPanel.updateDisplayedWidth(value);
    },
    onConfirmChangeDefaultDialog(value: boolean): void {
      this.isChangeDefaultDialogActive = false;
      if (this.changeDefaultDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.changeDefaultDialogResolver(value);
    },
  },
});
