import { AddressType } from '~/framework/services/google-maps/addressType';

/**
 * 正確であると判断したい順番（数値が高い方が優先順位が高い）
 * コンポーネントの多さで判断してもよいが...
 */
export const addressTypePriorityMap: Map<AddressType, number> = new Map<AddressType, number>([
  [AddressType.Subpremise, 10],
  [AddressType.Premise, 9],
  [AddressType.StreetAddress, 8],
  [AddressType.SublocalityLevel5, 7],
  [AddressType.SublocalityLevel4, 6],
  [AddressType.SublocalityLevel3, 5],
  [AddressType.SublocalityLevel2, 4],
  [AddressType.SublocalityLevel1, 3],
  [AddressType.Ward, 2],
  [AddressType.Locality, 1],
]);
