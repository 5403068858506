
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RDialog',
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: 'fit-content',
    },
    scroll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
