import {
  AttendancesByDateRangeQuery,
  AttendancesByDateRangeQueryVariables,
  AttendancesByIdsQuery,
  AttendancesByIdsQueryVariables,
  CreateAttendancesMutation,
  CreateAttendancesMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AttendancesByDateRange, AttendancesByIds } from '@/../graphql/server-api/queries/attendance.gql';
import { CreateAttendances } from '@/../graphql/server-api/mutations/attendance.gql';
import _ from 'lodash';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IAttendanceData } from '~/framework/server-api/masters/attendance';

import { WithoutId } from '~/framework/typeAliases';

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<AttendancesByIdsQuery, AttendancesByIdsQueryVariables>({
      query: AttendancesByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.attendancesByIds, `AttendancesByIds`);
    return result.attendancesByIds;
  }
}

export class GetByDateRangeApi extends GraphqlApiBase {
  async getByDateRange(start: Date, end: Date) {
    const result = await this.query<AttendancesByDateRangeQuery, AttendancesByDateRangeQueryVariables>({
      query: AttendancesByDateRange,
      variables: { condition: { start, end } },
    });
    return result.attendancesByDateRange;
  }
}

export class GetByDateApi extends GraphqlApiBase {
  async getByDate(date: Date) {
    const result = await this.query<AttendancesByDateRangeQuery, AttendancesByDateRangeQueryVariables>({
      query: AttendancesByDateRange,
      variables: { condition: { start: date, end: date } },
    });
    return _.first(result.attendancesByDateRange);
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: WithoutId<IAttendanceData>[]) {
    const result = await this.mutate<CreateAttendancesMutation, CreateAttendancesMutationVariables>({
      mutation: CreateAttendances,
      variables: { attendances: data },
    });
    this.validateArrayConsistency(data, result.createAttendances, `CreateAttendances`);
    return result.createAttendances;
  }
}
