export type QueryKey = string;
export type QueryValue = string | null | (string | null)[];

/**
 * URI の path + query + fragment の事をここでは relative-ref と呼んでいる
 * ref: https://datatracker.ietf.org/doc/html/rfc3986#section-3
 * @param route
 */
export const getRelativeRefFrom = (path: string, queries: Map<QueryKey, QueryValue>, fragment: string) => {
  let relativeRef = path;
  const queryComponents = [];
  for (const [key, value] of queries.entries()) {
    if (value instanceof Array) {
      for (const aValue of value) queryComponents.push(buildSingleQuery(key, aValue));
    } else {
      queryComponents.push(buildSingleQuery(key, value));
    }
  }
  if (0 < queryComponents.length) relativeRef += '?' + queryComponents.join(`&`);
  if (fragment) relativeRef += fragment.startsWith(`#`) ? fragment : `#${fragment}`;
  return relativeRef;
};

/**
 * 一つのクエリを作る、value は null になっている可能性がある
 * @param key
 * @param value
 */
const buildSingleQuery = (key: string, value: string | null) => {
  if (value === null) return key;
  else return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};
