import Vue from 'vue';
import RContentTitle from '~/components/common/r-content-title/RContentTitle.vue';
import RSearchablePulldown from '~/components/common/r-searchable-pulldown/RSearchablePulldown.vue';
import RSubheader from '~/components/common/r-subheader/RSubheader.vue';
import RRadioGroup from '~/components/common/r-radio-group/RRadioGroup.vue';
import RContentFooter from '~/components/common/r-content-footer/RContentFooter.vue';
import RSnackbar from '~/components/common/r-snackbar/RSnackbar.vue';
import RSideform from '~/components/common/r-sideform/RSideform.vue';
import RGenerationSiteTask from '~/components/common/r-generation-site-task/RGenerationSiteTask.vue';
import RButtonGroup from '~/components/common/r-button-group/RButtonGroup.vue';
import RDateField from '~/components/common/r-date-field/RDateField.vue';
import RFieldHeader from '~/components/common/r-field-header/RFieldHeader.vue';
import RGenerationSiteTaskField from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/RGenerationSiteTaskField.vue';
import RTabs from '~/components/common/r-tabs/RTabs.vue';
import RHeaderRow from '~/components/common/r-header-row/RHeaderRow.vue';
import RIrregularTask from '~/components/common/r-irregular-task/RIrregularTask.vue';
import RHolidayRuleCheckboxes from '~/components/common/r-holiday-rule-checkboxes/RHolidayRuleCheckboxes.vue';
import RCreateButton from '~/components/common/r-create-button/RCreateButton.vue';
import RMapDialog from '~/components/common/r-map-dialog/RMapDialog.vue';
import RAddressValidator from '~/components/common/r-address-validator/RAddressValidator.vue';
import RLazyItems from '~/components/common/r-lazy-items/RLazyItems.vue';
import RListSubHeader from '~/components/common/r-list-sub-header/RListSubHeader.vue';
import RTruncate from '~/components/common/r-truncate/RTruncate.vue';
import RTruncateElements from '~/components/common/r-truncate-elements/RTruncateElements.vue';
import RTruncateWithBadge from '~/components/common/r-truncate-with-badge/RTruncateWithBadge.vue';
import RTimePicker from '~/components/common/r-time-picker/RTimePicker.vue';
import RConfirmDialog from '~/components/common/r-confirm-dialog/RConfirmDialog.vue';
import RVInput from '~/components/common/r-v-input/RVInput.vue';
import RHeader from '~/components/common/r-header/RHeader.vue';
import RGmapLoader from '~/components/common/r-gmap-loader/RGmapLoader.vue';
import RPanelSubHeader from '~/components/common/r-panel-sub-header/RPanelSubHeader.vue';
import RPeriodInput from '~/components/common/r-period-input/RPeriodInput.vue';
import RLazySearchablePulldown from '~/components/common/r-lazy-searchable-pulldown/RLazySearchablePulldown.vue';
import RDaysOfWeekSelector from '~/components/common/r-days-of-week-selector/RDaysOfWeekSelector.vue';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import RScheduleOrderPreloadStatus from '~/components/common/r-schedule-order-preload-status/RScheduleOrderPreloadStatus.vue';
import RScheduleOrderRecurringSettings from '~/components/common/r-schedule-order-recurring-settings/RScheduleOrderRecurringSettings.vue';
import RScheduleOrderRoutingGroup from '~/components/common/r-schedule-order-routing-group/RScheduleOrderRoutingGroup.vue';
import RInfeasibility from '~/components/common/r-infeasibility/RInfeasibility.vue';
import RVCheckbox from '~/components/common/r-v-checkbox/RVCheckbox.vue';
import RSqueezable from '~/components/common/r-squeezable/RSqueezable.vue';
import RVNumberField from '~/components/common/r-v-number-field/RVNumberField.vue';
import RVTextField from '~/components/common/r-v-text-field/RVTextField.vue';
import RWeekCalendar from '~/components/common/r-week-calendar/RWeekCalendar.vue';
import RWeekCalendarColumnHeaderItem from '~/components/common/r-week-calendar/RWeekCalendarColumnHeaderItem.vue';
import RWeekCalendarDataItem from '~/components/common/r-week-calendar/RWeekCalendarDataItem.vue';
import RWeekCalendarRow from '~/components/common/r-week-calendar/RWeekCalendarRow.vue';
import RWeekCalendarRowHeaderItem from '~/components/common/r-week-calendar/RWeekCalendarRowHeaderItem.vue';
import RMonthCalendar from '~/components/common/r-month-calendar/RMonthCalendar.vue';
import RMonthCalendarRow from '~/components/common/r-month-calendar/RMonthCalendarRow.vue';
import RMonthCalendarDataItem from '~/components/common/r-month-calendar/RMonthCalendarDataItem.vue';
import RVTooltip from '~/components/common/r-v-tooltip/RVTooltip.vue';
import RTable from '~/components/common/r-table/RTable.vue';
import RTableRow from '~/components/common/r-table/RTableRow.vue';
import RTableHeaderCell from '~/components/common/r-table/RTableHeaderCell.vue';
import RTableDataCell from '~/components/common/r-table/RTableDataCell.vue';
import RVBtn from '~/components/common/r-v-btn/RVBtn.vue';
import RPopupMenu from '~/components/common/r-popup-menu/RPopupMenu.vue';
import RDetailPopupMenu from '~/components/common/r-detail-popup-menu/RDetailPopupMenu.vue';
import RCounter from '~/components/common/r-counter/RCounter.vue';
import RContainerTypes from '~/components/common/r-container-types/RContainerTypes.vue';
import RLinkify from '~/components/common/r-linkify/RLinkify.vue';
import RScheduleCandidateDates from '~/components/common/r-schedule-candidate-dates/RScheduleCandidateDates.vue';
import RIconButton from '~/components/common/r-icon-button/RIconButton.vue';
import RPlanCandidateDatesDialog from '~/components/panels/schedule/r-order-form/r-plan-candidate-dates-dialog/RPlanCandidateDatesDialog.vue';
import RSnackbars from '~/components/common/r-snackbars/RSnackbars.vue';
import RFieldTooltip from '~/components/common/r-field-tooltip/RFieldTooltip.vue';
import RPlaceIconAndAddress from '~/components/common/r-place-icon-and-address/RPlaceIconAndAddress.vue';
import RRecordStamperForForm from '~/components/common/r-record-stamper-for-form/RRecordStamperForForm.vue';
import RRecordStamperForMenu from '~/components/common/r-record-stamper-for-menu/RRecordStamperForMenu.vue';
import RUploadedFile from '~/components/common/r-uploaded-file/RUploadedFile.vue';
import RUploadingFile from '~/components/common/r-uploading-file/RUploadingFile.vue';
import RDialogOrder from '~/components/common/r-dialog-order/RDialogOrder.vue';
import RTooltip from '~/components/common/RTooltip.vue';
import RHelpTooltip from '~/components/common/RHelpTooltip.vue';
import RDescribeCard from '~/components/common/r-describe-card/RDescribeCard.vue';

// layouts
import RPage from '~/components/templates/RPage.vue';
import RScrollablePage from '~/components/templates/RScrollablePage.vue';
import RAddressInputGroup from '~/components/common/r-address-input-group/RAddressInputGroup.vue';

// Rin 独自コンポーネントを各ページでインポートしなくても使える様にするためのもの
// components/common 以下に Rin 独自のコンポーネントを作成した場合はここで登録しておく

const components = {
  RContentTitle,
  RSearchablePulldown,
  RSubheader,
  RRadioGroup,
  RContentFooter,
  RSnackbar,
  RSideform,
  RGenerationSiteTask,
  RButtonGroup,
  RDateField,
  RFieldHeader,
  RGenerationSiteTaskField,
  RTabs,
  RHeaderRow,
  RIrregularTask,
  RHolidayRuleCheckboxes,
  RCreateButton,
  RMapDialog,
  RAddressValidator,
  RLazyItems,
  RListSubHeader,
  RTruncate,
  RTruncateElements,
  RTruncateWithBadge,
  RTimePicker,
  RConfirmDialog,
  RVInput,
  RHeader,
  RGmapLoader,
  RPanelSubHeader,
  RPeriodInput,
  RLazySearchablePulldown,
  RDaysOfWeekSelector,
  RDialog,
  RScheduleOrderPreloadStatus,
  RScheduleOrderRecurringSettings,
  RScheduleOrderRoutingGroup,
  RInfeasibility,
  RVCheckbox,
  RPage,
  RScrollablePage,
  RSqueezable,
  RVNumberField,
  RVTextField,
  RWeekCalendar,
  RWeekCalendarColumnHeaderItem,
  RWeekCalendarDataItem,
  RWeekCalendarRow,
  RWeekCalendarRowHeaderItem,
  RMonthCalendar,
  RMonthCalendarRow,
  RMonthCalendarDataItem,
  RVTooltip,
  RTable,
  RTableRow,
  RTableHeaderCell,
  RTableDataCell,
  RVBtn,
  RPopupMenu,
  RDetailPopupMenu,
  RCounter,
  RContainerTypes,
  RLinkify,
  RScheduleCandidateDates,
  RIconButton,
  RPlanCandidateDatesDialog,
  RSnackbars,
  RFieldTooltip,
  RAddressInputGroup,
  RPlaceIconAndAddress,
  RRecordStamperForForm,
  RRecordStamperForMenu,
  RUploadedFile,
  RUploadingFile,
  RDialogOrder,
  RTooltip,
  RHelpTooltip,
  RDescribeCard,
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
