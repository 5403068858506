export const holidayRule$getSymbol = Symbol('holidayRule$getSymbol');
export const holidayRule$updateSymbol = Symbol('holidayRule$updateSymbol');

export type HolidayRule = {
  [holidayRule$getSymbol]: IGetApi;
  [holidayRule$updateSymbol]: IUpdateApi;
};

export interface IGetApi {
  get(): Promise<IHolidayRuleData>;
}
export interface IHolidayRuleData {
  id: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  nationalHoliday: boolean;
}

export interface IUpdateApi {
  update(data: IHolidayRuleData): Promise<void>;
}
