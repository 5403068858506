
import Vue, { PropType } from 'vue';
import { CssClasses, CssStyles, Maybe } from '~/framework/typeAliases';
import { TableRowType } from '~/components/common/r-table/tableRowType';

const headRowDefaultHeight = 32;

export default Vue.extend({
  name: 'RTableRow',
  props: {
    type: {
      type: Number as PropType<Maybe<TableRowType>>,
      required: false,
      default: undefined,
    },
    height: {
      type: Number as PropType<Maybe<number>>,
      required: false,
      default: undefined,
    },
    highlightOnHover: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hoverColor: {
      type: String as PropType<string>,
      required: false,
      default: 'bluish-gray_05',
    },
    isActive: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    activeColor: {
      type: String as PropType<string>,
      required: false,
      default: 'primary_pale',
    },
  },
  computed: {
    styles(): CssStyles {
      const styles: CssStyles = {};
      if (this.height !== undefined) styles.height = `${this.height}px`;
      else if (this.type === TableRowType.TableHeadRow) styles.height = `${headRowDefaultHeight}px`;
      if (this.highlightOnHover) styles['--hover-color'] = `${this.$vuetify.theme.currentTheme[this.hoverColor]}`;
      styles['--active-color'] = `${this.$vuetify.theme.currentTheme[this.activeColor]}`;
      return styles;
    },
    classes(): CssClasses {
      const classes: CssClasses = {};
      classes['r-table-row'] = true;
      if (this.isActive) classes['r-table-row--active'] = true;
      return classes;
    },
  },
});
