import { Maybe } from '~/framework/typeAliases';
import { AggregatedWasteTypeEntity } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';
import { IGenerationSiteTaskData } from '~/framework/server-api/schedule/order/generation-site-task/generationSiteTask';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
/**
 * こいつは扱いが特殊で、Order の現場タスクを更新すると毎回新しい ID が振られる。
 * なので ID が同じでも集約の内容が更新される事はあり得ず、トラックする必要はない。
 */
export class AggregatedGenerationSiteTaskEntity {
  readonly id: string;
  readonly persistentId: string;
  orderId: string;
  taskType: TaskTypeEntity;
  containerNum: Maybe<number>;
  wasteType: Maybe<AggregatedWasteTypeEntity>;
  containerType: AggregatedContainerTypeEntity;

  constructor(
    data: IGenerationSiteTaskData,
    wasteType: Maybe<AggregatedWasteTypeEntity>,
    containerType: AggregatedContainerTypeEntity,
    taskType: TaskTypeEntity
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.orderId = data.orderId;
    this.taskType = taskType;
    this.containerNum = data.containerNum;
    this.wasteType = wasteType;
    this.containerType = containerType;
  }
}
