import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedPackingPlacementEntity } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementEntity';
import { AggregatedPackingPlacementStore } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementStore';
import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { AggregatedContainerTypeMapper } from '../../masters/container-type/aggregatedContainerTypeMapper';
import { AggregatedContainerTypeStore } from '../../masters/container-type/aggregatedContainerTypeStore';
import { PackingStyleStore } from '../../masters/packing-style/packingStyleStore';

export class AggregatedPackingPlacementMapper extends StoredMapperBase<
  IPackingPlacementData,
  AggregatedPackingPlacementEntity
> {
  protected store: AggregatedPackingPlacementStore;
  private containerTypeMapper: AggregatedContainerTypeMapper;

  constructor(
    store: AggregatedPackingPlacementStore,
    containerTypeStore: AggregatedContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.containerTypeMapper = new AggregatedContainerTypeMapper(containerTypeStore, packingStyleStore);
  }

  protected instantiateWithData(data: IPackingPlacementData): AggregatedPackingPlacementEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    return new AggregatedPackingPlacementEntity(data, containerType);
  }

  protected updateWithData(data: IPackingPlacementData, entity: AggregatedPackingPlacementEntity) {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    entity.update(data, containerType);
  }
}
