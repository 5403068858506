enum DisabledReason {
  GenerationSiteIsNotSelected,
  OrderGroupIsNotSelected,
  AssignedCarIsSelected,
  AssignableCarTypesIsSelected, // is は厳密にはおかしいけどまぁ意味は分かる
  EveryGenerationSiteTaskTypeIsAllocate,
  MultipleCarCountsSelected,
}

export default DisabledReason;
