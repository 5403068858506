
import Vue, { PropType } from 'vue';
import { CssStyles } from '~/framework/typeAliases';

export class TitleAndItems {
  constructor(public title: string, public items: string[]) {}
}

/**
 * 複数の項目を表示しており長くなりそうな箇所で、一定数を超えたら(+1)のようなバッジを追加し残りを省略表示するコンポーネント。
 * 省略時にマウスオーバーすることで、全部の項目が表示される。
 */
export default Vue.extend({
  name: 'RTruncateWithBadge',
  props: {
    width: {
      type: String as PropType<string>,
      required: false,
      default: 'auto',
    },
    height: {
      type: String as PropType<string>,
      required: false,
      default: 'auto',
    },
    titleAndItems: {
      type: Array as PropType<TitleAndItems[]>,
      required: true,
    },
    threshold: {
      type: Number as PropType<number>,
      required: true,
    },
    badgeProps: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      required: false,
      default: () => ({ 'offset-x': 23, 'offset-y': 19 }),
    },
    menuProps: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      required: false,
      default: () => ({}),
    },
    // hidden items は無いがホバー表示させたい場合にtrueにする
    displayHoverMenu: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    styles(): CssStyles {
      return {
        width: this.width,
        height: this.height,
      };
    },
    itemLength(): number {
      return this.titleAndItems.reduce((acc: number, value: TitleAndItems): number => {
        return (acc += value.items.length);
      }, 0);
    },
    hiddenItemNum(): number {
      return Math.max(0, this.itemLength - this.threshold);
    },
    hasHiddenItems(): boolean {
      return this.threshold < this.itemLength;
    },
    truncatedItems(): string[] {
      return this.titleAndItems
        .map((e) => e.items)
        .flat()
        .slice(0, this.threshold);
    },
  },
});
