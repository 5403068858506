import { Maybe } from '~/framework/typeAliases';
import { IllegalStateException } from '~/framework/core/exception';

/**
 * Maybe に対して ! の様なコードを書くのは微妙なので undefined な可能性を捨てるために使う
 * 返り値として value が利用可能
 *
 * @param value
 */
export const unwrap = <T>(value: Maybe<T>): T => {
  if (value === undefined) {
    throw new IllegalStateException(`value is undefined!`);
  }
  return value;
};

export type Assertion = (
  condition: unknown,
  message?: string,
  ConcreteException?: new (message?: string) => Error
) => asserts condition;

/**
 * 汎用 assert 関数
 * 関数には明示的に型を付けないと TS2775 が発生するため、Assertion の型を定義してやっている
 *
 * @param condition 条件
 * @param message 条件を満たさない場合に発生する例外のメッセージ
 * @param ConcreteException 条件を満たさない場合に発生する例外のクラス
 */
export const assert: Assertion = (
  condition: unknown,
  message: string = 'assertion failed',
  ConcreteException: new (message?: string) => Error = IllegalStateException
): asserts condition => {
  if (!condition) throw new ConcreteException(message);
};

/**
 * Maybe に対して ! の様なコードを書くのは微妙なので undefined な可能性を捨てるために使う
 * value に対してタイプガードをするためだけに利用する
 *
 * @param value
 */
export const ensure: <T>(value: Maybe<T>) => asserts value is T = <T>(value: Maybe<T>): asserts value is T => {
  assert(value !== undefined, 'value is undefined!');
};
