// NOTE Vuetify のための便利関数みたいなものはこの辺に定義する

/**
 * v-date-picker の day-format に使うためのもの
 * v-date-picker はそのまま日本語で使うと 16日 の様にご丁寧に日を付けてしまうのでそれを消す
 *
 * @param value
 */
export const dateFormatter = (value: string): string => {
  const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/g;
  const matches = Array.from(value.matchAll(regex));
  return Number.parseInt(matches[0][3]).toString();
};

/**
 * v-select に使う標準的な item の型
 */
export type SelectItem<Value> = { text: String; value: Value };
