import {
  ClientsByIdsQuery,
  ClientsByIdsQueryVariables,
  ClientsByKeywordsQuery,
  ClientsByKeywordsQueryVariables,
  ClientsWithGenerationSiteNumByIdsQuery,
  ClientsWithGenerationSiteNumByIdsQueryVariables,
  ClientsWithGenerationSiteNumByKeywordsQuery,
  ClientsWithGenerationSiteNumByKeywordsQueryVariables,
  CreateClientsMutation,
  CreateClientsMutationVariables,
  UpdateClientsMutation,
  UpdateClientsMutationVariables,
} from '@/graphql/graphQLServerApi';
import {
  ClientsByIds,
  ClientsByKeywords,
  ClientsWithGenerationSiteNumByIds,
  ClientsWithGenerationSiteNumByKeywords,
} from '@/../graphql/server-api/queries/client.gql';
import { CreateClients, UpdateClients } from '@/../graphql/server-api/mutations/client.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICondition, IClientCreateData, IClientUpdateData } from '~/framework/server-api/masters/client';

import { Maybe } from '~/framework/typeAliases';

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<ClientsByIdsQuery, ClientsByIdsQueryVariables>({
      query: ClientsByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.clientsByIds, `ClientsByIds`);
    return result.clientsByIds;
  }
}

export class GetByKeywordsApi extends GraphqlApiBase {
  async getByKeywords(first: number, after: Maybe<string>, condition: ICondition) {
    const result = await this.query<ClientsByKeywordsQuery, ClientsByKeywordsQueryVariables>({
      query: ClientsByKeywords,
      variables: { first, after, condition },
    });
    return result.clientsByKeywords;
  }

  async getWithOptionalsByKeywords(first: number, after: Maybe<string>, condition: ICondition) {
    const result = await this.query<
      ClientsWithGenerationSiteNumByKeywordsQuery,
      ClientsWithGenerationSiteNumByKeywordsQueryVariables
    >({
      query: ClientsWithGenerationSiteNumByKeywords,
      variables: { first, after, condition },
    });
    return result.clientsByKeywords;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: IClientCreateData[]) {
    const result = await this.mutate<CreateClientsMutation, CreateClientsMutationVariables>({
      mutation: CreateClients,
      variables: { clients: data },
    });
    this.validateArrayConsistency(data, result.createClients, `CreateClients`);
    return result.createClients;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IClientUpdateData[]) {
    const result = await this.mutate<UpdateClientsMutation, UpdateClientsMutationVariables>({
      mutation: UpdateClients,
      variables: { clients: data },
    });
    this.validateArrayConsistency(data, result.updateClients, `UpdateClients`);
    return result.updateClients;
  }
}

export class GetWithGenerationSiteNumByIdsApi extends GraphqlApiBase {
  async getWithGenerationSiteNumByIds(ids: string[]) {
    const result = await this.query<
      ClientsWithGenerationSiteNumByIdsQuery,
      ClientsWithGenerationSiteNumByIdsQueryVariables
    >({
      query: ClientsWithGenerationSiteNumByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.clientsByIds, `ClientsWithGenerationSiteNumByIds`);
    return result.clientsByIds;
  }
}
