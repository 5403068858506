
import Vue, { PropType } from 'vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { v4 as uuidv4 } from 'uuid';
import { ILazyLoader } from '~/components/common/r-lazy-items/lazyItems';
import { CssClasses, CssStyles, Maybe } from '~/framework/typeAliases';

type LazyItem = {
  /**
   * 読み込まれたアイテム自体
   */
  item: any;
  /**
   * 全体から見た時の index
   */
  index: number;
  /**
   * このアイテム用の div に指定するスタイル
   */
  styles: CssStyles;
};

type DataType = {
  intersectionObserver: Maybe<IntersectionObserver>;
  elementId: string;
  elementIdSelector: string;
  rawItems: LazyItem[];
  rawItemsLength: number;
  clientHeight: number;
  scrollTop: number;
};

/**
 * infinite-loading は v-virtual-scroll 的な処理はしていないので、このコンポーネントで表示領域分の
 * 要素に絞って表示させる様な処理をしている
 */
export default Vue.extend({
  name: 'RLazyItems',
  components: {
    InfiniteLoading,
  },
  props: {
    /**
     * アイテムを読み込む必要のある時に呼ばれる関数
     */
    loader: {
      type: Object as PropType<ILazyLoader<any>>,
      required: true,
    },
    /**
     * 一回に読み込むアイテム数
     * 指定しない時は表示領域とアイテムの高さから自動的に算出される
     * 表示領域が非常に小さい場合は手動で指定した方が自然な挙動になる
     * 表示領域が十分大きい場合は表示領域分を次も読み込むのでスクロールの幅は十分持たせられる
     */
    itemsPerLoad: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    /**
     * 一つのアイテムの高さ
     */
    itemHeight: {
      type: Number as PropType<number>,
      required: true,
    },
    /**
     * このコンポーネントの高さ
     * スタイルに指定するので、vh などを使ってよい
     */
    height: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * ラッパーに付けたい CSS クラス
     * アイテムの数が 1 以上の場合のみ適用される
     */
    wrapperClass: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    // HTML 上 ID は数字で始まってもいいのだが、CSS 上は数字から始まってはいけないという罠があるため、
    // prefix として RLazyItems を付けておく
    const elementId = `RLazyItems${uuidv4().replace(/-/g, '')}`;
    return {
      elementId,
      elementIdSelector: `#${elementId}`,
      rawItems: [],
      rawItemsLength: 0,
      clientHeight: 0,
      scrollTop: 0,
      intersectionObserver: undefined,
    };
  },
  computed: {
    styles(): CssStyles {
      return {
        'max-height': this.height,
        height: this.height,
      };
    },
    wrapperStyles(): CssStyles {
      return {
        height: `${this.rawItemsLength * this.itemHeight}px`,
      };
    },
    wrapperClasses(): CssClasses {
      const classes: CssClasses = {
        'r-lazy-items__wrapper': true,
      };
      if (this.wrapperClass !== undefined && 0 < this.rawItemsLength) {
        classes[this.wrapperClass] = true;
      }
      return classes;
    },
    rootDiv(): HTMLElement {
      return this.$refs.rootDiv as HTMLElement;
    },
    renderableItemsNum(): number {
      return (
        (this.clientHeight % this.itemHeight === 0
          ? Math.floor(this.clientHeight / this.itemHeight) + 1
          : Math.ceil(this.clientHeight / this.itemHeight)) + 1
      );
    },
    renderableItemIndexes(): number[] {
      const indexes: number[] = [];
      for (let index = 0; index < this.renderableItemsNum; index++) {
        indexes.push(index);
      }
      return indexes;
    },
    first(): number {
      return Math.floor(this.scrollTop / this.itemHeight);
    },
    last(): number {
      return Math.min(this.first + this.renderableItemsNum);
    },
    items(): LazyItem[] {
      return this.rawItems.slice(this.first, this.last);
    },
  },
  mounted(): void {
    // onResize でウィンドウリサイズの変更は検知しているが、例えばこの要素の親要素が display: none になったり
    // して非表示になった状態で onResize すると clientHeight が 0 になってしまう。また、clientHeight が 0 の場合を
    // 仮に弾いたとしても表示領域が拡張された場合にはこれを検知する事ができない。そこで IntersectionObserver で
    // 表示状態の変更を検知して、clientHeight を更新する事にする。
    this.intersectionObserver = new IntersectionObserver(() => this.onIntersect());
    this.intersectionObserver.observe(this.rootDiv);
  },
  beforeDestroy(): void {
    if (this.intersectionObserver !== undefined) this.intersectionObserver.disconnect();
  },
  methods: {
    onIntersect(): void {
      this.onResize();
    },
    onResize(): void {
      // onResize はウィンドウのリサイズで呼ばれるが、例えばタブなどを利用しておりこの r-lazy-items を含む親要素が
      // display: none されている状態だと clientHeight は 0 になってしまう。これは意味のない更新になるので弾いておく
      if (0 < this.rootDiv.clientHeight) this.clientHeight = this.rootDiv.clientHeight;
    },
    onScroll(): void {
      this.scrollTop = this.rootDiv.scrollTop;
    },
    getStylesOf(index: number): CssStyles {
      return {
        position: 'absolute',
        top: `${index * this.itemHeight}px`,
      };
    },
    getWrapperStylesOf(first: number, index: number): Maybe<CssStyles> {
      return this.getRenderItemOf(first, index)?.styles;
    },
    getRenderItemOf(first: number, index: number): Maybe<LazyItem> {
      const itemIndex = first + index;
      return itemIndex < this.rawItems.length ? this.rawItems[itemIndex] : undefined;
    },
    async loadItems(stateChanger: StateChanger): Promise<void> {
      // 一回に読み込む数が指定されていなかった場合、表示領域 + 1 だけのアイテムを読み込もうとする

      const loadItemsNum = this.itemsPerLoad !== undefined ? this.itemsPerLoad : this.renderableItemsNum;
      const start = this.rawItems.length;
      const [items, finished] = await this.loader.load(loadItemsNum);
      for (const [itemIndex, item] of items.entries()) {
        const index = start + itemIndex;
        this.rawItems.push({
          item,
          index,
          styles: this.getStylesOf(index),
        });
      }
      this.rawItemsLength = this.rawItems.length;
      if (finished) {
        stateChanger.complete();
      } else {
        stateChanger.loaded();
      }
    },
  },
});
