
import Vue from 'vue';

/**
 * 縦をウィンドウいっぱいまで持っていき内部をスクロール可能にするもの
 * （高さが固定されるので中身でスクロールの指定をできる）
 */
export default Vue.extend({
  name: 'RScrollablePage',
});
