import { ClientsByKeywordsCondition } from '~/framework/server-api/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import {
  IConnection,
  ILazySearchLoader,
  LazySearchBase,
} from '~/components/common/r-lazy-searchable-pulldown/lazySearch';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';

export class Client
  extends LazySearchBase<ClientsByKeywordsCondition, AggregatedClientEntity>
  implements ILazySearchLoader<ClientsByKeywordsCondition, AggregatedClientEntity>
{
  protected async loadConnection(
    first: number,
    after: Maybe<string>,
    condition: ClientsByKeywordsCondition
  ): Promise<IConnection<AggregatedClientEntity>> {
    const service = this.systemContext.applications.get(clientSymbol);
    const list = await service.searchSelection(first, after, condition);
    return {
      items: list.items.map((item) => item.entity),
      hasNextPage: list.pageInfo.hasNextPage,
      endCursor: list.pageInfo.endCursor,
    };
  }
}
