import Vue from 'vue';
import _ from 'lodash';
import { IShrinkable } from './rTruncateElementsTypes';
import { Pixel } from '~/framework/typeAliases';

/**
 * 配車表画面でタスク表示に使用しているVueコンポーネントが
 * RTruncateElementsを使用する際に必要な実装を追加した
 * ミックスインコンポーネント。
 * 水平方向の表示幅超過時の縮小を行うIShrinkableインターフェイスを実装。
 */
export default Vue.extend<{}, IShrinkable, {}, {}>({
  name: 'RLabelsShrinkable',
  methods: {
    /**
     * Shrinkのリセット
     *
     * 子要素の縮小化をすべて解除する。
     */
    resetShrink(): void {
      const contentElements = this.$el.children;
      Array.from(contentElements).forEach((element) => {
        element.removeAttribute('style');
      });
    },

    /**
     * Shrink
     *
     * 子要素を下記資料の方針に従って縮小化する。
     *
     * 水平方向の調整幅に関する詳細
     * https://github.com/FanfareInc/rin/pull/1445#issuecomment-1060115806
     *
     * @param width 縮小後の横幅
     */
    shrink(width: Pixel): void {
      const contentElement = this.$el as HTMLElement;
      const contentBorderTotal = contentElement.offsetWidth - contentElement.clientWidth;
      const contentElements = contentElement.children;
      const baseWidth = Math.round(((width - contentBorderTotal) / contentElements.length) * 10) / 10;
      // width均等割時との差を保持
      const subtractBaseWidth: number[] = [];
      Array.from(contentElements).forEach((element) => {
        subtractBaseWidth.push(
          Math.round(baseWidth * 10 - Math.round(element.getBoundingClientRect().width * 10)) / 10
        );
      });
      // 余剰pixel合計
      const totalSurplus = _.sum(subtractBaseWidth.filter((subtract) => 0 < subtract));
      // 不足pixel合計
      const totalShortage = _.sum(subtractBaseWidth.filter((subtract) => 0 > subtract));
      subtractBaseWidth.forEach((subtract, index) => {
        if (0 > subtract) {
          const element = contentElements[index] as HTMLElement;
          const width =
            0 === totalSurplus
              ? baseWidth
              : Math.round(Math.round((subtract / totalShortage) * totalSurplus * 10) + baseWidth * 10) / 10;
          element.style.width = `${width}px`;
          element.style.overflow = 'hidden';
          element.style.textOverflow = 'ellipsis';
        }
      });
    },
  },
});
