
import Vue from 'vue';

export default Vue.extend({
  name: 'RMonthCalendarDataItem',
  props: {
    height: {
      type: String,
      required: false,
      default: undefined,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
