import { IResultOrderData } from '~/framework/server-api/schedule/order/order';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderAssignableDriversAndNumInput,
  CreateOrderDisposalSitesAndTypeInput,
  CreateOrderRecurringSettingsInput,
  OrderRoutingGroupInput,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';
import { OrderSchedulingPriority, OrderStatus, PreloadStatus, MarginType } from '~/framework/domain/typeAliases';

export const order$createOrdersSymbol = Symbol('order$createOrdersSymbol');

export interface ICreateOrdersApi {
  [order$createOrdersSymbol]: void;
  createOrders(data: ICreateOrderData[]): Promise<IResultOrderData[]>;
}

export interface ICreateOrderData {
  plan: OrderPlanInput;
  orderGroupId: string;
  generationSiteId: string;
  generationSiteTasks: CreateGenerationSiteTaskInput[];
  irregularTasks: CreateIrregularTaskInput[];
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignableCarIds: string[];
  assignableCarTypeIds: string[];
  carNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  attachmentsToAdd: File[];
  avoidHighways: boolean;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  checkItemIds: string[];
  routingGroup: OrderRoutingGroupInput | undefined;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: CreateOrderRecurringSettingsInput | undefined;
  status: OrderStatus;
}
