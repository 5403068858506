
import Vue from 'vue';
import { Maybe } from '~/framework/typeAliases';
import RAnnouncement from '~/components/common/r-announcement/RAnnouncement.vue';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import {
  AnnouncementApplicationService,
  announcementSymbol,
} from '~/framework/application/announcementApplicationService';
import { IAnnouncementData } from '~/framework/server-api/announcement/announcement';
import { RinEventNames, AdditionalInfoKeys } from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  value: boolean;
  announcements: IAnnouncementData[];
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'RAnnouncements',
  components: {
    RAnnouncement,
  },
  data(): DataType {
    return {
      value: false,
      announcements: [] as IAnnouncementData[],
      listenerDisposer: undefined,
    };
  },
  async mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };

    const announcementApplicationService: AnnouncementApplicationService =
      this.$context.applications.get(announcementSymbol);
    const results = await announcementApplicationService.getUnreadAnnouncements();
    if (results.length > 0) {
      this.announcements = results;
      this.value = true;

      this.$rinGtm.push(RinEventNames.OPEN_FORM, {
        [AdditionalInfoKeys.TARGET]: 'show_announcements',
      });
    }
  },
  methods: {
    onClickClose(): void {
      this.$rinGtm.push(RinEventNames.CLOSE_FORM, {
        [AdditionalInfoKeys.TARGET]: 'show_announcements',
      });
      this.value = false;
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        Vue.nextTick(this.onClickClose);
      }
      context.stop();
    },
  },
});
