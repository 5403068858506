import { google } from 'google-maps';

import { Maybe } from '~/framework/typeAliases';
import { wait } from '~/framework/async';

/**
 * Google Maps JavaScript API をラップしたもの
 * 具体実装では内部的にリトライが差し込まれていたりする
 */
export interface IGoogleMapAsync {
  /**
   * geocode した結果を返す
   * @param request
   */
  geocode(request: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[]>;

  /**
   * DistanceMatrix を返す
   * @param request
   */
  getDistanceMatrix(request: google.maps.DistanceMatrixRequest): Promise<google.maps.DistanceMatrixResponse>;
}

const MAX_RETRY = 5;
const RETRY_INTERVAL = 1000;

export interface IRetryStrategy {
  /**
   * リクエストに失敗した際にどれだけの間隔を空けて次のリクエストを送るべきか
   *
   * @param retried 1つのリクエストに対して何度リトライしたか、0 からはじまる
   */
  getRetryInterval(retried: number): number;

  /**
   * リトライが行われる前に呼ばれる
   */
  onRetry(): void;
}

/**
 * Exponential back-off の concrete strategy
 */
export class ExponentialBackoffStrategy implements IRetryStrategy {
  getRetryInterval(retried: number): number {
    return RETRY_INTERVAL * 2 ** retried;
  }

  onRetry(): void {}
}

export class GoogleMapAsync implements IGoogleMapAsync {
  private google: google;
  private retryStrategy: IRetryStrategy;

  constructor(google: google, retryStrategy?: Maybe<IRetryStrategy>) {
    this.google = google;
    this.retryStrategy = retryStrategy ?? new ExponentialBackoffStrategy();
  }

  async geocode(request: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[]> {
    const service = new this.google.maps.Geocoder();
    return (await this.retryGeocode(service, 0, request)).results;
  }

  async getDistanceMatrix(request: google.maps.DistanceMatrixRequest): Promise<google.maps.DistanceMatrixResponse> {
    const service = new this.google.maps.DistanceMatrixService();
    return await this.retryDistanceMatrix(service, 0, request);
  }

  private async retryGeocode(
    service: google.maps.Geocoder,
    tried: number,
    request: google.maps.GeocoderRequest
    // ): Promise<google.maps.GeocoderResponse> {
  ): Promise<any> {
    try {
      return (await (service.geocode(request, () => {}) as unknown)) as Promise<any>;
    } catch (e: any) {
      if (e.code === this.google.maps.GeocoderStatus.ZERO_RESULTS) {
        throw e;
      } else if (e.code !== this.google.maps.GeocoderStatus.OVER_QUERY_LIMIT && MAX_RETRY <= tried) {
        throw e;
      }
    }
    const interval = this.retryStrategy.getRetryInterval(tried);

    await wait(interval);
    this.retryStrategy.onRetry();
    return await this.retryGeocode(service, tried + 1, request);
  }

  private async retryDistanceMatrix(
    service: google.maps.DistanceMatrixService,
    tried: number,
    request: google.maps.DistanceMatrixRequest
  ): Promise<google.maps.DistanceMatrixResponse> {
    try {
      return await (service.getDistanceMatrix(
        request,
        () => {}
      ) as unknown as Promise<google.maps.DistanceMatrixResponse>);
    } catch (e: any) {
      if (e.code !== this.google.maps.DistanceMatrixStatus.OVER_QUERY_LIMIT || MAX_RETRY <= tried) {
        throw e;
      }
    }
    const interval = this.retryStrategy.getRetryInterval(tried);

    await wait(interval);
    this.retryStrategy.onRetry();
    return await this.retryDistanceMatrix(service, tried + 1, request);
  }
}
