
import Vue, { PropType } from 'vue';
import { CssStyles } from '~/framework/typeAliases';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100%',
    },
    content: {
      type: String as PropType<string>,
      required: true,
    },
    nudgeTop: {
      type: Number as PropType<number>,
      required: false,
      default: 20,
    },
    styles: {
      type: Object as PropType<CssStyles>,
      required: false,
      default: undefined,
    },
  },
});
