import { AllPackingStylesQuery, AllPackingStylesQueryVariables } from '@/graphql/graphQLServerApi';
import { AllPackingStyles } from '@/../graphql/server-api/queries/packing-style.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllPackingStylesQuery, AllPackingStylesQueryVariables>({
      query: AllPackingStyles,
      variables: {},
    });
    return result.allPackingStyles;
  }
}
