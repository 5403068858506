import { google } from 'google-maps';
import _ from 'lodash';
import { addressTypePriorityMap } from '~/framework/services/google-maps/addressTypePriority';
import { AddressType } from '~/framework/services/google-maps/addressType';
import { AddressComponentType } from '~/framework/services/google-maps/addressComponentType';
import { addressComponentTypePriorityMap } from '~/framework/services/google-maps/addressComponentTypePriority';

/**
 * Google Maps API が返す address_components と Rin の address 系をうまく変換するためのもの
 * 本当は google に依存すべきではないかもしれないが手間がめっちゃかかるのでいったん置き
 */
export interface IAddress {
  geometry: google.maps.GeocoderGeometry;
  components: google.maps.GeocoderAddressComponent[];
  /**
   * 住所タイプ
   */
  addressType: AddressType;
  /**
   * 住所のコンポーネントタイプ
   * 一番詳細なものが返される
   */
  addressComponentType: AddressComponentType;
  /**
   * 郵便番号
   */
  postalCode: string;
  /**
   * 住所要素を全て結合したもの
   */
  address: string;
  /**
   * 都道府県
   */
  address1: string;
  /**
   * 市区町村
   */
  address2: string;
  /**
   * 番地
   */
  address3: string;
  /**
   * 建物名
   */
  address4: string;
  /**
   * 登録できる住所
   */
  isAcceptableAddress: boolean;
  /**
   * ある程度正確で警告を出さなくても大丈夫そうな住所
   */
  isPreciseAddress: boolean;

  /**
   * ソート用に比較する
   * @param another
   */
  compare(another: IAddress): number;

  toString(): string;
}

export class Address implements IAddress {
  private addressTypes: AddressType[];
  private addressComponentTypes: AddressComponentType[];
  private _geocoderResult: google.maps.GeocoderResult;
  private _components: google.maps.GeocoderAddressComponent[];
  private _postalCode: string;
  private _address1: string;
  private _address2: string;
  private _address3: string;
  private _address4: string;
  isAcceptableAddress!: boolean;
  isPreciseAddress!: boolean;

  get components(): google.maps.GeocoderAddressComponent[] {
    return this._components;
  }

  get postalCode(): string {
    return this._postalCode;
  }

  get address(): string {
    const postalCode = this.postalCode === '' ? undefined : this.postalCode;
    const address1 = this.address1 === '' ? undefined : this.address1;
    const address2 = this.address2 === '' ? undefined : this.address2;
    const address3 = this.address3 === '' ? undefined : this.address3;
    const address4 = this.address4 === '' ? undefined : this.address4;
    const components = [];
    if (postalCode) components.push(postalCode);
    if (address1) components.push(address1);
    if (address2) components.push(address2);
    if (address3) components.push(address3);
    if (address4) components.push(address4);
    return components.join(' ');
  }

  get address1(): string {
    return this._address1;
  }

  get address2(): string {
    return this._address2;
  }

  get address3(): string {
    return this._address3;
  }

  get address4(): string {
    return this._address4;
  }

  get geometry(): google.maps.GeocoderGeometry {
    return this._geocoderResult.geometry;
  }

  get addressType(): AddressType {
    return _.first(this.addressTypes)!;
  }

  get addressComponentType(): AddressComponentType {
    return _.first(this.addressComponentTypes)!;
  }

  constructor(geocoderResult: google.maps.GeocoderResult) {
    this._geocoderResult = geocoderResult;
    this._components = geocoderResult.address_components;
    this._postalCode = this.getPostalCode();
    this._address1 = this.getAddress1();
    this._address2 = this.getAddress2();
    this._address3 = this.getAddress3();
    this._address4 = this.getAddress4();
    this.addressTypes = this.getAddressTypes();
    this.addressComponentTypes = this.getAddressComponentTypes();
    this.updateIsAcceptableAddress();
    this.updateIsPreciseAddress();
  }

  compare(another: IAddress): number {
    const anotherPriority = addressComponentTypePriorityMap.get(another.addressComponentType) || 0;
    const ourPriority = addressComponentTypePriorityMap.get(this.addressComponentType) || 0;
    return anotherPriority - ourPriority;
  }

  toString(): string {
    return JSON.stringify(this);
  }

  private updateIsAcceptableAddress() {
    // addressType や addressComponentType が仮に受け付けられるものであったとしてもごく稀に県が埋まっていない住所が
    // 返ってくることがあり、この場合に必須項目がなく問題になるのでそういう住所は弾ける様にしておく
    const hasRequiredFields = this.address1 !== '' && this.address2 !== '';

    this.isAcceptableAddress =
      this.addressTypes.some(
        (type) =>
          type === AddressType.StreetAddress ||
          type === AddressType.Subpremise ||
          type === AddressType.Premise ||
          type === AddressType.SublocalityLevel5 ||
          type === AddressType.SublocalityLevel4 ||
          type === AddressType.SublocalityLevel3 ||
          type === AddressType.SublocalityLevel2 ||
          type === AddressType.SublocalityLevel1 ||
          type === AddressType.Ward ||
          type === AddressType.Locality
      ) && hasRequiredFields;
    if (this.isAcceptableAddress) return;

    // AddressType は point_of_interest などの扱いがよく分からないものが返ってくる事がある
    // 現状はこれだと正確なアドレスではないと判断されてしまうので、こういった場合に
    // AddressComponentType で正確かどうかを追加で判断する事にする
    this.isAcceptableAddress =
      this.addressComponentTypes.some(
        (type) =>
          type === AddressComponentType.Subpremise ||
          type === AddressComponentType.Premise ||
          type === AddressComponentType.SublocalityLevel5 ||
          type === AddressComponentType.SublocalityLevel4 ||
          type === AddressComponentType.SublocalityLevel3 ||
          type === AddressComponentType.SublocalityLevel2 ||
          type === AddressComponentType.SublocalityLevel1 ||
          type === AddressComponentType.Ward ||
          type === AddressComponentType.Locality
      ) && hasRequiredFields;
  }

  private updateIsPreciseAddress() {
    this.isPreciseAddress = this.addressTypes.some(
      (type) => type === AddressType.StreetAddress || type === AddressType.Premise || type === AddressType.Subpremise
    );
    if (this.isPreciseAddress) return;

    // AddressType は point_of_interest などの扱いがよく分からないものが返ってくる事がある
    // 現状はこれだと正確なアドレスではないと判断されてしまうので、こういった場合に
    // AddressComponentType で正確かどうかを追加で判断する事にする
    this.isPreciseAddress = this.addressComponentTypes.some(
      (type) => type === AddressComponentType.Subpremise || type === AddressComponentType.Premise
    );
  }

  /**
   * geocoderResult の types から AddressTypes を取得する
   * @private
   */
  private getAddressTypes(): AddressType[] {
    return this._geocoderResult.types
      .map((type) => type as AddressType)
      .sort((a, b) => {
        // 数字が大きい方が優先される
        const priorityA = addressTypePriorityMap.get(a) || 0;
        const priorityB = addressTypePriorityMap.get(b) || 0;
        return priorityB - priorityA;
      });
  }

  /**
   * address_components から、AddressComponentType を取得する
   * @private
   */
  private getAddressComponentTypes(): AddressComponentType[] {
    const typesSet = new Set<AddressComponentType>();
    for (const component of this.components) {
      typesSet.addValues(...component.types.map((type) => type as AddressComponentType));
    }
    return typesSet.toArray().sort((a, b) => {
      // 数字が大きい方が優先される
      const priorityA = addressComponentTypePriorityMap.get(a) || 0;
      const priorityB = addressComponentTypePriorityMap.get(b) || 0;
      return priorityB - priorityA;
    });
  }

  private getPostalCode(): string {
    const postalCode = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.PostalCode).length
    );
    return postalCode ? postalCode.long_name : '';
  }

  private getAddress1(): string {
    const administrativeAreaLevel1 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.AdministrativeAreaLevel1).length
    );
    return administrativeAreaLevel1 ? administrativeAreaLevel1.long_name : '';
  }

  private getAddress2(): string {
    const administrativeAreaLevel2 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.AdministrativeAreaLevel2).length
    );

    const locality = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.Locality).length
    );

    const ward = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.Ward).length
    );

    const sublocalityLevel1 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.SublocalityLevel1).length
    );

    const sublocalityLevel2 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.SublocalityLevel2).length
    );

    const components = [];
    if (administrativeAreaLevel2) components.push(administrativeAreaLevel2.long_name);
    if (locality) components.push(locality.long_name);
    if (ward) components.push(ward.long_name);
    if (sublocalityLevel1) components.push(sublocalityLevel1.long_name);
    if (sublocalityLevel2) components.push(sublocalityLevel2.long_name);

    return components.join(' ');
  }

  private getAddress3(): string {
    const sublocalityLevel3 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.SublocalityLevel3).length
    );

    const sublocalityLevel4 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.SublocalityLevel4).length
    );

    const sublocalityLevel5 = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.SublocalityLevel5).length
    );

    const premise = _.filter(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.Premise).length
    ).reverse();

    const components = [];
    if (sublocalityLevel3) components.push(sublocalityLevel3.long_name);
    if (sublocalityLevel4) components.push(sublocalityLevel4.long_name);
    if (sublocalityLevel5) components.push(sublocalityLevel5.long_name);
    if (premise) components.push(...premise.map((component) => component.long_name));

    return this.joinAddressComponents(components);
  }

  private getAddress4(): string {
    const subpremise = _.find(
      this._components,
      (component) => 0 < component.types.filter((type) => type === AddressComponentType.Subpremise).length
    );

    const components = [];
    if (subpremise) components.push(subpremise.long_name);

    return components.join(' ');
  }

  private joinAddressComponents(components: string[]): string {
    let address = '';
    let isLastComponentNumeric = false;
    for (const component of components) {
      const isNumeric = component.match(/^([0-9０-９]+)$/) !== null;
      if (isLastComponentNumeric && isNumeric) address = `${address}–${component}`;
      else address = `${address} ${component}`;
      isLastComponentNumeric = isNumeric;
    }
    return address;
  }
}
