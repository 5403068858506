import { Resolvers } from '@/graphql/graphQLLocalState';
import { DataProxy } from 'apollo-cache';
import {
  Mutations as SampleMutations,
  Queries as SampleQueries,
  setCacheDefaultValue as setSampleDefaultValue,
} from './local-state/sample';
import {
  Mutations as UserSettingMutations,
  Queries as UserSettingQueries,
  setCacheDefaultValue as setUserSettingDefaultValue,
} from './local-state/userSetting';

// キャッシュ内の ID を取得したい場合はこれを利用する
// 通常使っている限りは今のところ利用しないで済みそう
// import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

/**
 * Resolver の context に型を追加したい場合はここに定義する
 * ref: https://graphql-code-generator.com/docs/plugins/typescript-resolvers
 */
export interface ApolloContext {
  /**
   * ローカルステートのキャッシュ
   */
  cache: DataProxy;
  /**
   * __typename と id を渡してキャッシュ上のキーを取得する
   *
   * @param obj 取得したいキーの情報、__typename: GraphQL の型名、id: レコードの ID
   */
  getCacheKey: (obj: { __typename: string; id: string | number }) => string;
}

/**
 * ローカルステートの初期状態を定義する。
 *
 * @param cache
 */
export function setCacheDefaultValue(cache: DataProxy): void {
  setSampleDefaultValue(cache);
  setUserSettingDefaultValue(cache);
}

/**
 * ローカルステートのリゾルバを定義する
 * NOTE 全部のリゾルバを定義している訳ではない
 */
type LocalStateResolvers = Pick<Resolvers, 'Query' | 'Mutation'>;
export const resolvers: LocalStateResolvers = {
  Mutation: {
    ...SampleMutations,
    ...UserSettingMutations,
  },
  Query: {
    ...SampleQueries,
    ...UserSettingQueries,
  },
};
