import { AllCollectablePeriodTemplates } from '@/../graphql/server-api/queries/collectable-period-template.gql';
import {
  AllCollectablePeriodTemplatesQuery,
  AllCollectablePeriodTemplatesQueryVariables,
  CreateCollectablePeriodTemplatesMutation,
  CreateCollectablePeriodTemplatesMutationVariables,
  UpdateCollectablePeriodTemplatesMutation,
  UpdateCollectablePeriodTemplatesMutationVariables,
} from '@/graphql/graphQLServerApi';
import {
  CreateCollectablePeriodTemplates,
  UpdateCollectablePeriodTemplates,
} from '@/../graphql/server-api/mutations/collectable-period-template.gql';
import { ICollectablePeriodTemplateData } from '~/framework/server-api/masters/collectablePeriodTemplate';

import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

import { WithoutId } from '~/framework/typeAliases';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllCollectablePeriodTemplatesQuery, AllCollectablePeriodTemplatesQueryVariables>({
      query: AllCollectablePeriodTemplates,
      variables: {},
    });
    return result.allCollectablePeriodTemplates;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: ICollectablePeriodTemplateData[]) {
    const result = await this.mutate<
      UpdateCollectablePeriodTemplatesMutation,
      UpdateCollectablePeriodTemplatesMutationVariables
    >({
      mutation: UpdateCollectablePeriodTemplates,
      variables: {
        collectablePeriodTemplates: data,
      },
    });
    this.validateArrayConsistency(data, result.updateCollectablePeriodTemplates, `UpdateCollectablePeriodTemplates`);
    return result.updateCollectablePeriodTemplates;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: WithoutId<ICollectablePeriodTemplateData>[]) {
    const result = await this.mutate<
      CreateCollectablePeriodTemplatesMutation,
      CreateCollectablePeriodTemplatesMutationVariables
    >({
      mutation: CreateCollectablePeriodTemplates,
      variables: {
        collectablePeriodTemplates: data,
      },
    });
    this.validateArrayConsistency(data, result.createCollectablePeriodTemplates, `CreateCollectablePeriodTemplates`);
    return result.createCollectablePeriodTemplates;
  }
}
