
import Vue, { PropType } from 'vue';
import { IRRadioGroupItem } from './rRadioGroup';
import { CssClasses, ValidationRule } from '~/framework/typeAliases';
import RVRadio from '~/components/common/r-radio-group/RVRadio.vue';

enum EventTypes {
  Change = 'change',
}

type DataType = {
  key: number;
};

export default Vue.extend({
  name: 'RRadioGroup',
  components: {
    RVRadio,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    hideLabel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    value: {
      type: undefined as any as PropType<any>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    items: {
      type: Array as PropType<Array<IRRadioGroupItem<any>>>,
      required: true,
      default() {
        return [];
      },
    },
    // 指定された index の要素の右側にボーダーを表示する
    borderIndex: {
      type: Array as PropType<Array<number>>,
      required: false,
      default() {
        return [];
      },
    },
    isBreak: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    rules: {
      type: Array as PropType<Array<ValidationRule>>,
      required: false,
      default: undefined,
    },
    autofocus: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
    errorMessages: {
      type: [String, Array] as PropType<string | string[]>,
      default: () => [],
    },
  },
  data(): DataType {
    return { key: 1 };
  },
  computed: {
    labelClass(): CssClasses {
      return {
        'r-radio-group__label': true,
        'r-radio-group__label--disabled': this.disabled,
      };
    },
  },
  methods: {
    onChange(changeArgs: any): void {
      this.$emit(EventTypes.Change, changeArgs);
      this.key++;
    },
  },
});
