import { ApiType, ApiTypes } from '~/framework/server-api/apiTypes';
import { Instantiator } from '~/framework/typeAliases';

export class ServerApiManager {
  private readonly map: Map<symbol, Instantiator<unknown>>;

  constructor() {
    this.map = new Map<symbol, Instantiator<unknown>>();
  }

  get<Type extends keyof ApiTypes>(type: Type): ApiType<Type> {
    return (this.map.getOrError(type) as Instantiator<ApiType<Type>>)();
  }

  add<Type extends keyof ApiTypes>(type: Type, instantiator: Instantiator<ApiType<Type>>): void {
    this.map.set(type, instantiator);
  }
}
