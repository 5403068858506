import { ICollectablePeriodTemplateData } from '~/framework/server-api/masters/collectablePeriodTemplate';
import { Maybe } from '~/framework/typeAliases';

export class CollectablePeriodTemplateEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  note: Maybe<string>;
  isDefault: boolean;

  constructor(data: ICollectablePeriodTemplateData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.collectablePeriodStart = data.collectablePeriodStart;
    this.collectablePeriodEnd = data.collectablePeriodEnd;
    this.note = data.note;
    this.isDefault = data.isDefault;
  }

  update(data: ICollectablePeriodTemplateData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of CollectablePeriodTemplateEntity');
    }
    this.name = data.name;
    this.collectablePeriodStart = data.collectablePeriodStart;
    this.collectablePeriodEnd = data.collectablePeriodEnd;
    this.note = data.note;
    this.isDefault = data.isDefault;
  }
}
