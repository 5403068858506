import { GraphQLScalarType, Kind, print } from 'graphql';
import { JsonObject, Maybe } from '~/framework/typeAliases';
import {
  RawCollectionJsonObjectWithId,
  RawDisposalJsonObjectWithId,
  RawMiscJsonObject,
  RawRouteJsonObject,
  RawRouteJsonObjectMaybeWithId,
  RawRouteJsonObjectWithId,
  RawInconsistencyJsonObject,
  RawScheduleInfeasibilityJsonObject,
  RawScheduleJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { convertNullToUndefined } from '~/framework/property';
import { EntityIdGenerateFunctor } from '~/framework/systemContext';
import { IdGenerator } from '~/framework/core/id';

export class ScheduleJsonObject implements RawScheduleJsonObject<RawRouteJsonObjectWithId> {
  private readonly idGenerator: EntityIdGenerateFunctor = IdGenerator.generateNewId;

  // NOTE フィールドとして定義されていないと Apollo から書き出されない
  // getter, setter では動かないので注意
  // serialize の方に押し込むのも手かもしれない
  routes: RawRouteJsonObjectWithId[];
  infeasibilities: Maybe<RawScheduleInfeasibilityJsonObject[]>;
  inconsistencies: Maybe<RawInconsistencyJsonObject[]>;
  misc: Maybe<RawMiscJsonObject>;

  constructor(data: JsonObject) {
    // NOTE 型としては Maybe としているものの、実際の JSON では null が入ってくる事があるため、
    // 実際に使う場合には convertNullToUndefined などを行って null を undefined に変換しておく
    // 必要がある事に注意する必要がある。
    const converted = convertNullToUndefined(data) as RawScheduleJsonObject<RawRouteJsonObjectMaybeWithId>;
    // waiting なものなど、routes が存在しない可能性があるのでなんでもかんでも補完できる訳ではない
    if (converted.routes) {
      this.complementCarIndex(converted.routes);
      this.routes = this.complementId(converted.routes);
    } else {
      this.routes = [];
    }
    this.infeasibilities = converted.infeasibilities;
    this.inconsistencies = converted.inconsistencies;
    this.misc = converted.misc;
  }

  /**
   * v1 などの car_index がないデータは新しい ScheduleFactory ではビルドに失敗するので
   * ここで仮のデータを詰めて落ちない様にする。そもそも car_index は基本的にはちゃんと
   * 詰まった状態で最適化から出力されるのでこれは必要ない。car_id があるのに car_index がない
   * という状態のものだけが該当するので、そういうものだけ補完する。car_id も car_index もない
   * ルートはクライアント側で人をまたいだ案件の移動をやった時に生成された仮のルート。
   * 破壊的なので注意。
   *
   * @param routes
   * @private
   */
  private complementCarIndex(routes: RawRouteJsonObject[]): void {
    let carIndex: number = 0;
    let lastDriverId: Maybe<string>;
    for (const route of routes) {
      if (lastDriverId !== route.driver_id) carIndex = 0;
      if (route.car_id !== undefined && route.car_index === undefined) route.car_index = carIndex++;
      lastDriverId = route.driver_id;
    }
  }

  /**
   * 本来最適化エンジンから書き出される IRawRouteJsonObject には _id は含まれていない。
   * しかし再最適化した際に元となった route と編集後の route の同一性を判定できる基準がないと
   * どの様に編集されたのかが分からないため、フロントエンド側で _id を振って対応している。
   * そもそも最適化エンジンから id が吐き出されて欲しい感じもあるが、旧データには _id は含まれていないので
   * 結局のところフロントエンドで振り直すしかなく、同じ事になる気がしたのでフロントエンドで振っている。
   *
   * もし既に _id が存在する場合はそれは作成不可が出ていて以前から存在する route なのでその _id は
   * そのままにしておく必要がある。misc の方に入っている original の _id と対応しているため。
   *
   * @param routes
   * @private
   */
  private complementId(routes: RawRouteJsonObjectMaybeWithId[]): RawRouteJsonObjectWithId[] {
    const complementedRoutes: RawRouteJsonObjectWithId[] = [];
    for (const route of routes) {
      const complementedCollections: RawCollectionJsonObjectWithId[] = [];
      for (const collection of route.collections) {
        complementedCollections.push({
          ...collection,
          _id: collection._id ?? this.idGenerator(),
        });
      }
      const complementedDisposals: RawDisposalJsonObjectWithId[] = [];
      for (const disposal of route.disposals) {
        complementedDisposals.push({
          ...disposal,
          _id: disposal._id ?? this.idGenerator(),
        });
      }
      complementedRoutes.push({
        ...route,
        _id: route._id ?? this.idGenerator(),
        collections: complementedCollections,
        disposals: complementedDisposals,
      });
    }
    return complementedRoutes;
  }
}

/**
 * 配車表のデータフォーマット
 */
export const ScheduleJsonObjectScalarType = new GraphQLScalarType({
  name: 'ScheduleJsonObject',
  /**
   * 内部値（フロントエンド側）を出力用（サーバー側）の値に変換する
   * @param value
   */
  serialize: (value: RawScheduleJsonObject<RawRouteJsonObjectWithId>): any => {
    // infeasibilities はサーバー側には返さないので routes, misc だけに絞っておく
    return { routes: value.routes, misc: value.misc };
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param value
   */
  parseValue: (value: JsonObject): RawScheduleJsonObject<RawRouteJsonObjectMaybeWithId> => {
    return new ScheduleJsonObject(value);
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param valueAST
   */
  parseLiteral: (valueAST): ScheduleJsonObject => {
    // NOTE いつどう呼ばれるのかいまいち分からずテストができていない
    if (valueAST.kind === Kind.OBJECT) {
      const str = print(valueAST);
      const obj = JSON.parse(str);
      return new ScheduleJsonObject(obj);
    }
    throw new Error(`ScheduleJsonObject should be Object type!`);
  },
});
