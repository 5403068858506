import { AllTaskTypes } from '@/../graphql/server-api/queries/task-type.gql';
import { CreateTaskType, UpdateTaskType } from '@/../graphql/server-api/mutations/task-type.gql';
import {
  AllTaskTypesQuery,
  CreateTaskTypeMutation,
  CreateTaskTypeMutationVariables,
  UpdateTaskTypeMutation,
  UpdateTaskTypeMutationVariables,
} from '@/graphql/graphQLServerApi';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateData, IUpdateData } from '~/framework/server-api/masters/taskType';

export class TaskTypeApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllTaskTypesQuery>({
      query: AllTaskTypes,
      variables: {},
    });
    return result.allTaskTypes;
  }

  async update(taskType: IUpdateData) {
    const result = await this.mutate<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>({
      mutation: UpdateTaskType,
      variables: { taskType },
    });
    return result.updateTaskType;
  }

  async create(taskType: ICreateData) {
    const result = await this.mutate<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>({
      mutation: CreateTaskType,
      variables: { taskType },
    });
    return result.createTaskType;
  }
}
