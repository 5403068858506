import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { AggregatedDriverAttendanceStore } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceStore';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { AttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { AggregatedDriverMapper } from '~/framework/domain/masters/driver/aggregatedDriverMapper';
import { AggregatedDriverStore } from '~/framework/domain/masters/driver/aggregatedDriverStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { IDriverAttendanceData } from '~/framework/server-api/masters/driverAttendance';

export class AggregatedDriverAttendanceMapper extends StoredMapperBase<
  IDriverAttendanceData,
  AggregatedDriverAttendanceEntity
> {
  protected store: AggregatedDriverAttendanceStore;
  private attendanceMapper: AttendanceMapper;
  private driverMapper: AggregatedDriverMapper;
  private carMapper: AggregatedCarMapper;

  constructor(
    store: AggregatedDriverAttendanceStore,
    attendanceStore: AttendanceStore,
    driverStore: AggregatedDriverStore,
    carStore: AggregatedCarStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    baseSiteStore: AggregatedBaseSiteStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
    this.driverMapper = new AggregatedDriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.carMapper = new AggregatedCarMapper(carStore, baseSiteStore, userStore);
  }

  protected instantiateWithData(data: IDriverAttendanceData): AggregatedDriverAttendanceEntity {
    const attendance = this.attendanceMapper.mapSingle(data.attendance);
    const driver = this.driverMapper.mapSingle(data.driver);
    const primaryCar = this.carMapper.mapSingle(data.primaryCar);
    return new AggregatedDriverAttendanceEntity(data, attendance, driver, primaryCar);
  }

  protected updateWithData(data: IDriverAttendanceData, entity: AggregatedDriverAttendanceEntity): void {
    const attendance = this.attendanceMapper.mapSingle(data.attendance);
    const driver = this.driverMapper.mapSingle(data.driver);
    const primaryCar = this.carMapper.mapSingle(data.primaryCar);
    entity.update(data, attendance, driver, primaryCar);
  }
}
