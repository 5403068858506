import { IOpenPanelOption } from '~/framework/view-models/panels/panel';
import { IGenericObjectPanel } from '~/framework/view-models/panels/genericObjectPanel';
import { AbstractGenericObjectPanel } from '~/framework/view-models/panels/abstractGenericObjectPanel';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

export const BaseSiteTask: unique symbol = Symbol(`Means tasks at base site`);

export interface IOpenData extends IOpenPanelOption {
  taskType: TaskTypeEntity;
}

export interface ICloseData {
  taskType: TaskTypeEntity;
  isUpdated: boolean;
}

export interface IContainerTaskFormPanel extends IGenericObjectPanel<IOpenData, ICloseData> {}

export class ContainerTaskFormPanel
  extends AbstractGenericObjectPanel<IOpenData, ICloseData>
  implements IContainerTaskFormPanel
{
  protected isDataSame(a: IOpenData, b: IOpenData): boolean {
    return a.taskType === b.taskType;
  }
}
