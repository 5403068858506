import { v4 } from 'uuid';
import { Maybe } from '~/framework/typeAliases';

export enum ShowEventNames {
  Click = 'click',
  Mouseenter = 'mouseenter',
}

export enum HideEventNames {
  Click = 'click',
  Mouseleave = 'mouseleave',
}

export interface ITooltipDisplayData {
  /**
   * Tooltip identifier.
   */
  id: string;
  /**
   * Tooltip is visible when true.
   */
  active: boolean;
  /**
   * Display position X of tooltip on the viewport.
   */
  positionX: Maybe<number>;
  /**
   * Display position Y of tooltip on the viewport.
   */
  positionY: Maybe<number>;

  /**
   * Show tooltip at the specified position.
   */
  showTooltip: (positionX: number, positionY: number) => void;
  /**
   * Hide tooltip.
   */
  hideTooltip: () => void;
}

export class TooltipDisplayData implements ITooltipDisplayData {
  id: string;
  active: boolean;
  positionX: Maybe<number>;
  positionY: Maybe<number>;

  constructor() {
    this.id = v4();
    this.active = false;
  }

  public showTooltip(positionX: number, positionY: number) {
    this.positionX = positionX;
    this.positionY = positionY;
    this.active = true;
  }

  public hideTooltip() {
    this.active = false;
    // tooltip を閉じる animation が壊れるため `positionX` & `positionY` を `undefined` に戻さない。
  }
}
