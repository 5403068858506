import { Maybe } from '~/framework/typeAliases';
import { SiteJsonObject, SiteType } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class Site {
  siteType: SiteType;
  siteId: Maybe<string>;
  constructor(site: SiteJsonObject) {
    this.siteType = site?.site_type;
    this.siteId = site?.site_id?.toPseudoId().value;
    this.sitePseudoId = site?.site_id;
  }

  sitePseudoId: Maybe<string>;

  getSiteJsonObject(): SiteJsonObject {
    return {
      site_type: this.siteType,
      site_id: this.sitePseudoId,
    };
  }
}
