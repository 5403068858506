
import Vue, { PropType } from 'vue';
import { OrderRecurringSettings } from '~/framework/server-api/typeAliases';
import { getStringFrom } from '~/framework/view-models/recurringOrderSettings';

export default Vue.extend({
  name: 'RScheduleOrderRecurringSettings',
  props: {
    recurringSettings: {
      type: Object as PropType<OrderRecurringSettings>,
      required: true,
    },
  },
  computed: {
    recurringSettingsString(): string {
      return getStringFrom(this.recurringSettings);
    },
  },
});
