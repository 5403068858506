
import Vue from 'vue';

enum EventTypes {
  ClickClose = 'click:close',
  ClickCreateSchedule = 'click:create-schedule',
}

export default Vue.extend({
  name: 'RAcceptanceCheckResultUnstable',
  methods: {
    onClickCloseButton(): void {
      this.$emit(EventTypes.ClickClose, false);
    },
    onClickCreateScheduleButton(): void {
      this.$emit(EventTypes.ClickCreateSchedule);
    },
  },
});
