
import Vue, { PropType } from 'vue';
import { IRPopupMenuItem } from './rPopupMenu';

enum EventTypes {
  ClickListItem = 'click:list-item',
}

export default Vue.extend({
  name: 'RPopupMenu',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    items: {
      type: Array as PropType<Array<IRPopupMenuItem>>,
      required: false,
      default: () => [],
    },
  },
  methods: {
    onClickListItem(item: IRPopupMenuItem) {
      this.$emit(EventTypes.ClickListItem, item);
    },
    onInput(value: boolean) {
      this.$emit('input', value);
    },
  },
});
