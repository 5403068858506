import { PublishingStatus } from '~/framework/server-api/typeAliases';

export const schedule$updatePublishingStateSymbol = Symbol('schedule$updatePublishingStateSymbol');

export interface IUpdatePublishingStateApi {
  [schedule$updatePublishingStateSymbol]: void;
  updatePublishingStatusOfSchedule(publishingStatusData: IUpdatePublishingStatusInputData): Promise<string>;
}

export interface IUpdatePublishingStatusInputData {
  orderGroupId: string;
  date: Date;
  status: PublishingStatus;
}
