/**
 * 値が empty であるかを検証する
 * 配列を期待するフィールドの場合、配列に要素がない事を検証する
 * @param value
 */
export const isEmpty = (value: any): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return !value;
  }
};

/**
 * 値が0を含む自然数となるようにサニタイズする。
 * @param value
 */
export function sanitizeNaturalNumber(value: number | string): number {
  const number = Number(value);
  if (isNaN(number)) {
    return 0;
  }

  if (number < 0) {
    return 0;
  }

  return number;
}

/**
 * 値が 0 ~ 23 時間となるようにサニタイズする。
 * @param value
 */
export function sanitizeHours(value: number | string): number {
  const hoursNum = sanitizeNaturalNumber(value);
  if (hoursNum > 23) {
    return 23;
  }

  return hoursNum;
}

/**
 * 値が 0 ~ 59 時間となるようにサニタイズする。
 * @param value
 */
export function sanitizeMinutes(value: number | string): number {
  const minutesNum = sanitizeNaturalNumber(value);
  if (minutesNum > 59) {
    return 59;
  }

  return minutesNum;
}
