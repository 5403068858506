import { OrderRecurringSettings } from '~/framework/server-api/typeAliases';
import { OrderRecurringSettingsType, DayOfWeek } from '~/framework/domain/typeAliases';
import { dayOfWeekToIndex, dayOfWeekToLabel } from '~/framework/view-models/daysOfWeek';
import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';
import { dateToYymmdd } from '~/framework/services/date/date';
import { createLogger } from '~/framework/logger';
import { weekdayOrdinalToLabel } from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/weekdayOrdinal';
import { sortDaysOfMonth } from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettings';

/**
 * 表示上の重み付け
 * 表示上は月曜日が先頭に来ているのでそうなるようにするためのもの
 */
const dayOfWeekWeight = new Map([
  [DayOfWeek.Monday, 0],
  [DayOfWeek.Tuesday, 1],
  [DayOfWeek.Wednesday, 2],
  [DayOfWeek.Thursday, 3],
  [DayOfWeek.Friday, 4],
  [DayOfWeek.Saturday, 5],
  [DayOfWeek.Sunday, 6],
]);

const logger = createLogger('recurringSettings');

export const getStringFrom = (recurringSettings: OrderRecurringSettings): string => {
  if (recurringSettings === undefined) {
    logger.warn('recurringSettings is undefined');
    return '';
  } else if (
    recurringSettings.type !== OrderRecurringSettingsType.Daily &&
    recurringSettings.type !== OrderRecurringSettingsType.Weekly &&
    recurringSettings.type !== OrderRecurringSettingsType.Monthly
  ) {
    logger.warn(`type is ${recurringSettings.type}, impossible. ${JSON.stringify(recurringSettings)}`);
    return '';
  }

  const endDateString = recurringSettings.endAt ? `\n${dateToYymmdd(recurringSettings.endAt)}まで` : '';
  const nationalHolidays = recurringSettings.includeNationalHolidays ? '' : '\n（祝日は除く）';

  if (recurringSettings.type === OrderRecurringSettingsType.Daily) {
    const dayString = recurringSettings.step === 1 ? '毎日' : `${recurringSettings.step}日ごと`;
    return `${dayString}${nationalHolidays}${endDateString}`;
  } else if (recurringSettings.type === OrderRecurringSettingsType.Weekly) {
    const daysOfWeek = recurringSettings.daysOfWeek
      ?.sort((a, b) => dayOfWeekWeight.getOrError(a) - dayOfWeekWeight.getOrError(b))
      .map((day) => daysOfWeekMap[dayOfWeekToIndex(day)] + '曜');
    const daysOfWeekString = daysOfWeek ? '、' + daysOfWeek.join('、') : '';
    const weekString =
      recurringSettings.step === 1 ? '毎週' : recurringSettings.step === 2 ? '隔週' : `${recurringSettings.step}週ごと`;
    return `${weekString}${daysOfWeekString}${nationalHolidays}${endDateString}`;
  } else if (recurringSettings.type === OrderRecurringSettingsType.Monthly) {
    if (recurringSettings.daysOfMonth === undefined) throw new Error('Impossible!');
    const daysOfMonth: string[] = recurringSettings.daysOfMonth
      .sort(sortDaysOfMonth)
      .map(
        (dayOfMonth) => `${weekdayOrdinalToLabel(dayOfMonth.weekdayOrdinal)}${dayOfWeekToLabel(dayOfMonth.dayOfWeek)}曜`
      );
    const daysOfMonthString: string = daysOfMonth.join('、');

    const monthString = recurringSettings.step === 1 ? '毎月 ' : `${recurringSettings.step}カ月ごと `;

    return `${monthString}${daysOfMonthString}${nationalHolidays}${endDateString}`;
  } else {
    throw new Error(`Impossible!`);
  }
};
