
import Vue, { PropType } from 'vue';
import { CssClasses, CssStyles, Maybe } from '~/framework/typeAliases';

export interface IRButtonGroupItem {
  label: string;
  value: any;
  width?: Maybe<number>;
  disabled?: Maybe<boolean>;
}

interface IRButtonGroupInnerItem extends IRButtonGroupItem {
  color: string;
  classes: CssClasses;
  isSelected: boolean;
}

class RButtonGroupItem implements IRButtonGroupInnerItem {
  label: string;
  value: any;
  width: Maybe<number>;
  disabled: Maybe<boolean>;
  color: string;
  classes: CssClasses;
  _isSelected: boolean;

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
    this.updateColor();
    this.updateClasses();
  }

  constructor(label: string, value: any, width: Maybe<number>, disabled: Maybe<boolean>) {
    this.label = label;
    this.value = value;
    this.width = width;
    this.disabled = disabled;
    this.color = 'primary';
    this._isSelected = false;
    this.classes = {};
    this.updateColor();
    this.updateClasses();
  }

  private updateColor(): void {
    this.color = this.isSelected ? 'primary' : '';
  }

  private updateClasses(): void {
    this.classes = {
      'r-button-group__button': true,
      'r-button-group__button--selected': this.isSelected,
      'r-button-group__button--not-selected': !this.isSelected,
      'r-button-group__button--width-specific': this.width !== undefined,
      'px-0': true,
    };
  }
}

type DataType = {
  innerValue: any;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RButtonGroup',
  model: {
    event: 'change',
    prop: 'innerValue',
  },
  props: {
    items: {
      type: Array as PropType<IRButtonGroupItem[]>,
      required: true,
    },
    value: {
      type: undefined,
      required: true,
    },
    height: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      innerValue: this.value,
    };
  },
  computed: {
    buttonGroupClasses(): CssClasses {
      return {
        'r-button-group': true,
        'elevation-4': true,
      };
    },
    buttonGroupStyles(): CssStyles {
      return {
        height: this.height,
      };
    },
    itemsValue(): IRButtonGroupInnerItem[] {
      // NOTE おそらく完全にはリアクティブではない
      // 中の label とかだけ書き換えた場合は反応しない可能性があるが、そういう要望はいまのところない
      // と思うのでいったん置きにしておく。そういう必要が出た時にリファクタ
      const itemsValue: IRButtonGroupInnerItem[] = [];
      for (const item of this.items) {
        const itemValue = new RButtonGroupItem(item.label, item.value, item.width, item.disabled);
        itemValue.isSelected = itemValue.value === this.value;
        itemsValue.push(itemValue);
      }
      return itemsValue;
    },
  },
  methods: {
    onButtonClick(clickedIndex: number): void {
      for (let index = 0; index < this.itemsValue.length; index++) {
        this.itemsValue[index].isSelected = index === clickedIndex;
      }
      const newValue = this.itemsValue[clickedIndex].value;
      this.innerValue = newValue;
      this.$emit(EventTypes.Change, newValue);
    },
  },
});
