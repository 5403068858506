// .vueファイルにDateCollectablePeriodTypesを定義すると
// framework/view-models/panels/orderFormPanel.tsのテストで落ちるため、ここに定義する
enum DateCollectablePeriodTypes {
  // デフォルト、単数候補日入力状態
  Default,
  // 繰り返し受注
  Recurring,
  // 複数候補日入力状態
  Plan,
  // 候補日の一括登録
  BatchRegister,
  Reset,
}
export default DateCollectablePeriodTypes;
