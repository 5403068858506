
import Vue, { PropType } from 'vue';
import {
  isValidFormatHhMm,
  secsToHhMm,
  strToHHMMorBlank,
  getHoursOf,
  getTimeRangeName,
} from '~/framework/services/date-time/date-time';
import { required } from '~/framework/view-models/rules';
import { HardLimitTime } from '~/framework/constants';
import { CssStyles, ValidationRule } from '~/framework/typeAliases';

const selectableItem = [...Array(getHoursOf(HardLimitTime)).keys()]
  .map((hh) => {
    const mm = ['00', '30'];
    return mm.map((mm) => {
      return {
        text: `${getTimeRangeName(hh)} ${hh}:${mm}`,
        value: `${hh.toString().padStart(2, '0')}:${mm}`,
      };
    });
  })
  .flat(2);

export default Vue.extend({
  props: {
    // v-model したときに受け取るのはこの値
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    outputMode: {
      type: String as PropType<'secs' | 'hhmm'>,
      default: 'hhmm',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    hideDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    // 開始時間として時間が設定されている場合に初期スクロール位置をその時間にする為に利用する
    // すでに値が入っている場合にはそこの値は利用しない
    scrollTargetValue: {
      type: String as PropType<string>,
      required: false,
      default: '12:00',
    },
  },
  data() {
    return {
      selectableItem,
      isShowMousePicker: false,
      mousePickerHeight: 300,
      mousePickerWidth: 100,
      // スクロール対象を取得する前の待ち時間
      scrollWaitTime: 100,
      displayValue:
        typeof this.value === 'number'
          ? secsToHhMm(this.value)
          : typeof this.value === 'string'
          ? strToHHMMorBlank(this.value)
          : '',
    };
  },
  computed: {
    isValid(): boolean {
      // 必須ではない場合は値が入力されていなくても通す
      // 例：入力の片側のみでも大丈夫な場合等
      return this.displayValue !== '' || !this.required || this.disabled;
    },
    rules(): ValidationRule[] {
      // disabledの場合は必須ではない
      if (this.required && !this.disabled) {
        return [required];
      }
      return [];
    },
    pickerStyles(): CssStyles {
      return {
        ...(this.width ? { width: this.width } : {}),
      };
    },
    // hideDetailsにしていない場合、エラーメッセージ表示領域の分だけ下にずれるのでその分を補正する
    menuNudgeBottom(): number {
      return this.hideDetails ? 0 : -20;
    },
  },
  watch: {
    // props経由で value が変更されたら補正して changeイベントを投げる
    value(value) {
      this.displayValue = this.convertValidValue(value);
    },
    async isShowMousePicker(value) {
      if (!value) return;

      await this.initializeMousePicker();
    },
  },
  methods: {
    /**
     * 空文字かHH:MMかを確定してくれる
     * @param value
     */
    convertValidValue(value: string | number | null | undefined): string {
      if (value === null || value === undefined) return '';
      if (typeof value === 'number') return secsToHhMm(value);
      return strToHHMMorBlank(value);
    },
    emitChange() {
      if (this.displayValue === null || this.displayValue === undefined) {
        return;
      }

      // 必須でない場合は未入力状態での emit を許容する
      if (!(this.isValid || this.required)) {
        this.$emit('change', undefined);
        return;
      }

      if (this.outputMode === 'hhmm') {
        if (isValidFormatHhMm(this.displayValue)) this.$emit('change', this.displayValue);
        return;
      }
      if (this.outputMode === 'secs') {
        this.$emit('change', this.displayValue.toSecs());
      }
    },
    async initializeMousePicker() {
      await this.$nextTick();
      // Menuのホバー要素が描画されるまで少し時間がかかる、２５だとたまに要素が取得できないおそらく環境依存で余裕を持って100にしている
      await new Promise((resolve) => setTimeout(resolve, this.scrollWaitTime));

      // 設定されている時刻がある場合はそれを使う、ターゲットの時間が設定されている場合はそれを使う、それもない場合はデフォルトの12:00になる
      const targetValue = this.displayValue || this.scrollTargetValue;
      const index = this.selectableItem.findIndex((item) => item.value === targetValue);

      if (index === -1) return;

      // 指定のindexのボタンにフォーカスを当てる
      const button = this.$refs[`mousePickerButton_${index}`] as any;

      if (!(button && button[0])) return;

      const domElement = button[0].$el || button[0];

      if (!(domElement instanceof HTMLElement)) return;
      domElement.focus();
      domElement.scrollIntoView({ block: 'center' });
    },
  },
});
