import { GraphQLScalarType, Kind, print } from 'graphql';
import { JsonObject, Maybe } from '~/framework/typeAliases';
import { RawInfeasibilityJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { convertNullToUndefined } from '~/framework/property';

export interface IRawOrderValidationJsonObject {
  infeasibilities: Maybe<RawInfeasibilityJsonObject[]>;
}

export class OrderValidationJsonObject implements IRawOrderValidationJsonObject {
  // NOTE フィールドとして定義されていないと Apollo から書き出されない
  // getter, setter では動かないので注意
  // serialize の方に押し込むのも手かもしれない
  infeasibilities: Maybe<RawInfeasibilityJsonObject[]>;

  constructor(data: JsonObject) {
    // NOTE 型としては Maybe としているものの、実際の JSON では null が入ってくる事があるため、
    // 実際に使う場合には convertNullToUndefined などを行って null を undefined に変換しておく
    // 必要がある事に注意する必要がある。
    const converted = convertNullToUndefined(data) as IRawOrderValidationJsonObject;
    this.infeasibilities = converted.infeasibilities;
  }
}

/**
 * 受注情報検証結果のデータフォーマット
 */
export const OrderValidationJsonObjectScalarType = new GraphQLScalarType({
  name: 'OrderValidationJsonObject',
  /**
   * 内部値（フロントエンド側）を出力用（サーバー側）の値に変換する
   * @param value
   */
  serialize: (value: IRawOrderValidationJsonObject): any => {
    return value;
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param value
   */
  parseValue: (value: JsonObject): IRawOrderValidationJsonObject => {
    return new OrderValidationJsonObject(value);
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param valueAST
   */
  parseLiteral: (valueAST): OrderValidationJsonObject => {
    // NOTE いつどう呼ばれるのかいまいち分からずテストができていない
    if (valueAST.kind === Kind.OBJECT) {
      const str = print(valueAST);
      const obj = JSON.parse(str);
      return new OrderValidationJsonObject(obj);
    }
    throw new Error(`OrderValidationJsonObject should be Object type!`);
  },
});
