import { Maybe } from '~/framework/typeAliases';
import { Attendance, DisposalSiteAttendanceStatus } from '~/graphql/graphQLServerApi';
import { AttendanceEntity } from '../attendance/attendanceEntity';
import { IDisposalSiteData } from '~/framework/server-api/masters/disposalSite';
import { AggregatedDisposalSiteEntity } from '../disposal-site/aggregatedDisposalSiteEntity';

export interface IDisposalSiteAttendanceData {
  id: string;
  disposalSite: IDisposalSiteData;
  attendance: Attendance;
  periodStart: Maybe<number>;
  periodEnd: Maybe<number>;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  status: DisposalSiteAttendanceStatus;
  acceptanceLimit: number;
  note: Maybe<string>;
}

export class DisposalSiteAttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  disposalSite: AggregatedDisposalSiteEntity;
  attendance: AttendanceEntity;
  periodStart: Maybe<number>;
  periodEnd: Maybe<number>;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  status: DisposalSiteAttendanceStatus;
  acceptanceLimit: number;
  note: Maybe<string>;

  constructor(
    data: IDisposalSiteAttendanceData,
    attendance: AttendanceEntity,
    disposalSite: AggregatedDisposalSiteEntity
  ) {
    this.id = data.id;
    this.persistentId = data.id;
    this.disposalSite = disposalSite;
    this.attendance = attendance;
    this.periodStart = data.periodStart;
    this.periodEnd = data.periodEnd;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.status = data.status;
    this.acceptanceLimit = data.acceptanceLimit;
    this.note = data.note;
  }

  update(data: IDisposalSiteAttendanceData, attendance: AttendanceEntity, disposalSite: AggregatedDisposalSiteEntity) {
    if (this.id !== data.id) {
      throw new Error('invalid update of DisposalSiteAttendanceEntity');
    }
    this.disposalSite = disposalSite;
    this.attendance = attendance;
    this.periodStart = data.periodStart;
    this.periodEnd = data.periodEnd;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.status = data.status;
    this.acceptanceLimit = data.acceptanceLimit;
    this.note = data.note;
  }
}
