import {
  PackingPlacementsByKeywordsQuery,
  PackingPlacementsByKeywordsQueryVariables,
  UpdatePackingPlacementMutation,
  UpdatePackingPlacementMutationVariables,
} from '@/graphql/graphQLServerApi';
import { PackingPlacementsByKeywords } from '@/../graphql/server-api/queries/packing-placement.gql';
import { UpdatePackingPlacement } from '@/../graphql/server-api/mutations/packing-placement.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  ICondition,
  IGetByKeywordsApi,
  packingPlacement$getByKeywordsSymbol,
  PackingPlacementAtSite,
} from '~/framework/server-api/packing-placement/getByKeywords';
import { Maybe } from '~/framework/typeAliases';
import { IConnection } from '~/framework/server-api/connection';
import {
  IUpdateApi,
  IUpdateData,
  packingPlacement$updateSymbol,
} from '~/framework/server-api/packing-placement/update';
import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';

export class GetByKeywordsApi extends GraphqlApiBase implements IGetByKeywordsApi {
  [packingPlacement$getByKeywordsSymbol] = undefined;

  async getByKeywords(
    first: number,
    after: Maybe<string>,
    condition: ICondition
  ): Promise<IConnection<PackingPlacementAtSite>> {
    const result = await this.query<PackingPlacementsByKeywordsQuery, PackingPlacementsByKeywordsQueryVariables>({
      query: PackingPlacementsByKeywords,
      variables: {
        first,
        after,
        condition,
      },
    });
    return result.packingPlacementsByKeywords;
  }
}

export class UpdateApi extends GraphqlApiBase implements IUpdateApi {
  [packingPlacement$updateSymbol] = undefined;

  async update(generationSiteId: string, packingPlacements: IUpdateData[]): Promise<IPackingPlacementData[]> {
    const result = await this.mutate<UpdatePackingPlacementMutation, UpdatePackingPlacementMutationVariables>({
      mutation: UpdatePackingPlacement,
      variables: {
        generationSiteId,
        packingPlacements,
      },
    });
    return result.updatePackingPlacements;
  }
}
