import ja from 'date-fns/locale/ja';
import { add, format, startOfWeek, sub } from 'date-fns';
import first from 'lodash/first';
import last from 'lodash/last';
import { DayOfWeek } from '~/framework/typeAliases';
import { DaysAWeek } from '~/framework/constants';
import { HeaderDateColumn, IHeaderDateColumn } from '~/components/common/r-week-calendar/headerDateColumn';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { ensure } from '~/framework/core/value';

/**
 * NOTE day = 曜日, date = 日付
 */
export class WeekDatesCalendar {
  /**
   * その週の日付（7この配列）
   * startDayOfWeek をいじる事で自動的に調整される
   * startDayOfWeek は previousWeek, nextWeek で調整される
   */
  weekDates!: Date[];
  /**
   * 2020年8月〜9月みたいな文字列
   * startDayOfWeek をいじる事で自動的に調整される
   * startDayOfWeek は previousWeek, nextWeek で調整される
   */
  monthLabel!: string;
  /**
   *  表のカラムのヘッダーの表示に必要なデータ
   * startDayOfWeek をいじる事で自動的に調整される
   * startDayOfWeek は previousWeek, nextWeek で調整される
   */
  columnHeaders!: IHeaderDateColumn[];
  private readonly startOfWeek: DayOfWeek;
  private readonly holidayRule?: HolidayRuleEntity;
  private nationalHolidayService = new NationalHolidayService();

  constructor(baseDate: Date, startOfWeek: DayOfWeek, holidayRule?: HolidayRuleEntity) {
    this.startOfWeek = startOfWeek;
    this.holidayRule = holidayRule;
    this.startDateOfWeek = this.getStartOfWeekDateFrom(baseDate);
  }

  /**
   週の最初の日
   */
  get firstDate(): Date {
    return first(this.weekDates)!;
  }

  /**
   * 週の終わりの日
   */
  get lastDate(): Date {
    return last(this.weekDates)!;
  }

  /**
   * カレンダーを前の週に移動する
   */
  shiftToPreviousWeek() {
    this.startDateOfWeek = sub(this.startDateOfWeek, { weeks: 1 });
  }

  /**
   * カレンダーを次の週に移動する
   */
  shiftToNextWeek() {
    this.startDateOfWeek = add(this.startDateOfWeek, { weeks: 1 });
  }

  /**
   * 特定の日を指定し、その日を表示できるカレンダーにする
   */
  setArbitraryDate(baseDate: Date) {
    this.startDateOfWeek = this.getStartOfWeekDateFrom(baseDate);
  }

  /**
   * 特定の日がカレンダーのその週に含まれるかどうかを取得する
   * @param date
   */
  isDateOnCalendar(date: Date): boolean {
    return this.firstDate.getTime() <= date.getTime() && date.getTime() <= this.lastDate.getTime();
  }

  private _startDateOfWeek!: Date;

  private get startDateOfWeek(): Date {
    return this._startDateOfWeek;
  }

  private set startDateOfWeek(value: Date) {
    this._startDateOfWeek = value;
    this.updateWeekDates();
    this.updateMonthLabel();
    if (this.holidayRule) {
      this.updateColumnHeaders();
    }
  }

  private get dateFnsOptions() {
    return { weekStartsOn: this.startOfWeek, locale: ja };
  }

  private updateWeekDates(): void {
    this.weekDates = [];
    for (let index = 0; index < DaysAWeek; index++) {
      this.weekDates.push(add(this.startDateOfWeek, { days: index }));
    }
  }

  private updateMonthLabel(): void {
    const firstDate = this.firstDate;
    const lastDate = this.lastDate;
    const startMonth = format(firstDate, `yyyy年M月`, this.dateFnsOptions);
    if (firstDate.getMonth() === lastDate.getMonth()) {
      this.monthLabel = startMonth;
    } else if (firstDate.getFullYear() === lastDate.getFullYear()) {
      this.monthLabel = startMonth + `〜` + format(lastDate, `M月`, this.dateFnsOptions);
    } else {
      this.monthLabel = startMonth + `〜` + format(lastDate, `yyyy年M月`, this.dateFnsOptions);
    }
  }

  private updateColumnHeaders(): void {
    this.columnHeaders = [];
    ensure(this.holidayRule);
    for (const date of this.weekDates) {
      this.columnHeaders.push(
        new HeaderDateColumn(date, this.nationalHolidayService.isHoliday(date, this.holidayRule))
      );
    }
  }

  /**
   * 何かしらの適当な日付を与えて週の頭を考慮した上で週のはじめの日を取得する
   *
   * @param date
   * @private
   */
  private getStartOfWeekDateFrom(date: Date): Date {
    return startOfWeek(date, this.dateFnsOptions);
  }
}
