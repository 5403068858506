import { TaskType, ContainerTypeTaskTypeStatus } from '~/framework/domain/typeAliases';
export const packingStyleTaskTypeDefault$getAllSymbol = Symbol('getAll');
export const packingStyleTaskTypeDefault$updateSymbol = Symbol('update');

export type PackingStyleTaskTypeDefault = {
  [packingStyleTaskTypeDefault$getAllSymbol]: IGetAllApi;
  [packingStyleTaskTypeDefault$updateSymbol]: IUpdateApi;
};

export interface IGetAllApi {
  getAll(): Promise<IPackingStyleTaskTypeDefaultData[]>;
}

export interface IPackingStyleTaskTypeDefaultData {
  id: string;
  packingStyleId: string;
  taskType: TaskType;
  duration: number;
  status: ContainerTypeTaskTypeStatus;
  isProportionalToCount: boolean;
}

export interface IUpdateData {
  id: string;
  duration: number;
  status: ContainerTypeTaskTypeStatus;
  isProportionalToCount: boolean;
}

export interface IUpdateApi {
  update(data: IUpdateData[]): Promise<IPackingStyleTaskTypeDefaultData[]>;
}
