import { SystemContext } from '~/framework/systemContext';
import { Maybe } from '~/framework/typeAliases';
import { createLogger } from '~/framework/logger';

export type Notification = {
  message: string;
};

export class NotificationManager {
  private readonly systemContext: SystemContext;

  constructor(systemContext: SystemContext) {
    this.systemContext = systemContext;
  }

  hasNotificationService(): boolean {
    const hasNotification = 'Notification' in window;
    const hasRequestPermission = hasNotification && 'requestPermission' in Notification;
    return hasNotification && hasRequestPermission;
  }

  hasInitializedPermission(): Maybe<boolean> {
    if (this.hasNotificationService() === false) return undefined;
    return Notification.permission !== 'default';
  }

  isAllowedToNotify(): Maybe<boolean> {
    if (this.hasNotificationService() === false) return undefined;
    return Notification.permission === 'granted';
  }

  async requestPermission(): Promise<void> {
    await Notification.requestPermission();
  }

  notify(notification: Notification) {
    if (this.isAllowedToNotify() === true) {
      // デスクトップの場合、Notification を new する事で通知をする事ができる。一方で Android の場合は
      // そのまま Notification を new しても通知ができず Service Worker 経由でしか通知ができない。
      // しかし Android でも Notification クラス自体は存在するため、'Notification' in window で通知が
      // できるかどうかは正確に判断できない。そこで google は granted になる前に new Notification を試み
      // （granted になってから通知を試みると空の通知がそのまま表示されてしまう）、TypeError だった場合には
      // 通知ができない状態であると判断するのがよいとサンプルで示しているが、rin の場合は既に Android の場合に
      // granted になってしまっている端末がある可能性があるのでAndroid ではそもそも granted にしないという事が
      // できない状態になってしまっている。そこでここではそういった端末で起きるエラーのみ無視する形にしている。
      //
      // あまりいい処理ではないので本来的にはすべての通知を Service Worker 経由にすべきかもしれないが
      // そこまで大幅に書き換える事が現状だとできないのでこの対応にしている。
      //
      // ref:
      //   - https://developers.google.com/web/updates/2015/05/notifying-you-of-changes-to-notifications
      //   - https://notifications.spec.whatwg.org/#dom-notification-notification
      try {
        const issuedNotification = new Notification('配車頭', {
          body: notification.message,
          icon: '/symbol_512x512.png',
        });
        logger.info(`notification issued: `, issuedNotification);
      } catch (e: any) {
        if (e.name !== 'TypeError') {
          throw e;
        }
      }
    }
  }
}

const logger = createLogger('NotificationManager');
