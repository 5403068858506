
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RFieldHeader',
  props: {
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    labelClass() {
      return {
        'r-field-header__label': true,
        'r-field-header__label--disabled': this.disabled,
      };
    },
  },
});
