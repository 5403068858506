import { IDisposable } from '~/framework/core/disposable';
import { Maybe, MaybeWeakRef } from '~/framework/typeAliases';
import { isWeakRef } from '~/framework/core/weakRef';

/**
 * あるタイプの中に定義されているメソッドの引数タイプ
 */
type ArgsType<Type, Key extends keyof Type> = Type[Key] extends (...args: infer ArgsType) => any ? ArgsType : never;

export interface IPorts<Port extends object> {
  /**
   * 出力ポートを追加する
   * WeakRef のポートを追加する事もできるし、強参照のポートを追加する事もできる
   * WeakRef のポートを追加する場合は追加元でそのライフサイクルをコントロールする必要がある
   * 強参照のポートを追加するのであれば必要なくなった段階で dispose する必要がある
   * @param port
   */
  add(port: MaybeWeakRef<Port>): IDisposable;

  /**
   * 各出力ポートへ出力する
   * @param key
   * @param value
   */
  output<Key extends keyof Port>(key: Key, ...value: ArgsType<Port, Key>): void;
}

export class Ports<Port extends object> implements IPorts<Port> {
  private readonly ports: MaybeWeakRef<Port>[];

  constructor() {
    this.ports = [];
  }

  add(port: MaybeWeakRef<Port>): IDisposable {
    this.ports.push(port);
    return {
      dispose: () => {
        this.removePort(port);
      },
    };
  }

  output<Key extends keyof Port>(key: Key, ...value: ArgsType<Port, Key>): void {
    for (const port of this.ports) {
      let actualPort: Maybe<Port>;
      if (isWeakRef(port)) actualPort = port.deref();
      else actualPort = port;
      if (actualPort) (actualPort[key] as unknown as (...args: ArgsType<Port, Key>) => any)(...value);
    }
    this.removeInvalidPorts();
  }

  private removePort(port: MaybeWeakRef<Port>): void {
    const portIndex = this.ports.indexOf(port);
    if (-1 < portIndex) this.ports.splice(portIndex, 1);
  }

  private removeInvalidPorts(): void {
    const invalidPorts = this.ports
      .map((port, index) => {
        return {
          port,
          index,
        };
      })
      .reverse()
      .filter(({ port }) => isWeakRef(port) && port.deref() === undefined);
    for (const { index } of invalidPorts) {
      this.ports.splice(index, 1);
    }
  }
}
