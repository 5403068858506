import { Maybe } from '~/framework/typeAliases';
import { SystemContext } from '~/framework/systemContext';

/**
 * 一度に読み込むアイテムの数
 * とりあえず50程度ならそこまで遅くなさそうなので50としておく
 */
export const defaultItemsPerLoad = 50;

export interface ILazySearchResult<Item> {
  items: Item[];
  hasNextPage: boolean;
}

/**
 * Lazy な検索を行うためのデータのローダー
 */
export interface ILazySearchLoader<Condition extends ILazySearchCondition, Item> {
  condition: Condition;

  /**
   * 現状の検索条件（condition）に従って次のデータを読み込む
   */
  loadItems(): Promise<ILazySearchResult<Item>>;
}

export interface ILazySearchCondition {
  keywords: Maybe<string[]>;
}

export interface IConnection<Item> {
  items: Item[];
  hasNextPage: boolean;
  endCursor: Maybe<string>;
}

export abstract class LazySearchBase<Condition extends ILazySearchCondition, Item>
  implements ILazySearchLoader<Condition, Item>
{
  protected readonly systemContext: SystemContext;
  private readonly first: number;
  private _condition!: Condition;
  private endCursor: Maybe<string>;

  get condition() {
    return this._condition;
  }

  set condition(value: Condition) {
    this._condition = value;
    this.endCursor = undefined;
  }

  constructor(systemContext: SystemContext, condition: Condition) {
    this.systemContext = systemContext;
    this.first = defaultItemsPerLoad;
    this.condition = condition;
  }

  async loadItems(): Promise<ILazySearchResult<Item>> {
    const result = await this.loadConnection(this.first, this.endCursor, this.condition);
    this.endCursor = result.endCursor;
    return { items: result.items, hasNextPage: result.hasNextPage };
  }

  protected abstract loadConnection(
    first: number,
    after: Maybe<string>,
    condition: Condition
  ): Promise<IConnection<Item>>;
}
