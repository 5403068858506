import { v4 as uuidv4 } from 'uuid';
import { getDay } from 'date-fns';
import { secsToHhMm } from '~/framework/services/date-time/date-time';
import { Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import { DistinctTimeOptionId } from '~/framework/view-models/collectablePeriodTemplateOption';
import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';
import { dateToMd, dateToYyMdDaysOfWeek } from '~/framework/services/date/date';

// 受注の到着日時を表すクラス
// 受注の到着日時は基本的に1つだが、受注の一括登録や、複数候補日の設定により一度に複数の到着日時を設定・登録することがあるため、 item と定義されている
export class DateCollectablePeriodItem {
  /**
   * 複数日の一括登録で、このクラスの配列をv-forで表示するとき、keyにidを使う
   * keyに配列のindexを使いItemを削除すると、削除したItem以降のindexが繰り上がってしまい
   * 最初のkeyと異なってしまう。
   * これにより、keyのキャッシュで想定していない動きをしてしまうため、idを定義している。
   */
  id: string;
  date: Maybe<Date>;
  collectablePeriodTemplateName: Maybe<string>;
  collectableDistinctTime: Maybe<Seconds>;
  collectablePeriodStart: Maybe<Seconds>;
  collectablePeriodEnd: Maybe<Seconds>;

  // NOTE: 日毎に車台数を調整したい場合に設定する。受注の一括登録時にこちらが設定されていれば、こちらを優先する
  carNum: Maybe<number>;

  unloadDate: Maybe<Date>;
  collectablePeriodTemplateId: Maybe<PersistentId>;

  constructor(
    date: Maybe<Date>,
    collectablePeriodTemplateName: Maybe<string> = undefined,
    collectablePeriodStart: Maybe<Seconds> = undefined,
    collectablePeriodEnd: Maybe<Seconds> = undefined,
    unloadDate: Maybe<Date> = undefined,
    carNum: Maybe<number> = undefined,
    collectablePeriodTemplateId: Maybe<PersistentId> = undefined,
    collectableDistinctTime: Maybe<Seconds> = undefined
  ) {
    this.date = date;
    this.collectablePeriodTemplateName = collectablePeriodTemplateName;
    this.collectablePeriodStart = collectablePeriodStart;
    this.collectablePeriodEnd = collectablePeriodEnd;
    this.unloadDate = unloadDate;
    this.carNum = carNum;
    this.collectablePeriodTemplateId = collectablePeriodTemplateId;
    this.collectableDistinctTime = collectableDistinctTime;
    this.id = uuidv4();
  }

  get isCollectableTimeDistinct(): boolean {
    return this.collectablePeriodTemplateId === DistinctTimeOptionId;
  }

  get isCollectableDateEmpty(): boolean {
    return !this.date;
  }

  get isCollectableTimeEmpty(): boolean {
    if (this.isCollectableTimeDistinct) return !this.collectableDistinctTime;
    return this.collectablePeriodStart === undefined || this.collectablePeriodEnd === undefined;
  }

  getCollectablePeriodStartHhMm(): string {
    if (this.collectablePeriodStart !== undefined) return secsToHhMm(this.collectablePeriodStart);
    return '';
  }

  getCollectablePeriodEndHhMm(): string {
    if (this.collectablePeriodEnd !== undefined) return secsToHhMm(this.collectablePeriodEnd);
    return '';
  }

  getCollectableDistinctTimeHhMm(): string {
    if (this.collectableDistinctTime !== undefined) return secsToHhMm(this.collectableDistinctTime);
    return '';
  }

  getDate(): Date {
    if (this.date === undefined) throw new Error('date is undefined');
    return this.date;
  }

  getTimeForDate(): Maybe<number> {
    return this.date === undefined ? undefined : this.date.getTime();
  }

  getTimeForUnloadDate(): Maybe<number> {
    return this.unloadDate === undefined ? undefined : this.unloadDate.getTime();
  }

  /**
   * 受注登録時に、時間厳守を判別して開始時間を返すメソッド
   * 時間厳守の場合、collectableDistinctTime
   * 時間厳守ではない場合、collectablePeriodStart
   * @returns Seconds
   */
  getCollectablePeriodStartOrDistinctTime(): Seconds {
    if (this.isCollectableTimeDistinct) {
      if (this.collectableDistinctTime === undefined) throw new Error('collectableDistinctTime is undefined');
      return this.collectableDistinctTime;
    } else {
      if (this.collectablePeriodStart === undefined) throw new Error('collectablePeriodStart is undefined');
      return this.collectablePeriodStart;
    }
  }

  /**
   * 受注登録時に、時間厳守を判別して終了時間を返すメソッド
   * 時間厳守の場合、collectableDistinctTime
   * 時間厳守ではない場合、collectablePeriodEnd
   * @returns Seconds
   */
  getCollectablePeriodEndOrDistinctTime(): Seconds {
    if (this.isCollectableTimeDistinct) {
      if (this.collectableDistinctTime === undefined) throw new Error('collectableDistinctTime is undefined');
      return this.collectableDistinctTime;
    } else {
      if (this.collectablePeriodEnd === undefined) throw new Error('collectablePeriodEnd is undefined');
      return this.collectablePeriodEnd;
    }
  }

  /**
   * DateCollectablePeriodItemから1行のテキストを表示するメソッド
   * @return 例) 2021/06/12 (土) 午前中 08:00~12:00 (車1台) 荷下ろし日: 06/14 (月)
   */
  getText(withDate: boolean = true, withCarNum: boolean = false, withUnloadDate: boolean = true): string {
    let dateItemText = '';
    dateItemText += withDate ? dateToYyMdDaysOfWeek(this.getDate()) : '';
    dateItemText += ` ${this.collectablePeriodTemplateName ?? ''}`;
    if (this.isCollectableTimeDistinct) {
      dateItemText += ` ${this.getCollectableDistinctTimeHhMm()}`;
    } else if (this.getCollectablePeriodStartHhMm() === this.getCollectablePeriodEndHhMm()) {
      dateItemText += ` ${this.getCollectablePeriodStartHhMm()}`;
    } else {
      dateItemText += ` ${this.getCollectablePeriodStartHhMm()}~${this.getCollectablePeriodEndHhMm()}`;
    }
    if (withCarNum && this.carNum !== undefined) dateItemText += ` (車${this.carNum}台)`;
    if (this.unloadDate !== undefined && withUnloadDate)
      dateItemText += ` 荷下ろし日: ${dateToMd(this.unloadDate)} (${daysOfWeekMap[getDay(this.unloadDate)]})`;
    return dateItemText;
  }

  clone(exceptDate: boolean): DateCollectablePeriodItem {
    return new DateCollectablePeriodItem(
      exceptDate ? undefined : this.date,
      this.collectablePeriodTemplateName,
      this.collectablePeriodStart,
      this.collectablePeriodEnd,
      exceptDate ? undefined : this.unloadDate,
      this.carNum,
      this.collectablePeriodTemplateId,
      this.collectableDistinctTime
    );
  }

  setCollectableDistinctTime(forceSetDate?: Maybe<Seconds>) {
    if (forceSetDate) {
      this.collectableDistinctTime = forceSetDate;
      return;
    }
    if (this.isCollectableTimeDistinct) this.collectableDistinctTime = this.collectablePeriodStart;
  }

  isDirty(initialDateCollectablePeriodItem: DateCollectablePeriodItem): boolean {
    const isDirty =
      this.getTimeForDate() !== initialDateCollectablePeriodItem.getTimeForDate() ||
      this.getTimeForUnloadDate() !== initialDateCollectablePeriodItem.getTimeForUnloadDate() ||
      this.collectablePeriodTemplateName !== initialDateCollectablePeriodItem.collectablePeriodTemplateName ||
      (this.isCollectableTimeDistinct
        ? // 時間厳守の時間は、collectablePeriodStartとして登録されるため、collectableDistinctTimeと比較する
          this.collectableDistinctTime !== initialDateCollectablePeriodItem.collectablePeriodStart
        : this.collectablePeriodStart !== initialDateCollectablePeriodItem.collectablePeriodStart ||
          this.collectablePeriodEnd !== initialDateCollectablePeriodItem.collectablePeriodEnd);
    return isDirty;
  }

  isSameDay(dateCollectablePeriodItem: DateCollectablePeriodItem): boolean {
    return this.getTimeForDate() === dateCollectablePeriodItem.getTimeForDate();
  }

  isTimeIncluded(collectableTime: Seconds): boolean {
    return this.isCollectableTimeDistinct
      ? // 時間厳守の時間は、collectablePeriodStartとして登録されるため、collectableDistinctTimeと比較する
        this.collectableDistinctTime === collectableTime
      : this.collectablePeriodStart !== undefined &&
          this.collectablePeriodEnd !== undefined &&
          this.collectablePeriodStart <= collectableTime &&
          collectableTime <= this.collectablePeriodEnd;
  }
}
