// ここの JSON は Python のデータが元となっており、snake_case でもよい事とする
// ただし、それをそのまま使っていますよという意味で Raw を付けている
/* eslint camelcase: 0 */

/**
 * 固定のエラーが出ている理由
 */
import { Maybe } from '~/framework/typeAliases';

/**
 * Route や Collection には元々 id はない。だがこれだと配車表を手動で組み替えた時に元がどのルートだったのかなどが
 * 不明になってしまうため、後から id を振っている。id だと元々あったプロパティだと勘違いしそうなのであえて
 * アンダースコアを付けているが、詳しくは ScheduleJsonObject#complementId あたりを参照の事。
 */
export interface IIdBased {
  _id: string;
}

export type WithId<Type> = Type & IIdBased;

export type MaybeWithId<Type> = Type & Partial<IIdBased>;

export type RawRouteJsonObjectWithId = WithId<
  RawRouteJsonObject<RawCollectionJsonObjectWithId, RawDisposalJsonObjectWithId>
>;
export type RawCollectionJsonObjectWithId = WithId<RawCollectionJsonObject>;
export type RawDisposalJsonObjectWithId = WithId<RawDisposalJsonObject>;

export type RawRouteJsonObjectMaybeWithId = MaybeWithId<
  RawRouteJsonObject<RawCollectionJsonObjectMaybeWithId, RawDisposalJsonObjectMaybeWithId>
>;
export type RawCollectionJsonObjectMaybeWithId = MaybeWithId<RawCollectionJsonObject>;
export type RawDisposalJsonObjectMaybeWithId = MaybeWithId<RawDisposalJsonObject>;

export enum InconsistencyReasons {
  // 1st phase
  IndividualOrder = 'individual_order',
  CollectionSequence = 'collection_sequence',
  RouteTime = 'route_time',
}

/**
 * 計算不可になった原因のサマリー
 */
export enum InfeasibilityCauses {
  // 2nd phase
  // 受注情報、マスター、勤怠まわりに問題がある場合に出る
  Order = 'order',
  OrderOrMaster = 'order_or_master',
  AssignedDriverAttendance = 'assigned_driver_attendance',
  AssignedDriverCondition = 'assigned_driver_condition',
  PrimaryCarAttendance = 'primary_car_attendance',
  PrimaryCarCondition = 'primary_car_condition',
  DisposalSiteRestPeriod = 'disposal_site_rest_period',

  // 3rd phase
  // 実際に最適化を走らせてはじめてでてくるエラー
  Optimization = 'optimization',
}

/**
 * 何故計算不可だったのかの理由（受注情報もしくはマスター）
 */
export enum InfeasibilityReasons {
  // Order
  InconsistentAssignmentsOfCarAndCarType = 'inconsistent_assignments_of_car_and_car_type',
  AssignedCarDoesNotExist = 'assigned_car_does_not_exist',
  CarWithAssignedCarTypeDoesNotExist = 'car_with_assigned_car_type_does_not_exist',
  AssignedGenerationSiteDoesNotExist = 'assigned_generation_site_does_not_exist',
  AssignedDisposalSiteDoesNotExist = 'assigned_disposal_site_does_not_exist',
  PreloadOrderMustHaveAssignedDisposalSite = 'preload_order_must_have_assigned_disposal_site',
  PreloadTaskMustBeFetch = 'preload_task_must_be_fetch',
  PreloadTaskMustNotBeAllocate = 'preload_task_must_not_be_allocate',
  // OrderOrMaster
  AssignedCarCannotAcceptTasks = 'assigned_car_cannot_accept_tasks',
  CarWithAssignedCarTypeCannotAcceptTasks = 'car_with_assigned_car_type_cannot_accept_tasks',
  NoCarCanAcceptTasks = 'no_car_can_accept_tasks',
  NoBaseCanSupplyRequiredContainers = 'no_base_can_supply_required_containers',
  NoBaseCanSupplyNecessaryTransformingContainer = 'no_base_can_supply_necessary_transforming_container',
  AssignedDisposalSiteCannotAcceptWaste = 'assigned_disposal_site_cannot_accept_waste',
  NoDisposalSiteCanAcceptWaste = 'no_disposal_site_can_accept_waste',
  IncompatibleSitePeriods = 'incompatible_site_periods',
  GenerationSiteRestPeriod = 'generation_site_rest_period',
  UnavailableDisposalSite = 'unavailable_disposal_site',
  disposalSitePeriod = 'disposal_site_period',
  DisposalSiteRestPeriod = 'disposal_site_rest_period',
}

/**
 * ドライバーが問題だった場合の理由（車の割り当てや担当不可設定、勤怠など）
 */
export enum InfeasibilityDriverReasons {
  InvalidPrimaryCar = 'invalid_primary_car',
  BannedByGenerationSite = 'banned_by_generation_site',
  BannedByAssignedDisposalSite = 'banned_by_assigned_disposal_site',
  BannedByAllDisposableSites = 'banned_by_all_disposable_sites',
  CollectablePeriodAndRestPeriodOverlap = 'collectable_period_and_rest_period_overlap',
  CannotKeepCollectablePeriod = 'cannot_keep_collectable_period',
  CannotKeepDisposablePeriod = 'cannot_keep_disposable_period',
  CannotKeepEndOfWorkablePeriod = 'cannot_keep_end_of_workable_period',
}
export interface RawScheduleJsonObject<Route extends RawRouteJsonObject<any>> {
  routes: Route[];
  infeasibilities?: Maybe<RawScheduleInfeasibilityJsonObject[]>;
  inconsistencies?: Maybe<RawInconsistencyJsonObject[]>;
  /**
   * misc のフィールドは本来 scheduleData には含まれておらず、再最適化した際に元となった配車表データが
   * 分からなくなるとどの様に元に戻せばいいのかが分からなくなるのでここに元となった配車表データを
   * フロントエンド側から格納する事にしている。サーバー側に色々変更を加えればもっとましな構造にできた
   * 可能性があるが、ひとまず最低限の変更で実現するためにこうしている。
   * 古い配車表データではこれが存在しない可能性がある。
   */
  misc?: Maybe<RawMiscJsonObject>;
}

export interface RawInfeasibilityJsonObject {
  order_id: string;
  cause: InfeasibilityCauses;
  reasons: Maybe<InfeasibilityReasons[]>;
  driver_reasons: Maybe<RawDriverReasonJsonObject[]>;
  assigned_driver_id: Maybe<string>;
  assigned_car_id: Maybe<string>;
  acceptable_car_type_ids: Maybe<string[]>;
}

export interface RawDriverReasonJsonObject {
  driver_id: string;
  reasons: InfeasibilityDriverReasons[];
}

export interface RawInconsistencyJsonObject {
  driver_id: string;
  indexes: number[];
  reason: InconsistencyReasons;
  order_ids: Maybe<string[]>;
}

export interface RawRouteJsonObject<
  Collection extends RawCollectionJsonObject = RawCollectionJsonObject,
  Disposal extends RawDisposalJsonObject = RawDisposalJsonObject
> {
  index: number;
  collections: Collection[];
  disposals: Disposal[];
  car_id: Maybe<string>;
  car_index: Maybe<number>;
  driver_id: string;
  is_driver: Maybe<boolean>;
  is_helper: Maybe<boolean>;
  start_time: number;
  end_time: number;
  is_fixed_assignment?: boolean;
  is_fixed_schedule: boolean;
  inconsistent_loading_route_infos: Maybe<RawInconsistentRouteInfoJsonObject[]>;
  inconsistent_time_route_infos: Maybe<RawInconsistentRouteInfoJsonObject[]>;
  base_site_id: Maybe<string>;
  base_site_arrival_time: Maybe<number>;
  base_site_departure_time: Maybe<number>;
  garage_site_departure_id: Maybe<string>;
  garage_site_departure_time: Maybe<number>;
  garage_site_arrival_id: Maybe<string>;
  garage_site_arrival_time: Maybe<number>;
  has_rest_before_base_site_arrival: Maybe<boolean>;
  has_rest_before_garage_site_arrival: Maybe<boolean>;
  has_rest_after_generation_site_departure: Maybe<boolean>;
  has_rest_after_disposal_site_departure: Maybe<boolean>;
}

export interface RawCollectionJsonObject {
  index: number;
  order_id: string;
  generation_site_arrival_time: number;
  generation_site_departure_time: number;
  has_rest_before_generation_site_arrival: boolean;
  is_fixed_assignment?: boolean;
}

export interface RawDisposalJsonObject {
  index: number;
  disposal_site_arrival_time: number;
  disposal_site_departure_time: number;
  disposal_site_id: string;
  has_rest_before_disposal_site_arrival: boolean;
  /**
   * これは処分場の候補・優先順位が導入された後に追加されたフィールドなので古い配車表データでは
   * まだデータがなく厳密には undefined になる可能性がある事に注意
   */
  prioritize_assigned_disposal_site: boolean;
  /**
   * これは処分場の候補・優先順位が導入された後に追加されたフィールドなので古い配車表データでは
   * まだデータがなく厳密には undefined になる可能性がある事に注意
   */
  sequential_assigned_disposal_site: boolean;
}

/**
 * 最適化エンジンに指定された、固定が原因で配車不可能になりうるドライバーとルートのindexの情報
 */
export interface RawInconsistentRouteInfoJsonObject {
  index: number;
  driver_id: string;
  type: Maybe<string>;
}

export interface RawMiscJsonObject {
  /**
   * 編集の原点となった配車表データ
   */
  original: RawOriginalScheduleDataJsonObject;
}

export interface RawOriginalScheduleDataJsonObject {
  /**
   * 編集の原点となった配車表データは必ず _id が存在する
   * original に格納されているのはいったんフロントエンドを通したデータであり、
   * フロントエンドを通したものは必ず complementId で _id が付与されているため。
   */
  routes: RawRouteJsonObjectWithId[];
}

/**
 * 配車表が作成不可だった理由のタイプ
 */
export enum ScheduleInfeasibilityTypes {
  Master = 'master',
  NoAssignedDriver = 'no_assigned_driver',
  NoAssignableDriver = 'no_assignable_driver',
  NoAssignableHelper = 'no_assignable_helper',
  TightAcceptablePeriods = 'tight_acceptable_periods',
  ShortOvertimeWorkableDuration = 'short_overtime_workable_duration',
  ModifiableConflict = 'modifiable_conflict',
  CriticalConflict = 'critical_conflict',
  RoutingGroup = 'routing_group',
}

/**
 *  残業のタイプ
 */
export enum ScheduleInfeasibilityOvertimeWorkType {
  None = 'none',
  Both = 'both',
  AvailableInEarlyTime = 'available_in_early_time',
  AvailableInLateTime = 'available_in_late_time',
}

/**
/**
 * IRawInfeasibilityJsonObjectに定義されている
 * assigned_car_id, acceptable_car_type_idsはIRawScheduleInfeasibilityJsonObjectを使う時に今後利用しない。
 * 代わりに、assignable_car_ids, assignable_car_type_idsを利用する。
 * (旧フォーマットの表示で利用する可能性があり、削除できないので、残っている)
 */
export interface RawScheduleInfeasibilityJsonObject extends RawInfeasibilityJsonObject {
  type: ScheduleInfeasibilityTypes;
  assignable_car_type_ids: Maybe<string[]>;
  release_driver_assignment: Maybe<boolean>;
  duration_at_generation_site: Maybe<number>;
  duration_at_disposal_site: Maybe<number>;
  duration_of_driving: Maybe<number>;
  reducible_duration_by_highway: Maybe<number>;
  potential_modifications: Maybe<RawPotentialModificationJsonObject[]>;
  /* 以下は最適化から送られてきてはいるが、未使用  */
  assignable_car_ids: Maybe<string[]>;
  assignable_garage_site_ids: Maybe<string[]>;
}

export interface RawPotentialModificationJsonObject {
  drivers: RawPotentialModificationDriverJsonObject[];
  orders: RawPotentialModificationOrderJsonObject[];
  disposal_sites: RawPotentialModificationDisposalSiteJsonObject[];
}

export interface RawPotentialModificationDriverJsonObject {
  driver_id: string;
  regular_work_period_start: number;
  regular_work_period_end: number;
  overtime_work_type: ScheduleInfeasibilityOvertimeWorkType;
  overtime_workable_duration: number;
  ideal_overtime_work_type: Maybe<ScheduleInfeasibilityOvertimeWorkType>;
  ideal_overtime_workable_duration: Maybe<number>;
}

export interface RawPotentialModificationOrderJsonObject {
  order_id: string;
  collectable_period_start: number;
  collectable_period_end: number;
  ideal_arrival_time: number;
}

export interface RawPotentialModificationDisposalSiteJsonObject {
  site_id: string;
  // NOTE: 修正すべき処分場が指定されている orderId
  order_id: Maybe<string>;
  disposable_period_start: number;
  disposable_period_end: number;
  ideal_arrival_time: number;
  attendance: Maybe<RawPotentialModificationDisposalSiteAttendanceJsonObject>;
}

export interface RawPotentialModificationDisposalSiteAttendanceJsonObject {
  disposable_period_start: number;
  disposable_period_end: number;
  ideal_arrival_time: number;
}
