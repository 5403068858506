import { Maybe } from '~/framework/typeAliases';
import { ScheduleRequestPeriod } from '~/graphql/custom-scalars/scheduleRequestJsonObjectTypes';
import { PeriodJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class Period {
  startTime: Maybe<number>;
  endTime: Maybe<number>;
  constructor(period: Maybe<PeriodJsonObject>) {
    this.startTime = period?.start_time;
    this.endTime = period?.end_time;
  }

  getScheduleRequestPeriod(): Maybe<ScheduleRequestPeriod> {
    // startTimeとendTimeがどちらもundefinedだった場合はundefinedを返す
    if (this.startTime === undefined && this.endTime === undefined) {
      return undefined;
    }
    return {
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  getPeriodJsonObject(): Maybe<PeriodJsonObject> {
    // startTimeとendTimeがどちらもundefinedだった場合はundefinedを返す
    if (this.startTime === undefined && this.endTime === undefined) {
      return undefined;
    }
    return {
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }
}
