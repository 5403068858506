import _ from 'lodash';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';

import { ITypedEventContext } from '~/framework/events/typedEventContext';

export enum KeyboardEventPriority {
  DefaultLayout = 0,
  Page = 10,
  Panel = 20,
  Dialog = 30,
  Root = Number.MAX_VALUE,
}

export enum KeyboardEventCode {
  Enter = 'Enter',
  Escape = 'Escape',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  KeyA = 'KeyA',
  KeyC = 'KeyC',
  KeyE = 'KeyE',
  KeyF = 'KeyF',
  KeyG = 'KeyG',
  KeyI = 'KeyI',
  KeyM = 'KeyM',
  KeyO = 'KeyO',
  KeyS = 'KeyS',
  KeyT = 'KeyT',
}

export class UIKeyboardEvent {
  /**
   * 生のイベント
   */
  readonly rawEvent: KeyboardEvent;

  constructor(event: KeyboardEvent) {
    this.rawEvent = event;
  }

  /**
   * 修飾子なしのイベントかどうかを判定する
   * @param code
   */
  isCodeWithoutModifiers(code: string): boolean {
    const hasModifiers =
      this.rawEvent.altKey || this.rawEvent.ctrlKey || this.rawEvent.metaKey || this.rawEvent.shiftKey;
    return this.rawEvent.code === code && hasModifiers === false;
  }
}

export class UIEventManager {
  keyboardEvent: ITypedEvent<UIKeyboardEvent>;

  constructor() {
    this.keyboardEvent = new TypedEvent();
    const defaultListener = this.defaultListener.bind(this);
    this.keyboardEvent.on(defaultListener, KeyboardEventPriority.Root);
    const listener = this.onKeydown.bind(this);
    document.addEventListener('keydown', listener, false);
  }

  onKeydown(e: KeyboardEvent): void {
    this.keyboardEvent.emit(new UIKeyboardEvent(e));
  }

  private defaultListener(e: UIKeyboardEvent, context: ITypedEventContext): void {
    if (e.rawEvent.target !== undefined && e.rawEvent.target instanceof HTMLElement) {
      // body, div に向けて発せられたイベント以外はどこかにフォーカスが当たっているので基本的には何もしない
      // Escape だった場合にはフォーカスを外したいので blur する
      const tagName = e.rawEvent.target.tagName.toLowerCase();
      if (tagName !== 'body' && tagName !== 'div') {
        if (e.rawEvent.code === KeyboardEventCode.Escape) {
          e.rawEvent.target.blur();
          this.blurComponent(e.rawEvent.target);
        }
        context.stop();
      }
    }
  }

  /**
   * 単純に input などに blur しても Vuetify のコンポーネントが focus 状態のままになってしまう事がある。
   * これを防ぐために、input の親を再帰的に辿って Vue のコンポーネントかつ blur が存在するオブジェクトがあれば、
   * その blur を呼ぶ。あまり正確な処理ではないのだが、どっちにしろどこにも focus は残って欲しくないので
   * ギリ許されるかもしれない。
   *
   * @param el
   * @private
   */
  private blurComponent(el: HTMLElement): void {
    let executedBlur: boolean = false;
    if ('__vue__' in el) {
      const component = (el as any).__vue__;
      if ('blur' in component && _.isFunction(component.blur)) {
        component.blur();
        executedBlur = true;
      }
    }
    if (executedBlur === false && el.parentElement) {
      this.blurComponent(el.parentElement);
    }
  }
}
