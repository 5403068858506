
import Vue, { PropType } from 'vue';
import { CssStyles, Maybe } from '~/framework/typeAliases';

export default Vue.extend({
  name: 'RTableDataCell',
  props: {
    width: {
      type: Number,
      required: true,
    },
    /**
     * Makes cell sticky to left for horizontal scroll.
     * Used in combination with `width` and `stickyPositionLeft`.
     */
    sticky: {
      type: Boolean as PropType<Maybe<boolean>>,
      required: false,
      default: undefined,
    },
    /**
     * Position to stick, measured from left of parent component.
     */
    stickyPositionLeft: {
      type: Number,
      required: false,
      default: 0,
    },
    disableFlexGrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableMinWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    style(): CssStyles {
      const styles: CssStyles = {};
      if (this.disableMinWidth) {
        styles.flexBasis = '0px';
      } else {
        styles.flexBasis = `${this.width}px`;
        styles.minWidth = `${this.width}px`;
      }

      if (this.sticky === true) {
        styles.maxWidth = `${this.width}px`;
        styles.position = `sticky`;
        styles.left = `${this.stickyPositionLeft}px`;
      }

      if (this.disableFlexGrow === true) styles.flexGrow = `0`;

      return styles;
    },
  },
});
