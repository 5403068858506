var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"r-driver-assignment"},[_c('RVInput',{ref:"input",attrs:{"hideDetails":"auto","appendToSublabel":true},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"r-driver-assignment__order-form-select"},[_c('ROrderFormSelect',{ref:"select",attrs:{"items":_vm.displaySelectionItems,"value":_vm.isCandidate ? 'enableMultipleAssignmentDialog' : undefined,"disabled":_vm.driverAssignmentDisabled},on:{"change":_vm.onChangeDriverAssignmentDetails}})],1)]},proxy:true}])},[_c('div',{staticClass:"r-driver-assignment__forms"},[_c('div',{staticClass:"r-driver-assignment__forms__drivers-form"},[_c('div',{staticClass:"r-driver-assignment__forms__drivers-form__driver-num"},[_c('RFieldTooltip',{attrs:{"content":"候補を複数選択している場合は、「候補を複数登録」から変更してください","disabled":!_vm.isCandidate}},[_c('VSelect',{attrs:{"value":_vm.driverNum,"suffix":"人","items":_vm.numItems,"label":"乗務員数*","hideDetails":"auto","rules":[_vm.required],"errorMessages":_vm.errorMessages,"errorCount":_vm.maxErrorCount,"disabled":_vm.driverNumDisabled || _vm.isCandidate},on:{"change":_vm.onChangeDriverNum}})],1)],1),_vm._v(" "),_c('div',{staticClass:"r-driver-assignment__forms__drivers-form__assignable-drivers"},[(!_vm.isHelperEnabled && !_vm.isCandidate)?_c('RSearchablePulldown',{attrs:{"value":_vm.selectedDriverIds,"entities":_vm.driverItems,"label":"乗務員指定","multiple":"","disabled":_vm.driverAssignmentDisabled,"entityName":"乗務員","no-data-text":"true","placeholder":"乗務員選択","itemValue":"persistentId","clearable":"","rules":[
              _vm.arrayLengthLessThanOrEqualToNum(_vm.driverNum, '乗務員が乗務可能な人数を超えています'),
              _vm.notIncludesGenerationSiteBannedDrivers,
              _vm.notIncludesTaskBannedDrivers,
            ]},on:{"change":function($event){return _vm.onChangeAssignableDriver('Driver', $event)}}}):_vm._e(),_vm._v(" "),(!_vm.isHelperEnabled && _vm.isCandidate)?_c('div',[_c('label',{staticClass:"r-driver-assignment__forms__drivers-form__assignable-drivers__label"},[_vm._v("\n              乗務員\n              "),(_vm.isDriversCandidate(_vm.driverNum, _vm.assignableDrivers))?_c('span',[_vm._v("(候補)")]):_vm._e()]),_vm._v(" "),_c('RDriverAssignmentMultipleView',{attrs:{"driverEntities":_vm.selectedDriverEntities,"label":"乗務員","isCandidate":_vm.isDriversCandidate(_vm.driverNum, _vm.assignableDrivers)},on:{"open:edit-dialog":function($event){return _vm.toggleDriverAssignmentMultipleEditDialog(true)}}})],1):_vm._e(),_vm._v(" "),(_vm.isHelperEnabled && !_vm.isCandidate)?_c('RSearchablePulldown',{attrs:{"value":_vm.selectedOperatorIds,"entities":_vm.selectableOperatorItems,"disabled":_vm.driverAssignmentDisabled,"multiple":"","entityName":"運転者","no-data-text":"true","label":"運転者指定","placeholder":"運転者選択","itemValue":"persistentId","clearable":"","rules":[
              _vm.notIncludesGenerationSiteBannedDrivers,
              _vm.notIncludesTaskBannedDrivers,
              _vm.arrayLengthLessThanOrEqualToNum(_vm.carNum, '乗務員が乗務可能な人数を超えています'),
            ]},on:{"change":function($event){return _vm.onChangeAssignableDriver('Operator', $event)}}}):_vm._e(),_vm._v(" "),(_vm.isHelperEnabled && _vm.isCandidate)?_c('div',[_c('label',{staticClass:"r-driver-assignment__forms__drivers-form__assignable-drivers__label"},[_vm._v("\n              運転者\n              "),(_vm.isOperatorsCandidate(_vm.carNum, _vm.assignableDrivers))?_c('span',[_vm._v("(候補)")]):_vm._e()]),_vm._v(" "),_c('RDriverAssignmentMultipleView',{attrs:{"driverEntities":_vm.selectedOperatorEntities,"label":"運転者","isCandidate":_vm.isOperatorsCandidate(_vm.carNum, _vm.assignableDrivers)},on:{"open:edit-dialog":function($event){return _vm.toggleDriverAssignmentMultipleEditDialog(true)}}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"r-driver-assignment__forms__helpers"},[(_vm.driverNum > _vm.carNum && !_vm.isCandidate && !_vm.driverAssignmentDisabled)?_c('div',{staticClass:"r-driver-assignment__forms__helpers__select-helper"},[(_vm.isHelperEnabled)?_c('div',{staticClass:"r-driver-assignment__forms__helpers__select-helper--pulldown"},[_c('RSearchablePulldown',{attrs:{"value":_vm.selectedHelperIds,"entities":_vm.selectableHelperItems,"multiple":"","entityName":"補助員","no-data-text":"true","label":"補助員指定*","placeholder":"補助員選択","itemValue":"persistentId","clearable":"","rules":[
                _vm.required,
                _vm.arrayLengthLessThanOrEqualToNum(_vm.maxHelperNum, '乗務員が乗務可能な人数を超えています'),
                _vm.notIncludesGenerationSiteBannedDrivers,
                _vm.notIncludesTaskBannedDrivers,
              ]},on:{"change":function($event){return _vm.onChangeAssignableDriver('Helper', $event)}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"r-driver-assignment__forms__helpers__settings"},[_c('RVCheckbox',{attrs:{"inputValue":_vm.isHelperEnabled,"label":"補助員を指定","color":"primary","hideDetails":"false"},on:{"change":_vm.onChangeHelperEnabled}}),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('VBtn',{attrs:{"depressed":"","outlined":"","color":"bluish-gray_40","small":""},on:{"click":_vm.onClickSetAllFieldWorkersToHelper}},[_vm._v("\n                現場作業員を補助員に自動設定\n              ")]),_vm._v(" "),_c('div',{staticClass:"h-100 ml-1"},[_c('RHelpTooltip',{attrs:{"position":"bottom"}},[_c('RDescribeCard',{attrs:{"items":[
                      {
                        text: '現場作業員は乗務員の形態から設定できます。',
                      },
                    ]}})],1)],1)],1)],1)]):_vm._e(),_vm._v(" "),(_vm.isHelperEnabled && _vm.driverNum > _vm.carNum && _vm.isCandidate)?_c('div',{staticClass:"r-driver-assignment__forms__helpers__show-helpers"},[_c('label',{staticClass:"r-driver-assignment__forms__helpers__show-helpers__label"},[_vm._v("\n            補助員\n            "),(_vm.isHelpersCandidate(_vm.carNum, _vm.driverNum, _vm.assignableDrivers))?_c('span',[_vm._v("(候補)")]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.isHelperEnabled && _vm.driverNum > _vm.carNum && _vm.isCandidate)?_c('div',{staticClass:"r-driver-assignment__forms__helpers__show-helpers"},[_c('RDriverAssignmentMultipleView',{attrs:{"driverEntities":_vm.selectedHelperEntities,"label":"補助員","isCandidate":_vm.isHelpersCandidate(_vm.carNum, _vm.driverNum, _vm.assignableDrivers)},on:{"open:edit-dialog":function($event){return _vm.toggleDriverAssignmentMultipleEditDialog(true)}}})],1):_vm._e()])])]),_vm._v(" "),(_vm.isDriverAssignmentMultipleDialogActive)?_c('RDriverAssignmentMultipleDialog',{attrs:{"isDialogActive":_vm.isDriverAssignmentMultipleDialogActive,"initialDriverNum":_vm.driverNum,"carNum":_vm.carNum,"driverNumItems":_vm.numItems,"initialDriverAssignmentType":_vm.driverAssignmentType,"initialAssignableDrivers":_vm.assignableDrivers,"driverItems":_vm.driverItems,"extraValidationRules":[_vm.notIncludesGenerationSiteBannedDrivers, _vm.notIncludesTaskBannedDrivers]},on:{"update":_vm.onUpdateDriverAssignmentMultiple,"close":function($event){return _vm.toggleDriverAssignmentMultipleEditDialog(false)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }