import { addDays, addMonths, format, lastDayOfMonth, startOfMonth, startOfWeek, endOfWeek, subMonths } from 'date-fns';
import ja from 'date-fns/locale/ja';
import _ from 'lodash';
import { DayOfWeek } from '~/framework/typeAliases';

import { formatDateToString } from '~/framework/services/date/date';
import {
  HeaderDayColumn,
  IHeaderDayColumn,
} from '~/components/common/r-driver-monthly-attendances-dialog/headerDayColumn';
import daysOfWeek from '~/assets/settings/daysOfWeek.json';
import { DaysAWeek } from '~/framework/constants';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';

export class MonthDatesCalendar {
  private readonly holidayRule: HolidayRuleEntity;
  private readonly startOfWeek: DayOfWeek;
  private _firstDateOfMonth!: Date;
  private _lastDateOfMonth!: Date;

  /**
   * その月の最初の日
   */
  get firstDateOfMonth(): Date {
    return this._firstDateOfMonth;
  }

  set firstDateOfMonth(value: Date) {
    this._firstDateOfMonth = value;
    this.lastDateOfMonth = lastDayOfMonth(value);
    this.updateMonthDates();
    this.updateMonthLabel();
    this.updateHeaderColumns();
  }

  /**
   * その月の最後の日
   */
  get lastDateOfMonth(): Date {
    return this._lastDateOfMonth;
  }

  set lastDateOfMonth(value: Date) {
    // NOTE 直で設定される事は想定されていないので注意
    this._lastDateOfMonth = value;
  }

  private get dateFnsOptions() {
    return { weekStartsOn: this.startOfWeek, locale: ja };
  }

  /**
   *  /**
   *    * その月の日付のリスト
   *    * 前後の日付も埋めようとするので必ずしもその月の日数には一致しない
   *
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  monthDates!: Date[];

  /**
   * /**
   *    * ヘッダに表示すべきカラム
   *
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  headerDayColumns!: IHeaderDayColumn[];

  /**
   *  /**
   *    * 2020年8月みたいな文字列
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  monthLabel!: string;

  /**
   * カレンダーの最初の日
   * 必ずしもその月の最初の日とは限らない
   */
  get firstDate(): Date {
    return _.first(this.monthDates)!;
  }

  /**
   * カレンダーの終わりの日
   * 必ずしもその月の最後の日とは限らない
   */
  get lastDate(): Date {
    return _.last(this.monthDates)!;
  }

  /**
   * baseDate の日が表示される様なカレンダーにする
   *
   * @param holidayRule
   * @param baseDate
   * @param startOfWeek
   */
  constructor(holidayRule: HolidayRuleEntity, baseDate: Date, startOfWeek: DayOfWeek) {
    this.holidayRule = holidayRule;
    this.startOfWeek = startOfWeek;
    this.firstDateOfMonth = this.getTargetMonthFrom(baseDate);
  }

  /**
   * カレンダーを前の月に移動する
   */
  shiftToPreviousMonth() {
    this.firstDateOfMonth = subMonths(this.firstDateOfMonth, 1);
  }

  /**
   * カレンダーを次の月に移動する
   */
  shiftToNextMonth() {
    this.firstDateOfMonth = addMonths(this.firstDateOfMonth, 1);
  }

  /**
   * ある日を基準として、それを表示する週の日を一週間分全て取得する
   * @param baseDate
   */
  getWeekDatesOf(baseDate: Date): Date[] {
    const dates: Date[] = [];
    const firstOfWeek = startOfWeek(baseDate, this.dateFnsOptions);
    for (let index = 0; index < DaysAWeek; index++) {
      dates.push(addDays(firstOfWeek, index));
    }
    return dates;
  }

  /**
   * 選択された月の日かどうかを返す
   */
  isDateOfSelectedMonth(date: Date): boolean {
    return this.firstDateOfMonth.getTime() <= date.getTime() && date.getTime() <= this.lastDateOfMonth.getTime();
  }

  private updateMonthDates(): void {
    const firstDate = startOfWeek(this.firstDateOfMonth, this.dateFnsOptions);
    const lastDate = addDays(startOfWeek(lastDayOfMonth(this.firstDateOfMonth), this.dateFnsOptions), 6);
    this.monthDates = [];
    for (let index = 0; true; index++) {
      const date = addDays(firstDate, index);
      this.monthDates.push(date);
      if (formatDateToString(date) === formatDateToString(lastDate)) break;
    }
  }

  private updateMonthLabel(): void {
    this.monthLabel = format(this.firstDateOfMonth, `yyyy年M月`, this.dateFnsOptions);
  }

  /**
   * 何かしらの適当な日付を与えて月のはじめの日を取得する
   *
   * @param date
   * @private
   */
  private getTargetMonthFrom(date: Date): Date {
    return startOfMonth(endOfWeek(date));
  }

  private updateHeaderColumns(): void {
    this.headerDayColumns = [];
    for (let index = 0; index < DaysAWeek; index++) {
      let day = this.startOfWeek + index;
      if (DaysAWeek <= day) day -= DaysAWeek;
      this.headerDayColumns.push(
        new HeaderDayColumn(daysOfWeek[day as DayOfWeek], this.holidayRule.isDayHoliday(day as DayOfWeek))
      );
    }
  }
}
