import { IRecurringOrderSettings } from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettings';

export enum DialogMode {
  New,
  Edit,
}

/**
 * interface 経由にするまでもないのでクラスにしてしまう
 */
export class RecurringOrderSettingsDialogValue {
  settings: IRecurringOrderSettings;
  mode: DialogMode;

  constructor(settings: IRecurringOrderSettings, mode: DialogMode) {
    this.settings = settings;
    this.mode = mode;
  }
}
