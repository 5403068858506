import { PreloadStatus } from '~/framework/domain/typeAliases';

export interface IPreloadOption {
  value: PreloadStatus;
  label: string;
}

export interface IDefaultPreloadOption {
  value: DefaultPreloadStatus;
  label: string;
}

export enum DefaultPreloadStatus {
  Forced = 'Forced',
  Modifiable = 'Modifiable',
  NotAllowed = 'NotAllowed',
  NotSet = 'NotSet',
}

/**
 * 宵積みの選択肢
 */
export const preloadOptions: IPreloadOption[] = [
  { value: PreloadStatus.Forced, label: '指定する' },
  { value: PreloadStatus.NotAllowed, label: '指定なし' },
  { value: PreloadStatus.Modifiable, label: '許可' },
];

/**
 * value から label を引ける様にしたもの
 */
export const preloadOptionMap = new Map<IPreloadOption['value'], IPreloadOption['label']>(
  preloadOptions.map((option) => [option.value, option.label])
);

/**
 * Labels and values for default preload status radio group UI
 */
export const getDefaultPreloadStatusOptions = (defaultPreloadStatus: PreloadStatus): IDefaultPreloadOption[] => {
  const defaultLabel = preloadOptionMap.get(defaultPreloadStatus);
  if (!defaultLabel) throw new Error('Impossible!');

  return [
    { value: DefaultPreloadStatus.Forced, label: '指定する' },
    { value: DefaultPreloadStatus.NotAllowed, label: '指定なし' },
    { value: DefaultPreloadStatus.Modifiable, label: '許可' },
    { value: DefaultPreloadStatus.NotSet, label: `ﾃﾞﾌｫﾙﾄ(${defaultLabel})` },
  ];
};

/**
 * This map is used to map enum to api values when sending data to server.
 */
export const defaultPreloadStatusEnumToApiValueMap = new Map<DefaultPreloadStatus, PreloadStatus | undefined>();
defaultPreloadStatusEnumToApiValueMap.set(DefaultPreloadStatus.Forced, PreloadStatus.Forced);
defaultPreloadStatusEnumToApiValueMap.set(DefaultPreloadStatus.Modifiable, PreloadStatus.Modifiable);
defaultPreloadStatusEnumToApiValueMap.set(DefaultPreloadStatus.NotAllowed, PreloadStatus.NotAllowed);
defaultPreloadStatusEnumToApiValueMap.set(DefaultPreloadStatus.NotSet, undefined);

/**
 * This map is used to restore data in form with data received from server.
 */
export const defaultPreloadStatusApiValueToEnumMap = new Map<PreloadStatus | undefined, DefaultPreloadStatus>();
defaultPreloadStatusApiValueToEnumMap.set(PreloadStatus.Forced, DefaultPreloadStatus.Forced);
defaultPreloadStatusApiValueToEnumMap.set(PreloadStatus.Modifiable, DefaultPreloadStatus.Modifiable);
defaultPreloadStatusApiValueToEnumMap.set(PreloadStatus.NotAllowed, DefaultPreloadStatus.NotAllowed);
defaultPreloadStatusApiValueToEnumMap.set(undefined, DefaultPreloadStatus.NotSet);
