import _ from 'lodash';
import { google } from 'google-maps';
import { Address, IAddress } from '~/framework/services/google-maps/address';
import { Maybe } from '~/framework/typeAliases';

/**
 * GeocoderResult からよさげなアドレスを取り出したりするやつ
 */
export interface IAddresses {
  preferredAddress: Maybe<IAddress>;
  preciseAddress: Maybe<IAddress>;
  acceptableAddress: Maybe<IAddress>;
  addresses: IAddress[];
}

export class Addresses implements IAddresses {
  private _addresses!: IAddress[];
  preferredAddress: Maybe<IAddress>;
  preciseAddress: Maybe<IAddress>;
  acceptableAddress: Maybe<IAddress>;

  set addresses(value: IAddress[]) {
    this._addresses = value;
    this.preferredAddress = this.getPreferredAddress();
    this.preciseAddress = this.getPreciseAddress();
    this.acceptableAddress = this.getAcceptableAddress();
  }

  get addresses() {
    return this._addresses;
  }

  constructor(addresses: IAddress[], inputAddress?: string) {
    // 特定の住所テキストでgoogle map検索をかけると、全く別の都道府県の結果が帰ってくることがある
    // 住所の表記ゆれなども考慮すると。現状優先順位判定では上記の結果を弾くことが難しいため、都道府県が一致しているかどうかをチェックするために以下の処理を追加する

    // 住所テキストの入力がある場合には、検索結果の都道府県もしくは市区町村が住所に含まれているかをフィルタして、フィルタ結果が存在する場合にはフィルタ結果を利用する
    // フィルタ結果が0件の場合にはすべての結果を利用する
    const filteredAddress = addresses.filter((address) => {
      // address2は市区町村以降の住所もスペース区切りで返却されるのでsplitをかけている
      return inputAddress?.includes(address.address1) || inputAddress?.includes(address.address2.split(' ')[0]);
    });
    this.addresses = filteredAddress.length > 0 ? filteredAddress : addresses;
  }

  private getPreferredAddress(): Maybe<IAddress> {
    this.prioritizeAddresses(this._addresses);
    return _.first(this._addresses);
  }

  private getPreciseAddress(): Maybe<IAddress> {
    const preciseAddresses = this._addresses.filter((address) => address.isPreciseAddress);
    this.prioritizeAddresses(preciseAddresses);
    return _.first(preciseAddresses);
  }

  private getAcceptableAddress(): Maybe<IAddress> {
    const acceptableAddresses = this._addresses.filter((address) => address.isAcceptableAddress);
    this.prioritizeAddresses(acceptableAddresses);
    return _.first(acceptableAddresses);
  }

  /**
   * 住所を住所タイプによって正確な順番にソート
   * 破壊的なので注意する事
   *
   * @param addresses
   * @private
   */
  private prioritizeAddresses(addresses: IAddress[]): void {
    addresses.sort((a, b) => a.compare(b));
  }
}

export class AddressesFactory {
  static buildAddresses(geocoderResults: google.maps.GeocoderResult[], inputAddress?: string): IAddresses {
    return new Addresses(
      geocoderResults.map((result) => new Address(result)),
      inputAddress
    );
  }
}
