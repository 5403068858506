import { Exception } from '~/framework/core/exception';

export const authentication$signInSymbol = Symbol('authentication$signInSymbol');

export type SignInApi = {
  [authentication$signInSymbol]: void;

  /**
   *
   * @param data
   * @throws SignInException
   */
  signIn(data: SignInData): Promise<SignInStatus>;
};

/**
 * ログイン状態
 */
export enum SignInStatus {
  Success = 'success',
  Failed = 'failed',
}

export type SignInData = {
  user: {
    email: string;
    password: string;
    // eslint-disable-next-line camelcase
    remember_me: boolean;
  };
};

export class SignInException extends Exception {
  readonly name = 'SignInException';
}
