import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedCarTypeContainerTypeEntity } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeEntity';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { ICarTypeContainerTypeData } from '~/framework/server-api/masters/carType';
import { AggregatedContainerTypeStore } from '../../container-type/aggregatedContainerTypeStore';
import { AggregatedContainerTypeMapper } from '../../container-type/aggregatedContainerTypeMapper';
import { PackingStyleStore } from '../../packing-style/packingStyleStore';

export class AggregatedCarTypeContainerTypeMapper extends StoredMapperBase<
  ICarTypeContainerTypeData,
  AggregatedCarTypeContainerTypeEntity
> {
  protected store: AggregatedCarTypeContainerTypeStore;
  private containerTypeMapper: AggregatedContainerTypeMapper;

  constructor(
    store: AggregatedCarTypeContainerTypeStore,
    containerTypeStore: AggregatedContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.containerTypeMapper = new AggregatedContainerTypeMapper(containerTypeStore, packingStyleStore);
  }

  protected instantiateWithData(data: ICarTypeContainerTypeData): AggregatedCarTypeContainerTypeEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    return new AggregatedCarTypeContainerTypeEntity(data, containerType);
  }

  protected updateWithData(data: ICarTypeContainerTypeData, entity: AggregatedCarTypeContainerTypeEntity): void {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    entity.update(data, containerType);
  }
}
