import { v4 as uuidv4 } from 'uuid';
import { Maybe } from '~/framework/typeAliases';
import { VuetifyColors } from '~/framework/constants';
import { ISnackbarButton } from '~/components/common/r-snackbar/snackbarButton';
import { SnackbarListPosition } from '~/components/common/r-snackbar-list/snackbarList';

export type SnackbarOption = Partial<Omit<ISnackbarData, 'id' | 'message'>>;

export interface ISnackbarData {
  /**
   * Unique identifier
   */
  id: string;
  /**
   * Snackbar が表示状態にあるかどうか
   * 生成時に true になる
   */
  value: boolean;
  /**
   * v-snackbar 内部に表示したいメッセージ
   * VNode としてもいいのかもしれないが、必要があれば今後検討
   */
  message: string;
  /**
   * バックグランド色
   */
  color: VuetifyColors;
  /**
   * 複数行になる様なテキストを小さく表示したい場合
   */
  described: boolean;
  /**
   * タイムアウト時間（0=自動的に閉じない）
   */
  timeout: Maybe<number>;
  /**
   * Snackbar 上に表示するボタン
   */
  buttons: Maybe<ISnackbarButton[]>;
  /**
   * Snackbar の表示位置
   */
  position: Maybe<SnackbarListPosition>;
  /**
   *  アウトラインを表示するかどうか
   */
  outlined: Maybe<boolean>;
  /**
   * 閉じるボタンを表示するかどうか
   */
  displayButton: boolean;
}

export class SnackbarData implements ISnackbarData {
  id: string;
  value: boolean;
  message: string;
  color: VuetifyColors;
  described: boolean;
  timeout: Maybe<number>;
  buttons: Maybe<ISnackbarButton[]>;
  position: Maybe<SnackbarListPosition>;
  outlined: Maybe<boolean>;
  displayButton: boolean;

  constructor(message: string, options: Maybe<SnackbarOption>) {
    const mergedOptions = {
      color: options?.color ?? VuetifyColors.Success,
      described: options?.described ?? false,
      timeout: options?.timeout ?? undefined,
      buttons: options?.buttons ?? undefined,
      position: options?.position ?? SnackbarListPosition.TopRight,
      outlined: options?.outlined ?? false,
      displayButton: options?.displayButton ?? true,
    };

    this.id = uuidv4();
    this.value = true;
    this.message = message;
    this.color = mergedOptions.color;
    this.described = mergedOptions.described;
    this.timeout = mergedOptions.timeout;
    this.buttons = mergedOptions.buttons;
    this.position = mergedOptions.position;
    this.outlined = mergedOptions.outlined;
    this.displayButton = mergedOptions.displayButton;
  }
}
