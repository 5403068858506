/**
 * Map から値を取り出そうと試みて存在しなければ指定された初期値を返す
 * Map に生やしてもよかったのだが、拡張はあんまりやるべきではなさそうなので
 * @param map
 * @param key
 * @param initialValue
 */
export const getOrInit = <K, V>(map: Map<K, V>, key: K, initialValue: V): V => {
  if (map.has(key) === false) map.set(key, initialValue);
  return map.getOrError(key);
};
