import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';

import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

/**
 * TaskTypeを編集するためのもの
 */
export interface ITaskTypeFormPanel extends IEntityFormPanel<TaskTypeEntity> {}

export class TaskTypeFormPanel extends AbstractEntityFormPanel<TaskTypeEntity> implements ITaskTypeFormPanel {}
