
import Vue from 'vue';
import _ from 'lodash';
import { EmploymentStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { required } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { employmentStatusOptions, IEmploymentStatusOption } from '~/framework/view-models/employmentStatusOption';
import { yomiganize } from '~/framework/services/string/string';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import RDriverFormAttendanceSelect from '~/components/panels/masters/r-driver-form/RDriverFormAttendanceSelect.vue';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import {
  driverSymbol,
  DriverApplicationService,
} from '~/framework/application/masters/driver/driverApplicationService';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { carSymbol } from '~/framework/application/masters/car/carApplicationService';
import { carTypeSymbol } from '~/framework/application/masters/car-type/carTypeApplicationService';
import { driverAttendanceTemplateSymbol } from '~/framework/application/masters/driver-attendance-template/driverAttendanceTemplateApplicationService';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { ids } from '~/framework/core/entity';

export enum FormMode {
  Register,
  Edit,
}

type InitialFormValues = {
  name: Maybe<string>;
  nameRuby: Maybe<string>;
  employmentStatus: Maybe<EmploymentStatus>;
  status: Maybe<SoftDeleteStatus>;
  defaultPrimaryCarId: Maybe<string>;
  defaultAttendanceTemplateId: Maybe<string>;
  licensedCarTypeIds: string[];
};

class DriverForm {
  title: string;
  rules: { [key: string]: ValidationRule };
  driver: Maybe<AggregatedDriverEntity>;
  createDriver: Maybe<AggregatedDriverEntity>;
  updateDriver: Maybe<AggregatedDriverEntity>;
  name: Maybe<string>;
  nameRuby: Maybe<string>;
  employmentStatus: Maybe<EmploymentStatus>;
  employmentStatusOptions: IEmploymentStatusOption[];
  status: Maybe<SoftDeleteStatus>;
  licensedCarTypeIds: string[];
  defaultPrimaryCarId: Maybe<string>;
  defaultPrimaryCarTypeId: Maybe<string>;
  defaultAttendanceTemplateId: Maybe<string>;
  cars: AggregatedCarEntity[];
  carTypes: AggregatedCarTypeEntity[];
  driverAttendanceTemplates: DriverAttendanceTemplateEntity[];
  _isFormValid: boolean = false;
  isRegisterButtonDisabled: boolean;
  isRegistering: boolean;
  formMode: FormMode;
  initialValues: InitialFormValues;
  defaultPrimaryCarType: Maybe<AggregatedCarTypeEntity>;
  isNameRubyTooltipOpen: Boolean;

  get isFormValid() {
    return this._isFormValid;
  }

  get isDirty(): boolean {
    const currentValues = {
      name: this.name,
      nameRuby: this.nameRuby,
      status: this.status,
      licensedCarTypeIds: this.licensedCarTypeIds,
      employmentStatus: this.employmentStatus,
      defaultPrimaryCarId: this.defaultPrimaryCarId,
      defaultAttendanceTemplateId: this.defaultAttendanceTemplateId,
    };
    const isDirty = !_.isEqual(currentValues, this.initialValues);
    return isDirty;
  }

  set isFormValid(value) {
    this._isFormValid = value;
    this.updateRegisterButtonDisabled();
  }

  constructor(
    cars: AggregatedCarEntity[],
    carTypes: AggregatedCarTypeEntity[],
    driverAttendanceTemplates: DriverAttendanceTemplateEntity[],
    driver: Maybe<AggregatedDriverEntity>
  ) {
    this.driver = driver;
    this.createDriver = undefined;
    this.updateDriver = undefined;
    this.employmentStatusOptions = employmentStatusOptions;
    this.cars = cars;
    this.carTypes = carTypes;
    this.driverAttendanceTemplates = driverAttendanceTemplates;
    this.formMode = this.driver === undefined ? FormMode.Register : FormMode.Edit;
    this.title = this.formMode === FormMode.Register ? '乗務員の登録' : '乗務員の編集';
    const initialValues = this.getInitialFormValues(this.driver);
    this.name = initialValues.name;
    this.nameRuby = initialValues.nameRuby;
    this.employmentStatus = initialValues.employmentStatus;
    this.status = initialValues.status;
    this.defaultPrimaryCarId = initialValues.defaultPrimaryCarId;
    this.licensedCarTypeIds = initialValues.licensedCarTypeIds;
    this.defaultPrimaryCarTypeId = driver?.defaultPrimaryCar?.carType.id;
    this.defaultAttendanceTemplateId = driver?.defaultAttendanceTemplate?.id;
    this.rules = { required };
    this.isRegisterButtonDisabled = true;
    this.isRegistering = false;
    // 初期値を保持
    this.initialValues = initialValues;
    this.isNameRubyTooltipOpen = false;
  }

  onAttendanceTemplateChange(): void {
    const _defaultAttendanceTemplate = this.driverAttendanceTemplates.find((v) => {
      return v.persistentId === this.defaultAttendanceTemplateId;
    });
    this.defaultAttendanceTemplateId = _defaultAttendanceTemplate?.id;
  }

  onChangeNameRuby(): void {
    if (this.nameRuby) {
      const [replacedNameRuby, containedIllegalValue] = yomiganize(this.nameRuby);
      Vue.nextTick().then(() => {
        this.nameRuby = replacedNameRuby;
        if (containedIllegalValue) {
          this.isNameRubyTooltipOpen = true;
          setTimeout(() => {
            this.isNameRubyTooltipOpen = false;
          }, 2000);
        }
      });
    }
  }

  onChangeDefaultPrimaryCarTypeId(): void {
    this.defaultPrimaryCarId = undefined;
  }

  private getInitialFormValues(driver: Maybe<AggregatedDriverEntity>): InitialFormValues {
    return driver === undefined ? this.getDefaultInitialFormValues() : this.getInitialFormValuesByOrder(driver);
  }

  private getDefaultInitialFormValues(): InitialFormValues {
    return {
      name: undefined,
      nameRuby: undefined,
      employmentStatus: undefined,
      status: undefined,
      defaultPrimaryCarId: undefined,
      defaultAttendanceTemplateId: undefined,
      licensedCarTypeIds: [],
    };
  }

  private updateRegisterButtonDisabled(): void {
    this.isRegisterButtonDisabled = !this.isFormValid;
  }

  private getInitialFormValuesByOrder(driver: AggregatedDriverEntity): InitialFormValues {
    return {
      name: driver.name,
      nameRuby: driver.nameRuby,
      employmentStatus: driver.employmentStatus,
      status: driver.status,
      defaultPrimaryCarId: driver.defaultPrimaryCar?.id,
      defaultAttendanceTemplateId: driver.defaultAttendanceTemplate?.id,
      licensedCarTypeIds: ids(driver.licensedCarTypes),
    };
  }
}

type DataType = AsyncDataType & {
  isActive: boolean;
  FormMode: typeof FormMode;
  isCloseConfirmDialogActive: boolean;
  isDeleteConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  driverApplicationService: DriverApplicationService;
};

type AsyncDataType = {
  viewModel: Maybe<DriverForm>;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RDriverForm',
  components: {
    RDriverFormAttendanceSelect,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const driverApplicationService = this.$context.applications.get(driverSymbol);
    return {
      isActive: false,
      viewModel: undefined,
      FormMode,
      listenerDisposer: undefined,
      isCloseConfirmDialogActive: false,
      isDeleteConfirmDialogActive: false,
      closeConfirmDialogResolver: undefined,
      driverApplicationService,
    };
  },
  computed: {
    sortedCars(): AggregatedCarEntity[] {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const cars = this.viewModel.cars || [];
      return cars.filter((car: AggregatedCarEntity) => car.carType.id === this.viewModel?.defaultPrimaryCarTypeId);
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.DRIVER;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.driverFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.driverFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async open(driver: Maybe<AggregatedDriverEntity> = undefined): Promise<void> {
      if (this.isActive) return;
      const carApplicationService = this.$context.applications.get(carSymbol);
      const carTypeApplicationService = this.$context.applications.get(carTypeSymbol);
      const driverAttendanceTemplateApplicationService = this.$context.applications.get(driverAttendanceTemplateSymbol);
      const [cars, carTypes, driverAttendanceTemplates] = await Promise.all([
        carApplicationService.getAll(),
        carTypeApplicationService.getAll(),
        driverAttendanceTemplateApplicationService.getAll(),
      ]);
      this.viewModel = new DriverForm(cars, carTypes, driverAttendanceTemplates, driver);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      // サイドフォームを閉じる
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<AggregatedDriverEntity> = {
        entity: this.viewModel!.driver,
        createdEntity: this.viewModel?.createDriver,
        updatedEntity: this.viewModel?.updateDriver,
        removedEntity: undefined,
      };
      this.$context.panels.driverFormPanel.closeFormEvent.emit(closeFormArgs);
    },
    async onOpenForm(args: IOpenEntityFormArgs<AggregatedDriverEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async onCloseForm(): Promise<boolean> {
      // 変更している場合にダイアログを表示する
      if (this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    // 乗務員の論理削除が未対応なので、このメソッドはUI上の同線がない
    async onConfirmDeleteDriver(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      if (this.viewModel.driver === undefined) throw new Error(`Impossible!`);
      this.viewModel.isRegistering = true;
      this.viewModel.updateDriver = await this.driverApplicationService.delete(this.viewModel.driver.persistentId);
      this.viewModel.isRegistering = false;
      this.$context.snackbar.success('乗務員の削除完了');
      await this.close();
    },
    onDeleteDriver(): void {
      this.isDeleteConfirmDialogActive = true;
    },
    async onRegisterButtonClick(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;
      if (viewModel.formMode === FormMode.Register) {
        const entity = await this.driverApplicationService.create({
          name: viewModel.name!,
          nameRuby: viewModel.nameRuby!,
          employmentStatus: viewModel.employmentStatus!,
          defaultPrimaryCarId: viewModel.defaultPrimaryCarId,
          licensedCarTypeIds: viewModel.licensedCarTypeIds,
          defaultAttendanceTemplateId: viewModel.defaultAttendanceTemplateId,
          status: SoftDeleteStatus.Active,
        });
        viewModel.createDriver = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        const driver = viewModel.driver!;
        const entity = await this.driverApplicationService.update({
          id: driver.persistentId,
          name: viewModel.name!,
          nameRuby: viewModel.nameRuby!,
          employmentStatus: viewModel.employmentStatus!,
          defaultPrimaryCarId: viewModel.defaultPrimaryCarId,
          licensedCarTypeIds: viewModel.licensedCarTypeIds,
          defaultAttendanceTemplateId: viewModel.defaultAttendanceTemplateId,
          status: SoftDeleteStatus.Active,
        });
        viewModel.updateDriver = entity;
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('乗務員の登録完了');
      await this.close();
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.driverFormPanel.updateDisplayedWidth(value);
    },
  },
});
