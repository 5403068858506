import { StoredMapperBase } from '~/framework/core/mapper';
import {
  OrderAcceptanceCheckEntityData,
  OrderAcceptanceCheckEntity,
} from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckEntity';
import { OrderAcceptanceCheckStore } from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckStore';

export class OrderAcceptanceCheckMapper extends StoredMapperBase<
  OrderAcceptanceCheckEntityData,
  OrderAcceptanceCheckEntity
> {
  protected readonly store: OrderAcceptanceCheckStore;

  constructor(store: OrderAcceptanceCheckStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: OrderAcceptanceCheckEntityData): OrderAcceptanceCheckEntity {
    return new OrderAcceptanceCheckEntity(
      data.id,
      data.acceptanceData,
      data.ordersNum,
      data.estimatedFinishAt,
      data.status
    );
  }

  protected updateWithData(data: OrderAcceptanceCheckEntityData, entity: OrderAcceptanceCheckEntity): void {
    entity.acceptanceData = data.acceptanceData;
    entity.ordersNum = data.ordersNum;
    entity.estimatedFinishAt = data.estimatedFinishAt;
    entity.status = data.status;
  }
}
