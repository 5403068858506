import { IRawOrderAcceptanceJsonObject } from './orderAcceptanceCheck';
import { Maybe } from '~/framework/typeAliases';
import { IUpdateOrderData } from '~/framework/server-api/schedule/order/updateOrders';
import { OrderAcceptanceCheckStatus } from '~/framework/domain/typeAliases';

export const orderAcceptanceCheck$createSymbol = Symbol('orderAcceptanceCheck$createSymbol');

export interface ICreateApi {
  [orderAcceptanceCheck$createSymbol]: void;
  create(order: ICreateOrderAcceptanceCheckData): Promise<ICreateResult>;
}

/**
 * 本来なら、ICreateApi の引数の型は updateOrders の IUpdateData と同じ型で問題ない。
 * しかし、受注の瞬間チェックエラー後に実行される
 * scheduleFactory の buildOrderAcceptanceCheckInfeasibilities の引数(input: ICreateData)で clientId が必要になるため、
 * ここで IUpdateData を拡張して、ICreateApi 内で clientId を delete している。
 * 排出場(GenerationSite)取得の GraphQL から、ネストして顧客(Client)を取得できれば、このextendsは不要になる
 */
export interface ICreateOrderAcceptanceCheckData extends IUpdateOrderData {
  clientId: string;
  // updateにはreservationIdが必要なくcreateのみで必要なのでここで追加
  reservationId: Maybe<string>;
}

export interface ICreateResult {
  id: string;
  acceptanceData: IRawOrderAcceptanceJsonObject;
  status: OrderAcceptanceCheckStatus;
}
