import { getDay } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { OrderRecurringSettingsType } from '~/framework/domain/typeAliases';
import { OrderRecurringSettings } from '~/framework/server-api/typeAliases';
import { Maybe, DayOfWeek, DayOfMonth } from '~/framework/typeAliases';
import { dayOfWeekToIndex, dayOfWeekToLabel, indexToDayOfWeek } from '~/framework/view-models/daysOfWeek';

import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';
import {
  weekdayOrdinalToIndex,
  weekdayOrdinalToLabel,
  calculateWeekdayOrdinal,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/weekdayOrdinal';
import { ensure } from '~/framework/core/value';

export interface IRecurringOrderSettings {
  startAt: Date;
  endAt: Maybe<Date>;
  type: OrderRecurringSettingsType;
  step: number;
  daysOfWeek: Maybe<DayOfWeek[]>;
  /**
   * 受注登録を行う際に登録される月の繰り返し設定。
   */
  daysOfMonth: Maybe<DayOfMonth[]>;
  /**
   * `daysOfMonthInput` は `daysOfMonth` と違って、未完成な入力も許可するためにおいている。
   * 月の繰り返し受注の設定を `追加` したときには、その中身が `undefined` から始まり、そこからユーザーが入力する。そのため未完成な状態が存在する。
   * `setDaysOfMonth` を呼ぶことによって、完成している設定が `daysOfMonth` に入る。
   */
  daysOfMonthInput: Maybe<Partial<DayOfMonth>[]>;
  readonlyDaysOfWeek: Maybe<DayOfWeek[]>;
  includeNationalHolidays: boolean;

  /**
   * 繰り返し条件をテキストで返すメソッド
   */
  getRecurringIntervalText(options?: { sort?: boolean }): string;

  /**
   * 同じ内容のオブジェクトをコピーして返す
   * そのまま書き換えるとキャンセルできなくなるので
   */
  clone(): IRecurringOrderSettings;

  /**
   * `daysOfMonthInput` から未入力の項目を除いて `daysOfMonth` に入れる
   */
  setDaysOfMonth(): void;
  /**
   * daysOfMonthInput で重複している設定のインデックスの配列。
   * 例： ['a', 'b', 'a', 'a', 'c', 'b'] の場合は
   * インデックス２, インデックス３, インデックス5 の配列を返す ([2, 3, 5])。
   * 繰り返し設定が月の繰り返しではないときには undefined。
   */
  indexesOfDuplicatedDaysOfMonths: Maybe<Set<number>>;
  daysOfMonthDuplicatesExist: Maybe<boolean>;
  duplicateDaysOfMonthErrorMessage: Maybe<string>;
  updateDuplicateDaysOfMonth: () => void;
}

/**
 * デフォルトのダイアログ用オブジェクトを生成する
 * 新規設定時に利用する
 * @param date
 */
export const buildDefault = (date: Date): IRecurringOrderSettings => {
  return new RecurringOrderSettings(date, undefined, OrderRecurringSettingsType.Daily, 1, undefined, undefined, true);
};

/**
 * 既存の設定からダイアログ用オブジェクトを生成する
 * @param settings
 */
export const buildByOrderRecurringSettings = (settings: OrderRecurringSettings): IRecurringOrderSettings => {
  const daysOfWeek = settings.daysOfWeek?.map((day) => dayOfWeekToIndex(day));

  return new RecurringOrderSettings(
    settings.startAt,
    settings.endAt,
    settings.type,
    settings.step,
    daysOfWeek,
    settings.daysOfMonth,
    settings.includeNationalHolidays
  );
};

/**
 * 月の繰り返し設定を特定な順番にする関数。
 * `月の第 n 回目の m 曜日` の n の 昇順、m の 昇順。
 */
export const sortDaysOfMonth = (a: DayOfMonth, b: DayOfMonth) => {
  const aIndex = {
    weekdayOrdinalIndex: weekdayOrdinalToIndex(a.weekdayOrdinal),
    dayOfWeekIndex: dayOfWeekToIndex(a.dayOfWeek),
  };
  const bIndex = {
    weekdayOrdinalIndex: weekdayOrdinalToIndex(b.weekdayOrdinal),
    dayOfWeekIndex: dayOfWeekToIndex(b.dayOfWeek),
  };

  return aIndex.weekdayOrdinalIndex - bIndex.weekdayOrdinalIndex || aIndex.dayOfWeekIndex - bIndex.dayOfWeekIndex;
};

export class RecurringOrderSettings implements IRecurringOrderSettings {
  startAt: Date;
  endAt: Maybe<Date>;
  step: number;
  daysOfWeek: Maybe<DayOfWeek[]>;
  daysOfMonth: Maybe<DayOfMonth[]>;
  daysOfMonthInput: Maybe<Partial<DayOfMonth>[]>;
  readonlyDaysOfWeek: Maybe<DayOfWeek[]>;
  includeNationalHolidays: boolean;
  /**
   * 月の繰り返し設定を行う際に、同じ設定が複数回入力されている場合、
   * 複数設定の情報と処理を持つオブジェクト。
   */
  private daysOfMonthDuplicates: Maybe<IDaysOfMonthDuplicates>;
  indexesOfDuplicatedDaysOfMonths: Maybe<Set<number>>;
  daysOfMonthDuplicatesExist: Maybe<boolean>;
  duplicateDaysOfMonthErrorMessage: Maybe<string>;

  private _type: OrderRecurringSettingsType;
  set type(value: OrderRecurringSettingsType) {
    this._type = value;
    if (this._type === OrderRecurringSettingsType.Daily) {
      this.daysOfWeek = undefined;
      this.resetDaysOfMonthInput();
    } else if (this._type === OrderRecurringSettingsType.Weekly) {
      this.daysOfWeek = [getDay(this.startAt)];
      this.resetDaysOfMonthInput();
    } else if (this._type === OrderRecurringSettingsType.Monthly) {
      this.daysOfWeek = undefined;
      this.initializeDaysOfMonthInput();
    } else {
      throw new Error(`Impossible type, ${this._type}`);
    }
  }

  get type() {
    return this._type;
  }

  constructor(
    startAt: Date,
    endAt: Maybe<Date>,
    type: OrderRecurringSettingsType,
    step: number,
    daysOfWeek: Maybe<DayOfWeek[]>,
    daysOfMonth: Maybe<DayOfMonth[]>,
    includeNationalHolidays: boolean
  ) {
    this.startAt = startAt;
    this.endAt = endAt;
    this._type = type;
    this.step = step;
    this.daysOfWeek = daysOfWeek;
    this.daysOfMonth = daysOfMonth;
    this.daysOfMonthInput = daysOfMonth;
    this.readonlyDaysOfWeek = [getDay(startAt)];
    this.includeNationalHolidays = includeNationalHolidays;
    this.indexesOfDuplicatedDaysOfMonths = undefined;
    this.daysOfMonthDuplicatesExist = undefined;
    this.duplicateDaysOfMonthErrorMessage = undefined;

    if (type === OrderRecurringSettingsType.Monthly) this.initializeDaysOfMonthInput();
  }

  private initializeDaysOfMonthInput(): void {
    if (this.daysOfMonthInput === undefined && this.daysOfMonth === undefined) {
      this.daysOfMonthInput = [
        {
          weekdayOrdinal: calculateWeekdayOrdinal(this.startAt),
          dayOfWeek: indexToDayOfWeek(getDay(this.startAt)),
        },
      ];
    } else if (this.daysOfMonthInput === undefined && this.daysOfMonth !== undefined) {
      this.daysOfMonthInput = this.daysOfMonth;
    }

    this.initializeDaysOfMonthDuplicates();
  }

  private initializeDaysOfMonthDuplicates(): void {
    ensure(this.daysOfMonthInput);
    this.daysOfMonthDuplicates = new DaysOfMonthDuplicates(this.daysOfMonthInput);
    this.indexesOfDuplicatedDaysOfMonths = this.daysOfMonthDuplicates.indexesOfDuplicates;
    this.daysOfMonthDuplicatesExist = this.daysOfMonthDuplicates.duplicatesExist;
    this.duplicateDaysOfMonthErrorMessage = this.daysOfMonthDuplicates.errorMessage;
  }

  private resetDaysOfMonthInput(): void {
    this.daysOfMonthInput = undefined;
    this.resetDuplicateDaysOfMonth();
  }

  private resetDuplicateDaysOfMonth(): void {
    this.daysOfMonthDuplicates = undefined;
    this.indexesOfDuplicatedDaysOfMonths = undefined;
    this.daysOfMonthDuplicatesExist = undefined;
    this.duplicateDaysOfMonthErrorMessage = undefined;
  }

  updateDuplicateDaysOfMonth(): void {
    ensure(this.daysOfMonthDuplicates);
    this.daysOfMonthDuplicates.updateDuplicates();
    this.indexesOfDuplicatedDaysOfMonths = this.daysOfMonthDuplicates.indexesOfDuplicates;
    this.daysOfMonthDuplicatesExist = this.daysOfMonthDuplicates.duplicatesExist;
    this.duplicateDaysOfMonthErrorMessage = this.daysOfMonthDuplicates.errorMessage;
  }

  getRecurringIntervalText(options?: { sort?: boolean }): string {
    if (this._type === OrderRecurringSettingsType.Daily) {
      return this.step === 1 ? '毎日' : `${this.step}日ごと`;
    } else if (this._type === OrderRecurringSettingsType.Weekly) {
      const text = this.step === 1 ? '毎週' : `${this.step}週ごと`;
      if (!this.daysOfWeek) throw new Error(`Impossible`);
      return this.daysOfWeek.reduce((text, dayOfWeek) => {
        return text + `、${daysOfWeekMap[dayOfWeek]}曜`;
      }, text);
    } else if (this._type === OrderRecurringSettingsType.Monthly) {
      if (this.daysOfMonth === undefined) throw new Error('Impossible!');

      const daysOfMonth = cloneDeep(this.daysOfMonth);
      if (options?.sort) daysOfMonth.sort(sortDaysOfMonth);

      const daysOfMonthStrings = daysOfMonth.map((dayOfMonth) => {
        return `${weekdayOrdinalToLabel(dayOfMonth.weekdayOrdinal)}${dayOfWeekToLabel(dayOfMonth.dayOfWeek)}曜`;
      }, [] as string[]);
      const daysOfMonthString: string = daysOfMonthStrings.join('、');
      const monthString = this.step === 1 ? '毎月 ' : `${this.step}カ月ごと `;

      return `${monthString}${daysOfMonthString}`;
    } else {
      throw new Error(`Impossible type, ${this._type}`);
    }
  }

  clone(): IRecurringOrderSettings {
    return new RecurringOrderSettings(
      this.startAt,
      this.endAt,
      this.type,
      this.step,
      this.daysOfWeek === undefined ? undefined : [...this.daysOfWeek],
      this.daysOfMonth === undefined ? undefined : [...this.daysOfMonth],
      this.includeNationalHolidays
    );
  }

  setDaysOfMonth(): void {
    if (this.daysOfMonthInput === undefined) {
      this.daysOfMonth = undefined;
      return;
    }
    this.daysOfMonth = this.daysOfMonthInput.filter((dayOfMonth) => {
      return dayOfMonth.dayOfWeek !== undefined && dayOfMonth.weekdayOrdinal !== undefined;
    }) as DayOfMonth[];
  }
}

interface IDaysOfMonthDuplicates {
  /**
   * daysOfMonth で重複している設定のインデックスの配列。
   * 例： ['a', 'b', 'a', 'a', 'c', 'b'] の場合は
   * インデックス２, インデックス３, インデックス5 の配列を返す ([2, 3, 5])。
   */
  indexesOfDuplicates: Set<number>;
  duplicatesExist: boolean;
  errorMessage: Maybe<string>;
  updateDuplicates: () => void;
}

class DaysOfMonthDuplicates implements IDaysOfMonthDuplicates {
  private daysOfMonth: Partial<DayOfMonth>[];
  indexesOfDuplicates: Set<number>;

  constructor(daysOfMonth: Partial<DayOfMonth>[]) {
    this.daysOfMonth = daysOfMonth;
    this.indexesOfDuplicates = new Set();
  }

  get duplicatesExist(): boolean {
    return this.indexesOfDuplicates.size > 0;
  }

  get errorMessage(): Maybe<string> {
    if (!this.duplicatesExist) return;

    const duplicatedDaysOfMonthString = [] as string[];
    this.indexesOfDuplicates.forEach((index) => {
      const dayOfMonth = this.daysOfMonth[index];

      // `dayOfMonth` やその中身が `undefined` になっている場合は
      // `indexesOfDuplicates` に該当のインデックスがあること自体は何か間違っているので `ensure` する。
      ensure(dayOfMonth);
      ensure(dayOfMonth.weekdayOrdinal);
      ensure(dayOfMonth.dayOfWeek);
      duplicatedDaysOfMonthString.push(
        `${weekdayOrdinalToLabel(dayOfMonth.weekdayOrdinal)}${dayOfWeekToLabel(dayOfMonth.dayOfWeek)}曜日`
      );
    });

    const duplicatedDaysOfMonthStringUniqueSet = new Set(duplicatedDaysOfMonthString);
    return `${[...duplicatedDaysOfMonthStringUniqueSet].join('、')}はすでに登録されています。削除してください。`;
  }

  updateDuplicates(): void {
    const indexesOfDuplicates: Set<number> = new Set();

    for (let index = 0; index < this.daysOfMonth.length; index++) {
      for (let compareIndex = index + 1; compareIndex < this.daysOfMonth.length; compareIndex++) {
        const dayOfMonth = this.daysOfMonth[index];
        const dayOfMonthToCompare = this.daysOfMonth[compareIndex];

        // 未入力の項目があれば比較の対象外とする。
        if (
          dayOfMonth.weekdayOrdinal !== undefined &&
          dayOfMonth.dayOfWeek !== undefined &&
          dayOfMonthToCompare.weekdayOrdinal !== undefined &&
          dayOfMonthToCompare.dayOfWeek !== undefined &&
          dayOfMonthToCompare.weekdayOrdinal === dayOfMonth.weekdayOrdinal &&
          dayOfMonthToCompare.dayOfWeek === dayOfMonth.dayOfWeek
        ) {
          indexesOfDuplicates.add(compareIndex);
        }
      }
    }

    this.indexesOfDuplicates = indexesOfDuplicates;
  }
}
