import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Maybe } from '~/framework/typeAliases';
import {
  IDisposalEntity,
  IDisposalEntityData,
} from '~/framework/domain/schedule/schedule/pseudo-entities/disposalEntity';
import {
  ICollectionEntity,
  ICollectionEntityData,
} from '~/framework/domain/schedule/schedule/pseudo-entities/collectionEntity';

import { IOriginalRouteEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalRouteEntity';
import { IInconsistentRouteInfoData } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfoData';
import { IInconsistentRouteInfo as IBaseInconsistentRouteInfo } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfo';

import { integerize } from '~/framework/domain/schedule/schedule/pseudo-entities/integerization';
import { IllegalArgumentException, IllegalStateException } from '~/framework/core/exception';
import { CollectionComparison } from '~/framework/domain/schedule/schedule/pseudo-entities/collectionComparison';
import { hhMmToSecs } from '~/framework/services/date-time/date-time';
import { endOfItems, pairNext, zip } from '~/framework/core/array';
import { IOriginalCollectionEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalCollectionEntity';

export interface IRouteEntityData<
  Collection extends ICollectionEntityData,
  Disposal extends IDisposalEntityData,
  InconsistentRouteInfo extends IInconsistentRouteInfoData
> {
  /**
   * 一意な ID
   */
  id: string;

  /**
   * ドライバーの順番（ドライバーで一意）
   */
  index: number;

  /**
   * 車の ID
   * 回収の入れ換えによって車が不定になっている場合は undefined
   */
  carId: Maybe<PseudoId>;

  /**
   * 車が利用されるシチュエーションに割り当てられた ID
   * 回収の入れ換えによって車が不定になっている場合は undefined
   */
  carIndex: Maybe<number>;

  /**
   * ドライバーの ID
   */
  driverId: PseudoId;

  /**
   * 運転者かどうか
   * 配車表の route に is_driver が存在しない場合は undefined
   */
  isDriver: Maybe<boolean>;

  /**
   * 補助員かどうか
   * 配車表の route に is_helper が存在しない場合は undefined
   */
  isHelper: Maybe<boolean>;

  /**
   * ルートの開始時間
   * この時間は再計算で勝手に変更される可能性がある
   */
  startTime: number;

  /**
   * ルートの終了時間
   * この時間は再計算で勝手に変更される可能性がある
   */
  endTime: number;

  /**
   * 車庫出発サイト ID
   */
  garageSiteDepartureId: Maybe<PseudoId>;

  /**
   * 車庫出発時間（00:00 からの秒数）
   */
  garageSiteDepartureTime: Maybe<number>;

  /**
   * 車庫到着サイト ID
   */
  garageSiteArrivalId: Maybe<PseudoId>;

  /**
   * 車庫到着時間（00:00 からの秒数）
   */
  garageSiteArrivalTime: Maybe<number>;

  /**
   * 車庫到着前に休憩を挟むかどうか
   */
  hasRestBeforeGarageSiteArrival: Maybe<boolean>;

  /**
   * 排出場出発後に休憩を挟むかどうか
   * 処分場に寄らないがこのルート内に休憩を入れたい場合に true
   * 排出場出発〜endTime までの間が休憩となる
   */
  hasRestAfterGenerationSiteDeparture: Maybe<boolean>;

  /**
   * 処分場出発後に休憩を挟むかどうか
   * 処分場出発後にこのルート内に休憩を入れたい場合に true
   * 処分場出発〜endTime までの間が休憩となる
   */
  hasRestAfterDisposalSiteDeparture: Maybe<boolean>;

  /**
   * 車の乗り換えがあるかどうか
   */
  hasChangeCar: Maybe<boolean>;

  /**
   * 拠点 ID
   */
  baseSiteId: Maybe<PseudoId>;

  /**
   * 拠点到着時間
   */
  baseSiteArrivalTime: Maybe<number>;

  /**
   * 拠点出発時間
   */
  baseSiteDepartureTime: Maybe<number>;

  /**
   * 拠点到着前に休憩を挟むかどうか
   */
  hasRestBeforeBaseSiteArrival: Maybe<boolean>;

  /**
   * 順番固定
   */
  isFixedAssignment: boolean;

  /**
   * 時間固定
   */
  isFixedSchedule: boolean;

  /**
   * 収集
   */
  collections: Collection[];

  /**
   * 処分場
   */
  disposals: Disposal[];

  /**
   * 最適化エンジンに指定された過積載の原因となりうるドライバーとルートのindexの情報
   */
  inconsistentLoadingRouteInfos: InconsistentRouteInfo[];

  /**
   * 最適化エンジンに指定された到着時間に間に合わない原因となりうるドライバーとルートのindexの情報
   */
  inconsistentTimeRouteInfos: InconsistentRouteInfo[];
}

export interface IRouteEntity<
  Original extends IOriginalRouteEntity<any, any, any>,
  Collection extends ICollectionEntity<any, Collection>,
  Disposal extends IDisposalEntity<any, Disposal>,
  InconsistentRouteInfo extends IBaseInconsistentRouteInfo<InconsistentRouteInfo>,
  Clone extends IRouteEntity<Original, Collection, Disposal, InconsistentRouteInfo, Clone>
> extends IRouteEntityData<Collection, Disposal, InconsistentRouteInfo> {
  // 必要なプロパティ・メソッドを定義

  /**
   * オリジナルの値
   */
  original: Maybe<Original>;

  /**
   * 状態が更新された時に更新される
   * コンポーネントの状態を強制的に再描画したいために利用している
   * https://michaelnthiessen.com/force-re-render/
   */
  stateId: string;

  /**
   * ユーザーに入力された固定の開始時間
   * 固定されていなければ undefined
   */
  fixedStartTime: Maybe<number>;

  /**
   * ユーザーに入力された固定の終了時間
   * 固定されていなければ undefined
   */
  fixedEndTime: Maybe<number>;

  /**
   * 排出場に寄る場合に、その中の最後の排出場の出発時間
   */
  disposalSiteDepartureTime: Maybe<number>;

  /**
   * 二番目以降のルート収集での前回の排出場の出発時間
   * 設置のみのタスクなどの場合前回のルートで処分場に寄っていない可能性があり、
   * そういう場合はこの値を利用する可能性がある
   */
  lastGenerationSiteDepartureTime: Maybe<number>;

  /**
   * 二番目以降のルート収集で前回の車庫到着時間がある場合
   */
  lastGarageSiteArrivalTime: Maybe<number>;

  /**
   * 二番目以降のルート回収で前回の処分場出発時間がある場合
   */
  lastDisposalSiteDepartureTime: Maybe<number>;

  /**
   * 前のルートで排出場の出発後に休憩を取っていた場合、それが終了した時間
   * 実質的には前のルートの endTime と一致する
   */
  lastRestAfterGenerationSiteDepartureEndTime: Maybe<number>;

  /**
   * 前のルートで処分場の出発後に休憩を取っていた場合、それが終了した時間
   * 実質的には前のルートの endTime と一致する
   */
  lastRestAfterDisposalSiteDepartureEndTime: Maybe<number>;

  /**
   * 処分場に寄るかどうか
   * （少なくとも１つは処分場が設定されているかどうか）
   */
  hasDisposals: boolean;

  /**
   * 次のルート
   */
  nextRoute: Maybe<IRouteEntity<Original, Collection, Disposal, InconsistentRouteInfo, Clone>>;

  /**
   * 前のルート
   */
  previousRoute: Maybe<IRouteEntity<Original, Collection, Disposal, InconsistentRouteInfo, Clone>>;

  /**
   * 時間が不定になっているかどうか
   * ルート順を入れ換えると不定になる
   */
  isTimetableUnstable: boolean;

  /**
   * 回収が編集されているかどうか
   * 追加・削除・入れ換えなど行っていると編集されている事になる
   */
  isCollectionEdited: boolean;

  /**
   * route にかかる時間
   */
  routeDuration: number;

  /**
   * ドライバーの最初のルートかどうか
   */
  isFirstOfDriver: boolean;

  /**
   * ドライバーの最後のルートかどうか
   */
  isLastOfDriver: boolean;

  /**
   * ルート回収かどうか
   */
  isRouteCollection: boolean;

  /**
   * ルート内に1つでも回収の順番固定があるか
   */
  isFixedAssignmentInAnyOfCollections: boolean;

  /**
   * ルートの固定を解除する
   *
   * 自動的に時間の固定も解除される
   * これを呼んだ後は時間を再計算する必要がある事に注意する
   * フリーになった事で後述のルートも全て再計算する必要があるので scheduleDataEntity から操作する必要がある
   */
  unfix(): void;

  /**
   * ルートを固定する
   */
  fix(): void;

  /**
   * ユーザーに入力された固定の開始と終了時間をリセットする
   */
  resetFixedStartAndEndTime(): void;

  /**
   * 開始時間を指定してスケジュールを fix する
   * @param startTime
   */
  fixScheduleByStartTime(startTime: number): void;

  /**
   * 終了時間を指定してスケジュールを fix する
   * @param endTime
   */
  fixScheduleByEndTime(endTime: number): void;

  /**
   * startTime, endTimeに合わせてrouteの中身を調整している
   * endTime = undefined の場合、
   * startTime に合わせる様に、endTime を調整し時間を再計算する。(endTime は中に含まれる回収の数によって変化する)
   * ただし固定されていて時間が指定されている場合には startTime も endTime も調整されない
   * @param startTime 固定されていて開始時間の調整が必要ない場合は undefined
   * @param endTime 固定されていて終了時間の調整が必要ない場合は undefined
   */
  updateDurations(startTime: Maybe<number>, endTime: Maybe<number>): void;

  /**
   * 拡張情報をリセットする
   */
  resetProperties(): void;

  /**
   * 収集の開始・終了時間を調整する
   */
  updateCollectionStartAndEndTime(): void;

  /**
   * 回収の順番固定有無をセットする
   * 合わせて、このルートに紐づく回収が全て順番固定されていたら、このルート自体を順番固定ありに、
   * どれか一つでも順番固定されていなかったら、このルート自体を順番固定なしにセットする
   *
   * @param index 回収INDEX
   * @param value 回収の順番固定有無
   */
  setIsFixedAssignmentOfCollection(index: number, value: boolean): void;

  /**
   * 回収を取得する
   * @param index
   */
  getCollection(index: number): Collection;

  /**
   * 処分を取得する
   * @param index
   */
  getDisposal(index: number): Disposal;

  /**
   * 回収をこのルートから削除する
   * 時間がズレる可能性があり、 `updateCollectionStartAndEndTime` は別途呼ぶ必要がある
   * @param index
   */
  removeCollection(index: number): Collection;

  /**
   * 回収をルートに追加する
   * @param index
   * @param collection
   */
  addCollection(index: number, collection: Collection): void;

  /**
   * collection の index を現状に合わせて振り替える
   */
  updateCollectionIndexes(): void;

  /**
   * 時間が不定になっているかどうかを更新する
   * @param isRouteTimetableUnstable
   */
  updateIsTimetableUnstable(isRouteTimetableUnstable: boolean): void;

  /**
   * ルートの状態IDを更新する
   *
   * コンポーネントの状態を強制的に再描画したい時に呼び出す
   */
  updateStateId(): void;

  /**
   * このオブジェクトの clone を返す
   */
  clone(): Clone;
}

/**
 * 新規作成されたルート用の設定
 */
export const defaultRouteSettings = {
  /**
   * 排出場から排出場への平均移動時間
   */
  averageGenerationSiteTravelTime: hhMmToSecs('01:00'),
  /**
   * ルートが開始されてから最初の排出場に到着するまでの時間
   */
  generationSiteArrivalTimeDiff: hhMmToSecs('01:00'),
} as const;

export class RouteEntity<
  Original extends IOriginalRouteEntity<any, any, any>,
  Collection extends ICollectionEntity<any, Collection>,
  Disposal extends IDisposalEntity<any, Disposal>,
  InconsistentRouteInfo extends IBaseInconsistentRouteInfo<InconsistentRouteInfo>
> implements
    IRouteEntity<
      Original,
      Collection,
      Disposal,
      InconsistentRouteInfo,
      RouteEntity<Original, Collection, Disposal, InconsistentRouteInfo>
    >
{
  id: string;
  index: number;
  carId: Maybe<PseudoId>;
  carIndex: Maybe<number>;
  driverId: PseudoId;
  isDriver: Maybe<boolean>;
  isHelper: Maybe<boolean>;
  _startTime: number;
  _endTime: number;

  garageSiteDepartureId: Maybe<PseudoId>;
  garageSiteDepartureTime: Maybe<number>;
  garageSiteArrivalId: Maybe<PseudoId>;
  garageSiteArrivalTime: Maybe<number>;
  hasRestBeforeGarageSiteArrival: Maybe<boolean>;
  baseSiteId: Maybe<PseudoId>;
  baseSiteArrivalTime: Maybe<number>;
  baseSiteDepartureTime: Maybe<number>;
  hasRestBeforeBaseSiteArrival: Maybe<boolean>;
  hasRestAfterGenerationSiteDeparture: Maybe<boolean>;
  hasRestAfterDisposalSiteDeparture: Maybe<boolean>;

  _isFixedAssignment: boolean;
  isFixedSchedule: boolean;

  hasChangeCar: Maybe<boolean>;

  collections: Collection[];
  disposals: Disposal[];

  inconsistentLoadingRouteInfos: InconsistentRouteInfo[];
  inconsistentTimeRouteInfos: InconsistentRouteInfo[];

  original: Maybe<Original>;
  stateId: string;
  _fixedStartTime: Maybe<number>;
  _fixedEndTime: Maybe<number>;
  _lastGenerationSiteDepartureTime: Maybe<number>;
  _lastGarageSiteArrivalTime: Maybe<number>;
  _lastDisposalSiteDepartureTime: Maybe<number>;
  _lastRestAfterGenerationSiteDepartureEndTime: Maybe<number>;
  _lastRestAfterDisposalSiteDepartureEndTime: Maybe<number>;
  nextRoute: Maybe<RouteEntity<Original, Collection, Disposal, InconsistentRouteInfo>>;
  previousRoute: Maybe<RouteEntity<Original, Collection, Disposal, InconsistentRouteInfo>>;

  isTimetableUnstable: boolean;
  isCollectionEdited: boolean;
  isFirstOfDriver: boolean;
  isLastOfDriver: boolean;
  isRouteCollection!: boolean;
  hasDisposals: boolean;

  get lastGenerationSiteDepartureTime(): Maybe<number> {
    return this._lastGenerationSiteDepartureTime;
  }

  set lastGenerationSiteDepartureTime(value: Maybe<number>) {
    this._lastGenerationSiteDepartureTime = value;
    const firstCollection = _.first(this.collections)!;
    firstCollection.lastGenerationSiteDepartureTime = value;
  }

  get lastGarageSiteArrivalTime() {
    return this._lastGarageSiteArrivalTime;
  }

  set lastGarageSiteArrivalTime(value: Maybe<number>) {
    this._lastGarageSiteArrivalTime = value;
    const firstCollection = _.first(this.collections)!;
    firstCollection.lastGarageSiteArrivalTime = value;
  }

  get lastDisposalSiteDepartureTime() {
    return this._lastDisposalSiteDepartureTime;
  }

  set lastDisposalSiteDepartureTime(value: Maybe<number>) {
    this._lastDisposalSiteDepartureTime = value;
    const firstCollection = _.first(this.collections)!;
    firstCollection.lastDisposalSiteDepartureTime = value;
  }

  get lastRestAfterGenerationSiteDepartureEndTime() {
    return this._lastRestAfterGenerationSiteDepartureEndTime;
  }

  set lastRestAfterGenerationSiteDepartureEndTime(value: Maybe<number>) {
    this._lastRestAfterGenerationSiteDepartureEndTime = value;
    const firstCollection = _.first(this.collections)!;
    firstCollection.lastRestAfterGenerationSiteDepartureEndTime = value;
  }

  get lastRestAfterDisposalSiteDepartureEndTime() {
    return this._lastRestAfterDisposalSiteDepartureEndTime;
  }

  set lastRestAfterDisposalSiteDepartureEndTime(value: Maybe<number>) {
    this._lastRestAfterDisposalSiteDepartureEndTime = value;
    const firstCollection = _.first(this.collections)!;
    firstCollection.lastRestAfterDisposalSiteDepartureEndTime = value;
  }

  get routeDuration(): number {
    return this.endTime - this.startTime;
  }

  get disposalSiteDepartureTime(): Maybe<number> {
    if (0 < this.disposals.length) {
      return _.last(this.disposals)!.disposalSiteDepartureTime;
    }
    return undefined;
  }

  get isFixedAssignment(): boolean {
    return this._isFixedAssignment;
  }

  set isFixedAssignment(value: boolean) {
    this._isFixedAssignment = value;
    if (value === false) this.resetFixedStartAndEndTime();
  }

  /**
   * もし固定の開始時間が設定されているならそれを返す
   * なければ自動計算された時間を返す
   */
  get startTime() {
    if (this.fixedStartTime !== undefined) return this.fixedStartTime;
    return this._startTime;
  }

  set startTime(value: number) {
    this._startTime = value;
  }

  /**
   * もし固定の終了時間が設定されているならそれを返す
   * なければ自動計算された時間を返す
   */
  get endTime() {
    if (this.fixedEndTime !== undefined) return this.fixedEndTime;
    return this._endTime;
  }

  set endTime(value: number) {
    this._endTime = value;
  }

  get fixedStartTime(): Maybe<number> {
    return this._fixedStartTime;
  }

  set fixedStartTime(value: Maybe<number>) {
    if (this._startTime === value) value = undefined;
    this._fixedStartTime = value;

    this.updateIsFixedSchedule();
  }

  get fixedEndTime(): Maybe<number> {
    return this._fixedEndTime;
  }

  set fixedEndTime(value: Maybe<number>) {
    if (this._endTime === value) value = undefined;
    this._fixedEndTime = value;

    // もし fixedEndTime を undefined にしたとして、前のルートが存在するのであれば
    // ルートが連続している前提だと開始時間を固定する意味はないのでこちらも undefined にしておく
    // ルートが連続していない場合にはおかしくなる可能性がある
    if (
      this._fixedEndTime === undefined &&
      this.previousRoute !== undefined &&
      this.previousRoute.driverId.equals(this.driverId)
    ) {
      this.fixedStartTime = undefined;
    }
    this.updateIsFixedSchedule();
  }

  get hasRestBeforeDisposalSiteArrival(): boolean {
    const rest = this.disposals.filter((disposal) => disposal.hasRestBeforeDisposalSiteArrival);
    return 0 < rest.length;
  }

  get isFixedAssignmentInAnyOfCollections(): boolean {
    return this.collections.some((collection) => collection.isFixedAssignment);
  }

  constructor(
    original: Maybe<Original>,
    id: string,
    index: number,
    carId: Maybe<PseudoId>,
    carIndex: Maybe<number>,
    driverId: PseudoId,
    isDriver: Maybe<boolean>,
    isHelper: Maybe<boolean>,
    startTime: number,
    endTime: number,
    garageSiteDepartureId: Maybe<PseudoId>,
    garageSiteDepartureTime: Maybe<number>,
    garageSiteArrivalId: Maybe<PseudoId>,
    garageSiteArrivalTime: Maybe<number>,
    hasRestBeforeGarageSiteArrival: Maybe<boolean>,
    hasChangeCar: Maybe<boolean>,
    baseSiteId: Maybe<PseudoId>,
    baseSiteArrivalTime: Maybe<number>,
    baseSiteDepartureTime: Maybe<number>,
    hasRestBeforeBaseSiteArrival: Maybe<boolean>,
    hasRestAfterGenerationSiteDeparture: Maybe<boolean>,
    hasRestAfterDisposalSiteDeparture: Maybe<boolean>,
    isFixedAssignment: boolean,
    isFixedSchedule: boolean,
    collections: Collection[],
    disposals: Disposal[],
    inconsistentLoadingRouteInfos: InconsistentRouteInfo[],
    inconsistentTimeRouteInfos: InconsistentRouteInfo[]
  ) {
    this.original = original;
    this.stateId = '';
    this.id = id;
    this.index = index;
    this.carId = carId;
    this.carIndex = carIndex;
    this.driverId = driverId;
    this.isDriver = isDriver;
    this.isHelper = isHelper;
    this._startTime = startTime;
    this._endTime = endTime;
    this.garageSiteDepartureId = garageSiteDepartureId;
    this.garageSiteDepartureTime = garageSiteDepartureTime;
    this.garageSiteArrivalId = garageSiteArrivalId;
    this.garageSiteArrivalTime = garageSiteArrivalTime;
    this.hasRestBeforeGarageSiteArrival = hasRestBeforeGarageSiteArrival;
    this.hasChangeCar = hasChangeCar;
    this.baseSiteId = baseSiteId;
    this.baseSiteArrivalTime = baseSiteArrivalTime;
    this.baseSiteDepartureTime = baseSiteDepartureTime;
    this.hasRestBeforeBaseSiteArrival = hasRestBeforeBaseSiteArrival;
    this.hasRestAfterGenerationSiteDeparture = hasRestAfterGenerationSiteDeparture;
    this.hasRestAfterDisposalSiteDeparture = hasRestAfterDisposalSiteDeparture;
    this._isFixedAssignment = isFixedAssignment;
    this.isFixedSchedule = isFixedSchedule;
    this.collections = collections;
    this.disposals = disposals;
    this.inconsistentLoadingRouteInfos = inconsistentLoadingRouteInfos;
    this.inconsistentTimeRouteInfos = inconsistentTimeRouteInfos;
    this.hasDisposals = 0 < this.disposals.length;
    this.isTimetableUnstable = false;
    this.isCollectionEdited = false;
    this.isFirstOfDriver = false;
    this.isLastOfDriver = false;
    this._fixedStartTime = isFixedSchedule ? startTime : undefined;
    this._fixedEndTime = isFixedSchedule ? endTime : undefined;
    this._lastGarageSiteArrivalTime = undefined;
    this._lastDisposalSiteDepartureTime = undefined;
    this._lastRestAfterGenerationSiteDepartureEndTime = undefined;
    this._lastRestAfterDisposalSiteDepartureEndTime = undefined;
    this.nextRoute = undefined;
    this.previousRoute = undefined;
    this.updateIsRouteCollection();
    this.updateCollectionProperties();
    this.updateStateId();
  }

  fixScheduleByStartTime(startTime: number): void {
    if (this.isFixedAssignment === false) {
      throw new Error('Should fix assignment first!');
    }
    const duration = this.routeDuration;
    this.fixedStartTime = startTime;

    // "もし終了時間が固定されていなければ" 終了時間は自動的に調整される
    if (this.fixedEndTime === undefined) {
      this.endTime = startTime + duration;
    }
  }

  fixScheduleByEndTime(endTime: number): void {
    if (this.isFixedAssignment === false) {
      throw new Error('Should fix assignment first!');
    }
    this.fixedEndTime = endTime;
  }

  updateDurations(startTime: Maybe<number>, endTime: Maybe<number>): void {
    if (this.original) this.updateDurationsWithOriginal(startTime, endTime);
    else this.updateDurationsWithoutOriginal(startTime, endTime);
    this.updateCollectionProperties();
    this.updateStateId();
  }

  updateIsTimetableUnstable(isRouteTimetableUnstable: boolean): void {
    // このルート自体の情報を更新するとともに、回収の情報も更新する
    this.isTimetableUnstable = isRouteTimetableUnstable;
    const collectionComparison = new CollectionComparison(this.original?.collections || [], this.collections);
    let isTimetableUnstable = isRouteTimetableUnstable;
    for (const collection of this.collections) {
      if (collectionComparison.diffs.get(collection.id)) isTimetableUnstable = true;
      collection.isTimetableUnstable = isTimetableUnstable;
    }
    this.isCollectionEdited = collectionComparison.hasDiff;
  }

  clone(): RouteEntity<Original, Collection, Disposal, InconsistentRouteInfo> {
    const collections = this.collections.map((collection) => collection.clone());
    const disposals = this.disposals.map((disposal) => disposal.clone());

    const inconsistentLoadingRouteInfos = this.inconsistentLoadingRouteInfos.map((inconsistentLoadingRouteInfo) =>
      inconsistentLoadingRouteInfo.clone()
    );

    const inconsistentTimeRouteInfos = this.inconsistentTimeRouteInfos.map((inconsistentTimeRouteInfo) =>
      inconsistentTimeRouteInfo.clone()
    );

    const clone = new RouteEntity(
      this.original,
      this.id,
      this.index,
      this.carId,
      this.carIndex,
      this.driverId,
      this.isDriver,
      this.isHelper,
      this._startTime,
      this._endTime,
      this.garageSiteDepartureId,
      this.garageSiteDepartureTime,
      this.garageSiteArrivalId,
      this.garageSiteArrivalTime,
      this.hasRestBeforeGarageSiteArrival,
      this.hasChangeCar,
      this.baseSiteId,
      this.baseSiteArrivalTime,
      this.baseSiteDepartureTime,
      this.hasRestBeforeBaseSiteArrival,
      this.hasRestAfterGenerationSiteDeparture,
      this.hasRestAfterDisposalSiteDeparture,
      this.isFixedAssignment,
      this.isFixedSchedule,
      collections,
      disposals,
      // 以下のinconsistentLoadingRouteInfos、inconsistentTimeRouteInfosの配列が、
      // 変更される可能性は無いが、念の為参照を切っている。
      inconsistentLoadingRouteInfos,
      inconsistentTimeRouteInfos
    );

    clone.nextRoute = this.nextRoute;
    clone.previousRoute = this.previousRoute;
    clone._fixedStartTime = this._fixedStartTime;
    clone._fixedEndTime = this._fixedEndTime;
    clone._lastGenerationSiteDepartureTime = this._lastGenerationSiteDepartureTime;
    clone._lastGarageSiteArrivalTime = this._lastGarageSiteArrivalTime;
    clone._lastDisposalSiteDepartureTime = this._lastDisposalSiteDepartureTime;
    clone._lastRestAfterGenerationSiteDepartureEndTime = this._lastRestAfterGenerationSiteDepartureEndTime;
    clone._lastRestAfterDisposalSiteDepartureEndTime = this._lastRestAfterDisposalSiteDepartureEndTime;
    clone.isFirstOfDriver = this.isFirstOfDriver;
    clone.isLastOfDriver = this.isLastOfDriver;
    clone.isTimetableUnstable = this.isTimetableUnstable;
    clone.isCollectionEdited = this.isCollectionEdited;

    return clone;
  }

  /**
   * オリジナルのルートが存在するルートを更新する
   * オリジナルのルートが存在するという事は処分場などの情報が詰まっている可能性があり、
   * そこ含めて調整する必要があるため複雑
   * @private
   */
  private updateDurationsWithOriginal(startTime: Maybe<number>, endTime: Maybe<number>): void {
    if (this.original === undefined) throw new IllegalStateException(`original is undefined`);

    const originalCollectionDuration = this.original.collectionDuration;
    const estimatedCollectionDuration = this.getEstimatedOriginalCollectionDuration();
    const estimatedTravelTimes = this.getEstimatedTravelTimes();
    const collectionDurationDiff = integerize(estimatedCollectionDuration - originalCollectionDuration);

    if (startTime !== undefined) {
      const originalRouteDuration = this.original.routeDuration;
      this.startTime = startTime;
      this.endTime = startTime + originalRouteDuration + collectionDurationDiff;
    }

    if (endTime !== undefined) {
      this.endTime = endTime;
    }

    // ここでの比というのは回収の幅の変動は考慮せず、回収以外のものが広がったりしたかどうかというもの
    // 例えば回収が追加されて全体の時間が伸びたからと言って終了時間が調整されたいた訳ではないので
    // あれば durationRatio 自体は 1 になる
    const durationRatio = this.routeDuration / (this.original.routeDuration + collectionDurationDiff);

    if (this.original.garageSiteDepartureTime !== undefined) {
      this.garageSiteDepartureTime = integerize(
        this.startTime + (this.original.garageSiteDepartureTime - this.original.startTime) * durationRatio
      );
    }
    if (this.original.baseSiteArrivalTime !== undefined) {
      this.baseSiteArrivalTime = integerize(
        this.startTime + (this.original.baseSiteArrivalTime - this.original.startTime) * durationRatio
      );
    }
    if (this.original.baseSiteDepartureTime !== undefined) {
      this.baseSiteDepartureTime = integerize(
        this.startTime + (this.original.baseSiteDepartureTime - this.original.startTime) * durationRatio
      );
    }
    if (this.original.garageSiteArrivalTime !== undefined) {
      const relativeGarageSiteArrivalTime =
        this.original.garageSiteArrivalTime + collectionDurationDiff - this.original.startTime;
      this.garageSiteArrivalTime = integerize(this.startTime + relativeGarageSiteArrivalTime * durationRatio);
    }

    this.resetCollectionProperties();

    // 排出場更新
    let time = this.startTime + this.getOriginalGenerationSiteArrivalTimeDiff() * durationRatio;
    for (const [index, collection] of this.collections.entries()) {
      const generationSiteDepartureTime = time + (collection.original?.generationSiteDuration ?? 0) * durationRatio;
      collection.generationSiteArrivalTime = integerize(time);
      collection.generationSiteDepartureTime = integerize(generationSiteDepartureTime);
      const travelTime = index < estimatedTravelTimes.length ? estimatedTravelTimes[index] : 0;
      time = generationSiteDepartureTime + travelTime * durationRatio;
    }

    // 処分場更新
    for (const disposal of this.disposals) {
      const relativeArrivalTime =
        disposal.original.disposalSiteArrivalTime + collectionDurationDiff - this.original.startTime;
      const relativeDepartureTime =
        disposal.original.disposalSiteDepartureTime + collectionDurationDiff - this.original.startTime;
      disposal.disposalSiteArrivalTime = integerize(this.startTime + relativeArrivalTime * durationRatio);
      disposal.disposalSiteDepartureTime = integerize(this.startTime + relativeDepartureTime * durationRatio);
    }
  }

  /**
   * オリジナルのルートが存在しないルートを更新する
   * オリジナルのルートが存在しないという事はフロントエンド側で生成したルートであり、
   * 処分場などは詰まっておらず回収にかかる時間しか存在しない
   * @param startTime
   * @private
   */
  private updateDurationsWithoutOriginal(startTime: Maybe<number>, endTime: Maybe<number>): void {
    if (this.original !== undefined) throw new IllegalStateException(`original is not undefined`);

    // デフォルトの準備時間 + 排出場にいる時間の合計をルート全体でかかる時間という事にしておく
    const originalRouteDuration = integerize(
      defaultRouteSettings.generationSiteArrivalTimeDiff + this.getEstimatedOriginalCollectionDuration()
    );

    if (startTime !== undefined) {
      this.startTime = startTime;
      this.endTime = startTime + originalRouteDuration;
    }

    if (endTime !== undefined) {
      this.endTime = endTime;
    }

    // 新規作成したルートは直下のプロパティはそもそも存在しないので調整の必要がない
    // 処分場についても同様で排出場のみ調整の必要がある
    this.resetCollectionProperties();
    const durationRatio = this.routeDuration / originalRouteDuration;
    let time = this.startTime + defaultRouteSettings.generationSiteArrivalTimeDiff * durationRatio;
    for (const collection of this.collections) {
      const generationSiteDepartureTime = time + (collection.original?.generationSiteDuration ?? 0) * durationRatio;
      collection.generationSiteArrivalTime = integerize(time);
      collection.generationSiteDepartureTime = integerize(generationSiteDepartureTime);
      time = generationSiteDepartureTime + defaultRouteSettings.averageGenerationSiteTravelTime * durationRatio;
    }
  }

  updateCollectionIndexes() {
    let index = 0;
    for (const collection of this.collections) {
      collection.index = index++;
    }
  }

  resetProperties(): void {
    this.lastGenerationSiteDepartureTime = undefined;
    this.lastGarageSiteArrivalTime = undefined;
    this.lastDisposalSiteDepartureTime = undefined;
    this.lastRestAfterGenerationSiteDepartureEndTime = undefined;
    this.lastRestAfterDisposalSiteDepartureEndTime = undefined;
    this.isCollectionEdited = false;
    this.isTimetableUnstable = false;
    this.isFirstOfDriver = false;
    this.isLastOfDriver = false;
  }

  updateCollectionStartAndEndTime(): void {
    const firstCollection = _.first(this.collections)!;
    firstCollection.adjustStartTime(this.startTime);

    for (const collection of this.collections) {
      collection.updateStartAndEndTime();
    }
  }

  unfix() {
    this.setIsFixedAssignmentOfAllCollections(false);
  }

  fix() {
    this.setIsFixedAssignmentOfAllCollections(true);
  }

  resetFixedStartAndEndTime() {
    this.fixedStartTime = undefined;
    this.fixedEndTime = undefined;
  }

  setIsFixedAssignmentOfCollection(index: number, value: boolean) {
    const [from, to] = (() => {
      if (value && 0 < index) {
        return [0, index];
      } else if (!value && index + 1 < this.collections.length) {
        return [index, this.collections.length - 1];
      } else {
        return [index, index];
      }
    })();
    this.setIsFixedAssignmentOfCollections(from, to, value);
  }

  getCollection(index: number): Collection {
    if (index < 0) throw new IllegalArgumentException(`index < 0`);
    if (this.collections.length <= index) throw new IllegalArgumentException(`length <= index`);
    return this.collections[index];
  }

  getDisposal(index: number): Disposal {
    if (index < 0) throw new IllegalArgumentException(`index < 0`);
    if (this.disposals.length <= index) throw new IllegalArgumentException(`length <= index`);
    return this.disposals[index];
  }

  removeCollection(index: number): Collection {
    if (index < 0) throw new IllegalArgumentException(`index < 0`);
    if (this.collections.length <= index) throw new IllegalArgumentException(`length <= index`);
    const [removed] = this.collections.splice(index, 1);
    if (removed === undefined) throw new IllegalStateException(`Could not get removed collection`);
    this.updateCollectionIndexes();
    this.updateIsRouteCollection();
    return removed;
  }

  addCollection(index: number, collection: Collection) {
    if (index < 0) throw new IllegalArgumentException(`index < 0`);
    if (this.collections.length < index) throw new IllegalArgumentException(`${this.collections.length} < ${index}`);
    this.collections.splice(index, 0, collection);
    this.updateIsRouteCollection();

    // 回収を追加すると実際に何の車になるのかは不定になるため、undefined にしておく
    this.setIsCarUnstable(true);
  }

  protected setIsCarUnstable(isUnstable: boolean): void {
    if (isUnstable) {
      this.carId = undefined;
      this.carIndex = undefined;
    } else {
      this.carId = this.original?.carId;
      this.carIndex = this.original?.carIndex;
    }
    for (const collection of this.collections) {
      collection.setIsCarUnstable(isUnstable);
    }
  }

  private updateIsRouteCollection(): void {
    this.isRouteCollection = 1 < this.collections.length;
  }

  /**
   * 今このルートに入っている回収が当初の必要作業時間で合算されたらどれほどの所要時間になるか。
   * 移動時間はこのルートの当初の平均移動時間か、新規ルートの場合には 0 として仮定される。
   * 新規ルートの場合に移動時間を想定するのは無理だから。
   * @private
   */
  private getEstimatedOriginalCollectionDuration(): number {
    const estimatedTravelTimes = this.getEstimatedTravelTimes();
    return this.collections.reduce((value, collection, index) => {
      const travelTime = index < this.collections.length - 1 ? estimatedTravelTimes[index] : 0;
      return value + (collection.original?.generationSiteDuration ?? 0) + travelTime;
    }, 0);
  }

  /**
   * 現在の回収に要するであろう回収と回収の間の移動の時間を取得する
   *
   * 現在の回収がオリジナルと同じ順番で配置されているなら移動時間も同じになると仮定する。もしオリジナルとは違う順番に
   * なっていたら平均移動時間が適用され、新規ルートの場合にはデフォルトの平均移動時間（仮に1時間）として仮定される。
   * なお、オリジナルの回収が一つしか存在しない場合移動時間が存在しないので、その場合はデフォルトの平均移動時間が
   * 適用される。
   *
   * @private
   */
  private getEstimatedTravelTimes(): number[] {
    if (this.original !== undefined) {
      const travelTimes = [];
      const averageTravelTime =
        this.original.averageGenerationSiteTravelTime ?? defaultRouteSettings.averageGenerationSiteTravelTime;
      const nextPairs = pairNext(
        zip<ICollectionEntity<any, any>, IOriginalCollectionEntity>(this.collections, this.original.collections)
      );
      let sameCollections = true;
      for (const [formerPair, latterPair] of nextPairs) {
        const isFormerPairSame =
          formerPair[0] !== undefined && formerPair[1] !== undefined && formerPair[0].id === formerPair[1].id;
        const isLatterPairSame =
          latterPair !== endOfItems &&
          latterPair[0] !== undefined &&
          latterPair[1] !== undefined &&
          latterPair[0].id === latterPair[1].id;
        sameCollections &&= isFormerPairSame;
        // @ts-ignore
        if (sameCollections && isLatterPairSame && latterPair !== endOfItems) {
          // latterPair が endOfItems でない事は確定しているのだが、TypeScript の型推論がおばかちゃんで認識
          // してくれないので致し方なく if の最後に入れている
          const travelTime = latterPair[1]!.generationSiteArrivalTime - formerPair[1]!.generationSiteDepartureTime;
          travelTimes.push(travelTime);
        } else if (latterPair !== endOfItems) {
          travelTimes.push(averageTravelTime);
        }
      }
      return travelTimes;
    } else {
      const averageTravelTime = defaultRouteSettings.averageGenerationSiteTravelTime;
      return pairNext(this.collections)
        .filter(([_former, latter]) => latter !== endOfItems)
        .map(() => averageTravelTime);
    }
  }

  /**
   * 全ての収集の route が設定する拡張情報をリセットする
   * 本来 collection が持っている情報はリセットされない
   */
  private resetCollectionProperties(): void {
    for (const collection of this.collections) {
      collection.resetProperties();
    }
  }

  /**
   * 収集の拡張情報を設定する
   * route しか知り得ない情報を外部から注入する
   */
  private updateCollectionProperties(): void {
    // その他の収集については排出場到着前に休憩があればアリ
    // 最初と最後の収集については下でさらに上書きされる
    for (const [index, collection] of this.collections.entries()) {
      collection.index = index;
      // ルートの開始・終了時間はルート内のどの回収も持っているものとする
      collection.routeStartTime = this.startTime;
      collection.routeEndTime = this.endTime;
      collection.hasRest = collection.hasRestBeforeGenerationSiteArrival;
    }

    // collection が空な事はあり得ない
    const firstCollection = _.first(this.collections)!;
    const lastCollection = _.last(this.collections)!;

    // ルートの最初と最後のマーク
    firstCollection.isFirstOfRoute = true;
    lastCollection.isLastOfRoute = true;

    // 最初の収集には乗り換えとコンテナ積み込みの時間がつく
    firstCollection.garageSiteDepartureTime = this.garageSiteDepartureTime;
    firstCollection.baseSiteArrivalTime = this.baseSiteArrivalTime;
    firstCollection.baseSiteDepartureTime = this.baseSiteDepartureTime;
    firstCollection.isStartGarageSiteAndBaseSiteSame =
      this.garageSiteDepartureId !== undefined &&
      this.baseSiteId !== undefined &&
      this.garageSiteDepartureId.equals(this.baseSiteId);

    // 最初の収集には拠点の ID と車庫出発 ID がつく（あれば）
    firstCollection.baseSiteId = this.baseSiteId;
    firstCollection.garageSiteDepartureId = this.garageSiteDepartureId;

    // 最初の収集には拠点到着前の休憩時間がつく（あれば）
    firstCollection.hasRest = firstCollection.hasRestBeforeGenerationSiteArrival;
    firstCollection.hasRest =
      firstCollection.hasRest || (this.hasRestBeforeBaseSiteArrival !== undefined && this.hasRestBeforeBaseSiteArrival);

    // 最後の収集には処分場と車庫に行く時間がつく（あれば）
    if (0 < this.disposals.length) {
      lastCollection.disposalSiteArrivalTime = _.first(this.disposals)!.disposalSiteArrivalTime;
      lastCollection.disposalSiteDepartureTime = _.last(this.disposals)!.disposalSiteDepartureTime;
    }
    lastCollection.garageSiteArrivalTime = this.garageSiteArrivalTime;

    // 最後の収集には排出場、処分場出発後の休憩がつく（あれば）
    lastCollection.hasRestAfterGenerationSiteDeparture = this.hasRestAfterGenerationSiteDeparture;
    lastCollection.hasRestAfterDisposalSiteDeparture = this.hasRestAfterDisposalSiteDeparture;

    // 最後の収集にはあれば以下の休憩がつく
    // - 車庫到着前の休憩時間
    // - 処分場前の休憩時間
    // - 排出場出発後の休憩時間
    // - 処分場出発後の休憩時間
    lastCollection.hasRest = lastCollection.hasRest || lastCollection.hasRestBeforeGenerationSiteArrival;
    lastCollection.hasRest =
      lastCollection.hasRest ||
      (this.hasRestBeforeGarageSiteArrival !== undefined && this.hasRestBeforeGarageSiteArrival) ||
      (this.hasRestAfterGenerationSiteDeparture !== undefined && this.hasRestAfterGenerationSiteDeparture) ||
      (this.hasRestAfterDisposalSiteDeparture !== undefined && this.hasRestAfterDisposalSiteDeparture) ||
      this.hasRestBeforeDisposalSiteArrival;

    // 最後の排出場の出発時間を次の収集に設定する
    // 開始・終了時刻を更新する
    let lastGenerationSiteDepartureTime: Maybe<number>;
    for (const collection of this.collections) {
      collection.lastGenerationSiteDepartureTime = lastGenerationSiteDepartureTime;
      lastGenerationSiteDepartureTime = collection.generationSiteDepartureTime;
    }
  }

  updateStateId(): void {
    // ドライバー ID 含めておけば重複はしないはず
    this.stateId = `${this.driverId.toString()}-${uuidv4()}`;
  }

  /**
   * 編集前の最初の排出場到着時間とルートの開始時間の差分を取得する
   * 新しいルートの開始時間に足すと、最初の収集が開始されるべき時間になる
   */
  private getOriginalGenerationSiteArrivalTimeDiff(): number {
    if (this.original === undefined) return defaultRouteSettings.generationSiteArrivalTimeDiff;
    return _.first(this.original.collections)!.generationSiteArrivalTime - this.original.startTime;
  }

  private updateIsFixedSchedule(): void {
    // 開始時間、終了時間、どちらかに固定の値が入力されていたらそれは固定されていると判断
    this.isFixedSchedule = this.fixedStartTime !== undefined || this.fixedEndTime !== undefined;
  }

  private setIsFixedAssignmentOfAllCollections(value: boolean) {
    this.setIsFixedAssignmentOfCollections(0, this.collections.length - 1, value);
  }

  /**
   * このルートに含まれる回収のロックを設定する
   *
   * このルートに含まれる回収の全てがロックされていたらルート自体をロックするというロジックが含まれるため、
   * 外部から何も考えずに Collection#isFixedAssignment を呼ぶのは禁止。
   *
   * @private
   */
  private setIsFixedAssignmentOfCollections(from: number, to: number, value: boolean): void {
    for (let index = from; index <= to; index++) {
      this.getCollection(index).isFixedAssignment = value;
    }
    this.isFixedAssignment = this.collections.every((collection) => collection.isFixedAssignment);
  }
}
