
import Vue, { PropType } from 'vue';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import {
  IOrderRoutingGroupItem,
  IRoutableOrder,
  getRoutableOrderDisplayName,
} from '~/components/panels/schedule/r-order-form/routingGroup';
import { IRoutableOrdersCondition } from '~/framework/server-api/schedule/order/routableOrders';
import { required } from '~/framework/view-models/rules';
import { routeCollectionOptions, IRouteCollectionOption } from '~/framework/view-models/routeCollectionOption';
import { OrderApplicationService, orderSymbol } from '~/framework/application/schedule/order/orderApplicationService';

type DataType = {
  initialized: boolean;
  routableOrders: IRoutableOrder[];
  routeCollectionAllowedValue: boolean;
  isSelectingRoutingGroup: boolean;
  rules: { [key: string]: ValidationRule };
  routeCollectionOptions: IRouteCollectionOption[];
  orderApplicationService: OrderApplicationService;
  isResetRoutingGroupConfirmDialogActive: boolean;
  confirmDeleteRoutingGroup: () => void;
};

enum EventTypes {
  UpdateRouteCollectionAllowed = 'update:route-collection-allowed',
  UpdateRoutingGroup = 'update:routing-group',
}

export default Vue.extend({
  name: 'RRouteCollectionInput',
  props: {
    routeCollectionAllowed: {
      type: Boolean,
      required: true,
    },
    routeCollectionDisabled: {
      type: Boolean,
      required: true,
    },
    routingGroupDisabled: {
      type: Boolean,
      required: true,
    },
    routingGroup: {
      type: Object as PropType<IOrderRoutingGroupItem>,
      required: false,
      default: undefined,
    },
    routingGroupDisabledReason: {
      type: String,
      required: false,
      default: '',
    },
    orderId: {
      type: String,
      required: false,
      default: undefined,
    },
    orderGroupId: {
      type: String,
      required: false,
      default: undefined,
    },
    date: {
      type: Date as PropType<Date>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      initialized: false,
      routableOrders: [],
      routeCollectionAllowedValue: this.routeCollectionAllowed,
      isSelectingRoutingGroup: this.routingGroup?.orderIds.length > 0,
      rules: { required },
      routeCollectionOptions,
      orderApplicationService: this.$context.applications.get(orderSymbol),
      isResetRoutingGroupConfirmDialogActive: false,
      confirmDeleteRoutingGroup: () => {},
    };
  },
  computed: {
    // NOTE: ルート化選択可能な受注一覧
    routableOrderItems(): (orderId: Maybe<string>) => IRoutableOrder[] {
      return (orderId) => {
        if (this.routingGroup === undefined) return [];

        // NOTE: 未入力のフォームは除外する
        const selectedOrderIds = this.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[];

        // NOTE: 選択中の受注がなければ filter しない
        if (selectedOrderIds.length === 0) {
          return this.routableOrders;
        }

        // NOTE: 選択中の受注がなければ orderIds と同じ受注を除外する
        // ただし、選択中のフォームで指定している id を除外すると正しく表示されないので、フォームごとに残す受注を変える
        return this.routableOrders.filter((routableOrder) => {
          return routableOrder.id === orderId || !selectedOrderIds.includes(routableOrder.id);
        });
      };
    },
    isSelectingRoutingGroupDisabled(): boolean {
      return (
        this.orderGroupId === undefined || this.date === undefined || this.routingGroupDisabled || !this.initialized
      );
    },
  },
  watch: {
    routeCollectionAllowed(value) {
      this.routeCollectionAllowedValue = value;

      if (!value) {
        this.isSelectingRoutingGroup = false;
        this.$emit(EventTypes.UpdateRoutingGroup, undefined);
      }
    },
    isSelectingRoutingGroup(value: boolean): void {
      if (value) {
        this.loadRoutableOrders();
      }
    },
    routingGroup(value: Maybe<IOrderRoutingGroupItem>): void {
      if (value === undefined) {
        this.isSelectingRoutingGroup = false;
      }
    },
  },
  async mounted() {
    await this.loadRoutableOrders();
    this.initialized = true;
  },
  methods: {
    getRoutableOrderDisplayName,
    abortDeleteRoutingGroup(): void {
      if (!this.routeCollectionAllowedValue) this.routeCollectionAllowedValue = true;
      if (!this.isSelectingRoutingGroup) this.isSelectingRoutingGroup = true;
    },
    async onChangeRouteCollectionAllowed(value: boolean): Promise<void> {
      const selectedOrders =
        this.routingGroup !== undefined
          ? (this.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[])
          : [];
      if (!value && selectedOrders.length > 0) {
        await new Promise<void>((resolve) => {
          this.isResetRoutingGroupConfirmDialogActive = true;
          this.confirmDeleteRoutingGroup = () => resolve();
        });
      }

      this.routeCollectionAllowedValue = value;

      if (!value) {
        this.isSelectingRoutingGroup = false;
        this.$emit(EventTypes.UpdateRoutingGroup, undefined);
      }
      this.$emit(EventTypes.UpdateRouteCollectionAllowed, value);
    },
    async onClickIsSelectingRoutingGroup(value: boolean): Promise<void> {
      if (this.isSelectingRoutingGroupDisabled) {
        return;
      }

      const selectedOrders =
        this.routingGroup !== undefined
          ? (this.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[])
          : [];
      if (value && selectedOrders.length > 0) {
        await new Promise<void>((resolve) => {
          this.isResetRoutingGroupConfirmDialogActive = true;
          this.confirmDeleteRoutingGroup = () => resolve();
        });
      }

      this.updateIsSelectingRoutingGroup(!value);
    },
    updateIsSelectingRoutingGroup(value: boolean): void {
      this.isSelectingRoutingGroup = value;
      if (value) {
        const routingGroup = {
          ...this.routingGroup,
          orderIds: [undefined],
        };
        this.$emit(EventTypes.UpdateRoutingGroup, routingGroup);

        return;
      }
      this.$emit(EventTypes.UpdateRoutingGroup, undefined);
    },
    onChangeSelectedOrderIds(value: string, index: number): void {
      const routingGroup = this.routingGroup;
      routingGroup.orderIds[index] = value;
      this.$emit(EventTypes.UpdateRoutingGroup, routingGroup);
    },
    onRemoveOrderRoutingGroupItem(orderIds: string[], targetIndex: number): void {
      this.$delete(orderIds, targetIndex);
    },
    onClickAddRoutingGroupItemButton(): void {
      if (this.routingGroup === undefined) return;

      const routingGroup = this.routingGroup;
      routingGroup.orderIds.push(undefined);

      this.$emit(EventTypes.UpdateRoutingGroup, routingGroup);
    },
    async loadRoutableOrders(): Promise<void> {
      if (this.orderGroupId === undefined || this.date === undefined) {
        // NOTE: orderGroupId と date が undefined のときは routableOrders で検索できないので return
        return;
      }

      const condition: IRoutableOrdersCondition = {
        orderId: this.orderId,
        orderGroupId: this.orderGroupId,
        date: this.date,
      };

      const result = await this.orderApplicationService.getRoutableOrders(condition);
      this.routableOrders = result.map((order) => {
        return {
          id: order.id,
          name: getRoutableOrderDisplayName(order),
        };
      });
    },
  },
});
