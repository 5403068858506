
import Vue, { PropType } from 'vue';
import { Id, PersistentId } from '~/framework/typeAliases';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { getOrInit } from '~/framework/core/map';
import { mapEntity } from '~/framework/core/mapper';
import { IllegalStateException } from '~/framework/core/exception';
import { IPackingStyleFormValues } from '~/components/panels/packing-placement/r-packing-placements-form/packingStyleFormValues';
import {
  IPackingPlacementFormValues,
  PackingPlacementFormValuesFactory,
} from '~/components/panels/packing-placement/r-packing-placements-form/packingPlacementFormValues';
import { AllowNumberTypes } from '~/components/common/r-v-number-field/sanitizeNumericalText';

type DataType = {
  /**
   * 登録されている荷姿がすべて表示されているかどうか
   */
  allContainerTypesAreShown: boolean;
  /**
   * 荷姿種別が一つも表示されていない場合に true
   */
  isNoPackingStyleShown: boolean;
  /**
   * "荷姿種別 ID -> 既に選択されている荷姿の ID" の Set を表す Map
   * 既に選択されている荷姿は重複して選択させたくないので必要
   */
  selectedContainerTypeSetMap: Map<PersistentId, Set<PersistentId>>;
  /**
   * 荷姿が存在していてかつまだ荷姿を追加する余地のある荷姿種別
   * 荷姿種別は 1〜9 まであるが、実際にコンテナが登録されていないものは表示する意味もなく、
   * 既に全ての荷姿を追加している荷姿種別に関しても選択肢として表示する意味がないので弾く必要がある
   */
  availablePackingStyles: PackingStyleEntity[];
  /**
   * r-v-number-field の allow-number-types 用の enum
   */
  AllowNumberTypes: typeof AllowNumberTypes;
};

/**
 * 残存数を入力するやつ
 */
export default Vue.extend({
  name: 'RPackingPlacementsInput',
  props: {
    packingStyles: {
      type: Array as PropType<PackingStyleEntity[]>,
      required: true,
    },
    values: {
      type: Array as PropType<IPackingStyleFormValues[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      allContainerTypesAreShown: false,
      isNoPackingStyleShown: false,
      selectedContainerTypeSetMap: new Map<PersistentId, Set<PersistentId>>(),
      availablePackingStyles: [],
      AllowNumberTypes,
    };
  },
  computed: {
    //
  },
  watch: {
    values: {
      handler() {
        const packingStyleMap = mapEntity(this.packingStyles);
        const availablePackingStyles: PackingStyleEntity[] = [];
        const selectedContainerTypeSetMap: Map<PersistentId, Set<PersistentId>> = new Map();
        for (const packingStyle of this.values) {
          const selectedContainerTypeSet = getOrInit(selectedContainerTypeSetMap, packingStyle.id, new Set());
          selectedContainerTypeSet.addValues(
            ...packingStyle.packingPlacements.map((packingStyle) => packingStyle.containerTypeId)
          );
          if (
            1 <= packingStyle.containerTypes.length &&
            packingStyle.packingPlacements.length < packingStyle.containerTypes.length
          ) {
            availablePackingStyles.push(packingStyleMap.getOrError(packingStyle.id));
          }
        }
        this.availablePackingStyles = availablePackingStyles;
        this.selectedContainerTypeSetMap = selectedContainerTypeSetMap;
        this.allContainerTypesAreShown = this.values.every(
          (packingStyle) => packingStyle.containerTypes.length === packingStyle.packingPlacements.length
        );
        this.isNoPackingStyleShown = this.values.every((packingStyle) => packingStyle.packingPlacements.length === 0);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onClickRemoveButton(packingPlacement: IPackingPlacementFormValues): void {
      this.$set(packingPlacement, 'num', 0);
    },
    addAvailableLoadableContainerTypes(): void {
      for (const packingStyle of this.values) {
        for (const containerType of packingStyle.containerTypes) {
          if (this.selectedContainerTypeSetMap.getOrError(packingStyle.id).has(containerType.id)) continue;
          if (packingStyle.packingPlacements.length === packingStyle.containerTypes.length) break;
          this.$set(
            packingStyle.packingPlacements,
            packingStyle.packingPlacements.length,
            PackingPlacementFormValuesFactory.buildByContainerTypeId(containerType.persistentId)
          );
        }
      }
    },
    onSelectContainerType(packingStyleId: Id, containerTypeId: Id): void {
      const packingStyle = this.getPackingStyleOf(packingStyleId);
      const item = PackingPlacementFormValuesFactory.buildByContainerTypeId(containerTypeId);
      this.$set(packingStyle.packingPlacements, packingStyle.packingPlacements.length, item);
    },
    getPackingStyleOf(id: PersistentId): IPackingStyleFormValues {
      for (const packingStyle of this.values) {
        if (packingStyle.id === id) return packingStyle;
      }
      throw new IllegalStateException(`not found packing style`);
    },
  },
});
