import { AggregatedOrderStore } from '~/framework/domain/schedule/order/aggregatedOrderStore';
import { OrderAcceptanceCheckStore } from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckStore';
import { ScheduleStore } from '~/framework/domain/schedule/schedule/scheduleStore';

export class ScheduleNamespace {
  readonly schedule: ScheduleStore;
  readonly orderAcceptanceCheck: OrderAcceptanceCheckStore;
  readonly aggregatedOrder: AggregatedOrderStore;

  constructor() {
    this.schedule = new ScheduleStore();
    this.orderAcceptanceCheck = new OrderAcceptanceCheckStore();
    this.aggregatedOrder = new AggregatedOrderStore();
  }
}
