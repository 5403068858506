
import Vue, { PropType } from 'vue';
import { DayOfWeek } from '~/framework/typeAliases';

type DayOfWeekItem = {
  value: DayOfWeek;
  inputValue: boolean;
  readonly: boolean;
  icon: string;
};

type DataType = {};

enum EventTypes {
  UpdateDaysOfWeek = 'update:days-of-week',
  ClickReadonlyDayOfWeek = 'click:readonly-day-of-week',
}

export default Vue.extend({
  name: 'RDaysOfWeekSelector',
  props: {
    daysOfWeek: {
      type: Array as PropType<DayOfWeek[]>,
      required: true,
    },
    readonlyDaysOfWeek: {
      type: Array as PropType<DayOfWeek[]>,
      required: false,
      default() {
        return [];
      },
    },
    /**
     * 選択中の曜日が区別がつかなくなるので `disabled` を `v-checkbox` に渡さない。
     *
     * 繰り返し設定の編集時には曜日の選択を編集する事ができないが、
     * 既存の選択されている曜日を表示する必要がある。
     * 曜日選択の `v-checkbox` を disabled にすると、
     * 既存の選択されている曜日もすべて灰色になってしまいもユーザーに見えなくなる。
     * 回避策として disabled の時に readonly にし、既存の選択されている曜日を見える状態にしておく。
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {};
  },
  computed: {
    dayOfWeekItems(): DayOfWeekItem[] {
      const daysOfWeekSet = new Set(this.daysOfWeek);
      const readonlyDaysOfWeekSet = new Set(this.readonlyDaysOfWeek);
      const dayOfWeekItems: DayOfWeekItem[] = [
        { value: 1, inputValue: daysOfWeekSet.has(1), readonly: readonlyDaysOfWeekSet.has(1), icon: 'ffi-monday' },
        { value: 2, inputValue: daysOfWeekSet.has(2), readonly: readonlyDaysOfWeekSet.has(2), icon: 'ffi-tuesday' },
        { value: 3, inputValue: daysOfWeekSet.has(3), readonly: readonlyDaysOfWeekSet.has(3), icon: 'ffi-wednesday' },
        { value: 4, inputValue: daysOfWeekSet.has(4), readonly: readonlyDaysOfWeekSet.has(4), icon: 'ffi-thursday' },
        { value: 5, inputValue: daysOfWeekSet.has(5), readonly: readonlyDaysOfWeekSet.has(5), icon: 'ffi-friday' },
        { value: 6, inputValue: daysOfWeekSet.has(6), readonly: readonlyDaysOfWeekSet.has(6), icon: 'ffi-saturday' },
        { value: 0, inputValue: daysOfWeekSet.has(0), readonly: readonlyDaysOfWeekSet.has(0), icon: 'ffi-sunday' },
      ];

      return dayOfWeekItems;
    },
  },
  methods: {
    onChangeDayOfWeek(value: DayOfWeek, inputValue: boolean): void {
      const daysOfWeekSet = new Set(this.daysOfWeek);
      if (inputValue) daysOfWeekSet.add(value);
      else daysOfWeekSet.delete(value);

      this.$emit(EventTypes.UpdateDaysOfWeek, daysOfWeekSet.toArray().sort());
    },
    onClickDayOfWeek(value: DayOfWeek): void {
      const readonlyDaysOfWeekSet = new Set(this.readonlyDaysOfWeek);
      if (readonlyDaysOfWeekSet.has(value) === false) return;
      this.$emit(EventTypes.ClickReadonlyDayOfWeek, value);
    },
  },
});
