import { IRetryStrategy } from '~/framework/core/retry/retryStrategy';
import { MilliSeconds } from '~/framework/typeAliases';
import { Exception } from '~/framework/core/exception';

export class ExceedsMaxRetryException extends Exception {
  readonly name = 'ExceedsMaxRetryException';
  constructor(retried: number) {
    super(`Already retried ${retried} times!`);
  }
}

/**
 * Exponential back-off の concrete strategy
 * 内部にリトライカウントを持っており再利用するとおかしくなるので注意
 */
export class ExponentialBackoffStrategy implements IRetryStrategy {
  private readonly retryIntervalBase: MilliSeconds;
  private readonly maxRetry: number;
  private retried: number;

  /**
   * @param retryIntervalBase 最低どれだけの間隔を空けてリトライするか
   * @param maxRetry 最大何回リトライしていいか
   */
  constructor(retryIntervalBase: MilliSeconds = 1000, maxRetry: number = 5) {
    this.retryIntervalBase = retryIntervalBase;
    this.maxRetry = maxRetry;
    this.retried = 0;
  }

  getRetryInterval(retried: number): MilliSeconds {
    return this.retryIntervalBase * 2 ** retried;
  }

  onRetry(): void {
    if (this.maxRetry < this.retried + 1) {
      throw new ExceedsMaxRetryException(this.maxRetry);
    }
    this.retried++;
  }
}
