import { erpOrder$createErpOrderSymbol } from '~/framework/server-api/schedule/order/createErpOrder';
import { IErpOrderData } from '~/framework/server-api/schedule/order/erpOrder';
import { erpOrder$erpOrderByOrderIdSymbol } from '~/framework/server-api/schedule/order/erpOrderByOrder';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Id, Maybe } from '~/framework/typeAliases';
import { ICreateData, IUpdateData } from '~/framework/port.adapter/server-api/schedule/erpOrder';
import { erpClient$getAllTransportationClientsSymbol, IErpClientData } from '~/framework/server-api/masters/erpClient';
import { erpOrder$updateErpOrderSymbol } from '~/framework/server-api/schedule/order/updateErpOrder';
import { erpOrder$deleteErpOrderByOrderIdSymbol } from '~/framework/server-api/schedule/order/deleteErpOrderByOrderId';
import { retry } from '~/framework/core/retry/retry';
import { ExponentialBackoffStrategy } from '~/framework/core/retry/exponentialBackoffStrategy';
import { GraphQLNetworkException } from '~/framework/port.adapter/server-api/graphqlApiBase';

export const erpOrderApplicationServiceSymbol = Symbol('erpOrderApplicationServiceSymbol');

export class ErpOrderApplicationService {
  private readonly serverApis: ServerApiManager;

  constructor(serverApis: ServerApiManager) {
    this.serverApis = serverApis;
  }

  async getAllTransportationClients(): Promise<IErpClientData[]> {
    const api = this.serverApis.get(erpClient$getAllTransportationClientsSymbol);
    return await api.getAllTransportationClients();
  }

  async getErpOrderByOrderId(orderId: Id): Promise<Maybe<IErpOrderData>> {
    const api = this.serverApis.get(erpOrder$erpOrderByOrderIdSymbol);
    return await api.getErpOrderByOrderId(orderId);
  }

  async createErpOrder(createData: ICreateData): Promise<IErpOrderData> {
    const api = this.serverApis.get(erpOrder$createErpOrderSymbol);

    // NOTE: 配車頭の受注は登録され、Erp の受注登録に失敗したらリトライ処理を入れる。
    return await retry(
      async () => await api.createErpOrder(createData),
      this.isProcessingErpOrderException,
      new ExponentialBackoffStrategy(500, 3)
    );
  }

  async updateErpOrder(updateData: IUpdateData): Promise<IErpOrderData> {
    const api = this.serverApis.get(erpOrder$updateErpOrderSymbol);

    // NOTE: 配車頭の受注は更新され、Erp の受注の更新に失敗したらリトライ処理を入れる。
    return await retry(
      async () => await api.updateErpOrder(updateData),
      this.isProcessingErpOrderException,
      new ExponentialBackoffStrategy(500, 3)
    );
  }

  async deleteErpOrderByOrderId(orderId: Id, includeFollowingRecurringOrders: boolean = false): Promise<Id> {
    const api = this.serverApis.get(erpOrder$deleteErpOrderByOrderIdSymbol);

    // NOTE: 配車頭の受注は更新され、Erp の受注の削除に失敗したらリトライ処理を入れる。
    return await retry(
      async () => await api.deleteErpOrderByOrderId(orderId, includeFollowingRecurringOrders),
      this.isProcessingErpOrderException,
      new ExponentialBackoffStrategy(500, 3)
    );
  }

  /**
   * ネットワーク起因のエラーの場合はリトライ処理。それ以外はリトライしないで Sentry 通知を拾って手動で対応。
   * @param error
   * @returns
   */
  private isProcessingErpOrderException(error: Error): boolean {
    return error instanceof GraphQLNetworkException;
  }
}
