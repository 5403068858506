import { IHolidayRuleData } from '~/framework/server-api/masters/holidayRule';
import { DayOfWeek } from '~/framework/typeAliases';

export type HolidayRuleFields = Pick<
  HolidayRuleEntity,
  'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | 'nationalHoliday'
>;

export class HolidayRuleEntity implements HolidayRuleEntity {
  readonly id: string;
  readonly persistentId: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  nationalHoliday: boolean;

  constructor(data: IHolidayRuleData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.monday = data.monday;
    this.tuesday = data.tuesday;
    this.wednesday = data.wednesday;
    this.thursday = data.thursday;
    this.friday = data.friday;
    this.saturday = data.saturday;
    this.sunday = data.sunday;
    this.nationalHoliday = data.nationalHoliday;
  }

  update(data: IHolidayRuleData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of HolidayRuleEntity');
    }
    this.monday = data.monday;
    this.tuesday = data.tuesday;
    this.wednesday = data.wednesday;
    this.thursday = data.thursday;
    this.friday = data.friday;
    this.saturday = data.saturday;
    this.sunday = data.sunday;
    this.nationalHoliday = data.nationalHoliday;
  }

  isDayHoliday(day: DayOfWeek): boolean {
    if (day === 0) return this.sunday;
    if (day === 1) return this.monday;
    if (day === 2) return this.tuesday;
    if (day === 3) return this.wednesday;
    if (day === 4) return this.thursday;
    if (day === 5) return this.friday;
    if (day === 6) return this.saturday;
    throw new Error(`Impossible day: ${day}`);
  }

  // 本当は文字はリソースに切り出した方がいい
  getDayLabelBy(fieldName: keyof HolidayRuleEntity): string {
    if (fieldName === 'monday') return '月';
    if (fieldName === 'tuesday') return '火';
    if (fieldName === 'wednesday') return '水';
    if (fieldName === 'thursday') return '木';
    if (fieldName === 'friday') return '金';
    if (fieldName === 'saturday') return '土';
    if (fieldName === 'sunday') return '日';
    if (fieldName === 'nationalHoliday') return '祝日';
    throw new Error(`Impossible fieldName: ${fieldName}`);
  }
}
