import { isEmpty } from './ruleLogics';
import { Maybe, ValidationRule } from '~/framework/typeAliases';

// Vuetify の rule は同じものを使いまわしそうなので、そういうものはここに定義する

/**
 * フィールドの値が入力されているかを検証する
 * @param value
 */
export const required: ValidationRule = (value: any) => {
  return !isEmpty(value) || '必須項目です';
};

/**
 * フィールドの値が重複していないかを検証する
 * @param value
 * 重複チェックから除外したい値がある場合は self にその値を渡す
 * @param self
 */
export const uniqued = (items: any[], self: Maybe<any>): ValidationRule => {
  return (value: any) => {
    if (value === self) return true;
    if (items.includes(value)) return `${value} はすでに使用されています`;

    return true;
  };
};

/**
 * フィールドの値が float の値として入力されているかどうかを検証する
 * @param value
 */
export const float: ValidationRule = (value: any) => {
  // NOTE parseFloat で NaN が返らないこと、としてしまうと一部だけパース可能な時に
  // 不自然な値になってしまうため正規表現でチェックしている。
  // また、いったん + は入れられない様にしている。
  if (/^-?\d+(\.\d+)?$/.test(value) === false) return '数値で入力してください';
  const parsedValue = Number.parseFloat(value);
  return !isNaN(parsedValue) || '数値で入力してください';
};

/**
 * 0より大きい数字になっているかどうかを検証する
 * @param value
 */
export const greaterThanZero: ValidationRule = (value: any) => {
  const parsedValue = Number(value);
  return 0 < parsedValue || '0より大きい数を入力してください';
};

/**
 * 0以上の数になっているかどうかを検証する
 * @param value
 */
export const greaterThanOrEqualToZero: ValidationRule = (value: any) => {
  const parsedValue = Number(value);
  return 0 <= parsedValue || '0以上の数を入力してください';
};

/**
 * 指定した値を最大値とするルールを作成する高階関数
 */
export const max = (maxValue: number): ValidationRule => {
  return (value: any) => {
    const parsedValue = Number(value);
    return parsedValue <= maxValue || `${maxValue}以下の数を入力してください`;
  };
};

/**
 * 指定した値を最小値とするルールを作成する高階関数
 */
export const min = (minValue: number): ValidationRule => {
  return (value: any) => {
    const parsedValue = Number(value);
    return minValue <= parsedValue || `${minValue}以上の数を入力してください`;
  };
};

/**
 * 指定した文字数以内とするルールを作成する高階関数
 * @param maxLength
 */
export const maxLength = (maxLength: number, errorMessage?: Maybe<string>): ValidationRule => {
  return (value: string) => {
    if (errorMessage === undefined) errorMessage = `${maxLength}文字以内で入力して下さい`;
    // lengthの取得できない未入力を許容する
    if (!value) return true;
    return value.length <= maxLength || errorMessage;
  };
};

/**
 * 指定した文字数固定とするルールを作成する高階関数
 * @param fixLength
 */
export const fixLength = (fixLength: number, errorMessage?: Maybe<string>): ValidationRule => {
  return (value: Maybe<string>) => {
    if (errorMessage === undefined) errorMessage = `${fixLength}文字で入力して下さい`;
    return value?.length === fixLength || errorMessage;
  };
};

/**
 * 指定した配列の要素数未満とするルールを作成する高階関数
 * @param maxLength
 */
export const arrayLengthLessThanNum = (maxLength: number, errorMessage?: Maybe<string>): ValidationRule => {
  return (values: Maybe<any[]>) => {
    if (errorMessage === undefined) errorMessage = `${maxLength} 件未満で入力して下さい`;
    return (values && values.length < maxLength) || errorMessage;
  };
};

/**
 * 指定した配列の要素数以内とするルールを作成する高階関数
 * @param maxLength
 */
export const arrayLengthLessThanOrEqualToNum = (maxLength: number, errorMessage?: Maybe<string>): ValidationRule => {
  return (values: Maybe<any[]>) => {
    if (errorMessage === undefined) errorMessage = `${maxLength} 件以内で入力して下さい`;
    return (values && values.length <= maxLength) || errorMessage;
  };
};
