import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';

export interface IBusinessDaysService {
  /**
   * Method to get the next business day after the day passed as argument.
   * @param { Date } currentDate
   * @returns { Date } next business date
   */
  getNextBusinessDate(currentDate: Date): Date;
}

export class BusinessDaysService implements IBusinessDaysService {
  private readonly maxLoopCount: number;
  private readonly holidayRule: HolidayRuleEntity;
  private readonly nationalHolidayService: NationalHolidayService;

  constructor(holidayRule: HolidayRuleEntity, maxLoopCount?: number) {
    this.holidayRule = holidayRule;
    this.maxLoopCount = maxLoopCount ?? 30;
    this.nationalHolidayService = new NationalHolidayService();
  }

  getNextBusinessDate(currentDate: Date): Date {
    const testDate = new Date(currentDate);
    let isHoliday = true;
    let count = 0;

    while (isHoliday && count < this.maxLoopCount) {
      testDate.setDate(testDate.getDate() + 1);
      isHoliday = this.nationalHolidayService.isHoliday(testDate, this.holidayRule);
      count++;

      if (count >= this.maxLoopCount) throw new Error(`Next business day not found in next ${this.maxLoopCount} days.`);
    }

    return testDate;
  }
}
