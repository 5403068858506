import { OrderGroupColor } from '~/framework/domain/typeAliases';
import { IOrderGroupData } from '~/framework/server-api/masters/orderGroup';

export class OrderGroupEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  colorName: OrderGroupColor;

  constructor(data: IOrderGroupData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.colorName = data.colorName;
  }

  update(data: IOrderGroupData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of OrderGroupEntity');
    }
    this.name = data.name;
    this.colorName = data.colorName;
  }
}
