import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { Maybe } from '~/framework/typeAliases';
import { IClientData, IPackingStyleReservationSetting } from '~/framework/server-api/masters/client';

export class AggregatedClientEntity {
  readonly id: string;
  readonly persistentId: string;
  code: Maybe<string>;
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  createdAt: Date;
  status: SoftDeleteStatus;

  signInUrl: Maybe<string>;
  updatedAt: Date;
  reservationSiteEnabled: Maybe<boolean>;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
  createdBy: UserEntity;
  updatedBy: UserEntity;

  get isDeleted(): boolean {
    return this.status === SoftDeleteStatus.Deleted;
  }

  constructor(data: IClientData, createdBy: UserEntity, updatedBy: UserEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.code = data.code;
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.note = data.note;
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.signInUrl = data.signInUrl;
    this.updatedAt = data.updatedAt;
    this.reservationSiteEnabled = data.reservationSiteEnabled;
    this.packingStyleReservationSettings = data.packingStyleReservationSettings;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }

  update(data: IClientData, createdBy: UserEntity, updatedBy: UserEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedClientEntity');
    }
    this.code = data.code;
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.note = data.note;
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.signInUrl = data.signInUrl;
    this.updatedAt = data.updatedAt;
    this.reservationSiteEnabled = data.reservationSiteEnabled;
    this.packingStyleReservationSettings = data.packingStyleReservationSettings;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }
}
