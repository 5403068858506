import { AggregatedBaseSiteEntity } from '~/framework/domain/masters/base-site/aggregatedBaseSiteEntity';
import { SimpleCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { ICarData } from '~/framework/server-api/masters/car';

export class AggregatedCarEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  enabledOn: Date;
  carType: SimpleCarTypeEntity;
  baseSite: AggregatedBaseSiteEntity;

  get displayName(): string {
    return `${this.name} [${this.carType.name}]`;
  }

  constructor(data: ICarData, carType: SimpleCarTypeEntity, baseSite: AggregatedBaseSiteEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.enabledOn = data.enabledOn;
    this.carType = carType;
    this.baseSite = baseSite;
  }

  update(data: ICarData, carType: SimpleCarTypeEntity, baseSite: AggregatedBaseSiteEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedCarEntity');
    }
    this.name = data.name;
    this.enabledOn = data.enabledOn;
    this.carType = carType;
    this.baseSite = baseSite;
  }
}
