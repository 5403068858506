
import Vue, { PropType } from 'vue';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import RSearchablePulldown from '~/components/common/r-searchable-pulldown/RSearchablePulldown.vue';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';
import { OrderDisposalSitePriority } from '~/framework/domain/typeAliases';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';

type DataType = {
  selectedHighPriorityDisposalSiteIds: string[];
  selectedLowPriorityDisposalSiteIds: string[];
  // NOTE: 初期状態で何も入力されていない場合にいきなり alert を出すのは不自然なので、一度でも入力されたら alert が出るようにする。
  isFormInputEntered: boolean;
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Close = 'close',
  Update = 'update',
}

export default Vue.extend({
  name: 'RDisposalSitePriorityDialog',
  components: {
    RDialog,
    RSearchablePulldown,
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<AggregatedDisposalSiteEntity[]>,
      required: true,
    },
    preSelectedOrderAssignedDisposalSites: {
      type: Array as PropType<CreateOrderDisposalSiteInput[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      selectedHighPriorityDisposalSiteIds: [],
      selectedLowPriorityDisposalSiteIds: [],
      isFormInputEntered: false,
      listenerDisposer: undefined,
    };
  },
  computed: {
    isSelectedHighPriorityDisposalSiteIdsLimitExceeded(): boolean {
      return this.selectedHighPriorityDisposalSiteIds.length > 10;
    },
    isSelectedLowPriorityDisposalSiteIdsLimitExceeded(): boolean {
      return this.selectedLowPriorityDisposalSiteIds.length > 10;
    },
    isInputInvalid(): boolean {
      return (
        (this.selectedHighPriorityDisposalSiteIds.length === 0 &&
          this.selectedLowPriorityDisposalSiteIds.length === 0) ||
        this.isSelectedHighPriorityDisposalSiteIdsLimitExceeded ||
        this.isSelectedLowPriorityDisposalSiteIdsLimitExceeded
      );
    },
    // NOTE: 優先度低で選択されていない処分場のみ選べるようにしている
    selectableHighPriorityDisposalSites(): AggregatedDisposalSiteEntity[] {
      return this.disposalSiteEntities.filter((entity) => !this.selectedLowPriorityDisposalSiteIds.includes(entity.id));
    },
    // NOTE: 優先度高で選択されていない処分場のみ選べるようにしている
    selectableLowPriorityDisposalSites(): AggregatedDisposalSiteEntity[] {
      return this.disposalSiteEntities.filter(
        (entity) => !this.selectedHighPriorityDisposalSiteIds.includes(entity.id)
      );
    },
  },
  mounted() {
    // NOTE: priority: undefined の要素は全て high として扱う
    const highPriorityDisposalSiteIds = this.preSelectedOrderAssignedDisposalSites
      .filter(
        (orderAssignedDisposalSite) =>
          orderAssignedDisposalSite.priority === OrderDisposalSitePriority.High ||
          orderAssignedDisposalSite.priority === undefined
      )
      .map((orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId);

    const lowPriorityDisposalSiteIds = this.preSelectedOrderAssignedDisposalSites
      .filter((orderAssignedDisposalSite) => orderAssignedDisposalSite.priority === OrderDisposalSitePriority.Low)
      .map((orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId);

    if (highPriorityDisposalSiteIds.length !== 0)
      this.selectedHighPriorityDisposalSiteIds = highPriorityDisposalSiteIds;
    if (lowPriorityDisposalSiteIds.length !== 0) this.selectedLowPriorityDisposalSiteIds = lowPriorityDisposalSiteIds;

    // Stop propagation of all keyboard key press events to parent components.
    this.createKeyboardEventListener();
  },
  methods: {
    /**
     * Close dialog when `Esc` is pressed.
     * Stop propagation of all keyboard key press events to parents.
     */
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickClose() {
      this.disposeKeyboardEventListener();
      this.$emit(EventTypes.Close);
    },
    onClickComplete() {
      this.$emit(EventTypes.Update, this.selectedHighPriorityDisposalSiteIds, this.selectedLowPriorityDisposalSiteIds);

      this.onClickClose();
    },
    createKeyboardEventListener() {
      const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
        this.onKeydown,
        KeyboardEventPriority.Dialog
      );
      this.listenerDisposer = () => {
        keyboardEventListenerDisposer.dispose();
      };
    },
    disposeKeyboardEventListener() {
      if (this.listenerDisposer !== undefined) this.listenerDisposer();
    },
  },
});
