
import Vue, { PropType } from 'vue';
import { AllowNumberTypes, sanitizeNumericalText } from '~/components/common/r-v-number-field/sanitizeNumericalText';

/**
 * This component handles numeric inputs.
 * Specifications:
 * - `v-model` bind in parent component is expected to be a number or undefined.
 * - `v-model` is updated on `change` event.
 * - Return value is either a number or undefined.
 * - When `Number.parseFloat` is `NaN`, `undefined` is returned.
 * - `allowNumberType` may be passed as props. The default behavior only allows digits.
 * - `allowNegative` may be passed as props. The default behavior disallow negative numbers.
 * - Non-props attributes are forwarded to `v-text-field`.
 */
export default Vue.extend({
  name: 'RVNumberField',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number as PropType<number | undefined>,
      required: false,
      default: undefined,
    },
    allowNumberType: {
      type: Number as PropType<AllowNumberTypes>,
      required: false,
      default: AllowNumberTypes.OnlyDigits,
    },
    allowNegative: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'number',
    },
  },
  computed: {
    model: {
      get(): string | undefined {
        return this.value?.toString();
      },
      set(value: string | undefined): void {
        let numericValue: number | undefined;
        if (value !== undefined) {
          numericValue = Number.parseFloat(value);
          if (Number.isNaN(numericValue)) numericValue = undefined;
        }
        this.$emit('change', numericValue);
      },
    },
    sanitizeFunction(): (value: string) => string {
      return sanitizeNumericalText(this.allowNumberType, this.allowNegative);
    },
  },
});
