
import Vue, { PropType } from 'vue';
import { ISnackbarButton } from '~/components/common/r-snackbar/snackbarButton';
import { CssClasses } from '~/framework/typeAliases';

type DataType = {
  isOpened: boolean;
};

enum EventTypes {
  Input = 'input',
}

/**
 * デフォルトのタイムアウト時間
 * timeout のプロパティが与えられなかったらこれが設定される
 */
const defaultTimeout = 3000;

export default Vue.extend({
  name: 'RSnackbar',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    color: {
      type: String as PropType<string>,
      required: false,
      default: 'success',
    },
    timeout: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    described: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    buttons: {
      type: Array as PropType<ISnackbarButton[]>,
      required: false,
      default: undefined,
    },
    static: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    displayButton: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  data(): DataType {
    return {
      isOpened: this.value,
    };
  },
  computed: {
    classes(): CssClasses {
      return {
        'r-snackbar': true,
        'r-snackbar--default': !this.described,
        'r-snackbar--described': this.described,
        'r-snackbar--static': this.static,
        'r-snackbar--outlined': this.outlined,
      };
    },
    timeoutValue() {
      return this.timeout === undefined ? defaultTimeout : this.timeout;
    },
    styles() {
      return {
        '--main': `${this.$vuetify.theme.currentTheme[this.color]}`,
        '--pale': `${this.$vuetify.theme.currentTheme[this.color + '_pale']}`,
      };
    },
  },
  watch: {
    value(value: boolean) {
      this.isOpened = value;
    },
    isOpened(value: boolean) {
      this.$emit(EventTypes.Input, value);
    },
  },
  methods: {
    async onClickButton(button: ISnackbarButton): Promise<void> {
      this.isOpened = false;
      if (button.location !== undefined) {
        await this.$router.push(button.location);
      } else {
        console.log('no action was given');
      }
    },
  },
});
