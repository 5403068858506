
import Vue from 'vue';
import { Context } from '@nuxt/types';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';
import { Maybe } from '~/framework/typeAliases';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import {
  OfficeSettingApplicationService,
  officeSettingSymbol,
} from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import { IllegalStateException } from '~/framework/core/exception';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  officeSettingApplicationService: OfficeSettingApplicationService;
} & AsyncDataType;

type AsyncDataType = {
  viewModel?: IOfficeSettingForm;
};

/**
 * 時間：分の入力フォームで使うデータ型
 */
type ShortTimeType = {
  hour: number;
  minutes: number;
};

interface IOfficeSettingForm {
  context: Context;

  /**
   * 登録された時に呼ばれるイベント
   */
  registeredEvent: ITypedEvent<OfficeSettingEntity>;

  /**
   * 更新する時に使うEntity ( OfficeSettingは、一つしかないので配列じゃなくても良い )
   */
  officeSettingEntity: OfficeSettingEntity;

  /**
   * 更新されたEntity
   */
  updatedOfficeSettingEntity: Maybe<OfficeSettingEntity>;

  /**
   * 何かしら入力されていたら true
   */
  isDirty: boolean;

  /**
   * 登録中かどうか
   */
  isRegistering: boolean;

  // 拠点での入退場時間(フォーム入力に対応したデータ型)
  defaultDurationAtBaseSite: ShortTimeType;

  // 排出場での入退場時間(フォーム入力に対応したデータ型)
  defaultDurationAtGenerationSite: ShortTimeType;

  // 処分場での入退場時間(フォーム入力に対応したデータ型)
  defaultDurationAtDisposalSite: ShortTimeType;

  convertToSeconds(shortTime: ShortTimeType): number;
}

class OfficeSettingForm implements IOfficeSettingForm {
  // OfficeSettingで更新するプロパティ名一覧
  private fieldValuesKeys = [
    'defaultDurationAtBaseSite',
    'defaultDurationAtDisposalSite',
    'defaultDurationAtGenerationSite',
  ] as const;

  registeredEvent: ITypedEvent<OfficeSettingEntity> = new TypedEvent();

  context: Context;

  officeSettingEntity: OfficeSettingEntity;
  updatedOfficeSettingEntity: Maybe<OfficeSettingEntity>;

  defaultDurationAtBaseSite: ShortTimeType;
  defaultDurationAtDisposalSite: ShortTimeType;
  defaultDurationAtGenerationSite: ShortTimeType;

  isRegistering: boolean;

  get isDirty() {
    // 変更されていたらtrueを返す
    return this.fieldValuesKeys.some((key) => {
      return this.officeSettingEntity[key] !== this.convertToSeconds(this[key]);
    });
  }

  constructor(context: Context, officeSettingEntity: OfficeSettingEntity) {
    this.context = context;
    this.officeSettingEntity = officeSettingEntity;
    this.updatedOfficeSettingEntity = undefined;
    this.defaultDurationAtBaseSite = this.convertToShortTime(officeSettingEntity.defaultDurationAtBaseSite);
    this.defaultDurationAtDisposalSite = this.convertToShortTime(officeSettingEntity.defaultDurationAtDisposalSite);
    this.defaultDurationAtGenerationSite = this.convertToShortTime(officeSettingEntity.defaultDurationAtGenerationSite);
    this.isRegistering = false;
  }

  /**
   * 秒数をShortTimeTypeに変換する
   */
  convertToShortTime(seconds: number): ShortTimeType {
    return {
      hour: Math.floor(seconds / 3600),
      minutes: Math.floor(seconds / 60) % 60,
    };
  }

  /**
   * ShortTimeType型を秒数に変換する
   */
  convertToSeconds(shortTime: ShortTimeType): number {
    // 絶対値化してNaNになったら、0にする
    shortTime.hour = Math.abs(shortTime.hour) || 0;
    shortTime.minutes = Math.abs(shortTime.minutes) || 0;

    return Math.min(shortTime.hour, 23) * 3600 + Math.min(shortTime.minutes, 59) * 60;
  }
}

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'ROfficeSettingForm',

  model: {
    event: 'change',
    prop: 'isActive',
  },

  data(): DataType {
    const officeSettingApplicationService = this.$context.applications.get(officeSettingSymbol);
    return {
      isActive: false,
      isConfirmDialogActive: false,
      viewModel: undefined,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      officeSettingApplicationService,
    };
  },
  computed: {
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.OFFICE_SETTING;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.officeSettingFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.officeSettingFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },

  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },

  methods: {
    /**
     * @public
     */
    async open(): Promise<void> {
      const officeSetting = await this.officeSettingApplicationService.get();
      this.viewModel = new OfficeSettingForm(this.$nuxt.context, officeSetting);
      this.viewModel.registeredEvent.on(this.onUpdated);
      await (this.$refs.ROfficeSettingForm as any).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },

    /**
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.ROfficeSettingForm as any).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<OfficeSettingEntity> = {
        entity: this.viewModel ? this.viewModel.officeSettingEntity : undefined,
        updatedEntity: this.viewModel ? this.viewModel.updatedOfficeSettingEntity : undefined,
        createdEntity: undefined,
        removedEntity: undefined,
      };
      this.$context.panels.officeSettingFormPanel.closeFormEvent.emit(closeFormArgs);
    },

    async onOpenForm(_args: IOpenEntityFormArgs<OfficeSettingEntity>): Promise<void> {
      await this.open();
    },

    async onCloseForm(): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },

    async onUpdateButtonClicked(): Promise<void> {
      if (this.viewModel === undefined) throw new IllegalStateException('viewModel is undefined');
      this.viewModel.isRegistering = true;
      const entity = await this.officeSettingApplicationService.update({
        id: this.viewModel.officeSettingEntity.persistentId,
        defaultDurationAtBaseSite: this.viewModel.convertToSeconds(this.viewModel.defaultDurationAtBaseSite),
        defaultDurationAtGenerationSite: this.viewModel.convertToSeconds(
          this.viewModel.defaultDurationAtGenerationSite
        ),
        defaultDurationAtDisposalSite: this.viewModel.convertToSeconds(this.viewModel.defaultDurationAtDisposalSite),
        defaultRestPeriodStartOfGenerationSite:
          this.viewModel.officeSettingEntity.defaultRestPeriodStartOfGenerationSite,
        defaultRestPeriodEndOfGenerationSite: this.viewModel.officeSettingEntity.defaultRestPeriodEndOfGenerationSite,
        defaultRestPeriodStartOfDisposalSite: this.viewModel.officeSettingEntity.defaultRestPeriodStartOfDisposalSite,
        defaultRestPeriodEndOfDisposalSite: this.viewModel.officeSettingEntity.defaultRestPeriodEndOfDisposalSite,
        defaultPreloadStatus: this.viewModel.officeSettingEntity.defaultPreloadStatus,
        defaultRouteCollectionAllowed: this.viewModel.officeSettingEntity.defaultRouteCollectionAllowed,
      });
      this.viewModel.isRegistering = false;
      this.viewModel.updatedOfficeSettingEntity = entity;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.OFFICE_SETTING,
        [AdditionalInfoKeys.MODE]: FormModeParams.EDIT,
      });

      this.viewModel.registeredEvent.emit(entity);
    },

    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },

    onUpdated(_officeSettingEntity: OfficeSettingEntity): void {
      this.$context.snackbar.success('入退場時間の編集完了');
      this.close();
    },

    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.officeSettingFormPanel.updateDisplayedWidth(value);
    },
  },
});
