import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { packingStyle$getAllSymbol } from '~/framework/server-api/masters/packingStyle';
import { PackingStyleMapper } from '~/framework/domain/masters/packing-style/packingStyleMapper';

export const packingStyleSymbol = Symbol('packingStyle');

export class PackingStyleApplicationService {
  private readonly serverApis: ServerApiManager;
  private packingStyleMapper: PackingStyleMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.packingStyleMapper = new PackingStyleMapper(store.masters.packingStyle);
  }

  async getAll(): Promise<PackingStyleEntity[]> {
    const packingStyle$getAllApi = this.serverApis.get(packingStyle$getAllSymbol);
    const packingStyleData = await packingStyle$getAllApi.getAll();
    const packingStyles = this.packingStyleMapper.map(packingStyleData);
    return packingStyles;
  }
}
