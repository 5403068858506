import { ContainerTypeTaskType as ContainerTypeTaskTypeApiTypes } from '~/framework/server-api/masters/containerTypeTaskType';

import { SoftDeleteStatus } from '~/framework/domain/typeAliases';

import { IPackingStyleData } from '~/framework/server-api/masters/packingStyle';
import { ISimpleCarTypeData } from './carType';

export const containerType$createSymbol = Symbol('containerType$createSymbol');

export const containerType$getAllSymbol = Symbol('containerType$getAllSymbol');

export const containerType$getByIdsSymbol = Symbol('containerType$getByIdsSymbol');

export const containerType$updateSymbol = Symbol('containerType$updateSymbol');

export type ContainerType = {
  [containerType$getAllSymbol]: IGetAllApi;
  [containerType$getByIdsSymbol]: IGetByIdsApi;
  [containerType$createSymbol]: ICreateApi;
  [containerType$updateSymbol]: IUpdateApi;
} & ContainerTypeTaskTypeApiTypes;

export interface ICreateApi {
  create(data: ICreateData): Promise<IContainerTypeData>;
}

export interface ICreateData {
  name: string;
  packingStyleId: string;
  unitName: string;
  transformingCarTypeId: string | undefined;
  status: SoftDeleteStatus;
  reservationSiteEnabled: boolean | undefined;
}

export interface IContainerTypeData {
  id: string;
  name: string;
  packingStyle: IPackingStyleData;
  unitName: string;
  transformingCarType: ISimpleCarTypeData | undefined;
  status: SoftDeleteStatus;
  reservationSiteEnabled: boolean;
}

export interface IGetAllApi {
  getAll(): Promise<IContainerTypeData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IContainerTypeData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateData): Promise<IContainerTypeData>;
}

export interface IUpdateData {
  id: string;
  name: string;
  packingStyleId: string;
  unitName: string;
  transformingCarTypeId: string | undefined;
  status: SoftDeleteStatus;
  reservationSiteEnabled: boolean | undefined;
}
