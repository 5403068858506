import { ContainerTypeTaskTypeStatus, TaskType } from '~/framework/domain/typeAliases';
import { IPackingStyleTaskTypeDefaultData } from '~/framework/server-api/masters/packingStyleTaskTypeDefault';

export class PackingStyleTaskTypeDefaultEntity {
  readonly id: string;
  packingStyleId: string;
  taskType: TaskType;
  duration: number;
  status: ContainerTypeTaskTypeStatus;
  isProportionalToCount: boolean;
  constructor(data: IPackingStyleTaskTypeDefaultData) {
    this.id = data.id;
    this.packingStyleId = data.packingStyleId;
    this.taskType = data.taskType;
    this.duration = data.duration;
    this.status = data.status;
    this.isProportionalToCount = data.isProportionalToCount;
  }

  update(data: IPackingStyleTaskTypeDefaultData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of PackingStyleTaskTypeDefaultEntity');
    }
    this.packingStyleId = data.packingStyleId;
    this.taskType = data.taskType;
    this.duration = data.duration;
    this.status = data.status;
    this.isProportionalToCount = data.isProportionalToCount;
  }
}
