
import Vue, { PropType } from 'vue';
import { CssStyles, Maybe } from '~/framework/typeAliases';

export default Vue.extend({
  name: 'RListSubHeader',
  props: {
    label: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    height: {
      type: String as PropType<string>,
      required: false,
      default: '32px',
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: '100%',
    },
    badgeContent: {
      type: [String, Number] as PropType<string | number | undefined>,
      required: false,
      default: undefined,
    },
    badgeColor: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: 'undefined',
    },
  },
  computed: {
    styles(): CssStyles {
      return {
        height: this.height,
        width: this.width,
      };
    },
    showBadge(): boolean {
      return this.badgeContent !== undefined || this.$slots.badge !== undefined;
    },
  },
});
