import { Middleware, Context } from '@nuxt/types';

/**
 * 対応ブラウザなどをチェックするためのもの
 * @param context
 */
const compatibilityMiddleware: Middleware = (_: Context) => {
  // if (context.app.$context.isCompatibleWith(context.$ua.browser()) === false) {
  //   logger.warn(`accessing with incompatible browser: ${context.$ua.browser()}`, context.$ua);
  //   throw new IncompatibleBrowserException(context.$ua.browser());
  // }
};

export default compatibilityMiddleware;
