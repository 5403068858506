import { StoredMapperBase } from '~/framework/core/mapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { AggregatedWasteTypeEntity } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { JwnetWasteMasterMapper } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterMapper';
import { IWasteTypeData } from '~/framework/server-api/masters/wasteType';

export class AggregatedWasteTypeMapper extends StoredMapperBase<IWasteTypeData, AggregatedWasteTypeEntity> {
  protected store: AggregatedWasteTypeStore;
  private jwnetWasteMasterMapper: JwnetWasteMasterMapper;
  private userMapper: UserMapper;

  constructor(store: AggregatedWasteTypeStore, jwnetWasteMasterStore: JwnetWasteMasterStore, userStore: UserStore) {
    super();
    this.store = store;
    this.jwnetWasteMasterMapper = new JwnetWasteMasterMapper(jwnetWasteMasterStore);
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IWasteTypeData): AggregatedWasteTypeEntity {
    const jwnetWasteMaster = data.jwnetWasteMaster
      ? this.jwnetWasteMasterMapper.mapSingle(data.jwnetWasteMaster)
      : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    return new AggregatedWasteTypeEntity(data, jwnetWasteMaster, createdBy, updatedBy);
  }

  protected updateWithData(data: IWasteTypeData, entity: AggregatedWasteTypeEntity) {
    const jwnetWasteMaster = data.jwnetWasteMaster
      ? this.jwnetWasteMasterMapper.mapSingle(data.jwnetWasteMaster)
      : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    entity.update(data, jwnetWasteMaster, createdBy, updatedBy);
  }
}
