import { DisposalSiteAttendancesByDateRange } from '@/../graphql/server-api/queries/disposal-site-attendance.gql';
import { UpdateDisposalSiteAttendance } from '@/../graphql/server-api/mutations/disposal-site-attendance.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  UpdateDisposalSiteAttendanceInput,
  UpdateDisposalSiteAttendanceMutation,
  UpdateDisposalSiteAttendanceMutationVariables,
  DisposalSiteAttendancesByDateRangeQuery,
  DisposalSiteAttendancesByDateRangeQueryVariables,
} from '~/graphql/graphQLServerApi';

export const disposalSiteAttendanceSymbol = Symbol('disposalSiteAttendance');

export type ApiTypes = {
  [disposalSiteAttendanceSymbol]: DisposalSiteAttendanceApi;
};

export class DisposalSiteAttendanceApi extends GraphqlApiBase {
  async getByDateRange(disposalSiteIds: string[], start: Date, end: Date) {
    const result = await this.query<
      DisposalSiteAttendancesByDateRangeQuery,
      DisposalSiteAttendancesByDateRangeQueryVariables
    >({
      query: DisposalSiteAttendancesByDateRange,
      variables: { condition: { disposalSiteIds, start, end } },
    });
    return result.disposalSiteAttendancesByDateRange;
  }

  async updateDisposalSiteAttendance(data: UpdateDisposalSiteAttendanceInput): Promise<string> {
    const result = await this.mutate<
      UpdateDisposalSiteAttendanceMutation,
      UpdateDisposalSiteAttendanceMutationVariables
    >({
      mutation: UpdateDisposalSiteAttendance,
      variables: { disposalSiteAttendance: data },
    });

    return result.updateDisposalSiteAttendance.id;
  }
}
