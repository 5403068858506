import { convertUndefinedToNull } from '~/framework/property';

export interface ILocalStorage {
  saveObjects(objects: Map<string, object>): void;

  loadObjects<T>(ids: string[]): T[];

  getKeyByObject(obj: { __typename?: string; id: string | number }): string;

  getKey(typeName: string, id: string): string;
}

export class LocalStorage implements ILocalStorage {
  localStorage: Storage;
  reviver: (key: string, value: any) => any;
  replacer: (key: string, value: any) => any;

  constructor() {
    if (!window.localStorage) {
      throw new Error('This environment is not compatible with localStorage!');
    }
    this.localStorage = window.localStorage;
    this.reviver = (key, value) => {
      if (key === 'scheduleDate') {
        return new Date(value);
      } else if (key === 'updatedAt') {
        return new Date(value);
      } else if (key === 'inconsistencies' || key === 'infeasibilities') {
        return new Set(value);
      }
      return value;
    };
    this.replacer = (_key, value) => {
      if (value instanceof Set) {
        return value.toArray();
      } else {
        return value;
      }
    };
  }

  /**
   * convertUndefinedToNull をしておかないと、undefined だったものはキーごと消えてしまう
   * そうすると読み込んだ時に undefined となってしまい、Apollo がエラーを吐いてしまう
   *
   * @param objects
   */
  saveObjects(objects: Map<string, object>): void {
    for (const [key, object] of objects.entries()) {
      this.localStorage.setItem(key, JSON.stringify(convertUndefinedToNull(object), this.replacer));
    }
  }

  loadObjects<T>(ids: string[]): T[] {
    const objects = [];
    for (const id of ids) {
      const json = this.localStorage.getItem(id);
      if (json) {
        const object = JSON.parse(json, this.reviver);
        objects.push(object);
      }
    }
    return objects;
  }

  getKeyByObject(obj: { __typename?: string; id: string | number }): string {
    if (obj.__typename) {
      return `${obj.__typename}:${obj.id}`;
    } else {
      throw new Error(`Could not retrieve cacheKey for object: ${obj}`);
    }
  }

  getKey(typeName: string, id: string): string {
    return `${typeName}:${id}`;
  }
}

export const DefaultLocalStorage = new LocalStorage();
