import { NuxtErrorStatusCodes } from '~/nuxt';

export class Exception extends Error {}

export class UnimplementedException extends Exception {
  readonly name = 'UnimplementedException';
}

export class DeprecatedException extends Exception {
  readonly name = 'DeprecatedException';
}

export class IllegalStateException extends Exception {
  readonly name = 'IllegalStateException';
}

export class IllegalArgumentException extends Exception {
  readonly name = 'IllegalArgumentException';
}

/**
 * 未対応のブラウザの例外
 */
export class IncompatibleBrowserException extends Exception {
  readonly name = 'IncompatibleBrowserException';
  readonly statusCode = NuxtErrorStatusCodes.IncompatibleBrowserException;
}

/**
 * メンテナンス中に発生する例外
 */
export class MaintenanceException extends Exception {
  readonly name = 'MaintenanceException';
  readonly statusCode = NuxtErrorStatusCodes.MaintenanceException;
  readonly html: string;

  constructor(html: string) {
    super();
    this.html = html;
  }
}
