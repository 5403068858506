import { LoginForm, ILoginFormData } from '~/framework/application/authentication/form';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { userSetting$getSymbol } from '~/framework/server-api/user-setting/get';
import { userSetting$updateSymbol } from '~/framework/server-api/user-setting/update';
import { UserSettingMapper } from '~/framework/domain/user-setting/userSettingMapper';
import { authentication$currentSessionSymbol } from '~/framework/server-api/authentication/session';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { PersistentId } from '~/framework/typeAliases';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { authentication$signInSymbol, SignInData, SignInStatus } from '~/framework/server-api/authentication/signIn';
import { authentication$signOutSymbol } from '~/framework/server-api/authentication/signOut';

export const authenticationSymbol = Symbol('authenticationSymbol');

export interface SessionData {
  officeId: PersistentId;
  officeName: string;
  user: UserEntity;
}

export class AuthenticationApplicationService implements LoginForm {
  [authenticationSymbol] = undefined;
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  // region LoginForm
  async getLoginFormData(): Promise<ILoginFormData> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);
    const data = await getApi.get();
    const entity = userSettingMapper.mapSingle(data);
    return {
      rememberMe: entity.loginRememberMe,
    };
  }

  async setRememberMe(rememberMe: boolean): Promise<void> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);
    const data = await getApi.get();
    const entity = userSettingMapper.mapSingle(data);
    await updateApi.update({
      id: entity.id,
      scheduleDate: entity.scheduleDate,
      scheduleStartOfWeek: entity.scheduleStartOfWeek,
      scheduleTimelineStart: entity.scheduleTimelineStart,
      scheduleTimelineEnd: entity.scheduleTimelineEnd,
      scheduleTab: entity.scheduleTab,
      orderGroupId: entity.orderGroupId,
      scheduleSolutionStatus: entity.scheduleSolutionStatus,
      loginRememberMe: rememberMe,
    });
    entity.loginRememberMe = rememberMe;
  }

  async signIn(data: SignInData): Promise<SignInStatus> {
    const api = this.serverApis.get(authentication$signInSymbol);
    const result = await api.signIn(data);
    return result;
  }

  async signOut(): Promise<void> {
    const api = this.serverApis.get(authentication$signOutSymbol);
    await api.signOut();
  }
  // endregion

  /**
   * ログインできていればオフィスなどの情報を返す
   */
  async getCurrentSession(): Promise<SessionData> {
    const authentication$currentSessionApi = this.serverApis.get(authentication$currentSessionSymbol);
    const userMapper: UserMapper = new UserMapper(this.store.masters.user);
    const result = await authentication$currentSessionApi.getCurrentSession();
    const user = userMapper.mapSingle(result.user);
    return {
      officeId: result.officeId,
      officeName: result.officeName,
      user,
    };
  }
}
