import { v4 as uuidv4 } from 'uuid';

export const DistinctTimeOptionId = 'pseudo-entity:distinct-time';
export const DesignatedTimePeriodOptionId = 'pseudo-entity:designated-time-period';

export interface ICollectablePeriodTemplateOption {
  id: string;
  persistentId: string;
  name: string;
}

export const DistinctTimeOption: ICollectablePeriodTemplateOption = {
  id: uuidv4(),
  persistentId: DistinctTimeOptionId,
  name: '時間厳守',
};

export const DesignatedTimePeriodOption: ICollectablePeriodTemplateOption = {
  id: uuidv4(),
  persistentId: DesignatedTimePeriodOptionId,
  name: '時間指定',
};
