import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import {
  ILoadableContainerTypeFormValues,
  LoadableContainerTypeFormData,
} from '~/components/panels/masters/r-car-type-form/loadableContainerTypeFormValues';

export interface IPackingStyleFormValues {
  /**
   * 荷姿コードの ID
   */
  readonly id: string;
  /**
   * 荷姿コード名
   */
  readonly name: string;
  /**
   * この荷姿コードに属する荷姿
   */
  readonly containerTypes: AggregatedContainerTypeEntity[];
  /**
   * 搭載できるコンテナ
   */
  loadableContainerTypes: ILoadableContainerTypeFormValues[];

  isEqualTo(another: IPackingStyleFormValues): boolean;
}

export class PackingStyleFormValues implements IPackingStyleFormValues {
  readonly id: string;
  readonly name: string;
  readonly containerTypes: AggregatedContainerTypeEntity[];
  loadableContainerTypes: ILoadableContainerTypeFormValues[];

  constructor(
    id: string,
    name: string,
    containerTypes: AggregatedContainerTypeEntity[],
    loadableContainerTypes: ILoadableContainerTypeFormValues[]
  ) {
    this.id = id;
    this.name = name;
    this.containerTypes = containerTypes;
    this.loadableContainerTypes = loadableContainerTypes;
  }

  static clone(original: IPackingStyleFormValues): PackingStyleFormValues {
    const loadableContainerTypes = original.loadableContainerTypes.map((loadableContainerType) =>
      LoadableContainerTypeFormData.clone(loadableContainerType)
    );
    return new PackingStyleFormValues(original.id, original.name, original.containerTypes, loadableContainerTypes);
  }

  isEqualTo(another: IPackingStyleFormValues): boolean {
    return (
      this.id === another.id &&
      this.loadableContainerTypes.length === another.loadableContainerTypes.length &&
      this.loadableContainerTypes.every((item, index) => item.isEqualTo(another.loadableContainerTypes[index]))
    );
  }
}
