
import Vue, { PropType } from 'vue';
import { InfeasibilitySolutionTypes } from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import { CssClasses, JsonObject, PersistentId } from '~/framework/typeAliases';
import errorMessages from '~/assets/settings/scheduleErrorMessages.json';
import { Infeasibility } from '~/pages/schedule/infeasibility';

enum EventTypes {
  ClickEditOrder = 'click:edit-order',
  ClickEditGenerationSite = 'click:edit-generation-site',
  ClickRinLink = 'click:rin-link',
  ClickEditAttendance = 'click:edit-attendance',
}

type DataType = {
  errorMessages: JsonObject;
  InfeasibilitySolutionTypes: typeof InfeasibilitySolutionTypes;
};

export default Vue.extend({
  name: 'RInfeasibility',
  props: {
    infeasibility: {
      type: Object as PropType<Infeasibility>,
      required: true,
    },
    deemedAsSolved: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isClientAndGenerationSiteDisplayed: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  data(): DataType {
    return {
      errorMessages,
      InfeasibilitySolutionTypes,
    };
  },
  computed: {
    classes(): CssClasses {
      const deemedAsSolved = this.deemedAsSolved;
      return {
        'r-infeasibility': true,
        'r-infeasibility--solved': deemedAsSolved,
      };
    },
  },
  methods: {
    onClickEditOrder(infeasibility: Infeasibility, orderId: PersistentId): void {
      this.$emit(EventTypes.ClickEditOrder, infeasibility, orderId);
    },
    onClickEditGenerationSite(infeasibility: Infeasibility): void {
      this.$emit(EventTypes.ClickEditGenerationSite, infeasibility);
    },
    onClickRinLink(infeasibility: Infeasibility, path: string): void {
      this.$emit(EventTypes.ClickRinLink, infeasibility, path);
    },
    onClickEditAttendance(infeasibility: Infeasibility): void {
      this.$emit(EventTypes.ClickEditAttendance, infeasibility);
    },
  },
});
