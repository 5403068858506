import { Maybe, PersistentId } from '~/framework/typeAliases';

export class CarFormValues {
  name: Maybe<string>;
  carTypeId: Maybe<PersistentId>;
  baseSiteId: Maybe<PersistentId>;
  enabledOn: Date;

  constructor(name: Maybe<string>, carTypeId: Maybe<PersistentId>, baseSiteId: Maybe<PersistentId>, enabledOn: Date) {
    this.name = name;
    this.carTypeId = carTypeId;
    this.baseSiteId = baseSiteId;
    this.enabledOn = enabledOn;
  }
}
