import {
  CreateOrderAcceptanceCheckMutation,
  CreateOrderAcceptanceCheckMutationVariables,
  OrderAcceptanceChecksByIdsQuery,
  OrderAcceptanceChecksByIdsQueryVariables,
} from '@/graphql/graphQLServerApi';
import { CreateOrderAcceptanceCheck } from '@/../graphql/server-api/mutations/order-acceptance-check.gql';
import { OrderAcceptanceChecksByIds } from '@/../graphql/server-api/queries/order-acceptance-check.gql';
import { Optional } from '~/framework/typeAliases';
import {
  IGetByIdsApi,
  orderAcceptanceCheck$getByIdsSymbol,
} from '~/framework/server-api/schedule/order/order-acceptance-check/getByIds';
import {
  ICreateApi,
  ICreateOrderAcceptanceCheckData,
  ICreateResult,
  orderAcceptanceCheck$createSymbol,
} from '~/framework/server-api/schedule/order/order-acceptance-check/create';
import { IOrderAcceptanceCheckData } from '~/framework/server-api/schedule/order/order-acceptance-check/orderAcceptanceCheck';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { UpdateOrderInput, CreateOrderAcceptanceCheckInput, OrderPlanInput } from '~/framework/server-api/typeAliases';

// Rinの通常時のAPIの型は自動生成されたQuery、Mutation(~/graphql/graphQLServerApi)を利用している
// orderPlanInputの型が@oneOfに対応していない為、OrderPlanInputの型を書き換えたものを自前で定義して
// Omitと交差型を利用して自動生成されたVariablesを上書きしている

type NormalizedCreateOrderInput = Omit<UpdateOrderInput, 'plan'> & {
  plan: OrderPlanInput;
};

type NormalizedCreateOrderAcceptanceCheckInput = Omit<CreateOrderAcceptanceCheckInput, 'order'> & {
  order: NormalizedCreateOrderInput;
};

type NormalizedCreateOrderAcceptanceCheckMutationVariables = Omit<
  CreateOrderAcceptanceCheckMutationVariables,
  'orderAcceptanceCheck'
> & {
  orderAcceptanceCheck: NormalizedCreateOrderAcceptanceCheckInput;
};

export class CreateApi extends GraphqlApiBase implements ICreateApi {
  [orderAcceptanceCheck$createSymbol] = undefined;
  async create(createData: ICreateOrderAcceptanceCheckData): Promise<ICreateResult> {
    const order: Optional<ICreateOrderAcceptanceCheckData, 'clientId'> = createData;
    const reservationId = order.reservationId;
    delete order.clientId;
    delete order.reservationId;
    const result = await this.mutate<
      CreateOrderAcceptanceCheckMutation,
      NormalizedCreateOrderAcceptanceCheckMutationVariables
    >({
      mutation: CreateOrderAcceptanceCheck,
      variables: { orderAcceptanceCheck: { order, reservationId } },
    });
    return result.createOrderAcceptanceCheck;
  }
}

export class GetByIdsApi extends GraphqlApiBase implements IGetByIdsApi {
  [orderAcceptanceCheck$getByIdsSymbol] = undefined;
  async getByIds(ids: string[]): Promise<IOrderAcceptanceCheckData[]> {
    const result = await this.query<OrderAcceptanceChecksByIdsQuery, OrderAcceptanceChecksByIdsQueryVariables>({
      query: OrderAcceptanceChecksByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.orderAcceptanceChecksByIds, `OrderAcceptanceChecksByIds`);
    return result.orderAcceptanceChecksByIds;
  }
}
