import type { ApolloClientMethods, ClientOptions } from 'vue-apollo/types/vue-apollo';
import { FetchResult } from 'apollo-link';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { ApolloError, ApolloQueryResult, MutationOptions, OperationVariables, QueryOptions } from 'apollo-client';
import _ from 'lodash';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { ServerParseError } from 'apollo-link-http-common';
import { Exception, IllegalStateException, MaintenanceException } from '~/framework/core/exception';
import { convertNullToUndefined, convertUndefinedToNull } from '~/framework/property';
import { InconsistentSessionException, UnauthenticatedException } from '~/framework/server-api/authentication/session';
import { UnauthorizedOperationException } from '~/framework/server-api/authorize';
import { ErrorContext } from '~/framework/systemContext';
import { Maybe } from '~/framework/typeAliases';
import { NuxtErrorStatusCodes } from '~/nuxt';
import { createLogger } from '~/framework/logger';
import { ResponseHandler } from '~/graphql/responseAwareLink';

// 定義としては string を入れると意味はあまりなくなるのだが、全てここで定義するのはさすがに
// おかしい一方で特殊な使い方をしていて名前がズレると動作しなくなるものは定義しておいた方が
// よいのでこうしている。
type QueryTypeNames = 'GenerationSiteTasksByIds' | 'IrregularTasksByIds' | string;

export enum ErrorCodes {
  /**
   * 認証に失敗している（ログインする必要がある）
   */
  Unauthenticated = 'UNAUTHENTICATED',
  InconsistentSession = 'INCONSISTENT_SESSION',
  UnauthorizedOperation = 'UNAUTHORIZED_OPERATION_ERROR',
}

/**
 * 例外が認証エラーによるものかどうかを判定する
 * @param error
 */
export const isUnauthenticatedError = (error: ApolloError) => {
  return error.graphQLErrors.some((error) => error.extensions?.code === ErrorCodes.Unauthenticated);
};

/**
 * 例外が権限不足で行えない操作によるエラーかどうかを判定する
 * @param error
 * @returns
 */
export const isUnauthorizedOperationError = (error: ApolloError) => {
  return error.graphQLErrors.some((error) => error.extensions?.code === ErrorCodes.UnauthorizedOperation);
};

/**
 * ログイン中のセッションのOffice idとリクエストのOffice idに不整合性があるかを判定する
 * @param error
 */
export const isInconsistentSessionError = (error: ApolloError) => {
  return error.graphQLErrors.some((error) => error.extensions?.code === ErrorCodes.InconsistentSession);
};

/**
 * サーバー側とクライアント側で、GraphQLのスキーマのバージョンに不整合製があるかを判定する
 * @param error
 */
export const isMaintenanceError = (error: ApolloError) => {
  return (error.networkError as ServerParseError)?.statusCode === 503;
};

export class GraphQLException extends Exception {
  readonly name = 'GraphQLException';
  errors: ReadonlyArray<GraphQLError>;
  context: Maybe<ErrorContext>;

  constructor(errors: ReadonlyArray<GraphQLError>, context?: ErrorContext) {
    super(errors.map((error) => error.message).join(`, `) + JSON.stringify(context?.queries || context?.mutations));
    this.errors = errors;
    this.context = context;
  }
}

/**
 * ネットワーク切れてるっぽい
 */
export class GraphQLNetworkException extends Exception {
  readonly name = 'GraphQLNetworkException';
  readonly statusCode = NuxtErrorStatusCodes.GraphQLNetworkException;
}

/**
 * API に問い合わせた時の引数の配列の数とサーバーから返ってきた結果の数が異なっている時
 * （要は取得できなかった ID がある様な場合）に返す例外
 */
export class GraphQLResultConsistencyException extends Exception {
  readonly name = 'GraphQLResultConsistencyException';
  readonly typename: Maybe<QueryTypeNames>;
  readonly input: number;
  readonly output: number;

  constructor(input: number, output: number, typename?: Maybe<QueryTypeNames>) {
    super(`スキーマ: ${typename}, 引数は ${input}, 結果の長さと不一致 ${output}!`);
    this.input = input;
    this.output = output;
    this.typename = typename;
  }
}

export abstract class GraphqlApiBase {
  protected readonly apollo: ApolloClientMethods;

  constructor(apollo: ApolloClientMethods) {
    this.apollo = apollo;
  }

  /**
   * 必ずこいつを通して通信する事
   * convertUndefinedToNull, convertNullToUndefined をしないとおかしくなるから
   * @param options
   */
  async query<R = any, TVariables = OperationVariables>(options: QueryOptions<TVariables> & ClientOptions): Promise<R> {
    let response;
    try {
      const responseHandler: ResponseHandler = (res) => (response = res);
      const variables = convertUndefinedToNull(_.cloneDeep(options.variables));
      const context = { ...options.context, responseHandler };
      const result = await this.apollo.query<R, TVariables>({
        ...options,
        context,
        variables,
        fetchPolicy: 'no-cache',
      });
      return convertNullToUndefined(_.cloneDeep(this.validateQueryResult(result)));
    } catch (e) {
      if (e instanceof ApolloError) {
        if (isMaintenanceError(e)) throw new MaintenanceException((e.networkError as ServerParseError)?.bodyText);
        if (isUnauthenticatedError(e)) throw new UnauthenticatedException();
        if (isInconsistentSessionError(e)) throw new InconsistentSessionException();
        if (isUnauthorizedOperationError(e)) throw new UnauthorizedOperationException();
        if (e.message === 'Network error: Failed to fetch') throw new GraphQLNetworkException(e.message + e.stack);
        throw new GraphQLException(
          e.graphQLErrors,
          this.generateQueryErrorContext(options.query, options.variables, e, response)
        );
      }
      throw e;
    }
  }

  /**
   * 必ずこいつを通して通信する事
   * convertUndefinedToNull, convertNullToUndefined をしないとおかしくなるから
   * @param options
   */
  async mutate<R = any, TVariables = OperationVariables>(
    options: MutationOptions<R, TVariables> & ClientOptions
  ): Promise<R> {
    let response;
    try {
      const responseHandler: ResponseHandler = (res) => (response = res);
      const variables = convertUndefinedToNull(_.cloneDeep(options.variables));
      const context = { ...options.context, responseHandler };
      const result = await this.apollo.mutate<R, TVariables>({
        ...options,
        context,
        variables,
        fetchPolicy: 'no-cache',
      });
      return convertNullToUndefined(_.cloneDeep(this.validateMutationResult(result)));
    } catch (e) {
      if (e instanceof ApolloError) {
        if (isMaintenanceError(e)) throw new MaintenanceException((e.networkError as ServerParseError)?.bodyText);
        if (isUnauthenticatedError(e)) throw new UnauthenticatedException();
        if (isInconsistentSessionError(e)) throw new InconsistentSessionException();
        if (isUnauthorizedOperationError(e)) throw new UnauthorizedOperationException();

        if (e.message === 'Network error: Failed to fetch') throw new GraphQLNetworkException(e.message + e.stack);
        throw new GraphQLException(
          e.graphQLErrors,
          this.generateMutationErrorContext(options.mutation, options.variables, e, response)
        );
      }
      throw e;
    }
  }

  /**
   * 与えられた FetchResult に data が入っている事を確実にする
   * @param result
   * @protected
   */
  protected validateMutationResult<Data>(result: FetchResult<Data, any, any>): Data {
    if (result.data === undefined || result.data === null) {
      if (result.errors === undefined) {
        throw new IllegalStateException(`data is undefined but errors is also undefined!`);
      }
      throw new GraphQLException(result.errors);
    }
    return result.data;
  }

  /**
   * 与えられた ApolloQueryResult に errors がない事を確実にする
   * @param result
   * @protected
   */
  protected validateQueryResult<Data>(result: ApolloQueryResult<Data>): Data {
    if (result.errors !== undefined) {
      throw new GraphQLException(result.errors);
    }
    return result.data;
  }

  /**
   * 入力した配列の数と API から返ってきた配列の数を検証する
   * update や create した場合の ID の配列の数を検証するために使える
   * @param input データの配列
   * @param output ID などの API の結果の配列
   * @protected
   */
  protected validateArrayConsistency<Input, Output>(
    input: Input[],
    output: Output[],
    typename?: Maybe<QueryTypeNames>
  ): void {
    if (input.length !== output.length) {
      logger.addBreadcrumb({
        type: 'debug',
        message: `validateArrayConsistency`,
        data: {
          input: JSON.stringify(input),
          output: JSON.stringify(output),
          typename,
        },
      });
      throw new GraphQLResultConsistencyException(input.length, output.length, typename);
    }
  }

  private generateQueryErrorContext(
    query: DocumentNode,
    variables: any,
    error: ApolloError,
    response: Maybe<Response>
  ): ErrorContext {
    const queryAndVariables = {
      query: (query.definitions as OperationDefinitionNode[]).map((def) => def.name?.value).join(', '),
      variables,
    };
    return {
      queries: JSON.stringify(queryAndVariables),
      result: JSON.stringify({
        message: error.message,
        graphQLErrors: error.graphQLErrors,
        networkError: error.networkError,
        extraInfo: error.extraInfo,
      }),
      'x-request-id': response?.headers.get('x-request-id'),
    };
  }

  private generateMutationErrorContext(
    mutation: DocumentNode,
    variables: any,
    error: ApolloError,
    response: Maybe<Response>
  ): ErrorContext {
    const mutationAndVariables = {
      mutation: (mutation.definitions as OperationDefinitionNode[]).map((def) => def.name?.value).join(', '),
      variables,
    };
    return {
      mutations: JSON.stringify(mutationAndVariables),
      result: JSON.stringify({
        message: error.message,
        graphQLErrors: error.graphQLErrors,
        networkError: error.networkError,
        extraInfo: error.extraInfo,
      }),
      'x-request-id': response?.headers.get('x-request-id'),
    };
  }
}

const logger = createLogger(`GraphqlApiBase`);
