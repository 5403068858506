import { IPackingPlacementData } from '~/framework/server-api/packing-placement/packingPlacement';
import { AggregatedContainerTypeEntity } from '../../masters/container-type/aggregatedContainerTypeEntity';

export class AggregatedPackingPlacementEntity {
  readonly id: string;
  readonly persistentId: string;
  num: number;
  lastAllocatedAt: Date;
  containerType: AggregatedContainerTypeEntity;

  constructor(data: IPackingPlacementData, containerType: AggregatedContainerTypeEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.num = data.num;
    this.lastAllocatedAt = data.lastAllocatedAt;
    this.containerType = containerType;
  }

  update(data: IPackingPlacementData, containerType: AggregatedContainerTypeEntity): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedPackingPlacementEntity');
    }
    this.num = data.num;
    this.lastAllocatedAt = data.lastAllocatedAt;
    this.containerType = containerType;
  }
}
