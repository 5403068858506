import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  driver$createSymbol,
  driver$getAllSymbol,
  driver$getByIdsSymbol,
  driver$updateSymbol,
  ICreateDriverData,
  IUpdateDriverData,
  IUpdateDriverSequenceData,
} from '~/framework/server-api/masters/driver';

import { Id, PersistentId } from '~/framework/typeAliases';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';

import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { AggregatedDriverMapper } from '~/framework/domain/masters/driver/aggregatedDriverMapper';
import { ids } from '~/framework/core/entity';

export const driverSymbol = Symbol('driver');

export class DriverApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private driverMapper: AggregatedDriverMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
    this.driverMapper = new AggregatedDriverMapper(
      store.masters.aggregatedDriver,
      store.masters.aggregatedCar,
      store.masters.aggregatedBaseSite,
      store.masters.driverAttendanceTemplate,
      store.masters.user
    );
  }

  async create(data: ICreateDriverData): Promise<AggregatedDriverEntity> {
    const driver$createApi = this.serverApis.get(driver$createSymbol);
    const [id] = await driver$createApi.create([data]);
    const [driver] = await this.getByIds([id]);
    return driver;
  }

  async update(data: IUpdateDriverData): Promise<AggregatedDriverEntity> {
    const driver$updateApi = this.serverApis.get(driver$updateSymbol);
    const [id] = await driver$updateApi.update([data]);
    const [driver] = await this.getByIds([id]);
    return driver;
  }

  async updateDriverSequence(data: IUpdateDriverSequenceData): Promise<Id[]> {
    const driver$updateApi = this.serverApis.get(driver$updateSymbol);
    const res = await driver$updateApi.updateDriverSequence(data);
    return res;
  }

  // 通信が多重になっているが微妙だが今後 API の構成をユースケースごとに変えればどうにでもなる
  async delete(id: PersistentId): Promise<AggregatedDriverEntity> {
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const driver$updateApi = this.serverApis.get(driver$updateSymbol);
    const [data] = await driver$getByIdsApi.getByIds([id]);
    const updateData: IUpdateDriverData = {
      id: data.id,
      name: data.name,
      nameRuby: data.nameRuby,
      employmentStatus: data.employmentStatus,
      defaultPrimaryCarId: data.defaultPrimaryCar ? data.defaultPrimaryCar.id : undefined,
      licensedCarTypeIds: ids(data.licensedCarTypes),
      defaultAttendanceTemplateId: data.defaultAttendanceTemplate ? data.defaultAttendanceTemplate.id : undefined,
      status: SoftDeleteStatus.Deleted, // NOTE これが大事
    };
    await driver$updateApi.update([updateData]);
    const [driver] = await this.getByIds([id]);
    return driver;
  }

  async getAll(): Promise<AggregatedDriverEntity[]> {
    const driver$getAllApi = this.serverApis.get(driver$getAllSymbol);
    const data = await driver$getAllApi.getAll();
    const drivers = this.driverMapper.map(data);
    return drivers;
  }

  async getByIds(ids: string[]): Promise<AggregatedDriverEntity[]> {
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const data = await driver$getByIdsApi.getByIds(ids);
    const drivers = this.driverMapper.map(data);
    return drivers;
  }
}
