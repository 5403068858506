import { PreloadStatus, MarginType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
export const officeSetting$getSymbol = Symbol('officeSetting$get');
export const officeSetting$updateSymbol = Symbol('officeSetting$update');

export type OfficeSetting = {
  [officeSetting$getSymbol]: IGetApi;
  [officeSetting$updateSymbol]: IUpdateApi;
};

export interface IGetApi {
  get(): Promise<IOfficeSettingData>;
}

export interface IOfficeSettingData {
  id: string;
  defaultDurationAtBaseSite: number;
  defaultDurationAtGenerationSite: number;
  defaultDurationAtDisposalSite: number;
  defaultRestPeriodStartOfGenerationSite: number | undefined;
  defaultRestPeriodEndOfGenerationSite: number | undefined;
  defaultRestPeriodStartOfDisposalSite: number | undefined;
  defaultRestPeriodEndOfDisposalSite: number | undefined;
  defaultBannedDriverIdsAtGenerationSite: string[];
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: boolean;
  defaultAvoidHighways: boolean;
  isPackingPlacementManagementEnabled: boolean;
  isFixedArrivalTimeReportEnabled: boolean;
  defaultIsFixedArrivalTimeReportNeeded: boolean;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  isOnlineReservationEnabled: boolean;
  isPublishingStatusOfScheduleManagementEnabled: boolean;
  canPrintSchedules: boolean;
  canUseErp: boolean;
  useCsvImport: boolean;
  useCsvExport: boolean;
}

export interface IUpdateApi {
  update(data: IUpdateData): Promise<string>;
}

export interface IUpdateData {
  id: string;
  defaultDurationAtBaseSite: number;
  defaultDurationAtGenerationSite: number;
  defaultDurationAtDisposalSite: number;
  defaultRestPeriodStartOfGenerationSite: Maybe<number>;
  defaultRestPeriodEndOfGenerationSite: Maybe<number>;
  defaultRestPeriodStartOfDisposalSite: Maybe<number>;
  defaultRestPeriodEndOfDisposalSite: Maybe<number>;
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: boolean;
}
