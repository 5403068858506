import Vue from 'vue';
import _ from 'lodash';
import { schedulePriorityOptions, ISchedulingPriorityOption } from '../schedulingPriority';
import {
  BaseTaskTypeName,
  DriverAssignmentType,
  DriverType,
  MarginType,
  OrderDisposalSiteAssignmentType,
  OrderDisposalSitePriority,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import { RDisposalSitesInstance } from '~/components/panels/schedule/r-order-form/rDisposalSites';
import {
  ClientsByKeywordsCondition,
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  GenerationSitesByKeywordsCondition,
} from '~/framework/server-api/typeAliases';

import { Maybe, PersistentId, ValidationRule } from '~/framework/typeAliases';
import { highwayOptions, IHighwayOption } from '~/framework/view-models/highwayOption';
import {
  fixedDisplayOnReservationOptions,
  IFixedDisplayOnReservationOption,
} from '~/framework/view-models/fixedDisplayOnReservationOption';
import {
  ContainerTypeTaskItemFactory,
  IGenerationSiteTaskItem,
} from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';

import { mapEntity } from '~/framework/core/mapper';
import { IContainerTypeTaskDuration } from '~/components/panels/schedule/r-order-form/containerTypeTaskDuration';
import { required } from '~/framework/view-models/rules';
import {
  GenerationSiteTaskCategory,
  generationSiteTaskCategoryOptions,
  IGenerationSiteTaskCategory,
} from '~/framework/view-models/generationSiteTaskCategory';
import { ISkipDisposalSiteOption, skipDisposalSiteOptions } from '~/framework/view-models/skipDisposalSite';
import {
  IIrregularTaskItem,
  IrregularTaskItemFactory,
} from '~/components/panels/schedule/r-order-form/irregularTaskItem';
import { IOrderValidation } from '~/framework/view-models/orderValidation';
import { Client } from '~/components/common/r-lazy-searchable-pulldown/client';
import { GenerationSite } from '~/components/common/r-lazy-searchable-pulldown/generationSite';
import { RLazySearchablePulldown } from '~/components/common/r-lazy-searchable-pulldown/componentType';
import { numberArray } from '~/framework/array';
import { InfeasibilityReasons, RawInfeasibilityJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import {
  AggregatedCarTypeEntity,
  SimpleCarTypeEntity,
} from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import DisabledReason from '~/components/panels/schedule/r-order-form/disabledReasonEnum';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import {
  fixedArrivalTimeReportOptions,
  IFixedArrivalTimeReportOption,
} from '~/framework/view-models/fixedArrivalTimeReportOption';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { IMarginTypeOption, marginTypeOptions } from '~/framework/view-models/marginTypeOption';
import { IOrderAcceptanceCheckData } from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceCheckApplicationService';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';

import { IllegalStateException } from '~/framework/core/exception';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { IOrderRoutingGroupItem } from '~/components/panels/schedule/r-order-form/routingGroup';
import { CheckItemEntity } from '~/framework/domain/masters/check-item/checkItemEntity';
import { DisposalSiteAttendanceEntity } from '~/framework/domain/masters/disposal-site-attendance/disposalSiteAttendanceEntity';
import { createLogger } from '~/framework/logger';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import {
  isDriversCandidate,
  isHelpersCandidate,
  isOperatorsCandidate,
} from '~/framework/domain/schedule/order/driver/aggregatedOrderAssignableDriver';
import { AggregatedWasteTypeEntity } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { ICreateOrderData } from '~/framework/server-api/schedule/order/createOrders';
import { CreateOrderAssignableDriverInput, CreateOrderDisposalSiteInput } from '~/graphql/graphQLServerApi';
import { ids } from '~/framework/core/entity';

// OrderFormBase を持つ前置き。
// 受注頭の開発で予約の概念を導入する時に、予約パネルが必要になった。受注頭で行われた回収の予約を配車頭の予約パネルで確認し、確定すること
// によって、新しい受注が登録される。予約パネルの内容はほとんど受注パネルの内容と同等だが、同じではない。
// 既存の受注パネルに条件分岐をすることで予約のロジックを仕込むとあらゆるところで if 文を書いて対応することになるため予約のロジックが
// 分散されてしまう。かつ予約と同じ様にまた別の分岐が増えた際の拡張性にも弱いためあまり良くないと判断し、予約パネルを新設することにした。
// 受注の新規登録と予約の確定の共通ロジックとして、例えば同じバリデーションを走らせる、フォームの入力内容の依存関係を持つ（例えば排出場の選択を
// 更新した際にほかの入力項目が自動で更新される）、等がある。従って、抽象クラス OrderFormBase に共通のロジックを持たせることにし、
// 受注パネルと予約パネルはこちらのクラスを拡張して定義する。
// 今回は受注・予約フォームの viewModel の共通ロジックを OrderFormBase に持たせることで対応しているが、他には mixin に
// したりコンポーネントの拡張をしたりする等の方法が考えられるが、受注頭の PoC のタイムライン的にその開発が間に合わないと判断したため、
// OrderFormBase を設けて対応している。

const rinLogger = createLogger('orderFormBase');

export abstract class OrderFormBase<FormValues> {
  abstract readonly title: string;
  abstract readonly isDirty: boolean;
  abstract initialFormValues: FormValues;
  /**
   * フォームに入力されている値を返す。
   */
  abstract getFormValues(): FormValues;
  /**
   * フォームの各プロパティに値を設定をし、整合性をチェックと依存性を持つプロパティの値を調整する。
   * @param initialFormValues フォームに設定する各プロパティの値。
   * @param client 会社を設定する際に、該当の会社のエンティティ。
   * @param generationSite 排出場を設定する際に、該当の排出場のエンティティ。
   */
  abstract initializeFormValues(
    initialFormValues: FormValues,
    client: Maybe<AggregatedClientEntity>,
    generationSite: Maybe<AggregatedGenerationSiteEntity>
  ): void;

  /**
   * 受注登録前のチェックのためのデータを取得する
   */
  abstract getValidateOrderData(): ICreateOrderData;
  /**
   * 瞬間チェックのためのデータを取得する
   */
  abstract getOrderAcceptanceCheckData(): IOrderAcceptanceCheckData;

  // form general
  /**
   * v-model for v-form
   */
  isFormValid: boolean = false;
  isCheckingOrderAcceptance: boolean;
  isRegistering: boolean;
  get isRegisterButtonDisabled(): boolean {
    return !this.isFormValid;
  }

  // client
  readonly clientLoader: Client;
  clientDefaultCondition: ClientsByKeywordsCondition;
  clientId: Maybe<PersistentId>;

  // generation site
  readonly generationSiteLoader: GenerationSite;
  generationSiteDefaultCondition: GenerationSitesByKeywordsCondition;
  generationSiteId: Maybe<PersistentId>;
  generationSiteSelection: Maybe<RLazySearchablePulldown>;

  // xxx を entity としたとき、xxxId と allXxxs があれば、基本的に xxx が取得できる。
  // しかし、Client, GenerationSite は数が多く、 allXxxs を保持することができない。
  // そのため、xxxIdに関する画面表示のために、対応する xxx を別途保持する。
  // なお、xxxId を削除し、xxx のみを管理するようなやり方は、xxx が readonly であることから、
  // 値を変更する form 直接入れることができないため、実装上の都合上できない。
  client: Maybe<AggregatedClientEntity>;
  generationSite: Maybe<AggregatedGenerationSiteEntity>;

  // order group
  orderGroupId: Maybe<PersistentId>;
  readonly allOrderGroups: OrderGroupEntity[];

  // tasks
  readonly allWasteTypes: AggregatedWasteTypeEntity[];
  readonly allContainerTypes: AggregatedContainerTypeEntity[];
  readonly allContainerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  readonly allPackingStyles: PackingStyleEntity[];
  generationSiteTaskCategory!: GenerationSiteTaskCategory; // initializeFormValues によって設定される
  generationSiteTaskCategoryOptions: IGenerationSiteTaskCategory[];
  // task - container
  generationSiteTaskItems!: IGenerationSiteTaskItem[]; // initializeFormValues によって設定される
  taskTypes: TaskTypeEntity[];
  /**
   * Filtered by selected orderGroup.
   */
  filteredContainerTypes: AggregatedContainerTypeEntity[];
  generationSiteTaskDuration!: IContainerTypeTaskDuration; // initializeFormValues によって設定される
  // task - irregular
  irregularTask!: IIrregularTaskItem; // initializeFormValues によって設定される
  skipDisposalSiteOptions: ISkipDisposalSiteOption[];

  // disposal sites
  readonly allDisposalSites: AggregatedDisposalSiteEntity[];
  disposalSiteIds!: PersistentId[]; // initializeFormValues によって設定される
  selectedDisposalSites!: AggregatedDisposalSiteEntity[]; // initializeFormValues によって設定される
  orderAssignedDisposalSites!: CreateOrderDisposalSiteInput[];
  disposalSiteAssignmentType!: OrderDisposalSiteAssignmentType;
  disposalSiteSelectionComponent: Maybe<Vue>;
  disposalSiteRules!: ValidationRule[]; // initializeFormValues によって設定される

  // disposal site attendance
  disposalSiteAttendances: DisposalSiteAttendanceEntity[];

  // cars count
  carNum!: number; // initializeFormValues によって設定される
  carNumItems: number[];
  numItems: number[];

  // order note
  note: Maybe<string>;
  attachmentsToAdd: File[];

  // driver count
  driverNum!: number; // initializeFormValues によって設定される
  driverNumItems: number[];

  // avoid highways
  avoidHighways!: boolean; // initializeFormValues によって設定される
  avoidHighwaysOptions: IHighwayOption[];

  // assigner driver
  readonly allDrivers: AggregatedDriverEntity[];
  driverAssignmentType: DriverAssignmentType;
  isAssignableDriversCandidate: boolean;
  assignableDrivers: Array<CreateOrderAssignableDriverInput>;
  assignableDriverAttendances: Maybe<AggregatedDriverAttendanceEntity[]>;

  // car and car type
  readonly allCars: AggregatedCarEntity[];
  assignableCarIds!: PersistentId[]; // initializeFormValues によって設定される
  get assignableCars(): AggregatedCarEntity[] {
    return this.allCars.filter((car) => this.assignableCarIds.includes(car.id));
  }

  readonly allCarTypes: AggregatedCarTypeEntity[];
  assignableCarTypeIds!: PersistentId[]; // initializeFormValues によって設定される
  get assignableCarTypes(): SimpleCarTypeEntity[] {
    return this.allCarTypes.filter((carType) => this.assignableCarTypeIds.includes(carType.id));
  }

  /**
   * Filtered by selected orderGroup.
   */
  filteredCarTypes: SimpleCarTypeEntity[];

  /**
   * Filtered by selected orderGroup.
   */
  filteredCars: AggregatedCarEntity[];
  primaryCarOfAssignableDriver: Maybe<AggregatedCarEntity>;
  primaryCarTypeOfAssignableDriver: Maybe<SimpleCarTypeEntity>;

  // route collection
  routeCollectionAllowed!: boolean; // initializeFormValues によって設定される
  routingGroup: Maybe<IOrderRoutingGroupItem>;

  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: Maybe<string>;
  fixedDisplayOnReservationOptions: IFixedDisplayOnReservationOption[];

  schedulingPriority!: OrderSchedulingPriority; // initializeFormValues によって設定される
  schedulingPriorityOptions: ISchedulingPriorityOption[];

  // commit arrival time for pick-up
  isFixedArrivalTimeReportNeeded!: boolean; // initializeFormValues によって設定される
  fixedArrivalTimeReportOptions: IFixedArrivalTimeReportOption[];
  marginTypeOfFixedArrivalTime!: MarginType; // initializeFormValues によって設定される
  marginTypeOptions: IMarginTypeOption[];
  marginOfFixedArrivalTimeHours: number = 0;
  marginOfFixedArrivalTimeMinutes: number = 0;
  fixedArrivalTime: Maybe<number>;
  get marginOfFixedArrivalTime(): number {
    return this.marginOfFixedArrivalTimeHours * 60 * 60 + this.marginOfFixedArrivalTimeMinutes * 60;
  }

  // checkItems
  // NOTE: 受注パネルに表示するタスクの一覧とチェックするかどうか (isRequired)
  checkItems: (CheckItemEntity & { isRequired: boolean })[];
  // NOTE: input で送るための computed property
  // isRequired のものだけ送る
  get checkItemIds(): string[] {
    return this.checkItems.filter((item) => item.isRequired).map((item) => item.id);
  }

  // recordStamper
  // NOTE: FormMode === Register の時は null, また，Reservation では常に null(未対応)
  createdBy: Maybe<UserEntity>;
  createdAt: Maybe<Date>;
  updatedBy: Maybe<UserEntity>;
  updatedAt: Maybe<Date>;

  // validation
  rules: { [key: string]: ValidationRule };
  maxErrorCount: number;
  orderValidation: IOrderValidation;

  // disabled/readonly
  isCollectablePeriodTemplateDisabled: boolean;
  isCollectablePeriodStartDisabled: boolean;
  isCollectablePeriodEndDisabled: boolean;
  isCollectableDistinctTimeDisabled: boolean;
  isDateDisabled: boolean;
  isUnloadDateDisabled: boolean;
  isDateCollectablePeriodTypeInputDisabled: boolean;
  isTaskFieldDisabled: boolean;
  isAddTaskButtonDisabled: boolean;
  isDisposalSiteSelectionShown!: boolean; // initializeFormValues によって設定される
  isDisposalSiteDisabled: boolean;
  disposalSiteDisabledReason: Set<DisabledReason>;
  isNoteDisabled: boolean;
  isAttachmentsDisabled: boolean;
  isAvoidHighwaysDisabled: boolean;
  isCarUsageWarned: boolean;
  isRouteCollectionOptionsDisabled: boolean;
  isFixedArrivalTimeReportDisplayed: boolean;
  isMarginTypeOfFixedArrivalTimeDisabled: boolean;
  isMarginOfFixedArrivalTimeDisabled: boolean;

  protected readonly disposalSiteMap: Map<PersistentId, AggregatedDisposalSiteEntity>;

  constructor(
    clientDefaultCondition: ClientsByKeywordsCondition,
    clientLoader: Client,
    generationSiteDefaultCondition: GenerationSitesByKeywordsCondition,
    generationSiteLoader: GenerationSite,
    officeSetting: OfficeSettingEntity,
    orderGroups: OrderGroupEntity[],
    disposalSites: AggregatedDisposalSiteEntity[],
    wasteTypes: AggregatedWasteTypeEntity[],
    containerTypes: AggregatedContainerTypeEntity[],
    containerTypeTaskTypes: ContainerTypeTaskTypeEntity[],
    packingStyles: PackingStyleEntity[],
    drivers: AggregatedDriverEntity[],
    carTypes: AggregatedCarTypeEntity[],
    cars: AggregatedCarEntity[],
    assignableDriverAttendances: Maybe<AggregatedDriverAttendanceEntity[]>,
    orderValidation: IOrderValidation,
    taskTypes: TaskTypeEntity[],
    checkItems: CheckItemEntity[]
  ) {
    // マスター系
    this.allOrderGroups = orderGroups;
    this.allDisposalSites = disposalSites;
    this.allWasteTypes = wasteTypes;
    this.allContainerTypes = containerTypes;
    this.allContainerTypeTaskTypes = containerTypeTaskTypes;
    this.allPackingStyles = packingStyles;
    this.allDrivers = drivers;
    this.allCarTypes = carTypes;
    this.allCars = cars;
    this.assignableDriverAttendances = assignableDriverAttendances;
    this.disposalSiteMap = mapEntity(this.allDisposalSites);
    this.taskTypes = taskTypes;
    this.checkItems = checkItems.map((item) => ({ ...item, isRequired: item.default }));
    this.disposalSiteAttendances = [];

    // フォームに関係する値を設定
    this.orderValidation = orderValidation;
    // 固定的な値を設定
    // さすがに10コもエラー出る事はあり得ないと思うので
    this.maxErrorCount = 10;
    this.filteredContainerTypes = [];
    this.filteredCarTypes = [];
    this.filteredCars = [];
    this.rules = { required };
    this.numItems = numberArray(1, 99);
    this.attachmentsToAdd = [];
    this.carNumItems = this.numItems;
    this.driverNumItems = this.numItems;
    this.isDateCollectablePeriodTypeInputDisabled = true;
    this.isDateDisabled = true;
    this.isUnloadDateDisabled = true;
    this.isCollectablePeriodTemplateDisabled = true;
    this.isCollectableDistinctTimeDisabled = true;
    this.isCollectablePeriodStartDisabled = true;
    this.isCollectablePeriodEndDisabled = true;
    this.isTaskFieldDisabled = true;
    this.isAddTaskButtonDisabled = true;
    this.isDisposalSiteDisabled = true;
    this.disposalSiteDisabledReason = new Set();
    this.isNoteDisabled = true;
    this.isAttachmentsDisabled = true;
    this.isAvoidHighwaysDisabled = true;
    this.isRouteCollectionOptionsDisabled = true;
    this.isFixedArrivalTimeReportDisplayed = officeSetting.isFixedArrivalTimeReportEnabled;
    this.fixedArrivalTimeReportOptions = fixedArrivalTimeReportOptions;
    this.isMarginTypeOfFixedArrivalTimeDisabled = true;
    this.marginTypeOptions = marginTypeOptions;
    this.isMarginOfFixedArrivalTimeDisabled = true;
    this.routingGroup = undefined;
    this.fixedDisplayOnReservation = false;
    this.fixedDisplayOnReservationName = undefined;
    this.schedulingPriority = OrderSchedulingPriority.None;
    this.schedulingPriorityOptions = schedulePriorityOptions;
    this.isCheckingOrderAcceptance = false;
    this.isRegistering = false;
    this.avoidHighwaysOptions = highwayOptions;
    this.fixedDisplayOnReservationOptions = fixedDisplayOnReservationOptions;
    this.driverAssignmentType = DriverAssignmentType.NotDistinguished;
    this.isAssignableDriversCandidate = false;
    this.assignableDrivers = [];
    this.isCarUsageWarned = false;
    this.primaryCarOfAssignableDriver = undefined;
    this.primaryCarTypeOfAssignableDriver = undefined;
    this.generationSiteTaskCategoryOptions = generationSiteTaskCategoryOptions;
    this.skipDisposalSiteOptions = skipDisposalSiteOptions;
    this.clientDefaultCondition = clientDefaultCondition;
    this.clientLoader = clientLoader;
    this.generationSiteDefaultCondition = generationSiteDefaultCondition;
    this.generationSiteLoader = generationSiteLoader;
    this.orderAssignedDisposalSites = [];
  }

  // form general

  onInputPrecheckInfeasibilities(infeasibilities: RawInfeasibilityJsonObject[]): void {
    this.orderValidation.setErrorsByInfeasibilities(
      this.assignableDrivers.map((assignableDriver) => assignableDriver.driverId),
      this.assignableCars,
      infeasibilities
    );
  }

  // client methods
  onClientChange(initialization: boolean = false, triggeredByGenerationSiteChange: boolean = false): void {
    this.generationSiteDefaultCondition = { ...this.generationSiteDefaultCondition, clientId: this.client?.id };

    if (initialization === false) {
      if (!triggeredByGenerationSiteChange) {
        // クライアントが変更された場合は排出場はリセットするが、警告は出したくないので reset する
        // @ts-ignore
        if (this.generationSiteSelection !== undefined) this.generationSiteSelection.reset();
      }
    }

    this.onChangeGenerationSite(initialization);
  }

  // generation site methods
  onChangeGenerationSite(initialization: boolean = false): void {
    this.isTaskFieldDisabled = this.generationSiteId === undefined;
    this.isAddTaskButtonDisabled = this.generationSiteId === undefined;
    this.isNoteDisabled = this.generationSiteId === undefined;
    this.isAttachmentsDisabled = this.generationSiteId === undefined;
    this.isAvoidHighwaysDisabled = this.generationSiteId === undefined;
    this.isRouteCollectionOptionsDisabled = this.generationSiteId === undefined;
    this.isMarginTypeOfFixedArrivalTimeDisabled = this.generationSiteId === undefined;
    this.isMarginOfFixedArrivalTimeDisabled = this.generationSiteId === undefined;

    if (this.generationSiteId === undefined) {
      this.addDisposalSiteDisabledReason(DisabledReason.GenerationSiteIsNotSelected);
      this.resetDisposalSiteSelection();
    } else {
      this.deleteDisposalSiteDisabledReason(DisabledReason.GenerationSiteIsNotSelected);
      this.updateDisposalSiteSelection(initialization);
    }

    if (initialization === false) {
      this.orderValidation.resetErrorByField('generationSite');
      this.orderValidation.resetErrorByField('disposablePeriod');
      if (this.generationSiteId !== undefined) {
        if (this.generationSite === undefined) {
          throw new Error(`generation site: ${this.generationSiteId} was not found!`);
        }
        // 排出場に設定されている各種デフォルトの値を設定
        if (this.disposalSiteDisabledReason.has(DisabledReason.EveryGenerationSiteTaskTypeIsAllocate)) {
          this.resetDisposalSiteSelection();
        } else if (this.generationSite.defaultAssignedDisposalSite !== undefined) {
          this.disposalSiteIds = [this.generationSite.defaultAssignedDisposalSite.id];
        } else if (this.allDisposalSites.length === 1) {
          this.disposalSiteIds = [this.allDisposalSites[0].persistentId];
        } else if (this.disposalSiteSelectionComponent !== undefined) {
          this.disposalSiteIds = [];
          // Remove validation error of `RDisposalSite` due to empty array value of `disposalSiteIds`.
          // Action buttons remain disabled.
          // @ts-ignore
          (this.disposalSiteSelectionComponent as RDisposalSitesInstance).resetInput();
        }
        this.onChangeAssignedDisposalSite();
        this.setAssignableCarTypesAndAssignedCarToGenerationSiteDefault();
        this.avoidHighways = this.generationSite.defaultAvoidHighways;
        // TODO: HGB-103 排出場のデフォルト指定乗務員は補助指定できないので「補助指定なし」としている
        this.driverAssignmentType = DriverAssignmentType.NotDistinguished;
        // NOTE: 指定乗務員が候補状態かどうかを再計算する (現状は排出場のデフォルト指定乗務員は1人までなので候補状態にはならない)
        this.isAssignableDriversCandidate =
          this.driverAssignmentType === DriverAssignmentType.NotDistinguished
            ? isDriversCandidate(this.driverNum, this.assignableDrivers)
            : isOperatorsCandidate(this.carNum, this.assignableDrivers) ||
              isHelpersCandidate(this.carNum, this.driverNum, this.assignableDrivers);
        // NOTE: デフォルト指定乗務員がいない場合は空にする
        this.assignableDrivers = this.generationSite.defaultAssignedDriver
          ? [
              {
                driverType: DriverType.Driver,
                driverId: this.generationSite.defaultAssignedDriver.id,
              },
            ]
          : [];
        this.generationSiteTaskDuration.setDefaultDurationAtEntrance(this.generationSite.defaultDurationAtEntrance);
        this.updateGenerationSiteTaskDuration();
      }
    }
  }

  setClientOnGenerationSiteChange(client: AggregatedClientEntity) {
    this.client = client;
    this.clientId = client.id;
    this.onClientChange(false, true);
  }

  onMountGenerationSiteSelection(rLazySearchablePulldown: RLazySearchablePulldown): void {
    this.generationSiteSelection = rLazySearchablePulldown;
  }

  onUnmountGenerationSiteSelection(): void {
    this.generationSiteSelection = undefined;
  }

  setAssignableCarTypesAndAssignedCarToGenerationSiteDefault(): void {
    this.setAssignableCarTypesToGenerationSiteDefault();
    this.setAssignableCarsToGenerationSiteDefault();
    this.onChangeAssignableCarTypes();
    this.onChangeAssignableCars();
  }

  setAssignableCarTypesToGenerationSiteDefault(): void {
    if (this.generationSiteId === undefined) return;
    if (this.generationSite === undefined) {
      throw new Error(`generation site: ${this.generationSiteId} was not found!`);
    }

    // デフォルト指定車種は反映したいがグループによっては存在していない可能性があるので
    const filteredCarTypeIdsSet = new Set<PersistentId>(ids(this.filteredCarTypes));
    this.assignableCarTypeIds = this.generationSite.defaultAssignableCarTypes
      .map((carType) => carType.id)
      .filter((carTypeId) => filteredCarTypeIdsSet.has(carTypeId));
  }

  setAssignableCarsToGenerationSiteDefault(): void {
    if (this.generationSiteId === undefined) return;
    if (this.generationSite === undefined) {
      throw new Error(`generation site: ${this.generationSiteId} was not found!`);
    }

    // デフォルト指定車種は反映したいがグループによっては存在していない可能性があるので
    const filteredCarIdsSet = new Set<PersistentId>(ids(this.filteredCars));
    this.assignableCarIds = this.generationSite.defaultAssignableCars
      .map((car) => car.id)
      .filter((carId) => filteredCarIdsSet.has(carId));
  }

  getDurationAtGenerationSite(): Maybe<number> {
    return this.generationSiteTaskCategory === GenerationSiteTaskCategory.TaskWithContainer
      ? this.generationSiteTaskDuration.durationAtGenerationSite
      : this.irregularTask.durationAtGenerationSite;
  }

  // order group methods
  onChangeOrderGroup(initialization: boolean = false): void {
    this.updateContainerTypes();

    this.isDateCollectablePeriodTypeInputDisabled = this.orderGroupId === undefined;
    this.isDateDisabled = this.orderGroupId === undefined;
    this.isUnloadDateDisabled = this.orderGroupId === undefined;
    this.isCollectablePeriodTemplateDisabled = this.orderGroupId === undefined;
    this.isCollectableDistinctTimeDisabled = this.orderGroupId === undefined;
    this.isCollectablePeriodStartDisabled = this.orderGroupId === undefined;
    this.isCollectablePeriodEndDisabled = this.orderGroupId === undefined;

    if (this.orderGroupId === undefined) {
      this.filteredCarTypes = [];
      this.filteredCars = [];
    } else {
      this.filteredCarTypes = this.allCarTypes.filter((carType) => carType.orderGroup.id === this.orderGroupId);
      this.filteredCars = this.allCars.filter((car) => car.carType.orderGroup.id === this.orderGroupId);
    }

    if (initialization === false) {
      // 受注グループを変更するとコンテナの種類、車種、車番が変わってしまうため、作業、車種、車番をリセットする
      this.resetGenerationSiteTaskItems();
      this.resetTaskRelatedErrors();

      if (this.generationSite !== undefined) {
        this.setAssignableCarTypesAndAssignedCarToGenerationSiteDefault();
      } else {
        this.resetAssignableCarTypes();
        this.resetAssignedCar();
      }
    }
  }

  // task methods
  onChangeGenerationSiteTaskCategory(initialization: boolean = false): void {
    // タスクのカテゴリが変更された場合は他のカテゴリのタスクをリセットする
    if (initialization === false) {
      // 面倒なので一気にガツッと初期化してしまう
      this.resetTaskRelatedErrors();
      this.irregularTask = IrregularTaskItemFactory.instantiateDefault();
      this.resetGenerationSiteTaskItems();
      this.resetDisposalSiteSelection();
      const disposalSiteSelectionComponent = this.disposalSiteSelectionComponent as RDisposalSitesInstance;
      if (disposalSiteSelectionComponent !== undefined) {
        // @ts-ignore
        disposalSiteSelectionComponent.resetInput();
      }
    }
    this.updateDisposalSiteSelection(initialization);
  }

  onResetTaskCategory(): void {
    this.generationSiteTaskCategory = GenerationSiteTaskCategory.TaskWithContainer;
    this.onChangeGenerationSiteTaskCategory();
  }

  onGenerationSiteTaskChange(
    initialization: boolean = false,
    items: Maybe<IGenerationSiteTaskItem[]> = undefined
  ): void {
    this.updateDisposalSiteSelection(initialization);
    if (items !== undefined) this.updateGenerationSiteTask(items);
    this.updateGenerationSiteTaskDuration();
    if (initialization === false) {
      this.resetTaskRelatedErrors();
    }
  }

  onTaskAddButtonClick(): void {
    this.generationSiteTaskItems.push(ContainerTypeTaskItemFactory.instantiateDefault());
    this.updateGenerationSiteTaskDuration();
  }

  getCreateGenerationSiteTaskInput(): CreateGenerationSiteTaskInput[] {
    if (this.generationSiteTaskCategory !== GenerationSiteTaskCategory.TaskWithContainer) return [];
    const inputs: CreateGenerationSiteTaskInput[] = [];
    for (const item of this.generationSiteTaskItems) {
      if (item.taskType?.id === undefined) throw new IllegalStateException('task type fail');

      inputs.push({
        taskTypeId: item.taskType.id,
        wasteTypeId: item.wasteTypeId,
        containerTypeId: item.containerTypeId!,
        containerNum: item.containerNum!,
      });
    }
    return inputs;
  }

  onIrregularTaskChange(): void {
    this.resetTaskRelatedErrors();
  }

  onChangeSkipDisposalSite(initialization: boolean = false): void {
    if (initialization === false) {
      this.irregularTask.hoursAtDisposalSite = 0;
      this.irregularTask.minutesAtDisposalSite = 0;
    }
    this.updateDisposalSiteSelection(initialization);
  }

  getCreateIrregularTaskInput(): CreateIrregularTaskInput[] {
    if (this.generationSiteTaskCategory !== GenerationSiteTaskCategory.Irregular) return [];
    const inputs: CreateIrregularTaskInput[] = [];
    inputs.push({
      name: this.irregularTask.name === '' ? 'その他' : this.irregularTask.name,
      durationAtGenerationSite: this.irregularTask.durationAtGenerationSite,
      skipDisposalSite: this.irregularTask.skipDisposalSite,
      durationAtDisposalSite: this.irregularTask.durationAtDisposalSite,
    });
    return inputs;
  }

  // disposal site methods
  onMountedDisposalSitesComponent(rDisposalSitesComponent: Vue) {
    this.disposalSiteSelectionComponent = rDisposalSitesComponent;
  }

  onBeforeDestroyDisposalSitesComponent(): void {
    this.disposalSiteSelectionComponent = undefined;
  }

  // NOTE: disposalSiteEntity と disposalSiteAttendanceEntity から IOrderAssignedDisposalSite を生成する
  // options はデフォルトから明示的に上書きしたい値を指定できる。指定がなければ API や store からデフォルト値を設定する
  generateOrderAssignedDisposalSite(
    disposalSiteId: string,
    options?: {
      priority?: OrderDisposalSitePriority;
      durationAtEntrance?: number;
      disposablePeriodStart?: number;
      disposablePeriodEnd?: number;
    }
  ): CreateOrderDisposalSiteInput {
    const disposalSite = this.allDisposalSites.find((disposalSite) => disposalSite.id === disposalSiteId);
    if (disposalSite === undefined) throw new Error('assigned disposal site must exist');

    // NOTE: this.disposalSiteAttendances は基準日のデータのみ持つようにしているので、1件に定まる
    const disposalSiteAttendance = this.disposalSiteAttendances.find(
      (disposalSiteAttendance) => disposalSiteAttendance.disposalSite.id === disposalSite.id
    );

    const defaultDisposablePeriodsStart = disposalSiteAttendance?.periodStart ?? disposalSite.disposablePeriodStart;
    const defaultDisposablePeriodsEnd = disposalSiteAttendance?.periodEnd ?? disposalSite.disposablePeriodEnd;

    return {
      disposalSiteId: disposalSite.id,
      priority: options?.priority ?? undefined,
      durationAtEntrance: options?.durationAtEntrance ?? disposalSite.durationAtEntrance,
      disposablePeriodStart: options?.disposablePeriodStart ?? defaultDisposablePeriodsStart,
      disposablePeriodEnd: options?.disposablePeriodEnd ?? defaultDisposablePeriodsEnd,
    };
  }

  onChangeAssignedDisposalSiteWithPriority(
    highPriorityDisposalSiteIds: string[],
    lowPriorityDisposalSiteIds: string[]
  ): void {
    const selectedDisposalSiteIds = [...highPriorityDisposalSiteIds, ...lowPriorityDisposalSiteIds];

    // NOTE: viewModel の disposalSiteId に合わせて orderAssignedDisposalSites を更新 or 生成する
    this.orderAssignedDisposalSites = selectedDisposalSiteIds.map((id) => {
      const orderAssignedDisposalSite = this.orderAssignedDisposalSites.find(
        (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId === id
      );
      const priority = highPriorityDisposalSiteIds.includes(id)
        ? OrderDisposalSitePriority.High
        : OrderDisposalSitePriority.Low;
      if (orderAssignedDisposalSite !== undefined) {
        // NOTE: orderAssignedDisposalSite がすでにある場合は priority を更新する
        return {
          ...orderAssignedDisposalSite,
          priority,
        };
      } else {
        return this.generateOrderAssignedDisposalSite(id, {
          priority,
        });
      }
    });

    this.selectedDisposalSites = this.disposalSiteIds.map((id) => this.disposalSiteMap.getOrError(id));
  }

  onChangeAssignedDisposalSite(initialization: boolean = false): void {
    if (initialization === false) {
      this.orderValidation.resetErrorByField('disposalSite');
      this.orderValidation.resetErrorByField('disposablePeriod');
    }

    // NOTE: viewModel の disposalSiteId に合わせて orderAssignedDisposalSites を更新する
    this.orderAssignedDisposalSites = this.disposalSiteIds.map((id) => {
      const orderAssignedDisposalSite = this.orderAssignedDisposalSites.find(
        (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId === id
      );
      if (orderAssignedDisposalSite !== undefined) {
        return orderAssignedDisposalSite;
      } else {
        return this.generateOrderAssignedDisposalSite(id);
      }
    });

    this.selectedDisposalSites = this.disposalSiteIds.map((id) => this.disposalSiteMap.getOrError(id));
  }

  onChangeOrderAssignedDisposalSiteDurations(
    disposalSiteDurations: { disposalSiteId: string; durationAtEntrance: number }[]
  ): void {
    this.orderAssignedDisposalSites = this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
      const disposalSiteDuration = disposalSiteDurations.find(
        (disposalSiteDuration) => disposalSiteDuration.disposalSiteId === orderAssignedDisposalSite.disposalSiteId
      );

      // NOTE: 更新すべきデータがない場合はそのまま返す
      // 入退場時間の更新では処分場の増減はありえないので、この分岐は通らないはず。
      if (disposalSiteDuration === undefined) {
        rinLogger.warn(
          `disposalSiteIds in orderAssignedDisposalSite did not match. disposalSiteId is ${orderAssignedDisposalSite.disposalSiteId}`
        );
        return orderAssignedDisposalSite;
      }

      return {
        ...orderAssignedDisposalSite,
        durationAtEntrance: disposalSiteDuration.durationAtEntrance,
      };
    });
  }

  onChangeOrderAssignedDisposalSiteDisposablePeriods(
    disposalSiteDisposablePeriods: {
      disposalSiteId: string;
      disposablePeriodStart: number;
      disposablePeriodEnd: number;
    }[]
  ): void {
    this.orderValidation.resetErrorByField('disposablePeriod');

    this.orderAssignedDisposalSites = this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
      const disposalSiteDisposablePeriod = disposalSiteDisposablePeriods.find(
        (disposalSiteDuration) => disposalSiteDuration.disposalSiteId === orderAssignedDisposalSite.disposalSiteId
      );

      // NOTE: 更新すべきデータがない場合はそのまま返す
      // 到着時間指定の更新では処分場の増減はありえないので、この分岐は通らないはず。
      if (disposalSiteDisposablePeriod === undefined) {
        rinLogger.warn(
          `disposalSiteIds in orderAssignedDisposalSite did not match. disposalSiteId is ${orderAssignedDisposalSite.disposalSiteId}`
        );
        return orderAssignedDisposalSite;
      }

      return {
        ...orderAssignedDisposalSite,
        disposablePeriodStart: disposalSiteDisposablePeriod.disposablePeriodStart,
        disposablePeriodEnd: disposalSiteDisposablePeriod.disposablePeriodEnd,
      };
    });
  }

  // 処分場の稼働状況から到着時間指定を更新する
  updateDisposablePeriodsByDefault(): void {
    this.orderAssignedDisposalSites = this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
      // NOTE: 1日分の disposalSiteAttendance のみ保持するようにしているので、 find で一意に定まる
      const disposalSiteAttendance = this.disposalSiteAttendances.find(
        (disposalSiteAttendance) => disposalSiteAttendance.disposalSite.id === orderAssignedDisposalSite.disposalSiteId
      );

      if (
        disposalSiteAttendance === undefined ||
        disposalSiteAttendance.periodStart === undefined ||
        disposalSiteAttendance.periodEnd === undefined
      ) {
        return orderAssignedDisposalSite;
      }

      return {
        ...orderAssignedDisposalSite,
        disposablePeriodStart: disposalSiteAttendance.periodStart,
        disposablePeriodEnd: disposalSiteAttendance.periodEnd,
      };
    });
  }

  onChangeDisposalSiteAssignmentType(value: OrderDisposalSiteAssignmentType): void {
    if (value !== OrderDisposalSiteAssignmentType.PrioritySingle) {
      this.resetDisposalSitePriorities();
    }
    this.disposalSiteAssignmentType = value;
  }

  onCarNumChange(carNum: number): void {
    // 車の数が多くなった場合に、乗務員の数や、同乗者を指定することが不正になる場合がある
    // そのような場合には、乗務員指定をリセットする
    const carNumExceeds = carNum > this.driverNum;
    const invalidOperatorHelperAssignment =
      carNum === this.driverNum &&
      this.assignableDrivers.some((assignableDriver) => assignableDriver.driverType !== DriverType.Driver);
    if (carNumExceeds || invalidOperatorHelperAssignment) {
      // 乗務員数と指定をリセットする
      this.driverNum = carNum;
      this.resetAssignableDrivers();
    }

    // NOTE: 車台数に応じて指定可能な乗務員数を自動的に調整する
    this.driverNumItems = this.numItems.filter((item) => carNum <= item);
    this.orderValidation.resetErrorByField('driver');
  }

  // 乗務員指定をデフォルトに初期化する
  resetAssignableDrivers(): void {
    this.isAssignableDriversCandidate = false;
    this.driverAssignmentType = DriverAssignmentType.NotDistinguished;
    this.assignableDrivers = [];
  }

  // NOTE: 指定処分場に priority が設定されていればリセットする
  resetDisposalSitePriorities(): void {
    this.orderAssignedDisposalSites = [
      ...this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
        return {
          ...orderAssignedDisposalSite,
          priority: undefined,
        };
      }),
    ];
  }

  // assignable drivers validation
  validateCarUsage(): void {
    this.isCarUsageWarned = false;
    this.primaryCarOfAssignableDriver = undefined;
    this.primaryCarTypeOfAssignableDriver = undefined;

    const assignableDriverOrOperators = this.assignableDrivers.filter((assignableDriver) => {
      return this.driverAssignmentType === DriverAssignmentType.Distinguished
        ? assignableDriver.driverType === DriverType.Operator
        : assignableDriver.driverType === DriverType.Driver;
    });

    // NOTE: 指定された乗務員または運転者が1人のときのみ警告を表示する
    // これは乗務員が複数 or 候補の場合には警告が増えすぎる、かつ乗れる車を確認して乗務員を複数指定するケースが多く頻出しない想定のため
    if (!assignableDriverOrOperators || assignableDriverOrOperators.length !== 1) {
      return;
    }

    if (!this.assignableDriverAttendances || this.assignableDriverAttendances.length === 0) {
      return;
    }

    const assignableDriver = assignableDriverOrOperators[0];
    const driverAttendance = this.assignableDriverAttendances.find(
      (driverAttendance) => driverAttendance.driver.id === assignableDriver.driverId
    );
    if (driverAttendance === undefined) {
      return;
    }

    const primaryCar = driverAttendance.primaryCar;
    const primaryCarType = primaryCar.carType;
    const conflictsWithDriverAttendance =
      0 < this.assignableCars.length &&
      this.assignableCars.every((car) => {
        return car.id !== primaryCar.id;
      });
    const carTypeConflicts =
      0 < this.assignableCarTypes.length &&
      this.assignableCarTypes.every((carType) => {
        return carType.id !== primaryCar.carType.id;
      });
    if (conflictsWithDriverAttendance || carTypeConflicts) {
      this.isCarUsageWarned = true;
      this.primaryCarOfAssignableDriver = primaryCar;
      this.primaryCarTypeOfAssignableDriver = primaryCarType;
    }
  }

  onChangeAssignableDrivers(): void {
    this.orderValidation.resetErrorByField('driver');
  }

  // assignable car type
  onChangeAssignableCarTypes(initialization: boolean = false): void {
    if (initialization === false) {
      this.resetTaskRelatedErrors();
      this.orderValidation.resetErrorByField('carType');
      this.orderValidation.resetErrorByReason(InfeasibilityReasons.InconsistentAssignmentsOfCarAndCarType);
    }
    this.validateCarUsage();
  }

  // assignable car
  onChangeAssignableCars(initialization: boolean = false): void {
    if (initialization === false) {
      this.resetTaskRelatedErrors();
      this.orderValidation.resetErrorByField('car');
      this.orderValidation.resetErrorByReason(InfeasibilityReasons.InconsistentAssignmentsOfCarAndCarType);
    }
    this.validateCarUsage();
  }

  onChangeRouteCollectionAllowed(value: boolean): void {
    this.routeCollectionAllowed = value;
  }

  updateRoutingGroup(value: Maybe<IOrderRoutingGroupItem>): void {
    this.routingGroup = value;
  }

  updateDisposalSiteSelection(initialization: boolean = false): void {
    // 処分場を表示するかどうかという事とその上でそれを必須にするかどうかというのは別にコントロールしている。
    // 処分場を表示した上で disabled にしたい場合（コンテナありの車で設置のみの場合）というのがあり、
    // その場合に処分場が必須な状態になっていると form が invalid になってしまい登録ができなくなるため。
    let isDisposalSiteRequired = false;
    if (this.generationSiteTaskCategory === GenerationSiteTaskCategory.TaskWithContainer) {
      this.isDisposalSiteSelectionShown = true;
      isDisposalSiteRequired = true;
    } else if (this.generationSiteTaskCategory === GenerationSiteTaskCategory.Irregular) {
      this.isDisposalSiteSelectionShown = this.irregularTask.skipDisposalSite === false;
      isDisposalSiteRequired = this.isDisposalSiteSelectionShown;
    } else {
      throw new Error('Impossible!');
    }

    // 基本コンテナありのタスクは処分場が必須だが、設置のみのタスクは処分場に寄る必要がないので
    // その場合はここで必須項目ではなくするという事をやっている。また、↑のタスクごとに表示するか
    // しないかを設定しているセクションでコントロールしてしまうとコンテナありの車のタスクからなしの
    // 車のタスクに切り替えた時に disabled のままになり続けてしまうという問題があるのでここで
    // 一括して disabled にするかどうかを設定するという事をやっている。
    // Note: Calling Array.prototype.every() on an empty array will return true for any condition!
    const isEveryGenerationSiteTaskItemTypeAllocate = this.generationSiteTaskItems.every((task) => {
      return (
        task.taskType !== undefined && task.taskType.baseTaskType.name === BaseTaskTypeName.AllocateAtGenerationSite
      );
    });

    if (
      this.generationSiteTaskCategory === GenerationSiteTaskCategory.TaskWithContainer &&
      isEveryGenerationSiteTaskItemTypeAllocate
    ) {
      this.addDisposalSiteDisabledReason(DisabledReason.EveryGenerationSiteTaskTypeIsAllocate);
      isDisposalSiteRequired = false;
    } else {
      this.deleteDisposalSiteDisabledReason(DisabledReason.EveryGenerationSiteTaskTypeIsAllocate);
    }

    if (isDisposalSiteRequired) {
      this.disposalSiteRules = [this.rules.required];
    } else if (initialization === false) {
      // 初期化時はデフォルト値が設定されている可能性があるのでリセットしてはならない
      this.resetDisposalSiteSelection();
    }
  }

  private resetTaskRelatedErrors(): void {
    this.orderValidation.resetErrorByField('task');
    this.orderValidation.resetErrorByReason(InfeasibilityReasons.GenerationSiteRestPeriod);
  }

  private resetGenerationSiteTaskItems(): void {
    this.generationSiteTaskItems.splice(
      0,
      this.generationSiteTaskItems.length,
      ContainerTypeTaskItemFactory.instantiateDefault()
    );
    this.onGenerationSiteTaskChange();

    this.generationSiteTaskDuration.resetHoursAndMinutes();
    this.updateGenerationSiteTaskDuration();
  }

  private resetDisposalSiteSelection(): void {
    this.disposalSiteRules = [];
    this.orderAssignedDisposalSites = [];
    this.disposalSiteAssignmentType = OrderDisposalSiteAssignmentType.NonSequentialMultiple;
    this.onChangeAssignedDisposalSite();
  }

  private resetIrregularTaskItem(): void {
    this.irregularTask = IrregularTaskItemFactory.instantiateDefault();
  }

  private resetAssignableCarTypes(): void {
    this.assignableCarTypeIds = [];
    this.onChangeAssignableCarTypes();
  }

  private resetAssignedCar(): void {
    this.assignableCarIds = [];
    this.onChangeAssignableCars();
  }

  private updateContainerTypes(): void {
    if (this.orderGroupId === undefined) return;
    const carTypes = this.allCarTypes.filter((entity) => entity.orderGroup.id === this.orderGroupId);
    const containerTypeIds = _.flatten(
      carTypes.map((entity) =>
        entity.loadableContainerTypes.map((carTypeContainerType) => carTypeContainerType.containerType.id)
      )
    );
    const containerTypeMap = mapEntity(this.allContainerTypes);
    this.filteredContainerTypes = containerTypeIds.map((id) => containerTypeMap.get(id)!);
  }

  private updateGenerationSiteTask(items: IGenerationSiteTaskItem[]): void {
    this.generationSiteTaskItems = items;
  }

  private updateGenerationSiteTaskDuration(): void {
    this.generationSiteTaskDuration.recalculateDuration(this.generationSiteTaskItems);
  }

  private addDisposalSiteDisabledReason(reason: DisabledReason): void {
    this.disposalSiteDisabledReason.add(reason);
    this.isDisposalSiteDisabled = 1 <= this.disposalSiteDisabledReason.size;
  }

  private deleteDisposalSiteDisabledReason(reason: DisabledReason): void {
    this.disposalSiteDisabledReason.delete(reason);
    this.isDisposalSiteDisabled = 1 <= this.disposalSiteDisabledReason.size;
  }
}
