import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { AggregatedDriverEntity as IDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';

/**
 * Composite な OrderEntity を編集するためのもの
 */
export interface IDriverFormPanel extends IEntityFormPanel<IDriverEntity> {}

export class DriverFormPanel extends AbstractEntityFormPanel<IDriverEntity> implements IDriverFormPanel {}
