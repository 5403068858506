import { ITypedEventContext, TypedEventContext } from '~/framework/events/typedEventContext';
import { IDisposable } from '~/framework/core/disposable';
import { isWeakRef } from '~/framework/core/weakRef';

/**
 * リスナのインターフェースそのもの
 */
export interface Listener<T> {
  (event: T, context: ITypedEventContext): any;
}

/**
 * WeakRef にラップされたリスナ
 */
export type WeakListener<T> = WeakRef<Listener<T>>;

/**
 * リスナそのもの、もしくは WeakRef にラップされたリスナ
 */
export type ListenerLike<T> = Listener<T> | WeakListener<T>;

/**
 * on と once の切り替えがあったり Disposable の仕組みがいいなと思ったので導入する。
 * https://typescript-jp.gitbook.io/deep-dive/main-1/typed-event
 */
export interface ITypedEvent<T> {
  /**
   * リスナを登録する。
   * 返り値の Disposable#dispose を呼ぶ事でリスナを削除できる。
   * いつまでも呼ばれない様に off するか dispose する事を忘れない事。
   * @param listener
   * @param priority リスナーのレイヤー（0 = 最も優先順位が低い）
   */
  on(listener: ListenerLike<T>, priority?: number): IDisposable;

  /**
   * 一度限りで削除されるリスナを登録する。
   * 現状では oncer の方が普通のリスナよりも優先される。
   * @param listener
   * @param priority リスナーのレイヤー（0 = 最も優先順位が低い）
   */
  once(listener: ListenerLike<T>, priority?: number): void;

  /**
   * リスナを削除する。
   * @param listener
   */
  off(listener: ListenerLike<T>): void;

  /**
   * イベントを発火する。
   * @param event
   */
  emit(event: T): void;

  /**
   * このイベントが発火すると別のイベントを発火する様にパイプする。
   * @param another
   */
  pipe(another: TypedEvent<T>): IDisposable;
}

export class TypedEvent<T> implements ITypedEvent<T> {
  private listeners: ListenerLike<T>[] = [];
  private listenersOncer: ListenerLike<T>[] = [];
  private listenerLayerMap: Map<ListenerLike<T>, number> = new Map<ListenerLike<T>, number>();

  on(listener: ListenerLike<T>, priority: number = 0): IDisposable {
    this.listeners.push(listener);
    this.listenerLayerMap.set(listener, priority);
    this.sortListeners(this.listeners);
    return {
      dispose: () => this.off(listener),
    };
  }

  once(listener: ListenerLike<T>, priority: number = 0): void {
    this.listenersOncer.push(listener);
    this.listenerLayerMap.set(listener, priority);
    this.sortListeners(this.listenersOncer);
  }

  off(listener: ListenerLike<T>): void {
    const callbackIndex = this.listeners.indexOf(listener);
    if (-1 < callbackIndex) this.listeners.splice(callbackIndex, 1);
    this.listenerLayerMap.delete(listener);
  }

  emit(event: T): void {
    const context = new TypedEventContext();
    for (const listener of this.listenersOncer) {
      if (context.isStopped === false) this.call(listener, event, context);
      this.listenerLayerMap.delete(listener);
    }
    this.listenersOncer = [];
    if (context.isStopped) return;
    for (const listener of this.listeners) {
      if (context.isStopped === false) this.call(listener, event, context);
      if (context.isStopped) break;
    }
    this.removeInvalidListeners();
  }

  pipe(another: TypedEvent<T>): IDisposable {
    return this.on((e) => another.emit(e));
  }

  private call(listener: ListenerLike<T>, event: T, context: ITypedEventContext): void {
    if (isWeakRef(listener)) {
      const derefedListener = listener.deref();
      if (derefedListener) this.call(derefedListener, event, context);
    } else {
      listener(event, context);
    }
  }

  private sortListeners(listeners: ListenerLike<T>[]): void {
    listeners.sort((a, b) => {
      return this.listenerLayerMap.getOrError(b) - this.listenerLayerMap.getOrError(a);
    });
  }

  /**
   * WeakRef で deref したら中身がないイベントは消してしまう
   * @private
   */
  private removeInvalidListeners(): void {
    const invalidListeners = this.listeners
      .map((listener, index) => {
        return {
          listener,
          index,
        };
      })
      .reverse()
      .filter(({ listener }) => isWeakRef(listener) && listener.deref() === undefined);
    for (const { listener, index } of invalidListeners) {
      this.listeners.splice(index, 1);
      this.listenerLayerMap.delete(listener);
    }
  }
}
