export interface IFixedArrivalTimeReportOption {
  value: boolean;
  label: string;
}

export interface IDefaultIsFixedArrivalTimeReportNeededOption {
  value: DefaultIsFixedArrivalTimeReportNeededOptionsValues;
  label: string;
}

export enum DefaultIsFixedArrivalTimeReportNeededOptionsValues {
  Needed = 'Needed',
  NotNeeded = 'NotNeeded',
  NotSet = 'NotSet',
}

/**
 * 到着予定時刻の報告の選択肢
 */
export const fixedArrivalTimeReportOptions: IFixedArrivalTimeReportOption[] = [
  { value: true, label: '必要' },
  { value: false, label: '不要' },
];

/**
 * value から label を引ける様にしたもの
 */
export const fixedArrivalTimeReportOptionMap = new Map<
  IFixedArrivalTimeReportOption['value'],
  IFixedArrivalTimeReportOption['label']
>(fixedArrivalTimeReportOptions.map((option) => [option.value, option.label]));

/**
 * Labels and values for default is fixed arrival time report needed radio group UI
 */
export const getDefaultIsFixedArrivalTimeReportNeededOptions = (
  defaultIsFixedArrivalTimeReportNeeded: boolean
): IDefaultIsFixedArrivalTimeReportNeededOption[] => {
  const defaultValue = fixedArrivalTimeReportOptionMap.get(defaultIsFixedArrivalTimeReportNeeded);
  if (!defaultValue) throw new Error('Impossible!');

  return [
    { value: DefaultIsFixedArrivalTimeReportNeededOptionsValues.Needed, label: '必要' },
    { value: DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotNeeded, label: '不要' },
    { value: DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet, label: `ﾃﾞﾌｫﾙﾄ(${defaultValue})` },
  ];
};

/**
 * This map is used to map enum to api values when sending data to server.
 */
export const defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap = new Map<
  DefaultIsFixedArrivalTimeReportNeededOptionsValues,
  boolean | undefined
>();
defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap.set(
  DefaultIsFixedArrivalTimeReportNeededOptionsValues.Needed,
  true
);
defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap.set(
  DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotNeeded,
  false
);
defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap.set(
  DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet,
  undefined
);

/**
 * This map is used to restore data in form with data received from server.
 */
export const defaultIsFixedArrivalTimeReportNeededApiValueToEnumMap = new Map<
  boolean | undefined,
  DefaultIsFixedArrivalTimeReportNeededOptionsValues
>();
defaultIsFixedArrivalTimeReportNeededApiValueToEnumMap.set(
  true,
  DefaultIsFixedArrivalTimeReportNeededOptionsValues.Needed
);
defaultIsFixedArrivalTimeReportNeededApiValueToEnumMap.set(
  false,
  DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotNeeded
);
defaultIsFixedArrivalTimeReportNeededApiValueToEnumMap.set(
  undefined,
  DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet
);
