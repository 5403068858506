import {
  MutationResolvers,
  QueryResolvers,
  SampleLocalState,
  SampleLocalStateNodeQuery,
  SampleLocalStateNodeQueryVariables,
} from '@/graphql/graphQLLocalState';
import { SampleLocalStateNode } from '@/../graphql/local-state/queries/sample.gql';
import { DataProxy } from 'apollo-cache';

export function setCacheDefaultValue(cache: DataProxy): void {
  const data: SampleLocalState = {
    __typename: 'SampleLocalState',
    id: '1',
    value1: 'initial value',
    value2: 'initial value',
    value3: 'initial value',
  };
  cache.writeData<SampleLocalStateNodeQuery>({ data: { sampleLocalState: data } });
}

type SampleLocalStateMutationResolvers = Pick<MutationResolvers, 'mutateSampleLocalState'>;

export const Mutations: SampleLocalStateMutationResolvers = {
  mutateSampleLocalState: (_parent, args, context, _info) => {
    const currentValue = context.cache.readQuery<SampleLocalStateNodeQuery, SampleLocalStateNodeQueryVariables>({
      query: SampleLocalStateNode,
    });
    if (currentValue != null) {
      // 意図しないデータ書き換えが発生する事を防ぐためにクエリ結果は freeze されている
      // なのでここではあえて新しいインスタンスを生成している
      // 将来的にはもう少し楽な方法で mutate させられる様に考える余地はあり
      const newValue: SampleLocalStateNodeQuery = {
        sampleLocalState: {
          __typename: 'SampleLocalState',
          id: currentValue.sampleLocalState.id,
          value1: args.value1 ?? currentValue.sampleLocalState.value1,
          value2: args.value1 ?? currentValue.sampleLocalState.value2,
          value3: args.value1 ?? currentValue.sampleLocalState.value3,
        },
      };
      context.cache.writeQuery<SampleLocalStateNodeQuery>({
        query: SampleLocalStateNode,
        data: newValue,
      });
      return true;
    }
    return false;
  },
};

type SampleLocalStateQueryResolvers = Pick<QueryResolvers, 'sampleLocalState'>;

export const Queries: SampleLocalStateQueryResolvers = {
  sampleLocalState: (_parent, _args, _context, _info) => {
    throw new Error(`not implemented yet!`);
  },
};
