
import Vue, { PropType } from 'vue';
import { dateToYymmdd } from '~/framework/services/date/date';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  value: boolean;
  isDeleteCandidateDate: boolean;
  resolver: Maybe<(value: Maybe<boolean>) => void>;
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'RPlanCandidateDatesDeleteDialog',
  props: {
    editScheduleDate: {
      type: Date as PropType<Date>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      value: false,
      isDeleteCandidateDate: false,
      resolver: undefined,
      listenerDisposer: undefined,
    };
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    dateToYymmdd,
    open(): Promise<Maybe<boolean>> {
      return new Promise((resolve) => {
        this.resolver = resolve;
        this.value = true;
      });
    },
    onClickComplete(): void {
      if (this.resolver === undefined) throw new Error(`resolver has not been set! impossible.`);
      this.value = false;
      this.resolver(this.isDeleteCandidateDate);
    },
    onClickClose(): void {
      if (this.resolver === undefined) throw new Error(`resolver has not been set! impossible.`);
      this.value = false;
      this.resolver(undefined);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        Vue.nextTick(this.onClickClose);
      }
      context.stop();
    },
  },
});
