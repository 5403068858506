
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import { PreloadStatus } from '@/graphql/graphQLServerApi';
import { formatDateForField } from '~/framework/services/date/date';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import RDateCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RDateCollectablePeriodInput.vue';
import { Maybe, PersistentId, ValidationRule } from '~/framework/typeAliases';
import { ICollectablePeriodTemplateOption } from '~/framework/view-models/collectablePeriodTemplateOption';
import { IBusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { greaterThanZero, max } from '~/framework/view-models/rules';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  value: boolean;
  dateCollectablePeriodItemValues: DateCollectablePeriodItem[];
  PreloadStatus: typeof PreloadStatus;
  listenerDisposer: Maybe<() => void>;
  registerCount: number;
  rules: { [key: string]: ValidationRule };
};

enum EventTypes {
  Input = 'input',
  ClickComplete = 'click:complete',
  UpdateDate = 'update:date',
  UpdateUnloadDate = 'update:unload-date',
  UpdateCollectablePeriodTemplateId = 'update:collectable-period-template-id',
  UpdateCollectablePeriodTemplateName = 'update:collectable-period-template-name',
  UpdateCollectableDistinctTime = 'update:collectable-distinct-time',
  UpdateCollectablePeriodStart = 'update:collectable-period-start',
  UpdateCollectablePeriodEnd = 'update:collectable-period-end',
}

export default Vue.extend({
  name: 'RDateCollectablePeriodBatchRegisterDialog',
  components: {
    RDateCollectablePeriodInput,
  },
  model: {
    prop: 'value',
    event: EventTypes.Input,
  },
  props: {
    dateCollectablePeriodItems: {
      type: Array as PropType<DateCollectablePeriodItem[]>,
      required: true,
    },
    collectablePeriodTemplates: {
      type: Array as PropType<ICollectablePeriodTemplateOption[]>,
      required: true,
    },
    collectablePeriodTemplateMap: {
      type: Map as PropType<Map<PersistentId, CollectablePeriodTemplateEntity>>,
      required: true,
    },
    preloadStatus: {
      type: String as PropType<PreloadStatus>,
      required: false,
      default: PreloadStatus.NotAllowed,
    },
    isCarNumDisabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    carNumDisabledReason: {
      type: String as PropType<string>,
      required: true,
    },
    defaultCarNum: {
      type: Number as PropType<number>,
      required: true,
    },
    carNumItems: {
      type: Array as PropType<number[]>,
      required: true,
    },
    businessDaysService: {
      type: Object as PropType<IBusinessDaysService>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      value: false,
      dateCollectablePeriodItemValues: [],
      PreloadStatus,
      listenerDisposer: undefined,
      registerCount: 1,
      rules: {
        greaterThanZero,
        max99: max(99),
      },
    };
  },
  computed: {
    isDeleteButtonDisplayed(): boolean {
      return this.dateCollectablePeriodItemValues.length > 1;
    },
    isCompleteButtonDisabled(): boolean {
      return this.dateCollectablePeriodItemValues.every((item) => item.isCollectableTimeEmpty);
    },
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    formatDateForField,
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.close();
      }
      context.stop();
    },
    open(): void {
      const cloned = _.cloneDeep(this.dateCollectablePeriodItems);

      // NOTE: carNum が設定されていなければ、デフォルト値をセットする
      cloned.forEach((item) => {
        item.carNum = item.carNum ?? this.defaultCarNum;
      });

      this.dateCollectablePeriodItemValues = cloned;

      this.value = true;
      this.registerCount = 1;
    },
    close(): void {
      this.value = false;
      this.$emit(EventTypes.Input, this.value);
    },
    onCompleteButtonClicked(): void {
      // 到着日時が未入力のものを削除する
      this.dateCollectablePeriodItemValues = this.dateCollectablePeriodItemValues.filter(
        (item) => !item.isCollectableDateEmpty && !item.isCollectableTimeEmpty
      );
      this.$emit(EventTypes.ClickComplete, _.cloneDeep(this.dateCollectablePeriodItemValues));
      this.close();
    },
    onCloseButtonClicked(): void {
      this.close();
    },
    async onAddItemButtonClicked(): Promise<void> {
      const last = _.last(this.dateCollectablePeriodItemValues);
      if (last === undefined) throw new Error(`Impossible!`);
      const registerCount = this.registerCount || 1;
      for (let i = 0; i < registerCount; i++) {
        // 日付は常に引き継ぐようにする
        // see: https://fanfare-inc.atlassian.net/browse/HGB-1647?focusedCommentId=12777
        this.dateCollectablePeriodItemValues.push(last.clone(false));
      }
      await this.$nextTick();
      const content = this.$refs.content as HTMLElement;
      content.scrollTop = content.scrollHeight;
    },
    onDeleteItemButtonClicked(id: string) {
      const index = this.dateCollectablePeriodItemValues.findIndex((item) => item.id === id);
      this.dateCollectablePeriodItemValues.splice(index, 1);
    },
    onChangeDate(value: Date): void {
      this.$emit(EventTypes.UpdateDate, value);
    },
    onChangeUnloadDate(value: string) {
      this.$emit(EventTypes.UpdateUnloadDate, value);
    },
    onChangeCollectablePeriodTemplate(args: any): void {
      this.$emit(EventTypes.UpdateCollectablePeriodTemplateId, args);
    },
    onChangeCollectableDistinctTime(value: Maybe<string>): void {
      this.$emit(EventTypes.UpdateCollectableDistinctTime, value);
    },
    onChangeCollectablePeriodStart(value: Maybe<string>): void {
      this.$emit(EventTypes.UpdateCollectablePeriodStart, value);
    },
    onChangeCollectablePeriodEnd(value: Maybe<string>): void {
      this.$emit(EventTypes.UpdateCollectablePeriodEnd, value);
    },
  },
});
