import { Maybe } from '~/framework/typeAliases';

export class Clipboard {
  isClipboardAvailable(): boolean {
    return !!navigator.clipboard;
  }

  /**
   * クリップボードの内容を取り出す
   * クリップボードが使えない環境だった場合は undefined となる
   */
  async readText(): Promise<Maybe<string>> {
    if (this.isClipboardAvailable() === false) return undefined;
    try {
      return await navigator.clipboard.readText();
    } catch (e) {
      // 権限ダイアログで蹴られた場合にはエラーが吐かれる

      return undefined;
    }
  }

  /**
   * クリップボードにテキストをコピーする
   * 成功した場合は true を返す
   * クリップボードが使えない環境だった場合は false を返す
   */
  async copyText(text: string): Promise<boolean> {
    if (this.isClipboardAvailable() === false) return false;
    try {
      await navigator.clipboard.writeText(text);

      return true;
    } catch (e: any) {
      // NOTE: たまに navigator.clipboard.writeText が失敗して throw されることがあるので、その場合は代替手段を使う
      // NOTE: 一時的に引数を受け取るために textarea を作る
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();

      // NOTE: 非推奨だが、代替手段として仕方なく execCommand を使う
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);

      return successful;
    }
  }
}
