import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { version$getVersionSymbol } from '~/framework/server-api/version/getVersion';
import { Maybe } from '~/framework/typeAliases';

export const versionSymbol = Symbol('versionSymbol');

export class VersionApplicationService {
  private readonly serverApis: ServerApiManager;

  constructor(serverApis: ServerApiManager) {
    this.serverApis = serverApis;
  }

  async hasNewVersion(rinVersion: Maybe<string>): Promise<boolean> {
    const version$getVersionApi = this.serverApis.get(version$getVersionSymbol);
    const version = await version$getVersionApi.getVersion();
    return version !== rinVersion;
  }
}
