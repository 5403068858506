import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import overtimeWorkTypeMap from '~/assets/settings/overtimeWorkType.json';

/**
 * 残業の選択肢のインターフェース
 */
export interface IOvertimeWorkTypeItem {
  value: OvertimeWorkType;
  text: string;
}

/**
 * 残業の選択肢
 */
export const overtimeWorkTypeItems: IOvertimeWorkTypeItem[] = [
  {
    text: overtimeWorkTypeMap[OvertimeWorkType.None],
    value: OvertimeWorkType.None,
  },
  {
    text: overtimeWorkTypeMap[OvertimeWorkType.Both],
    value: OvertimeWorkType.Both,
  },
  {
    text: overtimeWorkTypeMap[OvertimeWorkType.AvailableInEarlyTime],
    value: OvertimeWorkType.AvailableInEarlyTime,
  },
  {
    text: overtimeWorkTypeMap[OvertimeWorkType.AvailableInLateTime],
    value: OvertimeWorkType.AvailableInLateTime,
  },
];

/**
 * 残業の選択肢のマップ
 */
export const overtimeWorkTypeItemsMap = new Map<IOvertimeWorkTypeItem['value'], IOvertimeWorkTypeItem['text']>(
  overtimeWorkTypeItems.map((item) => [item.value, item.text])
);

/**
 * 休憩時間の有無
 */
export interface IHasRestPeriodItem {
  readonly value: boolean;
  readonly text: string;
}

/**
 * 休憩時間の有無の選択肢
 */
export const hasRestPeriodItems: IHasRestPeriodItem[] = [
  { text: '休憩無', value: false },
  { text: '休憩有', value: true },
];

/**
 * 休憩時間の有無の選択肢のマップ
 */
export const hasRestPeriodMap = new Map<IHasRestPeriodItem['value'], IHasRestPeriodItem['text']>(
  hasRestPeriodItems.map((item) => [item.value, item.text])
);
