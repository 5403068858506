import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { IDriverAttendanceTemplateData } from '~/framework/server-api/masters/driverAttendanceTemplate';
import { Maybe } from '~/framework/typeAliases';

export class DriverAttendanceTemplateEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
  defaultForceRidePrimaryCar: boolean;

  constructor(data: IDriverAttendanceTemplateData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.regularWorkPeriodStart = data.regularWorkPeriodStart;
    this.regularWorkPeriodEnd = data.regularWorkPeriodEnd;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.overtimeWorkType = data.overtimeWorkType;
    this.overtimeWorkableDuration = data.overtimeWorkableDuration;
    this.defaultForceRidePrimaryCar = data.defaultForceRidePrimaryCar;
  }

  update(data: IDriverAttendanceTemplateData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of DriverAttendanceTemplateEntity');
    }
    this.name = data.name;
    this.regularWorkPeriodStart = data.regularWorkPeriodStart;
    this.regularWorkPeriodEnd = data.regularWorkPeriodEnd;
    this.restPeriodStart = data.restPeriodStart;
    this.restPeriodEnd = data.restPeriodEnd;
    this.overtimeWorkType = data.overtimeWorkType;
    this.overtimeWorkableDuration = data.overtimeWorkableDuration;
    this.defaultForceRidePrimaryCar = data.defaultForceRidePrimaryCar;
  }
}
