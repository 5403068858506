import { Maybe, PersistentId } from '~/framework/typeAliases';
import OrderErrorMessages from '~/assets/settings/orderErrorMessages.json';
import {
  InfeasibilityCauses,
  InfeasibilityDriverReasons,
  InfeasibilityReasons,
  RawInfeasibilityJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { AggregatedCarEntity } from '../domain/masters/car/aggregatedCarEntity';

type ValidationErrors = {
  /**
   * 乗務員に関するエラー
   */
  driver: string[];

  /**
   * 車番に関するエラー
   */
  car: string[];

  /**
   * 車種に関するエラー
   */
  carType: string[];

  /**
   * 排出場に関するエラー
   */
  generationSite: string[];

  /**
   * 処分場に関するエラー
   */
  disposalSite: string[];

  /**
   * 処分場の到着時間に関するエラー
   */
  disposablePeriod: string[];

  /**
   * 現場タスク（コンテナあり、その他）に関するエラー
   */
  task: string[];

  /**
   * 到着時間に関するエラー
   */
  collectablePeriod: string[];
};

export interface IOrderValidation {
  /**
   * バリデーションを行った結果、どの様なエラーがあったか
   * バリデーション前では全て空配列になっている
   */
  errors: ValidationErrors;

  /**
   * 一つでもエラーがあったかどうかを確認する
   */
  hasErrors: boolean;

  /**
   * フィールドを与えてエラーをリセットする
   * @param field
   */
  resetErrorByField(field: keyof ValidationErrors): void;

  /**
   * InfeasibilityReasons を与えてエラーをリセットする
   * @param reason
   */
  resetErrorByReason(reason: InfeasibilityReasons): void;

  /**
   * エラーの内容を全てリセットする
   */
  resetErrors(): void;

  /**
   * infeasibility を与えて、エラーの内容をセットする
   * @param driverId フォーム側で指定しているならその乗務員の ID
   * @param cars フォーム側で指定しているならその車番
   * @param infeasibilities
   */
  setErrorsByInfeasibilities(
    driverIds: PersistentId[],
    cars: AggregatedCarEntity[],
    infeasibilities: Maybe<RawInfeasibilityJsonObject[]>
  ): void;
}

// NOTE: InfeasibilityReasons がどのリソースに関するエラーであるかを mapping している
const reasonFieldMap: Map<InfeasibilityReasons, keyof ValidationErrors> = new Map<
  InfeasibilityReasons,
  keyof ValidationErrors
>([
  [InfeasibilityReasons.InconsistentAssignmentsOfCarAndCarType, 'car'],
  [InfeasibilityReasons.AssignedCarDoesNotExist, 'car'],
  [InfeasibilityReasons.CarWithAssignedCarTypeDoesNotExist, 'carType'],
  [InfeasibilityReasons.AssignedGenerationSiteDoesNotExist, 'generationSite'],
  [InfeasibilityReasons.AssignedDisposalSiteDoesNotExist, 'disposalSite'],
  [InfeasibilityReasons.PreloadOrderMustHaveAssignedDisposalSite, 'disposalSite'],
  [InfeasibilityReasons.PreloadTaskMustBeFetch, 'task'],
  [InfeasibilityReasons.PreloadTaskMustNotBeAllocate, 'task'],
  [InfeasibilityReasons.AssignedCarCannotAcceptTasks, 'car'],
  [InfeasibilityReasons.CarWithAssignedCarTypeCannotAcceptTasks, 'carType'],
  [InfeasibilityReasons.NoCarCanAcceptTasks, 'task'],
  [InfeasibilityReasons.NoBaseCanSupplyRequiredContainers, 'task'],
  [InfeasibilityReasons.NoBaseCanSupplyNecessaryTransformingContainer, 'task'],
  [InfeasibilityReasons.AssignedDisposalSiteCannotAcceptWaste, 'disposalSite'],
  [InfeasibilityReasons.NoDisposalSiteCanAcceptWaste, 'disposalSite'],
  [InfeasibilityReasons.IncompatibleSitePeriods, 'disposablePeriod'],
  [InfeasibilityReasons.GenerationSiteRestPeriod, 'collectablePeriod'],
  [InfeasibilityReasons.UnavailableDisposalSite, 'disposablePeriod'],
  [InfeasibilityReasons.disposalSitePeriod, 'disposablePeriod'],
  [InfeasibilityReasons.DisposalSiteRestPeriod, 'disposablePeriod'],
]);

const driverReasonFieldMap: Map<InfeasibilityDriverReasons, keyof ValidationErrors> = new Map<
  InfeasibilityDriverReasons,
  keyof ValidationErrors
>([
  [InfeasibilityDriverReasons.InvalidPrimaryCar, 'driver'],
  [InfeasibilityDriverReasons.BannedByGenerationSite, 'driver'],
  [InfeasibilityDriverReasons.BannedByAssignedDisposalSite, 'driver'],
  [InfeasibilityDriverReasons.BannedByAllDisposableSites, 'driver'],
  [InfeasibilityDriverReasons.CollectablePeriodAndRestPeriodOverlap, 'driver'],
  [InfeasibilityDriverReasons.CannotKeepCollectablePeriod, 'driver'],
  [InfeasibilityDriverReasons.CannotKeepDisposablePeriod, 'driver'],
  [InfeasibilityDriverReasons.CannotKeepEndOfWorkablePeriod, 'driver'],
]);

export class OrderValidation implements IOrderValidation {
  private readonly reasons: Set<InfeasibilityReasons>;
  private readonly driverReasons: Set<InfeasibilityDriverReasons>;

  errors: ValidationErrors;

  hasErrors: boolean;

  constructor() {
    this.reasons = new Set<InfeasibilityReasons>();
    this.driverReasons = new Set<InfeasibilityDriverReasons>();
    this.errors = this.getEmptyErrors();
    this.hasErrors = false;
  }

  resetErrorByField(error: keyof ValidationErrors): void {
    // この for のやり方はなかなか無駄なのだが数が少ないのと逆のマップを作るのはやや冗長なのでよしとする
    for (const [key, value] of reasonFieldMap) {
      if (error === value) this.reasons.delete(key);
    }
    for (const [key, value] of driverReasonFieldMap) {
      if (error === value) this.driverReasons.delete(key);
    }
    this.updateErrors();
  }

  resetErrorByReason(reason: InfeasibilityReasons) {
    this.reasons.delete(reason);
    this.updateErrors();
  }

  resetErrors(): void {
    this.reasons.clear();
    this.driverReasons.clear();
    this.updateErrors();
  }

  // NOTE: viewModel にエラーをセットする
  setErrorsByInfeasibilities(
    driverIds: PersistentId[],
    _cars: AggregatedCarEntity[],
    infeasibilities: Maybe<RawInfeasibilityJsonObject[]>
  ): void {
    this.resetErrors();
    if (!infeasibilities) return;
    for (const infeasibility of infeasibilities) {
      if (
        infeasibility.cause === InfeasibilityCauses.Order ||
        infeasibility.cause === InfeasibilityCauses.OrderOrMaster
      ) {
        if (infeasibility.reasons) {
          for (const reason of infeasibility.reasons) {
            this.reasons.add(reason);
          }
        }
        if (infeasibility.driver_reasons) {
          for (const driverReason of infeasibility.driver_reasons) {
            if (!driverIds.includes(driverReason.driver_id.toPseudoId().value)) continue;
            for (const reason of driverReason.reasons) {
              this.driverReasons.add(reason);
            }
          }
        }
      }
    }
    this.updateErrors();
  }

  // NOTE: エラーメッセージをセットする
  private updateErrors(): void {
    this.errors = this.getEmptyErrors();
    for (const reason of this.reasons) {
      this.errors[reasonFieldMap.getOrError(reason)].push(OrderErrorMessages.reasons[reason]);
    }
    for (const reason of this.driverReasons) {
      this.errors[driverReasonFieldMap.getOrError(reason)].push(OrderErrorMessages.driverReasons[reason]);
    }
    this.updateHasErrors();
  }

  private getEmptyErrors(): ValidationErrors {
    return {
      driver: [],
      car: [],
      carType: [],
      generationSite: [],
      disposalSite: [],
      disposablePeriod: [],
      task: [],
      collectablePeriod: [],
    };
  }

  private updateHasErrors(): void {
    this.hasErrors =
      0 < this.errors.driver.length ||
      0 < this.errors.car.length ||
      0 < this.errors.carType.length ||
      0 < this.errors.generationSite.length ||
      0 < this.errors.disposalSite.length ||
      0 < this.errors.disposablePeriod.length ||
      0 < this.errors.task.length ||
      0 < this.errors.collectablePeriod.length;
  }
}
