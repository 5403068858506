export interface IHeaderDayColumn {
  label: string;
  isHoliday: boolean;
}

export class HeaderDayColumn implements IHeaderDayColumn {
  label: string;
  isHoliday: boolean;

  constructor(label: string, isHoliday: boolean) {
    this.label = label;
    this.isHoliday = isHoliday;
  }
}
