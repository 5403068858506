
import Vue, { PropType } from 'vue';
import { VRadio } from 'vuetify/lib';

export default (VRadio as typeof Vue & typeof VRadio).extend({
  name: 'RVRadio',
  props: {
    autofocus: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
  },
  mounted(): void {
    if (this.autofocus === true) {
      const input = this.$refs.input as any;
      if (input !== undefined && typeof input.focus === 'function') input.focus();
    }
  },
});
