import { WasteCategory } from '~/framework/domain/typeAliases';
import wasteCategoryName from '~/assets/settings/wasteCategoryName.json';

export interface IWasteCategory {
  label: string;
  value: WasteCategory;
}

export const wasteCategories: IWasteCategory[] = Object.entries(WasteCategory).map(([, value]) => ({
  label: wasteCategoryName[value],
  value,
}));
