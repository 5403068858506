import { Site } from './site';
import { TimelineEventJsonObject, TimelineEventType } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';
import { Maybe } from '~/framework/typeAliases';

export class TimelineEvent {
  eventType: TimelineEventType;
  time: number;
  site: Maybe<Site>;
  constructor(timelineEvent: TimelineEventJsonObject) {
    this.eventType = timelineEvent.event_type;
    this.time = timelineEvent.time;
    this.site = timelineEvent.site ? new Site(timelineEvent.site) : undefined;
  }

  getTimelineEventJsonObject(): TimelineEventJsonObject {
    return {
      event_type: this.eventType,
      time: this.time,
      site: this.site?.getSiteJsonObject(),
    };
  }
}
