import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { IBaseSiteData } from '~/framework/server-api/masters/baseSite';
import { AbstractSiteEntity } from '../abstractSiteEntity';

export class AggregatedBaseSiteEntity extends AbstractSiteEntity {
  hasCars: boolean;
  hasContainers: boolean;

  constructor(data: IBaseSiteData, createdBy: UserEntity) {
    super(data, createdBy);
    this.hasCars = data.hasCars;
    this.hasContainers = data.hasContainers;
  }

  update(data: IBaseSiteData, createdBy: UserEntity) {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedBaseSiteEntity');
    }
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.zipCode = data.zipCode;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.address3 = data.address3;
    this.address4 = data.address4;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.hasCars = data.hasCars;
    this.hasContainers = data.hasContainers;
    this.createdAt = data.createdAt;
    this.createdBy = createdBy;
  }
}
