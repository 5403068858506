import { MapperBase } from '~/framework/core/mapper';
import { CheckItemEntity } from '~/framework/domain/masters/check-item/checkItemEntity';
import { CheckItem } from '~/graphql/graphQLServerApi';

export class CheckItemMapper extends MapperBase<CheckItem, CheckItemEntity> {
  constructor() {
    super();
  }

  protected instantiateWithData(data: CheckItem): CheckItemEntity {
    return new CheckItemEntity(data);
  }

  protected updateWithData(data: CheckItem, entity: CheckItemEntity): void {
    // Entityの中でupdate関数を定義したいが、checkItemEntityをobject的に使っている実装があり、
    // updateをCheckItemEntity内に定義するとビルドができなくなるので、ここに実装する
    // TODO: CheckItemEntityをEntityっぽい実装にリファクタリングする
    entity.name = data.name;
    entity.default = data.default;
    entity.status = data.status;
  }
}
