import {
  AllDisposalSitesQuery,
  AllDisposalSitesQueryVariables,
  CreateDisposalSitesMutation,
  CreateDisposalSitesMutationVariables,
  UpdateDisposalSitesMutation,
  UpdateDisposalSitesMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllDisposalSites } from '@/../graphql/server-api/queries/disposal-site.gql';
import { CreateDisposalSites, UpdateDisposalSites } from '@/../graphql/server-api/mutations/disposal-site.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IDisposalSiteCreateData, IDisposalSiteUpdateData } from '~/framework/server-api/masters/disposalSite';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllDisposalSitesQuery, AllDisposalSitesQueryVariables>({
      query: AllDisposalSites,
      variables: {},
    });
    return result.allDisposalSites;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: IDisposalSiteCreateData[]) {
    const result = await this.mutate<CreateDisposalSitesMutation, CreateDisposalSitesMutationVariables>({
      mutation: CreateDisposalSites,
      variables: { disposalSites: data },
    });
    this.validateArrayConsistency(data, result.createDisposalSites, `CreateDisposalSites`);
    return result.createDisposalSites;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IDisposalSiteUpdateData[]) {
    const result = await this.mutate<UpdateDisposalSitesMutation, UpdateDisposalSitesMutationVariables>({
      mutation: UpdateDisposalSites,
      variables: { disposalSites: data },
    });
    this.validateArrayConsistency(data, result.updateDisposalSites, `UpdateDisposalSites`);
    return result.updateDisposalSites;
  }
}
