
import Vue, { PropType } from 'vue';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { hankanize } from '~/framework/services/string/string';

enum EventTypes {
  OpenEditDialog = 'open:edit-dialog',
}

export default Vue.extend({
  name: 'RDisposalSiteSequentialMultipleAssignment',
  props: {
    selectedDisposalSites: {
      type: Array as PropType<Array<AggregatedDisposalSiteEntity>>,
      required: true,
    },
  },
  computed: {
    displayEntities(): Array<AggregatedDisposalSiteEntity> {
      if (2 < this.selectedDisposalSites.length) return this.selectedDisposalSites.slice(0, 2);

      return this.selectedDisposalSites;
    },
  },
  methods: {
    hankanize,
    openEditDialog() {
      this.$emit(EventTypes.OpenEditDialog);
    },
  },
});
