import { Maybe } from '~/framework/typeAliases';
import { ScheduleStatus } from '~/framework/server-api/schedule/schedule/schedule';
import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { ScheduleResponseJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export interface IScheduleEntityData {
  id: string;
  attendanceId: string;
  orderGroupId: string;
  status: ScheduleStatus;
  creatingScheduleStartedAt: Maybe<Date>;
  scheduleData?: undefined;
  scheduleResponse: Maybe<ScheduleResponseJsonObject>;
  isPublished: Boolean;
}

export interface IScheduleEntity extends IEntity, IPersistentEntity {
  attendanceId: string;
  orderGroupId: string;
  status: ScheduleStatus;
  creatingScheduleStartedAt: Maybe<Date>;
  scheduleData?: undefined;
  scheduleResponse: Maybe<ScheduleResponseJsonObject>;
  isPublished: Boolean;
}

export class ScheduleEntity implements IScheduleEntity {
  readonly id: string;
  readonly persistentId: string;
  attendanceId: string;
  orderGroupId: string;
  status: ScheduleStatus;
  creatingScheduleStartedAt: Maybe<Date>;
  scheduleData?: undefined;
  scheduleResponse: Maybe<ScheduleResponseJsonObject>;
  isPublished: Boolean;

  constructor(
    id: string,
    attendanceId: string,
    orderGroupId: string,
    status: ScheduleStatus,
    creatingScheduleStartedAt: Maybe<Date>,
    scheduleData: undefined,
    scheduleResponse: Maybe<ScheduleResponseJsonObject>,
    isPublished: Boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.attendanceId = attendanceId;
    this.orderGroupId = orderGroupId;
    this.status = status;
    this.creatingScheduleStartedAt = creatingScheduleStartedAt;
    this.scheduleData = scheduleData;
    this.scheduleResponse = scheduleResponse;
    this.isPublished = isPublished;
  }
}
