
import Vue from 'vue';

// Simple container component which adjusts the width
// as per the 'visible' viewport width computed by framework
// This wrapper takes in account the following things:
// navigation bar width, panel width, and the margin applied to the page container (16px)
// Use this for components which need to be squeezed to the 'visible' viewport width
// Typical use-case would be items which should not be hidden when side panel is open
export default Vue.extend({
  name: 'RSqueezable',
});
