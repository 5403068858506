
import Vue, { PropType } from 'vue';
import { formatDateForField } from '~/framework/services/date/date';
import { PreloadStatus } from '~/framework/domain/typeAliases';

export default Vue.extend({
  name: 'RScheduleOrderPreloadStatus',
  props: {
    preloadStatus: {
      type: String as PropType<PreloadStatus>,
      required: true,
    },
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    unloadDate: {
      type: Date as PropType<Date | undefined>,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      PreloadStatus,
    };
  },
  methods: {
    formatDateForField,
  },
});
