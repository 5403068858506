import { StoredMapperBase } from '~/framework/core/mapper';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { IPackingStyleData } from '~/framework/server-api/masters/packingStyle';

export class PackingStyleMapper extends StoredMapperBase<IPackingStyleData, PackingStyleEntity> {
  protected store: PackingStyleStore;

  constructor(store: PackingStyleStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IPackingStyleData): PackingStyleEntity {
    return new PackingStyleEntity(data);
  }

  protected updateWithData(data: IPackingStyleData, entity: PackingStyleEntity): void {
    entity.update(data);
  }
}
