import ja from 'date-fns/locale/ja';
import { format, getDay, parse, Locale } from 'date-fns';

import { DateString } from '~/framework/typeAliases';
import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';

/**
 * ISO8601 の年月日だけのフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale = ja
 */
export const formatDateToString = (date: Date, locale: Locale = ja): DateString => {
  return format(date, 'yyyy-MM-dd', { locale });
};

/**
 * ISO8601 のフォーマットの文字列を Date で返す
 * 標準では ja 基準になる
 *
 * @param dateString
 * @param locale = ja
 */
export const parseStringToDate = (dateString: DateString, locale: Locale = ja) => {
  return parse(dateString, 'yyyy-MM-dd', new Date(), { locale });
};

/**
 * 人間が読める年月日だけのフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale = ja
 */
export const dateToYymmdd = (date: Date, locale: Locale = ja): string => {
  return format(date, 'yyyy/MM/dd', { locale });
};

/**
 * 人間が読める月日だけのフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale = ja
 */
export const dateToMmdd = (date: Date, locale: Locale = ja): string => {
  return format(date, 'MM/dd', { locale });
};

/**
 * 人間が読める月日(曜日)に 03/01 (月) の様なフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale = ja
 */
export const dateToMmDdDaysOfWeek = (date: Date, locale: Locale = ja): string => {
  return `${format(date, 'MM/dd', { locale })} (${daysOfWeekMap[getDay(date)]})`;
};

/**
 * 人間が読める月日だけのフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale = ja
 */
export const dateToMd = (date: Date, locale: Locale = ja): string => {
  return format(date, 'M/d', { locale });
};

/**
 * 人間が読める年月日(曜日)に 2021/03/01 (月) の様なフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale
 */
export const dateToYyMdDaysOfWeek = (date: Date, locale: Locale = ja): string => {
  return `${format(date, 'yyyy/M/d', { locale })} (${daysOfWeekMap[getDay(date)]})`;
};

/**
 * 受注日時などを選択させるフィールド用に 21/03/01 月 の様なフォーマットで返す
 * 標準では ja 基準になる
 *
 * @param date
 * @param locale
 */
export const formatDateForField = (date: Date, locale: Locale = ja) => {
  return format(date, 'yy/MM/dd', { locale }) + ' ' + daysOfWeekMap[getDay(date)];
};
