
import Vue, { PropType } from 'vue';
import { parseISO } from 'date-fns';
import { DayOfWeek, Maybe } from '~/framework/typeAliases';
import { dateFormatter } from '~/framework/view-models/vuetify';
import { formatDateToString } from '~/framework/services/date/date';
import { WeekDatesCalendar } from '~/framework/services/calendar/weekDatesCalendar';
import {
  AdditionalInfoKeys,
  RinEventNames,
  RinEventPageNameTypes,
} from '~/framework/services/rin-events/rinEventParams';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';

type DataType = {
  weekDatesCalendar: WeekDatesCalendar;
  isDatePickerActive: boolean;
};

enum Events {
  updateDates = 'update:dates',
}

export type UpdateDateEventPayload = {
  start: Date;
  end: Date;
  selectedDate: Maybe<Date>;
};

export default Vue.extend({
  name: 'RWeekCalendar',
  props: {
    startOfWeek: {
      type: Number as PropType<DayOfWeek>,
      required: true,
    },
    holidayRule: {
      type: Object as PropType<HolidayRuleEntity>,
      required: true,
    },
    baseDate: {
      type: Date as PropType<Date>,
      required: false,
      default() {
        return new Date();
      },
    },
    pageTarget: {
      type: String as PropType<RinEventPageNameTypes>,
      required: true,
    },
  },
  data(): DataType {
    return {
      weekDatesCalendar: new WeekDatesCalendar(this.baseDate, this.startOfWeek, this.holidayRule),
      isDatePickerActive: false,
    };
  },
  watch: {
    isDatePickerActive(value: boolean) {
      if (value) {
        this.$rinGtm.push(RinEventNames.OPEN_CALENDAR, {
          [AdditionalInfoKeys.TARGET]: this.pageTarget,
        });
      }
    },
  },
  mounted() {
    this.emitUpdateDateEvent();
  },
  methods: {
    formatDateToString,
    dateFormatter,
    onClickPrevWeekButton(): void {
      this.$rinGtm.push(RinEventNames.CHANGE_WEEK, {
        [AdditionalInfoKeys.TARGET]: this.pageTarget,
      });
      this.weekDatesCalendar.shiftToPreviousWeek();
      this.emitUpdateDateEvent();
    },
    onClickNextWeekButton(): void {
      this.$rinGtm.push(RinEventNames.CHANGE_WEEK, {
        [AdditionalInfoKeys.TARGET]: this.pageTarget,
      });
      this.weekDatesCalendar.shiftToNextWeek();
      this.emitUpdateDateEvent();
    },
    onSelectDate(dateString: string): void {
      const date = parseISO(dateString);
      this.weekDatesCalendar.setArbitraryDate(date);
      this.emitUpdateDateEvent(date);
      this.isDatePickerActive = false;
    },
    /**
     * Emit event to update date in parent component
     * @param date Optional. Date when user explicitly selects a date.
     */
    emitUpdateDateEvent(date?: Date) {
      const eventPayload: UpdateDateEventPayload = {
        start: this.weekDatesCalendar.firstDate,
        end: this.weekDatesCalendar.lastDate,
        selectedDate: date,
      };
      this.$emit(Events.updateDates, eventPayload);
    },
  },
});
