import _ from 'lodash';
import { DayOfWeek, Maybe, PersistentId } from '~/framework/typeAliases';
import { ScheduleTabType } from '~/framework/domain/typeAliases';
import { IScheduleSolutionStatus } from '~/framework/server-api/user-setting/userSetting';

const maxScheduleSolutionNum = 10;

export interface UserSettingEntityData {
  id: string;
  scheduleDate: Date;
  scheduleStartOfWeek: DayOfWeek;
  scheduleTimelineStart: number;
  scheduleTimelineEnd: number;
  scheduleTab: ScheduleTabType;
  scheduleSolutionStatus: IScheduleSolutionStatus[];
  orderGroupId: Maybe<string>;
  loginRememberMe: boolean;
}

export class UserSettingEntity {
  readonly id: string;
  scheduleDate: Date;
  scheduleStartOfWeek: DayOfWeek;
  scheduleTimelineStart: number;
  scheduleTimelineEnd: number;
  scheduleTab: ScheduleTabType;
  orderGroupId: Maybe<string>;
  scheduleSolutionStatus: IScheduleSolutionStatus[];
  loginRememberMe: boolean;

  constructor(
    id: string,
    scheduleDate: Date,
    scheduleStartOfWeek: DayOfWeek,
    scheduleTimelineStart: number,
    scheduleTimelineEnd: number,
    scheduleTab: ScheduleTabType,
    orderGroupId: Maybe<string>,
    scheduleSolutionStatus: IScheduleSolutionStatus[],
    loginRememberMe: boolean
  ) {
    this.id = id;
    this.scheduleDate = scheduleDate;
    this.scheduleStartOfWeek = scheduleStartOfWeek;
    this.scheduleTimelineStart = scheduleTimelineStart;
    this.scheduleTimelineEnd = scheduleTimelineEnd;
    this.scheduleTab = scheduleTab;
    this.orderGroupId = orderGroupId;
    this.scheduleSolutionStatus = scheduleSolutionStatus;
    this.loginRememberMe = loginRememberMe;
  }

  setScheduleInconsistencyFixed(id: PersistentId, index: number): void {
    const status = this.getScheduleSolutionStatusOf(id);

    status.inconsistencies.add(index);
    this.addScheduleSolutionStatus(status);
  }

  setScheduleInfeasibilityFixed(id: PersistentId, index: number): void {
    const status = this.getScheduleSolutionStatusOf(id);

    status.infeasibilities.add(index);
    this.addScheduleSolutionStatus(status);
  }

  getScheduleSolutionStatusOf(id: PersistentId): IScheduleSolutionStatus {
    const status = this.scheduleSolutionStatus.find((status) => status.scheduleId === id);
    return (
      status || {
        scheduleId: id,
        inconsistencies: new Set<number>(),
        infeasibilities: new Set<number>(),
        updatedAt: new Date(),
      }
    );
  }

  private addScheduleSolutionStatus(status: IScheduleSolutionStatus): void {
    status = _.cloneDeep(status);
    status.updatedAt = new Date();
    const copy = this.scheduleSolutionStatus.filter((item) => item.scheduleId !== status.scheduleId);
    copy.push(status);
    copy.sort((a, b) => a.updatedAt.getTime() - b.updatedAt.getTime());
    if (maxScheduleSolutionNum < copy.length) {
      // 先頭にあるものの方が古いので、それを消す
      copy.splice(0, copy.length - maxScheduleSolutionNum);
    }
    this.scheduleSolutionStatus = copy;
  }
}
