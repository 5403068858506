import { Predicate } from '~/framework/typeAliases';
import { IRetryStrategy } from '~/framework/core/retry/retryStrategy';
import { wait } from '~/framework/async';

/**
 * 何らかの非同期タスクをリトライする
 * @param task リトライしたい非同期タスクを生成するファンクタ
 * @param predicate リトライしていい例外が発生したかどうかを判断する, true = リトライ可能
 * @param retryStrategy リトライする際の戦略
 */
export const retry = async <Value>(
  task: () => Promise<Value>,
  predicate: Predicate<Error>,
  retryStrategy: IRetryStrategy
): Promise<Value> => {
  return await retryInner(task, predicate, retryStrategy, 0);
};

const retryInner = async <Value>(
  task: () => Promise<Value>,
  predicate: Predicate<Error>,
  retryStrategy: IRetryStrategy,
  retried: number
): Promise<Value> => {
  try {
    return await task();
  } catch (e: any) {
    if (predicate(e) === false) {
      throw e;
    }
  }
  const interval = retryStrategy.getRetryInterval(retried);

  await wait(interval);
  retryStrategy.onRetry();
  return await retryInner(task, predicate, retryStrategy, retried + 1);
};
