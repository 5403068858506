import {
  DisposalJsonObject,
  DisposalSiteAssignmentType,
} from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class Disposal {
  // スキーマ上で定義されている項目
  disposalIndex: number;
  disposalSiteId: string;
  disposalSiteAssignmentType: DisposalSiteAssignmentType;
  constructor(disposal: DisposalJsonObject) {
    this.disposalIndex = disposal.disposal_index;
    this.disposalSiteId = disposal.disposal_site_id.toPseudoId().value;
    this.disposalSitePseudoId = disposal.disposal_site_id;
    this.disposalSiteAssignmentType = disposal.disposal_site_assignment_type;
  }

  // Rinで必要な項目
  disposalSitePseudoId: string;

  getDisposalJsonObject(): DisposalJsonObject {
    return {
      disposal_index: this.disposalIndex,
      disposal_site_id: this.disposalSitePseudoId,
      disposal_site_assignment_type: this.disposalSiteAssignmentType,
    };
  }
}
