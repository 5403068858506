export interface IHighwayOption {
  value: boolean;
  label: string;
}

/**
 * 高速利用の選択肢
 */
export const highwayOptions: IHighwayOption[] = [
  { value: false, label: '許可' },
  { value: true, label: '禁止' },
];

/**
 * value から label を引ける様にしたもの
 */
export const highwayOptionMap = new Map<IHighwayOption['value'], IHighwayOption['label']>(
  highwayOptions.map((option) => [option.value, option.label])
);
