
import Vue, { PropType } from 'vue';
import { ItemType } from '~/components/panels/schedule/r-order-form/rOrderFormSelectItem';

enum EventTypes {
  Change = 'change',
}

export enum ItemTextColorClassType {
  Primary = 'item--primary',
  Error = 'item--error',
}

type DataType = {
  internalKey: number;
};

export default Vue.extend({
  name: 'ROrderFormSelect',
  model: {
    prop: 'value',
    event: EventTypes.Change,
  },

  props: {
    items: {
      type: Array as PropType<ItemType[]>,
      required: true,
    },
    value: {
      type: undefined as any as PropType<any>,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // NOTE: updateCountというPropsはVSelectの再レンダリングを強制したい場合にインクリメントして再レンダリングさせることができる
    updateCount: {
      type: Number,
      default: 0,
    },
  },
  data(): DataType {
    return {
      internalKey: 0,
    };
  },
  computed: {
    key(): string {
      return `${this.internalKey}:${this.updateCount}`;
    },
  },
  methods: {
    async onChangeValue(item: ItemType): Promise<void> {
      if (item.onBeforeChange) {
        // 仮に value の change イベントを発火しなかったとしても内部の v-select の内部的な値が
        // 変更されてしまっているため、ここで internalKey をインクリメントする事でこちらから
        // 指定した value によって強制的に再度レンダリングする
        this.internalKey++;
        if (await item.onBeforeChange()) {
          this.$emit(EventTypes.Change, item.value);
        }
      } else {
        this.$emit(EventTypes.Change, item.value);
      }
    },
    reset() {
      (this.$refs.select as any).reset();
    },
  },
});
