import { google } from 'google-maps';
import { GoogleMapAsync, IGoogleMapAsync, IRetryStrategy } from '~/framework/googleMapAsync';
import { AddressesFactory, IAddresses } from '~/framework/services/google-maps/addresses';

import { Maybe } from '~/framework/typeAliases';

/**
 * Fanfare 旧本社の所在地
 */
const fanfareLatLng = {
  lat: 35.6645534,
  lng: 139.7431935,
};

/**
 * 地図のコンポーネントなどで使いまわしそうなものをひとまとめにしたもの
 */
export interface IGoogleMapService extends IGoogleMapAsync {
  /**
   * 指定した地点が車で移動可能なのかどうかを取得する
   * Fanfare 旧本社から移動できれば到達可能と判断する
   *
   * @param location
   * @param travelMode
   */
  isTravelable(location: google.maps.LatLngLiteral, travelMode?: google.maps.TravelMode): Promise<boolean>;

  /**
   * 雑な住所を与えてあり得る住所を取得する
   * @param address
   */
  getAddressesFrom(address: string): Promise<IAddresses>;

  /**
   * 郵便番号からあり得る住所を取得する
   * @param zipcode
   */
  getAddresses(zipcode: string): Promise<IAddresses>;
}

export class GoogleMapService implements IGoogleMapService {
  private readonly google: IGoogleMapAsync;

  constructor(google: google, retryStrategy?: Maybe<IRetryStrategy>) {
    this.google = new GoogleMapAsync(google, retryStrategy);
  }

  geocode(request: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[]> {
    return this.google.geocode(request);
  }

  getDistanceMatrix(request: google.maps.DistanceMatrixRequest): Promise<google.maps.DistanceMatrixResponse> {
    return this.google.getDistanceMatrix(request);
  }

  async getAddressesFrom(address: string): Promise<IAddresses> {
    const geocodeRequest: google.maps.GeocoderRequest = { address };
    const results = await this.google.geocode(geocodeRequest);

    return AddressesFactory.buildAddresses(results, address);
  }

  async getAddresses(zipcode: string): Promise<IAddresses> {
    const geocodeRequest: google.maps.GeocoderRequest = { address: zipcode };
    const results = await this.google.geocode(geocodeRequest);

    return AddressesFactory.buildAddresses(results);
  }

  // 往復で共に車で移動可能かどうかを返却する
  async isTravelable(
    location: google.maps.LatLngLiteral,
    travelMode: google.maps.TravelMode = 'DRIVING' as google.maps.TravelMode.DRIVING
  ): Promise<boolean> {
    // origins, destinations を2つずつ設定すれば済むが、課金体系が origins * destinations の数なので無駄な組み合わせを減らすために request を分割している
    const distanceMatrixRequest1: google.maps.DistanceMatrixRequest = {
      origins: [fanfareLatLng],
      destinations: [location],
      travelMode,
    };
    const distanceMatrixRequest2: google.maps.DistanceMatrixRequest = {
      origins: [location],
      destinations: [fanfareLatLng],
      travelMode,
    };
    const [distanceMatrixResponse1, distanceMatrixResponse2] = await Promise.all([
      this.google.getDistanceMatrix(distanceMatrixRequest1),
      this.google.getDistanceMatrix(distanceMatrixRequest2),
    ]);

    return [distanceMatrixResponse1, distanceMatrixResponse2].every((response) =>
      response.rows.some((row) => row.elements.some((element) => element.status === 'OK'))
    );
  }
}
