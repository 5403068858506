import { IUserData } from '~/framework/server-api/masters/user';

export class UserEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;

  constructor(data: IUserData) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
  }

  update(data: IUserData): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of UserEntity');
    }
    this.name = data.name;
  }
}
