export class Secrets {
  private readonly _googleMapApiKey: string;

  constructor(googleMapApiKey: string) {
    this._googleMapApiKey = googleMapApiKey;
  }

  /**
   * Google Map の API にアクセスするためのキー
   */
  get googleMapApiKey(): string {
    return this._googleMapApiKey;
  }
}
