import { MapperBase } from '~/framework/core/mapper';
import { AggregatedWasteTypeMapper } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeMapper';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { AggregatedContainerTypeMapper } from '~/framework/domain/masters/container-type/aggregatedContainerTypeMapper';
import { AggregatedContainerTypeStore } from '~/framework/domain/masters/container-type/aggregatedContainerTypeStore';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { TaskTypeMapper } from '~/framework/domain/masters/task-type/taskTypeMapper';
import { AggregatedDriverStore } from '~/framework/domain/masters/driver/aggregatedDriverStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import {
  AggregatedReservationGenerationSiteTaskEntity,
  IAggregatedReservationGenerationSiteTaskData,
} from './aggregatedReservationGenerationSiteTaskEntity';

/**
 * トラックする必要はないので Mapper は厳密には必要ないが、同じ作法にしておいた方が
 * 分かりやすそうなので用意している
 */

export class AggregatedReservationGenerationSiteTaskMapper extends MapperBase<
  IAggregatedReservationGenerationSiteTaskData,
  AggregatedReservationGenerationSiteTaskEntity
> {
  private readonly wasteTypeMapper: AggregatedWasteTypeMapper;
  private readonly containerTypeMapper: AggregatedContainerTypeMapper;
  private readonly taskTypeMappter: TaskTypeMapper;

  constructor(
    aggregatedWasteTypeStore: AggregatedWasteTypeStore,
    jwnetWasteMasterStore: JwnetWasteMasterStore,
    userStore: UserStore,
    aggregatedContainerTypeStore: AggregatedContainerTypeStore,
    packingStyleStore: PackingStyleStore,
    driverStore: AggregatedDriverStore,
    carStore: AggregatedCarStore,
    baseSiteStore: AggregatedBaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore
  ) {
    super();
    this.wasteTypeMapper = new AggregatedWasteTypeMapper(aggregatedWasteTypeStore, jwnetWasteMasterStore, userStore);
    this.containerTypeMapper = new AggregatedContainerTypeMapper(aggregatedContainerTypeStore, packingStyleStore);
    this.taskTypeMappter = new TaskTypeMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
  }

  protected instantiateWithData(
    data: IAggregatedReservationGenerationSiteTaskData
  ): AggregatedReservationGenerationSiteTaskEntity {
    const wasteType = data.wasteType ? this.wasteTypeMapper.mapSingle(data.wasteType) : undefined;
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    const taskType = this.taskTypeMappter.mapSingle(data.taskType);
    return new AggregatedReservationGenerationSiteTaskEntity(data, wasteType, containerType, taskType);
  }
}
