
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RIconButton',
  props: {
    icon: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    title: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
});
