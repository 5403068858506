import { StoredMapperBase } from '~/framework/core/mapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { IClientData } from '~/framework/server-api/masters/client';

export class AggregatedClientMapper extends StoredMapperBase<IClientData, AggregatedClientEntity> {
  protected store: AggregatedClientStore;
  private userMapper: UserMapper;

  constructor(store: AggregatedClientStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: IClientData): AggregatedClientEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    return new AggregatedClientEntity(data, createdBy, updatedBy);
  }

  protected updateWithData(data: IClientData, entity: AggregatedClientEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    entity.update(data, createdBy, updatedBy);
  }
}
