
import Vue, { PropType } from 'vue';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { Maybe } from '~/framework/typeAliases';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

enum EventTypes {
  ChangeValue = 'change:value',
  ClickYes = 'click:yes',
  ClickNo = 'click:no',
}

type DataType = {
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'RConfirmDialog',
  model: {
    prop: 'value',
    event: EventTypes.ChangeValue,
  },
  props: {
    title: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    yesColor: {
      type: String as PropType<string>,
      required: false,
      default: 'error',
    },
    noColor: {
      type: String as PropType<string>,
      required: false,
      default: 'bluish-gray_40',
    },
  },
  data(): DataType {
    return {
      listenerDisposer: undefined,
    };
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    onChangeValue(value: boolean): void {
      this.$emit(EventTypes.ChangeValue, value);
    },
    onClickYes(): void {
      // ダイアログを閉じた上でクリックの通知を行う
      this.$emit(EventTypes.ChangeValue, false);
      this.$emit(EventTypes.ClickYes);
    },
    onClickNo(): void {
      // ダイアログを閉じた上でクリックの通知を行う
      this.$emit(EventTypes.ChangeValue, false);
      this.$emit(EventTypes.ClickNo);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        Vue.nextTick(this.onClickNo);
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.Enter)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ENTER, RinEventDialogComponentParam);
        Vue.nextTick(this.onClickYes);
      }
      context.stop();
    },
  },
});
