import { ReasonType, WarningJsonObject, WarningType } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export class Warning {
  warningType: WarningType;
  reasonType: ReasonType;
  driverId: string;
  routeIndex: number;
  constructor(warning: WarningJsonObject) {
    this.warningType = warning.warning_type;
    this.reasonType = warning.reason_type;
    this.driverId = warning.driver_id.toPseudoId().value;
    this.driverPseudoId = warning.driver_id;
    this.routeIndex = warning.route_index;
  }

  driverPseudoId: string;

  getWarningJsonObject(): WarningJsonObject {
    return {
      warning_type: this.warningType,
      reason_type: this.reasonType,
      driver_id: this.driverPseudoId,
      route_index: this.routeIndex,
    };
  }
}
