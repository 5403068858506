import marginTypeMap from '~/assets/settings/marginType.json';
import { MarginType } from '~/framework/domain/typeAliases';
/*
 * 時刻に対するマージン種別の選択肢のインターフェース
 */
export interface IMarginTypeOption {
  value: MarginType;
  text: string;
}

/**
 * 時刻に対するマージン種別の選択肢
 */
export const marginTypeOptions: IMarginTypeOption[] = [
  {
    text: marginTypeMap[MarginType.AllowBeforeAndAfter],
    value: MarginType.AllowBeforeAndAfter,
  },
  {
    text: marginTypeMap[MarginType.AllowBefore],
    value: MarginType.AllowBefore,
  },
  {
    text: marginTypeMap[MarginType.AllowAfter],
    value: MarginType.AllowAfter,
  },
];

/**
 * 時刻に対するマージン種別の選択肢のマップ
 */
export const marginTypeOptionMap = new Map<IMarginTypeOption['value'], IMarginTypeOption['text']>(
  marginTypeOptions.map((item) => [item.value, item.text])
);
