const middleware = {}

middleware['compatibility'] = require('../src/middleware/compatibility.ts')
middleware['compatibility'] = middleware['compatibility'].default || middleware['compatibility']

middleware['login'] = require('../src/middleware/login.ts')
middleware['login'] = middleware['login'].default || middleware['login']

middleware['notification'] = require('../src/middleware/notification.ts')
middleware['notification'] = middleware['notification'].default || middleware['notification']

export default middleware
