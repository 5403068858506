import { Maybe } from '~/framework/typeAliases';

type MaybePair<TypeA, TypeB> = [aElement: Maybe<TypeA>, bElement: Maybe<TypeB>];

/**
 * a と b の配列を与えて、それぞれの index の要素の組を返す。
 * どちらか大きい方の要素数に合わせて組を生成し、対が存在しない場合には undefined を返す。
 * （一般的には小さい方に合わせる様だが、それだとやりにくいので）
 * @param a
 * @param b
 */
export const zip = <TypeA, TypeB>(a: TypeA[], b: TypeB[]): MaybePair<TypeA, TypeB>[] => {
  const result: MaybePair<TypeA, TypeB>[] = [];
  const maxLength = Math.max(a.length, b.length);
  for (let index = 0; index < maxLength; index++) {
    const aElement = index < a.length ? a[index] : undefined;
    const bElement = index < b.length ? b[index] : undefined;
    result.push([aElement, bElement]);
  }
  return result;
};

/**
 * アイテムの最後を表すシンボル
 * undefined を利用すると undefined 自体と判別が付かなくなるので unique symbol を利用する
 */
export const endOfItems: unique symbol = Symbol('means it is the end of items');

/**
 * 配列の隣り合った要素の組を表す
 */
type NextPair<Type> = [Type, MaybeEndOfItems<Type>];

/**
 * 最後のアイテムの場合は次の要素が存在しないので、endOfItems になる
 */
type MaybeEndOfItems<Type> = Type | typeof endOfItems;

/**
 * 配列の隣り合う要素を一つのペアとして配列にして返す
 *
 * 最後の要素はペアの片方が endOfPairs になる。
 * 例えば、
 * [1, 2, 3]
 * の場合は
 * [[1, 2], [2, 3], [3, endOfItems]]
 * になる。
 *
 * 要素が初めから空の場合は空の配列を返す。
 *
 * そもそも最後の要素を返さない方式も考えられるが、そうしたとしても使う側で if を挟み込んだりする可能性があって
 * あまり変わりがない様に思えるし、明示的に最後の要素である事が分かるメリットもある気がしたので最後のアイテムも
 * endOfItems と合わせて返す形にしている。
 *
 * @param items
 */
export const pairNext = <Type>(items: Type[]): NextPair<Type>[] => {
  if (items.length === 0) return [];
  return items.map((item, index) => [item, index + 1 < items.length ? items[index + 1] : endOfItems]);
};
