
import Vue, { PropType } from 'vue';
import RErpOrderFormInput from '~/components/common/r-erp-order-form/RErpOrderFormInput.vue';
import { IErpClientData } from '~/framework/server-api/masters/erpClient';
import { Maybe } from '~/framework/typeAliases';
import { ErpPath } from '~/framework/services/mio/mio';
import { ErpOrderForm } from '~/components/common/r-erp-order-form/RErpOrderForm';

export default Vue.extend({
  name: 'RErpOrderForm',
  components: { RErpOrderFormInput },
  model: {
    prop: 'initialErpOrderForm',
    event: 'change',
  },
  props: {
    transportationClients: {
      type: Array as PropType<IErpClientData[]>,
      required: true,
    },
    initialErpOrderForm: {
      type: Object as PropType<Maybe<ErpOrderForm>>,
      required: false,
      default: undefined,
    },
    isErpOrderCategoryRequired: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      linkToErpOrders: this.$context.mio.getErpLink(ErpPath.ErpOrders),
    };
  },
  methods: {
    onChangeErpOrderForm(erpOrderForm: ErpOrderForm): void {
      this.$emit('change', erpOrderForm);
    },
  },
});
