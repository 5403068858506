
import Vue from 'vue';
import RSnackbarList from '~/components/common/r-snackbar-list/RSnackbarList.vue';
import { ISnackbarList, SnackbarListPosition } from '~/components/common/r-snackbar-list/snackbarList';
import { ISnackbarData } from '~/framework/view-models/snackbarData';
import { Maybe } from '~/framework/typeAliases';

type DataType = {
  snackbarListMap: Map<string, ISnackbarList>;
  snackbarDataTopRight: ISnackbarData[];
  snackbarDataBottomRight: ISnackbarData[];
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'RSnackbars',
  components: {
    RSnackbarList,
  },
  data(): DataType {
    return {
      snackbarListMap: new Map<SnackbarListPosition, ISnackbarList>(),
      snackbarDataTopRight: [],
      snackbarDataBottomRight: [],
      listenerDisposer: undefined,
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  beforeMount() {
    this.snackbarListMap.set(SnackbarListPosition.TopRight, {
      position: SnackbarListPosition.TopRight,
      snackbars: this.snackbarDataTopRight,
    });
    this.snackbarListMap.set(SnackbarListPosition.BottomRight, {
      position: SnackbarListPosition.BottomRight,
      snackbars: this.snackbarDataBottomRight,
    });
  },
  mounted() {
    const snackbarAddEventListenerDisposer = this.$context.events.snackbarAddEvent.on(this.onSnackbarAddEvent);
    this.listenerDisposer = () => {
      snackbarAddEventListenerDisposer.dispose();
    };
  },
  methods: {
    onSnackbarAddEvent(snackbar: ISnackbarData) {
      // スナックバー位置が指定してある時はその位置に、指定してない時は右上に表示する
      const position = snackbar.position ? snackbar.position : SnackbarListPosition.TopRight;
      const snackbarList = this.snackbarListMap.getOrError(position);
      snackbarList.snackbars.push(snackbar);
    },
    onSnackbarInput(id: string, value: boolean, position: SnackbarListPosition) {
      const snackbarData = this.snackbarListMap.getOrError(position).snackbars;
      const snackbarIndex = snackbarData.findIndex((snackbar) => snackbar.id === id);
      if (snackbarIndex === -1) return;

      // ここで value に入れる意味はほぼないが、一応念のため
      snackbarData[snackbarIndex].value = value;
      snackbarData.splice(snackbarIndex, 1);
    },
  },
});
