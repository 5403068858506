import { AllCheckItems } from '@/../graphql/server-api/queries/check-item.gql';
import {
  CreateCheckItem,
  UpdateCheckItem,
  DeleteCheckItem,
  UpdateCheckItemSequence,
} from '@/../graphql/server-api/mutations/check-item.gql';
import {
  AllCheckItemsQuery,
  CreateCheckItemMutation,
  CreateCheckItemMutationVariables,
  DeleteCheckItemMutation,
  DeleteCheckItemMutationVariables,
  UpdateCheckItemMutation,
  UpdateCheckItemMutationVariables,
  UpdateCheckItemSequenceMutation,
  UpdateCheckItemSequenceMutationVariables,
} from '@/graphql/graphQLServerApi';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateData, IUpdateData } from '~/framework/server-api/masters/checkItem';
import { PersistentId } from '~/framework/typeAliases';

export class CheckItemApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllCheckItemsQuery>({
      query: AllCheckItems,
      variables: {},
    });
    return result.allCheckItems;
  }

  async create(data: ICreateData) {
    const result = await this.mutate<CreateCheckItemMutation, CreateCheckItemMutationVariables>({
      mutation: CreateCheckItem,
      variables: { checkItem: data },
    });
    return result.createCheckItem;
  }

  async update(id: string, data: IUpdateData) {
    const result = await this.mutate<UpdateCheckItemMutation, UpdateCheckItemMutationVariables>({
      mutation: UpdateCheckItem,
      variables: { id, checkItem: data },
    });
    return result.updateCheckItem;
  }

  async delete(id: PersistentId) {
    const result = await this.mutate<DeleteCheckItemMutation, DeleteCheckItemMutationVariables>({
      mutation: DeleteCheckItem,
      variables: { id },
    });
    return result.deleteCheckItem;
  }

  async updateSequence(data: string[]) {
    const result = await this.mutate<UpdateCheckItemSequenceMutation, UpdateCheckItemSequenceMutationVariables>({
      mutation: UpdateCheckItemSequence,
      variables: { checkItemSequence: data },
    });
    return result.updateCheckItemSequence;
  }
}
