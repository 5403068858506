import { format, getDay } from 'date-fns';
import daysOfWeek from '~/assets/settings/daysOfWeek.json';
import { CssClasses } from '~/framework/typeAliases';
import { formatDateToString } from '~/framework/services/date/date';

export interface IHeaderDateColumn {
  date: Date;
  dateString: string;
  label: string;
  isHoliday: boolean;
  classes: CssClasses;
}

export class HeaderDateColumn implements IHeaderDateColumn {
  date: Date;
  dateString: string;
  label: string;
  isHoliday: boolean;
  classes: CssClasses;

  constructor(date: Date, isHoliday: boolean) {
    this.date = date;
    this.dateString = formatDateToString(date);
    this.label = `${format(date, 'd')} ${daysOfWeek[getDay(date)]}`;
    this.isHoliday = isHoliday;
    this.classes = {
      'r-week-calendar__content-headers__header-item': true,
      'r-week-calendar__content-headers__header-item--holiday': this.isHoliday,
    };
  }
}
