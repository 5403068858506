
import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
import _ from 'lodash';
import RCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RCollectablePeriodInput.vue';
import {
  DefaultPreloadStatus,
  IDefaultPreloadOption,
  getDefaultPreloadStatusOptions,
  defaultPreloadStatusEnumToApiValueMap,
} from '~/framework/view-models/preloadOption';
import {
  ICollectablePeriodTemplateOption,
  DistinctTimeOption,
  DistinctTimeOptionId,
  DesignatedTimePeriodOptionId,
  DesignatedTimePeriodOption,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import { ClientsByKeywordsCondition, ClientsByKeywordsOrder } from '~/framework/server-api/typeAliases';
import { MarginType, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { highwayOptions, IHighwayOption } from '~/framework/view-models/highwayOption';
import { ExtractVue, Maybe, PersistentId, ValidationRule } from '~/framework/typeAliases';
import { required, maxLength } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { yomiganize } from '~/framework/services/string/string';
import { getHoursAndMinutesOf } from '~/framework/services/date-time/date-time';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import {
  getInitialFormValuesByGenerationSite,
  IGenerationSiteFormPanelOption,
  IGenerationSiteFormValues,
} from '~/framework/view-models/panels/generationSiteFormPanel';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { VuetifyColors } from '~/framework/constants';
import { IHasRestPeriodOption, hasRestPeriodOptions } from '~/framework/view-models/restPeriodOption';
import { Client } from '~/components/common/r-lazy-searchable-pulldown/client';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import {
  generationSiteSymbol,
  GenerationSiteApplicationService,
} from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import {
  defaultRouteCollectionEnumToApiValueMap,
  IDefaultRouteCollectionOption,
  DefaultRouteCollectionOptionsValues,
  getDefaultRouteCollectionOptions,
} from '~/framework/view-models/routeCollectionOption';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { IRPopupMenuItem } from '~/components/common/r-popup-menu/rPopupMenu';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import {
  defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap,
  DefaultIsFixedArrivalTimeReportNeededOptionsValues,
  getDefaultIsFixedArrivalTimeReportNeededOptions,
  IDefaultIsFixedArrivalTimeReportNeededOption,
} from '~/framework/view-models/fixedArrivalTimeReportOption';
import { IMarginTypeOption, marginTypeOptions } from '~/framework/view-models/marginTypeOption';
import LazySearchablePullDownVue from '~/components/common/r-lazy-searchable-pulldown/RLazySearchablePulldown.vue';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  PageNames,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import RUploadedFile from '~/components/common/r-uploaded-file/RUploadedFile.vue';
import {
  DriverApplicationService,
  driverSymbol,
} from '~/framework/application/masters/driver/driverApplicationService';
import { disposalSiteSymbol } from '~/framework/application/masters/disposal-site/disposalSiteApplicationService';
import { carTypeSymbol } from '~/framework/application/masters/car-type/carTypeApplicationService';
import { carSymbol } from '~/framework/application/masters/car/carApplicationService';
import { collectablePeriodTemplateSymbol } from '~/framework/application/masters/collectable-period-template/collectablePeriodTemplateApplicationService';
import { officeSettingSymbol } from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import { sanitizeHours, sanitizeMinutes } from '~/framework/view-models/ruleLogics';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { ids } from '~/framework/core/entity';

type LazySearchablePullDownType = ExtractVue<typeof LazySearchablePullDownVue>;

enum FormMode {
  Register,
  Edit,
}

export enum DisabledReason {
  ClientIdIsNotSelected,
  DefaultAssignableCarIdsIsSelected,
  DefaultAssignableCarTypeIdsIsSelected,
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  isDeleteConfirmDialogActive: boolean;
  masters: Maybe<Masters>;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
  DisabledReason: typeof DisabledReason;
  DefaultIsFixedArrivalTimeReportNeededOptionsValues: typeof DefaultIsFixedArrivalTimeReportNeededOptionsValues;
  generationSiteApplicationService: GenerationSiteApplicationService;
};

type AsyncDataType = {
  viewModel: Maybe<GenerationSiteForm>;
};

class GenerationSiteForm {
  private readonly officeSetting: OfficeSettingEntity;

  title: string;
  formMode: FormMode;
  generationSite: Maybe<AggregatedGenerationSiteEntity>;
  createdGenerationSite: Maybe<AggregatedGenerationSiteEntity>;
  updatedGenerationSite: Maybe<AggregatedGenerationSiteEntity>;
  client: Maybe<AggregatedClientEntity>;
  clientLoader: Client;
  clientDefaultCondition: ClientsByKeywordsCondition;
  driverEntities: AggregatedDriverEntity[];
  disposalSiteEntities: AggregatedDisposalSiteEntity[];
  carTypeEntities: AggregatedCarTypeEntity[];
  carEntities: AggregatedCarEntity[];
  collectablePeriodTemplateEntities: Array<CollectablePeriodTemplateEntity>;
  rules: { [key: string]: ValidationRule };
  clientId: Maybe<PersistentId> = undefined;
  name: string = '';
  nameRuby: string = '';
  zipCode: Maybe<string> = '';
  address1: string = '';
  address2: string = '';
  address3: string = '';
  address4: Maybe<string> = undefined;
  latitude: Maybe<number> = undefined;
  longitude: Maybe<number> = undefined;
  registeredNotes: Maybe<string> = undefined;
  note: Maybe<string> = undefined;
  attachments: UploadedFile[] = [];
  inputAttachments: File[] = [];
  attachmentsToAdd: File[] = [];
  attachmentsToRemove: string[] = [];
  bannedDriverIds: PersistentId[] = [];
  defaultAssignedDriverId: Maybe<PersistentId> = undefined;
  defaultAssignedDisposalSiteId: Maybe<PersistentId> = undefined;
  defaultAssignableCarIds: PersistentId[] = [];
  defaultAssignableCarTypeIds: PersistentId[] = [];
  defaultAvoidHighWays: boolean = true;
  defaultAvoidHighwaysOptions: Array<IHighwayOption>;
  defaultRouteCollectionOptions: Array<IDefaultRouteCollectionOption>;
  defaultPreloadStatusOptions: Array<IDefaultPreloadOption>;
  defaultIsFixedArrivalTimeReportNeededOptions: Array<IDefaultIsFixedArrivalTimeReportNeededOption>;
  marginTypeOptions: Array<IMarginTypeOption>;
  defaultDurationAtEntranceHours: number = 0;
  defaultDurationAtEntranceMinutes: number = 0;
  hasRestPeriod: boolean = false;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  isAddressComplete: boolean = false;
  hasRestPeriodOptions: IHasRestPeriodOption[];
  _isAddressValidated: boolean = false;
  isMapDialogActive: boolean = false;
  isRegistering: boolean = false;
  _isFormValid: boolean = false;
  isNameRubyTooltipOpen: boolean = false;
  isPastingAddress: boolean = false;
  isNoteDisabled: boolean = true;
  isAttachmentsDisabled: boolean = true;
  isNoteForOfficeDisabled: boolean = true;
  isBannedDriverIdsDisabled: boolean = true;
  isDefaultAssignedDriverIdDisabled: boolean = true;
  isDefaultAssignedDisposalSiteIdDisabled: boolean = true;
  isDefaultAssignableCarTypeIdsDisabled: boolean = true;
  defaultAssignableCarTypeIdsDisabledReason: Set<DisabledReason>;
  isDefaultAssignableCarIdsDisabled: boolean = true;
  defaultAssignableCarIdsDisabledReason: Set<DisabledReason>;
  isDefaultAvoidHighwaysDisabled: boolean = true;
  isDefaultRouteCollectionAllowedDisabled: boolean = true;
  isDefaultPreloadStatusDisabled: boolean = true;
  isFixedArrivalTimeReportDisplayed: boolean = false;
  isDefaultIsFixedArrivalTimeReportNeededDisabled: boolean = true;
  isDefaultMarginTypeOfFixedArrivalTimeDisabled: boolean = true;
  isDefaultMarginOfFixedArrivalTimeDisabled: boolean = true;
  isRegisterButtonDisabled: boolean = true;
  isDefaultDurationAtEntranceDisabled: boolean = true;
  defaultCollectablePeriodTemplateId: Maybe<string> = undefined;
  collectablePeriodTemplateOptions: ICollectablePeriodTemplateOption[] = [];
  defaultCollectablePeriodDistinct: Maybe<number> = undefined;
  defaultCollectablePeriodStart: Maybe<number> = undefined;
  defaultCollectablePeriodEnd: Maybe<number> = undefined;
  defaultRouteCollectionAllowed: DefaultRouteCollectionOptionsValues = DefaultRouteCollectionOptionsValues.NotSet;
  defaultPreloadStatus: DefaultPreloadStatus = DefaultPreloadStatus.NotSet;
  defaultIsFixedArrivalTimeReportNeeded: DefaultIsFixedArrivalTimeReportNeededOptionsValues =
    DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet;

  defaultMarginTypeOfFixedArrivalTime: MarginType = MarginType.AllowBeforeAndAfter;
  defaultMarginOfFixedArrivalTimeHours: number = 0;
  defaultMarginOfFixedArrivalTimeMinutes: number = 0;
  noteForOffice: Maybe<string> = undefined;
  option: Maybe<IGenerationSiteFormPanelOption>;
  registerButtonLabel: string;

  private initialFormValues: IGenerationSiteFormValues;

  get address() {
    const components = [];
    if (this.zipCode) components.push(this.zipCode);
    if (this.address1) components.push(this.address1);
    if (this.address2) components.push(this.address2);
    if (this.address3) components.push(this.address3);
    // address4 を入れるとそこまで premise で返ってきてしまうため、あえて入れない
    return components.join(' ');
  }

  get isAddressValidated() {
    return this._isAddressValidated;
  }

  set isAddressValidated(value: boolean) {
    this._isAddressValidated = value;
    this.updateRegisterButtonDisabled();
    if (this._isAddressValidated) {
      this.isAddressComplete = true;
    }
  }

  get isFormValid(): boolean {
    return this._isFormValid;
  }

  set isFormValid(value: boolean) {
    this._isFormValid = value;
    this.updateRegisterButtonDisabled();
  }

  get isDirty(): boolean {
    const isDirty =
      this.clientId !== this.initialFormValues.clientId ||
      this.name !== this.initialFormValues.name ||
      this.nameRuby !== this.initialFormValues.nameRuby ||
      this.zipCode !== this.initialFormValues.zipCode ||
      this.address1 !== this.initialFormValues.address1 ||
      this.address2 !== this.initialFormValues.address2 ||
      this.address3 !== this.initialFormValues.address3 ||
      this.address4 !== this.initialFormValues.address4 ||
      this.latitude !== this.initialFormValues.latitude ||
      this.longitude !== this.initialFormValues.longitude ||
      this.note !== this.initialFormValues.note ||
      _.isEqual(_.sortBy(this.bannedDriverIds), _.sortBy(this.initialFormValues.bannedDriverIds)) === false ||
      this.defaultAssignedDriverId !== this.initialFormValues.defaultAssignedDriverId ||
      this.defaultAssignedDisposalSiteId !== this.initialFormValues.defaultAssignedDisposalSiteId ||
      this.defaultAssignableCarIds !== this.initialFormValues.defaultAssignableCarIds ||
      this.defaultAssignableCarTypeIds !== this.initialFormValues.defaultAssignableCarTypeIds ||
      this.defaultAvoidHighWays !== this.initialFormValues.defaultAvoidHighWays ||
      this.defaultDurationAtEntrance !== this.initialFormValues.defaultDurationAtEntrance ||
      this.hasRestPeriod !== this.initialFormValues.hasRestPeriod ||
      this.restPeriodStart !== this.initialFormValues.restPeriodStart ||
      this.restPeriodEnd !== this.initialFormValues.restPeriodEnd ||
      this.isAddressComplete !== this.initialFormValues.isAddressComplete ||
      this.defaultCollectablePeriodTemplateId !== this.initialFormValues.defaultCollectablePeriodTemplateId ||
      this.defaultCollectablePeriodDistinct !== this.initialFormValues.defaultCollectablePeriodDistinct ||
      this.defaultCollectablePeriodStart !== this.initialFormValues.defaultCollectablePeriodStart ||
      this.defaultCollectablePeriodEnd !== this.initialFormValues.defaultCollectablePeriodEnd ||
      this.defaultRouteCollectionAllowed !== this.initialFormValues.defaultRouteCollectionAllowed ||
      this.defaultPreloadStatus !== this.initialFormValues.defaultPreloadStatus ||
      this.defaultIsFixedArrivalTimeReportNeeded !== this.initialFormValues.defaultIsFixedArrivalTimeReportNeeded ||
      this.defaultMarginTypeOfFixedArrivalTime !== this.initialFormValues.defaultMarginTypeOfFixedArrivalTime ||
      this.defaultMarginOfFixedArrivalTime !== this.initialFormValues.defaultMarginOfFixedArrivalTime ||
      this.noteForOffice !== this.initialFormValues.noteForOffice;
    return isDirty;
  }

  get defaultDurationAtEntrance(): number {
    return this.defaultDurationAtEntranceHours * 60 * 60 + this.defaultDurationAtEntranceMinutes * 60;
  }

  get showContinueRegistrationButton(): boolean {
    if (this.option?.disableContinueRegistrationButton) {
      return false;
    }
    return true;
  }

  get defaultMarginOfFixedArrivalTime(): number {
    return this.defaultMarginOfFixedArrivalTimeHours * 60 * 60 + this.defaultMarginOfFixedArrivalTimeMinutes * 60;
  }

  get isDefaultMarginTypeAndMarginOfFixedArrivalTimeDisplayed(): boolean {
    return (
      this.defaultIsFixedArrivalTimeReportNeeded === DefaultIsFixedArrivalTimeReportNeededOptionsValues.Needed ||
      (this.defaultIsFixedArrivalTimeReportNeeded === DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet &&
        this.officeSetting.defaultIsFixedArrivalTimeReportNeeded)
    );
  }

  get defaultMarginTypeAndMarginOfFixedArrivalTimeTooltipDisabled(): boolean {
    return (
      this.isDefaultIsFixedArrivalTimeReportNeededDisabled ||
      (!this.isDefaultMarginTypeOfFixedArrivalTimeDisabled && !this.isDefaultMarginOfFixedArrivalTimeDisabled)
    );
  }

  constructor(
    clientDefaultCondition: ClientsByKeywordsCondition,
    clientLoader: Client,
    generationSite: Maybe<AggregatedGenerationSiteEntity>,
    client: Maybe<AggregatedClientEntity>,
    driverEntities: AggregatedDriverEntity[],
    disposalSiteEntities: AggregatedDisposalSiteEntity[],
    carTypeEntities: AggregatedCarTypeEntity[],
    carEntities: AggregatedCarEntity[],
    collectablePeriodTemplateEntities: Array<CollectablePeriodTemplateEntity>,
    officeSetting: OfficeSettingEntity,
    option?: IGenerationSiteFormPanelOption
  ) {
    this.generationSite = generationSite;
    this.client = client;
    this.driverEntities = driverEntities;
    this.disposalSiteEntities = disposalSiteEntities;
    this.carTypeEntities = carTypeEntities;
    this.carEntities = carEntities;
    this.collectablePeriodTemplateEntities = collectablePeriodTemplateEntities;
    this.officeSetting = officeSetting;
    this.option = option;
    this.rules = { required };
    this.collectablePeriodTemplateOptions = [
      DistinctTimeOption,
      ...collectablePeriodTemplateEntities.map((entity) => {
        return {
          id: entity.id,
          persistentId: entity.persistentId,
          name: entity.name,
        } as ICollectablePeriodTemplateOption;
      }),
      DesignatedTimePeriodOption,
    ];
    this.defaultAvoidHighwaysOptions = highwayOptions;
    this.defaultRouteCollectionOptions = getDefaultRouteCollectionOptions(officeSetting.defaultRouteCollectionAllowed);
    this.defaultPreloadStatusOptions = getDefaultPreloadStatusOptions(officeSetting.defaultPreloadStatus);
    this.defaultIsFixedArrivalTimeReportNeededOptions = getDefaultIsFixedArrivalTimeReportNeededOptions(
      officeSetting.defaultIsFixedArrivalTimeReportNeeded
    );
    this.marginTypeOptions = marginTypeOptions;

    this.hasRestPeriodOptions = hasRestPeriodOptions;
    this.formMode = this.generationSite === undefined ? FormMode.Register : FormMode.Edit;
    this.initialFormValues = (() => {
      // option の initialFormValues は Partial なため、補完しておく必要がある
      const initialFormValues = this.getInitialFormValues(generationSite);
      return option?.initialFormValues !== undefined
        ? {
            ...initialFormValues,
            ...option.initialFormValues,
          }
        : initialFormValues;
    })();
    this.setFormValues(this.initialFormValues);
    this.title = this.formMode === FormMode.Register ? '排出場の登録' : '排出場の編集';
    this.registerButtonLabel =
      option?.registerButtonLabel ?? (this.formMode === FormMode.Register ? '登録' : '編集完了');

    // 新規作成のときのみデフォルト設定されている排出場担当不可ドライバーを反映する
    if (this.formMode === FormMode.Register) {
      this.bannedDriverIds = officeSetting.defaultBannedDriverIdsAtGenerationSite;
    }
    this.defaultAssignableCarTypeIdsDisabledReason = new Set<DisabledReason>();
    this.defaultAssignableCarIdsDisabledReason = new Set<DisabledReason>();
    this.isFixedArrivalTimeReportDisplayed = officeSetting.isFixedArrivalTimeReportEnabled;

    // フォームに値が設定されているにも関わらず preloaded でない場合は何かが間違っているので落としておく
    if (this.clientId !== undefined && this.client === undefined) {
      throw new Error(`clientId(${this.clientId}) is set, but pre-loaded client was not found!`);
    }
    this.clientLoader = clientLoader;
    this.clientDefaultCondition = clientDefaultCondition;

    this.onClientIdChange();
    this.onChangeDefaultAssignableCarIds();
    this.onChangeDefaultAssignableCarTypeIds();
    this.onChangeDefaultIsFixedArrivalTimeReportNeeded();
  }

  private setFormValues(initialFormValues: IGenerationSiteFormValues) {
    this.clientId = initialFormValues.clientId;
    this.name = initialFormValues.name;
    this.nameRuby = initialFormValues.nameRuby;
    this.zipCode = initialFormValues.zipCode;
    this.address1 = initialFormValues.address1;
    this.address2 = initialFormValues.address2;
    this.address3 = initialFormValues.address3;
    this.address4 = initialFormValues.address4;
    this.latitude = initialFormValues.latitude;
    this.longitude = initialFormValues.longitude;
    this.note = initialFormValues.note;
    this.attachments = initialFormValues.attachments;
    this.bannedDriverIds = initialFormValues.bannedDriverIds;
    this.defaultAssignedDriverId = initialFormValues.defaultAssignedDriverId;
    this.defaultAssignedDisposalSiteId = initialFormValues.defaultAssignedDisposalSiteId;
    this.defaultAssignableCarIds = initialFormValues.defaultAssignableCarIds;
    this.defaultAssignableCarTypeIds = initialFormValues.defaultAssignableCarTypeIds;
    this.defaultAvoidHighWays = initialFormValues.defaultAvoidHighWays;
    const [hours, minutes] = getHoursAndMinutesOf(initialFormValues.defaultDurationAtEntrance);
    this.defaultDurationAtEntranceHours = hours;
    this.defaultDurationAtEntranceMinutes = minutes;
    this.hasRestPeriod = initialFormValues.hasRestPeriod;
    this.restPeriodStart = initialFormValues.restPeriodStart;
    this.restPeriodEnd = initialFormValues.restPeriodEnd;
    this.isAddressValidated = initialFormValues.isAddressValidated;
    this.isAddressComplete = initialFormValues.isAddressComplete;
    this.defaultCollectablePeriodTemplateId = initialFormValues.defaultCollectablePeriodTemplateId;
    this.defaultCollectablePeriodDistinct = initialFormValues.defaultCollectablePeriodDistinct;
    this.defaultCollectablePeriodStart = initialFormValues.defaultCollectablePeriodStart;
    this.defaultCollectablePeriodEnd = initialFormValues.defaultCollectablePeriodEnd;
    this.defaultRouteCollectionAllowed = initialFormValues.defaultRouteCollectionAllowed;
    this.defaultPreloadStatus = initialFormValues.defaultPreloadStatus;
    this.defaultIsFixedArrivalTimeReportNeeded = this.initialFormValues.defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = this.initialFormValues.defaultMarginTypeOfFixedArrivalTime;
    const [defaultMarginOfFixedArrivalTimeHours, defaultMarginOfFixedArrivalTimeMinutes] = getHoursAndMinutesOf(
      this.initialFormValues.defaultMarginOfFixedArrivalTime
    );
    this.defaultMarginOfFixedArrivalTimeHours = defaultMarginOfFixedArrivalTimeHours;
    this.defaultMarginOfFixedArrivalTimeMinutes = defaultMarginOfFixedArrivalTimeMinutes;
    this.noteForOffice = initialFormValues.noteForOffice;
  }

  getFormValues(): IGenerationSiteFormValues {
    return {
      clientId: this.clientId,
      name: this.name,
      nameRuby: this.nameRuby,
      zipCode: this.zipCode,
      address1: this.address1,
      address2: this.address2,
      address3: this.address3,
      address4: this.address4,
      latitude: this.latitude,
      longitude: this.longitude,
      note: this.note,
      attachments: this.attachments,
      inputAttachments: this.inputAttachments,
      attachmentsToAdd: this.attachmentsToAdd,
      attachmentsToRemove: this.attachmentsToRemove,
      bannedDriverIds: _.clone(this.bannedDriverIds),
      defaultAssignedDriverId: this.defaultAssignedDriverId,
      defaultAssignedDisposalSiteId: this.defaultAssignedDisposalSiteId,
      defaultAssignableCarIds: _.clone(this.defaultAssignableCarIds),
      defaultAssignableCarTypeIds: _.clone(this.defaultAssignableCarTypeIds),
      defaultAvoidHighWays: this.defaultAvoidHighWays,
      defaultDurationAtEntrance: this.defaultDurationAtEntrance,
      hasRestPeriod: this.hasRestPeriod,
      restPeriodStart: this.restPeriodStart,
      restPeriodEnd: this.restPeriodEnd,
      isAddressValidated: this.isAddressValidated,
      isAddressComplete: this.isAddressComplete,
      defaultCollectablePeriodTemplateId: this.defaultCollectablePeriodTemplateId,
      defaultCollectablePeriodDistinct: this.defaultCollectablePeriodDistinct,
      defaultCollectablePeriodStart: this.defaultCollectablePeriodStart,
      defaultCollectablePeriodEnd: this.defaultCollectablePeriodEnd,
      defaultRouteCollectionAllowed: this.defaultRouteCollectionAllowed,
      defaultPreloadStatus: this.defaultPreloadStatus,
      defaultIsFixedArrivalTimeReportNeeded: this.defaultIsFixedArrivalTimeReportNeeded,
      defaultMarginTypeOfFixedArrivalTime: this.defaultMarginTypeOfFixedArrivalTime,
      defaultMarginOfFixedArrivalTime: this.defaultMarginOfFixedArrivalTime,
      noteForOffice: this.noteForOffice,
    };
  }

  onChangeNameRuby(): void {
    if (this.nameRuby) {
      const [replacedNameRuby, containedIllegalValue] = yomiganize(this.nameRuby);
      Vue.nextTick().then(() => {
        this.nameRuby = replacedNameRuby;
        if (containedIllegalValue) {
          this.isNameRubyTooltipOpen = true;
          setTimeout(() => {
            this.isNameRubyTooltipOpen = false;
          }, 2000);
        }
      });
    }
  }

  onClientIdChange(): void {
    this.isNoteDisabled = this.clientId === undefined;
    this.isAttachmentsDisabled = this.clientId === undefined;
    this.isNoteForOfficeDisabled = this.clientId === undefined;
    this.isBannedDriverIdsDisabled = this.clientId === undefined;
    this.isDefaultAssignedDriverIdDisabled = this.clientId === undefined;
    this.isDefaultAssignedDisposalSiteIdDisabled = this.clientId === undefined;
    this.isDefaultAvoidHighwaysDisabled = this.clientId === undefined;
    this.isDefaultRouteCollectionAllowedDisabled = this.clientId === undefined;
    this.isDefaultPreloadStatusDisabled = this.clientId === undefined;
    this.isDefaultIsFixedArrivalTimeReportNeededDisabled = this.clientId === undefined;
    this.isDefaultDurationAtEntranceDisabled = this.clientId === undefined;
    this.registeredNotes = this.client?.note;
    if (this.clientId === undefined) {
      this.addDefaultAssignableCarTypeIdsDisabledReason(DisabledReason.ClientIdIsNotSelected);
      this.addDefaultAssignableCarIdsDisabledReason(DisabledReason.ClientIdIsNotSelected);
    } else {
      this.deleteDefaultAssignableCarTypeIdsDisabledReason(DisabledReason.ClientIdIsNotSelected);
      this.deleteDefaultAssignableCarIdsDisabledReason(DisabledReason.ClientIdIsNotSelected);
    }
  }

  onChangeRestPeriod(start: number, end: number): void {
    this.restPeriodStart = start;
    this.restPeriodEnd = end;
    this.updateRegisterButtonDisabled();
  }

  onChangeHasRestPeriod(): void {
    this.restPeriodStart = undefined;
    this.restPeriodEnd = undefined;
  }

  onChangeDefaultAssignableCarTypeIds(): void {
    if (this.defaultAssignableCarTypeIds.length === 0) {
      this.deleteDefaultAssignableCarIdsDisabledReason(DisabledReason.DefaultAssignableCarTypeIdsIsSelected);
    } else {
      this.addDefaultAssignableCarIdsDisabledReason(DisabledReason.DefaultAssignableCarTypeIdsIsSelected);
    }
  }

  onChangeDefaultAssignableCarIds(): void {
    if (this.defaultAssignableCarIds.length === 0) {
      this.deleteDefaultAssignableCarTypeIdsDisabledReason(DisabledReason.DefaultAssignableCarIdsIsSelected);
    } else {
      this.addDefaultAssignableCarTypeIdsDisabledReason(DisabledReason.DefaultAssignableCarIdsIsSelected);
    }
  }

  onChangeDefaultIsFixedArrivalTimeReportNeeded(): void {
    if (this.defaultIsFixedArrivalTimeReportNeeded === DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet) {
      if (this.officeSetting.defaultIsFixedArrivalTimeReportNeeded) {
        this.defaultMarginTypeOfFixedArrivalTime = this.officeSetting.defaultMarginTypeOfFixedArrivalTime;
        const [hours, minutes] = getHoursAndMinutesOf(this.officeSetting.defaultMarginOfFixedArrivalTime);
        this.defaultMarginOfFixedArrivalTimeHours = hours;
        this.defaultMarginOfFixedArrivalTimeMinutes = minutes;
        this.isDefaultMarginTypeOfFixedArrivalTimeDisabled = true;
        this.isDefaultMarginOfFixedArrivalTimeDisabled = true;
      }
    } else {
      this.isDefaultMarginTypeOfFixedArrivalTimeDisabled = false;
      this.isDefaultMarginOfFixedArrivalTimeDisabled = false;
    }
  }

  private updateIsDefaultAssignableCarTypeIdsDisabled(): void {
    this.isDefaultAssignableCarTypeIdsDisabled =
      this.clientId === undefined || 1 <= this.defaultAssignableCarTypeIdsDisabledReason.size;
  }

  private updateIsDefaultAssignableCarIdsDisabled(): void {
    this.isDefaultAssignableCarIdsDisabled =
      this.clientId === undefined || 1 <= this.defaultAssignableCarIdsDisabledReason.size;
  }

  private addDefaultAssignableCarIdsDisabledReason(reason: DisabledReason): void {
    this.defaultAssignableCarIdsDisabledReason.add(reason);
    this.updateIsDefaultAssignableCarIdsDisabled();
  }

  private deleteDefaultAssignableCarIdsDisabledReason(reason: DisabledReason): void {
    this.defaultAssignableCarIdsDisabledReason.delete(reason);
    this.updateIsDefaultAssignableCarIdsDisabled();
  }

  private addDefaultAssignableCarTypeIdsDisabledReason(reason: DisabledReason): void {
    this.defaultAssignableCarTypeIdsDisabledReason.add(reason);
    this.updateIsDefaultAssignableCarTypeIdsDisabled();
  }

  private deleteDefaultAssignableCarTypeIdsDisabledReason(reason: DisabledReason): void {
    this.defaultAssignableCarTypeIdsDisabledReason.delete(reason);
    this.updateIsDefaultAssignableCarTypeIdsDisabled();
  }

  private updateRegisterButtonDisabled(): void {
    this.isRegisterButtonDisabled = !this.isAddressValidated || !this.isFormValid;
  }

  private getInitialFormValues(generationSite: Maybe<AggregatedGenerationSiteEntity>): IGenerationSiteFormValues {
    return generationSite === undefined
      ? this.getDefaultInitialFormValues()
      : getInitialFormValuesByGenerationSite(generationSite);
  }

  private getDefaultInitialFormValues(): IGenerationSiteFormValues {
    return {
      clientId: undefined,
      name: '',
      nameRuby: '',
      zipCode: '',
      address1: '',
      address2: '',
      address3: '',
      address4: undefined,
      latitude: undefined,
      longitude: undefined,
      note: undefined,
      attachments: [],
      inputAttachments: [],
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      bannedDriverIds: [],
      defaultAssignedDriverId: undefined,
      defaultAssignedDisposalSiteId: undefined,
      defaultAssignableCarIds: [],
      defaultAssignableCarTypeIds: [],
      defaultAvoidHighWays: this.officeSetting.defaultAvoidHighways,
      defaultDurationAtEntrance: this.officeSetting.defaultDurationAtGenerationSite,
      hasRestPeriod: this.officeSetting.hasDefaultRestPeriodOfGenerationSite,
      restPeriodStart: this.officeSetting.defaultRestPeriodStartOfGenerationSite,
      restPeriodEnd: this.officeSetting.defaultRestPeriodEndOfGenerationSite,
      isAddressValidated: false,
      isAddressComplete: true,
      defaultCollectablePeriodTemplateId: undefined,
      defaultCollectablePeriodDistinct: undefined,
      defaultCollectablePeriodStart: undefined,
      defaultCollectablePeriodEnd: undefined,
      defaultPreloadStatus: DefaultPreloadStatus.NotSet,
      defaultRouteCollectionAllowed: DefaultRouteCollectionOptionsValues.NotSet,
      defaultIsFixedArrivalTimeReportNeeded: DefaultIsFixedArrivalTimeReportNeededOptionsValues.NotSet,
      defaultMarginTypeOfFixedArrivalTime: this.officeSetting.defaultMarginTypeOfFixedArrivalTime,
      defaultMarginOfFixedArrivalTime: this.officeSetting.defaultMarginOfFixedArrivalTime,
      noteForOffice: undefined,
    };
  }
}

enum EventTypes {
  Change = 'change',
}

type PopupMenuElementsType = {
  createClient: IRPopupMenuItem;
  editClient: IRPopupMenuItem;
};

const popupMenuElements: PopupMenuElementsType = {
  createClient: {
    key: 'create_client',
    title: '新規登録',
    icon: 'mdi-plus-circle-outline',
    color: VuetifyColors.Success,
  },
  editClient: {
    key: 'edit_client',
    title: '編集',
    icon: 'mdi-pencil',
    color: VuetifyColors.Primary,
  },
};

type Masters = {
  client: Maybe<AggregatedClientEntity>;
  drivers: AggregatedDriverEntity[];
  disposalSites: AggregatedDisposalSiteEntity[];
  carTypes: AggregatedCarTypeEntity[];
  cars: AggregatedCarEntity[];
  collectablePeriodTemplates: Array<CollectablePeriodTemplateEntity>;
  officeSetting: OfficeSettingEntity;
};

export default Vue.extend({
  name: 'RGenerationSiteForm',
  components: { RUploadedFile, RCollectablePeriodInput },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const generationSiteApplicationService = this.$context.applications.get(generationSiteSymbol);
    return {
      isActive: false,
      isConfirmDialogActive: false,
      isDeleteConfirmDialogActive: false,
      viewModel: undefined,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      FormMode,
      DisabledReason,
      masters: undefined,
      DefaultIsFixedArrivalTimeReportNeededOptionsValues,
      generationSiteApplicationService,
    };
  },
  computed: {
    clientDetailItems(): IRPopupMenuItem[] {
      return this.viewModel?.client !== undefined
        ? [popupMenuElements.editClient, popupMenuElements.createClient]
        : [popupMenuElements.createClient];
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.GENERATION_SITE;
    },
    isDefaultCollectablePeriodDisabled(): boolean {
      return this.viewModel?.clientId === undefined;
    },
    isCollectableTimeDistinct(): boolean {
      if (this.viewModel === undefined) return false;
      if (this.viewModel.defaultCollectablePeriodTemplateId === DistinctTimeOptionId) return true;

      return false;
    },
    isCollectableTimeDesignated(): boolean {
      if (this.viewModel === undefined) return false;
      if (this.viewModel.defaultCollectablePeriodTemplateId === DesignatedTimePeriodOptionId) return true;

      return false;
    },
    isCollectablePeriodTimeRequired(): boolean {
      if (this.viewModel === undefined) return false;

      // NOTE: 時間厳守の場合は入力必須
      if (this.isCollectableTimeDistinct) return true;

      // NOTE: templateId が指定されている場合は start, end のどちらかは最低限入力しなければならない
      if (
        this.viewModel.defaultCollectablePeriodTemplateId !== undefined &&
        this.viewModel.defaultCollectablePeriodStart === undefined &&
        this.viewModel.defaultCollectablePeriodEnd === undefined
      )
        return true;

      return false;
    },
    selectedCollectablePeriodTemplateEntity(): Maybe<CollectablePeriodTemplateEntity> {
      if (this.viewModel === undefined) return;
      if (this.viewModel.defaultCollectablePeriodTemplateId === undefined) return;
      if (
        this.viewModel.defaultCollectablePeriodTemplateId === DistinctTimeOptionId ||
        this.viewModel.defaultCollectablePeriodTemplateId === DesignatedTimePeriodOptionId
      )
        return;

      const defaultCollectablePeriodTemplateId = this.viewModel.defaultCollectablePeriodTemplateId;

      return this.viewModel.collectablePeriodTemplateEntities.find(
        (entity) => entity.id === defaultCollectablePeriodTemplateId
      );
    },
  },
  watch: {
    'viewModel.inputAttachments'(input: File[]) {
      if (this.viewModel === undefined || input.length === 0) return;
      const addedFiles = input.filter((attachment) => {
        return !this.viewModel!.attachmentsToAdd.map((a) => {
          return a.name;
        }).includes(attachment.name);
      });
      if (addedFiles.length !== 0) {
        this.viewModel.attachmentsToAdd = [...this.viewModel.attachmentsToAdd, ...addedFiles];
      }
      this.viewModel.inputAttachments = [];
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.generationSiteFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.generationSiteFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    maxLength,
    sanitizeHours,
    sanitizeMinutes,
    async open(
      generationSite: Maybe<AggregatedGenerationSiteEntity>,
      option?: IGenerationSiteFormPanelOption
    ): Promise<void> {
      if (this.isActive) return;

      const clientApplicationService = this.$context.applications.get(clientSymbol);
      const driverApplicationService = this.$context.applications.get(driverSymbol) as DriverApplicationService;
      const disposalSiteApplicationService = this.$context.applications.get(disposalSiteSymbol);
      const carTypeApplicationService = this.$context.applications.get(carTypeSymbol);
      const carApplicationService = this.$context.applications.get(carSymbol);
      const collectablePeriodTemplateApplicationService = this.$context.applications.get(
        collectablePeriodTemplateSymbol
      );
      const officeSettingApplicationService = this.$context.applications.get(officeSettingSymbol);

      const clientId = option?.initialFormValues?.clientId ?? generationSite?.client.id;
      const [client, drivers, disposalSites, carTypes, cars, collectablePeriodTemplates, officeSetting] =
        await Promise.all([
          clientId !== undefined ? clientApplicationService.getById(clientId) : undefined,
          driverApplicationService.getAll(),
          disposalSiteApplicationService.getAll(),
          carTypeApplicationService.getAll(),
          carApplicationService.getAll(),
          collectablePeriodTemplateApplicationService.getAll(),
          officeSettingApplicationService.get(),
        ]);

      this.masters = {
        client,
        drivers,
        disposalSites,
        carTypes,
        cars,
        collectablePeriodTemplates,
        officeSetting,
      };

      // 乗務員のリストは all で取りたいものの過去に作成された排出場の乗務員は論理削除されている可能性がある
      // この様な乗務員は別途 ID を指定して取得する
      const existingDriverIdSet: Set<PersistentId> = new Set<PersistentId>();
      if (generationSite !== undefined) {
        if (generationSite.defaultAssignedDriver !== undefined)
          existingDriverIdSet.add(generationSite.defaultAssignedDriver.id);
        if (generationSite.bannedDrivers.length !== 0)
          existingDriverIdSet.addValues(...ids(generationSite.bannedDrivers));
      }

      if (existingDriverIdSet) {
        const allDriverIdSet = new Set(ids(drivers));
        const deletedDriverIds = existingDriverIdSet.toArray().filter((driverId) => !allDriverIdSet.has(driverId));
        if (deletedDriverIds) {
          const deletedDrivers = await driverApplicationService.getByIds(deletedDriverIds);
          drivers.concat(deletedDrivers);
        }
      }

      const clientDefaultCondition = {
        keywords: undefined,
        since: undefined,
        orderBy: ClientsByKeywordsOrder.CreatedAtDesc,
      };
      const clientLoader = new Client(this.$context, clientDefaultCondition);
      this.viewModel = new GenerationSiteForm(
        clientDefaultCondition,
        clientLoader,
        generationSite,
        client,
        drivers,
        disposalSites,
        carTypes,
        cars,
        collectablePeriodTemplates,
        officeSetting,
        option
      );
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @param preserveContext このパネルが開かれたコンテキストを尊重してコールバックを呼ぶべきかどうか
     */
    async close(preserveContext: boolean): Promise<void> {
      if (this.isActive === false) return;
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<AggregatedGenerationSiteEntity> = {
        entity: viewModel.generationSite,
        createdEntity: viewModel.createdGenerationSite,
        updatedEntity: viewModel.updatedGenerationSite,
        removedEntity: undefined,
      };
      this.$context.panels.generationSiteFormPanel.closeFormEvent.emit(closeFormArgs);
      if (preserveContext && viewModel.option?.closeCallback !== undefined) {
        if (viewModel.option.closeCallback instanceof Promise) {
          await viewModel.option.closeCallback(closeFormArgs.createdEntity);
        } else {
          viewModel.option.closeCallback(closeFormArgs.createdEntity);
        }
      }
    },
    async onOpenForm(
      args: IOpenEntityFormArgs<AggregatedGenerationSiteEntity, IGenerationSiteFormPanelOption>
    ): Promise<void> {
      await this.open(args.entity, args.option);
    },
    /**
     * @param forceClose 編集した状態であってもダイアログ表示せずに強制的に閉じる場合にtrue
     * @param preserveContext このパネルが開かれたコンテキストを尊重してコールバックを呼ぶべきかどうか
     */
    async onCloseForm(forceClose: boolean = false, preserveContext: boolean = false): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (forceClose === false && this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close(preserveContext);
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    async onRegisterButtonClicked(continueRegistration: boolean): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;

      // NOTE: 到着時間テンプレート、時間指定の設定によって初期化する内容を変える
      const {
        defaultCollectablePeriodTemplateId,
        defaultCollectablePeriodStart,
        defaultCollectablePeriodEnd,
      }: {
        defaultCollectablePeriodTemplateId: Maybe<string>;
        defaultCollectablePeriodStart: Maybe<number>;
        defaultCollectablePeriodEnd: Maybe<number>;
      } = (() => {
        // NOTE: ユーザー指定の template id が指定されている場合はフォームの時間は送らない
        if (
          viewModel.defaultCollectablePeriodTemplateId !== undefined &&
          viewModel.defaultCollectablePeriodTemplateId !== DistinctTimeOptionId &&
          viewModel.defaultCollectablePeriodTemplateId !== DesignatedTimePeriodOptionId
        ) {
          return {
            defaultCollectablePeriodTemplateId: viewModel.defaultCollectablePeriodTemplateId,
            defaultCollectablePeriodStart: undefined,
            defaultCollectablePeriodEnd: undefined,
          };
        }

        // NOTE: 時間厳守の場合は start と end を同じにして送る
        // NOTE: 時間厳守、時間指定は内部で持っている一時的な id なので送らない
        if (viewModel.defaultCollectablePeriodTemplateId === DistinctTimeOptionId) {
          return {
            defaultCollectablePeriodTemplateId: undefined,
            defaultCollectablePeriodStart: viewModel.defaultCollectablePeriodDistinct,
            defaultCollectablePeriodEnd: viewModel.defaultCollectablePeriodDistinct,
          };
        }
        return {
          defaultCollectablePeriodTemplateId: undefined,
          defaultCollectablePeriodStart: viewModel.defaultCollectablePeriodStart,
          defaultCollectablePeriodEnd: viewModel.defaultCollectablePeriodEnd,
        };
      })();

      if (viewModel.formMode === FormMode.Register) {
        const entity = await this.generationSiteApplicationService.create({
          clientId: viewModel.clientId!,
          name: viewModel.name,
          nameRuby: viewModel.nameRuby,
          zipCode: viewModel.zipCode,
          address1: viewModel.address1,
          address2: viewModel.address2,
          address3: viewModel.address3,
          address4: viewModel.address4,
          latitude: viewModel.latitude!,
          longitude: viewModel.longitude!,
          bannedDriverIds: viewModel.bannedDriverIds,
          defaultAssignedDriverId: viewModel.defaultAssignedDriverId,
          defaultAssignedDisposalSiteId: viewModel.defaultAssignedDisposalSiteId,
          defaultAssignableCarIds: viewModel.defaultAssignableCarIds,
          defaultAssignableCarTypeIds: viewModel.defaultAssignableCarTypeIds,
          defaultAvoidHighways: viewModel.defaultAvoidHighWays,
          defaultDurationAtEntrance: viewModel.defaultDurationAtEntrance,
          restPeriodStart: viewModel.restPeriodStart,
          restPeriodEnd: viewModel.restPeriodEnd,
          note: viewModel.note,
          attachmentsToAdd: viewModel.attachmentsToAdd,
          isAddressComplete: true,
          status: SoftDeleteStatus.Active,
          defaultCollectablePeriodTemplateId,
          defaultCollectablePeriodStart,
          defaultCollectablePeriodEnd,
          defaultRouteCollectionAllowed: defaultRouteCollectionEnumToApiValueMap.get(
            viewModel.defaultRouteCollectionAllowed
          ),
          defaultPreloadStatus: defaultPreloadStatusEnumToApiValueMap.get(viewModel.defaultPreloadStatus),
          defaultIsFixedArrivalTimeReportNeeded: defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap.get(
            viewModel.defaultIsFixedArrivalTimeReportNeeded
          ),
          defaultMarginTypeOfFixedArrivalTime: viewModel.defaultMarginTypeOfFixedArrivalTime,
          defaultMarginOfFixedArrivalTime: viewModel.defaultMarginOfFixedArrivalTime,
          noteForOffice: viewModel.noteForOffice,
        });

        this.viewModel.createdGenerationSite = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        if (viewModel.generationSite === undefined) throw new Error('Impossible!');
        const generationSite = viewModel.generationSite;
        const entity = await this.generationSiteApplicationService.update({
          id: generationSite.persistentId,
          clientId: viewModel.clientId!,
          name: viewModel.name,
          nameRuby: viewModel.nameRuby,
          zipCode: viewModel.zipCode,
          address1: viewModel.address1,
          address2: viewModel.address2,
          address3: viewModel.address3,
          address4: viewModel.address4,
          latitude: viewModel.latitude!,
          longitude: viewModel.longitude!,
          bannedDriverIds: viewModel.bannedDriverIds,
          defaultAssignedDriverId: viewModel.defaultAssignedDriverId,
          defaultAssignedDisposalSiteId: viewModel.defaultAssignedDisposalSiteId,
          defaultAssignableCarIds: viewModel.defaultAssignableCarIds,
          defaultAssignableCarTypeIds: viewModel.defaultAssignableCarTypeIds,
          defaultAvoidHighways: viewModel.defaultAvoidHighWays,
          defaultDurationAtEntrance: viewModel.defaultDurationAtEntrance,
          restPeriodStart: viewModel.restPeriodStart,
          restPeriodEnd: viewModel.restPeriodEnd,
          note: viewModel.note,
          attachmentsToAdd: viewModel.attachmentsToAdd,
          attachmentsToRemove: viewModel.attachmentsToRemove,
          isAddressComplete: viewModel.isAddressComplete,
          status: generationSite.status,
          defaultCollectablePeriodTemplateId,
          defaultCollectablePeriodStart,
          defaultCollectablePeriodEnd,
          defaultRouteCollectionAllowed: defaultRouteCollectionEnumToApiValueMap.get(
            viewModel.defaultRouteCollectionAllowed
          ),
          defaultPreloadStatus: defaultPreloadStatusEnumToApiValueMap.get(viewModel.defaultPreloadStatus),
          defaultIsFixedArrivalTimeReportNeeded: defaultIsFixedArrivalTimeReportNeededEnumToApiValueMap.get(
            viewModel.defaultIsFixedArrivalTimeReportNeeded
          ),
          defaultMarginTypeOfFixedArrivalTime: viewModel.defaultMarginTypeOfFixedArrivalTime,
          defaultMarginOfFixedArrivalTime: viewModel.defaultMarginOfFixedArrivalTime,
          noteForOffice: viewModel.noteForOffice,
        });

        this.viewModel.updatedGenerationSite = entity;
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(continueRegistration ? RinEventNames.COMPLETE_INPUT_CONTINUE : RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.GENERATION_SITE,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('排出場の登録完了');
      await this.close(true);
      if (continueRegistration) {
        await this.$context.panels.generationSiteFormPanel.open(undefined);
      }
    },
    onClickDeleteButton(): void {
      this.isDeleteConfirmDialogActive = true;
    },
    async onConfirmDelete(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      if (this.viewModel.generationSite === undefined) throw new Error(`Impossible!`);
      if (this.viewModel.formMode !== FormMode.Edit) throw new Error(`Impossible!`);

      this.viewModel.isRegistering = true;
      this.viewModel.updatedGenerationSite = await this.generationSiteApplicationService.delete(
        this.viewModel.generationSite.persistentId
      );
      this.viewModel.isRegistering = false;
      this.$context.snackbar.success('排出場の削除完了');

      this.$rinGtm.push(RinEventNames.DELETE, {
        [AdditionalInfoKeys.GENERATION_SITE_ID]: this.viewModel.generationSite.persistentId,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.GENERATION_SITE,
        [AdditionalInfoKeys.REFERRER]: PageNames.GENERATION_SITE_FORM,
      });

      await this.close(true);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(() => this.onCloseForm(false, true));
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.generationSiteFormPanel.updateDisplayedWidth(value);
    },

    onChangeCollectablePeriodTemplate(id: Maybe<string>): void {
      if (this.viewModel === undefined) return;

      // NOTE: フォームをクリアしたら時刻もクリアする
      if (id === undefined) {
        this.viewModel.defaultCollectablePeriodDistinct = undefined;
        this.viewModel.defaultCollectablePeriodStart = undefined;
        this.viewModel.defaultCollectablePeriodEnd = undefined;

        return;
      }

      // NOTE: 時間厳守の場合は start, end をクリアする
      if (id === DistinctTimeOptionId) {
        this.viewModel.defaultCollectablePeriodStart = undefined;
        this.viewModel.defaultCollectablePeriodEnd = undefined;

        return;
      }

      // NOTE: 時間厳守以外のテンプレートが選択されると、時間厳守の時刻をクリアする
      this.viewModel.defaultCollectablePeriodDistinct = undefined;

      // NOTE: ユーザーが作成した到着時間テンプレートが指定された場合
      // NOTE: 到着時間テンプレートに設定された時刻をセットする
      if (id !== DesignatedTimePeriodOptionId) {
        const selectedCollectablePeriodTemplate = this.masters?.collectablePeriodTemplates.find((template) => {
          return template.persistentId === id;
        });

        if (selectedCollectablePeriodTemplate === undefined) {
          throw new Error(`CollectablePeriodTemplate ${id} is not found!`);
        }

        this.viewModel.defaultCollectablePeriodStart = selectedCollectablePeriodTemplate.collectablePeriodStart;
        this.viewModel.defaultCollectablePeriodEnd = selectedCollectablePeriodTemplate.collectablePeriodEnd;
      }
    },
    onChangeDefaultCollectablePeriodStart(value: Maybe<number>): void {
      if (this.viewModel === undefined) return;
      if (value === undefined) return;
      if (this.viewModel.defaultCollectablePeriodTemplateId === DesignatedTimePeriodOptionId) return;

      // NOTE: テンプレート設定中に値を変更せずにイベントが発火する場合はテンプレートを変更しない
      // 例: [午前中] 8:00 ~ 12:00 が設定されているときの time-picker にフォーカスして 8:00 を選択したときに値が変更されていないのにイベントが発火してしまうので、それをガードする
      if (
        this.selectedCollectablePeriodTemplateEntity !== undefined &&
        this.selectedCollectablePeriodTemplateEntity.collectablePeriodStart === value
      )
        return;

      // NOTE: 時間が変更されると、テンプレートを時間指定に変更する
      this.viewModel.defaultCollectablePeriodTemplateId = DesignatedTimePeriodOptionId;
    },
    onChangeDefaultCollectablePeriodEnd(value: Maybe<number>): void {
      if (this.viewModel === undefined) return;
      if (value === undefined) return;
      if (this.viewModel.defaultCollectablePeriodTemplateId === DesignatedTimePeriodOptionId) return;
      // NOTE: テンプレート設定中に値を変更せずにイベントが発火する場合はテンプレートを変更しない
      // 例: [午前中] 8:00 ~ 12:00 が設定されているときの time-picker にフォーカスして 8:00 を選択したときに値が変更されていないのにイベントが発火してしまうので、それをガードする
      if (
        this.selectedCollectablePeriodTemplateEntity !== undefined &&
        this.selectedCollectablePeriodTemplateEntity.collectablePeriodEnd === value
      )
        return;

      // NOTE: 時間が変更されると、テンプレートを時間指定に変更する
      this.viewModel.defaultCollectablePeriodTemplateId = DesignatedTimePeriodOptionId;
    },
    async onClickCreateClientButton(): Promise<void> {
      const searchInput = (this.$refs.searchablePulldown as LazySearchablePullDownType).searchInput;
      await this.openClientPanel(popupMenuElements.createClient, searchInput);
    },
    async onClickCreateClientPopup(item: IRPopupMenuItem): Promise<void> {
      await this.openClientPanel(item);
    },
    async openClientPanel(openElement: IRPopupMenuItem, initialClientName?: string) {
      if (openElement.key === popupMenuElements.editClient.key && initialClientName !== undefined)
        throw new Error('set initialClientName with EditClient key');
      const viewModel = this.viewModel;
      const context = this.$context;
      if (viewModel === undefined) throw new Error('viewModel is undefined');
      const option = viewModel.option || {};
      const formValues = viewModel.getFormValues();
      const generationSite = viewModel.generationSite;
      viewModel.option = undefined;
      const targetClient = await this.getClientDataToEdit(
        openElement.key === popupMenuElements.editClient.key ? viewModel.client?.id : undefined
      );
      const clientEditMessage = openElement.key === popupMenuElements.createClient.key ? '登録' : '編集完了';
      const generationSiteEditMessage = viewModel.formMode === FormMode.Register ? '登録' : '編集';

      if (openElement.key === popupMenuElements.createClient.key) {
        this.$rinGtm.push(RinEventNames.OPEN_FORM, {
          [AdditionalInfoKeys.TARGET]: FormTargetParams.CLIENT,
          [AdditionalInfoKeys.REFERRER]: PageNames.GENERATION_SITE_FORM,
        });
      } else if (openElement.key === popupMenuElements.editClient.key && targetClient) {
        this.$rinGtm.push(RinEventNames.OPEN_FORM, {
          [AdditionalInfoKeys.CLIENT_ID]: targetClient.client.persistentId,
          [AdditionalInfoKeys.TARGET]: FormTargetParams.CLIENT,
          [AdditionalInfoKeys.REFERRER]: PageNames.GENERATION_SITE_FORM,
        });
      }

      await context.panels.clientFormPanel.open(targetClient, {
        forceClose: true,
        registerButtonLabel: `${clientEditMessage}して排出場の${generationSiteEditMessage}を続ける`,
        initialFormValues:
          openElement.key === popupMenuElements.createClient.key
            ? {
                name: initialClientName,
              }
            : undefined,
        closeCallback: (client: Maybe<AggregatedClientEntity>) => {
          formValues.clientId = client?.id;
          if (this.masters !== undefined) this.masters.client = client;
          option.initialFormValues = formValues;
          context.panels.generationSiteFormPanel.open(generationSite, option);
        },
      });
    },
    onRemoveUploadingFile(fileName: string): void {
      if (this.viewModel === undefined) return;
      this.viewModel.attachmentsToAdd = this.viewModel.attachmentsToAdd.filter((attachment) => {
        return fileName !== attachment.name;
      });
    },
    async getClientDataToEdit(clientId: Maybe<string>) {
      if (clientId === undefined) return undefined;
      const service = this.$context.applications.get(clientSymbol);
      const client = await service.getWithGenerationSiteNumById(clientId);
      return { client: client.entity, generationSiteNum: client.generationSiteNum };
    },
  },
});
