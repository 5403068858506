import { StoredMapperBase } from '~/framework/core/mapper';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { IUserData } from '~/framework/server-api/masters/user';

export class UserMapper extends StoredMapperBase<IUserData, UserEntity> {
  protected store: UserStore;

  constructor(store: UserStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IUserData): UserEntity {
    return new UserEntity(data);
  }

  protected updateWithData(data: IUserData, entity: UserEntity): void {
    entity.update(data);
  }
}
