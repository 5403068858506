import { AllJwnetWasteMastersQuery, AllJwnetWasteMastersQueryVariables } from '@/graphql/graphQLServerApi';
import { AllJwnetWasteMasters } from '@/../graphql/server-api/queries/jwnet-waste-master.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllJwnetWasteMastersQuery, AllJwnetWasteMastersQueryVariables>({
      query: AllJwnetWasteMasters,
      variables: {},
    });
    return result.allJwnetWasteMasters;
  }
}
