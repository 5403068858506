import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { CheckItem } from '~/graphql/graphQLServerApi';

export class CheckItemEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  default: boolean;
  status: SoftDeleteStatus;

  constructor(data: CheckItem) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.default = data.default;
    this.status = data.status;
  }
}
