import { IOrderData } from '~/framework/server-api/schedule/order/order';
import { Maybe, PersistentId } from '~/framework/typeAliases';

export const order$latestOrders = Symbol('order$latestOrders');

export interface ILatestOrdersApi {
  [order$latestOrders]: void;
  latestOrders(condition: ILatestOrdersCondition): Promise<IOrderData[]>;
}

export interface ILatestOrdersCondition {
  clientId: Maybe<PersistentId>;
  generationSiteIds: PersistentId[];
  startAt: Maybe<Date>;
  endAt: Maybe<Date>;
  createdStartAt: Maybe<Date>;
  createdEndAt: Maybe<Date>;
  note: Maybe<string>;
  wasteTypeId: Maybe<PersistentId>;
  includeCancelled: Maybe<boolean>;
  includeFollowingRecurringOrder: Maybe<boolean>;
  onlyFixedDisplayOnReservation: Maybe<boolean>;
}
