
import Vue, { PropType } from 'vue';
import ROrderFormSelect, {
  ItemTextColorClassType,
} from '~/components/panels/schedule/r-order-form/ROrderFormSelect.vue';
import { VuetifyColors } from '~/framework/constants';
import { PreloadStatus } from '~/framework/domain/typeAliases';
import DateCollectablePeriodTypes from '~/components/panels/schedule/r-order-form/dateCollectablePeriodTypes';

type ItemType = {
  title: string;
  value: DateCollectablePeriodTypes;
  icon: string;
  class: string;
  color: VuetifyColors;
};

type DataType = {
  items: ItemType[];
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RDateCollectablePeriodTypeInput',
  components: {
    ROrderFormSelect,
  },
  model: {
    prop: 'value',
    event: EventTypes.Change,
  },

  props: {
    value: {
      type: undefined as any as PropType<DateCollectablePeriodTypes>,
      required: false,
      default: undefined,
    },
    isDateCollectablePeriodTypeInputDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    preloadStatus: {
      type: String as PropType<PreloadStatus>,
      required: true,
    },
  },
  data(): DataType {
    return {
      items: [
        {
          title: '繰り返し',
          value: DateCollectablePeriodTypes.Recurring,
          icon: 'mdi-autorenew',
          class: ItemTextColorClassType.Primary,
          color: VuetifyColors.Primary,
        },
        {
          title: '候補日を登録',
          value: DateCollectablePeriodTypes.Plan,
          icon: 'mdi-calendar-multiselect',
          class: ItemTextColorClassType.Primary,
          color: VuetifyColors.Primary,
        },
        {
          title: '複数受注の一括登録',
          value: DateCollectablePeriodTypes.BatchRegister,
          icon: 'mdi-checkbox-multiple-blank-outline',
          class: ItemTextColorClassType.Primary,
          color: VuetifyColors.Primary,
        },
        {
          title: '詳細設定をリセット',
          value: DateCollectablePeriodTypes.Reset,
          icon: 'mdi-close',
          class: ItemTextColorClassType.Error,
          color: VuetifyColors.Error,
        },
      ],
    };
  },
  computed: {
    computedItems(): ItemType[] {
      return this.items.filter((item) => {
        // 宵積みのときは、繰り返しを表示しない
        if (this.preloadStatus === PreloadStatus.Forced && item.value === DateCollectablePeriodTypes.Recurring) {
          return false;
        }
        // 何も選択していない時は、リセットを表示しない
        if (this.value === DateCollectablePeriodTypes.Default && item.value === DateCollectablePeriodTypes.Reset) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    async onChangeValue(value: DateCollectablePeriodTypes) {
      this.$emit(EventTypes.Change, value);
      // 値が変更された場合、各モーダルの編集完了後に値が変わるので、一度リセットしておく
      if (this.value !== value) {
        await Vue.nextTick();
        (this.$refs.select as any).reset();
      }
    },
  },
});
