
import Vue, { PropType } from 'vue';
import { CssStyles } from '~/framework/typeAliases';

/**
 * データテーブル的なもののヘッダーの行は似たような使い方をしそうなので共通化するためのもの
 */
export default Vue.extend({
  name: 'RHeaderRow',
  props: {
    height: {
      type: String as PropType<string>,
      required: false,
      default: 'fit-content',
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: '100%',
    },
    minWidth: {
      type: String as PropType<string>,
      required: false,
      default: 'undefined',
    },
  },
  computed: {
    styles(): CssStyles {
      return {
        height: this.height,
        width: this.width,
        minWidth: this.minWidth,
      };
    },
  },
});
