import {
  AllCarsQuery,
  AllCarsQueryVariables,
  CarsByIdsQuery,
  CarsByIdsQueryVariables,
  CreateCarMutation,
  CreateCarMutationVariables,
  UpdateCarMutation,
  UpdateCarMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllCars, CarsByIds } from '@/../graphql/server-api/queries/car.gql';
import { CreateCar, UpdateCar } from '@/../graphql/server-api/mutations/car.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateData, IUpdateData } from '~/framework/server-api/masters/car';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllCarsQuery, AllCarsQueryVariables>({
      query: AllCars,
      variables: {},
    });
    return result.allCars;
  }
}

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<CarsByIdsQuery, CarsByIdsQueryVariables>({
      query: CarsByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.carsByIds, `CarsByIds`);
    return result.carsByIds;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: ICreateData) {
    const result = await this.mutate<CreateCarMutation, CreateCarMutationVariables>({
      mutation: CreateCar,
      variables: { car: data },
    });
    return result.createCar;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateData) {
    const result = await this.mutate<UpdateCarMutation, UpdateCarMutationVariables>({
      mutation: UpdateCar,
      variables: { car: data },
    });
    return result.updateCar;
  }
}
