
import Vue, { PropType } from 'vue';
import { IRTabItem } from '~/components/common/r-tabs/rTabItem';
import { CssStyles } from '~/framework/typeAliases';

enum EventTypes {
  InputValue = 'input',
}

/**
 * v-tabs をそのまま使ってしまうとタブのバーの左上と右上に角丸が付かないのと
 * タブの中のスタイルも合わないので苦肉の策として独自コンポーネントとして定義している
 * マスター系の画面では基本的にこれを使う予定
 */
export default Vue.extend({
  name: 'RTabs',
  props: {
    tabs: {
      type: Array as PropType<IRTabItem[]>,
      required: false,
      default: undefined,
    },
    value: {
      // ただのタブの表示であればタブの index を示す number でいいのだが、nuxt リンクにすると
      // パスが設定された string になってしまうので number と string 両方受け付ける様にしている
      // こうしないとパラメータが合わない旨のエラーになってしまう
      type: undefined as any as PropType<number | string>,
      required: false,
      default: 0,
    },
  },
  methods: {
    onChangeTab(value: number) {
      // value をそのまま bind できる様に input のイベントを発火している
      this.$emit(EventTypes.InputValue, value);
    },
    getStyleOf(index: number): CssStyles {
      return {
        width: this.tabs[index].width,
      };
    },
    getClassOf(index: number): CssStyles {
      return {
        'r-tabs__tab': true,
        'r-tabs__tab--first': index === 0,
      };
    },
  },
});
