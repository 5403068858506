
import Vue, { PropType } from 'vue';
import {
  HideEventNames,
  ITooltipDisplayData,
  ShowEventNames,
  TooltipDisplayData,
} from '~/components/common/r-v-tooltip/tooltip';

type DataType = {
  tooltipDisplayData: ITooltipDisplayData;
};

export default Vue.extend({
  name: 'RVTooltip',
  inheritAttrs: false,
  props: {
    showEventName: {
      type: String as PropType<ShowEventNames>,
      required: false,
      default: ShowEventNames.Mouseenter,
    },
    hideEventName: {
      type: String as PropType<HideEventNames>,
      required: false,
      default: HideEventNames.Mouseleave,
    },
  },
  data(): DataType {
    return {
      tooltipDisplayData: new TooltipDisplayData(),
    };
  },
  methods: {
    onMouseenterElement(event: MouseEvent): void {
      const positionX = event.clientX;
      const positionY = event.clientY;

      if (this.showEventName === ShowEventNames.Mouseenter) this.tooltipDisplayData.showTooltip(positionX, positionY);
    },
    onMouseleaveElement(): void {
      if (this.hideEventName === HideEventNames.Mouseleave) this.tooltipDisplayData.hideTooltip();
    },
    onClickElement(event: MouseEvent): void {
      const positionX = event.clientX;
      const positionY = event.clientY;

      if (!this.tooltipDisplayData.active && this.showEventName === ShowEventNames.Click) {
        this.tooltipDisplayData.showTooltip(positionX, positionY);
      } else if (this.tooltipDisplayData.active && this.hideEventName === HideEventNames.Click) {
        this.tooltipDisplayData.hideTooltip();
      }
    },
  },
});
