import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { IAbstractSiteData } from '~/framework/server-api/masters/abstractSite';
import { Maybe } from '~/framework/typeAliases';

export class AbstractSiteEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  nameRuby: string;
  zipCode: Maybe<string>;
  address1: string;
  address2: string;
  address3: string;
  address4: Maybe<string>;
  latitude: number;
  longitude: number;
  createdAt: Date;
  createdBy: UserEntity;

  constructor(data: IAbstractSiteData, createdBy: UserEntity) {
    this.id = data.id;
    this.persistentId = data.id;
    this.name = data.name;
    this.nameRuby = data.nameRuby;
    this.zipCode = data.zipCode;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.address3 = data.address3;
    this.address4 = data.address4;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.createdAt = data.createdAt;
    this.createdBy = createdBy;
  }

  get address() {
    const components = [];
    if (this.zipCode !== undefined && this.zipCode !== '') {
      components.push(`〒${this.zipCode}`);
    }
    if (this.address1 !== '') components.push(this.address1);
    if (this.address2 !== '') components.push(this.address2);
    if (this.address3 !== '') components.push(this.address3);
    if (this.address4 !== undefined && this.address4 !== '') components.push(this.address4);
    return components.join(' ');
  }
}
