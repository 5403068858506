import { MapperBase } from '~/framework/core/mapper';
import { IrregularTaskEntity } from '~/framework/domain/schedule/order/irregular-task/irregularTaskEntity';
import { IIrregularTaskData } from '~/framework/server-api/schedule/order/irregular-task/irregularTask';

/**
 * トラックする必要はないので Mapper は厳密には必要ないが、同じ作法にしておいた方が
 * 分かりやすそうなので用意している
 */

export class IrregularTaskMapper extends MapperBase<IIrregularTaskData, IrregularTaskEntity> {
  protected instantiateWithData(data: IIrregularTaskData): IrregularTaskEntity {
    return new IrregularTaskEntity(data);
  }
}
