
import Vue, { PropType } from 'vue';
import { generateUrl } from '~/framework/services/google-maps/url';
import { RinEventNames } from '~/framework/services/rin-events/rinEventParams';

export default Vue.extend({
  name: 'RPlaceIconAndAddress',
  props: {
    address: {
      type: String as PropType<string>,
      required: true,
    },
    latitude: {
      type: Number as PropType<number>,
      required: true,
    },
    longitude: {
      type: Number as PropType<number>,
      required: true,
    },
    allowTextTruncate: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickPlace(): void {
      this.$rinGtm.push(RinEventNames.OPEN_MAP);
      window.open(generateUrl(this.latitude, this.longitude), '_blank');
    },
  },
});
