import { IGenerationSiteData } from './generationSite';
import { RoutingRegulationType } from '~/framework/domain/typeAliases';
import { IUserData } from '~/framework/server-api/masters/user';
import { PersistentId } from '~/framework/typeAliases';
import { CreateRoutingRegulationMutation, UpdateRoutingRegulationMutation } from '~/graphql/graphQLServerApi';
export const routingRegulation$getByIdsSymbol = Symbol('routingRegulation$getByIds');
export const routingRegulation$getAllSymbol = Symbol('routingRegulation$getAll');
export const routingRegulation$createSymbol = Symbol('routingRegulation$create');
export const routingRegulation$updateSymbol = Symbol('routingRegulation$update');
export const routingRegulation$deleteSymbol = Symbol('routingRegulation$delete');

export type ApiTypes = {
  [routingRegulation$getByIdsSymbol]: IGetByIdsApi;
  [routingRegulation$getAllSymbol]: IGetAllApi;
  [routingRegulation$createSymbol]: ICreateApi;
  [routingRegulation$updateSymbol]: IUpdateApi;
  [routingRegulation$deleteSymbol]: IDeleteApi;
};

export interface IRoutingRegulationData {
  id: string;
  name: string;
  createdBy: IUserData;
  updatedBy: IUserData;
  createdAt: Date;
  updatedAt: Date;
}

export type ICreateResultTypes = CreateRoutingRegulationMutation['createRoutingRegulation'];
export type IUpdateResultTypes = UpdateRoutingRegulationMutation['updateRoutingRegulation'];

// NOTE: 今後排出場だけでなく、廃棄物種別・会社を追加する可能性があるため、 interface を拡張してある
export interface IRoutingRegulationGenerationSiteData extends IRoutingRegulationData {
  generationSites: IGenerationSiteData[];
  optionalGenerationSites: IGenerationSiteData[];
  preserveGenerationSiteSequence: boolean;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IRoutingRegulationGenerationSiteData[]>;
}

export interface IGetAllApi {
  getAll(): Promise<IRoutingRegulationGenerationSiteData[]>;
}

export interface ICreateData {
  name: string;
  routingRegulationType: RoutingRegulationType;
  generationSiteIds: string[];
  optionalGenerationSiteIds: string[];
  preserveGenerationSiteSequence: boolean;
}

export interface ICreateApi {
  create(data: ICreateData): Promise<ICreateResultTypes>;
}

export interface IUpdateData {
  id: string;
  name: string;
  routingRegulationType: RoutingRegulationType;
  generationSiteIds: string[];
  optionalGenerationSiteIds: string[];
  preserveGenerationSiteSequence: boolean;
}

export interface IUpdateApi {
  update(data: IUpdateData): Promise<IUpdateResultTypes>;
}

export interface IDeleteApi {
  delete(data: PersistentId): Promise<PersistentId>;
}
