import { MarginType, PreloadStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { WithoutId, Maybe } from '~/framework/typeAliases';

import { GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';

import { IConnection } from '~/framework/server-api/connection';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import { IDisposalSiteData } from './disposalSite';
import { IClientData } from './client';
import { IDriverData } from './driver';
import { ISimpleCarTypeData } from './carType';
import { ICarData } from './car';
import { ICollectablePeriodTemplateData } from './collectablePeriodTemplate';
import { IAbstractSiteData } from './abstractSite';

export const generationSite$createSymbol = Symbol('generationSite$createApi');
export const generationSite$getByIdsSymbol = Symbol('generationSite$getByIdsSymbol');
export const generationSite$getByKeywordsSymbol = Symbol('generationSite$getByKeywordsSymbol');
export const generationSite$updateSymbol = Symbol('generationSite$updateApi');

export type GenerationSite = {
  [generationSite$getByIdsSymbol]: IGetByIdsApi;
  [generationSite$getByKeywordsSymbol]: IGetByKeywordsApi;
  [generationSite$createSymbol]: ICreateApi;
  [generationSite$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: WithoutId<ICreateGenerationSiteData>[]): Promise<ICreateResultData[]>;
}

export interface ICreateResultData {
  id: string;
  createdAt: Date;
  // 今のところ createdBy はサポートされていないが、本当はここで返した方がよい
  // createdBy: IUserData;
}

export interface ICreateGenerationSiteData {
  clientId: string;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  bannedDriverIds: string[];
  defaultAssignedDriverId: string | undefined;
  defaultAssignedDisposalSiteId: string | undefined;
  defaultAssignableCarIds: string[];
  defaultAssignableCarTypeIds: string[];
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  note: string | undefined;
  attachmentsToAdd: File[];
  isAddressComplete: boolean;
  status: SoftDeleteStatus;
  defaultCollectablePeriodTemplateId: string | undefined;
  defaultCollectablePeriodStart: number | undefined;
  defaultCollectablePeriodEnd: number | undefined;
  defaultRouteCollectionAllowed: boolean | undefined;
  defaultPreloadStatus: PreloadStatus | undefined;
  defaultIsFixedArrivalTimeReportNeeded: boolean | undefined;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: string | undefined;
}

export interface IGenerationSiteData extends IAbstractSiteData {
  code: string | undefined;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  note: string | undefined;
  attachments: UploadedFile[] | undefined;
  isAddressComplete: boolean;
  status: SoftDeleteStatus;
  client: IClientData;
  bannedDrivers: IDriverData[];
  defaultAssignedDriver: IDriverData | undefined;
  defaultAssignedDisposalSite: IDisposalSiteData | undefined;
  defaultAssignableCars: ICarData[];
  defaultAssignableCarTypes: ISimpleCarTypeData[];
  defaultCollectablePeriodTemplate: ICollectablePeriodTemplateData | undefined;
  defaultCollectablePeriodStart: number | undefined;
  defaultCollectablePeriodEnd: number | undefined;
  defaultRouteCollectionAllowed: boolean | undefined;
  defaultPreloadStatus: PreloadStatus | undefined;
  defaultIsFixedArrivalTimeReportNeeded: boolean | undefined;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: string | undefined;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IGenerationSiteData[]>;
}

export { GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';

export interface IGetByKeywordsApi {
  /**
   * キーワードで排出場を検索する
   * @param first
   * @param after
   * @param condition
   */
  getByKeywords(first: number, after: Maybe<string>, condition: ICondition): Promise<IConnection<IGenerationSiteData>>;
}

export interface ICondition {
  clientId: Maybe<string>;
  keywords: Maybe<string[]>;
  since: Maybe<Date>;
  orderBy: GenerationSitesByKeywordsOrder;
}

export interface IUpdateApi {
  update(data: IUpdateGenerationSiteData[]): Promise<string[]>;
}

export interface IUpdateGenerationSiteData extends ICreateGenerationSiteData {
  id: string;
  attachmentsToRemove: string[];
}
