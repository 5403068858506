
import Vue, { PropType } from 'vue';
import { hankanize } from '~/framework/services/string/string';
import RLabelsShrinkable from '~/components/common/r-truncate-elements/RLabelsShrinkable';

type DataType = {};

export default Vue.extend({
  name: 'RIrregularTask',
  mixins: [RLabelsShrinkable],
  props: {
    name: {
      type: String as PropType<string>,
      required: false,
      default: 'その他',
    },
    skipDisposalSite: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {};
  },
  computed: {
    skipDisposalSiteLabel() {
      return this.skipDisposalSite ? 'しない' : 'する';
    },
  },
  methods: {
    hankanize,
  },
});
