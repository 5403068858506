import {
  OfficeSettingQuery,
  OfficeSettingQueryVariables,
  UpdateOfficeSettingsMutation,
  UpdateOfficeSettingsMutationVariables,
} from '@/graphql/graphQLServerApi';
import { OfficeSetting } from '@/../graphql/server-api/queries/office-setting.gql';
import { UpdateOfficeSettings } from '@/../graphql/server-api/mutations/office-setting.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IUpdateData } from '~/framework/server-api/masters/officeSetting';

export class GetApi extends GraphqlApiBase {
  async get() {
    const result = await this.query<OfficeSettingQuery, OfficeSettingQueryVariables>({
      query: OfficeSetting,
      variables: {},
    });
    return result.officeSetting;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateData) {
    const result = await this.mutate<UpdateOfficeSettingsMutation, UpdateOfficeSettingsMutationVariables>({
      mutation: UpdateOfficeSettings,
      variables: { officeSettings: [data] },
    });
    this.validateArrayConsistency([data], result.updateOfficeSettings, `UpdateOfficeSettings`);
    return result.updateOfficeSettings[0];
  }
}
