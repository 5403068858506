
import Vue, { PropType } from 'vue';
import { NuxtError } from '@nuxt/types';
import RErrors from '~/components/pages/error/r-errors/RErrors.vue';
import { NuxtErrorStatusCodes } from '~/nuxt';
import { Maybe } from '~/framework/typeAliases';

type DataType = {
  statusCode: Maybe<number>;
};

interface MaybeMaintenanceError extends NuxtError {
  html?: string;
}

/**
 * ネットに接続できなかった様な場合、そのまま操作を続けられると状態が不正になったまま操作を続けられる事
 * になってしまい色々不具合が連続して起きる状態になってしまう。これを避けるためにエラーが起きたら
 * 強制的にトップページに飛ばして最初から操作してもらう様にダイアログにしている。
 */
export default Vue.extend({
  components: { RErrors },
  layout: 'unrestricted',
  props: {
    error: {
      type: Object as PropType<MaybeMaintenanceError>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      statusCode: this.error.statusCode,
    };
  },
  head() {
    const title = this.error.statusCode === NuxtErrorStatusCodes.MaintenanceException ? 'メンテナンス' : 'エラー';
    return {
      title,
    };
  },
  computed: {
    isMaintenanceException(): boolean {
      return this.statusCode === NuxtErrorStatusCodes.MaintenanceException;
    },
  },
  created() {
    if (this.isMaintenanceException && this.error?.html) {
      // インフラ側から返されるHTMLにメンテナンス期間等の情報が記載されているので、そのまま表示する
      // eslint-disable-next-line nuxt/no-globals-in-created
      document.body.innerHTML = this.error.html;
    }
  },
});
