import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';

/**
 * Car を編集するためのもの
 */
export interface ICarFormPanel extends IEntityFormPanel<AggregatedCarEntity> {}

export class CarFormPanel extends AbstractEntityFormPanel<AggregatedCarEntity> implements ICarFormPanel {}
