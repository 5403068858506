import ja from 'date-fns/locale/ja';
import { addDays, addMonths, format, lastDayOfMonth, startOfMonth, startOfWeek, endOfWeek, subMonths } from 'date-fns';
import first from 'lodash/first';
import last from 'lodash/last';
import daysOfWeek from '~/assets/settings/daysOfWeek.json';
import { DaysAWeek } from '~/framework/constants';
import { DayOfWeek } from '~/framework/typeAliases';
import { formatDateToString } from '~/framework/services/date/date';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { HeaderDayColumn, IHeaderDayColumn } from '~/components/common/r-month-calendar/headerDayColumn';

// １カ月の日付をすべてを最大６週間で表示できること
type WeekNumber = 1 | 2 | 3 | 4 | 5 | 6;

type MonthDateData = {
  date: Date;
  dateString: string;
};

type MonthDatesGroupedIntoWeeks = {
  [weekNumber in WeekNumber]: MonthDateData[];
};

export interface IMonthDatesCalendar {
  /**
   * その月の日付のリスト
   * 前後の日付も埋めようとするので必ずしもその月の日数には一致しない
   */
  monthDates: Date[];

  /**
   * その月の日付を週ごとにグループしたもの
   * 前後の日付も埋めようとするので、必ず毎週７つの日付が存在する
   */
  monthDatesGroupedIntoWeeks: MonthDatesGroupedIntoWeeks;

  /**
   * ヘッダに表示すべきカラム
   */
  headerDayColumns: IHeaderDayColumn[];

  /**
   * その月の最初の日
   */
  firstDateOfMonth: Date;

  /**
   * その月の最後の日
   */
  lastDateOfMonth: Date;

  /**
   * カレンダーの最初の日
   * 必ずしもその月の最初の日とは限らない
   */
  firstDate: Date;

  /**
   * カレンダーの終わりの日
   * 必ずしもその月の最後の日とは限らない
   */
  lastDate: Date;

  /**
   * 2020年8月みたいな文字列
   */
  monthLabel: string;

  /**
   * カレンダーを前の月に移動する
   */
  shiftToPreviousMonth(): void;

  /**
   * カレンダーを次の月に移動する
   */
  shiftToNextMonth(): void;

  /**
   * ある日を基準として、それを表示する週の日を一週間分全て取得する
   */
  getWeekDatesOf(baseDate: Date): Date[];

  /**
   * 選択された月の日かどうかを返す
   */
  isDateOfSelectedMonth(date: Date): boolean;

  /**
   * インスタンスの基準とする日付を更新する
   */
  updateBaseDate(date: Date): void;
}

export class MonthDatesCalendar implements IMonthDatesCalendar {
  /**
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  monthDates!: Date[];
  /**
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  monthDatesGroupedIntoWeeks!: MonthDatesGroupedIntoWeeks;
  /**
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  headerDayColumns!: IHeaderDayColumn[];
  /**
   * firstDateOfMonth をいじる事で自動的に調整される
   * firstDateOfMonth は previousMonth, nextMonth で調整される
   */
  monthLabel!: string;
  private readonly holidayRule: HolidayRuleEntity;
  private readonly startOfWeek: DayOfWeek;

  /**
   * baseDate の日が表示される様なカレンダーにする
   *
   * @param holidayRule
   * @param baseDate
   * @param startOfWeek
   */
  constructor(holidayRule: HolidayRuleEntity, baseDate: Date, startOfWeek: DayOfWeek) {
    this.holidayRule = holidayRule;
    this.startOfWeek = startOfWeek;
    this.firstDateOfMonth = this.getTargetMonthFrom(baseDate);
  }

  private _firstDateOfMonth!: Date;

  get firstDateOfMonth(): Date {
    return this._firstDateOfMonth;
  }

  set firstDateOfMonth(value: Date) {
    this._firstDateOfMonth = value;
    this.lastDateOfMonth = lastDayOfMonth(value);
    this.updateMonthDates();
    this.updateMonthLabel();
    this.updateHeaderColumns();
    this.updateMonthDatesGroupedIntoWeeks();
  }

  private _lastDateOfMonth!: Date;

  get lastDateOfMonth(): Date {
    return this._lastDateOfMonth;
  }

  set lastDateOfMonth(value: Date) {
    // NOTE 直で設定される事は想定されていないので注意
    this._lastDateOfMonth = value;
  }

  get firstDate(): Date {
    return first(this.monthDates)!;
  }

  get lastDate(): Date {
    return last(this.monthDates)!;
  }

  private get dateFnsOptions() {
    return { weekStartsOn: this.startOfWeek, locale: ja };
  }

  shiftToPreviousMonth() {
    this.firstDateOfMonth = subMonths(this.firstDateOfMonth, 1);
  }

  shiftToNextMonth() {
    this.firstDateOfMonth = addMonths(this.firstDateOfMonth, 1);
  }

  getWeekDatesOf(baseDate: Date): Date[] {
    const dates: Date[] = [];
    const firstOfWeek = startOfWeek(baseDate, this.dateFnsOptions);
    for (let index = 0; index < DaysAWeek; index++) {
      dates.push(addDays(firstOfWeek, index));
    }
    return dates;
  }

  isDateOfSelectedMonth(date: Date): boolean {
    return this.firstDateOfMonth.getTime() <= date.getTime() && date.getTime() <= this.lastDateOfMonth.getTime();
  }

  updateBaseDate(baseDate: Date) {
    this.firstDateOfMonth = this.getTargetMonthFrom(baseDate);
  }

  private updateMonthDatesGroupedIntoWeeks(): void {
    this.monthDatesGroupedIntoWeeks = this.monthDates.reduce((monthDatesGroupedIntoWeeks, date, index) => {
      const weekNumber = (Math.floor(index / DaysAWeek) + 1) as WeekNumber;

      const dateData = { date, dateString: formatDateToString(date) };
      if (weekNumber in monthDatesGroupedIntoWeeks) monthDatesGroupedIntoWeeks[weekNumber].push(dateData);
      else monthDatesGroupedIntoWeeks[weekNumber] = [dateData];

      return monthDatesGroupedIntoWeeks;
    }, {} as MonthDatesGroupedIntoWeeks);
  }

  private updateMonthDates(): void {
    const firstDate = startOfWeek(this.firstDateOfMonth, this.dateFnsOptions);
    const lastDate = addDays(startOfWeek(lastDayOfMonth(this.firstDateOfMonth), this.dateFnsOptions), 6);
    this.monthDates = [];
    for (let index = 0; true; index++) {
      const date = addDays(firstDate, index);
      this.monthDates.push(date);
      if (formatDateToString(date) === formatDateToString(lastDate)) break;
    }
  }

  private updateMonthLabel(): void {
    this.monthLabel = format(this.firstDateOfMonth, `yyyy年M月`, this.dateFnsOptions);
  }

  /**
   * 何かしらの適当な日付を与えて月のはじめの日を取得する
   *
   * @param date
   * @private
   */
  private getTargetMonthFrom(date: Date): Date {
    return startOfMonth(endOfWeek(date));
  }

  private updateHeaderColumns(): void {
    this.headerDayColumns = [];
    for (let index = 0; index < DaysAWeek; index++) {
      let day = this.startOfWeek + index;
      if (DaysAWeek <= day) day -= DaysAWeek;
      this.headerDayColumns.push(
        new HeaderDayColumn(daysOfWeek[day as DayOfWeek], this.holidayRule.isDayHoliday(day as DayOfWeek))
      );
    }
  }
}
