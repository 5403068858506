
import Vue, { PropType } from 'vue';
import { CssClasses, Maybe, PersistentId } from '~/framework/typeAliases';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { getOrInit } from '~/framework/core/map';
import { mapEntity } from '~/framework/core/mapper';
import { IllegalStateException } from '~/framework/core/exception';
import { IPackingStyleFormValues } from '~/components/panels/masters/r-car-type-form/packingStyleFormValues';
import { LoadableContainerTypeFormValuesFactory } from '~/components/panels/masters/r-car-type-form/loadableContainerTypeFormValues';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';

interface IUpperTierAvailabilityItem {
  icon: string;
  color: string;
  value: boolean;
}

type DataType = {
  /**
   * 1段目と2段目に積荷できる個数を表示するかどうか
   */
  showFullCountDetailsValue: boolean;
  /**
   * 登録されている荷姿がすべて表示されているかどうか
   * 厳密には荷姿の選択肢が空欄であったとしてもそれは欄としては存在しているので表示しているという事になる
   * そうしないと、荷姿が空欄の行が不必要に表示される事になってしまう
   */
  allContainerTypesAreShown: boolean;
  /**
   * 全ての荷姿が入力されているかどうか
   * 全て入力しているのに「登録されている荷姿を全て表示」のチェックを外せると意味が分からない事になるので
   * この値が true の場合には 「登録されている荷姿を全て表示」のチェックボックスが disabled になる
   */
  allContainerTypesAreFilled: boolean;
  /**
   * 荷姿種別が一つも表示されていない場合に true
   */
  isNoPackingStyleShown: boolean;
  /**
   * 重ね置を許可するかどうかの選択肢
   */
  upperTierAvailabilityItems: IUpperTierAvailabilityItem[];
  /**
   * "荷姿種別 ID -> 既に選択されている荷姿の ID" の Set を表す Map
   * 既に選択されている荷姿は重複して選択させたくないので必要
   */
  selectedContainerTypeSetMap: Map<PersistentId, Set<PersistentId>>;
  /**
   * 荷姿が存在していてかつまだ荷姿を追加する余地のある荷姿種別
   * 荷姿種別は 1〜9 まであるが、実際にコンテナが登録されていないものは表示する意味もなく、
   * 既に全ての荷姿を追加している荷姿種別に関しても選択肢として表示する意味がないので弾く必要がある
   */
  availablePackingStyles: PackingStyleEntity[];
};

/**
 * 積めるコンテナを入力させるやつ
 */
export default Vue.extend({
  name: 'RLoadableContainerTypeInput',
  props: {
    showFullCountDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    packingStyles: {
      type: Array as PropType<PackingStyleEntity[]>,
      required: true,
    },
    values: {
      type: Array as PropType<IPackingStyleFormValues[]>,
      required: true,
    },
  },
  data(): DataType {
    const upperTierAvailabilityItems = [
      { icon: 'ffi-available', color: 'bluish-gray_20', value: true, text: '許可' },
      { icon: 'ffi-not-available', color: 'error', value: false, text: '禁止' },
    ];
    return {
      upperTierAvailabilityItems,
      showFullCountDetailsValue: this.showFullCountDetails,
      allContainerTypesAreShown: false,
      allContainerTypesAreFilled: false,
      isNoPackingStyleShown: false,
      selectedContainerTypeSetMap: new Map<PersistentId, Set<PersistentId>>(),
      availablePackingStyles: [],
    };
  },
  computed: {
    containerSelectClasses(): CssClasses {
      return {
        'r-loadable-container-type-input__container-column__container__select--full': this.showFullCountDetailsValue,
        'r-loadable-container-type-input__container-column__container__select': !this.showFullCountDetailsValue,
      };
    },
    fullWidthColspan(): number {
      // 満載の詳細を表示している時に全幅の1行を表示しようとした時に指定すべき colspan
      // td の数に応じたただのマジックナンバー
      return this.showFullCountDetailsValue ? 8 : 7;
    },
  },
  watch: {
    values: {
      handler() {
        const packingStyleMap = mapEntity(this.packingStyles);
        const availablePackingStyles: PackingStyleEntity[] = [];
        const selectedContainerTypeSetMap: Map<PersistentId, Set<PersistentId>> = new Map();
        for (const packingStyle of this.values) {
          const selectedContainerTypeSet = getOrInit(selectedContainerTypeSetMap, packingStyle.id, new Set());
          for (const loadableContainerType of packingStyle.loadableContainerTypes) {
            if (loadableContainerType.containerTypeId === undefined) continue;
            selectedContainerTypeSet.add(loadableContainerType.containerTypeId);
          }
          if (
            1 <= packingStyle.containerTypes.length &&
            packingStyle.loadableContainerTypes.length < packingStyle.containerTypes.length
          ) {
            availablePackingStyles.push(packingStyleMap.getOrError(packingStyle.id));
          }
        }
        this.availablePackingStyles = availablePackingStyles;
        this.selectedContainerTypeSetMap = selectedContainerTypeSetMap;
        this.allContainerTypesAreShown = this.values.every(
          (packingStyle) => packingStyle.containerTypes.length === packingStyle.loadableContainerTypes.length
        );
        this.allContainerTypesAreFilled =
          this.allContainerTypesAreShown &&
          this.values.every((packingStyle) =>
            packingStyle.loadableContainerTypes.every(
              (loadableContainerType) => !loadableContainerType.isRemovable(true)
            )
          );
        this.isNoPackingStyleShown = this.values.every(
          (packingStyle) => packingStyle.loadableContainerTypes.length === 0
        );
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onChangeShowFullCountDetails(value: boolean): void {
      // checkboxがfalseになった時に、1段目のコンテナの値を満載の値にして２段目の値を0にする
      if (!value) {
        for (const packingStyle of this.values) {
          for (const loadableContainerType of packingStyle.loadableContainerTypes) {
            loadableContainerType.editableFullCount = loadableContainerType.lowerTierFullCount;
            loadableContainerType.upperTierFullCount = 0;
          }
        }
      }
    },
    onChangeShowAllContainerTypes(value: boolean): void {
      if (value) {
        this.removeUnusedLoadableContainerTypes(false);
        this.addAvailableLoadableContainerTypes();
      } else {
        this.removeUnusedLoadableContainerTypes(true);
      }
    },
    onClickRemoveButton(packingStyle: IPackingStyleFormValues, index: number): void {
      this.removeLoadableContainerType(packingStyle, index);
    },
    addLoadableContainerType(packingStyle: IPackingStyleFormValues): void {
      const item = (() => {
        if (packingStyle.containerTypes.length === 1) {
          return LoadableContainerTypeFormValuesFactory.buildByContainerTypeId(packingStyle.containerTypes[0].id);
        } else {
          return LoadableContainerTypeFormValuesFactory.buildDefault();
        }
      })();
      this.$set(packingStyle.loadableContainerTypes, packingStyle.loadableContainerTypes.length, item);
    },
    removeLoadableContainerType(packingStyle: IPackingStyleFormValues, index: number): void {
      this.$delete(packingStyle.loadableContainerTypes, index);
    },
    /**
     * 選択されたコンテナは必ず含み、それ以外に選択可能なコンテナのリストを返す
     * @param selectedContainerTypeId
     */
    getAvailableContainerTypesOf(
      packingStyle: IPackingStyleFormValues,
      selectedContainerTypeId: Maybe<PersistentId>
    ): AggregatedContainerTypeEntity[] {
      return packingStyle.containerTypes.filter((containerType) => {
        const containerTypeId = containerType.persistentId;
        return (
          containerTypeId === selectedContainerTypeId ||
          !this.selectedContainerTypeSetMap.getOrError(packingStyle.id).has(containerTypeId)
        );
      });
    },
    addAvailableLoadableContainerTypes(): void {
      for (const packingStyle of this.values) {
        for (const containerType of packingStyle.containerTypes) {
          if (this.selectedContainerTypeSetMap.getOrError(packingStyle.id).has(containerType.id)) continue;
          if (packingStyle.loadableContainerTypes.length === packingStyle.containerTypes.length) break;
          this.$set(
            packingStyle.loadableContainerTypes,
            packingStyle.loadableContainerTypes.length,
            LoadableContainerTypeFormValuesFactory.buildByContainerTypeId(containerType.persistentId)
          );
        }
      }
    },
    removeUnusedLoadableContainerTypes(includeSpecifiedContainerType: boolean): void {
      for (const packingStyle of this.values) {
        const unusedIndexes = [];
        for (const [index, item] of packingStyle.loadableContainerTypes.entries()) {
          if (item.isRemovable(includeSpecifiedContainerType)) unusedIndexes.push(index);
        }
        for (const index of unusedIndexes.reverse()) {
          this.removeLoadableContainerType(packingStyle, index);
        }
      }
    },
    onSelectPackingStyle(selectedPackingStyleId: string): void {
      this.addLoadableContainerType(this.getPackingStyleOf(selectedPackingStyleId));
    },
    getPackingStyleOf(id: PersistentId): IPackingStyleFormValues {
      for (const packingStyle of this.values) {
        if (packingStyle.id === id) return packingStyle;
      }
      throw new IllegalStateException(`not found packing style`);
    },
  },
});
