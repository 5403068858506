import { Middleware } from '@nuxt/types';

const notificationMiddleware: Middleware = async (context) => {
  const notificationManager = context.app.$context.notification;
  if (notificationManager.hasInitializedPermission() === false) {
    await notificationManager.requestPermission();
  }
};

export default notificationMiddleware;
