
import Vue, { PropType } from 'vue';
import { CandidateDate } from '~/framework/domain/reservation/reservation/candidateDate';
import { formatPeriod } from '~/framework/services/date-time/date-time';
import { formatDateForField } from '~/framework/services/date/date';
import { Vacancy } from '~/framework/domain/typeAliases';

type DataType = {
  Vacancy: typeof Vacancy;
};

export default Vue.extend({
  name: 'RScheduleCandidateDates',
  props: {
    candidateDates: {
      type: Array as PropType<CandidateDate[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      Vacancy,
    };
  },
  methods: {
    formatPeriod,
    formatDateForField,
  },
});
