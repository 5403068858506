import { GraphQLInt } from 'graphql';
import { Iso8601DateScalarType } from './custom-scalars/iso8601Date';
import { Iso8601DateTimeScalarType } from './custom-scalars/iso8601DateTime';
import { ScheduleJsonObjectScalarType } from '~/graphql/custom-scalars/scheduleJsonObject';
// eslint-disable-next-line prettier/prettier
import type { RawScheduleJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { OrderValidationJsonObjectScalarType } from '~/graphql/custom-scalars/orderValidationJsonObject';

// https://github.com/webpack/webpack/issues/7378
export { RawScheduleJsonObject };

/**
 * apollo-link-scalars に使うタイプマップ
 * 独自の Scalar を定義した場合にはここに GraphQLScalarType を定義する
 */
export const typesMap = {
  ScheduleJsonObject: ScheduleJsonObjectScalarType,
  Iso8601Date: Iso8601DateScalarType,
  Iso8601DateTime: Iso8601DateTimeScalarType,
  OrderValidationJsonObject: OrderValidationJsonObjectScalarType,
  Second: GraphQLInt,
};
