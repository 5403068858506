import { addDays, isSameDay } from 'date-fns';
import { AggregatedDriverAttendanceEntity as IDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { IDriverAttendanceMap } from '~/framework/view-models/driverAttendanceMap';
import { DateString } from '~/framework/typeAliases';
import { formatDateToString } from '~/framework/services/date/date';
import { AggregatedDriverEntity as IDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';

export interface IDefaultDriverAttendance {
  /**
   * primaryCar が衝突していて生成できなかった日を取得する
   * - defaultPrimaryCarId がないなら生成できない
   * - defaultAttendanceTemplateId がないなら生成できない
   * - 休日は生成しなくてよい
   * - 既に自分で生成されている場合は生成されない
   */
  getConflictedDates(start: Date, end: Date, generatedDriverAttendances: IDriverAttendanceEntity[]): Date[];
}

export class DefaultDriverAttendance implements IDefaultDriverAttendance {
  private readonly holidayRule: HolidayRuleEntity;
  private readonly driver: IDriverEntity;
  private readonly allDriverAttendanceMap: IDriverAttendanceMap;
  private readonly nationalHolidayService: NationalHolidayService;

  constructor(
    holidayRule: HolidayRuleEntity,
    driver: IDriverEntity,
    allDriverAttendanceMap: IDriverAttendanceMap,
    nationalHolidayService: NationalHolidayService
  ) {
    this.holidayRule = holidayRule;
    this.driver = driver;
    this.allDriverAttendanceMap = allDriverAttendanceMap;
    this.nationalHolidayService = nationalHolidayService;
  }

  getConflictedDates(start: Date, end: Date, generatedDriverAttendances: IDriverAttendanceEntity[]): Date[] {
    if (this.driver.defaultPrimaryCar === undefined || this.driver.defaultAttendanceTemplateId === undefined) return [];
    const generatedMap = new Map<DateString, IDriverAttendanceEntity>();
    for (const driverAttendance of generatedDriverAttendances) {
      generatedMap.set(formatDateToString(driverAttendance.attendance.date), driverAttendance);
    }
    const existingMap = this.allDriverAttendanceMap.getAttendanceMapOf(this.driver.persistentId);
    const result = [];
    for (let index = 0; true; index++) {
      const date = addDays(start, index);
      const key = formatDateToString(date);
      if (!this.nationalHolidayService.isHoliday(date, this.holidayRule)) {
        if (!existingMap.has(key) && !generatedMap.has(key)) result.push(date);
      }
      if (isSameDay(date, end)) break;
    }
    return result;
  }
}
