
import Vue from 'vue';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { required, uniqued } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { DaySeconds } from '~/framework/constants';
import {
  getHoursAndMinutesOf,
  hhMmToSecs,
  isNextDay,
  validateHardLimitTime,
} from '~/framework/services/date-time/date-time';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { overtimeWorkTypeItems, IOvertimeWorkTypeItem } from '~/framework/view-models/driverAttendance';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import {
  driverAttendanceTemplateSymbol,
  DriverAttendanceTemplateApplicationService,
} from '~/framework/application/masters/driver-attendance-template/driverAttendanceTemplateApplicationService';
import { Time } from '~/framework/domain/common/date-time/time';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { DriverAttendanceService } from '~/framework/domain/masters/driver-attendance/driverAttendanceService';

import { v4 as uuidv4 } from 'uuid';

export enum FormMode {
  Register,
  Edit,
}

type InitialFormValues = {
  name: Maybe<string>;
  overtimeWorkType: Maybe<OvertimeWorkType>;
  regularWorkPeriodStart: Maybe<number>;
  regularWorkPeriodEnd: Maybe<number>;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  overtimeWorkableDurationHours: Maybe<number>;
  overtimeWorkableDurationMinutes: Maybe<number>;
};

class DriverAttendanceTemplateForm {
  private readonly driverAttendanceService: DriverAttendanceService;
  title: string;
  regularWorkPeriodStartKey: string;
  regularWorkPeriodEndKey: string;
  restPeriodStartKey: string;
  restPeriodEndKey: string;
  attendanceTemplate: Maybe<DriverAttendanceTemplateEntity>;
  createdAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>;
  updatedAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>;
  removedAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>;
  overtimeWorkType: Maybe<OvertimeWorkType>;
  overtimeWorkTypes: IOvertimeWorkTypeItem[];
  _isFormValid: boolean = false;
  isRegisterButtonDisabled: boolean;
  hasRestPeriodDisabled: boolean;
  isOvertimeWorkableDurationDisabled: boolean;
  isRegistering: boolean;
  formMode: FormMode;
  rules: { [key: string]: ValidationRule };

  restOptions: {
    value: boolean;
    label: string;
  }[] = [
    { value: false, label: '無' },
    { value: true, label: '有' },
  ];

  hasRest: boolean;
  isOvertimeWorkable: boolean = false;

  initialValues: InitialFormValues;

  name: Maybe<string>;
  regularWorkPeriodStartString: Maybe<string>;
  regularWorkPeriodEndString: Maybe<string>;
  restPeriodStartString: Maybe<string>;
  restPeriodEndString: Maybe<string>;

  regularWorkPeriodEndErrorMessage: Maybe<string>;

  overtimeWorkableDurationHours: Maybe<number>;
  overtimeWorkableDurationMinutes: Maybe<number>;

  overtimeWorkHint: Maybe<string>;

  forceRidePrimaryCarOptions: {
    value: boolean;
    label: string;
  }[] = [
    { value: false, label: '指定なし' },
    { value: true, label: '指定あり' },
  ];

  defaultForceRidePrimaryCar = false;

  get isFormValid(): boolean {
    return this._isFormValid;
  }

  set isFormValid(value: boolean) {
    this._isFormValid = value;
    this.updateRegisterButtonDisabled();
  }

  get isDirty(): boolean {
    return (
      this.name !== this.initialValues.name ||
      this.regularWorkPeriodStartString !== this.initialValues.regularWorkPeriodStart?.toHhMm() ||
      this.regularWorkPeriodEndString !== this.initialValues.regularWorkPeriodEnd?.toHhMm() ||
      this.restPeriodStartString !== this.initialValues.restPeriodStart?.toHhMm() ||
      this.restPeriodEndString !== this.initialValues.restPeriodEnd?.toHhMm() ||
      this.overtimeWorkType !== this.initialValues.overtimeWorkType ||
      this.overtimeWorkableDurationHours !== this.initialValues.overtimeWorkableDurationHours ||
      this.overtimeWorkableDurationMinutes !== this.initialValues.overtimeWorkableDurationMinutes
    );
  }

  get regularWorkPeriodStart(): Maybe<number> {
    if (this.regularWorkPeriodStartString === undefined) return undefined;
    return hhMmToSecs(this.regularWorkPeriodStartString);
  }

  get regularWorkPeriodEnd(): Maybe<number> {
    if (this.regularWorkPeriodEndString === undefined) return undefined;
    return hhMmToSecs(this.regularWorkPeriodEndString);
  }

  get restPeriodStart(): Maybe<number> {
    if (this.restPeriodStartString === undefined) return undefined;
    return hhMmToSecs(this.restPeriodStartString);
  }

  get restPeriodEnd(): Maybe<number> {
    if (this.restPeriodEndString === undefined) return undefined;
    return hhMmToSecs(this.restPeriodEndString);
  }

  get overtimeWorkableDuration(): number {
    if (this.overtimeWorkableDurationHours === undefined || this.overtimeWorkableDurationMinutes === undefined)
      return 0;
    return this.overtimeWorkableDurationHours * 60 * 60 + this.overtimeWorkableDurationMinutes * 60;
  }

  constructor(attendanceTemplate: Maybe<DriverAttendanceTemplateEntity>) {
    this.driverAttendanceService = new DriverAttendanceService();

    // マスター系
    this.overtimeWorkTypes = overtimeWorkTypeItems;
    this.regularWorkPeriodStartKey = uuidv4();
    this.regularWorkPeriodEndKey = uuidv4();
    this.restPeriodStartKey = uuidv4();
    this.restPeriodEndKey = uuidv4();
    // フォームに関係する値を設定
    this.attendanceTemplate = attendanceTemplate;
    this.createdAttendanceTemplate = undefined;
    this.updatedAttendanceTemplate = undefined;
    this.removedAttendanceTemplate = undefined;
    this.formMode = this.attendanceTemplate === undefined ? FormMode.Register : FormMode.Edit;
    this.title = this.formMode === FormMode.Register ? '勤怠ルールの登録' : '勤怠ルールの編集';
    this.initialValues = this.getInitialFormValues(this.attendanceTemplate);
    this.name = this.initialValues.name;
    this.regularWorkPeriodStartString = this.initialValues.regularWorkPeriodStart?.toHhMm();
    this.regularWorkPeriodEndString = this.initialValues.regularWorkPeriodEnd?.toHhMm();
    this.restPeriodStartString = this.initialValues.restPeriodStart?.toHhMm();
    this.restPeriodEndString = this.initialValues.restPeriodEnd?.toHhMm();
    this.overtimeWorkType = this.initialValues.overtimeWorkType;
    this.overtimeWorkableDurationHours = this.initialValues.overtimeWorkableDurationHours;
    this.overtimeWorkableDurationMinutes = this.initialValues.overtimeWorkableDurationMinutes;

    this.hasRestPeriodDisabled = true;
    this.isOvertimeWorkableDurationDisabled = true;
    this.isRegisterButtonDisabled = true;
    this.isRegistering = false;

    this.rules = { required, requiredOvertimeWorkableDuration: () => this.requiredOvertimeWorkableDuration() };

    this.hasRest = attendanceTemplate?.restPeriodStart !== undefined && attendanceTemplate?.restPeriodEnd !== undefined;
    this.defaultForceRidePrimaryCar = attendanceTemplate?.defaultForceRidePrimaryCar === true;

    // ここで on〜 を呼ぶのが気持ち悪い様な気もするが、変更したと言えばしたのでよしとする
    this.onChangeHasRest();
    this.onUpdateRegularWorkPeriodError(this.regularWorkPeriodEndString);
    this.onChangeOvertimeWorkType();
  }

  onChangeHasRest(): void {
    if (!this.hasRest) {
      this.restPeriodStartString = undefined;
      this.restPeriodEndString = undefined;
    }
    this.updateHasRestPeriodDisabled();
  }

  onChangeRegularWorkPeriodStartString(value: Maybe<string>): void {
    if (value === undefined) return;

    this.regularWorkPeriodStartString = this.correctRegularWorkPeriodStart(Time.buildByString(value)).toHhMm();
    this.regularWorkPeriodStartKey = uuidv4();
    this.updateRegisterButtonDisabled();
  }

  onChangeRegularWorkPeriodEndString(value: Maybe<string>): void {
    if (value === undefined) return;

    this.regularWorkPeriodEndString = this.correctRegularWorkPeriodEnd(Time.buildByString(value)).toHhMm();
    this.regularWorkPeriodEndKey = uuidv4();
    this.updateRegisterButtonDisabled();
  }

  onChangeOvertimeWorkType(): void {
    this.isOvertimeWorkable = this.overtimeWorkType !== OvertimeWorkType.None && this.overtimeWorkType !== undefined;
    if (!this.isOvertimeWorkable) {
      this.overtimeWorkableDurationHours = undefined;
      this.overtimeWorkableDurationMinutes = undefined;
    } else {
      if (this.overtimeWorkableDurationHours === undefined) {
        this.overtimeWorkableDurationHours = 0;
      }
      if (this.overtimeWorkableDurationMinutes === undefined) {
        this.overtimeWorkableDurationMinutes = 0;
      }
    }
    this.updateOvertimeWorkableDurationDisabled();
    this.updateOvertimeWorkHint();
  }

  onChangeRestPeriodStartString(value: Maybe<string>): void {
    if (value === undefined) {
      return;
    }

    this.restPeriodStartString = this.correctRestPeriodStart(Time.buildByString(value)).toHhMm();
    this.restPeriodStartKey = uuidv4();
    this.updateRegisterButtonDisabled();
  }

  onChangeRestPeriodEndString(value: Maybe<string>): void {
    if (value === undefined) {
      return;
    }

    this.restPeriodEndString = this.correctRestPeriodEnd(Time.buildByString(value)).toHhMm();
    this.restPeriodEndKey = uuidv4();
    this.updateRegisterButtonDisabled();
  }

  // NOTE: time-picker 上で選択させたくない値をチェックする。
  // 現状はハードリミットの確認のみで、過去に遡ることはないため endTime だけチェックできればよい
  onUpdateRegularWorkPeriodError(value: Maybe<string>): void {
    if (value === undefined || this.regularWorkPeriodStartString === undefined) {
      return;
    }

    this.regularWorkPeriodEndErrorMessage = this.checkErrors(
      hhMmToSecs(this.regularWorkPeriodStartString),
      hhMmToSecs(value)
    );
  }

  checkErrors(start: number, end: number): Maybe<string> {
    // NOTE: startTime > endTime の場合は日跨ぎとみなす
    if (isNextDay(start, end)) {
      const calculatedEndTime = end + DaySeconds;

      if (!validateHardLimitTime(calculatedEndTime)) {
        return '翌日12時以降は指定できません';
      }
    }
    return undefined;
  }

  private correctRegularWorkPeriodStart(value: Time): Time {
    return this.driverAttendanceService.correctRegularWorkPeriodStart(
      value,
      this.restPeriodStartString ? Time.buildByString(this.restPeriodStartString) : undefined,
      this.restPeriodEndString ? Time.buildByString(this.restPeriodEndString) : undefined,
      this.regularWorkPeriodEndString ? Time.buildByString(this.regularWorkPeriodEndString) : undefined
    );
  }

  private correctRestPeriodStart(value: Time): Time {
    return this.driverAttendanceService.correctRestPeriodStart(
      this.regularWorkPeriodStartString ? Time.buildByString(this.regularWorkPeriodStartString) : undefined,
      value,
      this.restPeriodEndString ? Time.buildByString(this.restPeriodEndString) : undefined,
      this.regularWorkPeriodEndString ? Time.buildByString(this.regularWorkPeriodEndString) : undefined
    );
  }

  private correctRestPeriodEnd(value: Time): Time {
    return this.driverAttendanceService.correctRestPeriodEnd(
      this.regularWorkPeriodStartString ? Time.buildByString(this.regularWorkPeriodStartString) : undefined,
      this.restPeriodStartString ? Time.buildByString(this.restPeriodStartString) : undefined,
      value,
      this.regularWorkPeriodEndString ? Time.buildByString(this.regularWorkPeriodEndString) : undefined
    );
  }

  private correctRegularWorkPeriodEnd(value: Time): Time {
    return this.driverAttendanceService.correctRegularWorkPeriodEnd(
      this.regularWorkPeriodStartString ? Time.buildByString(this.regularWorkPeriodStartString) : undefined,
      this.restPeriodStartString ? Time.buildByString(this.restPeriodStartString) : undefined,
      this.restPeriodEndString ? Time.buildByString(this.restPeriodEndString) : undefined,
      value
    );
  }

  private updateRegisterButtonDisabled(): void {
    this.isRegisterButtonDisabled = !this.isFormValid;
  }

  private updateHasRestPeriodDisabled(): void {
    this.hasRestPeriodDisabled = !this.hasRest;
  }

  private updateOvertimeWorkableDurationDisabled(): void {
    this.isOvertimeWorkableDurationDisabled = !this.isOvertimeWorkable;
  }

  private updateOvertimeWorkHint(): void {
    switch (this.overtimeWorkType) {
      case OvertimeWorkType.None:
        this.overtimeWorkHint = undefined;
        break;
      case OvertimeWorkType.Both:
        this.overtimeWorkHint = '乗務時間の前後の残業が可能';
        break;
      case OvertimeWorkType.AvailableInEarlyTime:
        this.overtimeWorkHint = '乗務時間の前の残業が可能';
        break;
      case OvertimeWorkType.AvailableInLateTime:
        this.overtimeWorkHint = '乗務時間の後の残業が可能';
        break;
    }
  }

  private getInitialFormValues(attendanceTemplate: Maybe<DriverAttendanceTemplateEntity>): InitialFormValues {
    return attendanceTemplate === undefined
      ? this.getDefaultInitialFormValues()
      : this.getInitialFormValuesByAttendanceTemplate(attendanceTemplate);
  }

  private getDefaultInitialFormValues(): InitialFormValues {
    return {
      name: undefined,
      regularWorkPeriodStart: undefined,
      regularWorkPeriodEnd: undefined,
      restPeriodStart: undefined,
      restPeriodEnd: undefined,
      overtimeWorkType: undefined,
      overtimeWorkableDurationHours: undefined,
      overtimeWorkableDurationMinutes: undefined,
    };
  }

  private getInitialFormValuesByAttendanceTemplate(
    attendanceTemplate: DriverAttendanceTemplateEntity
  ): InitialFormValues {
    const [hours, minutes] =
      attendanceTemplate.overtimeWorkType === OvertimeWorkType.None
        ? [undefined, undefined]
        : getHoursAndMinutesOf(attendanceTemplate.overtimeWorkableDuration);
    return {
      name: attendanceTemplate.name,
      regularWorkPeriodStart: attendanceTemplate.regularWorkPeriodStart,
      regularWorkPeriodEnd: attendanceTemplate.regularWorkPeriodEnd,
      restPeriodStart: attendanceTemplate.restPeriodStart,
      restPeriodEnd: attendanceTemplate.restPeriodEnd,
      overtimeWorkType: attendanceTemplate.overtimeWorkType,
      overtimeWorkableDurationHours: hours,
      overtimeWorkableDurationMinutes: minutes,
    };
  }

  private requiredOvertimeWorkableDuration(): boolean | string {
    if (this.overtimeWorkableDurationHours !== undefined && this.overtimeWorkableDurationMinutes !== undefined) {
      return (
        this.overtimeWorkableDurationHours > 0 ||
        this.overtimeWorkableDurationMinutes > 0 ||
        '作業可能時間は1分以上を入力してください。'
      );
    } else {
      return '作業可能時間を入力してください';
    }
  }
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  isAdjustedToMinimumMessageActive: boolean;
  FormMode: typeof FormMode;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  driverAttendanceTemplateApplicationService: DriverAttendanceTemplateApplicationService;
  allDriverAttendanceTemplateNames: string[];
};

type AsyncDataType = {
  viewModel: Maybe<DriverAttendanceTemplateForm>;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RDriverAttendanceTemplateForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const driverAttendanceTemplateApplicationService = this.$context.applications.get(driverAttendanceTemplateSymbol);
    return {
      isActive: false,
      isConfirmDialogActive: false,
      isAdjustedToMinimumMessageActive: false,
      viewModel: undefined,
      FormMode,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      driverAttendanceTemplateApplicationService,
      allDriverAttendanceTemplateNames: [],
    };
  },
  computed: {
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.DRIVER_ATTENDANCE_TEMPLATE;
    },
    initialTemplateName(): Maybe<string> {
      return this.viewModel?.attendanceTemplate?.name;
    },
  },
  watch: {
    /**
     * メッセージが表示されてから4秒後に閉じる
     */
    isAdjustedToMinimumMessageActive(value) {
      if (value === true) {
        setTimeout(() => {
          this.isAdjustedToMinimumMessageActive = false;
        }, 4000);
      }
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.driverAttendanceTemplateFormPanel.openFormEvent.on(
      this.onOpenForm
    );
    this.$context.panels.driverAttendanceTemplateFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    uniqued,
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async open(attendanceTemplate: Maybe<DriverAttendanceTemplateEntity> = undefined): Promise<void> {
      if (this.isActive) return;
      this.viewModel = new DriverAttendanceTemplateForm(attendanceTemplate);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.loadAllDriverAttendanceTemplateNames();
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as any as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<DriverAttendanceTemplateEntity> = {
        entity: this.viewModel!.attendanceTemplate,
        createdEntity: this.viewModel!.createdAttendanceTemplate,
        updatedEntity: this.viewModel!.updatedAttendanceTemplate,
        removedEntity: this.viewModel!.removedAttendanceTemplate,
      };
      this.$context.panels.driverAttendanceTemplateFormPanel.closeFormEvent.emit(closeFormArgs);
    },
    async onOpenForm(args: IOpenEntityFormArgs<DriverAttendanceTemplateEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async onCloseForm(): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    async onRegisterButtonClick(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;
      if (viewModel.formMode === FormMode.Register) {
        const entity = await this.driverAttendanceTemplateApplicationService.create({
          name: viewModel.name!,
          regularWorkPeriodStart: viewModel.regularWorkPeriodStart!,
          regularWorkPeriodEnd: viewModel.regularWorkPeriodEnd!,
          restPeriodStart: viewModel.restPeriodStart,
          restPeriodEnd: viewModel.restPeriodEnd,
          overtimeWorkType: viewModel.overtimeWorkType!,
          overtimeWorkableDuration: viewModel.overtimeWorkableDuration,
          defaultForceRidePrimaryCar: viewModel.defaultForceRidePrimaryCar,
        });

        viewModel.createdAttendanceTemplate = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        const driverAttendanceTemplateEntity = viewModel.attendanceTemplate!;
        const entity = await this.driverAttendanceTemplateApplicationService.update({
          id: driverAttendanceTemplateEntity.id,
          name: viewModel.name!,
          regularWorkPeriodStart: viewModel.regularWorkPeriodStart!,
          regularWorkPeriodEnd: viewModel.regularWorkPeriodEnd!,
          restPeriodStart: viewModel.restPeriodStart,
          restPeriodEnd: viewModel.restPeriodEnd,
          overtimeWorkType: viewModel.overtimeWorkType!,
          overtimeWorkableDuration: viewModel.overtimeWorkableDuration,
          defaultForceRidePrimaryCar: viewModel.defaultForceRidePrimaryCar,
        });

        viewModel.updatedAttendanceTemplate = entity;
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER_ATTENDANCE_TEMPLATE,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('勤怠ルールの登録完了');
      await this.close();
    },
    async loadAllDriverAttendanceTemplateNames(): Promise<void> {
      const allDriverAttendanceTemplates = await this.driverAttendanceTemplateApplicationService.getAll();

      this.allDriverAttendanceTemplateNames = allDriverAttendanceTemplates.map(
        (driverAttendanceTemplate) => driverAttendanceTemplate.name
      );
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.driverAttendanceTemplateFormPanel.updateDisplayedWidth(value);
    },
  },
});
