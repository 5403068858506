import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import * as List from '~/framework/application/masters/client/search';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import {
  client$updateSymbol,
  IClientUpdateData,
  client$getByKeywordsSymbol,
  ICondition,
  client$createSymbol,
  IClientCreateData,
  client$getByIdsSymbol,
  client$getWithGenerationSiteNumByIdsSymbol,
} from '~/framework/server-api/masters/client';

import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';

import * as Selection from '~/framework/application/masters/client/searchSelection';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';

export const clientSymbol = Symbol('client');

export class ClientApplicationService {
  private serverApis: ServerApiManager;
  private clientMapper: AggregatedClientMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.serverApis = serverApis;
    this.clientMapper = new AggregatedClientMapper(store.masters.aggregatedClient, store.masters.user);
  }

  // region search
  async search(first: number, after: Maybe<string>, condition: ICondition): Promise<List.IList> {
    const client$getByKeywordsApi = this.serverApis.get(client$getByKeywordsSymbol);
    const result = await client$getByKeywordsApi.getWithOptionalsByKeywords(first, after, condition);
    const entities = this.clientMapper.map(result.edges.map((edge) => edge.node));
    const output: List.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: entities.map((entity, index) => {
        return {
          entity,
          generationSiteNum: result.edges[index].node.generationSiteNum,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region searchSelection
  async searchSelection(first: number, after: Maybe<string>, condition: ICondition): Promise<Selection.IList> {
    const client$getByKeywordsApi = this.serverApis.get(client$getByKeywordsSymbol);
    const result = await client$getByKeywordsApi.getByKeywords(first, after, condition);
    const entities = this.clientMapper.map(result.edges.map((edge) => edge.node));
    const output: Selection.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: entities.map((entity, index) => {
        return {
          entity,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region create
  async create(data: IClientCreateData): Promise<AggregatedClientEntity> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$createApi = this.serverApis.get(client$createSymbol);
    const [createResult] = await client$createApi.create([data]);
    const entity = this.clientMapper.mapSingle(createResult.client);
    return entity;
  }
  // endregion

  // region import

  async createMultiple(data: IClientCreateData[]): Promise<AggregatedClientEntity[]> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$createApi = this.serverApis.get(client$createSymbol);
    const createResults = await client$createApi.create(data);
    const entities = this.clientMapper.map(createResults.map((res) => res.client));
    return entities;
  }
  // endregion

  // region update
  async update(data: IClientUpdateData): Promise<AggregatedClientEntity> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$updateApi = this.serverApis.get(client$updateSymbol);
    const [updateResult] = await client$updateApi.update([data]);

    const entity = this.clientMapper.mapSingle(updateResult.client);
    return entity;
  }
  // endregion

  // region delete
  async delete(id: string): Promise<AggregatedClientEntity> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$updateApi = this.serverApis.get(client$updateSymbol);
    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const [getResult] = await client$getByIdsApi.getByIds([id]);
    const updateData = {
      id: getResult.id,
      name: getResult.name,
      nameRuby: getResult.nameRuby,
      note: getResult.note,
      status: SoftDeleteStatus.Deleted,
      packingStyleReservationSettings: [],
    };
    const [updatedData] = await client$updateApi.update([updateData]);
    const entity = this.clientMapper.mapSingle(updatedData.client);
    return entity;
  }
  // endregion

  async getById(id: PersistentId): Promise<AggregatedClientEntity> {
    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const [client] = await client$getByIdsApi.getByIds([id]);

    return this.clientMapper.mapSingle(client);
  }

  async getWithGenerationSiteNumById(
    id: PersistentId
  ): Promise<{ entity: AggregatedClientEntity; generationSiteNum: number }> {
    const client$getWithGenerationSiteNumByIdsApi = this.serverApis.get(client$getWithGenerationSiteNumByIdsSymbol);
    const [client] = await client$getWithGenerationSiteNumByIdsApi.getWithGenerationSiteNumByIds([id]);

    return {
      entity: this.clientMapper.mapSingle(client),
      generationSiteNum: client.generationSiteNum,
    };
  }
}
