import {
  AllCarTypesQuery,
  AllCarTypesQueryVariables,
  CreateCarTypeMutation,
  CreateCarTypeMutationVariables,
  UpdateCarTypeMutation,
  UpdateCarTypeMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllCarTypes } from '@/../graphql/server-api/queries/car-type.gql';
import { CreateCarType, UpdateCarType } from '@/../graphql/server-api/mutations/car-type.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateCarTypeData, IUpdateCarTypeData } from '~/framework/server-api/masters/carType';

import { mapData } from '~/framework/core/mapper';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllCarTypesQuery, AllCarTypesQueryVariables>({
      query: AllCarTypes,
      variables: {},
    });
    return result.allCarTypes;
  }
}

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<AllCarTypesQuery, AllCarTypesQueryVariables>({
      query: AllCarTypes,
      variables: {},
    });
    const carTypeDataMap = mapData(result.allCarTypes, 'id');
    return ids.mapValues(carTypeDataMap);
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: ICreateCarTypeData) {
    const result = await this.mutate<CreateCarTypeMutation, CreateCarTypeMutationVariables>({
      mutation: CreateCarType,
      variables: { carType: data },
    });
    return result.createCarType;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IUpdateCarTypeData) {
    const result = await this.mutate<UpdateCarTypeMutation, UpdateCarTypeMutationVariables>({
      mutation: UpdateCarType,
      variables: { carType: data },
    });
    return result.updateCarType;
  }
}
