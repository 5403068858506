import { getDay, getWeekOfMonth, startOfMonth } from 'date-fns';
import { WeekdayOrdinal } from '~/framework/domain/typeAliases';

export interface IWeekdayOrdinalOption {
  label: '第一' | '第二' | '第三' | '第四' | '月末';
  value: WeekdayOrdinal;
}

/**
 * `月の第 n 回目の m 曜日` の `n` を表すラベルと値のリスト。
 */
export const weekdayOrdinalOptions: IWeekdayOrdinalOption[] = [
  {
    label: '第一',
    value: WeekdayOrdinal.First,
  },
  {
    label: '第二',
    value: WeekdayOrdinal.Second,
  },
  {
    label: '第三',
    value: WeekdayOrdinal.Third,
  },
  {
    label: '第四',
    value: WeekdayOrdinal.Fourth,
  },
  {
    label: '月末',
    value: WeekdayOrdinal.Last,
  },
];

const weekdayOrdinalMap = new Map<IWeekdayOrdinalOption['value'], IWeekdayOrdinalOption['label']>(
  weekdayOrdinalOptions.map((option) => [option.value, option.label])
);

export const weekdayOrdinalToLabel = (weekdayOrdinal: WeekdayOrdinal): string => {
  return weekdayOrdinalMap.getOrError(weekdayOrdinal);
};

const indexToWeekdayOrdinalMap = new Map<number, WeekdayOrdinal>([
  [0, WeekdayOrdinal.First],
  [1, WeekdayOrdinal.Second],
  [2, WeekdayOrdinal.Third],
  [3, WeekdayOrdinal.Fourth],
  [4, WeekdayOrdinal.Last],
]);

export const indexToWeekdayOrdinal = (weekdayOrdinalIndex: number): WeekdayOrdinal => {
  return indexToWeekdayOrdinalMap.getOrError(weekdayOrdinalIndex);
};

export const weekdayOrdinalToIndexMap = new Map<WeekdayOrdinal, number>([
  [WeekdayOrdinal.First, 0],
  [WeekdayOrdinal.Second, 1],
  [WeekdayOrdinal.Third, 2],
  [WeekdayOrdinal.Fourth, 3],
  [WeekdayOrdinal.Last, 4],
]);

export const weekdayOrdinalToIndex = (weekdayOrdinal: WeekdayOrdinal): number => {
  return weekdayOrdinalToIndexMap.getOrError(weekdayOrdinal);
};

// weekdayOrdinal の計算には、指定された日付の曜日が該当の月の初日の曜日より前か後かの判定が必要。
// 前の場合： weekdayOrdinal が date-fns の weekOfMonth より一つ前になるため `-1` のオフセットを持つ。
// 等しい・後の場合： weekdayOrdinal が date-fns の weekOfMonth と同じ値になるため `0` のオフセットを持つ。
// weekOfMonth に オフセット を足して weekdayOrdinal を計算する。
export const calculateWeekdayOrdinal = (date: Date): WeekdayOrdinal => {
  const dayOfStartOfMonth = getDay(startOfMonth(date));
  const dayOfDate = getDay(date);
  const isDayOfDateBeforeDayOfStartOfMonth = dayOfDate - dayOfStartOfMonth < 0;
  const offsetFromWeekOfMonth = isDayOfDateBeforeDayOfStartOfMonth ? -1 : 0;

  const weekOfMonth = getWeekOfMonth(date); // Possible values: 1 ~ 6. This is not an index!

  // 月の６週目の曜日は必ず月末の曜日にマップされる。
  if (weekOfMonth === 6) {
    return WeekdayOrdinal.Last;
  } else {
    const weekOfMonthIndex = weekOfMonth - 1;
    const weekdayOrdinalIndex = weekOfMonthIndex + offsetFromWeekOfMonth;
    return indexToWeekdayOrdinal(weekdayOrdinalIndex);
  }
};
