
import Vue, { PropType } from 'vue';
import { isSameDay } from 'date-fns';
import { ICandidateDateCollectablePeriodItem } from './candidateDateCollectablePeriod';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { Vacancy } from '~/framework/domain/typeAliases';
import { formatDateForField } from '~/framework/services/date/date';
import { PersistentId } from '~/framework/typeAliases';
import { ICollectablePeriodTemplates } from '~/pages/masters/collectable-period-template/collectablePeriodTemplates';
import RDateCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RDateCollectablePeriodInput.vue';

enum Events {
  UpdateSelectedCandidateDateCollectablePeriodItem = 'update:selectedCandidateDateCollectablePeriodItem',
  UpdateDate = 'update:date',
  UpdateCollectablePeriodTemplateId = 'update:collectable-period-template-id',
  UpdateCollectablePeriodTemplateName = 'update:collectable-period-template-name',
  UpdateCollectableDistinctTime = 'update:collectable-distinct-time',
  UpdateCollectablePeriodStart = 'update:collectable-period-start',
  UpdateCollectablePeriodEnd = 'update:collectable-period-end',
}

type DataType = {
  Vacancy: typeof Vacancy;
};

export default Vue.extend({
  name: 'RCandidateDateCollectablePeriod',
  components: {
    RDateCollectablePeriodInput,
  },
  model: {
    prop: 'selectedCandidateDateCollectablePeriodItem',
    event: Events.UpdateSelectedCandidateDateCollectablePeriodItem,
  },
  props: {
    candidateDateCollectablePeriodItems: {
      type: Array as PropType<ICandidateDateCollectablePeriodItem[]>,
      required: true,
    },
    selectedCandidateDateCollectablePeriodItem: {
      type: Object as PropType<ICandidateDateCollectablePeriodItem>,
      required: true,
    },
    collectablePeriodTemplates: {
      type: Array as PropType<ICollectablePeriodTemplates[]>,
      required: true,
    },
    collectablePeriodTemplateMap: {
      type: Map as PropType<Map<PersistentId, CollectablePeriodTemplateEntity>>,
      required: true,
    },
    isCollectablePeriodDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      required: false,
      default() {
        return [];
      },
    },
  },
  data(): DataType {
    return {
      Vacancy,
    };
  },
  methods: {
    formatDateForField,
    valueComparator(a: ICandidateDateCollectablePeriodItem, b: ICandidateDateCollectablePeriodItem): boolean {
      if (!a.date || !b.date) return false;

      return (
        isSameDay(a.date, b.date) &&
        a.collectablePeriodTemplateName === b.collectablePeriodTemplateName &&
        a.collectablePeriodStart === b.collectablePeriodStart &&
        a.collectablePeriodEnd === b.collectablePeriodEnd
      );
    },
    onChangeSelectedCandidateDateCollectablePeriodItem(
      candidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem
    ): void {
      this.$emit(Events.UpdateSelectedCandidateDateCollectablePeriodItem, candidateDateCollectablePeriodItem);
    },
    onChangeDate(candidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem) {
      if (candidateDateCollectablePeriodItem.id === this.selectedCandidateDateCollectablePeriodItem.id) {
        this.$emit(Events.UpdateDate, candidateDateCollectablePeriodItem.date);
      }
    },
    onChangeCollectablePeriodTemplateId(candidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem) {
      if (candidateDateCollectablePeriodItem.id === this.selectedCandidateDateCollectablePeriodItem.id) {
        this.$emit(Events.UpdateCollectablePeriodTemplateId);
      }
    },
    onChangeCollectableDistinctTime(candidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem) {
      if (candidateDateCollectablePeriodItem.id === this.selectedCandidateDateCollectablePeriodItem.id) {
        this.$emit(Events.UpdateCollectableDistinctTime);
      }
    },
    onChangeCollectablePeriodStart(candidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem) {
      if (candidateDateCollectablePeriodItem.id === this.selectedCandidateDateCollectablePeriodItem.id) {
        this.$emit(Events.UpdateCollectablePeriodStart);
      }
    },
    onChangeCollectablePeriodEnd(candidateDateCollectablePeriodItem: ICandidateDateCollectablePeriodItem) {
      if (candidateDateCollectablePeriodItem.id === this.selectedCandidateDateCollectablePeriodItem.id) {
        this.$emit(Events.UpdateCollectablePeriodEnd);
      }
    },
  },
});
