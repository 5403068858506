
import Vue, { PropType } from 'vue';

enum EventTypes {
  ClickCancelAbort = 'click:cancel-abort',
  ClickCancelConfirm = 'click:cancel-confirm',
}

export default Vue.extend({
  name: 'RScheduleCancelConfirmDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    cancelConfirmBtnContent: {
      type: String as PropType<string>,
      required: true,
    },
  },

  methods: {
    onClickCancelAbort(): void {
      this.$emit(EventTypes.ClickCancelAbort);
    },
    onClickCancelConfirm(): void {
      this.$emit(EventTypes.ClickCancelConfirm);
    },
  },
});
