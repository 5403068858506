import {
  IOrderAcceptanceCheckError,
  InconsistencyError,
  InfeasibilityOfAttendanceError,
  InfeasibilityOfOptimizationError,
  InfeasibilityOfPrecheckError,
  NoAttendancesError,
  ViolatesMultipleCheckConditionError,
} from './resultErrors';
import { IOrderType } from './orderType';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import {
  ErrorTypes,
  IRawOrderAcceptanceJsonObject,
} from '~/framework/server-api/schedule/order/order-acceptance-check/orderAcceptanceCheck';
import { InfeasibilityCauses } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';

export interface IOrderAcceptanceCheckResult {
  /**
   * 受注を受けられるかどうか
   */
  isAcceptable: IOrderType<boolean>;

  /**
   * 受けられない場合のエラー内容
   */
  error: Maybe<IOrderAcceptanceCheckError>;
}

export class OrderAcceptanceCheckResult implements IOrderAcceptanceCheckResult {
  private readonly orderId: PersistentId;

  error: Maybe<IOrderAcceptanceCheckError>;
  isAcceptable: IOrderType<boolean>;

  constructor(orderId: PersistentId, result: IRawOrderAcceptanceJsonObject) {
    this.orderId = orderId;

    // 何もなければ受注可能という扱いにしておく
    this.isAcceptable = {
      object: true,
      others: true,
    };

    if (result.error) {
      if (result.error.type === ErrorTypes.NoAttendances) {
        this.error = new NoAttendancesError();
        this.isAcceptable.object = false;
        this.isAcceptable.others = false;
      } else if (result.error.type === ErrorTypes.ViolatesMultipleCheckCondition) {
        this.error = new ViolatesMultipleCheckConditionError(result.error.checking?.id);
        this.isAcceptable.object = false;
        this.isAcceptable.others = false;
      }
    } else if (result.infeasibilities) {
      const infeasibilitiesOfPrecheck = result.infeasibilities.filter(
        (item) => item.cause === InfeasibilityCauses.OrderOrMaster || item.cause === InfeasibilityCauses.Order
      );
      const infeasibilitiesOfAttendance = result.infeasibilities.filter(
        (item) =>
          item.cause === InfeasibilityCauses.AssignedDriverAttendance ||
          item.cause === InfeasibilityCauses.AssignedDriverCondition ||
          item.cause === InfeasibilityCauses.PrimaryCarAttendance ||
          item.cause === InfeasibilityCauses.PrimaryCarCondition ||
          item.cause === InfeasibilityCauses.DisposalSiteRestPeriod
      );
      const infeasibilitiesOfOptimization = result.infeasibilities.filter(
        (item) => item.cause === InfeasibilityCauses.Optimization
      );

      if (
        (0 < infeasibilitiesOfPrecheck.length || 0 < infeasibilitiesOfAttendance.length) &&
        0 < infeasibilitiesOfOptimization.length
      ) {
        throw new Error(
          `Infeasibilities of this pattern should not be sent at the same time!(pattern: only precheck, only attendance, only optimization and both precheck and attendance)`
        );
      }

      if (0 < infeasibilitiesOfPrecheck.length) {
        this.error = new InfeasibilityOfPrecheckError(this.orderId, infeasibilitiesOfPrecheck);
        this.isAcceptable.object = false;
        this.isAcceptable.others = false;
      } else if (0 < infeasibilitiesOfAttendance.length) {
        // precheck, attendanceの両方が設定される場合は、precheckを優先して、エラー表示している
        this.error = new InfeasibilityOfAttendanceError(this.orderId, infeasibilitiesOfAttendance);
        this.isAcceptable.object = false;
        this.isAcceptable.others = false;
      } else if (0 < infeasibilitiesOfOptimization.length) {
        const error = new InfeasibilityOfOptimizationError(this.orderId, infeasibilitiesOfOptimization);
        this.error = error;
        this.isAcceptable.object = error.hasError.object === false;
        this.isAcceptable.others = error.hasError.others === false;
      }
    } else if (result.inconsistencies) {
      this.error = new InconsistencyError(result.inconsistencies);
      this.isAcceptable.object = false;
      this.isAcceptable.others = false;
    }
  }
}
