import { AllBaseTaskTypesQuery } from '@/graphql/graphQLServerApi';
import { AllBaseTaskTypes } from '@/../graphql/server-api/queries/base-task-type.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';

export class BaseTaskTypeApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllBaseTaskTypesQuery>({
      query: AllBaseTaskTypes,
      variables: {},
    });
    return result.allBaseTaskTypes;
  }
}
