
import Vue, { PropType } from 'vue';
import { linkify } from '@fanfare/lib-js';
import { Maybe } from '~/framework/typeAliases';

export default Vue.extend({
  props: {
    text: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    /**
     * URIのaタグ targetの指定
     *   defaultの"_blank"(別タブで開く)の他に
     *   ""(同一画面遷移)、もしくは"_self"(同一フレーム遷移)を指定
     */
    target: {
      type: String as PropType<string>,
      required: false,
      default: '_blank',
    },
  },
  computed: {
    linkify(): string {
      if (!this.text) return '';
      const sanitized = this.text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return linkify(sanitized, this.target);
    },
  },
});
