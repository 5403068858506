import { ICollectionEntityData } from '~/framework/domain/schedule/schedule/pseudo-entities/collectionEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';

export interface IOriginalCollectionEntity extends ICollectionEntityData {
  /**
   * 排出場にいる時間
   */
  generationSiteDuration: number;
}

export class OriginalCollectionEntity implements IOriginalCollectionEntity {
  id: string;
  index: number;
  orderId: PseudoId;
  generationSiteArrivalTime: number;
  generationSiteDepartureTime: number;
  hasRestBeforeGenerationSiteArrival: boolean;
  isFixedAssignment: boolean;

  get generationSiteDuration() {
    return this.generationSiteDepartureTime - this.generationSiteArrivalTime;
  }

  constructor(
    id: string,
    index: number,
    orderId: PseudoId,
    generationSiteArrivalTime: number,
    generationSiteDepartureTime: number,
    hasRestBeforeGenerationSiteArrival: boolean,
    isFixedAssignment: boolean
  ) {
    this.id = id;
    this.index = index;
    this.orderId = orderId;
    this.generationSiteArrivalTime = generationSiteArrivalTime;
    this.generationSiteDepartureTime = generationSiteDepartureTime;
    this.hasRestBeforeGenerationSiteArrival = hasRestBeforeGenerationSiteArrival;
    this.isFixedAssignment = isFixedAssignment;
  }
}
