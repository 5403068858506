var render, staticRenderFns
import script from "./RVInput.vue?vue&type=script&lang=ts"
export * from "./RVInput.vue?vue&type=script&lang=ts"
import style0 from "./RVInput.vue?vue&type=style&index=0&id=5155d798&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5155d798",
  null
  
)

export default component.exports