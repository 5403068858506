
import Vue, { PropType } from 'vue';
import { DayOfWeek } from '~/framework/typeAliases';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { IMonthDatesCalendar, MonthDatesCalendar } from '~/components/common/r-month-calendar/monthDatesCalendar';
import {
  AdditionalInfoKeys,
  RinEventNames,
  RinEventPageNameTypes,
} from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  monthDatesCalendar: IMonthDatesCalendar;
};

enum Events {
  'updateDates' = 'update:dates',
}

export type UpdateDatesEventPayload = {
  startDate: Date;
  endDate: Date;
  monthDates: Array<Date>;
  weekDatesOfBaseDate: Array<Date>;
};

export default Vue.extend({
  props: {
    startOfWeek: {
      type: Number as PropType<DayOfWeek>,
      required: true,
    },
    holidayRule: {
      type: Object as PropType<HolidayRuleEntity>,
      required: true,
    },
    baseDate: {
      type: Date as PropType<Date>,
      required: false,
      default() {
        return new Date();
      },
    },
    formTarget: {
      type: String as PropType<RinEventPageNameTypes>,
      required: true,
    },
  },
  data(): DataType {
    return {
      monthDatesCalendar: new MonthDatesCalendar(this.holidayRule, this.baseDate, this.startOfWeek),
    };
  },
  watch: {
    baseDate(date: Date) {
      this.monthDatesCalendar.updateBaseDate(date);
      this.emitUpdateDatesEvent();
    },
  },
  mounted() {
    this.emitUpdateDatesEvent();
  },
  methods: {
    onClickPrevMonthButton(): void {
      this.$rinGtm.push(RinEventNames.CHANGE_MONTH, {
        [AdditionalInfoKeys.TARGET]: this.formTarget,
      });
      this.monthDatesCalendar.shiftToPreviousMonth();
      this.emitUpdateDatesEvent();
    },
    onClickNextMonthButton(): void {
      this.$rinGtm.push(RinEventNames.CHANGE_MONTH, {
        [AdditionalInfoKeys.TARGET]: this.formTarget,
      });
      this.monthDatesCalendar.shiftToNextMonth();
      this.emitUpdateDatesEvent();
    },
    emitUpdateDatesEvent(): void {
      const eventPayload: UpdateDatesEventPayload = {
        startDate: this.monthDatesCalendar.firstDate,
        endDate: this.monthDatesCalendar.lastDate,
        monthDates: this.monthDatesCalendar.monthDates,
        weekDatesOfBaseDate: this.monthDatesCalendar.getWeekDatesOf(this.baseDate),
      };

      this.$emit(Events.updateDates, eventPayload);
    },
  },
});
