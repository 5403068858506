export const jwnetWasteMaster$getAllSymbol = Symbol('getAll');

export type JwnetWasteMaster = {
  [jwnetWasteMaster$getAllSymbol]: IGetAllApi;
};

export interface IGetAllApi {
  getAll(): Promise<IJwnetWasteMasterData[]>;
}
export interface IJwnetWasteMasterData {
  id: string;
  firstCategoryName: string;
  secondCategoryName: string | undefined;
  thirdCategoryName: string | undefined;
  code: string;
  isUsable: boolean;
}
