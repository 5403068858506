import { ReservationNamespace } from './reservation/reservationNamespace';
import { MastersStore } from '~/framework/domain/masters/mastersStore';
import { ScheduleNamespace } from '~/framework/domain/schedule/scheduleNamespace';
import { UserSettingStore } from '~/framework/domain/user-setting/userSettingStore';
import { PackingPlacementNamespace } from '~/framework/domain/packing-placement/packingPlacementNamespace';

/**
 * 各種 ViewModel を格納しておくもの
 */
export class Store {
  readonly masters: MastersStore;
  readonly schedule: ScheduleNamespace;
  readonly userSetting: UserSettingStore;
  readonly packingPlacement: PackingPlacementNamespace;
  readonly reservation: ReservationNamespace;

  constructor() {
    this.masters = new MastersStore();
    this.schedule = new ScheduleNamespace();
    this.userSetting = new UserSettingStore();
    this.packingPlacement = new PackingPlacementNamespace();
    this.reservation = new ReservationNamespace();
  }
}
