import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';

/**
 * CollectablePeriodTemplateEntity を編集するためのもの
 */
export interface ICollectablePeriodTemplateFormPanel extends IEntityFormPanel<CollectablePeriodTemplateEntity> {}

export class CollectablePeriodTemplateFormPanel
  extends AbstractEntityFormPanel<CollectablePeriodTemplateEntity>
  implements ICollectablePeriodTemplateFormPanel {}
