import {
  AllWasteTypesQuery,
  AllWasteTypesQueryVariables,
  AllDisplayedWasteTypesQuery,
  AllDisplayedWasteTypesQueryVariables,
  WasteTypesByKeywordsQuery,
  WasteTypesByKeywordsQueryVariables,
  AvailableWasteTypeCodeQuery,
  AvailableWasteTypeCodeQueryVariables,
  CreateWasteTypeMutation,
  CreateWasteTypeMutationVariables,
  UpdateWasteTypeMutation,
  UpdateWasteTypeMutationVariables,
} from '@/graphql/graphQLServerApi';
import {
  AllWasteTypes,
  AllDisplayedWasteTypes,
  WasteTypesByKeywords,
  AvailableWasteTypeCode,
} from '@/../graphql/server-api/queries/waste-type.gql';
import { CreateWasteType, UpdateWasteType } from '@/../graphql/server-api/mutations/waste-type.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ICreateData, IUpdateData } from '~/framework/server-api/masters/wasteType';

export class GetAllApi extends GraphqlApiBase {
  async getAll() {
    const result = await this.query<AllWasteTypesQuery, AllWasteTypesQueryVariables>({
      query: AllWasteTypes,
      variables: {},
    });
    return result.allWasteTypes;
  }
}

export class GetAllDisplayedApi extends GraphqlApiBase {
  async getAllDisplayed() {
    const result = await this.query<AllDisplayedWasteTypesQuery, AllDisplayedWasteTypesQueryVariables>({
      query: AllDisplayedWasteTypes,
      variables: {},
    });
    return result.allDisplayedWasteTypes;
  }
}

export class GetByKeywordsApi extends GraphqlApiBase {
  async getByKeywords(keywords: string[]) {
    const result = await this.query<WasteTypesByKeywordsQuery, WasteTypesByKeywordsQueryVariables>({
      query: WasteTypesByKeywords,
      variables: { keywords },
    });
    return result.wasteTypesByKeywords;
  }
}

export class GetAvailableCodeApi extends GraphqlApiBase {
  async getAvailableCode(code: string) {
    const result = await this.query<AvailableWasteTypeCodeQuery, AvailableWasteTypeCodeQueryVariables>({
      query: AvailableWasteTypeCode,
      variables: { code },
    });
    return result.availableWasteTypeCode;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(wasteType: ICreateData) {
    const result = await this.mutate<CreateWasteTypeMutation, CreateWasteTypeMutationVariables>({
      mutation: CreateWasteType,
      variables: { wasteType },
    });
    return result.createWasteType;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(wasteType: IUpdateData) {
    const result = await this.mutate<UpdateWasteTypeMutation, UpdateWasteTypeMutationVariables>({
      mutation: UpdateWasteType,
      variables: { wasteType },
    });
    return result.updateWasteType;
  }
}
