import { render, staticRenderFns } from "./RContainerTaskForm.vue?vue&type=template&id=b5a51cd0&scoped=true"
import script from "./RContainerTaskForm.vue?vue&type=script&lang=ts"
export * from "./RContainerTaskForm.vue?vue&type=script&lang=ts"
import style0 from "./RContainerTaskForm.vue?vue&type=style&index=0&id=b5a51cd0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5a51cd0",
  null
  
)

export default component.exports