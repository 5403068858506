import { SystemContext } from '~/framework/systemContext';
import { PersistentId } from '~/framework/typeAliases';

export enum Features {
  OrderAcceptanceCheck,
}

export class FeatureManager {
  private readonly fanfareDemoOfficeId: PersistentId = '11';
  private readonly systemContext: SystemContext;

  constructor(systemContext: SystemContext) {
    this.systemContext = systemContext;
  }

  /**
   * 将来的にデータを取ったりするかもしれないので非同期にしておく
   *
   * @param features
   */
  getFeatureAvailabilities(features: Features[]): Promise<boolean[]> {
    const results: boolean[] = [];
    for (const feature of features) {
      // TODO Feature ごとにチェッカーを追加できる様に
      if (feature === Features.OrderAcceptanceCheck) {
        results.push(true);
      } else {
        throw new Error(`Not compatible with: ${feature}`);
      }
    }
    return Promise.resolve(results);
  }
}
