
import Vue, { PropType } from 'vue';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { dateToYymmddhhmm } from '~/framework/services/date-time/date-time';

// 一覧のポップアップ詳細(menu)を表示する際に，更新・登録を行ったユーザ・日時を表示します．
export default Vue.extend({
  name: 'RRecordStamperForMenu',
  props: {
    createdBy: {
      type: Object as PropType<UserEntity>,
      required: true,
    },
    createdAt: {
      type: Date as PropType<Date>,
      required: true,
    },
    updatedBy: {
      type: Object as PropType<UserEntity>,
      required: true,
    },
    updatedAt: {
      type: Date as PropType<Date>,
      required: true,
    },
  },
  computed: {
    nameOfCreatedBy(): string {
      return this.createdBy?.name || '';
    },
    nameOfUpdatedBy(): string {
      return this.updatedBy?.name || '';
    },
    createdAtYymmddhhmm(): string {
      return this.createdAt ? dateToYymmddhhmm(this.createdAt) : '';
    },
    updatedAtYymmddhhmm(): string {
      return this.updatedAt ? dateToYymmddhhmm(this.updatedAt) : '';
    },
    isCreatedByDeleted(): boolean {
      // TODO: ユーザの論理削除実行時に `this.createdBy.status === 'Deleted'` みたいな判定に変更する
      return false;
    },
    isUpdatedByDeleted(): boolean {
      // TODO: ユーザの論理削除実行時に `this.updatedBy.status === 'Deleted'` みたいな判定に変更する
      return false;
    },
  },
});
