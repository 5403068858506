import consola, { Consola, ConsolaLogObject, LogLevel } from 'consola';
import * as Sentry from '@sentry/browser';
import { Breadcrumb } from '@sentry/types';
import { Maybe } from '~/framework/typeAliases';
import { isProduction, isStaging } from '~/env';

export type RinLogger = Consola & {
  /**
   * type = navigation, ui.*, info 等
   * message = メッセージ（行為）
   * category = カテゴリ
   *
   * type は詳しくは
   * https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
   * を参照のこと。
   *
   * @param breadcrumb
   */
  addBreadcrumb(breadcrumb: Breadcrumb): void;
};

const logLevelSeverityMap = new Map<number, Sentry.SeverityLevel>([
  [0, 'error'],
  [1, 'warning'],
  [2, 'log'],
  [3, 'info'],
  [4, 'debug'],
  [5, 'debug'],
]);

// https://github.com/nuxt-contrib/consola/blob/master/src/types.js
export const createLogger = (tag: string): RinLogger => {
  const logger = consola.withTag(tag);
  const isOnProduction = isProduction();
  const isOnStaging = isStaging();
  logger.level = isOnProduction ? LogLevel.Warn : LogLevel.Trace;
  // 複数回呼ばれる可能性があり、add すると何度も reporter が追加されてしまうので何度も追加されない様にしている

  if (isOnProduction || isOnStaging) {
    logger.removeReporter(sentryReporter);
    logger.addReporter(sentryReporter);
  }

  // ref: https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  Object.assign(logger, {
    addBreadcrumb(breadcrumb: Breadcrumb): void {
      Sentry.addBreadcrumb(breadcrumb);
    },
  });
  const wantToNotRead = JSON.parse(window.localStorage.getItem('disableLogger') || 'false');
  if (wantToNotRead) {
    const dummyLogger = new Consola({}) as RinLogger;
    dummyLogger.log = () => {};
    dummyLogger.addBreadcrumb = () => {};
    return dummyLogger;
  }

  return logger as unknown as RinLogger;
};

/**
 * ログを Sentry に送るための Consola のレポーター
 */
class SentryReporter {
  log(logObj: ConsolaLogObject): void {
    const severity = logObj.level !== undefined ? logLevelSeverityMap.get(logObj.level) : undefined;
    const message = this.generateMessageFrom(logObj);
    if (message !== undefined) Sentry.captureMessage(message, severity);
  }

  private generateMessageFrom(logObj: ConsolaLogObject): Maybe<string> {
    if (logObj.message === undefined && (logObj.args === undefined || logObj.args.length === 0)) return undefined;
    if (logObj.args === undefined) return logObj.message;
    if (logObj.message === undefined) return this.generateMessageFromArgs(logObj.args);
    else return `${logObj.message}, ${this.generateMessageFromArgs(logObj.args)}`;
  }

  private generateMessageFromArgs(args: any[]): string {
    if (args.length === 0) return ``;
    if (args.length === 1) return `${args[0]}`;
    return args.map((arg) => `${arg}`).join(`, `);
  }
}

const sentryReporter = new SentryReporter();
