import { MaybeWeakRef } from '~/framework/typeAliases';

/**
 * WeakRef がないブラウザで Constructor をそのまま参照するとエラーになってしまう
 * Chrome では対応されていると思っていたが iOS 版だとないものがある模様（？）
 * WeakRef 自体を無効化するには影響範囲が大きすぎるのでひとまず回避するための仕組み
 * 使用されているコードで Port を WeakRef で突っ込んでいるところは現状ない
 * @param value
 */
export const isWeakRef = <Port extends object>(value: MaybeWeakRef<Port>): value is WeakRef<Port> => {
  return typeof value === 'object' && (value as any)[Symbol.toStringTag] === 'WeakRef';
};
