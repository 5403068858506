import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedOrderEntity, PostponeOrderStatus } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedOrderStore } from '~/framework/domain/schedule/order/aggregatedOrderStore';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { AggregatedGenerationSiteTaskMapper } from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskMapper';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { IrregularTaskMapper } from '~/framework/domain/schedule/order/irregular-task/irregularTaskMapper';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { AggregatedDriverMapper } from '../../masters/driver/aggregatedDriverMapper';
import { AggregatedGenerationSiteMapper } from '../../masters/generation-site/aggregatedGenerationSiteMapper';
import { IOrderData } from '~/framework/server-api/schedule/order/order';
import { AggregatedGenerationSiteStore } from '../../masters/generation-site/aggregatedGenerationSiteStore';
import { CollectablePeriodTemplateStore } from '../../masters/collectable-period-template/collectablePeriodTemplateStore';
import { AggregatedDriverStore } from '../../masters/driver/aggregatedDriverStore';
import { DriverAttendanceTemplateStore } from '../../masters/driver-attendance-template/driverAttendanceTemplateStore';
import { SimpleCarTypeEntity } from '../../masters/car-type/aggregatedCarTypeEntity';
import { AggregatedContainerTypeStore } from '../../masters/container-type/aggregatedContainerTypeStore';
import { PackingStyleStore } from '../../masters/packing-style/packingStyleStore';

export class AggregatedOrderMapper extends StoredMapperBase<IOrderData, AggregatedOrderEntity> {
  protected store: AggregatedOrderStore;
  private clientMapper: AggregatedClientMapper;
  private orderGroupMapper: OrderGroupMapper;
  private generationSiteMapper: AggregatedGenerationSiteMapper;
  private disposalSiteMapper: AggregatedDisposalSiteMapper;
  private driverMapper: AggregatedDriverMapper;
  private carMapper: AggregatedCarMapper;
  private userMapper: UserMapper;
  private generationSiteTaskMapper: AggregatedGenerationSiteTaskMapper;
  private irregularTaskMapper: IrregularTaskMapper;

  constructor(
    store: AggregatedOrderStore,
    aggregatedGenerationSiteStore: AggregatedGenerationSiteStore,
    clientStore: AggregatedClientStore,
    userStore: UserStore,
    collectablePeriodTemplateStore: CollectablePeriodTemplateStore,
    orderGroupStore: OrderGroupStore,
    disposalSiteStore: AggregatedDisposalSiteStore,
    wasteTypeStore: AggregatedWasteTypeStore,
    carStore: AggregatedCarStore,
    baseSiteStore: AggregatedBaseSiteStore,
    driverStore: AggregatedDriverStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    jwnetWasteMasterStore: JwnetWasteMasterStore,
    containerTypeStore: AggregatedContainerTypeStore,
    packingStyleStore: PackingStyleStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new AggregatedClientMapper(clientStore, userStore);
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.generationSiteMapper = new AggregatedGenerationSiteMapper(
      aggregatedGenerationSiteStore,
      clientStore,
      userStore,
      disposalSiteStore,
      collectablePeriodTemplateStore,
      carStore,
      baseSiteStore,
      driverStore,
      driverAttendanceTemplateStore
    );
    this.disposalSiteMapper = new AggregatedDisposalSiteMapper(disposalSiteStore, userStore);
    this.driverMapper = new AggregatedDriverMapper(
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.carMapper = new AggregatedCarMapper(carStore, baseSiteStore, userStore);
    this.userMapper = new UserMapper(userStore);
    this.generationSiteTaskMapper = new AggregatedGenerationSiteTaskMapper(
      wasteTypeStore,
      jwnetWasteMasterStore,
      userStore,
      containerTypeStore,
      packingStyleStore,
      driverStore,
      carStore,
      baseSiteStore,
      driverAttendanceTemplateStore
    );
    this.irregularTaskMapper = new IrregularTaskMapper();
  }

  protected instantiateWithData(data: IOrderData): AggregatedOrderEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSiteEntity = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = data.assignedDisposalSitesAndType.orderDisposalSites.map(
      (orderDisposalSite) => orderDisposalSite.disposalSite
    );
    const orderAssignableDrivers = data.assignableDrivers.map((orderAssignableDriver) => {
      return {
        driverType: orderAssignableDriver.driverType,
        driver: this.driverMapper.mapSingle(orderAssignableDriver.driver),
      };
    });
    const assignedDisposalSiteEntities = this.disposalSiteMapper.map(assignedDisposalSites);
    const assignableCarTypes = data.assignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const assignableCars = this.carMapper.map(data.assignableCars);
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);

    const generationSiteTasks = this.generationSiteTaskMapper.map(data.generationSiteTasks);
    const irregularTasks = this.irregularTaskMapper.map(data.irregularTasks);
    // __typename入っていれば削除する
    delete data.plan?.__typename;
    delete data.plan?.fixed?.__typename;
    data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);

    return new AggregatedOrderEntity(
      data,
      createdBy,
      updatedBy,
      client,
      orderGroup,
      generationSiteEntity,
      orderAssignableDrivers,
      assignedDisposalSiteEntities,
      assignableCars,
      assignableCarTypes,
      generationSiteTasks,
      irregularTasks,
      PostponeOrderStatus.Default
    );
  }

  protected updateWithData(data: IOrderData, entity: AggregatedOrderEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSiteEntity = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = data.assignedDisposalSitesAndType.orderDisposalSites.map(
      (orderDisposalSite) => orderDisposalSite.disposalSite
    );
    const orderAssignableDrivers = data.assignableDrivers.map((orderAssignableDriver) => {
      return {
        driverType: orderAssignableDriver.driverType,
        driver: this.driverMapper.mapSingle(orderAssignableDriver.driver),
      };
    });
    const assignedDisposalSiteEntities = this.disposalSiteMapper.map(assignedDisposalSites);
    const assignableCarTypes = data.assignableCarTypes.map((carType) => new SimpleCarTypeEntity(carType));
    const assignableCars = this.carMapper.map(data.assignableCars);
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);

    const generationSiteTasks = this.generationSiteTaskMapper.map(data.generationSiteTasks);
    const irregularTasks = this.irregularTaskMapper.map(data.irregularTasks);
    // __typename入っていれば削除する
    delete data.plan?.__typename;
    delete data.plan?.fixed?.__typename;
    data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);

    entity.update(
      data,
      createdBy,
      updatedBy,
      client,
      orderGroup,
      generationSiteEntity,
      orderAssignableDrivers,
      assignedDisposalSiteEntities,
      assignableCars,
      assignableCarTypes,
      generationSiteTasks,
      irregularTasks,
      PostponeOrderStatus.Default
    );
  }
}
