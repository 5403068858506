
import Vue, { PropType } from 'vue';
import { Maybe } from '~/framework/typeAliases';
import { IIsProminentOption, isProminentOptions } from '~/framework/view-models/isProminentOption';
import RVRadio from '~/components/common/r-radio-group/RVRadio.vue';

enum EventTypes {
  Change = 'change',
}

type DataType = {
  isProminentOptions: IIsProminentOption[];
};

export default Vue.extend({
  name: 'RWasteTypeIsProminentOption',
  components: { RVRadio },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean as PropType<Maybe<boolean>>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    return {
      isProminentOptions,
    };
  },
  methods: {
    onChange(changeArgs: any): void {
      this.$emit(EventTypes.Change, changeArgs);
    },
  },
});
