import { IAttendanceData } from '~/framework/server-api/masters/attendance';
import { Seconds } from '~/framework/typeAliases';
import { CarAttendanceType } from '~/framework/domain/typeAliases';

export const carAttendance$getByDateRangeSymbol = Symbol('carAttendance$getByDateRangeSymbol');

export const carAttendance$updateSymbol = Symbol('carAttendance$updateSymbol');

export type CarAttendance = {
  [carAttendance$getByDateRangeSymbol]: IGetByDateRangeApi;
  [carAttendance$updateSymbol]: IUpdateApi;
};

export interface ICarAttendanceData {
  id: string;
  attendance: IAttendanceData;
  carId: string;
  carAttendanceType: CarAttendanceType;
  periodStart: Seconds | undefined;
  periodEnd: Seconds | undefined;
  note: string | undefined;
}

export interface IGetByDateRangeApi {
  getByDateRange(start: Date, end: Date, carIds?: Array<string>): Promise<ICarAttendanceData[]>;
}

export interface IUpdateApi {
  update(data: IUpdateData): Promise<ICarAttendanceData>;
}

export interface IUpdateData {
  id: string;
  attendanceId: string;
  carId: string;
  carAttendanceType: CarAttendanceType;
  periodStart: number | undefined;
  periodEnd: number | undefined;
  note: string | undefined;
}
