
import Vue, { PropType } from 'vue';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { Maybe } from '~/framework/typeAliases';

enum EventTypes {
  ClickClose = 'click:close',
  ClickCheckSchedule = 'click:check-schedule',
}

export default Vue.extend({
  name: 'RAcceptanceCheckResultInconsistent',
  props: {
    inconsistencies: {
      type: Array as PropType<Maybe<Inconsistency[]>>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    driverNames(): Maybe<string[]> {
      if (this.inconsistencies === undefined) return undefined;
      return this.inconsistencies.map((inconsistency) => inconsistency.driver.name);
    },
  },
  methods: {
    onClickCloseButton(): void {
      this.$emit(EventTypes.ClickClose);
    },
    onClickCheckScheduleButton(): void {
      this.$emit(EventTypes.ClickCheckSchedule);
    },
  },
});
