import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IUnreadAnnouncementsApi } from '~/framework/server-api/announcement/unreadAnnouncements';
import { IAnnouncementData } from '~/framework/server-api/announcement/announcement';
import { UnreadAnnouncements } from '@/../graphql/server-api/queries/announcement.gql';
import { UnreadAnnouncementsQuery, UnreadAnnouncementsQueryVariables } from '~/graphql/graphQLServerApi';

export class UnreadAnnouncementsApi extends GraphqlApiBase implements IUnreadAnnouncementsApi {
  async unreadAnnouncements(): Promise<IAnnouncementData[]> {
    const result = await this.query<UnreadAnnouncementsQuery, UnreadAnnouncementsQueryVariables>({
      query: UnreadAnnouncements,
      variables: {},
    });

    return result.unreadAnnouncements.map((unreadAnnouncement) => {
      return {
        id: unreadAnnouncement.id,
        subject: unreadAnnouncement.subject,
        body: unreadAnnouncement.body,
        publishedSince: new Date(unreadAnnouncement.publishedSince),
        publishedUntil: unreadAnnouncement.publishedUntil ? new Date(unreadAnnouncement.publishedUntil) : undefined,
        isRead: unreadAnnouncement.isRead,
      };
    });
  }
}
