import { DayOfWeek, PersistentId } from '~/framework/typeAliases';
import { ScheduleTabType } from '~/framework/domain/typeAliases';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { userSetting$getSymbol } from '~/framework/server-api/user-setting/get';
import { userSetting$updateSymbol } from '~/framework/server-api/user-setting/update';
import { UserSettingMapper } from '~/framework/domain/user-setting/userSettingMapper';
import { UserSettingEntity } from '~/framework/domain/user-setting/userSettingEntity';

export const userSettingSymbol = Symbol('userSettingSymbol');

export class UserSettingApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async get(): Promise<UserSettingEntity> {
    const userSetting$getApi = this.serverApis.get(userSetting$getSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);
    const userSettingData = await userSetting$getApi.get();
    const userSetting = userSettingMapper.mapSingle(userSettingData);
    return userSetting;
  }

  async setScheduleInconsistencyFixed(scheduleId: PersistentId, index: number): Promise<void> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper = new UserSettingMapper(this.store.userSetting);

    const data = await getApi.get();
    const entity = userSettingMapper.mapSingle(data);

    // NOTE 若干気持ち悪いのだがエンティティを書き換えてその結果を書き戻すという事をやっている
    //   UserSetting はローカルに書き出されるので、これが失敗する事はまぁないだろうという前提
    //   古いロジックの名残
    entity.setScheduleInconsistencyFixed(scheduleId, index);
    await updateApi.update({
      id: entity.id,
      scheduleDate: entity.scheduleDate,
      scheduleStartOfWeek: entity.scheduleStartOfWeek,
      scheduleTimelineStart: entity.scheduleTimelineStart,
      scheduleTimelineEnd: entity.scheduleTimelineEnd,
      scheduleTab: entity.scheduleTab,
      orderGroupId: entity.orderGroupId,
      scheduleSolutionStatus: entity.scheduleSolutionStatus,
      loginRememberMe: entity.loginRememberMe,
    });
  }

  async setScheduleInfeasibilityFixed(scheduleId: PersistentId, index: number): Promise<void> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);

    const data = await getApi.get();
    const entity = userSettingMapper.mapSingle(data);

    // NOTE 若干気持ち悪いのだがエンティティを書き換えてその結果を書き戻すという事をやっている
    //   UserSetting はローカルに書き出されるので、これが失敗する事はまぁないだろうという前提
    //   古いロジックの名残
    entity.setScheduleInfeasibilityFixed(scheduleId, index);
    await updateApi.update({
      id: entity.id,
      scheduleDate: entity.scheduleDate,
      scheduleStartOfWeek: entity.scheduleStartOfWeek,
      scheduleTimelineStart: entity.scheduleTimelineStart,
      scheduleTimelineEnd: entity.scheduleTimelineEnd,
      scheduleTab: entity.scheduleTab,
      orderGroupId: entity.orderGroupId,
      scheduleSolutionStatus: entity.scheduleSolutionStatus,
      loginRememberMe: entity.loginRememberMe,
    });
  }

  async setScheduleDate(date: Date): Promise<void> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);

    const data = await getApi.get();
    data.scheduleDate = date;
    await updateApi.update(data);
    userSettingMapper.mapSingle(data);
  }

  async setScheduleStartOfWeek(startOfWeek: DayOfWeek): Promise<void> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);

    const data = await getApi.get();
    data.scheduleStartOfWeek = startOfWeek;
    await updateApi.update(data);
    userSettingMapper.mapSingle(data);
  }

  async setScheduleTab(tab: ScheduleTabType) {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);

    const data = await getApi.get();
    data.scheduleTab = tab;
    await updateApi.update(data);
    userSettingMapper.mapSingle(data);
  }

  async setScheduleOrderGroupId(id: PersistentId): Promise<void> {
    const getApi = this.serverApis.get(userSetting$getSymbol);
    const updateApi = this.serverApis.get(userSetting$updateSymbol);
    const userSettingMapper: UserSettingMapper = new UserSettingMapper(this.store.userSetting);

    const data = await getApi.get();
    data.orderGroupId = id;
    await updateApi.update(data);
    userSettingMapper.mapSingle(data);
  }
}
