import { Maybe } from '~/framework/typeAliases';
import {
  DistinctTimeOptionId,
  DesignatedTimePeriodOptionId,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';

// 時間厳守、時間指定をチェックしていい感じに表示すべきテンプレート名、開始・終了を計算する
export class DefaultCollectablePeriod {
  readonly templateEntity: Maybe<CollectablePeriodTemplateEntity>;
  // NOTE: 時間厳守、時間指定の場合は entity は存在しないが、内部的な id ( DistinctTimeOptionId / DesignatedTimePeriodOptionId ) が設定される
  readonly templateId: Maybe<string>;
  readonly templateName: string;
  readonly start: Maybe<number>;
  readonly end: Maybe<number>;
  readonly distinct: Maybe<number>;

  constructor(templateEntity: Maybe<CollectablePeriodTemplateEntity>, start: Maybe<number>, end: Maybe<number>) {
    // NOTE: 到着時間テンプレートがある場合はテンプレートにある時間を設定する
    if (templateEntity !== undefined) {
      this.templateEntity = templateEntity;
      this.templateId = templateEntity.id;
      this.templateName = templateEntity.name;

      this.start = templateEntity.collectablePeriodStart;
      this.end = templateEntity.collectablePeriodEnd;

      return;
    }

    // NOTE: start, end が指定されている場合は時間指定とする
    if (start !== undefined || end !== undefined) {
      // NOTE: start === end の場合は時間厳守として扱う
      if (start === end) {
        this.templateId = DistinctTimeOptionId;
        this.templateName = '時間厳守';
        this.distinct = start;
      } else {
        this.templateId = DesignatedTimePeriodOptionId;
        this.templateName = '時間指定';
        this.start = start;
        this.end = end;
      }

      return;
    }

    // NOTE: template も時間指定もない場合は空で初期化する
    this.templateId = undefined;
    this.templateName = '';
  }

  isCollectablePeriodDistinct(): boolean {
    return this.templateId === DistinctTimeOptionId;
  }

  isCollectablePeriodDesignated(): boolean {
    return this.templateId === DesignatedTimePeriodOptionId;
  }

  // NOTE: デフォルトの到着時間指定がないことをチェック
  isDefaultCollectablePeriodEmpty(): boolean {
    return this.templateId === undefined && this.start === undefined && this.end === undefined;
  }
}
