
import Vue from 'vue';
import prefectures from '~/assets/settings/prefectures.json';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import {
  checkItemServiceSymbol,
  CheckItemApplicationService,
} from '~/framework/application/masters/check-item/checkItemApplicationService';
import { CheckItemMutationErrorTypes, ICheckItemMutationError } from '~/framework/server-api/masters/checkItem';
import { CheckItemEntity } from '~/framework/domain/masters/check-item/checkItemEntity';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { required } from '~/framework/view-models/rules';
import { ensure } from '~/framework/core/value';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

enum FormMode {
  Register,
  Edit,
}

const FormTitles = {
  [FormMode.Register]: 'タスクの登録',
  [FormMode.Edit]: 'タスクの編集',
} as const;

enum EventTypes {
  Change = 'change',
}

const defaultItems = [
  { value: true, label: '必要' },
  { value: false, label: '不要' },
];

interface IFormValues {
  name: string;
  default: boolean;

  hasChanges(that: IFormValues): boolean;
}

type ErrorTypes = CheckItemMutationErrorTypes['__typename'];

interface IFormErrors {
  // リアクティブに動かしたいため、配列にしてる
  fields: {
    name: ErrorTypes[];
    default: ErrorTypes[];
  };

  addError(field: keyof IFormErrors['fields'], error: ErrorTypes): void;
  removeError(field: keyof IFormErrors['fields'], error: ErrorTypes): void;
  resetAllErrors(): void;
}

const errorMessages: Map<ErrorTypes, string> = new Map([['DuplicatedNameError', '同じ名前が存在します。']]);

type DataType = {
  checkItemApplicationService: CheckItemApplicationService;
  /** `FormMode` への参照 */
  FormMode: typeof FormMode;
  prefectures: typeof prefectures;
  defaultItems: typeof defaultItems;
  /* 入力フォームの制御 */
  formMode: FormMode;
  formValues: IFormValues;
  initialFormValues: Maybe<Readonly<IFormValues>>;
  formErrors: IFormErrors;
  rules: {
    required: typeof required;
  };
  isFormValid: boolean;

  /** 編集中/登録完了/更新完了したこのフォームに関連する拠点エンティティの参照 */
  entities: {
    editingCheckItem: Maybe<CheckItemEntity>;
    createdCheckItem: Maybe<CheckItemEntity>;
    updatedCheckItem: Maybe<CheckItemEntity>;
    removedCheckItem: Maybe<CheckItemEntity>;
  };

  /* パネルの状態 */
  isActive: boolean;
  isRegistering: boolean;
  isDeleteConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;

  /* パネルの制御下にあるコンポーネントの状態 */
  widgets: {
    closeConfirmDialog: {
      isActive: boolean;
      resolver: Maybe<(value: boolean) => void>;
    };
  };
};

/**
 * 入力フォームの状態
 */
class FormValues implements IFormValues {
  name: string = '';
  default: boolean = true;

  constructor(name: string = '', isDefault: boolean = true) {
    this.name = name;
    this.default = isDefault;
  }

  hasChanges(another: IFormValues): boolean {
    return this.name !== another.name || this.default !== another.default;
  }

  static createEmpty(): FormValues {
    return new FormValues();
  }

  /**
   * CheckItemのエンティティから入力フォームの初期値を補完する
   *
   * @param checkItem
   * @returns
   */
  static fromEntity(checkItem: CheckItemEntity): FormValues {
    return new FormValues(checkItem.name, checkItem.default);
  }
}

class FormErrors implements IFormErrors {
  fields: IFormErrors['fields'];

  constructor() {
    this.fields = {
      name: [],
      default: [],
    };
  }

  addError(field: keyof IFormErrors['fields'], error: ErrorTypes): void {
    this.fields[field].push(error);
  }

  removeError(field: keyof IFormErrors['fields'], error: ErrorTypes): void {
    this.fields[field] = this.fields[field].filter((item) => item !== error);
  }

  resetAllErrors(): void {
    this.fields = {
      name: [],
      default: [],
    };
  }
}

export default Vue.extend({
  name: 'RCheckItemForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const checkItemApplicationService = this.$context.applications.get(checkItemServiceSymbol);
    return {
      checkItemApplicationService,
      FormMode,
      prefectures,
      defaultItems,
      formMode: FormMode.Register,
      formValues: FormValues.createEmpty(),
      initialFormValues: undefined,
      formErrors: new FormErrors(),
      rules: {
        required,
      },
      isFormValid: true,

      entities: {
        editingCheckItem: undefined,
        createdCheckItem: undefined,
        updatedCheckItem: undefined,
        removedCheckItem: undefined,
      },

      isActive: false,
      isRegistering: false,
      isDeleteConfirmDialogActive: false,
      listenerDisposer: undefined,
      widgets: {
        closeConfirmDialog: {
          isActive: false,
          resolver: undefined,
        },
      },
    };
  },
  computed: {
    title(): string {
      return FormTitles[this.formMode];
    },
    isRegisterButtonEnabled(): boolean {
      return this.isFormValid;
    },
    registerLabel(): string {
      return this.formMode === FormMode.Register ? '登録' : '編集完了';
    },
    isDirty(): boolean {
      if (this.formValues && this.initialFormValues) {
        return this.formValues.hasChanges(this.initialFormValues);
      } else {
        return false;
      }
    },
    nameErrorMessages(): string[] {
      return this.formErrors.fields.name.map((error) => errorMessages.getOrError(error));
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.CHECK_ITEM;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.checkItemFormPanel.openFormEvent.on(this.onOpen);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );

    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };

    this.$context.panels.checkItemFormPanel.registerCloseHandler(this.onCloseForm);
    this.checkItemApplicationService.addCreatePresenter({
      create: this.onCreate,
      errorOnCreate: this.onFormError,
    });
    this.checkItemApplicationService.addUpdatePresenter({
      update: this.onUpdate,
      errorOnUpdate: this.onFormError,
    });
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    /* event handlers for the components' own event */
    /**
     * パネルが開かれた時のイベント処理
     * @param checkItem
     * @returns
     */
    async open(checkItem: Maybe<CheckItemEntity>): Promise<void> {
      if (this.isActive) return;

      this.initializeForm(checkItem);
      await (this.$refs.RSideform as RSideformInstance).open();

      this.isActive = true;
      this.$emit(EventTypes.Change, true);

      this.$rinGtm.push(RinEventNames.OPEN_FORM, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CHECK_ITEM,
      });
    },
    /**
     * パネルが閉じられた時のイベント処理
     * @returns
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;

      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, false);

      const closeArgs: ICloseEntityFormArgs<CheckItemEntity> = {
        entity: this.entities.editingCheckItem,
        createdEntity: this.entities.createdCheckItem,
        updatedEntity: this.entities.updatedCheckItem,
        removedEntity: this.entities.removedCheckItem,
      };
      this.$context.panels.checkItemFormPanel.closeFormEvent.emit(closeArgs);
      this.widgets.closeConfirmDialog = {
        isActive: false,
        resolver: undefined,
      };
      this.formErrors.resetAllErrors();

      this.$rinGtm.push(RinEventNames.CLOSE_FORM, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CHECK_ITEM,
      });
    },
    /** 登録 */
    async onClickRegister(): Promise<void> {
      this.isRegistering = true;
      this.formErrors.resetAllErrors();
      ensure(this.formValues.name);
      ensure(this.formValues.default);
      if (this.formMode === FormMode.Register) {
        await this.checkItemApplicationService.create({
          name: this.formValues.name,
          default: this.formValues.default,
        });
      } else if (this.formMode === FormMode.Edit) {
        ensure(this.entities.editingCheckItem);
        await this.checkItemApplicationService.update(this.entities.editingCheckItem.id, {
          name: this.formValues.name,
          default: this.formValues.default,
        });
      }

      this.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CHECK_ITEM,
        [AdditionalInfoKeys.MODE]: this.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },
    onClickConfirmClose(confirm: boolean): void {
      this.widgets.closeConfirmDialog.isActive = false;
      if (this.widgets.closeConfirmDialog.resolver === undefined)
        throw new Error(`RCheckItemForm: closeConfirmDialog resolver is not set`);

      this.widgets.closeConfirmDialog.resolver(confirm);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.close);
        context.stop();
      }
    },
    onCreate(checkItem: CheckItemEntity): void {
      this.entities.createdCheckItem = checkItem;
      this.$context.snackbar.success('タスクの登録完了');
      this.$nextTick(this.close);
    },
    onUpdate(checkItem: CheckItemEntity): void {
      this.entities.updatedCheckItem = checkItem;
      this.$context.snackbar.success('タスクの編集完了');
      this.$nextTick(this.close);
    },

    /* subscribe to panel manager events */
    async onOpen({ entity }: IOpenEntityFormArgs<CheckItemEntity>): Promise<void> {
      await this.open(entity);
    },
    async onCloseForm(): Promise<boolean> {
      if (this.isDirty) {
        const closeConfirmPromise = new Promise<boolean>((resolve) => {
          this.widgets.closeConfirmDialog.isActive = true;
          this.widgets.closeConfirmDialog.resolver = resolve;
        });
        const isClosable = await closeConfirmPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.checkItemFormPanel.updateDisplayedWidth(value);
    },

    /* helpers */
    initializeForm(checkItem: Maybe<CheckItemEntity>): void {
      const newFormMode = checkItem === undefined ? FormMode.Register : FormMode.Edit;

      // 編集用のオブジェクトとサーバーから取得したデータは個別に保持する
      this.initialFormValues = this.getInitialFormValues(checkItem);
      this.formValues = this.getInitialFormValues(checkItem);

      this.formMode = newFormMode;

      // 編集中のデータに関連するエンティティを最新の状態にする
      this.entities.editingCheckItem = checkItem;
    },
    getInitialFormValues(checkItem: Maybe<CheckItemEntity>): IFormValues {
      return checkItem === undefined ? FormValues.createEmpty() : FormValues.fromEntity(checkItem);
    },

    onChangeName(): void {
      this.formErrors.removeError('name', 'DuplicatedNameError');
    },

    onFormError({ errors }: ICheckItemMutationError): void {
      for (const err of errors) {
        switch (err.__typename) {
          case 'DuplicatedNameError':
            this.formErrors.addError('name', 'DuplicatedNameError');
            break;

          default:
            break;
        }
      }
    },

    onDeleteButtonClicked(): void {
      this.isDeleteConfirmDialogActive = true;
    },

    async onConfirmDelete(): Promise<void> {
      if (this.entities.editingCheckItem === undefined || this.formMode !== FormMode.Edit) return;

      this.$rinGtm.push(RinEventNames.DELETE, {
        [AdditionalInfoKeys.CHECK_ITEM_ID]: this.entities.editingCheckItem.id,
        [AdditionalInfoKeys.CHECK_ITEM_NAME]: this.entities.editingCheckItem.name,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CHECK_ITEM,
      });

      this.isRegistering = true;
      const checkItem = await this.checkItemApplicationService.delete(this.entities.editingCheckItem.id);
      this.entities.removedCheckItem = checkItem;
      this.isRegistering = false;
      this.entities.removedCheckItem = checkItem;
      this.$context.snackbar.success('タスクの削除完了');
    },
  },
});
