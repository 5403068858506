import { zip } from '~/framework/core/array';
import { IRouteEntity, IRouteEntityData } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { IRouteDiff, RouteDiff } from '~/framework/domain/schedule/schedule/pseudo-entities/scheduleDiff';
import { CollectionComparison } from '~/framework/domain/schedule/schedule/pseudo-entities/collectionComparison';

/**
 * ルートが入れ換えられたり追加・削除されたりして編集された状態にあるかどうかを取得する
 * id は original の方も実際の方も一緒という前提があるので注意
 */
export interface IRouteComparison {
  /**
   * key は presentRoute の id
   * presentRoute から見た diff が IRouteDiff としてマップされている
   */
  readonly diffs: Map<string, IRouteDiff>;

  /**
   * 与えられたルート全体として差分があるかどうか
   */
  readonly hasDiff: boolean;

  /**
   * 時間の固定を行っていたとしても不定の判定から免除されるべき条件を満たしているかどうかを取得する
   * 現在存在している側の回収からしか辿れないので注意
   * @param id
   */
  getIsMatchExemptionCondition(id: string): boolean;
}

export class RouteComparison<
  OriginalRoute extends IRouteEntityData<any, any, any>,
  PresentRoute extends IRouteEntity<any, any, any, any, any>
> implements IRouteComparison
{
  private readonly exemptionMap: Map<string, boolean>;
  readonly diffs: Map<string, IRouteDiff>;
  readonly hasDiff: boolean;

  constructor(originalRoutes: OriginalRoute[], presentRoutes: PresentRoute[]) {
    this.exemptionMap = new Map<string, boolean>();
    this.diffs = new Map<string, IRouteDiff>();
    this.hasDiff = false;
    let exemptionCondition = true;
    const originalRouteMap = new Map(originalRoutes.map((originalRoute) => [originalRoute.id, originalRoute]));
    const pairs = zip(originalRoutes, presentRoutes);
    for (const [originalRoute, presentRoute] of pairs) {
      if (presentRoute !== undefined) {
        const originalCollections = originalRouteMap.get(presentRoute.id)?.collections || [];
        const presentCollections = presentRoute.collections;
        const collectionComparison = new CollectionComparison(originalCollections, presentCollections);
        const isCreated = presentRoute.original === undefined;
        const hasCollectionDiff = collectionComparison.hasDiff;
        const hasIndexDiff =
          originalRoute === undefined ||
          presentRoute.id !== originalRoute.id ||
          presentRoute.index !== originalRoute.index;
        const hasDiff = isCreated || hasCollectionDiff || hasIndexDiff;
        if (hasDiff) {
          const routeDiff = new RouteDiff(isCreated, hasCollectionDiff, hasIndexDiff);
          for (const [id, diff] of collectionComparison.diffs) {
            routeDiff.setCollectionDiff(id, diff);
          }
          this.diffs.set(presentRoute.id, routeDiff);
        }
        this.hasDiff ||= hasDiff;
      } else if (presentRoute === undefined) {
        this.hasDiff = true;
      } else if (originalRoute === undefined) {
        this.hasDiff = true;
      }

      // 固定を行っていたとしてもその後の時間の不定化が免除される条件
      if (
        exemptionCondition &&
        originalRoute &&
        presentRoute &&
        originalRoute.id === presentRoute.id &&
        originalRoute.index === presentRoute.index &&
        originalRoute.endTime === presentRoute.endTime
      ) {
        exemptionCondition = true;
      } else {
        exemptionCondition = false;
      }
      if (presentRoute) this.exemptionMap.set(presentRoute.id, exemptionCondition);
    }
  }

  getIsMatchExemptionCondition(id: string): boolean {
    return this.exemptionMap.getOrError(id);
  }

  toString(): string {
    const diffs: { [key: string]: string } = {};
    for (const [key, value] of this.diffs.entries()) {
      diffs[key] = value.toString();
    }
    return `diffs: ${JSON.stringify(diffs)}`;
  }
}
