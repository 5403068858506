
import Vue, { PropType } from 'vue';
import { Seconds } from '~/framework/typeAliases';
import { getHoursAndMinutesOf } from '~/framework/services/date-time/date-time';
import { sanitizeHours, sanitizeMinutes } from '~/framework/view-models/ruleLogics';

type DataType = {
  hours: number;
  minutes: number;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RDurationInput',
  model: {
    prop: 'duration',
    event: EventTypes.Change,
  },
  props: {
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    duration: {
      type: Number as PropType<Seconds>,
      required: true,
    },
  },
  data(): DataType {
    const [hours, minutes] = getHoursAndMinutesOf(this.duration);
    return {
      hours,
      minutes,
    };
  },
  methods: {
    onChangeHours(hours: number | string): void {
      const hoursNum = sanitizeHours(hours);

      this.hours = hoursNum;
      const secs = (hoursNum * 60 + this.minutes) * 60;
      this.$emit('change', secs);
    },
    onChangeMinutes(minutes: string): void {
      const minutesNum = sanitizeMinutes(minutes);

      this.minutes = minutesNum;
      const secs = (this.hours * 60 + minutesNum) * 60;
      this.$emit('change', secs);
    },
  },
});
